/* eslint-disable no-use-before-define */
import React from "react";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { StyDivContainerLine, StyDivContainerLeftSide, StyDivContainerRightSide } from "./CalendarRow.styles";

function CalendarSkeleton() {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Skeleton variant="rect" height={100} animation="wave" style={{ margin: "25px 0px" }} />
      </Grid>
      <Grid item xs={12}>
        {[1, 2, 3].map((_, index) => (
          <StyDivContainerLine key={`block${index}`}>
            <StyDivContainerLeftSide>
              <Skeleton
                variant="rect"
                height={120}
                animation="wave"
                style={{ marginBottom: "15px", marginRight: "10px" }}
              />
            </StyDivContainerLeftSide>
            <StyDivContainerRightSide>
              <Skeleton variant="rect" height={120} animation="wave" style={{ marginBottom: "15px" }} />
            </StyDivContainerRightSide>
          </StyDivContainerLine>
        ))}
      </Grid>
    </Grid>
  );
}

export default CalendarSkeleton;
