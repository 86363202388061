import { MdClear } from "react-icons/md";
import React, { useState, useEffect } from "react";
import SectionTitle from "@atoms/Titles/SectionTitle";
import FenixTable from "@molecules/Tables/FenixTable";
import MenuItemUploadCSV from "@molecules/Buttons/MenuItemUploadCSV";
import { wordcontrol, filename, csvHead, csvSpecs } from "./constants";
import MenuItemDownloadCSV from "@molecules/Buttons/MenuItemDownloadCSV";
import bulkUploadSuppliersHyperguest from "./bulkUploadSuppliersHyperguest";
import { ThreeDotMenu, useThreeDotMenu } from "@molecules/Menus/ThreeDotMenu";
import { getSuppliersHyperguest, deleteSupplierHyperguest } from "./useSuppliersHyperguest";
import UploadResultAlert from "@molecules/Alerts/UploadResultAlert";

const SuppliersHyperguest = () => {
  const [loadingTable, setLoadingTable] = useState(true);
  const [suppliersList, setSuppliersList] = useState([]);
  const [uploadResult, setUploadResult] = useState();
  const { anchorEl, onOpenMenu, handleClose } = useThreeDotMenu();

  async function getAllSuppliersHyperguest() {
    setLoadingTable(true);
    const list = await getSuppliersHyperguest();
    setSuppliersList(list);
    setLoadingTable(false);
  }

  useEffect(() => {
    getAllSuppliersHyperguest();
  }, [uploadResult]);

  const deleteSupplier = async (row) => {
    if (confirm(`Vas a borrar ${row.id}`)) {
      try {
        await deleteSupplierHyperguest(row);
      } catch (e) {
        alert(e);
      }
      await getAllSuppliersHyperguest();
    }
  };

  const onUploadEnded = (resultUpload) => {
    let status = "success";
    if (resultUpload.errors.length > 0) {
      status = resultUpload.saved && resultUpload.saved > 0 ? "warning" : "error";
    }
    setUploadResult({ status, ...resultUpload });
  };

  function onUploadCSVEnded(result) {
    handleClose();
    onUploadEnded(result);
  }

  const tableHead = [
    { code: "id", name: "Id", type: "string" },
    { code: "code", name: "Codigo", type: "string" },
    { code: "supplier", name: "Supplier", type: "string" },
    { code: "api", name: "Api", type: "string" },
  ];

  const actions = [
    {
      name: "Borrar",
      func: deleteSupplier,
      icon: <MdClear />,
    },
  ];

  function generateCsvFromList() {
    return suppliersList.map((supplier) => ({
      ...supplier,
    }));
  }

  return (
    <>
      <SectionTitle
        title={"Suppliers Hyperguest"}
        buttons={[
          <ThreeDotMenu key={0} style={{ minWidth: "0" }} {...{ anchorEl, onOpenMenu, handleClose }}>
            <MenuItemUploadCSV
              itemsName={"itemsName"}
              wordcontrol={wordcontrol}
              filename={`${filename}`}
              buttonLabel={"Subir Suppliers"}
              specs={csvSpecs}
              uploadFunction={bulkUploadSuppliersHyperguest}
              onUploadEnded={onUploadCSVEnded}
            />
            <MenuItemDownloadCSV
              wordcontrol={wordcontrol}
              filename={`${filename}`}
              head={csvHead}
              specs={csvSpecs}
              generateTable={generateCsvFromList}
              buttonLabel={"Descargar Suppliers"}
              onDownloadEnded={handleClose}
            />
          </ThreeDotMenu>,
        ]}
      />
      {uploadResult && (
        <UploadResultAlert uploadResult={uploadResult} onClose={() => setUploadResult({ status: "close" })} />
      )}
      <FenixTable
        loading={loadingTable}
        tableHead={tableHead}
        tableContent={suppliersList}
        actions={actions}
      />
    </>
  );
};

export default SuppliersHyperguest;
