/* eslint-disable no-use-before-define */
import { getDatesBetweenTwo } from "@lib/helpers/dates";
import saveCalendars from "./saveCalendars";
import { fetchCalendarByType } from "./daoCalendars";
import { emptyDatabaseCalendar } from "../utils";

async function saveCalendarMassUpdate(dates, selections, channel) {
  let errors = [];
  const { precincts, products, tickets, sessions } = filterSelectionsToChange(selections);
  const dateRange = getDatesObject(dates);
  const promises = [];

  const yearList = Object.keys(dateRange);
  for (let index = 0; index < yearList.length; index += 1) {
    const year = parseInt(yearList[index], 10);
    // console.log("**** Año ", year);

    try {
      promises.push(createTheNewCalendar("precinct", precincts, {}, year, dateRange));
    } catch (e) {
      const eMessage = `Error al generar calendario de recintos: ${e.message}`;
      errors.push(eMessage);
    }

    try {
      promises.push(createTheNewCalendar("product", products, channel, year, dateRange));
    } catch (e) {
      const eMessage = `Error al generar calendario de productos: ${e.message}`;
      errors.push(eMessage);
    }

    try {
      promises.push(createTheNewCalendar("ticket", tickets, channel, year, dateRange));
    } catch (e) {
      const eMessage = `Error al generar calendario de entradas: ${e.message}`;
      errors.push(eMessage);
    }

    try {
      promises.push(createTheNewCalendar("session", sessions, channel, year, dateRange));
    } catch (e) {
      const eMessage = `Error al generar calendario de sesiones: ${e.message}`;
      errors.push(eMessage);
    }
  }

  const listToSave = (await Promise.all(promises)).flat();
  // console.log(`${listToSave.length} to save`);

  if (errors.length === 0) {
    const resultSaving = await saveCalendars(listToSave);
    if (resultSaving.errors.length > 0) {
      errors = [...errors, ...resultSaving.errors];
    }
  }

  return { num: listToSave.length, errors };
}

function filterSelectionsToChange(selections) {
  return Object.keys(selections).reduce(
    (accu, precinctCode) => {
      const nuAccu = { ...accu };
      if (selections[precinctCode].changed) {
        const toChange = getValuesToChange(selections[precinctCode].value);
        if (Object.keys(toChange).length > 0) {
          nuAccu.precincts[precinctCode] = { precinctCode, changes: toChange };
        }
      }

      const parsedProducts = Object.keys(selections[precinctCode].products).reduce(
        (accuP, productCode) => {
          const nuAccuProducts = { ...accuP };
          const { changed, value, tickets, sessions } = selections[precinctCode].products[productCode];

          if (changed) {
            const toChange = getValuesToChange(value);
            if (Object.keys(toChange).length > 0) {
              nuAccuProducts.products[productCode] = { precinctCode, productCode, changes: toChange };
            }
          }

          const ttickets = Object.values(tickets)
            .filter((t) => t.changed)
            .reduce((accuT, ticket) => {
              const toChangeT = getValuesToChange(ticket.value);
              if (Object.keys(toChangeT).length > 0) {
                const { ticketId } = ticket;
                return {
                  ...accuT,
                  [ticketId]: { precinctCode, productCode, ticketId, changes: toChangeT },
                };
              }
              return accuT;
            }, {});
          nuAccuProducts.tickets = { ...accuP.tickets, ...ttickets };

          const tsessions = Object.values(sessions)
            .filter((s) => s.changed)
            .reduce((accuS, session) => {
              const toChangeT = getValuesToChange(session.value);
              if (Object.keys(toChangeT).length > 0) {
                const { sessionId, ticketId } = session;
                return {
                  ...accuS,
                  [sessionId]: { precinctCode, productCode, ticketId, sessionId, changes: toChangeT },
                };
              }
              return accuS;
            }, {});
          nuAccuProducts.sessions = { ...accuP.sessions, ...tsessions };

          return nuAccuProducts;
        },
        { products: {}, tickets: {}, sessions: {} }
      );
      nuAccu.products = { ...accu.products, ...parsedProducts.products };
      nuAccu.tickets = { ...accu.tickets, ...parsedProducts.tickets };
      nuAccu.sessions = { ...accu.sessions, ...parsedProducts.sessions };

      return nuAccu;
    },
    {
      precincts: {},
      products: {},
      tickets: {},
      sessions: {},
    }
  );
}

function getValuesToChange(value) {
  // TODO: Ya no tiene sentido esta funcion
  return value;
}

function getDatesObject(dates) {
  return getDatesBetweenTwo(dates.datei, dates.dateo).reduce((accu, dt) => {
    const nuAccu = { ...accu };
    const year = new Date(dt).getFullYear();
    if (nuAccu[year] === undefined) {
      nuAccu[year] = {};
    }
    nuAccu[year][dt] = {};
    return nuAccu;
  }, {});
}

async function createTheNewCalendar(type, elementsToChange, channel, year, dateRange) {
  const resultCalendarElements = [];

  const identifiers = Object.keys(elementsToChange);
  for (let indexP = 0; indexP < identifiers.length; indexP += 1) {
    const id = identifiers[indexP];
    const { precinctCode, productCode, ticketId, sessionId, changes } = elementsToChange[id];

    let nuThisYearCalendar = {};
    const thisYearCalendar = await fetchCalendarByType(type, id, channel, year);
    if (Object.keys(thisYearCalendar).length > 0) {
      nuThisYearCalendar = { ...thisYearCalendar };
    } else {
      nuThisYearCalendar = emptyDatabaseCalendar({
        type,
        precinctCode,
        productCode,
        ticketId,
        sessionId,
        year,
        channel,
      });
    }

    nuThisYearCalendar.calendar = Object.values(
      mergeCurrentCalendarWithChanges(nuThisYearCalendar.calendar, dateRange[year], changes)
    );

    resultCalendarElements.push({ type, calendarEntry: nuThisYearCalendar });
  }

  return resultCalendarElements.flat();
}

function mergeCurrentCalendarWithChanges(currentCalendar, dateRangeOfYear, changes) {
  const kvCurrentCalendar = currentCalendar.reduce((a, dt) => ({ ...a, [dt.date]: dt }), {});
  Object.keys(dateRangeOfYear).forEach((nuDate) => {
    if (kvCurrentCalendar[nuDate] === undefined) {
      kvCurrentCalendar[nuDate] = { date: nuDate, enable: false };
    }

    let wday = new Date(nuDate).getDay();
    if (wday === 0) {
      wday = 7;
    }

    const inPriceValues = ["cost", "sellingPrice"];
    const nuChanges = { ...kvCurrentCalendar[nuDate] };
    Object.keys(changes).forEach((field) => {
      if (changes[field][wday].active) {
        if (inPriceValues.includes(field)) {
          if (nuChanges.price === undefined) {
            nuChanges.price = {};
          }
          nuChanges.price[field] = changes[field][wday].value;

          // Protection in order to send always "price" complete
          inPriceValues.forEach((fieldMissing) => {
            if (nuChanges.price[fieldMissing] === undefined) {
              nuChanges.price[fieldMissing] = changes[field][wday].value;
            }
          });
        } else {
          nuChanges[field] = changes[field][wday].value;
        }
      }
    });
    kvCurrentCalendar[nuDate] = { ...kvCurrentCalendar[nuDate], ...nuChanges };
  });

  return kvCurrentCalendar;
}

export default saveCalendarMassUpdate;
