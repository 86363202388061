import React, { Fragment, useState } from "react";
import { Popper, Fade, Paper, List, ListItem, ListItemText, Link } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

//Components & hooks
import CardTitle from "@atoms/Titles/CardTitle";

const fmSections = {
  "cdn-tr-hotels-v2": {
    name: "Hoteles",
    url: "hoteles",
    description: (
      <Fragment>
        <p>
          <b>CREACIÓN DE CARPETAS</b>
          {`: País/Provincia/Ciudad/Nombre Hotel -> Imágenes. `}
          <br />
          <b>{`ALMACENAR CONTENIDO(buscar antes de crear)`}</b>
          {`: País/Provincia/Ciudad -> Adjuntar
            nuevo hotel.`}
        </p>
        <p>
          <b>ESENCIAL</b>
          {`: Etiquetar todo contenido subido con un nombre de referencia, en este
            caso el del hotel. Antes de borrar carpetas consultar (Ruth/Amparo)`}
        </p>
      </Fragment>
    ),
  },
  "cdn-tr-img": {
    name: "Imágenes",
    url: "images",
    description: (
      <Fragment>
        <p>
          <b>GRAPHIC DESIGN</b>
          {`: Carpeta de Amparo (Solo incluye contenido amparo)`}
          <br />
          <b>PRODUCTOS</b>: Imágenes de los productos de la web.
          <br />
          <b>DESTINOS</b>: Imágenes de zonas de destino de la web
          <br />
          <br />
          <b>CREAR/ALMACENAR CONTENIDO</b>
          {`: Buscar una carpeta correspondiente al contenido para ver si ya está creada (Producto/Destino); si no está, crear directamente una carpeta nueva con el nombre propio del producto o de la zona de destino. Por ejemplo: Productos>ParqueWarner>Adj.Imágenes / Destinos>Mallorca>Adj.Imágenes`}
        </p>
        <p>
          <b>ESENCIAL</b>
          {`: Etiquetar todo contenido subido con un nombre de referencia, en este
            caso el del producto/destino. Antes de borrar carpetas consultar (Ruth/Amparo)`}
        </p>
      </Fragment>
    ),
  },
  sprites: {
    name: "Diseño Gráfico",
    url: "sprites",
    description: (
      <Fragment>
        <p>
          <b>GRAPHIC DESIGN</b>
          {`:  (Solo incluye contenido Amparo) y en caso de ser necesario incluir contenido consultarle primero.`}
        </p>
      </Fragment>
    ),
  },
};

const FileManagerTitle = ({ bucket }) => {
  const url = "/app/cdn/file-manager";

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openDescription, setOpenDescription] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClickDescription = () => {
    setOpenDescription(!openDescription);
  };

  return (
    <CardTitle
      title={
        <Fragment>
          <span>File Manager</span>
          <span
            onClick={(e) => handleClick(e)}
            style={{ marginLeft: "10px", cursor: "pointer", color: "#54b2dd" }}
          >
            <b>
              {(fmSections[bucket] && fmSections[bucket].name) || bucket}
              {!open ? <ExpandMore /> : <ExpandLess />}
            </b>
          </span>
          <Popper open={open} anchorEl={anchorEl} placement={"bottom"} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <List component="nav" aria-label="main mailbox folders">
                    {Object.keys(fmSections).map((bckt, index) => {
                      return (
                        <ListItem key={`section${index}`} button>
                          <Link href={`${url}/${fmSections[bckt].url}`} color="inherit">
                            <ListItemText primary={fmSections[bckt].name} />
                          </Link>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Fragment>
      }
      info={
        <span onClick={handleClickDescription}>
          <p>Gestor de archivos de Traventia</p>
          <p>
            <b>¡¡IMPORTANTE!!</b> Es necesario SEGUIR la siguiente ESTRUCTURA para guardar un orden lógico
            dentro del CDN. <br />
            Presiona aqui para ver las instrucciones:
            {!openDescription ? <ExpandMore /> : <ExpandLess />}
          </p>
          {(openDescription && fmSections[bucket] && fmSections[bucket].description) || ""}
        </span>
      }
      buttons={[]}
    />
  );
};

export default FileManagerTitle;
