/* eslint-disable no-use-before-define */
import { useState } from "react";
import useCalendarDayClick from "./useCalendarDayClick";
import changeOneCalendarDay from "./changeOneCalendarDay";
import { emptyDatabaseCalendar, getDayID } from "../utils";

const useChangeDay = ({ year, channel, onChangeErrors, fetchCalendar }) => {
  const [daysLoading, setDaysLoading] = useState({});

  const { addTask } = useCalendarDayClick({ executeOnFinish: onChangeDay });

  async function onChangeInDayData({
    type,
    precinctCode,
    productCode,
    ticketId,
    sessionId,
    ticketSessionId,
    newDayDate,
  }) {
    // console.log("Click ", type, precinctCode, productCode, ticketId, sessionId, newDayDate);
    let code = "";
    switch (type) {
      case "precinct":
        code = precinctCode;
        break;
      case "product":
        code = productCode;
        break;
      case "ticket":
        code = ticketId;
        break;
      case "session":
        code = sessionId;
        break;
      case "ticketsession":
        code = ticketSessionId;
        break;
      default:
        break;
    }
    addLoadingToDay(type, code, newDayDate);
    const emptyCalendar = emptyDatabaseCalendar({
      type,
      precinctCode,
      productCode,
      ticketId,
      sessionId,
      year,
      channel,
    });

    await addTask(async () => {
      try {
        await changeOneCalendarDay(type, code, channel, newDayDate, emptyCalendar);
        const nuDLoad = { ...daysLoading, [getDayID(type, code, newDayDate)]: false };
        setDaysLoading(nuDLoad);
      } catch (e) {
        // console.error(e);
        onChangeErrors([e.message]);
      }
    });
  }

  function addLoadingToDay(type, code, newDayDate) {
    const nuDLoad = { ...daysLoading };
    nuDLoad[getDayID(type, code, newDayDate)] = true;
    setDaysLoading(nuDLoad);
  }

  async function onChangeDay() {
    // console.log("Refresco todo");
    await fetchCalendar(false);
  }

  return { daysLoading, onChangeInDayData };
};

export default useChangeDay;
