import axios from "axios";

const API_SHORT_URL = "/api/v1/marketing-bc/url-shorter"

// eslint-disable-next-line import/prefer-default-export
 const createShortUrl = async (urlOffer, market, optionsUtm) => {
    
    let url = `https://www.traventia.${market}/ofertas/${urlOffer}`
    if (optionsUtm !== undefined) {
        const {utmMedium, utmlSource, utmCampaign, utmContent} = optionsUtm;
        url = `https://www.traventia.${market}/ofertas/${urlOffer}?utm_medium=${utmMedium}&utm_source=${utmlSource}&utm_campaign=${utmCampaign}&utm_content=${utmContent}`
    }
    let response = {};
    try {
        response = await axios.post(`${API_SHORT_URL}`, { url });
        response.ok = true;
        response.data.urlNoQuery = url.split('?')[0];
        return response;
    } catch (error) {
        console.log(error);
    }
    return response;
};

export default createShortUrl;

    