import axios from "axios";

const API_FERRYS = `/api/v1/product-bc/boats`;

export const getAllBoatsDestination = async () => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_FERRYS}/ports`);
      if (response.status === 200) {
        return { loadingList: false, list: response.data.data };
      }
      return { loadingList: false, list: [] };
    } catch (error) {
      console.error(error);
      return { loadingList: false, list: [] };
    }
  };
  const theStateFinal = await fetchData({ loadingList: false, list: [] });
  return theStateFinal;
};

export const getAllBoatsRoutes = async () => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_FERRYS}/routeports`);
      if (response.status === 200) {
        return { loadingList: false, list: response.data.data };
      }
      return { loadingList: false, list: [] };
    } catch (error) {
      console.error(error);
      return { loadingList: false, list: [] };
    }
  };
  const theStateFinal = await fetchData({ loadingList: false, list: [] });
  return theStateFinal;
};

export const getAllBoatsAccommodations = async () => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_FERRYS}/fares`);
      if (response.status === 200) {
        return { loadingList: false, list: response.data.data };
      }
      return { loadingList: false, list: [] };
    } catch (error) {
      console.error(error);
      return { loadingList: false, list: [] };
    }
  };
  const theStateFinal = await fetchData({ loadingList: false, list: [] });
  return theStateFinal;
};

export const getAllBoatsPortZones = async () => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_FERRYS}/ports-zones`);
      if (response.status === 200) {
        return { loadingList: false, list: response.data.data };
      }
      return { loadingList: false, list: [] };
    } catch (error) {
      console.error(error);
      return { loadingList: false, list: [] };
    }
  };
  const theStateFinal = await fetchData({ loadingList: false, list: [] });
  return theStateFinal;
};
