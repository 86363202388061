/* eslint-disable no-use-before-define */
import { fetchProducts } from "./useProducts";
import { csvSpecContent, contentFields, contentFieldsToStringArray } from "./constants";
import { tldXLangMatch } from "../shared/domainVariables";
import contentCsvRowValidation from "../shared/contentCsvRowValidation";
import difference from "../shared/differenceBetweenTwoLists";
import saveProductList from "./bulkUpload";

const contentFieldsArray = contentFieldsToStringArray(contentFields);

const bulkUploadProductsContent = async (productContentList) => {
  //* ** Get products saved
  let currentProducts = [];
  try {
    currentProducts = await fetchProducts({});
  } catch (e) {
    return { errors: [e.message] };
  }

  const currentByCode = currentProducts.reduce((a, p) => ({ ...a, [p.code]: p }), {});

  //* ** Validation
  const errorsValidation = productContentListValidation(productContentList, currentByCode);
  if (errorsValidation.length > 0) {
    return { errors: errorsValidation };
  }

  const toUpload = createCompleteProductListFromContent(productContentList, currentByCode);

  //* ** Get the intersection between the two
  const { toInsert, toUpdate } = difference(currentProducts, toUpload, true, contentFields);

  //* ** Save the results
  const { saved, errors } = await saveProductList([...toInsert, ...toUpdate]);

  return { saved, errors };
};

const productContentListValidation = (productContentList, currentByCode) => {
  const errors = [];
  productContentList.forEach((product) => {
    try {
      if (currentByCode[product.code] === undefined) {
        throw new Error(`Product <${product.code}> does not exists.`);
      }
      contentCsvRowValidation(product, csvSpecContent, contentFieldsArray);
    } catch (e) {
      errors.push(e.message);
    }
  });

  return errors;
};

const createCompleteProductListFromContent = (productContentCsv, currentByCode) => {
  const currentCopy = JSON.parse(JSON.stringify(currentByCode));

  const toSave = productContentCsv.reduce((toSaveAccu, productContentToCheck) => {
    const { code, field, tld, value } = productContentToCheck;

    const newSaveAccu = { ...toSaveAccu };

    const current = currentCopy[code];
    if (toSaveAccu[current.code] === undefined) {
      newSaveAccu[current.code] = { ...currentCopy[code] };
    }

    const lang = tldXLangMatch[tld];

    switch (field) {
      case "name":
      case "description":
        newSaveAccu[code][field][lang] = value;
        break;
      case "title":
        newSaveAccu[code].packageRules.title[lang] = value;
        break;
      case "priority":
        newSaveAccu[code].priority[tld] = parseInt(value, 10);
        break;

      default:
        break;
    }

    return newSaveAccu;
  }, {});

  return Object.values(toSave);
};

export default bulkUploadProductsContent;
