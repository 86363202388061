import { useRef, useState } from "react";

const Trcsvjs = require("trcsvjs");

const useCSVManagement = ({
  wordcontrol,
  head,
  specs,
  ignoreWordcontrol,
  filename,
  info,
  table,
  generateTable,
  onResultReady,
  onDownloadEnded,
}) => {
  const ref = useRef();

  const [loading, setLoading] = useState(false);

  // ----------- DOWNLOAD ----------------
  /**
   * Download a csv using the trcsvjs interface
   */
  const downloadCSV = async (csvList) => {
    // try {
    const csv = Trcsvjs.getEmptyCsv({ delimiter: ";" });
    if (wordcontrol) {
      let wcLine = ["WORD_CONTROL", wordcontrol];
      if (info.length > 0) {
        wcLine = [...wcLine, ...info];
      }
      csv.insertRow(wcLine);
    }
    csv.insertRow(head);
    csvList.forEach((thisdata) => {
      csv.insertRowFromObj(thisdata, specs);
    });
    csv.browseCsv(filename);
    onDownloadEnded();
    // } catch (err) {
    // console.error(err);
    // alert(err);
    // }
  };

  const onClickDownloadButton = async () => {
    setLoading(true);
    if (generateTable === undefined) {
      if (table !== undefined) {
        await downloadCSV(table);
      }
    } else {
      const newTable = await generateTable();
      await downloadCSV(newTable);
    }
    setLoading(false);
  };

  // ----------- UPLOAD ----------------
  /**
   * [getFile description]
   * @param {any} event [description]
   */
  const getFile = (event) => {
    event.preventDefault();
    ref.current.click();
  };

  /**
   * [handleFileInput description]
   * @param {FileList} files [description]
   */
  const handleFileInput = async (files) => {
    setLoading(true);
    const fileToUpload = files.item(0);

    Trcsvjs.getCsvFromFile(fileToUpload, { delimiter: ";" }, async (err, csv) => {
      if (err !== null) {
        console.error(err);
        setLoading(false);
        return;
      }
      if (!ignoreWordcontrol) {
        const wcOfCSV = csv.getCell(1, 2);
        if (wcOfCSV !== wordcontrol) {
          const error = `Palabra de control incorrecta. Esperada ${wordcontrol}. Contiene: ${wcOfCSV}`;
          console.error(error);
          alert(error);
          setLoading(false);
          return;
        }
      }

      const listParsed = [];
      const hasEspec = Object.keys(specs).length !== 0;

      let begin = 3;
      if (ignoreWordcontrol) {
        begin = 1;
      } else if (hasEspec) {
        begin = 3; // < Send header if without specifications
      }

      for (let i = begin; i < csv.number_of_rows; i += 1) {
        const point = !hasEspec ? csv.getRow(i) : csv.getObjFromRow(i, specs);
        listParsed.push(point);
      }

      setLoading(false);
      onResultReady(listParsed);
    });
  };

  return { ref, loading, onClickDownloadButton, handleFileInput, getFile };
};

useCSVManagement.defaultProps = {
  ignoreWordcontrol: false,
  onDownloadEnded: () => {},
};

export default useCSVManagement;
