import React from "react";
import { useParams } from "react-router-dom";
import CityTaxEditor from "@organisms/Product/Accommodations/CityTaxEditor";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

const CityTaxPage = () => {
  const { cityTaxCode } = useParams();

  return (
    <ContainerFenix>
      <CityTaxEditor cityTaxCode={cityTaxCode} />
    </ContainerFenix>
  );
};

export default CityTaxPage;
