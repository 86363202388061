/* eslint-disable import/no-unresolved */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { ButtonTP } from "./CrossSellingProductsList.styles";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";

function CrossSellingProductsList({ products, productsToUse, onChange }) {
  const handleTicketsProducts = (button) => {
    let nuProducToUse = [...productsToUse];
    const value = button.target.innerText;
    if (nuProducToUse.find((element) => element === value) === undefined) {
      nuProducToUse.push(value);
    } else {
      nuProducToUse.splice(nuProducToUse.indexOf(value), 1);
    }
    onChange(nuProducToUse);
  };

  const addAllTP = () => {
    productsToUse = products;
    onChange(productsToUse);
  };

  const removeAllTP = () => {
    productsToUse = [];
    onChange(productsToUse);
  };

  const getTicketProducts = () => {
    if (products !== undefined) {
      return products.map((cv, i) => {
        return (
          <Grid item key={`btn-product-${i}`}>
            <ButtonTP
              size={"small"}
              disableElevation
              color={productsToUse.find((element) => element === cv) === undefined ? " " : "primary"}
              variant={
                productsToUse.find((element) => element === cv) === undefined ? "outlined" : "contained"
              }
              onClick={(button) => {
                handleTicketsProducts(button);
              }}
            >
              {cv}
            </ButtonTP>
          </Grid>
        );
      });
    }
  };

  return (
    <Grid containet>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <ButtonCustom variant="outlined" onClick={addAllTP}>
              <FiPlusSquare /> Marcar todos
            </ButtonCustom>
          </Grid>
          <Grid item>
            <ButtonCustom variant="outlined" onClick={removeAllTP}>
              <FiMinusSquare /> Quitar todos
            </ButtonCustom>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid item xs={12}>
        <Grid container justify="center">
          {getTicketProducts()}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CrossSellingProductsList;
