import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import { priceInputData } from "../utils";

function PriceModeSelector({ priceMode, onChangePriceMode }) {
  return (
    <ButtonGroup style={{ width: "100%" }} variant="contained" aria-label="outlined primary button group">
      {priceInputData
        .filter((p) => p.inFilter)
        .map((pm) => (
          <Button
            size="small"
            key={`pm${pm.code}`}
            style={{ width: "100%", textTransform: "none" }}
            color={pm.code === priceMode && "primary"}
            onClick={() => onChangePriceMode(pm.code)}
          >
            {`${pm.label} (${pm.unit})`}
          </Button>
        ))}
    </ButtonGroup>
  );
}

export default PriceModeSelector;
