export const LS_setItem = (key, value) => {
  if (localStorage !== null) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const LS_getItem = (key) => {
  if (localStorage !== null) {
    const value = localStorage.getItem(key);
    return value !== undefined && value !== null ? JSON.parse(value) : null;
  }
  return null;
};

export const LS_removeItem = (key) => {
  if (localStorage !== null) {
    localStorage.removeItem(key);
  }
};

export const LS_clear = () => {
  if (localStorage !== null) {
    localStorage.clear();
  }
};
