/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { FaTicketAlt, FaEye, FaEyeSlash, FaStar, FaRegStar, FaCaretRight, FaCaretDown } from "react-icons/fa";
import { RiSearchEyeLine, RiSearchEyeFill } from "react-icons/ri";
// import Tooltip from "@material-ui/core/Tooltip";
import { getDayID } from "../utils";
import CalendarDay from "./CalendarDay";
import {
  StyDivContainerLine,
  StyDivContainerLeftSide,
  StyDivContainerRightSide,
  StyDivInteractionButton,
  StyDivProductText,
  StyDivInteractionIcon,
  ConstantType,
} from "./CalendarRow.styles";

function CalendarRow({
  item,
  precinctCode,
  productCode,
  product,
  type,
  fieldToShow,
  daysLoading,
  onClickInDay,
  parentCalendars,
  // * Highligth
  highlighted,
  changeHighligthing,
  // * Visibility
  isVisible,
  changeProductVisibility,
  // * Children display
  hasChildren,
  childrenDisplayed,
  changeChildrenVisibility,
}) {
  const idField = getIDField();
  const id = item[idField];
  const calendar = Object.values(item.calendar);
  const calendarLength = calendar.length;

  const styleText = getStyleTextByType();

  const [showCode, setShowCode] = useState(false);
  function getIDField() {
    let idF = "";
    switch (type) {
      case "precinct":
        idF = "precinctCode";
        break;
      case "product":
        idF = "productCode";
        break;
      case "ticket":
        idF = "ticketId";
        break;
      case "session":
        idF = "sessionId";
        break;
      case "ticketsession":
        idF = "ticketSessionId";
        break;
      default:
        throw new Error("Tipo no identificado");
    }

    return idF;
  }
  function getStyleTextByType() {
    let style = {};
    switch (type) {
      case "precinct":
        style = { fontWeight: "bold", fontSize: "12px", color: "darkslateblue" };
        break;
      case "product":
        style = { fontWeight: "bold" };
        break;
      default:
        style = {};
        break;
    }

    return style;
  }

  function showButtonPlaceHolder() {
    return type === "precinct";
  }
  function showButtonProductVisibility() {
    return type === "product";
  }
  function showButtonHighlighting() {
    return ["ticket", "session", "ticketsession"].includes(type);
  }
  function showButtonShowCode() {
    return ["product", "ticket", "session", "ticketsession"].includes(type);
  }

  function isParentEnabled(thisDate) {
    return parentCalendars.every((pc) => pc[thisDate.date] !== undefined && pc[thisDate.date].enable);
  }
  // function hasChildren() {
  //   return childrenDisplayed !== undefined && changeChildrenVisibility !== undefined;
  // }
  function onClickInName() {
    if (hasChildren) {
      changeChildrenVisibility(productCode, id);
    }
  }
  function clickInADay(newDayDate) {
    const field = getIDField();
    const dayData = { type, precinctCode, productCode, newDayDate, [field]: item[field] };
    onClickInDay(dayData);
  }

  return (
    <StyDivContainerLine style={{ backgroundColor: highlighted && "lavender" }}>
      {/* Interaction & name */}
      <StyDivContainerLeftSide>
        <>
          {/* Button placeholder */}
          {showButtonPlaceHolder() && (
            <CalendarRowButton
              backgroundColor={"darkslateblue"}
              style={{ cursor: "default", borderTopLeftRadius: "4px" }}
              icon={<FaTicketAlt />}
            />
          )}
          {/* Highlight Button */}
          {showButtonHighlighting() && (
            <CalendarRowButton
              active={childrenDisplayed}
              icon={highlighted ? <FaStar /> : <FaRegStar />}
              highlighted={highlighted}
              onClick={changeHighligthing}
            />
          )}
          {/* Visibility */}
          {showButtonProductVisibility() && (
            <CalendarRowButton
              backgroundColor={"grey"}
              active={childrenDisplayed}
              icon={isVisible !== undefined && isVisible(productCode) ? <FaEye /> : <FaEyeSlash />}
              highlighted={highlighted}
              onClick={() => changeProductVisibility(productCode)}
            />
          )}
          {/* Show children */}
          {/* {hasChildren() && (
            <CalendarRowButton
              active={childrenDisplayed}
              icon={childrenDisplayed ? <FaCaretDown /> : <FaCaretRight />}
              highlighted={highlighted}
              onClick={changeChildrenVisibility}
            />
          )} */}
          {/* Calendar */}
          <StyDivProductText
            style={{
              color: highlighted && "darkslateblue",
              fontWeight: highlighted && "bold",
              cursor: hasChildren && "pointer",
              ...styleText,
            }}
            onClick={onClickInName}
          >
            {hasChildren && <span>{childrenDisplayed ? <FaCaretDown /> : <FaCaretRight />}</span>}
            {idField === "productCode" ? (
              <>
                {!showCode ? (
                  <span>{item.productCode}</span>
                ) : (
                  <span title={product.productName["es-ES"] || item.productCode}>
                    {product.productName["es-ES"] || item.productCode}
                  </span>
                )}
              </>
            ) : (
              <>
                {showCode ? (
                  <span>{id}</span>
                ) : (
                  <ConstantType displayPriceModels={item.priceModel} title={`PerOneDay: ${item.alias || id}`}>
                    {item.alias || id}
                  </ConstantType>
                )}
              </>
            )}

            {/* </Tooltip> */}
          </StyDivProductText>
          {/* Show code */}
          {showButtonShowCode() && (
            <StyDivInteractionIcon onClick={() => setShowCode(!showCode)}>
              {showCode ? <RiSearchEyeFill /> : <RiSearchEyeLine />}
            </StyDivInteractionIcon>
          )}
        </>
      </StyDivContainerLeftSide>

      {/* Calendar */}
      {(isVisible === undefined || isVisible(productCode)) && (
        <StyDivContainerRightSide>
          {calendar.map((dateT, indexDateT) => (
            <CalendarDay
              key={`dateT${id}${indexDateT}`}
              highlighted={highlighted}
              loading={daysLoading[getDayID(type, id, dateT)]}
              dataForDay={dateT}
              type={type}
              fieldToShow={fieldToShow}
              calendarLength={calendarLength}
              onClickInDay={clickInADay}
              parentEnabled={isParentEnabled(dateT)}
              fieldExclusions={{
                commission: item.priceCommissionable,
                quota: item.quotaEnable,
              }}
            />
          ))}
        </StyDivContainerRightSide>
      )}
    </StyDivContainerLine>
  );
}

const CalendarRowButton = ({ active, icon, highlighted, onClick, ...props }) => {
  return (
    <StyDivInteractionButton
      style={{ backgroundColor: highlighted && "orange" }}
      onClick={onClick}
      {...props}
    >
      {icon}
    </StyDivInteractionButton>
  );
};

CalendarRow.defaultProps = { parentCalendars: [], hasChildren: false };

export default CalendarRow;
