import _ from "lodash";
import { fetchCalendarByType } from "./daoCalendars";
import saveCalendars from "./saveCalendars";
import { priceInputData } from "../utils";

async function changeOneCalendarDay(type, code, channel, newDay, emptyCalendar) {
  // console.log("changeOneCalendarDay", type, code, newDay);

  if (newDay.date === undefined || newDay.enable === undefined) {
    throw new Error(`Missing key values in new day data`);
  }

  // Get year
  const year = new Date(newDay.date).getFullYear();

  let currentCalendar = {};
  try {
    currentCalendar = await fetchCalendarByType(type, code, channel, year);
  } catch (e) {
    // Year not found
  }
  // console.log("Leer", newDay, currentCalendar);

  let origin = { ...emptyCalendar };
  if (Object.keys(currentCalendar).length > 0) {
    origin = { ...currentCalendar };
  }

  const newCalendar = origin.calendar !== undefined ? [...origin.calendar] : [];

  const indexToChange = newCalendar.findIndex((day) => day.date === newDay.date);
  if (indexToChange === -1) {
    newCalendar.push(newDay);
  } else {
    newCalendar[indexToChange] = { ...newCalendar[indexToChange], ...newDay };
  }

  // Filter in-view data
  const fields = [...priceInputData.map((v) => v.code), "date", "price"];
  const calendar = newCalendar.map((day) => _.pick(day, fields));
  const calendarEntry = { ...origin, calendar };

  const result = await saveCalendars([{ type, calendarEntry }]);
  if (result.errors.length > 0) {
    throw new Error(result.errors.join("\n"));
  }

  return result;
}

export default changeOneCalendarDay;
