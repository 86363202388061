import React from "react";
import { RiCharacterRecognitionLine } from "react-icons/ri";
// Atoms
import SectionTitle from "@atoms/Titles/SectionTitle";
// Molecules
import { ThreeDotMenu, useThreeDotMenu } from "@molecules/Menus/ThreeDotMenu";
// Components-Precints
import {
  csvHeadContent as csvPrecinctHeadContent,
  csvSpecContent as csvPrecinctSpecContent,
  wordcontrolContent as wordcontrolContentPrecinct,
  filenameContent as filenameContentPrecinct,
  contentFields as contentPrecinctsFields,
} from "../Precincts/constants";
import bulkUploadPrecinctsContent from "../Precincts/bulkUploadPrecinctsContent";
import reorganizeListIntoCsvContent from "../shared/reorganizeListIntoCsvContent";
import MenuItemDownloadCSV from "../shared/MenuItemDownloadCSV";
import MenuItemUploadCSV from "../shared/MenuItemUploadCSV";

function TicketingSectionTitle({ precinctCode, precinct, goBackToPrecincts, onUploadEnded }) {
  const { anchorEl, onOpenMenu, handleClose } = useThreeDotMenu();

  function onUploadCSVEnded(result) {
    handleClose();
    onUploadEnded(result);
  }

  return (
    <SectionTitle
      title={
        <>
          {`Recinto `}
          <b>{precinctCode}</b>
        </>
      }
      backFunction={goBackToPrecincts}
      buttons={[
        <ThreeDotMenu
          key={"ButtonsCsvContent"}
          style={{ minWidth: "0" }}
          {...{ anchorEl, onOpenMenu, handleClose }}
        >
          <MenuItemUploadCSV
            icon={<RiCharacterRecognitionLine size={20} />}
            itemsName={"contenido de recintos"}
            wordcontrol={wordcontrolContentPrecinct}
            filename={`${precinctCode}_${filenameContentPrecinct}`}
            buttonLabel={`Subir contenido ${precinctCode}`}
            specs={csvPrecinctSpecContent}
            uploadFunction={bulkUploadPrecinctsContent}
            onUploadEnded={onUploadCSVEnded}
          />
          <MenuItemDownloadCSV
            icon={<RiCharacterRecognitionLine size={20} />}
            wordcontrol={wordcontrolContentPrecinct}
            filename={`${precinctCode}_${filenameContentPrecinct}`}
            head={csvPrecinctHeadContent}
            specs={csvPrecinctSpecContent}
            generateTable={() => reorganizeListIntoCsvContent([precinct], contentPrecinctsFields)}
            buttonLabel={`Descargar contenido ${precinctCode}`}
            onDownloadEnded={handleClose}
          />
        </ThreeDotMenu>,
      ]}
    />
  );
}

export default TicketingSectionTitle;
