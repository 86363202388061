/* eslint-disable no-use-before-define */
import axios from "axios";
import { getChannelID } from "../../shared/domainVariables";
import { getChannelAsQueryParms } from "../utils";

export const fetchCalendarByType = async (type, code, channel, year) => {
  let origin = {};
  const codes = code.split(":");
  switch (type) {
    case "precinct":
      origin = await fetchPrecinctCalendar(code, year);
      break;
    case "product":
      origin = await fetchProductCalendar(code, channel, year);
      break;
    case "ticket":
      origin = await fetchTicketCalendar(code, channel, year);
      break;
    case "session":
      origin = await fetchSessionCalendar(code, channel, year);
      break;
    // case "ticketSession":
    //   origin = await fetchTicketSessionCalendar(code, channel, year);
    case "ticketSession":
      origin = await fetchTicketSessionCalendar(codes[0], codes[1], channel, year);
      break;
    default:
      throw new Error(`Undefined type <${type}>`);
  }

  return origin;
};

export const fetchPrecinctCalendar = async (precinctCode, year) => {
  const url = `/api/v1/ticketing-bc/calendar/precincts/${precinctCode}/${year}`;
  return get(url, {});
};

export const fetchProductCalendar = async (productCode, channel, year) => {
  const channelId = getChannelID(channel);
  const url = `/api/v1/ticketing-bc/calendar/products/${productCode}/${channelId}/${year}`;
  return get(url, {});
};

export const fetchTicketCalendar = async (ticketId, channel, year) => {
  const channelId = getChannelID(channel);
  const url = `/api/v1/ticketing-bc/calendar/tickets/${ticketId}/${channelId}/${year}`;
  return get(url, {});
};

export const fetchSessionCalendar = async (sessionId, channel, year) => {
  const channelId = getChannelID(channel);
  const url = `/api/v1/ticketing-bc/calendar/sessions/${sessionId}/${channelId}/${year}`;
  return get(url, {});
};

export const fetchTicketSessionCalendar = async (sessionId, ticketId, channel, year) => {
  const channelId = getChannelID(channel);
  const url = `/api/v1/ticketing-bc/calendar/ticketsessions/${sessionId}/${ticketId}/${channelId}/${year}`;
  return get(url, {});
};

export const fetchProductCalendarWithPrecinctCode = async (precinctCode, channel, year) => {
  const params = {
    year,
    precinctCode,
    ...getChannelAsQueryParms(channel, "channel"),
  };
  const url = `/api/v1/ticketing-bc/calendar/products`;
  return get(url, params);
};

export const fetchTicketCalendarWithPrecinctCode = async (precinctCode, channel, year) => {
  const params = {
    year,
    precinctCode,
    ...getChannelAsQueryParms(channel, "channel"),
  };
  const url = `/api/v1/ticketing-bc/calendar/tickets`;
  return get(url, params);
};

export const fetchSessionCalendarWithPrecinctCode = async (precinctCode, channel, year) => {
  const params = {
    year,
    precinctCode,
    ...getChannelAsQueryParms(channel, "channel"),
  };
  const url = `/api/v1/ticketing-bc/calendar/sessions`;
  return get(url, params);
};

export const fetchTicketSessionCalendarWithPrecinctCode = async (precinctCode, channel, year) => {
  const params = {
    year,
    precinctCode,
    ...getChannelAsQueryParms(channel, "channel"),
  };
  const url = `/api/v1/ticketing-bc/calendar/ticketsessions`;
  return get(url, params);
};

export const savePrecinctCalendar = async (body) => {
  const url = `/api/v1/ticketing-bc/calendar/precincts/${body.precinctCode}`;
  return put(url, {}, body);
};

export const saveProductCalendar = async (body) => {
  const channelId = getChannelID(body.channel);
  const url = `/api/v1/ticketing-bc/calendar/products/${body.productCode}/${channelId}`;
  return put(url, {}, body);
};

export const saveTicketCalendar = async (body) => {
  const channelId = getChannelID(body.channel);
  const url = `/api/v1/ticketing-bc/calendar/tickets/${body.ticketId}/${channelId}`;
  return put(url, {}, body);
};

export const saveSessionCalendar = async (body) => {
  const channelId = getChannelID(body.channel);
  const url = `/api/v1/ticketing-bc/calendar/sessions/${body.sessionId}/${channelId}`;
  return put(url, {}, body);
};

export const saveTicketSessionCalendar = async (body) => {
  const channelId = getChannelID(body.channel);
  const url = `/api/v1/ticketing-bc/calendar/ticketsessions/${body.sessionId}/${body.ticketId}/${channelId}`;
  return put(url, {}, body);
};

async function get(url, params) {
  try {
    const { data } = await axios.get(url, { params });
    return data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    throw error;
  }
}

async function put(url, params, body) {
  try {
    const newBody = { ...body, calendar: sortedCalendar(body.calendar) };
    const { data } = await axios.put(url, newBody, { params });
    return data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    throw error;
  }
}

function sortedCalendar(calendar) {
  calendar.sort((a, b) => (b.date > a.date ? -1 : 1));
  return calendar;
}
