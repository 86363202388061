import React from "react";
import PropTypes from "prop-types";
import { FiUploadCloud } from "react-icons/fi";
import useCSVManagement from "@lib/hooks/useCSVManagement";
import CircularProgress from "@material-ui/core/CircularProgress";

function MenuItemUploadCSV({
  itemsName,
  wordcontrol,
  specs,
  buttonLabel,
  ignoreWordcontrol,
  uploadFunction,
  onUploadEnded,
  icon,
  ...props
}) {
  async function onResultReady(resultList) {
    if (confirm(`Se van a subir ${resultList.length} ${itemsName}. ¿Continuar?`)) {
      const uploadResult = await uploadFunction(resultList);
      onUploadEnded({ total: resultList.length, ...uploadResult });
    }
  }

  const { ref, loading, handleFileInput, getFile } = useCSVManagement({
    wordcontrol,
    specs,
    buttonLabel,
    ignoreWordcontrol,
    onResultReady,
  });

  return loading ? (
    <div style={{ width: "100%" }}>
      <CircularProgress size={20} color="primary" style={{ marginRight: "10px" }} /> Cargando CSV...
    </div>
  ) : (
    <>
      <div style={{ width: "100%" }} {...props} onClick={getFile}>
        {icon}
        <FiUploadCloud size={20} style={{ marginRight: "10px" }} /> {buttonLabel || ""}
      </div>
      <div style={{ display: "none" }}>
        <input type="file" ref={ref} onChange={(event) => handleFileInput(event.target.files)} />
      </div>
    </>
  );
}

MenuItemUploadCSV.propTypes = {
  wordcontrol: PropTypes.string,
  specs: PropTypes.object,
  buttonLabel: PropTypes.string,
  ignoreWordcontrol: PropTypes.bool,
  onResultReady: PropTypes.func,
};

MenuItemUploadCSV.defaultProps = {
  ignoreWordcontrol: false,
};

export default MenuItemUploadCSV;
