import axios from "axios";

const url = "/api/v1/product-bc/content/cdn-akamai-invalidate";

export const cleanUrl = async (urlToClean) => {
  let response = {};
  try {
    await axios.post(`${url}/url`, {
      url: urlToClean,
    });
    response.ok = true;
  } catch (error) {
    response.ok = false;
    response.err = parseErrorResponse(error, urlToClean);
  }
  return response;
};

export const cleanMarket = async (market) => {
  let response = {};
  try {
    await axios.post(`${url}/market`, {
      market: market,
    });
    response.ok = true;
  } catch (error) {
    response.ok = false;
    response.err = parseErrorResponse(error, market);
  }
  return response;
};

const parseErrorResponse = (error, data) => {
  let errorList = [`Error limpiando ${data}`];
  if (
    error.response !== undefined &&
    (error.response.error !== undefined || error.response.data?.error !== undefined)
  ) {
    const msg = error.response.error || error.response.data?.error;
    errorList.push(msg);
  }
  return errorList;
};
