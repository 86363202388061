import React from "react";
import axios from "axios";
import ItemSelectorWithQuery from "@molecules/Forms/ItemSelectorWithQuery";
import { TravCard, TravHeaderCard, TravBodyCard } from "@atoms/Card/FxCard";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import Grid from "@material-ui/core/Grid";

function RoomOptionSelector({ roomOptions = [], onChange, withCard = true }) {
  const quickRoomOptions = [
    { code: "DBLS", label: "Doble" },
    { code: "TPRS", label: "Triple" },
    { code: "HQUA", label: "Cuadruple" },
    { code: "FAM", label: "Familiar" },
  ];

  async function onSearch(query) {
    if (query.length < 2) {
      return [];
    }

    let roomoptionList = [];
    try {
      const url = "/api/v1/web-bff-bc/accommodation/roomoption";
      const promises = [
        axios.get(url, { params: { "code[regex]": query } }),
        axios.get(url, { params: { "desc[regex]": query } }),
      ];

      const response = await Promise.all(promises);

      response.forEach(({ status, data }) => {
        if (status === 200) {
          roomoptionList = [
            ...roomoptionList,
            ...data.data.map((product) => ({
              ...product,
              text: [product.code, product.description].join(" - "),
            })),
          ];
        }
      });
    } catch (e) {
      console.error(e);
    }

    return roomoptionList;
  }

  function onChangeRoomOption(newRoomoption) {
    onChange(newRoomoption);
  }

  return (
    <>
      {withCard ? (
        <TravCard>
          <TravHeaderCard>
            <h5>Modalidades</h5>
            <p style={{ fontSize: "12px" }}>
              Añade aqui las modalidades que usa el alojamiento <b>exclusivamente</b>. Si lo dejas vacio,
              incluira todas los posibles. Si añades alguna, la oferta solo incluira esas.
            </p>
          </TravHeaderCard>
          <TravBodyCard>
            <ItemSelectorWithQuery
              title={
                <>
                  {"Añade algunas rapidamente: "}
                  <br />
                  <>
                    {quickRoomOptions.map((option) => (
                      <ButtonAdd
                        key={option.code}
                        size={"small"}
                        disabled={roomOptions.includes(option.code)}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onChangeRoomOption([...roomOptions, option.code]);
                        }}
                      >
                        {option.code}
                      </ButtonAdd>
                    ))}
                  </>
                </>
              }
              inputPlaceholder={"... o escribe aqui el código o nombre de la modalidad para buscarlo"}
              selected={roomOptions}
              onSearch={onSearch}
              onChange={onChangeRoomOption}
            />
          </TravBodyCard>
        </TravCard>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <h5>Modalidades</h5>
            <p style={{ fontSize: "12px" }}>
              Añade aqui las modalidades que usa el alojamiento <b>exclusivamente</b>. Si lo dejas vacio,
              incluira todas los posibles. Si añades alguna, la oferta solo incluira esas.
            </p>
          </Grid>
          <Grid item xs={12}>
            <ItemSelectorWithQuery
              title={
                <>
                  {"Añade algunas rapidamente: "}
                  <br />
                  <>
                    {quickRoomOptions.map((option) => (
                      <ButtonAdd
                        key={option.code}
                        size={"small"}
                        disabled={roomOptions.includes(option.code)}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onChangeRoomOption([...roomOptions, option.code]);
                        }}
                      >
                        {option.code}
                      </ButtonAdd>
                    ))}
                  </>
                </>
              }
              inputPlaceholder={"... o escribe aqui el código o nombre de la modalidad para buscarlo"}
              selected={roomOptions}
              onSearch={onSearch}
              onChange={onChangeRoomOption}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default RoomOptionSelector;
