import React, { useState } from "react";
import { FormControl, FormLabel, FormGroup } from "@material-ui/core";
import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import Modal from "@atoms/Modals/Modal";
import YearSelectorMultiple from "@atoms/Selectors/YearSelectorMultiple";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import  ButtonDownload  from "@atoms/Buttons/ButtonDownload";
import csvCalendarGenerator from "./csvCalendarGenerator";
import csvCalendarUploader from "./csvCalendarUploader";

function CalendarCSV({ precinctCode, channel, onUpdate }) {
  const wordcontrol = `TICKETING_CALENDAR_V1`;

  const [yearsSelected, setYearsSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorModal, setErrorModal] = useState({ open: false });

  async function generateCSVCalendar() {
    setLoading(true);
    await csvCalendarGenerator(precinctCode, channel, yearsSelected, wordcontrol);
    setLoading(false);
  }

  async function uploadCSVCalendar(csv) {
    try {
      setLoading(true);
      const results = await csvCalendarUploader(csv, wordcontrol);
      setLoading(false);

      setErrorModal({ open: true, ...results });

      // onUpdate({ dates: { dateo: csv[0].date } });
    } catch (e) {
      setLoading(false);
      setErrorModal({ open: true, errors: e.message.split("|") });
    }
  }

  function onCloseModal() {
    setErrorModal({ open: false });
    onUpdate({});
  }

  return (
    <TravCard>
      <TravBodyCard>
        <TravTitleCard>Modificación por CSV</TravTitleCard>
        <div style={{ display: "flex" }}>
          <YearSelectorMultiple
            label={"Selecciona los años que descargará el CSV"}
            disabled={loading}
            yearsSelected={yearsSelected}
            onChangeSelectedYears={setYearsSelected}
          />
          <FormControl>
            <FormLabel component="legend">
              {"Descarga CSV para los años elegidos o sube uno (sin restricciones)"}
            </FormLabel>
            <FormGroup style={{gap:"10px"}} row={true}>
              <ButtonUploadCSV
                {...{ wordcontrol }}
                specs={{}}
                onResultReady={uploadCSVCalendar}
                buttonLabel={`Subir calendario`}
                ignoreWordcontrol={true}
                disabled={loading}
             />

              <ButtonDownload onClick={generateCSVCalendar} disabled={loading}>
                {`Descargar ${yearsSelected.length > 0 ? yearsSelected.join(", ") : "vacío"}`}
              </ButtonDownload>

              {loading && <div style={{ lineHeight: "20px", padding: "10px" }}>{"Cargando..."}</div>}
            </FormGroup>
          </FormControl>
          <Modal show={errorModal.open} onClose={onCloseModal}>
            <>
              <h2>Resultados de la subida 🧗🏼‍♂️</h2>
              {errorModal.total && <p>Se ha subido un archivo con {errorModal.total} filas.</p>}
              {errorModal.saved ? (
                <ul>
                  {errorModal.saved.precincts !== 0 && (
                    <li>
                      {`Se han subido correctamente ${errorModal.saved.precincts}/${errorModal.total_breakdown.precincts} recintos.`}
                    </li>
                  )}
                  {errorModal.saved.products !== 0 && (
                    <li>
                      {`Se han subido correctamente ${errorModal.saved.products}/${errorModal.total_breakdown.products} productos.`}
                    </li>
                  )}
                  {errorModal.saved.tickets !== 0 && (
                    <li>
                      {`Se han subido correctamente ${errorModal.saved.tickets}/${errorModal.total_breakdown.tickets} tarifas.`}
                    </li>
                  )}
                </ul>
              ) : (
                <p>Nada ha sido guardado</p>
              )}
              {errorModal.errors && errorModal.errors.length > 0 && (
                <>
                  <h3>Errores encontrados ❌</h3>
                  <ul style={{ color: "red" }}>
                    {errorModal.errors.map((e, ix) => {
                      const errorList = e.split("\n");
                      return (
                        <li key={`error${ix}`}>
                          <>
                            {errorList[0]}
                            <ul>
                              {errorList.slice(1).map((e1, ix1) => (
                                <li key={`error${ix}${ix1}`}>{e1}</li>
                              ))}
                            </ul>
                          </>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </>
          </Modal>
        </div>
      </TravBodyCard>
    </TravCard>
  );
}

export default CalendarCSV;
