import React, { Fragment, useState, useEffect } from "react";
import { FormControl, Input, InputLabel, TextField, InputAdornment } from "@material-ui/core";
import { Card } from "react-bootstrap";
import { BsLink, BsArrowReturnRight } from "react-icons/bs";

const ComboTitleAndURLInput = ({ blockId, labels = ["Titulo", "Enlace"], item, onChangeItem }) => {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex" }}>
        <TextField
          style={{ width: "50%" }}
          label={labels[0]}
          value={item.name}
          onChange={(nVal) => {
            item.name = nVal.target.value;
            onChangeItem(item);
          }}
        />

        {/* <BsArrowReturnRight width={100} /> */}
        <FormControl style={{ width: "50%", display: "flex" }}>
          <InputLabel htmlFor={`input-combo-title-url-${blockId}`}>{labels[1]}</InputLabel>
          <Input
            id={`input-combo-title-url-${blockId}`}
            type={"text"}
            value={item.url}
            onChange={(nVal) => {
              item.url = nVal.target.value;
              onChangeItem(item);
            }}
            startAdornment={
              <InputAdornment position="start">
                <BsLink />
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    </div>
  );
};

export default ComboTitleAndURLInput;
