import axios from "axios";
import { useState, useEffect } from "react";
const url = "/api/v1/product-bc/offers/searchs";

export const useOfferSearchs = (idOffer) => {
  const [state, setState] = useState({ loading: true, searchs: {}, error: "", ok: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { searchs: {}, loading: false };
        const response = await axios.get(`${url}?idOffer=${idOffer}`);
        if (response.status === 200 && response.data) {
          thestate = { searchs: response.data, loading: false, error: "", ok: true };
        }
        setState(thestate);
      } catch (error) {
        setState({ searchs: {}, loading: false, error: catchErrorMessage(error), ok: false });
        throw error;
      }
    };
    if (state.loading) {
      fetchData();
    }
  }, [state.loading, state, idOffer]);

  return { loadingSearchs: state.loading, searchs: state.searchs, error: state.error, ok: state.ok };
};

export const getOfferSearchs = async (idOffer) => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}?idOffer=${idOffer}`);
      if (response.status === 200) {
        return { loadingSearchs: false, searchs: response.data };
      }
      return { loadingSearchs: false, searchs: [] };
    } catch (error) {
      console.error(error);
      return { loadingSearchs: false, searchs: [] };
    }
  };
  const theStateFinal = await fetchData({ loadingSearchs: false, searchs: [] });
  return theStateFinal;
};
