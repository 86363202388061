import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import RemittancesPage from "@organisms/Administration/Remittances/pages/RemittancesPage";
import BankAccountValidationPage from "@organisms/Administration/BankAccountValidation/pages/BankAccountValidationPage";
import TransfersRefundPendingPage from "./transfersRefundPendingPage/TransfersRefundPendingPage";
import PaymentsToSuppliersMainPage from "./PaymentsToSuppliersMainPage";

function RoutesAdministration({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/administration`;

  return (
    <Fragment>
      <Route exact path={`${mainUrl}/transfersRefundPending`} component={TransfersRefundPendingPage} />
      <Route exact path={`${mainUrl}/paymentsToSuppliers`} component={PaymentsToSuppliersMainPage} />
      <Route exact path={`${mainUrl}/remittances`} component={RemittancesPage} />
      <Route exact path={`${mainUrl}/bankAccountValidation`} component={BankAccountValidationPage} />
    </Fragment>
  );
}

export default RoutesAdministration;
