import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CardTitle from "@atoms/Titles/CardTitle";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import { AiFillFileUnknown } from "react-icons/ai";
import { CgSearchFound } from "react-icons/cg";
import { FaPiggyBank } from "react-icons/fa";

import BankTransfersSearcher from "@organisms/Operations/BankTransfersSearcher";
import BankTransfersUnmatched from "@organisms/Operations/BankTransfersUnmatched";

function BankTransfersManagmentPage({}) {
  const [tabSelected, setTabSelected] = useState(0);

  const handleChange = (_, newValue) => {
    setTabSelected(newValue);
  };

  return (
    <ContainerFenix>
      <CardTitle title={"Gestión de transferencias bancarias"} />
      <Paper>
        <Tabs value={tabSelected} onChange={handleChange} variant="fullWidth">
          <Tab label="Transferencias no asignadas" icon={<AiFillFileUnknown size={25} />} />
          <Tab label="Busca transferencias" icon={<CgSearchFound size={25} />} />
        </Tabs>
      </Paper>

      <div role="tabpanel" hidden={0 !== tabSelected}>
        <BankTransfersUnmatched />
      </div>
      <div role="tabpanel" hidden={1 !== tabSelected}>
        <BankTransfersSearcher />
      </div>
    </ContainerFenix>
  );
}

BankTransfersManagmentPage.propTypes = {};

export default BankTransfersManagmentPage;
