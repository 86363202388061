import axios from "axios";
// import sleep from "@lib/helpers/sleep";

export class PaymentsToSuppliersApi {
  static async getPendingInfoFromSuppliers() {
    // (`😴 Leyendo informacion de proveedores`);

    const url = "/api/v1/booking-bc/accounting/supplier-invoice/pending-summary";
    const { data, status } = await axios.get(url);
    if (status !== 200 || data === null) {
      throw new Error("getPendingInfoFromSuppliers: Not valid response");
    }
    return data;
  }

  static async getPendingInvoicesOfSupplier(supplierCode) {
    // (`😴 Leyendo las facturas de ${supplierCode}`);

    const url = `/api/v1/booking-bc/accounting/supplier-invoice/pending-summary/${supplierCode}`;
    const { data, status } = await axios.get(url);
    if (status !== 200 || data === null) {
      throw new Error("getPendingInfoFromSuppliers: Not valid response");
    }

    if (data.length === 0) {
      return [];
    }

    const [{ invoices }] = data;
    if (!invoices) {
      return [];
    }
    return invoices;
  }

  static async getRemittanceOfUser(userEmail) {
    // (`🥶 Leyendo remesa para el usuario: ${userEmail}`);

    const url = "/api/v1/booking-bc/suppliers/temporal-remittance";
    const { data, status } = await axios.get(url, { params: { createdBy: userEmail } });
    if (status !== 200 || data === null) {
      throw new Error("getRemittanceOfUser: Not valid response");
    }

    return data;
  }

  static async getRemittanceByID(remittanceID) {
    // (`🥶 Leyendo remesa para el ID: ${remittanceID}`);

    const url = `/api/v1/booking-bc/suppliers/temporal-remittance/${remittanceID}`;
    const { data, status } = await axios.get(url);
    // ("🚀 ~ PaymentsToSuppliersApi ~ getRemittanceByID ~ data:", data);
    if (status !== 200 || data === null) {
      throw new Error("getRemittanceByID: Not valid response");
    }
    // TODO: controlar errores, dependiendo de si es
    // 500, 400
    // 404 -> no existe = nuevo

    return data;
  }

  static async createRemittance(newRemittance) {
    // (`🥶 Creando remesa NUEVA para ${newRemittance.createdBy}`);

    const url = `/api/v1/booking-bc/suppliers/temporal-remittance/${newRemittance.id}`;
    const { data, status } = await axios.put(url, newRemittance);
    if (status !== 201 || data === null) {
      throw new Error("createRemittance: Not valid response");
    }

    return data;
  }

  static async addInvoicesToRemittance(remittanceID, invoices) {
    // (`🥶 Añadiendo ${invoices.length} facturas a remesa ${remittanceID}`);

    const url = `/api/v1/booking-bc/suppliers/temporal-remittance/${remittanceID}/addInvoices`;
    const { data, status } = await axios.post(url, { id: remittanceID, invoices });
    if (status !== 201 || data === null) {
      throw new Error("createRemittance: Not valid response");
    }

    return data;
  }

  static async removeInvoicesFromRemittance(remittanceID, invoicesToDelete) {
    // (`🥶 Quitando ${invoicesToDelete.length} facturas a remesa ${remittanceID}`);

    const url = `/api/v1/booking-bc/suppliers/temporal-remittance/${remittanceID}/removeInvoices`;
    const { data, status } = await axios.post(url, { id: remittanceID, invoices: invoicesToDelete });
    if (status !== 201 || data === null) {
      throw new Error("removeInvoicesFromRemittance: Not valid response");
    }

    return data;
  }

  static async confirmRemittance(remittance) {
    // (`🥶 Confirmar remesa ${remittance.id}`);

    const url = `/api/v1/booking-bc/suppliers/remittance/${remittance.id}`;
    const { data, status } = await axios.put(url, {
      user: remittance.createdBy,
      idTemporalRemittance: remittance.id,
    });
    if (status !== 200 || data === null) {
      throw new Error("confirmRemittance: Not valid response");
    }

    return data;
  }
}

export default PaymentsToSuppliersApi;
