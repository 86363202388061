import styled from "styled-components";
import { ChipDelete } from "@atoms/Content/Chips";
import { Alert } from "react-bootstrap";

export const ChipDeleteService = styled(ChipDelete)`
  color: #1bc5bd;

  &:hover {
    color: #06a29b;
  }
`;

export const TitleListService = styled.h6`
  position: relative;
  font-weight: bold;
  padding: 5px 10px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0.1rem;
    width: 4px;
    height: 100%;
    background-color: #1bc5bd;
  }
`;

export const AlertNoAccommodation = styled(Alert)`
  margin-bottom: 0 !important;
  color: #0d3c61;
  background-color: #e8f4fd;
  border-color: #e8f4fd;
`;
