import React, { useState, useEffect } from "react";

export const useFenixTablePagination = (active, maxNumberPerPages, tableData) => {
  const [limit, setLimit] = useState({
    indexI: 0,
    indexF: active ? maxNumberPerPages : tableData.length,
    pageActive: 1,
    pages: 1,
    llength: tableData.length,
  });

  useEffect(() => {
    //console.log("Cambia la tabla....", limit);

    if (tableData.length !== limit.llength) {
      const nuLimit = { ...limit };
      nuLimit.indexI = 0;
      nuLimit.pages = tableData.length > 0 ? Math.ceil(tableData.length / maxNumberPerPages) : 1;
      nuLimit.pageActive = 1;
      if (active) {
        nuLimit.indexF = maxNumberPerPages;
      } else {
        nuLimit.indexF = tableData.length;
      }
      nuLimit.llength = tableData.length;
      setLimit(nuLimit);
    }
  }, [tableData]);

  const onPageChangeByOne = (action) => {
    if (action === "less") {
      goToPage(limit.pageActive - 1);
    } else {
      goToPage(limit.pageActive + 1);
    }
  };

  const goToPage = (pageToGo) => {
    const nuLimit = { ...limit };
    const nuEndIndex = maxNumberPerPages * pageToGo;
    nuLimit.indexI = nuEndIndex - maxNumberPerPages;
    nuLimit.indexF = nuEndIndex;
    nuLimit.pageActive = pageToGo;

    setLimit(nuLimit);
  };

  return { limit, onPageChangeByOne };
};
