import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";

const FxChip = ({ label, onDelete, ...props }) => {
  const chipProps = { ...props };
  if (onDelete !== undefined) {
    chipProps.onDelete = onDelete;
  }
  if (chipProps.color === undefined) {
    chipProps.color = "primary";
  }

  return <LabelChip label={label} {...chipProps} />;
};

const LabelChip = styled(Chip)`
  color: white !important;
  margin: 0 3px 3px 0;
  background-color: ${(props) => `${props.colour} !important` || "grey"};
  color: ${(props) => `${props.colourtext} !important` || "grey"};
`;

FxChip.propTypes = {};

export default FxChip;
