/* eslint-disable no-undef */
import { useState, useEffect, useCallback } from "react";
import { fetchPrecinctChannels } from "../Precincts/usePrecincts";
import { getChannelID } from "../shared/domainVariables";

const useCalendarChannels = (precinctCode) => {
  const keyLS = `chanelFor${precinctCode}`;
  const storageChannel = sessionStorage.getItem(keyLS);

  const [channel, setChannel] = useState();
  const [channels, setChannels] = useState([]);
  const [loadingChannels, setLoadingChannels] = useState(true);

  const getPrecintChannels = useCallback(async () => {
    setLoadingChannels(true);
    const precinctChannelsFound = await fetchPrecinctChannels(precinctCode);
    const newChannelList = precinctChannelsFound.map((ch) => ({ ...ch.channel }));

    const nuChannelSelected =
      storageChannel === null
        ? newChannelList[0]
        : newChannelList.find((c) => getChannelID(c) === storageChannel);

    setChannels(newChannelList);
    setChannel(nuChannelSelected);
    setLoadingChannels(false);
  }, [precinctCode, storageChannel]);

  useEffect(() => {
    getPrecintChannels();
  }, [precinctCode, getPrecintChannels]);

  function onChangeChannel(event) {
    setChannel(event.target.value);
    sessionStorage.setItem(keyLS, getChannelID(event.target.value));
  }

  return { loadingChannels, channels, channel, onChangeChannel };
};

export default useCalendarChannels;
