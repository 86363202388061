import React from "react";
import { Grid } from "@material-ui/core";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

function ChannelPageTemplate(props) {
  return (
    <ContainerFenix>
      <Grid container>
        <Grid item xs={12}>
          {props.children}
          <div style={{ marginBottom: "40px" }}></div>
        </Grid>
      </Grid>
    </ContainerFenix>
  );
}

ChannelPageTemplate.propTypes = {};

export default ChannelPageTemplate;
