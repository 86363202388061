import { saveProduct } from "./useProducts";

const saveProductList = async (fullList) => {
  let saved = 0;
  const errors = [];
  for (let index = 0; index < fullList.length; index += 1) {
    const product = fullList[index];
    try {
      await saveProduct(product);
      saved += 1;
      // console.log("Saved", product);
    } catch (e) {
      errors.push(
        `Error en el codigo ${product.code}: ${e.response.data.errors
          .reduce((acc, cv) => {
            acc.push(cv.error);
            return acc;
          }, [])
          .join(",")}`
      );
    }
  }

  return { saved, errors };
};

export default saveProductList;
