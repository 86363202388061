import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { Breadcrumbs } from "@material-ui/core";
import { usePath } from "../../FileManagerContext";
import HomeIcon from "@material-ui/icons/Home";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    marginBottom: "20px",
  },
}));

const FileBreadcrumbs = ({}) => {
  const classes = useStyles();
  const rootKeyword = "%#%ROOT%#%";
  const [{ path }, setPath] = usePath();

  let breadcrumbsList = path.split("/").filter((str) => /\S/.test(str));
  breadcrumbsList.unshift(rootKeyword);

  const clickBreadcrumb = (indexBc) => {
    const nuPath = `${breadcrumbsList.slice(0, indexBc).join("/")}/${breadcrumbsList[indexBc]}`.replace(
      rootKeyword,
      ""
    );
    setPath(nuPath);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbsList.map((breadcrumb, index) => {
          return (
            <Link
              key={`breadcrumb${index}`}
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => clickBreadcrumb(index)}
            >
              {breadcrumb === rootKeyword ? <HomeIcon /> : breadcrumb}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Paper>
  );
};

export default FileBreadcrumbs;
