import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
// import useInterval from "@lib/hooks/useInterval";
import { useJsonPage, upsertJsonPage, publishJsonPage, useJsonPageDispatch } from "../hooks/useJsonPages";
// eslint-disable-next-line import/named
import {
  parsedResponseInForm,
  getFormEmpty,
  parsedFormInJsonPage,
  openInNewTab,
  parsedFormInJsonPagePublish,
  prettyJson,
} from "../utility/utility";

function useJsonPageEditor() {
  const { tld, id } = useParams();
  const history = useHistory();

  const { loading, jsonPage, ok } = useJsonPage(id);
  const { loadingDispatch, jsonPageDispatch } = useJsonPageDispatch(id);
  const [form, setForm] = useState(getFormEmpty(id, tld));
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [infoModal, setInfoModal] = useState({ show: false, text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });

  useEffect(() => {
    if (!loading) {
      if (ok) {
        setForm(parsedResponseInForm(jsonPage, id, tld));
      }
    }
    if (errors.list.length > 0) {
      setErrors({ show: true, list: [errors.list] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonPage, jsonPageDispatch]);

  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };

  const backToTable = () => {
    history.push(`/app/content/json-pages`);
  };

  const checkFormErrors = () => {
    const nuErrors = [];
    if (!form.name) {
      nuErrors.push("Falta un nombre válido.");
    }
    if (form.name.length === 0) {
      nuErrors.push("El nombre no puede estar vacío.");
    }
    if (!form.path) {
      nuErrors.push("Falta una dirección válida.");
    }
    if (form.path.length === 0) {
      nuErrors.push("La dirección no puede estar vacía.");
    }
    if (!form.title) {
      nuErrors.push("Falta un titulo válido.");
    }
    if (form.title.length === 0) {
      nuErrors.push("El titulo no puede estar vacío.");
    }
    if (!form.description) {
      nuErrors.push("Falta una descripción válida.");
    }
    if (form.description.length === 0) {
      nuErrors.push("La descripción no puede estar vacía.");
    }

    try {
      prettyJson(form.json);
    } catch (errorParsed) {
      nuErrors.push("El JSON esta mal formado.");
    }
    return nuErrors;
  };

  const saveJsonPage = async () => {
    const responseFromSaving = await upsertJsonPage(parsedFormInJsonPage(form));
    if (!responseFromSaving.ok) {
      setErrors({
        show: true,
        list: [`No se ha podido guardar por un error al enviar: ${responseFromSaving.message}`],
      });
    } else {
      handleAlertSnackBar(true, "Guardada correctamente.");
      // backToTable();
    }
  };

  const handleSaveJsonPage = async () => {
    const nuErrors = checkFormErrors();
    if (nuErrors.length > 0) {
      setErrors({ show: true, list: nuErrors });
    } else {
      setConfirmationDialog({
        open: true,
        text: `Advertencia`,
        body: "Esta acción no puede revertirse. ¿Seguro que desea guardar los cambios?",
        onConfirm: () => {
          saveJsonPage();
        },
      });
    }
  };

  const publish = async () => {
    const jsonPageFormated = parsedFormInJsonPagePublish(form);
    const responseFromPublish = await publishJsonPage(jsonPageFormated.id);
    if (!responseFromPublish.ok) {
      setErrors({
        show: true,
        list: [`No se ha podido publicar por un error al enviar: ${responseFromPublish.message}`],
      });
    } else {
      handleAlertSnackBar(true, "Publicada correctamente.");
      // backToTable();
    }
  };

  const handlePublishJsonPage = async () => {
    const nuErrors = checkFormErrors();
    if (nuErrors.length > 0) {
      setErrors({ show: true, list: nuErrors });
    } else {
      setConfirmationDialog({
        open: true,
        text: `Advertencia`,
        body: "Esta acción no puede revertirse. ¿Seguro que desea Publicar los cambios?.",
        onConfirm: () => {
          publish();
        },
      });
    }
  };

  const restorelLastSavedVersion = async () => {
    if (!loading) {
      setForm(parsedResponseInForm(jsonPage, jsonPage.id, jsonPage.tld));
    }
  };

  const restorelLastPublisedVersion = async () => {
    if (!loadingDispatch) {
      setForm(
        parsedResponseInForm(jsonPageDispatch.page, jsonPageDispatch.page.id, jsonPageDispatch.page.tld)
      );
      setInfoModal({
        show: true,
        text: "Te mostramos la última versión publicada y si quieres quedarte con ella dale a guardar.",
      });
    }
  };

  const onChangeForm = async (newValue) => {
    setForm(newValue);
  };

  const closeErrorModal = () => setErrors({ show: false, list: [] });
  const closeInfoModal = () => setInfoModal({ show: false, text: "" });

  return {
    backToTable,
    handleSaveJsonPage,
    handlePublishJsonPage,
    onChangeForm,
    alertOpen,
    setAlertOpen,
    alertData,
    closeErrorModal,
    form,
    errors,
    loading,
    openInNewTab,
    loadingDispatch,
    jsonPageDispatch,
    restorelLastSavedVersion,
    restorelLastPublisedVersion,
    confirmationDialog,
    setConfirmationDialog,
    infoModal,
    closeInfoModal,
    setErrors,
    setInfoModal,
    ok,
    tld,
  };
}

export default useJsonPageEditor;
