import styled from "styled-components";
import { Row, Button, Alert } from "react-bootstrap";
import { MdCancel } from "react-icons/md";
import { MdDeleteForever, MdCheckCircle } from "react-icons/md";

export const DivCenter = styled.div`
  margin-top: 10px;
`;

export const ButtonOK = styled(MdCheckCircle)`
  background-color: lime;
  border-radius: 50%;
  font-size: 30px;
`;

export const ButtonDelete = styled(MdDeleteForever)`
  background-color: red;
  border-radius: 50%;
  font-size: 30px;
`;
