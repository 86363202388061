import axios from "axios";
import React, { useState, useEffect } from "react";

const URL = "/api/v1/product-bc/files-manager";

export const getFolder = (bucket, path = null) => {
  const [FData, setFData] = useState({ loading: true, data: {}, path: path });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, data: {}, path: path };
        const response = await axios.get(
          path !== null ? `${URL}/${bucket}/folders/${path.replace("/", "%2F")}` : `${URL}/${bucket}/folders`
        );
        if (response.status === 200) {
          thestate = { loading: false, data: response.data, path: path };
        }
        setFData(thestate);
      } catch (error) {
        setFData({ loading: false, data: {}, path: path });
        console.error(error);
      }
    };

    if (FData.loading) {
      fetchData();
    }
  }, [FData.loading, path, bucket]);
  return { loading: FData.loading, data: FData.data, path: FData.path };
};

export const getFolders = (bucket, criteria) => {
  let thestateFinal = { loading: true, data: [], path: null, error: null };

  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { loading: true, data: [], path: null, error: null };
      const response = await axios.get(`${URL}/${bucket}/folders?${criteria}&_sort=path`);
      if (response.status === 200) {
        thestate = { loading: false, data: response.data.data, path: null, error: null };
      }
      thestateFinal = { ...thestate };
    } catch (error) {
      thestateFinal = { loading: false, data: [], path: null, error: error.response.data };
      console.error(error);
    }
    return thestateFinal;
  };

  if (thestateFinal.loading) {
    return (thestateFinal = fetchData(thestateFinal));
  }
  return thestateFinal;
};

export const MoveFolders = async (path, newPath, bucket) => {
  let response = {};
  try {
    response = await axios.get(
      `${URL}/${bucket}/folders/${path.replaceAll("/", "%2F")}/move/${newPath.replaceAll("/", "%2F")}`
    );
  } catch (error) {
    response = { status: 400 };
    console.error(error);
  }
  return response;
};

export const putFolder = async (folder, bucket) => {
  let response = {};
  try {
    const pathModify = folder.path.replaceAll("/", "%2F");
    response = await axios.put(`${URL}/${bucket}/folders/${pathModify}`, folder);
  } catch (error) {
    response = { status: 400 };
    console.error(error);
  }
  return response;
};

export const deleteFolder = async (bucket, path) => {
  let response = {};
  try {
    const pathModify = path.replaceAll("/", "%2F");
    response = await axios.delete(`${URL}/${bucket}/folders/${pathModify}`);
  } catch (error) {
    response = { status: 400 };
    console.error(error);
  }
  return response;
};
