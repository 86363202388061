import React, { useState, Fragment, useEffect } from "react";
import { BiCrop } from "react-icons/bi";
import { ModuleCrop } from "./ModuleCrop";
import ButtonCancel from "@atoms/Buttons/ButtonCancel";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { usePath } from "../../../FileManagerContext";
import { sanitizeName } from "../../../shared/sanitize";
import { createFileInFolder } from "../../../hooks/useFileFetch";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "@atoms/Modals/ErrorModal";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import "./styleCrop.css";

const FORMAT_IMG_VALID = {
  JPEG: "jpeg",
  JPG: "jpg",
  PNG: "png",
  BMP: "bmp",
};

const FORMAT_IMG_SPECIAL = {
  SVG: "svg",
  GIF: "gif",
};

const ModalCrop = ({ show, onChangeCropModal, filesInFolder }) => {
  const [nameFile, setNameFile] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [image, setImage] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [cropData, setCropData] = useState("#");
  const [openCrop, setOpenCrop] = useState(false);
  const [{ path, bucket }] = usePath();
  const [aspectRatio, setAspectRatio] = useState({ name: "3/2", ratio: 3 / 2 });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({ text: "" });
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [dataFiles, setDataFiles] = useState([]);
  const [totalImg, setTotalImg] = useState({ total: 0, index: -1 });

  useEffect(() => {
    setLoadingSubmit(false);
  }, [show]);

  useEffect(() => {
    if (dataFiles.length > 0) {
      const countImg = totalImg;
      countImg.index = totalImg.index + 1;
      setTotalImg(countImg);
      setNameFile(dataFiles[0].name);
      readFile(dataFiles[0].file, dataFiles[0].type);
    } else {
      setTotalImg({ total: 0, index: -1 });
      setLoadingSubmit(false);
      handleCloseWithReset();
    }
  }, [dataFiles]);

  const handleCropData = (e) => {
    setCropper(e);
  };

  const handleClose = () => {
    onChangeCropModal(false);
    setOpenCrop(false);
  };

  const handleCloseWithReset = () => {
    onChangeCropModal(false);
    setOpenCrop(false);
    resetCrop();
  };

  const handleCloseLessCrop = () => {
    onChangeCropModal(false);
  };

  const handleShow = () => {
    onChangeCropModal(true);
  };

  const resetCrop = () => {
    setCropper(null);
    setImage(null);
    setCropData("#");
    setNameFile("");
    setLoadingSubmit(false);
    setAspectRatio({ name: "3/2", ratio: 3 / 2 });
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const name = files[0].name;
    const type = name.split(".").pop().toUpperCase();
    const cleanName = sanitizeName(name);

    if (fileExists(cleanName)) {
      setErrors({
        show: true,
        list: [`Ya existe un archivo con este nombre (${cleanName}) en este directorio`],
      });
      handleCloseWithReset();
    } else if (!Object.keys(FORMAT_IMG_VALID).concat(Object.keys(FORMAT_IMG_SPECIAL)).includes(type)) {
      setErrors({
        show: true,
        list: [`Formato "${type}" no soportado.`],
      });
      handleCloseWithReset();
    } else {
      setNameFile(cleanName);
      readFile(files[0], type);
    }
  };

  const fileExists = (cleanName) => {
    const newFileName = `${path}${path === "/" ? "" : "/"}${cleanName}`;
    const previousFileInPath = filesInFolder.find((file) => file.path === newFileName);
    return previousFileInPath !== undefined;
  };

  const onChangeMultipleFiles = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const fileNames = Object.keys(files).reduce(
      (acc, key, i) => {
        const name = files[key].name;
        const type = name.split(".").pop();
        if (
          Object.keys(FORMAT_IMG_VALID).concat(Object.keys(FORMAT_IMG_SPECIAL)).includes(type.toUpperCase())
        ) {
          acc.list.push({ file: files[key], name: sanitizeName(name), type });
        } else {
          acc.errors.push(`La imagen "${name}" tiene un formato incorrecto.`);
        }
        return acc;
      },
      { errors: [], list: [] }
    );
    if (fileNames.errors.length > 0) {
      setErrors({
        show: true,
        list: fileNames.errors,
      });
    } else {
      const countImg = totalImg;
      countImg.total = fileNames.list.length;
      setTotalImg(countImg);
      setDataFiles(fileNames.list);
    }
  };

  const nextMultipleFile = () => {
    dataFiles.shift();
    setDataFiles([...dataFiles]);
  };

  async function readFile(file, type) {
    let directSubmit = false;
    let reader = new FileReader();
    const img = new Image();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      img.src = e.target.result;
      setImage(reader.result);
    };
    reader.onerror = function () {
      setErrors({
        show: true,
        list: [`Error al leer la imagen.` /*, JSON.stringify(reader.error)*/],
      });
    };
    let promise = new Promise((resolve) => {
      img.onload = () => resolve({ w: img.width, h: img.height });
    });
    let result = await promise;

    if (bucket === "sprites") {
      directSubmit = true;
    } else if (Object.keys(FORMAT_IMG_SPECIAL).includes(type.toUpperCase())) {
      directSubmit = true;
    } else if (result.w / result.h < 1.3 || result.w / result.h > 1.7) {
      setImage(reader.result);
      setOpenCrop(true);
      directSubmit = false;
    } else {
      directSubmit = true;
    }

    if (directSubmit) {
      await submitWithFormData(file);
    }
  }

  async function submitWithFormData(file) {
    setLoadingSubmit(true);
    const formData = new FormData();
    const saniName = sanitizeName(file.name);
    formData.append("file", file, saniName);
    const response = await createFileInFolder(formData, path, bucket, saniName);
    if (response.status !== 201) {
      setErrors({
        show: true,
        list: [`Error al subir la imagen  ${saniName}`, JSON.stringify(response.error)],
      });
    } else {
      setAlertOpen(true);
      setAlertData({ text: `Imagen subida con éxito` });
      if (dataFiles.length > 0) {
        nextMultipleFile();
      } else {
        handleCloseWithReset();
      }
    }
  }

  //funcion para obtener el blob
  function getBlob(cropper) {
    return new Promise((resolve) => {
      cropper.getCroppedCanvas().toBlob(async (blob) => {
        resolve(blob);
      });
    });
  }

  async function getCropData(submit = false) {
    if (!openCrop) {
      setOpenCrop(true);
    }
    if (typeof cropper !== "undefined" && cropper !== null) {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      if (submit) {
        const blob = await getBlob(cropper);
        const formData = new FormData();
        formData.append("file", blob, nameFile);
        const response = await createFileInFolder(formData, path, bucket, nameFile);
        if (response.status !== 201) {
          setErrors({
            show: true,
            list: [`Error al subir la imagen  ${nameFile}`, JSON.stringify(response.error)],
          });
        } else {
          if (dataFiles.length > 1) {
            nextMultipleFile();
            handleClose();
            setTimeout(function () {
              handleShow();
            }, 200);
          } else {
            handleCloseWithReset();
            setAlertOpen(true);
            setAlertData({ text: `Imagen subida con éxito` });
          }
        }
      }
    }
  }

  const handleChangeAspectRatio = (e) => {
    const arrAux = e.target.value.split("/");
    setAspectRatio({ name: e.target.value, ratio: arrAux[0] / arrAux[1] });
    handleCloseLessCrop();
    setTimeout(function () {
      handleShow();
    }, 200);
  };

  const resetFilesInCrop = () => {
    setDataFiles([]);
  };

  return (
    <Fragment>
      <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => {
          setErrors({ show: false, list: [] });
        }}
      />

      <Modal class={"modal-fade2"} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Subida y recorte de imagenes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModuleCrop
            imageRemain={dataFiles.length}
            image={image}
            openCrop={openCrop}
            cropData={cropData}
            cropper={cropper}
            aspectRatio={aspectRatio}
            onChange={onChange}
            onChangeMultipleFiles={onChangeMultipleFiles}
            getCropData={() => getCropData()}
            handleChangeAspectRatio={handleChangeAspectRatio}
            handleCropData={handleCropData}
            loadingSubmit={loadingSubmit}
            formatValid={Object.keys(FORMAT_IMG_VALID).concat(Object.keys(FORMAT_IMG_SPECIAL))}
          />
          {totalImg.total > 0 && (
            <div class="divCenterWithMarginTop">
              Imagenes subidas {totalImg.index} de {totalImg.total}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex", gap: "5px" }}>
            <ButtonCancel
              size="small"
              onClick={(e) => {
                handleClose();
                resetCrop();
                resetFilesInCrop();
              }}
            >
              Cancelar
            </ButtonCancel>
            <ButtonCustom
              disabled={image === null ? true : false}
              color="primary"
              size="small"
              icon={<BiCrop />}
              onClick={async (e) => {
                setOpenCrop(false);
                await getCropData(true);
              }}
            >
              Cortar y Guardar
            </ButtonCustom>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ModalCrop;
