import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import EditIcon from "@material-ui/icons/Edit";

function ButtonEdit({ size, variant, disabled, onClick, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="Edit"
      icon={<EditIcon />}
      customcolor="blue"
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonEdit;
