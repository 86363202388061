import React from "react";
import { sanitizeTextQuery } from "@lib/helpers/string";
import PropTypes from "prop-types";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const ResultListLine = styled(ListItem)`
  padding-left: ${(props) => (props.padding ? " calc(16px + 16px)!important" : "")};
`;
const IconResultsLine = styled.span`
  font-size: 20px;
  margin-right: 10px;
`;

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "0",
    paddingBottom: "0",
  },
}));

const SearchQueryResultsLine = ({ element, query, highlight, onSelect }) => {
  const padding = element.level && element.level > 0 ? 16 * element.level : 0;
  const classes = useStyles();
  const { beforeText, coloredText, afterText } = getTextParts(element.text, query);
  return (
    <List className={classes.root}>
      <ResultListLine
        button
        style={{ backgroundColor: highlight ? "rgba(0, 0, 0, 0.04)" : "" }}
        padding={padding}
        onClick={onSelect}
      >
        {/* Icono */}
        {element.icon && (
          <>
            {typeof element.icon === "string" && <IconResultsLine className={element.icon} />}
            {typeof element.icon !== "string" && element.icon}
          </>
        )}

        <div>
          {/* linea principal*/}
          <div>
            {beforeText}
            {coloredText && coloredText !== "" && <span style={{ fontWeight: "bold" }}>{coloredText}</span>}
            {afterText}
          </div>
          {/* Segunda linea */}
          {element.subtext && <div style={{ fontSize: "0.75rem" }}>{element.subtext}</div>}
        </div>
      </ResultListLine>
    </List>
  );
};

const getTextParts = (text, query) => {
  let sanitizedQuery = sanitizeTextQuery(query);
  let sanitizedText = sanitizeTextQuery(text);
  let beforeText = text;
  let coloredText = "";
  let afterText = "";
  if (sanitizedQuery !== "") {
    const queryRegex = new RegExp(sanitizedQuery, "i");
    const textExist = sanitizedText.match(queryRegex);
    if (textExist !== null) {
      const [start, end] = [textExist.index, textExist.index + textExist["0"].length - 1];
      if (end > start) {
        beforeText = start > 0 ? text.substr(0, start) : "";
        coloredText = text.substr(start, end - start + 1);
        afterText = end < text.length ? text.substr(end + 1, text.length - (end + 1)) : "";
      }
    }
  }
  return { beforeText, coloredText, afterText };
};

SearchQueryResultsLine.propTypes = {
  /**
   * color fondo para la tabulacion
   */
  highlight: PropTypes.bool,

  /**
   * On select in shape
   */
  onSelect: PropTypes.func,
};

SearchQueryResultsLine.defaultProps = { query: "", highlight: false };

export default SearchQueryResultsLine;
