import { TYPES_ACTIONS, TYPES_ALERT } from "./types";

export const addNewField = (actions) => {
  return actions.map((cv) => {
    cv.idShort = cv.id.split("-")[4];
    cv.typeTranslated = TYPES_ACTIONS[cv.type];
    cv.actionAlertState = cv.alert !== null ? true : false;
    cv.forCommentWithBookCSV = cv.forCommentWithBook ? 1 : 0;
    cv.alertEnable = cv.alert ? 1 : 0;
    cv.alertLevel = cv.alert ? TYPES_ALERT[cv.alert["level"]] : "";
    cv.alertMessage = cv.alert ? cv.alert["message"] : "";
    if (cv.parentId) {
      cv.parentIdShort = cv.parentId.split("-")[4];
    }
    return cv;
  });
};

export const sortActionsByParent = (actions) => {
  let actionsParent = [];
  let actionsChild = [];
  Object.keys(actions).map((action, i) => {
    if (!action.parentId) {
      actionsParent.push(actions[i]);
    } else {
      nuActionsChild.push(actions[i]);
    }
  });
  const nuActions = actionsParent.concat(actionsChild);
  return nuActions.reduce((acc, action, i) => {
    if (action.parentId) {
      const indexParent = getIndexParent(actions, action.parentId);
      acc.splice(indexParent + 1, 0, actions[i]);
    } else {
      acc.push(action);
    }
    return acc;
  }, []);
};

export const getIndexParent = (list, idActionToSearch) => {
  let indexParent;
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === idActionToSearch) {
      indexParent = i;
    }
  }
  return indexParent;
};
