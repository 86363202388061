import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SectionTitle from "@atoms/Titles/SectionTitle";
import FenixTable from "@molecules/Tables/FenixTable";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PrecinctsSectionMenu from "./PrecinctsSectionMenu";
import { fetchPrecincts } from "./usePrecincts";
import UploadResultAlert from "../shared/UploadResultAlert";
import { precinctTypesTranslation } from "./constants";

function PrecinctsSection() {
  const history = useHistory();
  const [loadingTable, setLoadingTable] = useState(true);
  const [precinctsList, setPrecinctsList] = useState([]);
  const [uploadResult, setUploadResult] = useState();

  async function fetchPrecintsList() {
    setLoadingTable(true);
    const precincts = await fetchPrecincts();
    setPrecinctsList(precincts);
    setLoadingTable(false);
  }

  useEffect(() => {
    fetchPrecintsList();
  }, [uploadResult]);

  const actionViewPrecinct = (rowData) => {
    history.push(`${history.location.pathname}/${rowData.code}`);
  };

  const tableActionsPrecinct = [{ name: "Ver Recinto", icon: <VisibilityIcon />, func: actionViewPrecinct }];

  const tableHead = [
    { code: "code", name: "Código", type: "string" },
    { code: "name.es-ES", name: "Nombre (es)", type: "string" },
    { code: "countryCode", name: "País", type: "string", filterable: true },
    {
      code: "type",
      name: "Categoria",
      type: "label",
      translation: precinctTypesTranslation,
      filterable: true,
    },
    { code: "logo", name: "Logo", type: "image" },
  ];

  const onUploadEnded = (resultUpload) => {
    let status = "success";
    if (resultUpload.errors.length > 0) {
      status = resultUpload.saved && resultUpload.saved > 0 ? "warning" : "error";
    }

    setUploadResult({ status, ...resultUpload });
  };

  return (
    <>
      <SectionTitle
        title={`Recintos de ticketing`}
        info={[
          "Recinto es -generalmente- el lugar que un cliente va a visitar y donde se realiza la actividad que adquiere. Recintos típicos son parques temáticos, parques de animales, estaciones de esquí, balnearios,... aunque tambien se consideran recintos a actividades más itinerantes como rutas, excursiones o autobuses turisticos.",
        ]}
        buttons={[]}
      />
      <PrecinctsSectionMenu precinctsList={precinctsList} onUploadEnded={onUploadEnded} />

      {uploadResult && <UploadResultAlert uploadResult={uploadResult} />}

      <FenixTable
        loading={loadingTable}
        pagination={true}
        tableHead={tableHead}
        tableContent={precinctsList}
        actions={tableActionsPrecinct}
      />
    </>
  );
}

PrecinctsSection.propTypes = {};

export default PrecinctsSection;
