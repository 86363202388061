import React, { useEffect } from "react";
import "./Fenix.css";
import "moment/locale/es";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter, Switch, Route, Redirect, Link } from "react-router-dom";
import UiProvider from "./layout/UiProvider";
import Routes from "@pages/Routes.js";
import { FenixAuthContextProvider } from "./auth/FenixAuthContext";
import { isAuthorized } from "./auth/FenixAuthContext";
import UserAuth from "./auth/UserAuth";
import AgentPanel from "../../pages/agentPanel/panel/AgentPanel";

const throttle = (callback, delay) => {
  let timeout;
  return (...args) => {
    if (timeout !== undefined) {
      return;
    }

    timeout = setTimeout(() => {
      timeout = undefined;
    }, delay);

    return callback(...args);
  };
};

const Fenix = () => {
  return (
    <FenixAuthContextProvider>
      <UiProvider>
        <DndProvider backend={HTML5Backend}>
          <FenixRouter />
        </DndProvider>
      </UiProvider>
    </FenixAuthContextProvider>
  );
};

function FenixRouter() {
  const isAuth = isAuthorized();

  const sendMessageThrottled = throttle(() => {
    console.log("send message to parent x-tr-fenix-interaction");
    window.top.postMessage({ "x-tr-fenix-interaction": "1" }, "*");
  }, 5000);

  function sendMessage() {
    sendMessageThrottled();
  }

  useEffect(() => {
    let lastSize = 0;
    function size() {
      const body = document.body,
        html = document.documentElement;

      let height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      if (height < 1000) height = 1000;
      if (lastSize !== height) {
        lastSize = height;
        console.log("send message to parent x-tr-fenix-height", lastSize);
        window.top.postMessage({ "x-tr-fenix-height": lastSize }, "*");
      }
    }

    const interval = setInterval(function () {
      size();
    }, 200);

    const events = ["mousemove", "mousedown", "click", "scroll", "keypress"];
    events.forEach((event) => {
      window.addEventListener(event, sendMessage);
    });

    return () => {
      clearInterval(interval);
      const eventsB = ["mousemove", "mousedown", "click", "scroll", "keypress"];
      eventsB.forEach((event) => {
        window.removeEventListener(event, sendMessage);
      });
    };
  }, []);
  console.log("Recargo fenix router....", isAuth);

  return (
    <BrowserRouter>
      <UserAuth>
        <Switch>
          <Route exact path={`/users/agentPanel`} component={AgentPanel} />
          <Routes />
        </Switch>
      </UserAuth>
    </BrowserRouter>
  );
}

export default Fenix;
