export const wordcontrol = "suppliers_hyperguest_v1";
export const filename = "suppliers_hyperguest";

export const csvHead = ["Id", "Codigo", "Supplier", "Api"];

export const csvSpecs = {
  id: "",
  code: "",
  supplier: "",
  api: "",
};

export const contentFields = {
  id: "lang",
  code: "lang",
  supplier: "lang",
  api: "lang",
};
