import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFenixAuthContext } from "./FenixAuthContext";
import LoadingFrame from "../components/LoadingFrame/LoadingFrame";
import { isLogged, getUserProfile } from "./authStorage";

const UserAuth = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [state, dispatch] = useFenixAuthContext();
  const [inAuth, setInAuth] = useState(true);

  const isPublicLocation = () => {
    const publicLocations = ["/users/login", "/users/logout", "/users/login/redirect"];
    return publicLocations.includes(location.pathname);
  };

  /**
   * It checks if the user is logged in and if not, it redirects to the login page
   */
  useEffect(() => {
    if (inAuth && isPublicLocation()) {
      setInAuth(false);
      return;
    }
    if (inAuth && !state.isAuth) {
      if (isLogged()) {
        const userProfile = getUserProfile();
        dispatch({ type: "login", user: { profile: userProfile } });
        setInAuth(false);
      }
      //} else {
      //sessionStorage.setItem("entryUrl", location.pathname);
      //window.location = "/users/login";
      //}
    }
  }, []);

  if (inAuth) {
    return <LoadingFrame />;
  }
  return <>{children}</>;
};

export default UserAuth;
