import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import ButtonSearch from "@atoms/Buttons/ButtonSearch";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import FenixTable from "@molecules/Tables/FenixTable";
import { getBankData, searchBankTransfers } from "./useBankTransfers";

function BankTransfersSearcher() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchParams, setSearchParams] = useState({
    datei: moment().add(-1, "days").format("YYYY-MM-DD"),
    dateo: moment().format("YYYY-MM-DD"),
  });
  const [searchResults, setSearchResults] = useState([]);

  const onSearchBankTransfers = async () => {
    const banktransferlist = await searchBankTransfers(searchParams.datei, searchParams.dateo);
    if (banktransferlist.error) {
      setError(true);
    } else {
      setSearchResults(banktransferlist.banktransfers);
    }
  };

  const tableHead = [
    { code: "concept", name: "Concepto", type: "string" },
    { code: "payer", name: "Pagador", type: "string" },
    { code: "amount", name: "Cantidad", type: "money" },
    {
      code: "bank",
      name: "Banco",
      type: "label",
      translation: getBankData(),
      filterable: true,
    },
    { code: "date", name: "Fecha pago", type: "date" },
    { code: "processed_date", name: "Fecha procesado", type: "date" },
    { code: "bookref", name: "Reserva", type: "string" },
    { code: "assignedBy", name: "Asignada por", type: "string" },
    { code: "assignTs", name: "Fecha asignación", type: "date" },
  ];

  return (
    <>
      <Alert severity="info">
        En esta sección podrás buscar todas las transferencias procesadas por fecha de proceso.
      </Alert>
      <TravCard>
        <TravBodyCard>
          <div style={{ display: "flex" }}>
            <FxDatePickersFromTo
              labelFrom={"Desde"}
              dateFromInitial={searchParams.datei}
              labelTo={"Hasta"}
              dateToInitial={searchParams.dateo}
              onChange={({ datei, dateo }) => {
                setSearchParams({ datei, dateo });
              }}
            />
            <ButtonSearch onClick={onSearchBankTransfers} />
          </div>

          {loading && <CenterLoaderCircle />}

          {error && (
            <Alert severity="error">
              Ha occurido un error al hacer la solicitud de transferencias. Por favor, intentalo de nuevo más
              tarde.
            </Alert>
          )}

          {searchResults.length > 0 && (
            <FenixTable
              title={"Resultados de transferencias"}
              tableHead={tableHead}
              tableContent={searchResults}
            />
          )}
        </TravBodyCard>
      </TravCard>
    </>
  );
}

BankTransfersSearcher.propTypes = {};

export default BankTransfersSearcher;
