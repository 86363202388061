import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CrossSellingTicketsDraggableList from "./CrossSellingTicketsDraggableList";

const TicketList = ({ title, ticketArray, onChangeList, ticketsProducts }) => {
  return (
    <Fragment>
      <CrossSellingTicketsDraggableList
        title="CrossSellingTicketsDraggableList"
        ticketsProducts={ticketsProducts}
        list={ticketArray}
        editable={false}
        onChangeList={onChangeList}
      />
    </Fragment>
  );
};

TicketList.propTypes = {
  title: PropTypes.string,
  ticketArray: PropTypes.array,
  onChangeList: PropTypes.func,
};

export default TicketList;
