import axios from "axios";
import { getChannelID } from "../shared/domainVariables";

const prefix = "/api/v1/ticketing-bc/precincts";

export const fetchPrecincts = async (filters) => {
  // TODO: Filters, TBD

  const { data } = await axios.get(`${prefix}`, {});
  return data.data;
};

export const fetchPrecinct = async (precinctCode) => {
  const { data } = await axios.get(`${prefix}/${precinctCode}`, {});
  return data;
};

export const savePrecinct = async (precinct) => {
  await axios.put(`${prefix}/${precinct.code}`, precinct);
};

export const fetchAllPrecinctChannels = async () => {
  const precincts = await fetchPrecincts();
  const promises = precincts.map((precinct) => {
    const url = `${prefix}/${precinct.code}/channels`;
    return axios.get(url, {});
  });

  const responseTickets = await Promise.all(promises);
  return responseTickets
    .map((res) => {
      const { data } = res.data;
      const { precinctCode } = data[0];
      const precinct = precincts.find((p) => p.code === precinctCode);
      return data.map((cv) => {
        return { ...cv, countryCode: precinct.countryCode };
      });
    })
    .flat();
};

export const fetchPrecinctChannels = async (precinctCode) => {
  const url = `${prefix}/${precinctCode}/channels`;
  const { data } = await axios.get(url, {});
  return data.data;
};

export const savePrecinctChannel = async (precinctCode, channel, body) => {
  const url = `${prefix}/${precinctCode}/channels/${getChannelID(channel)}`;
  await axios.put(url, body);
};

export const deletePrecinctChannel = async (precinctCode, channel) => {
  const channelCode = [channel.provider];
  if (channel.merchant !== undefined) {
    channelCode.push(channel.merchant);
  }
  const url = `${prefix}/${precinctCode}/channels/${channelCode.join("-")}`;
  await axios.delete(url, {});
};
