import React, { Fragment, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  ButtonGroup,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import { MdSend } from "react-icons/md";
import { brickModes, brickStyles } from "../../constants";
import BrickQuerySearcher from "../BrickQuerySearcher";

function ModalNewBrick({ open, type = "brick", executeSelection }) {
  const [selection, setSelection] = useState({
    mode: brickModes[0].code,
    destination: brickStyles[0].code,
    template: "",
    tcode: "",
  });

  return (
    <Fragment>
      <Dialog open={open} keepMounted aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
        <DialogTitle>Creando {type}...</DialogTitle>
        <DialogContent>
          <Card>
            <Card.Header>Elige el modo para el {type}</Card.Header>
            <Card.Body>
              <ButtonGroup
                className="market-no-select-class"
                size="large"
                color="primary"
                aria-label="marketSelector"
              >
                {brickModes.map((mode) => {
                  return (
                    <Button
                      key={`mode_${mode.code}`}
                      disabled={!mode.status}
                      size="large"
                      color={mode.code === selection.mode ? "secondary" : "primary"}
                      startIcon={mode.icon}
                      onClick={() => {
                        setSelection({ ...selection, mode: mode.code });
                      }}
                    >
                      {mode.name}

                      {/* 
                  //Que puñetero eres Material...
                  {!mode.status && (
                    <Fragment>
                      <br />
                      <span>Proximamente...</span>
                    </Fragment>
                  )} */}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>Elige la web donde utilizaras este {type}</Card.Header>
            <Card.Body>
              <TextField
                select
                label="Web de destino"
                value={selection.destination}
                onChange={(e) => setSelection({ ...selection, destination: e.target.value })}
              >
                {brickStyles.map((sty, i) => {
                  return (
                    <MenuItem key={`styl${i}`} value={sty.code}>
                      {sty.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>¿Quieres usar un template como punto de partida? (opcional)</Card.Header>
            <Card.Body>
              <BrickQuerySearcher
                types={["templates"]}
                destinations={[selection.destination]}
                onSelect={(e) => setSelection({ ...selection, template: e.content.value, tcode: e.code })}
              />
              {selection.tcode && (
                <Chip
                  label={selection.tcode}
                  onDelete={() => setSelection({ ...selection, template: "", tcode: "" })}
                />
              )}
            </Card.Body>
          </Card>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={selection.mode === null}
            variant="contained"
            color="primary"
            startIcon={<MdSend />}
            onClick={() => executeSelection(selection)}
          >
            Crear
          </Button>
        </DialogActions>
      </Dialog>

      {/* <ErrorModal
        show={error.open}
        listErrors={error.errors}
        onClose={() => {
          setError({ open: false, errors: [] });
        }}
      /> */}
    </Fragment>
  );
}

export default ModalNewBrick;
