import styled from "styled-components";
import Card from "@material-ui/core/Card";

/* Cards */

export const TravCard = styled(Card)`
 margin: ${props => (props.margin ? props.margin : "15px 0")};
  min-width: 0;
  font-size: 0.875rem;
  border: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  overflow: visible !important;
`;
export const TravCardBorder = styled(TravCard)`
  margin:0;
  min-width: 0;
  font-size: 0.875rem;
  border: 0;
  border-left: ${props => (props.borderleft ? props.borderleft : "4px solid  #1bc5bd")};
  /* border-left:4px solid #1bc5bd; */
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  overflow: hidden !important;
`;

export const TravHeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 15px 2rem;
  background-color: transparent;
  border-bottom: 1px solid #ebedf3;
`;
export const TravBodyCard = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px 1rem;
`;

/* Titles */

export const TravTitleCard = styled.h4`
  display: flex;
  align-items: center;
  margin: 0.5rem 0.5rem 0.5rem 0;
  color: #3c4858;
  text-decoration: none;
`;

export const TravLabelCard = styled.h4`
  margin: 0 0.75rem 0 0;
  flex-wrap: wrap;
  font-size: 1.275rem;
  font-weight: 500;
  margin: 0;
`;

/* Toolbar in title */

export const TravCardToolbar = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
`;

export const TravContentTools = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TravExampleToogle = styled.div`
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
  transition: all 0.15s ease;
  cursor: pointer;
  font-size: 1.15rem;
  color: #b5b5c3;
  font-size: 1.35rem;
  &:hover {
    color: #0073e9;
  }
`;

/* Icon in title */
export const TravCardIcon = styled(TravCard)`
  overflow: visible !important;
`;
export const TravIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  margin: -35px 15px 0 -20px;
  font-size: 1.5rem;
  color: #fff;
  border-radius: 3px;
  background-color: #999;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14);
  /* el backgroun quiero pasarlo con distintas opciones:
  primary, secondary, red, green, blue, success, danger, default para que solo sea ponerle el color que quieras y está.A ver si se hacerlo */
  background-color: #2ed4a2;
`;
