import axios from "axios";

const url = "/api/v1/ticketing-bc";

const searchHotelbedsAPI = async (zone, filterType, datei, dateo, occupancy) => {
  try {
    const requestRes = await axios.get(`${url}/providers/hotelbeds/availability`, {
      params: { codes: zone, startDate: datei, endDate: dateo, type: filterType, occupancy },
    });

    if (requestRes.status !== 200 || requestRes.data.error !== undefined) {
      throw new Error("Error while requesting");
    }
    return requestRes.data.activities;
  } catch (error) {
    return [];
  }
};

export default searchHotelbedsAPI;
