import React, { useContext, createContext, useReducer } from "react";

const initialState = {
  lockdown: false,

  pendingLoading: true,
  pendingList: [], // { code, total, amount }[]

  remittanceLoading: true,
  remittance: null, // id; datecreation; createdBy; invoices
};

const PaymentsToSuppliersContext = createContext({ state: initialState });

function reducer(state, action) {
  console.log(`    👻 Contexto: ${action.type} = `, action.payload);

  switch (action.type) {
    case "WRITE_LOCKDOWN":
      return { ...state, lockdown: action.payload };
    case "WRITE_PENDING_LOADING":
      return { ...state, pendingLoading: action.payload };
    case "WRITE_PENDING_LIST":
      return { ...state, pendingList: action.payload };
    case "WRITE_REMITTANCE_LOADING":
      return { ...state, remittanceLoading: action.payload };
    case "WRITE_REMITTANCE":
      return { ...state, remittance: action.payload };
    default:
      return state;
  }
}

export function PaymentsToSuppliersContextProvider({
  lockdown,
  pendingLoading,
  pendingList,
  remittanceLoading,
  remittance,
  children,
}) {
  // console.log("Set Payments To Suppliers Context Provider");

  const stateInit = { lockdown, pendingLoading, pendingList, remittanceLoading, remittance };
  const [state, dispatch] = useReducer(reducer, stateInit);
  const value = { state, dispatch };
  return <PaymentsToSuppliersContext.Provider value={value}>{children}</PaymentsToSuppliersContext.Provider>;
}

export const useLockdown = () => {
  const { state, dispatch } = useContext(PaymentsToSuppliersContext);
  const { lockdown } = state;

  function setLockdown(newLockdown) {
    dispatch({ type: "WRITE_LOCKDOWN", payload: newLockdown });
  }

  return { lockdown, setLockdown };
};

export const usePendingPayments = () => {
  const { state, dispatch } = useContext(PaymentsToSuppliersContext);
  const { pendingLoading, pendingList } = state;

  function setLoading(newLoading) {
    dispatch({ type: "WRITE_PENDING_LOADING", payload: newLoading });
  }

  function setPendingList(newPendingList) {
    dispatch({ type: "WRITE_PENDING_LIST", payload: newPendingList });
  }

  return { pendingLoading, pendingList, setLoading, setPendingList };
};

export const useRemittance = () => {
  const { state, dispatch } = useContext(PaymentsToSuppliersContext);
  const { remittanceLoading, remittance } = state;

  function setRemittanceLoading(newRemittanceLoading) {
    dispatch({ type: "WRITE_REMITTANCE_LOADING", payload: newRemittanceLoading });
  }

  function setRemittance(newRemittance) {
    dispatch({ type: "WRITE_REMITTANCE", payload: newRemittance });
  }

  function getRemittanceTotalAmount() {
    if (!remittance?.invoices) return 0;
    return remittance.invoices.reduce((acc, cur) => acc + cur.amount, 0);
  }

  return { remittanceLoading, remittance, setRemittanceLoading, setRemittance, getRemittanceTotalAmount };
};
