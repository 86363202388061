import React from "react";
import { useHistory } from "react-router-dom";
// Atoms
import ButtonSave from "@atoms/Buttons/ButtonSave";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import ButtonReset from "@atoms/Buttons/ButtonReset";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

function ProductConfigurationsMenu({
  productConfigurations,
  saveProductsConfigurations,
  resetProductsConfigurations,
  addNewProductConfiguration,
}) {
  const history = useHistory();

  function goBackToPrecinct() {
    const ticketingSectionPathname = history.location.pathname.split("/");
    ticketingSectionPathname.pop();
    ticketingSectionPathname.pop();
    history.push(`${ticketingSectionPathname.join("/")}`);
  }
  return (
    <ContainerFenix>
      <SectionTitle
        title={"Configuraciones de Producto"}
        backFunction={goBackToPrecinct}
        buttons={[
          <ButtonAdd
            size="small"
            key={"btA"}
            onClick={addNewProductConfiguration.func}
            disabled={!addNewProductConfiguration.available}
          >
            Añadir
          </ButtonAdd>,
          <ButtonReset
            size="small"
            key={"btW"}
            onClick={resetProductsConfigurations.func}
            disabled={!resetProductsConfigurations.available}
          >
            Deshacer
          </ButtonReset>,
          <ButtonSave
            size="small"
            key={"btG"}
            onClick={saveProductsConfigurations.func}
            disabled={!saveProductsConfigurations.available}
          >
            Guardar
          </ButtonSave>,
        ]}
      />
    </ContainerFenix>
  );
}

ProductConfigurationsMenu.propTypes = {};

export default ProductConfigurationsMenu;
