import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Paper, MenuList, MenuItem } from "@material-ui/core";
import { getFolders } from "../../../hooks/useFolderFetch";

const FolderSuggestions = ({ searcherString, bucket, maxsuggestions = 50, onSelectOption }) => {
  const minchar = 3;
  const [suggestions, setSuggestions] = useState([]);
  //   const [timer, setTimer] = useState(null);

  useEffect(() => {
    // if (timer !== null) {
    //   clearTimeout(timer);
    //   setTimer(null);
    // }
    if (searcherString.length >= minchar) {
      let newTimer = setTimeout(() => {
        getSuggestions(bucket, searcherString)
          .then((r) => {
            setSuggestions(r.slice(0, maxsuggestions));
          })
          .catch((e) => {
            console.error(e);
          });
      }, 500);
      //   setTimer(newTimer);
    } else {
      setSuggestions([]);
    }
  }, [searcherString]);

  const getSuggestions = async (bucket, stringToSearch) => {
    let listSuggestions = [];
    try {
      const Folders = await getFolders(bucket, `path[regex]=${stringToSearch}`);
      listSuggestions = [...new Set([...listSuggestions, ...Folders.data])];
    } catch (error) {
      console.error(error);
    }
    return listSuggestions;
  };

  const selectOption = (newOption) => {
    setSuggestions([]);
    onSelectOption(newOption);
  };

  return (
    <Fragment>
      {suggestions.length > 0 && (
        <Paper style={{ maxHeight: "230px", overflowY: "auto" }}>
          <MenuList>
            {suggestions.length === 0 && <MenuItem>No hay sugerencias</MenuItem>}
            {/* Menu List */}
            {suggestions.length > 0 &&
              suggestions.map((option, index) => {
                return (
                  <MenuItem key={`searchoption_${index}`} onClick={() => selectOption(option.path)}>
                    {option.path}
                  </MenuItem>
                );
              })}
          </MenuList>
        </Paper>
      )}
    </Fragment>
  );
};

export default FolderSuggestions;
