import React, { useState, useEffect } from "react";
import FenixTable from "@molecules/Tables/FenixTable";
import { FiCheckCircle } from "react-icons/fi";
import BankAccountApi from "../api/BankAccountApi";
import BankAccountValidationModal from "./BankAccountValidationModal/BankAccountValidationModal";

const BankAccountList = () => {
  const [loading, setLoading] = useState(true);
  const [bankAccounts, setBankAccounts] = useState([]);

  const [open, setOpen] = useState(false);
  const [backAccountSelected, setBackAccountSelected] = useState(null);

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        setLoading(true);
        const response = await BankAccountApi.getBankAccountPending();
        setBankAccounts(response.data);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };
    fetchBankAccounts();
  }, []);

  const tableHead = [
    { code: "id", name: "ID", type: "string" },
    { code: "supplier", name: "Supplier", type: "string" },
    { code: "bankAccountNumberObfuscated", name: "Cuenta bancaria", type: "string" },
    { code: "createdAt", name: "Fecha creacion", type: "date" },
    { code: "createdBy", name: "Creador", type: "string" },
  ];

  function onOpenValidationModal(row) {
    setBackAccountSelected(row);
    setOpen(true);
  }

  function onCloseValidationModal() {
    setOpen(false);
    setBackAccountSelected(null);
  }

  return (
    <>
      <FenixTable
        loading={loading}
        tableHead={tableHead}
        tableContent={bankAccounts}
        pagination
        actions={[{ name: "Validar", icon: <FiCheckCircle size={20} />, func: onOpenValidationModal }]}
      />
      <BankAccountValidationModal
        open={open}
        onClose={onCloseValidationModal}
        bankAccount={backAccountSelected}
      />
    </>
  );
};

export default BankAccountList;
