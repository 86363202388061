import styled, { css } from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";

export const IconButtonFiltersActive = styled(IconButton)`
  ${(props) =>
    props.activated === "true" &&
    css`
      flex: 0 0 auto;
      color: #fff !important;
      padding: 12px;
      overflow: visible;
      font-size: 1.5rem;
      text-align: center;
      background-color: #ffae17 !important;
      border-radius: 50%;
      &:hover {
        background-color: #ff8f18 !important;
      }
    `}
`;

export const ContGeneralFilterShow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

export const ContButtonsFileter = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 170px;
`;

export const BorderBottomPopover = styled.div`
  border-bottom: 1px solid #ddd;
  height: 1px;
`;

export const LabelSelectAll = styled(FormControlLabel)`
  margin-bottom: 0 !important;
`;
