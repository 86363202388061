// styled component of a muted text tha shows a text with a muted color in a minimum of 3 lines adjusting the size of the text

import React from "react";
import styled from "styled-components";

const MutedText = ({ children, className }) => {
  return <MutedTextStyled className={className}>{children}</MutedTextStyled>;
};

const MutedTextStyled = styled.div`
  color: #999;
  font-size: 0.75rem;
  line-height: 1.2rem;
  min-height: 3.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

export default MutedText;
