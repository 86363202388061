import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useBricks, generateNewBrickCode, deleteBrick } from "../hooks/useBricks";

function useBrickList({ type }) {
  const history = useHistory();
  const { loadingBricks, bricks } = useBricks(type);

  const addNewBrick = () => {
    goToEditor(generateNewBrickCode(type));
  };

  const editBrick = (rowData) => {
    goToEditor(rowData.code);
  };

  const duplicateBrick = (rowData) => {
    goToEditor(`${rowData.code}?duplicate=true`);
  };

  const deleteThisBrick = async (rowData) => {
    let r = await deleteBrick(rowData.code);
    if (r.ok) {
      window.location = "";
    }
  };

  const goToEditor = (code) => {
    //On type
    const editorURL = `/app/content/bricks/${type}`;
    history.push(`${editorURL}/${code}`);
  };

  const goBack = () => {
    history.push(`/app/content/bricks`);
  };

  return { type, loadingBricks, bricks, goBack, addNewBrick, editBrick, duplicateBrick, deleteThisBrick };
}

export default useBrickList;
