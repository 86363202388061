const lStorage = typeof localStorage !== "undefined" ? localStorage : null;
const sStorage = typeof sessionStorage !== "undefined" ? sessionStorage : null;

class Storage {
  constructor(type) {
    if (type === "local") {
      this.storage = lStorage;
    } else if (type === "session") {
      this.storage = sStorage;
    } else {
      throw new Error("wrong type");
    }
  }

  setItem(key, value) {
    if (this.storage !== null) {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }

  getItem(key) {
    if (this.storage !== null) {
      const value = this.storage.getItem(key);
      return value !== undefined && value !== null ? JSON.parse(value) : null;
    }
    return null;
  }

  removeItem(key) {
    if (this.storage !== null) {
      this.storage.removeItem(key);
    }
  }

  clear() {
    if (this.storage !== null) {
      this.storage.clear();
    }
  }
}

// module.exports = {
//   localStorage: new Storage("local"),
//   sessionStorage: new Storage("session"),
// };

export default Storage;
