import React from "react";
import moment from "moment";

const BankAccountSummary = ({ bankAccount }) => {
  const { supplier, bankAccountNumberObfuscated, createdAt, createdBy } = bankAccount;

  return (
    <>
      <p>
        Vas a confirmar la cuenta <b>{bankAccountNumberObfuscated}</b>
      </p>
      <ul>
        <li>
          De <b>{supplier}</b>
        </li>
        <li>
          Creada por <b>{createdBy}</b>
        </li>
        <li>
          Creada el <b>{moment(createdAt).format("DD/MM/YYYY")}</b>
        </li>
      </ul>
    </>
  );
};

export default BankAccountSummary;
