import React from "react";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell, Tooltip, TableSortLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CachedIcon from "@material-ui/icons/Cached";
import PopoverFilters from "./components/PopoverFilters/PopoverFilters";

const FenixTableHead = ({
  tableHead,
  filters,
  order,
  orderBy,
  actions,
  onRequestSort,
  onChangeFilters,
  withChildren,
  withSelection,
  allowSelectAll,
  mainCheckbox,
  onChangeAllCheckbox,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(property);
  };

  const changeFilters = (newFilters, column) => {
    const filtered = newFilters.filter((option) => option.checked).map((e) => e.value);
    onChangeFilters({ column, value: filtered });
  };

  const onChangeMainCheckbox = (e) => {
    const { checked } = e.target;
    onChangeAllCheckbox(checked);
  };

  const getCheckboxType = () => {
    return allowSelectAll ? (
      <Checkbox
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
        name={"SelectALL"}
        checked={mainCheckbox}
        onChange={onChangeMainCheckbox}
      />
    ) : (
      <CachedIcon style={{ color: "#bababa" }} />
    );
  };

  const getAlignmentFromType = (type) => {
    let alignment = "";
    switch (type) {
      case "boolean":
      case "market":
      case "label":
        alignment = "center";
        break;
      case "numeric":
      case "money":
      case "date":
        alignment = "right";
        break;
      default:
        alignment = "left";
        break;
    }

    return alignment;
  };

  return (
    <TableHead>
      <TableRow>
        {withChildren && <TableCell></TableCell>}
        {withSelection && <TableCell align="center">{getCheckboxType()}</TableCell>}

        {tableHead.map((column) => (
          <TableCell key={column.code} align={getAlignmentFromType(column.type)}>
            {/* Filters column */}
            {column.filterable && filters !== undefined && filters[column.code] && (
              <PopoverFilters
                columnConfig={column}
                column={filters[column.code]}
                onChange={(newF) => changeFilters(newF, column.code)}
              />
            )}

            <Tooltip title={`Ordena por ${column.name}`} placement={"bottom-end"} enterDelay={300}>
              <TableSortLabel
                active={orderBy === column.code}
                direction={order}
                onClick={createSortHandler(column.code)}
              >
                <b>{column.name}</b>
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
        {actions !== undefined && actions.length > 0 && (
          <TableCell align="center">
            <b>Acciones</b>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

FenixTableHead.propTypes = {
  tableHead: PropTypes.array,
  filters: PropTypes.object,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
  onChangeFilters: PropTypes.func,
};

FenixTableHead.defaultProps = {
  withChildren: false,
  withSelection: false,
  allowSelectAll: false,
  mainCheckbox: false,
  onChangeAllCheckbox: null,
};

export default FenixTableHead;
