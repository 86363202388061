/* eslint-disable no-param-reassign */
import { parse } from "js2xmlparser";

function readAccountNumberAndTaxInfo(facturaNode, accountInfoTax) {
  const numeroFactura = facturaNode?.IDFactura?.NumSerieFacturaEmisor?.["#"] ?? null;
  if (numeroFactura === null) {
    throw new Error(`Error al leer la factura en un caso de tipo DesgloseFactura normal`);
  }
  if (!accountInfoTax.has(numeroFactura)) {
    throw new Error(`Número de factura ${numeroFactura} no encontrado en el csv`);
  }
  return { numeroFactura, taxInfo: accountInfoTax.get(numeroFactura) };
}

function modifyNodeWithTaxes(node, factura, accountInfoTax, xmlProcessorStatsAndErrors, typeStat) {
  try {
    const { numeroFactura, taxInfo } = readAccountNumberAndTaxInfo(factura, accountInfoTax);
    node.TipoImpositivo = taxInfo.iva.toFixed(2);
    node.BaseImponible = taxInfo.BaseImponible.toFixed(2);
    node.CuotaRepercutida = taxInfo.cuotaRepercutida.toFixed(2);
    accountInfoTax.processed(numeroFactura);
    xmlProcessorStatsAndErrors.modified(typeStat);
  } catch (e) {
    xmlProcessorStatsAndErrors.addError(e.message);
  }
}

function modifyNodeWithoutTaxes(node, factura, accountInfoTax, xmlProcessorStatsAndErrors, typeStat) {
  try {
    const { numeroFactura, taxInfo } = readAccountNumberAndTaxInfo(factura, accountInfoTax);
    if (taxInfo.iva !== null || taxInfo.cuotaRepercutida !== null || taxInfo.BaseImponible !== 0) {
      throw new Error(
        `Factura ${numeroFactura} con impuestos configurados en el csv pero exenta en el xml (causa de excepción ${node.CausaExencion}). Deberían ser: tipoImpositivo=NA, cuotaRepercutida=NA, baseImponible=0`
      );
    }
    node.BaseImponible = 0;
    accountInfoTax.processed(numeroFactura);
    xmlProcessorStatsAndErrors.modifiedWithoutTaxex(typeStat);
  } catch (e) {
    xmlProcessorStatsAndErrors.addError(e.message);
  }
}

function processXmlFile(result, accountInfoTax, xmlProcessorStatsAndErrors) {
  const originalXml = parse("BorraEstaPutaMierdaPorFavor", result)
    .replace("<BorraEstaPutaMierdaPorFavor>\n", "")
    .replace("</BorraEstaPutaMierdaPorFavor>", "")
    .replace("<?xml version='1.0'?>", "<?xml version='1.0' encoding='utf-8'?>");
  const facturas = Array.isArray(
    result["soap:Envelope"]["soap:Body"].SuministroLRFacturasEmitidas.RegistroLRFacturasEmitidas
  )
    ? result["soap:Envelope"]["soap:Body"].SuministroLRFacturasEmitidas.RegistroLRFacturasEmitidas
    : [result["soap:Envelope"]["soap:Body"].SuministroLRFacturasEmitidas.RegistroLRFacturasEmitidas];

  // facturas = [facturas];
  facturas.forEach((factura) => {
    const tipoDesglose = factura.FacturaExpedida.TipoDesglose;
    if (tipoDesglose.DesgloseFactura !== undefined) {
      if (
        tipoDesglose.DesgloseFactura.Sujeta === undefined &&
        tipoDesglose.DesgloseFactura.NoSujeta !== undefined &&
        tipoDesglose.DesgloseFactura.NoSujeta.ImportePorArticulos7_14_Otros !== undefined
      ) {
        xmlProcessorStatsAndErrors.ignored("A");
      } else if (
        tipoDesglose.DesgloseFactura.Sujeta.Exenta !== undefined &&
        tipoDesglose.DesgloseFactura.Sujeta.Exenta.DetalleExenta.BaseImponible !== undefined
      ) {
        modifyNodeWithoutTaxes(
          tipoDesglose.DesgloseFactura.Sujeta.Exenta.DetalleExenta,
          factura,
          accountInfoTax,
          xmlProcessorStatsAndErrors,
          "A"
        );
      } else {
        modifyNodeWithTaxes(
          tipoDesglose.DesgloseFactura.Sujeta.NoExenta.DesgloseIVA.DetalleIVA,
          factura,
          accountInfoTax,
          xmlProcessorStatsAndErrors,
          "A"
        );
      }
    } /* if (tipoDesglose.DesgloseFactura !== undefined) */ else {
      // console.log(index, tipoDesglose.DesgloseTipoOperacion);
      const { PrestacionServicios } = tipoDesglose.DesgloseTipoOperacion;
      if (PrestacionServicios.Sujeta !== undefined) {
        if (
          PrestacionServicios.Sujeta.Exenta !== undefined &&
          PrestacionServicios.Sujeta.Exenta.DetalleExenta.BaseImponible !== undefined
        ) {
          modifyNodeWithoutTaxes(
            PrestacionServicios.Sujeta.Exenta.DetalleExenta,
            factura,
            accountInfoTax,
            xmlProcessorStatsAndErrors,
            "B"
          );
        } else {
          modifyNodeWithTaxes(
            PrestacionServicios.Sujeta.NoExenta.DesgloseIVA.DetalleIVA,
            factura,
            accountInfoTax,
            xmlProcessorStatsAndErrors,
            "B"
          );
        }
      } else if (PrestacionServicios.NoSujeta.ImportePorArticulos7_14_Otros !== undefined) {
        xmlProcessorStatsAndErrors.ignored("B");
      } else {
        xmlProcessorStatsAndErrors.addError('No se ha encontrado el tipo de desglose "PrestacionServicios');
      }
    }
  });
  const newXml = parse("BorraEstaPutaMierdaPorFavor", result)
    .replace("<BorraEstaPutaMierdaPorFavor>\n", "")
    .replace("</BorraEstaPutaMierdaPorFavor>", "")
    .replace("<?xml version='1.0'?>", "<?xml version='1.0' encoding='utf-8'?>");
  return [newXml, originalXml];
}
export default processXmlFile;
