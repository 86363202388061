import React, { useState, Fragment } from "react";
import { Grid, Button } from "@material-ui/core";
import { Row, Col, Form } from "react-bootstrap";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Cropper from "react-cropper";
import Modal from "react-bootstrap/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";

import "cropperjs/dist/cropper.css";
import "./styleCrop.css";

const defaultSrc = "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

export const ModuleCrop = ({
  imageRemain,
  image,
  openCrop,
  cropData,
  cropper,
  aspectRatio,
  onChange,
  onChangeMultipleFiles,
  getCropData,
  handleChangeAspectRatio,
  handleCropData,
  loadingSubmit,
  formatValid,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const handleClosePreview = () => setShowPreview(false);
  const handleShowPreview = () => setShowPreview(true);

  const contentInModuleCrop = (loadingSubmit, imageRemain) => {
    if (imageRemain > 0) {
      return (
        <div class="divCenter">
          <div class="loadingImgText"> Cargando imagen</div>
          <div>
            <CircularProgress />
          </div>
        </div>
      );
    }

    if (loadingSubmit && imageRemain > 0) {
      return (
        <div class="divCenter">
          <CircularProgress />
        </div>
      );
    }

    if (!loadingSubmit) {
      return (
        <div class="divCenter" style={{ width: "100%" }}>
          {/* <input type="file" onChange={onChange} /> */}
          <input type="file" onChange={onChangeMultipleFiles} name={"files"} multiple />
          <div class="onChangeMultipleFilesText">
            Formatos validos:
            {formatValid.map((cv, i) => {
              return i + 1 === formatValid.length ? ` ${cv.toLowerCase()}.` : ` ${cv.toLowerCase()},`;
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <Fragment>
      {!openCrop ? (
        contentInModuleCrop(loadingSubmit, imageRemain)
      ) : (
        <Fragment>
          <div style={{ width: "100%" }}>
            <Cropper
              aspectRatio={aspectRatio.ratio}
              initialAspectRatio={aspectRatio.ratio}
              style={{ height: 400, width: "100%" }}
              preview=".img-preview"
              src={image}
              viewMode={2}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                handleCropData(instance);
              }}
            />
          </div>

          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <FormControl>
                <InputLabel id="demo-simple-select">Ratio</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={aspectRatio.name}
                  onChange={handleChangeAspectRatio}
                >
                  <MenuItem value={"3/2"}>3/2</MenuItem>
                  <MenuItem value={"2000/344"}>2000/344</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={4}>
              <div style={{ textAlign: "right" }}>
                <Button
                  onClick={(e) => {
                    getCropData(e);
                    handleShowPreview();
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  {showPreview === false ? "Preview" : "Cerrar Preview"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Fragment>
      )}
      <Modal
        key={"ModalPreview"}
        id={"modalPreview"}
        class={"modal-dialog"}
        show={showPreview}
        onHide={handleClosePreview}
        centered
      >
        <Modal.Body>
          <img style={{ width: "100%" }} src={cropData} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModuleCrop;
