import moment from "@date-io/moment";

export const CSV_Parse = (offers) => {
  const offersParsed = [];
  for (const offer of offers) {
    // offer.idOferta = parseInt(offer.idOferta);
    // offer.price = parseInt(offer.price);
    // offer.previousPrice = parseInt(offer.previousPrice);
    // offer.dayPrice = parseInt(offer.dayPrice);
    // offer.idSkiResort = parseInt(offer.idSkiResort);
    // offer.startDate = Math.floor(new Date(offer.startDate).getTime() / 1000);
    // offer.endDate = Math.floor(new Date(offer.endDate).getTime() / 1000);
    offersParsed.push(offer);
  }
  return offersParsed;
};

export const CSV_Initialize = (offers) => {
  const offersInitialize = [];
  for (const offer of offers) {
    offer.idOferta = offer.idOferta === "" ? 0 : offer.idOferta;
    offer.visible = offer.visible ? 1 : 0;
    offer.delete = offer.delete === "" ? 0 : offer.delete;
    offer.previousPrice = offer.previousPrice === "" ? 0 : offer.previousPrice;
    offer.startDate = offer.startDate === "" ? moment().format("DD/MM/YYYY") : offer.startDate;
    offersInitialize.push(offer);
  }
  return offersInitialize;
};

export const CSV_Valdiator = (listE, seasons, skiResorts, items) => {
  //NO LLEGA EL DELETE
  const errorList = [...listE];
  let counter = 3;
  var myRe = new RegExp(/^[0-1]$/);
  for (const item of items) {
    let errorItem = [];
    // String
    if (typeof item.title !== "string") errorItem.push("El titulo tiene que ser una cadena de texto");
    if (item.title === "") errorItem.push("El titulo no puedo estar vacio");
    if (typeof item.url !== "string") errorItem.push("La url tiene que ser una cadena de texto");
    if (item.url === "") errorItem.push("La url no puedo estar vacia");
    if (item.idSeason === "") {
      errorItem.push("La temporada no puedo estar vacia");
    } else {
      if (typeof item.idSeason !== "string") errorItem.push("La temporada tiene que ser una cadena de texto");
      if (!existSeason(seasons, item.idSeason)) errorItem.push("La temporada tiene que ser valida");
    }
    if (isNaN(item.idOferta)) errorItem.push("El id tiene que ser un numero");
    // if (item.idOferta) errorItem.push("El id tiene que ser un numero");

    if (isNaN(item.price)) errorItem.push("El precio tiene que ser un numero");
    if (item.price === "") errorItem.push("El precio no puedo estar vacio");
    if (item.dayPrice !== "") {
      if (isNaN(item.dayPrice)) errorItem.push("El precio dia tiene que ser un numero");
    }
    if (isNaN(item.previousPrice)) errorItem.push("El precio de antes tiene que ser un numero");
    if (item.previousPrice === "") errorItem.push("El precio de antes no puede estar vacio");

    if (myRe.exec(item.delete) === null)
      errorItem.push("El Borrar tiene que contener una de las siguientes opciones (Vacio/0/1)");

    if (!existSkiResort(skiResorts, item.idSkiResort)) errorItem.push("El id estacion tiene que ser valido");
    if (isNaN(item.idSkiResort)) errorItem.push("El id estacion tiene que ser un numero");
    if (item.idSkiResort === "") errorItem.push("El id estacion no puedo estar vacio");
    if (!dateValidator(item.startDate))
      errorItem.push("La fecha inicial tiene que usar el formato DD/MM/YYYY");
    if (!dateValidator(item.endDate)) errorItem.push("La fecha final tiene que usar el formato DD/MM/YYYY");
    if (new Date(item.endDate).getTime() < new Date(item.startDate).getTime())
      errorItem.push("La fecha final no puede ser inferior a la fecha inicial");
    if (item.startDate === "") errorItem.push("La fecha inicial no puede estar vacia");
    if (item.endDate === "") errorItem.push("La fecha final no puede estar vacia");

    if (errorItem.length > 0) {
      errorList.push(`La Oferta de la linea ${counter}  (${errorItem.join(" ,")})`);
    }
    counter += 1;
  }
  return errorList;
};

const existSeason = (seasons, data) => {
  let match = false;
  for (const season of seasons) {
    if (season.code === data) {
      match = true;
    }
  }
  return match;
};

const existSkiResort = (skiResorts, data) => {
  let match = false;
  for (const skiResort of skiResorts) {
    if (skiResort.id === data.toString()) {
      match = true;
    }
  }
  return match;
};

const dateValidator = (date) => {
  var RegExPattern = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;
  if (date.match(RegExPattern) && date != "") {
    return true;
  } else {
    return false;
  }
};

export const customSlice = (list) => {
  let slices = { upupdate: [], deleted: [] };
  for (const item of list) {
    if (item.delete === 1) {
      slices.deleted.push(item);
      // delete item.delete;
    } else {
      slices.upupdate.push(item);
      // delete item.delete;
    }
  }
  return slices;
};

export const treatCSV = (listErrors, seasons, skiResorts, offers) => {
  let listE = CSV_Valdiator(listErrors, seasons, skiResorts, offers);
  let csvTrated = [];
  if (listE) {
    csvTrated = CSV_Initialize(offers);
  }
  return { offers: csvTrated, listErrors: listE };
};

export const searchRepeatedTitles = (offersInCSV, offersInBBDD) => {
  const errorList = [];
  let counter = 3;
  offersInCSV.forEach((offerInCSV) => {
    offersInBBDD.forEach((offerInBBDD) => {
      if (offerInCSV.idOferta === 0 && offerInCSV.title === offerInBBDD.title)
        errorList.push(
          `Titulo de la oferta existente en la linea ${counter}, tienes que modificar el titulo.`
        );
    });
    counter++;
  });
  return errorList;
};
