import React from "react";
import Money from "@atoms/Money/Money";

const SupplierBlockTitle = ({ supplier }) => {
  return (
    <div style={{ width: "100%" }}>
      <span>
        <b>{supplier.supplier}</b>
      </span>
      {"  -  "}
      <span>{supplier.totalInvoices} pendientes</span>

      <span style={{ float: "right" }}>
        <b>
          <Money amount={supplier.amount} />
        </b>
      </span>
    </div>
  );
};

export default SupplierBlockTitle;
