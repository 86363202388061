import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Grid, Button, ButtonGroup } from "@material-ui/core";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { UsCardTitle, LeftEnable } from "@atoms/Card/UsCard.styles";

function MultiItemSelector({ title, selected, optionsTemplate, onChange }) {
  const isActive = (optionKey) => {
    return selected.find((selection) => selection.code === optionKey) !== undefined;
  };

  const isActiveSub = (optionKey, subOptionkey) => {
    let active = false;
    if (isActive(optionKey)) {
      const index = selected.findIndex((selection) => selection.code === optionKey);
      return selected[index].options?.includes(subOptionkey);
    }

    return active;
  };

  const clickOption = (optionKey) => {
    let nuSelected = [...selected];
    if (isActive(optionKey)) {
      const index = selected.findIndex((selection) => selection.code === optionKey);
      nuSelected.splice(index, 1);
    } else {
      nuSelected.push({ code: optionKey });
    }

    onChange(nuSelected);
  };

  const clickSubOption = (optionKey, subOptionkey) => {
    let nuSelected = [...selected];
    const index = selected.findIndex((selection) => selection.code === optionKey);
    if (isActiveSub(optionKey, subOptionkey)) {
      const indexSub = selected[index].options.findIndex((subselection) => subselection === subOptionkey);
      nuSelected[index].options.splice(indexSub, 1);
      if (nuSelected[index].options.length === 0) {
        delete nuSelected[index].options;
      }
    } else {
      if (nuSelected[index].options === undefined) {
        nuSelected[index].options = [];
      }
      nuSelected[index].options.push(subOptionkey);
    }

    onChange(nuSelected);
  };

  const addAllNights = () => {
    const nuSelected = optionsTemplate.map((thisOption) => {
      const fkey = thisOption.code;
      const selection = { code: fkey };
      if (thisOption.options !== undefined) {
        selection.options = thisOption.options.map((opt) => opt.code);
      }
      return selection;
    });

    onChange(nuSelected);
  };

  const removeAllNights = () => {
    onChange([]);
  };

  return (
    <>
      <UsCardTitle>
        {title}
        <LeftEnable>
          <ButtonGroup aria-label="outlined  primary button group">
            <Button onClick={addAllNights}>
              <FiPlusSquare />
              {" Marcar todas"}
            </Button>
            <Button onClick={removeAllNights}>
              <FiMinusSquare />
              {" Quitar todas"}
            </Button>
          </ButtonGroup>
        </LeftEnable>
      </UsCardTitle>
      <br />
      <Grid container spacing={3}>
        {optionsTemplate.map((thisOption, i) => {
          const active = isActive(thisOption.code);
          return (
            <Grid item xs={3} key={`btn-${i}`}>
              <>
                <ItemToSelect
                  item={thisOption}
                  active={active}
                  onClick={() => clickOption(thisOption.code)}
                />

                {active && thisOption.options !== undefined && thisOption.options.length > 0 && (
                  <>
                    {thisOption.options.map((thisSubOption, index) => {
                      const subActive = isActiveSub(thisOption.code, thisSubOption.code);
                      return (
                        <ItemToSelect
                          key={`subo${index}`}
                          style={{ fontSize: "10px" }}
                          item={thisSubOption}
                          active={subActive}
                          onClick={() => clickSubOption(thisOption.code, thisSubOption.code)}
                        />
                      );
                    })}
                  </>
                )}
              </>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

const ItemToSelect = ({ item, active, onClick, ...props }) => {
  return (
    <ButtonNights
      {...props}
      disableElevation
      color={active ? "primary" : "default"}
      variant={active ? "contained" : "outlined"}
      onClick={onClick}
    >
      {item.name || item.code}
    </ButtonNights>
  );
};

export const ButtonNights = styled(Button)`
  min-width: 36px !important;
  margin-bottom: 5px;
  width: 100%;
`;

MultiItemSelector.propTypes = {
  /**
   * Selected array of objects.
   */
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Identifier of the item selected
       */
      code: PropTypes.string,
      /**
       * Identifiers of the subitems that are selected
       */
      options: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  /**
   * List of options.
   */
  optionsTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Identifier of the option to select
       */
      code: PropTypes.string.isRequired,
      /**
       * Name of the item, to show in view (if undefined, it will show the code instead)
       */
      name: PropTypes.string,
      /**
       * List of the options
       */
      options: PropTypes.arrayOf(
        PropTypes.shape({ code: PropTypes.string.isRequired, name: PropTypes.string })
      ),
    })
  ),
  onChange: PropTypes.func,
};

export default MultiItemSelector;
