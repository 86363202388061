import React from "react";
import PropTypes from "prop-types";
import { FiDownloadCloud } from "react-icons/fi";
import useCSVManagement from "@lib/hooks/useCSVManagement";
import CircularProgress from "@material-ui/core/CircularProgress";

function MenuItemDownloadCSV({
  wordcontrol,
  head,
  specs,
  table,
  filename,
  buttonLabel,
  info,
  generateTable,
  onDownloadEnded,
  icon,
  ...props
}) {
  const { loading, onClickDownloadButton } = useCSVManagement({
    wordcontrol,
    head,
    specs,
    table,
    filename,
    info,
    generateTable,
    onDownloadEnded,
  });

  return loading ? (
    <div style={{ width: "100%" }}>
      <CircularProgress size={20} color="primary" style={{ marginRight: "10px" }} /> Descargando CSV...
    </div>
  ) : (
    <div style={{ width: "100%" }} onClick={onClickDownloadButton} {...props}>
      {icon}
      <FiDownloadCloud size={20} style={{ marginRight: "10px" }} /> {buttonLabel || ""}
    </div>
  );
}

MenuItemDownloadCSV.propTypes = {
  wordcontrol: PropTypes.string,
  head: PropTypes.array.isRequired,
  specs: PropTypes.object.isRequired,
  table: PropTypes.array,
  filename: PropTypes.string,
  buttonLabel: PropTypes.string,
  info: PropTypes.array,
  generateTable: PropTypes.func,
};

MenuItemDownloadCSV.defaultProps = {
  table: [],
  info: [],
};

export default MenuItemDownloadCSV;
