import { useState, useEffect } from "react";
import axios from "axios";

const URL_TRANSFERS = "/api/v1/booking-bc/payments";
const URL_BANK_ACCOUNT = "/api/v1/booking-bc/book-refund-bank-account";

const FROM_PAYMENT_METHOD = {
  3: "Transferencia",
  9: "Multibanco",
};

export const executeConfirmRefund = async (paymentId) => {
  try {
    const response = await axios.put(`/api/v1/booking-bc/payments-refund/${paymentId}`);
    if (response.status !== 202) {
      throw new Error(JSON.stringify(response));
    }
    return response;
  } catch (error) {
    alert(`Ha ocurrido un error al confirmar esta devolucion. ${error}`);
  }
};

export const usePayments = () => {
  const [data, setData] = useState({ loading: true, dataTransfers: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, dataTransfers: [] };
        const params = {
          status: 3,
          method: 999,
          refundedFromPaymentMethod: 3,
        };
        const { status, data: dataRes } = await axios.get(URL_TRANSFERS, { params });
        if (status === 200) {
          // Get Bank Accounts
          const paramsBA = [
            "unencrypted=1",
            ...[...new Set(dataRes.data.map((t) => `bookref=${t.bookref}`))],
          ];
          const { status: statusBA, data: dataBA } = await axios.get(
            `${URL_BANK_ACCOUNT}?${paramsBA.join("&")}`
          );
          if (statusBA === 200) {
            const kvBA = dataBA.data.reduce((accu, v) => ({ ...accu, [v.bookref]: v }), {});

            thestate = {
              loading: false,
              dataTransfers: dataRes.data.reduce((acc, payment) => {
                if (
                  payment.refundedFromPaymentMethod &&
                  Object.keys(FROM_PAYMENT_METHOD).includes(payment.refundedFromPaymentMethod.toString())
                ) {
                  const nuPayment = { ...payment };
                  nuPayment.refundedFromPaymentMethodName =
                    FROM_PAYMENT_METHOD[payment.refundedFromPaymentMethod];
                  nuPayment.reservationCode = `${payment.bookref}-Traventia`;
                  nuPayment.hasIban = "B";
                  nuPayment.amount.toFixed = (payment.amount.amount / 100).toFixed(2);
                  nuPayment.amount.toFixedReplace = (payment.amount.amount / 100)
                    .toFixed(2)
                    .replace(".", ",");

                  // Check with IBAN
                  if (kvBA[payment.bookref] !== undefined) {
                    nuPayment.dataAccount = kvBA[payment.bookref];
                    nuPayment.hasIban = "A";
                  }

                  acc.push(nuPayment);
                }
                return acc;
              }, []),
            };
          }
        }

        setData(thestate);
      } catch (error) {
        setData({ loading: false, dataTransfers: [] });
        console.error(error);
      }
    };

    if (data.loading) {
      fetchData();
    }
  }, [data.loading]);

  return { loadingTransfers: data.loading, dataTransfers: data.dataTransfers };
};
