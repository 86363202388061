import {
  blockVoucher,
  sendEmailVoucher,
  sendMarkVoucher,
  seeVoucherPDF,
  unBlockVoucher,
  manageVoucher,
  combineVoucherPDFs,
  uploadVoucher,
  mutexVoucher,
  regenerateVoucher,
} from "./useVoucherFetch";

const userVoucherActions = ({ user, setIsManageVoucherActive, setVoucherToManage, updateVoucher }) => {
  const managementVoucherAction = async (event) => {
    const voucherDetail = await manageVoucher(event.bookref);
    setVoucherToManage(voucherDetail);
    setIsManageVoucherActive(true);
  };
  const sendMarkVoucherAction = (cb) => async (event) => {
    // eslint-disable-next-line no-alert, no-undef, no-restricted-globals
    const confirmSendMark = confirm(
      "¿Está seguro de que desea cambiar el estado del bono de esta reserva? Tenga en cuenta que si algún usuario está usando esta reserva el cambio no tendrá efecto"
    );
    if (confirmSendMark) {
      await sendMarkVoucher(event);
      cb();
    }
  };

  const blockVoucherAction = (cb) => async (event) => {
    await blockVoucher(event, user);
    // eslint-disable-next-line no-alert, no-undef
    alert("Bono bloqueado");
    cb();
  };
  const blockVoucherBackground = async (event) => {
    await mutexVoucher(event, user);
  };
  const unBlockVoucherAction = (cb) => async (event) => {
    await unBlockVoucher(event, user);
    // eslint-disable-next-line no-alert, no-undef
    alert("Bono desbloqueado");
    cb();
  };
  const seeVoucherPDFAction = async (event) => {
    await seeVoucherPDF(event);
  };
  const sendEmailVoucherAction =
    (cb, fromTable, voucherToManage = null) =>
    async (event) => {
      // eslint-disable-next-line no-alert, no-undef, no-restricted-globals
      let bookrefForReq = event.bookref;
      if (voucherToManage !== null) {
        bookrefForReq = voucherToManage.bookref;
      }
      const confirmSendEmail = confirm(`¿Seguro que quiere enviar el bono?\n \nReserva: ${bookrefForReq}`);
      if (confirmSendEmail) {
        const response = await sendEmailVoucher(voucherToManage ?? event, fromTable);
        if (response.err) {
          // eslint-disable-next-line no-alert, no-undef
          alert(response.err);
          cb(false);
        } else {
          // eslint-disable-next-line no-alert, no-undef
          alert("Enviado el bono correctamente");
          cb(true);
        }
      }
    };

  const combineVoucherPDFsAction = async (event) => {
    // eslint-disable-next-line no-alert, no-undef, no-restricted-globals
    const confirmSendEmail = confirm(`¿Desea realizar la fusión de los PDFs?\n \nReserva: ${event.bookref}`);
    if (confirmSendEmail) {
      const allPDF = extractFilesFromAllVouchers(event.voucherList);
      const response = await combineVoucherPDFs({ bookref: event.bookref, pdfs: allPDF });
      if (response.body.res === "exception while merging") {
        alert("Se ha producido un error inesperado." + " Error: " + response.body.res);
      } else {
        alert("Bono fusionado correctamente");
      }
      await updateVoucher(false);
      // eslint-disable-next-line no-alert, no-undef
    }
  };

  const extractFilesFromAllVouchers = (voucherList) => {
    const keys = Object.keys(voucherList);
    const PDF = [];
    for (const key of keys) {
      if (!key.includes("main")) {
        if (Array.isArray(voucherList[key])) {
          PDF.push(...voucherList[key].map((voucher) => voucher.path));
        } else {
          PDF.push(voucherList[key].path);
        }
      }
    }
    return PDF.filter((x) => x !== "");
  };

  const uploadVoucherAction = async (event) => {
    const confirmUploadVoucher = confirm(
      `Ha seleccionado el archivo ${event.file.name} para asignar a ${event.servref}`
    );
    if (confirmUploadVoucher) {
      const res = await uploadVoucher(event);
      alert("Bono actualizado asignado correctamente");
      await updateVoucher(false);
    }
  };

  const regenerateVoucherAction = async (event) => {
    const confirmUploadVoucher = confirm(`¿Desea regenerar los bonos de la reserva ${event.bookref}?`);
    if (confirmUploadVoucher) {
      await regenerateVoucher(event);
      alert("Bonos regenerándose. El proceso tardará unos minutos.");
    }
    return confirmUploadVoucher;
  };

  return {
    managementVoucherAction,
    sendMarkVoucherAction,
    blockVoucherAction,
    blockVoucherBackground,
    seeVoucherPDFAction,
    sendEmailVoucherAction,
    unBlockVoucherAction,
    combineVoucherPDFsAction,
    uploadVoucherAction,
    regenerateVoucherAction,
  };
};

export default userVoucherActions;
