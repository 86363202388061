import { withHook } from "@lib/helpers/withHook";
import AccommodationsProvidersListState from "./AccommodationsProvidersList.state";
import AccommodationsProvidersListView from "./AccommodationsProvidersList.view";

const AccommodationsProvidersListPage = withHook(
  AccommodationsProvidersListState,
  AccommodationsProvidersListView
);

export default AccommodationsProvidersListPage;
