import React from "react";

//Components & hooks
import CityTax from "@organisms/Product/Accommodations/CityTax";

//Styles
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

const CityTaxPage = () => {
  return (
    <ContainerFenix>
      <CityTax />
    </ContainerFenix>
  );
};

export default CityTaxPage;
