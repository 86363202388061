import React, { useState, useEffect } from "react";
import QueryDropDownList from "./components/QueryDropDownList";
import FormBorderDecoration from "@atoms/Inputs/FormBorderDecoration";
import { FaSearch } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import Grid from "@material-ui/core/Grid";

const SelectorWithInputQuery = ({
  elements,
  onSelect,
  onChangeQuery,
  placeholder,
  textOfAoutside,
  fieldToShow,
  device,
  inError,
  errorText,
  icon,
  additionalFormBorderProps,
  cleanOnSelect,
}) => {
  const [textOfSelection, setTextofSelection] = useState(
    textOfAoutside !== "" ? textOfAoutside : placeholder
  );

  useEffect(() => {
    const newOutsideText = textOfAoutside !== "" ? textOfAoutside : placeholder;
    setTextofSelection(newOutsideText);
  }, [textOfAoutside]);

  const title = placeholder; //textOfSelection !== placeholder ? placeholder : "";
  const [open, setOpen] = useState(false);
  const onSelectHandler = (elementSelected) => {
    if (elementSelected !== null) {
      if (cleanOnSelect) {
        setTextofSelection("");
      } else {
        setTextofSelection(elementSelected[fieldToShow]);
      }
      onSelect(elementSelected);
    }
    setOpen(false);
  };

  return (
    <>
      <FormBorderDecoration
        focus={open}
        onClick={() => setOpen(!open)}
        title={title}
        error={inError}
        errorText={errorText}
        iconSpace={"5px"}
        {...additionalFormBorderProps}
      >
        <Grid
          container
          spacing={0}
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          style={{ height: "100%" }}
        >
          <Grid container spacing={0} alignItems="center">
            {textOfSelection}
          </Grid>
        </Grid>

        {open && (
          <QueryDropDownList
            elements={elements}
            device={device}
            onSelect={onSelectHandler}
            onChangeQuery={onChangeQuery}
            closeMe={() => setOpen(false)}
          />
        )}
        <span
          style={{
            position: "absolute",
            bottom: "0.5rem",
            right: "0",
            height: "30px",
            widht: "30px",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {/* icono si es select */}
          {icon === "arrowdown" && <IoMdArrowDropdown />}
          {/*Icono si es lupa*/}
          {icon === "search" && (
            <span style={{ marginRight: "5px" }}>
              <FaSearch />
            </span>
          )}
        </span>
      </FormBorderDecoration>
    </>
  );
};

SelectorWithInputQuery.defaultProps = {
  onSelect: () => {},
  onChangeQuery: () => {},
  placeholder: "",
  textOfAoutside: "",
  fieldToShow: "text",
  device: "desktop",
  inError: false,
  errorText: "",
  icon: "",
  additionalFormBorderProps: {},
  cleanOnSelect: false,
};
export default SelectorWithInputQuery;
