/* eslint-disable no-use-before-define */
import { fetchProduct } from "../Products/useProducts";
import { fetchSession } from "./useSessions";
import difference from "../shared/differenceBetweenTwoLists";
import saveSessionList from "./bulkUpload";

/**
 *
 * @param {*} sessionList
 * @returns
 * saved: number, number of element send in the request after filtered
 * errors: string[], errors received during the execution
 */
const bulkUploadSessions = async (sessionList) => {
  //* ** Validation
  const errorsValidation = await sessionListValidation(sessionList);
  if (errorsValidation.length > 0) {
    return { errors: errorsValidation };
  }

  //* ** Turn the list into a body object list for the request,
  // because trcsv did not cover every object structure
  const sessionListParsed = parseSessionListIntoBody(sessionList);
  try {
    //* ** Get sessions saved
    const promises = [...new Set(sessionListParsed.filter((t) => t.id !== "").map((t) => t.id))].map((se) => {
      console.log(se);
      return fetchSession(se);
    });
    const currentSessions = await Promise.all(promises);

    //* ** Get the intersection between the two
    const { toInsert, toUpdate } = difference(currentSessions, sessionListParsed, false, {}, "id");
    //* ** Save the results
    const { saved, errors } = await saveSessionList([...toInsert, ...toUpdate]);
    return { saved, errors };
  } catch (e) {
    return { errors: [e.message] };
  }
};

const sessionListValidation = async (sessionList) => {
  const productCodesChecked = {};
  const ncodes = [];
  const errors = [];
  for (let index = 0; index < sessionList.length; index += 1) {
    const rowId = index + 2;

    const session = sessionList[index];

    try {
      sessionValidation(session);
    } catch (e) {
      errors.push(`Fila ${rowId}: ${e.message}`);
    }

    if (productCodesChecked[session.productCode] === undefined) {
      try {
        await fetchProduct(session.productCode);
        productCodesChecked[session.productCode] = true;
      } catch (e) {
        errors.push(`Fila ${rowId}: Codigo de producto <${session.productCode}> inexistente`);
        productCodesChecked[session.productCode] = false;
      }
    } else if (!productCodesChecked[session.productCode]) {
      errors.push(`Fila ${rowId}: Codigo de producto <${session.productCode}> inexistente`);
    }

    if (session.id !== "") {
      if (ncodes.includes(session.id)) {
        errors.push(`Fila ${rowId}: ID <${session.id}> duplicado`);
      } else {
        ncodes.push(session.id);
      }
    }
  }

  return errors;
};

const sessionValidation = (product) => {
  [
    // Mandatory
    "id",
    "precinctCode",
    "productCode",
    "enable",
    "time",
    "lang",
  ].forEach((field) => {
    if (product[field] === undefined) {
      throw new Error(`Field <${field}> is required`);
    }
  });
  [
    // Not Empty
    "precinctCode",
    "productCode",
  ].forEach((field) => {
    if (product[field].trim() === "") {
      throw new Error(`Field <${field}> is empty`);
    }
  });
};

function parseEnable(enable) {
  const newEnable = enable;
  if (typeof newEnable === "number") {
    newEnable = enable > 0;
  }
  return newEnable;
}

function parseSessionListIntoBody(sessionList) {
  return sessionList.map((session) => {
    const newSession = session;
    newSession.enable = parseEnable(session.enable);
    newSession.segments = {
      time: session.time,
      lang: session.lang,
    };
    delete newSession.time;
    delete newSession.lang;
    return newSession;
  });
}

export default bulkUploadSessions;
