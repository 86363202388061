import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import { Grid, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { FaFileUpload, FaCalculator } from "react-icons/fa";
import { TravCard, TravTitleCard, TravBodyCard } from "@atoms/Card/FxCard";
import ButtonSend from "@atoms/Buttons/ButtonSend";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import { getBankData, uploadCSVofBanks, launchBankTransferRecaculation } from "./useBankTransfers";

function BankTransferUpload() {
  const bankData = getBankData();
  const [loading, setLoading] = useState(false);
  const [bankSelected, setBankSelected] = useState("-");

  const changeBank = (e) => {
    setBankSelected(e.target.value);
  };

  const uploadCSV = async (csvFormatted) => {
    const r = confirm(
      `Se va a proceder a subir transferencias de ${bankData[bankSelected]?.name}. ¿Está de acuerdo?`
    );
    if (r) {
      setLoading(true);
      const { totalUploaded, newTransfers } = await uploadCSVofBanks(bankSelected, csvFormatted);
      alert(`Subidas al sistema. Subidas ${totalUploaded}, nuevas transferencias ${newTransfers}`);

      setLoading(false);
    }
  };

  const launchRecaculation = async () => {
    let c = confirm("Vas a lanzar el recalculo de transferencias.");
    if (c) {
      setLoading(true);
      const { ok } = await launchBankTransferRecaculation();
      if (ok) {
        alert("Se ha lanzado el recalculo de transferencias.");
      } else {
        alert("Ha ocurrido algun error al lanzar el recalculo de transferencias");
      }
      setLoading(false);
    }
  };

  return loading ? (
    <CenterLoaderCircle />
  ) : (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <TravCard>
          <TravBodyCard>
            <TravTitleCard>
              <FaFileUpload /> Carga
            </TravTitleCard>
            <Alert severity={"info"} style={{ marginBottom: "20px" }}>
              En esta sección puedes cargar los csv de transferencias que has obtenido de las distintas
              entidades bancarias. Selecciona una entidad bancaria y sube un csv con las transferencias.
              Posteriormente en el bloque de la derecha, puedes lanzar un recálculo para intentar asignar esas
              transferencias a reservas.
            </Alert>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id={"label-for-bank-selector"}>Selecciona una entidad bancaria</InputLabel>
                  <Select
                    labelId={"label-for-bank-selector"}
                    label={"Selecciona una entidad bancaria"}
                    value={bankSelected}
                    onChange={changeBank}
                  >
                    <MenuItem value={"-"} style={{ color: "grey" }}>
                      Selecciona un banco
                    </MenuItem>
                    {Object.keys(bankData).map((bankid, index) => (
                      <MenuItem key={`banks${index}`} value={bankid}>
                        {bankData[bankid].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                {bankSelected !== "-" && (
                  <ButtonUploadCSV
                    style={{ height: "100%", width: "100%" }}
                    ignoreWordcontrol={true}
                    specs={{}}
                    buttonLabel={"Subir CSV"}
                    onResultReady={uploadCSV}
                  />
                )}
              </Grid>
            </Grid>
          </TravBodyCard>
        </TravCard>
      </Grid>
      <Grid item xs={6}>
        <TravCard>
          <TravBodyCard>
            <TravTitleCard>
              <FaCalculator /> Recálculo
            </TravTitleCard>
            <Alert severity={"info"} style={{ marginBottom: "20px" }}>
              Al lanzar el recalculo de transferencias, se comprobaran las entradas subidas en los csv del
              bloque de la izquierda y se intentaran asignar a reservas.
              <br />
              <b>
                Se enviará un informe con el resultado a las personas en{" "}
                <a href="https://fenix.traventia.com/proxyweb/sys_notification/notifications" target="_blank">
                  la lista de notificacion "transferconciliation"
                </a>
                .
              </b>
              <br />
              Las reservas que no se puedan asignar automaticamente se listaran en la seccion{" "}
              <b>"Gestión de transferencias bancarias"</b>.
            </Alert>

            <ButtonSend style={{ width: "100%" }} onClick={launchRecaculation}>
              Lanzar recálculo
            </ButtonSend>
          </TravBodyCard>
        </TravCard>
      </Grid>
    </Grid>
  );
}

BankTransferUpload.propTypes = {};

export default BankTransferUpload;
