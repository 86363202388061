import React from "react";
import Grid from "@material-ui/core/Grid";

function PreviewDefault({ form, cardImg }) {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6} justify="flex-start">
        <img src={cardImg} width={"100%"} style={{ marginRight: "16px" }} />
      </Grid>
      <Grid item xs={6} justify="flex-end">
        {cardImg !== undefined && <span style={{ marginLeft: "16px" }}>{form.socialNetwork.text}</span>}
      </Grid>
    </Grid>
  );
}

export default PreviewDefault;
