import { useState, useEffect } from "react";
import axios from "axios";

import { useNewsletterList } from "../../shared/hooks/useNewsletterThirdPartyFetch";
import NewsletterThirdPartyListActions from "./NewsletterThirdPartyList.actions";

const NewsletterThirdPartyListState = () => {
  const MAX_Newsletters_VIEW = 10;
  const [NewsletterList, setNewsletterList] = useState({
    loadingList: true,
    list: [],
    infoList: { total: 0 },
    pages: 1,
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const channels = ["sendinblue"];
  const [channelSelected, setChannelSelected] = useState("sendinblue"); // TODO: si en algún momento se usa...

  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };
  const setNewsletterToManage = (id) => {
    window.location = `/app/marketing/newsletter/third-party/${id}`;
  };

  const updateNewsletterList = async () => {
    setNewsletterList({
      loadingList: true,
      list: [],
      infoList: { total: 0 },
      pages: 1,
    });
    const { newsletterData } = await useNewsletterList(channelSelected);
    setNewsletterList({
      loadingList: newsletterData.loadingList,
      list: newsletterData.list,
      infoList: {
        total: newsletterData.list.length ?? 0,
      },
      pages: newsletterData.list.length / MAX_Newsletters_VIEW,
    });
  };

  const createNewsletter = async (data) => {
    try {
      const response = await axios.post("/api/v1/marketing-bc/third-party-newsletter", data);
      if (response.status === 200) {
        handleAlertSnackBar(true, "Newsletter creada.");
        await updateNewsletterList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteNewsletter = async (id) => {
    try {
      const response = await axios.delete(`/api/v1/marketing-bc/third-party-newsletter/${id}`);
      if (response.status === 200) {
        handleAlertSnackBar(true, "Newsletter borrada.");
        await updateNewsletterList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNewsletterPreview = async (id) => {
    try {
      const response = await axios.get(`/api/v1/marketing-bc/third-party-newsletter/${id}/preview`);
      if (response.status === 200) {
        handleAlertSnackBar(true, "Preview inicializado.");
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { newsletterActions } = NewsletterThirdPartyListActions({
    setNewsletterToManage,
    updateNewsletterList,
    createNewsletter,
    deleteNewsletter,
    getNewsletterPreview,
  });

  useEffect(() => {
    async function loadData() {
      await updateNewsletterList();
    }
    loadData();
  }, []);

  const tableHead = [
    { code: "id", name: "Id", type: "string" },
    { code: "name", name: "Nombre", type: "string" },
    { code: "subject", name: "Asunto", type: "string" },
    { code: "title", name: "Vista Previa", type: "html" },
    { code: "market", name: "Mercado", type: "market" },
  ];

  return {
    NewsletterList,
    setNewsletterList,
    tableHead,
    MAX_Newsletters_VIEW,
    newsletterActions,
    setNewsletterToManage,
    updateNewsletterList,
    channels,
    channelSelected,
    setChannelSelected,
    alertOpen,
    setAlertOpen,
    alertData,
  };
};

export default NewsletterThirdPartyListState;
