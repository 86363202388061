import axios from "axios";

const API_SHORT_URL = "/api/v1/product-bc/offer-calendar/price/"

// eslint-disable-next-line import/prefer-default-export
 const getOfferBasePrice = async (idOffer) => {
    let response = {};
    try {
        response = await axios.get(`${API_SHORT_URL}/${idOffer}`);
        if (response.status === 200 && response.data) {
            return Math.round((response.data.basePrice/100)/2);
        }
    } catch (error) {
        console.log(error);
    }
    return response;
};

export default getOfferBasePrice;
