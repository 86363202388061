import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

function MarketChip({ market, showLabel = true }) {
  const markets = {
    es: {
      label: "ES",
      flag: "https://tr3.traventia.com/graphic-design/sprites/flags/flags-svg/263-spain.svg",
    },
    pt: {
      label: "PT",
      flag: "https://tr1.traventia.com/graphic-design/sprites/flags/flags-svg/224-portugal.svg",
    },
    fr: {
      label: "FR",
      flag: "https://tr3.traventia.com/graphic-design/sprites/flags/flags-svg/195-france.svg",
    },
    it: {
      label: "IT",
      flag: "https://tr2.traventia.com/graphic-design/sprites/flags/flags-svg/013-italy.svg",
    },
  };

  return (
    <Fragment>
      {markets[market] && <MarketFlag src={markets[market].flag} alt={markets[market].label} />}
      {showLabel && (markets[market] ? markets[market].label : "??")}
    </Fragment>
  );
}

const MarketFlag = styled.img`
  width: 20px;
  margin-right: 5px;
`;

MarketChip.propTypes = {
  market: PropTypes.string,
};

export default MarketChip;
