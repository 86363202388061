import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { FiRefreshCw } from "react-icons/fi";

function ButtonUpdate({ size, variant, disabled, onClick, children }) {
  return (
    <ButtonCustom
      disabled={disabled}
      size={size}
      variant={variant}
      aria-label="add"
      icon={<FiRefreshCw />}
      color="secondary"
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonUpdate;
