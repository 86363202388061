import { saveSuppliersHyperguest } from "./useSuppliersHyperguest";

const saveSuppliersList = async (fullList) => {
  let saved = 0;
  const errors = [];
  for (let index = 0; index < fullList.length; index += 1) {
    const supplier = fullList[index];
    try {
      await saveSuppliersHyperguest(supplier);
      saved += 1;
    } catch (e) {
      errors.push(
        `Error en el codigo ${supplier.id}: ${e.response.data.errors
          .reduce((acc, cv) => {
            acc.push(cv.error);
            return acc;
          }, [])
          .join(",")}`
      );
    }
  }

  return { saved, errors };
};

export default saveSuppliersList;
