import axios from "axios";

const prefix = "/api/v1/ticketing-bc/book/booking-groups";

export const fetchBookingGroups = async (filters) => {
  let params = {};
  if (filters.params !== undefined) {
    params = filters.params;
  }
  if (filters["group.precinctCode"] !== undefined) {
    params = { params: { ...filters } };
  }
  const { data } = await axios.get(`${prefix}/booking-group/`, { params });
  return data.data;
};

export const saveBookingGroup = async (bookingGroup) => {
  const id = bookingGroup.id;
  await axios.put(`${prefix}/booking-group/${id}`, bookingGroup);
};

export const deleteBookingGroup = async (bookingGroup) => {
  const id = bookingGroup.id;
  await axios.delete(`${prefix}/booking-group/${id}`);
};
