import styled from "styled-components";
import { Alert } from "react-bootstrap";

export const AlertNoFerry = styled(Alert)`
  margin-bottom: 0 !important;
  color: #0d3c61;
  background-color: #e8f4fd;
  border-color: #e8f4fd;
`;

export const TitleListServiceFerry = styled.h6`
  position: relative;
  font-weight: bold;
  padding: 5px 10px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0.1rem;
    width: 4px;
    height: 100%;
    background-color: #1bc5bd;
  }
`;

export const ChipNameRoute = styled.span`
  padding: 5px 10px;
  border-radius: 24px;
  background-color: #c9f7f5;
  color: #06a29b !important;
`;

export const ScheduleContainerStyle = styled.div`
  border: 1px solid #ddd;
`;

export const HeaderScheduleStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 10px 5px;
  gap: 5px;
`;
export const HeaderSecundaryScheduleStyle = styled(HeaderScheduleStyle)`
  background-color: #c9f7f5;
  font-weight: bold;
`;
export const CardScheduleStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
`;
export const InputScheduleBoxStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
`;
export const ListIntervaloStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #ddd;
`;
export const IconExcludeStyle = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px -4px 0;
  border-radius: 50%;
  border: 4px solid tomato;
  background-color: white;
`;
export const IconExcludeBarStyle = styled.div`
  position: absolute;
  top: -3px;
  bottom: 0;
  height: 16px;
  left: 5px;
  width: 4px;
  transform: rotate(45deg);
  background-color: tomato;
`;
