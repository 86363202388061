import styled from "styled-components";

export const BoxContentConfiguration = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 20px;
`;

export const BoxContentElements = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const NumberSlide = styled.div`
  padding: 5px;
  height: 30px;
  margin-right: 10px;
  text-align: center;
  color: #fff;
  background-color: #1f1f1f;
  border-radius: 5px;
`;

export const ElementsConfiguration = styled.div`
  flex-grow: 1;
  margin-right: 10px;
`;
