/* eslint-disable no-use-before-define */
import { useState, useEffect, useCallback } from "react";
import { fetchProducts } from "../../Products/useProducts";
import { fetchTickets } from "../../Tickets/useTickets";
import { fetchSessions } from "../../Sessions/useSessions";
import { priceInputData, getChannelAsQueryParms, numberListBetweenTwo } from "../utils";

export const useCalendarPrecinctMassUpdate = ({ precinctCode, channel }) => {
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [selections, setSelections] = useState({});

  const fetchFullProducts = useCallback(
    async (initLoading = true) => {
      setLoadingProducts(initLoading);
      try {
        // console.log("Fetch", precinctCode, channel, year);
        const [products, tickets] = await Promise.all([
          fetchProducts({ params: { precinctCode } }),
          fetchTickets({ params: { precinctCode, ...getChannelAsQueryParms(channel, "channels.channel") } }),
        ]);
        const completeProducts = getCompleteProductsObject({ products, tickets });
        const d = defaultSelection(completeProducts, precinctCode);
        setSelections(d);
        setLoadingProducts(false);
      } catch (e) {
        alert(e.message);
        console.error(e);
        // setLoadingProducts(false);
      }
    },
    [channel, precinctCode]
  );

  useEffect(() => {
    if (channel) {
      fetchFullProducts();
    }
  }, [channel, fetchFullProducts]);

  return { loadingProducts, selections, setLoadingProducts, setSelections };
};

export const useCalendarSessionMassUpdate = ({ precinctCode, channel }) => {
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [selections, setSelections] = useState({});

  const fetchFullProducts = useCallback(
    async (initLoading = true) => {
      setLoadingProducts(initLoading);
      try {
        // console.log("Fetch", precinctCode, channel, year);
        const [products, sessions] = await Promise.all([
          fetchProducts({ params: { precinctCode } }),
          fetchSessions({ params: { precinctCode } }),
        ]);
        const completeProducts = getCompleteProductsObject({ products, sessions });
        const d = defaultSelection(completeProducts, precinctCode);
        setSelections(d);
        setLoadingProducts(false);
      } catch (e) {
        alert(e.message);
        console.error(e);
        // setLoadingProducts(false);
      }
    },
    [precinctCode]
  );

  useEffect(() => {
    if (channel) {
      fetchFullProducts();
    }
  }, [channel, fetchFullProducts]);

  return { loadingProducts, selections, setLoadingProducts, setSelections };
};

function getDefaultValue() {
  return {
    enable: numberListBetweenTwo(1, 7).reduce((a, n) => ({ ...a, [n]: { active: false, value: false } }), {}),
    ...priceInputData
      .filter((p) => p.code !== "enable")
      .reduce(
        (accu, p) => ({
          ...accu,
          [p.code]: numberListBetweenTwo(1, 7).reduce(
            (a, n) => ({ ...a, [n]: { active: false, value: 100 } }),
            {}
          ),
        }),
        {}
      ),
  };
}

function defaultSelection(products, precinctCode) {
  return products.reduce((accu, product) => {
    const { productCode } = product;
    const nuAccu = { ...accu };
    if (nuAccu[precinctCode] === undefined) {
      nuAccu[precinctCode] = {
        precinctCode,
        changed: false,
        value: { ...getDefaultValue() },
        products: {},
      };
    }
    nuAccu[precinctCode].products[productCode] = {
      precinctCode,
      productCode,
      changed: false,
      value: { ...getDefaultValue() },
      tickets: product.tickets.reduce(
        (accuT, ticket) => ({
          ...accuT,
          [ticket.ticketId]: { ...ticket, changed: false, value: { ...getDefaultValue() } },
        }),
        {}
      ),
      sessions: product.sessions.reduce(
        (accuS, session) => ({
          ...accuS,
          [session.sessionId]: { ...session, changed: false, value: { ...getDefaultValue() } },
        }),
        {}
      ),
    };
    return nuAccu;
  }, {});
}

function getCompleteProductsObject({ products, tickets, sessions }) {
  return products.map((product) => ({
    precinctCode: product.precinctCode,
    productCode: product.code,
    tickets:
      tickets !== undefined
        ? tickets
            .filter((t) => t.productCode === product.code)
            .map((ticket) => ({ ticketId: ticket.id, alias: ticket.alias }))
        : [],
    sessions:
      sessions !== undefined
        ? sessions.filter((s) => s.productCode === product.code).map((session) => ({ sessionId: session.id }))
        : [],
  }));
}
