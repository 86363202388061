import React, { useState } from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";
import { FiPlusSquare } from "react-icons/fi";
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";

const NewsletterThirdPartyListView = ({
  NewsletterList,
  setNewsletterList,
  tableHead,
  MAX_Newsletters_VIEW,
  newsletterActions,
  setNewsletterToManage,
  updateNewsletterList,
  channels,
  channelSelected,
  setChannelSelected,
  alertOpen,
  setAlertOpen,
  alertData,
}) => {
  const goToAdd = () => {
    window.location = "/app/marketing/newsletter/third-party/new";
  };
  return (
    <>
      <CardTitle
        title={`Campañas ${channelSelected}`}
        buttons={[
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Canal</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={channelSelected}
              label="Canales"
              onChange={setChannelSelected}
            >
              {channels.map((channel, index) => (
                <MenuItem key={`channel${index}`} value={channel}>
                  {channel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>,
          <ButtonAdd key={"bt5"} variant="primary" size="md" onClick={goToAdd}>
            <FiPlusSquare />
          </ButtonAdd>,
        ]}
      />
      <FenixTable
        loading={NewsletterList.loadingList}
        tableHead={tableHead}
        tableContent={NewsletterList.list}
        actions={newsletterActions}
        maxNumberPerPages={MAX_Newsletters_VIEW}
        pagination={true}
        isSelectable={false}
      />
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
    </>
  );
};

export default NewsletterThirdPartyListView;
