import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Fab, ButtonGroup, Button } from "@material-ui/core";
import moment from "moment";
import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import InputText from "@atoms/Inputs/InputText";
import OccupancyInputs from "@molecules/Forms/OccupancyInputs";
import SelectorWithInputQuery from "@molecules/Selectors/SelectorWithInputQuery";
import HotelbedsApitudeSearchResults from "./HotelbedsApitudeSearchResults";
import searchHotelbedsAPI from "./useHotelbedsApitude";
import destinations from "./hotelbedsDestinations.json"; // RMR: Si necesitan mas paises aqui: Postman > Ticketing BC > Third party > Apitude > Content_Api > 01 y 02

const HotelbedsApitudeSearchInterface = () => {
  const filterTypes = [
    { code: "destination", name: "Por zona de destino" },
    { code: "service", name: "Por actividad" },
    { code: "service_modality", name: "Por modalidad" },
  ];

  const destinationList = Object.keys(destinations)
    .map((country) =>
      destinations[country].map((zone) => ({
        ...zone,
        text: `${zone.code} - ${zone.name} (${country})`,
        country,
      }))
    )
    .reduce((accu, tarray) => [...accu, ...tarray]);

  const [listToShow, setListToShow] = useState(destinationList);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchParams, setSearchParams] = useState({
    datei: moment().format("YYYY-MM-DD"),
    dateo: moment().format("YYYY-MM-DD"),
    zone: { code: "", text: "" },
    filterType: "destination", // destination (por defecto), service (activityCode), service_modality
    occupancy: "",
    adl: 1,
    chl: 0,
    chla: [],
  });
  const [frozenParams, setFrozenParams] = useState({});

  const onChangeQuery = async (query) => {
    if (query.length < 2) {
      return;
    }
    const filteredList = destinationList.filter((d) => d.text.toLowerCase().includes(query.toLowerCase()));
    setListToShow(filteredList);
  };

  const selectZone = (zone) => {
    setSearchParams({ ...searchParams, zone });
  };

  function changeType(newType) {
    setSearchParams({ ...searchParams, zone: { code: "", text: "" }, filterType: newType });
  }

  async function searchAPICaldea() {
    if (searchParams.zone?.code === undefined) {
      alert("Introduce una zona");
      return;
    }

    setLoadingSearch(true);

    let { code: zoneCode } = searchParams.zone;
    if (searchParams.filterType === "service_modality") {
      zoneCode = zoneCode.split("#").reverse().join("#");
    }

    const res = await searchHotelbedsAPI(
      zoneCode,
      searchParams.filterType,
      searchParams.datei,
      searchParams.dateo,
      [...Array(searchParams.adl).fill(30), ...searchParams.chla].join("|")
    );
    setFrozenParams({ ...searchParams });
    setSearchResult(res);
    setLoadingSearch(false);
  }

  return (
    <Row className="justify-content-md-center">
      <Col xs={12}>
        <Card>
          <Card.Header>
            <h3>Buscador de entradas</h3>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <ButtonGroup variant="contained" size="small">
                  {filterTypes.map((type) => (
                    <Button
                      key={type.code}
                      onClick={() => changeType(type.code)}
                      color={searchParams.filterType === type.code && "primary"}
                    >
                      {type.name}
                    </Button>
                  ))}
                </ButtonGroup>
                {searchParams.filterType === "destination" && (
                  <SelectorWithInputQuery
                    elements={listToShow.slice(0, 10)}
                    onSelect={selectZone}
                    onChangeQuery={onChangeQuery}
                    placeholder={"Zona de destino"}
                    textOfAoutside={searchParams.zone?.text || ""}
                    device={"desktop"}
                    icon={"search"}
                    additionalFormBorderProps={{ width: "100%" }}
                  />
                )}
                {(searchParams.filterType === "service" ||
                  searchParams.filterType === "service_modality") && (
                  <div style={{ marginTop: "15px" }}>
                    <InputText
                      title={"Introduce un codigo"}
                      value={searchParams.zone.code}
                      onChange={({ value }) => selectZone({ code: value, text: "" })}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <FxDatePickersFromTo
                labelFrom={"Desde"}
                dateFromInitial={searchParams.datei}
                labelTo={"Hasta"}
                dateToInitial={searchParams.dateo}
                onChange={({ datei, dateo }) => {
                  setSearchParams({ ...searchParams, datei, dateo });
                }}
              />
            </Row>
            <hr />

            <OccupancyInputs
              adults={searchParams.adl}
              childs={searchParams.chl}
              childAges={searchParams.chla}
              onChange={(newSearchParameters) => {
                setSearchParams({
                  ...searchParams,
                  adl: newSearchParameters.adults,
                  chl: newSearchParameters.childs,
                  chla: newSearchParameters.childAges,
                });
              }}
            />
            <hr />
            <Row>
              <Col>
                <Fab
                  style={{ width: "100%" }}
                  variant="extended"
                  size="large"
                  color="secondary"
                  onClick={searchAPICaldea}
                >
                  Buscar
                </Fab>
              </Col>
            </Row>

            {loadingSearch ? (
              <CenterLoaderCircle />
            ) : (
              <>
                {Object.keys(frozenParams).length > 0 && (
                  <HotelbedsApitudeSearchResults
                    frozenSearchParams={frozenParams}
                    searchResult={searchResult}
                  />
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default HotelbedsApitudeSearchInterface;
