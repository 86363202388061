import React, { useState, useEffect, useCallback } from "react";
import FenixTable from "@molecules/Tables/FenixTable";
import TicketsSectionMenu from "./SessionsSectionMenu";
import { fetchSessions } from "./useSessions";
import UploadResultAlert from "../shared/UploadResultAlert";

function SessionsSection({ precinctCode }) {
  const [loadingTable, setLoadingTable] = useState(true);
  const [ticketList, setProductList] = useState([]);
  const [uploadResult, setUploadResult] = useState();

  const fetchTicketList = useCallback(async () => {
    setLoadingTable(true);
    const tickets = await fetchSessions({ params: { precinctCode } });
    setProductList(tickets);
    setLoadingTable(false);
  }, [precinctCode]);

  useEffect(() => {
    fetchTicketList();
  }, [fetchTicketList, uploadResult]);

  const tableHead = [
    { code: "id", name: "ID", type: "string" },
    { code: "enable", name: "Activo", type: "boolean", text: { y: "Habilitado", n: "Deshabilitado" } },
  ];

  const onUploadEnded = (resultUpload) => {
    let status = "success";
    if (resultUpload.errors.length > 0) {
      status = resultUpload.saved && resultUpload.saved > 0 ? "warning" : "error";
    }

    setUploadResult({ status, ...resultUpload });
  };

  return (
    <>
      {uploadResult && <UploadResultAlert uploadResult={uploadResult} />}
      <FenixTable
        loading={loadingTable}
        tableTitle={
          <TicketsSectionMenu
            precinctCode={precinctCode}
            ticketList={ticketList}
            onUploadEnded={onUploadEnded}
          />
        }
        tableHead={tableHead}
        tableContent={ticketList}
        actions={[]}
      />
    </>
  );
}

SessionsSection.propTypes = {};

export default SessionsSection;
