/**
 *
 * @param {*} response
 */

// eslint-disable-next-line import/prefer-default-export
export const parsedResponseInForm = (response, id, tld) => {
  const { head, body } = response;
  return {
    id: response.id ?? id,
    name: response.name ?? "",
    path: response.path ?? "",
    tld: response.tld ?? tld,
    tags: response.tags ?? [],
    title: head ? head.title ?? "" : "",
    description: head ? head.description ?? "" : "",
    keywords: head ? head.keywords.split(",").filter((cv) => cv !== "") ?? [] : [],
    // indexable: head ? head.indexable : false,
    json: body ? body.json ?? "" : "",
    etag: body ? body.etag ?? "" : "",
    indexable: head ? head.indexable : false,
  };
};

export const getFormEmpty = (id, tld) => {
  return {
    id,
    name: "",
    path: "",
    title: "",
    tld,
    tags: [],
    description: "",
    keywords: [],
    indexable: false,
    json: "",
    etag: "",
  };
};

export const parsedFormInJsonPage = (form) => {
  return {
    id: form.id,
    name: form.name,
    path: form.path,
    tld: form.tld,
    tags: form.tags,
    head: {
      title: form.title,
      description: form.description,
      keywords: form.keywords.join(","),
      indexable: form.indexable,
    },
    body: {
      json: form.json,
      etag: form.etag,
    },
  };
};

export const parsedFormInJsonPagePublish = (form) => {
  return { page: parsedFormInJsonPage(form), versionComment: "", id: form.id };
};

export const prettyJson = (json) => {
  const jsonParsed = JSON.parse(json.replace(/(\r\n|\n|\r)/gm, "").trim());
  return JSON.stringify(jsonParsed, undefined, 4);
};

export const openInNewTab = (url) => {
  // eslint-disable-next-line no-undef
  window.open(url, "", "width=800,height=1200");
};
