import React from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import HotelbedsApitudeSearchInterface from "@organisms/Ticketing/ApiSearchers/HotelbedsApitude/HotelbedsApitudeSearchInterface";

// Styles
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

const HotelbedsApitudeApiPage = () => {
  return (
    <ContainerFenix>
      <CardTitle title={"Buscador API Hotelbeds Apitude"} />
      <HotelbedsApitudeSearchInterface />
    </ContainerFenix>
  );
};

export default HotelbedsApitudeApiPage;
