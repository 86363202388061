/* eslint-disable import/no-unresolved */
import React, { Fragment } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { ContOccupancy, FormControOccuppancy, DistributionDefault } from "./Occupancy.styles";
import { TextField } from "@material-ui/core";
import Distribution from "../CombinationDistributions/CombinationDistributions";
import { UsCardTitle, LeftEnable } from "@atoms/Card/UsCard.styles";

function Occupancy({ form, onChange }) {
  let defaultOccupancy = form.offerCriteria.defaultOccupancy;
  let formOccupancy = form.offerCriteria.occupancy;

  const changeAmountAdults = (value) => {
    const nuForm = { ...form };
    nuForm.offerCriteria.defaultOccupancy.adults = value.target.value;
    onChange(nuForm);
  };

  const changeAmountKids = (value) => {
    const nuForm = { ...form };
    nuForm.offerCriteria.defaultOccupancy.kids = fillerKids(value.target.value);
    onChange(nuForm);
  };

  const fillerKids = (kids) => {
    let counterKids = form.offerCriteria.defaultOccupancy.kids;

    while (kids < counterKids.length) {
      counterKids.pop();
    }

    while (kids > counterKids.length) {
      counterKids.push(formOccupancy.types.kids.refAge);
    }

    return counterKids;
  };

  const changeStatusBabies = () => {
    const nuForm = { ...form };
    nuForm.offerCriteria.occupancy.types.babies.accept = !nuForm.offerCriteria.occupancy.types.babies.accept;
    onChange(nuForm);
  };

  const changeStatusKids = () => {
    const nuForm = { ...form };
    nuForm.offerCriteria.occupancy.types.kids.accept = !nuForm.offerCriteria.occupancy.types.kids.accept;
    onChange(nuForm);
  };

  const onChangeDistribution = (value) => {
    const nuForm = { ...form };
    nuForm.offerCriteria.occupancy.occupants = value;
    onChange(nuForm);
  };

  const changeYearKid = (value, index) => {
    if (value > formOccupancy.types.kids.maxAge || value < formOccupancy.types.babies.minAge) {
      return;
    }
    form.offerCriteria.defaultOccupancy.kids[index] = value;
    onChange({ ...form });
  };

  const changeValueConfi = (value, type, typeAge) => {
    if (value < 0) {
      return;
    }
    if ((typeAge === "minAge" || typeAge === "refAge") && value > formOccupancy.types[type].maxAge) {
      return;
    }
    if ((typeAge === "maxAge" || typeAge === "refAge") && value < formOccupancy.types[type].minAge) {
      return;
    }
    const nuForm = { ...form };
    if (typeAge === "maxAge" && formOccupancy.types[type].maxAge < formOccupancy.types[type].refAge) {
      nuForm.offerCriteria.occupancy.types[type]["refAge"] = parseInt(value);
    }
    if (typeAge === "minAge" && formOccupancy.types[type].minAge > formOccupancy.types[type].refAge) {
      nuForm.offerCriteria.occupancy.types[type]["refAge"] = parseInt(value);
    }
    nuForm.offerCriteria.occupancy.types[type][typeAge] = parseInt(value);
    onChange(nuForm);
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12} md={4}>
                  <ContOccupancy>
                    <UsCardTitle>
                      <span>Distribución por defecto</span>
                    </UsCardTitle>
                    <br />
                    <Row>
                      <Col md={12}>
                        <DistributionDefault>
                          <FormControOccuppancy
                            styled={{ marginBottom: "5px" }}
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <TextField
                              disabled
                              defaultValue={formOccupancy.types.kids.refAge}
                              label="Adultos"
                              onChange={changeAmountAdults}
                              defaultValue={defaultOccupancy.adults}
                              type="number"
                              size="small"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControOccuppancy>
                        </DistributionDefault>
                      </Col>
                      <Col xs={12}>
                        <DistributionDefault key={"div-kids-selector"}>
                          <FormControOccuppancy
                            key={"form-kids-selector"}
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <TextField
                              disabled
                              id={"btn-kids-selector"}
                              label="Niños"
                              onChange={changeAmountKids}
                              defaultValue={defaultOccupancy.kids.length}
                              type="number"
                              size="small"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControOccuppancy>
                        </DistributionDefault>
                      </Col>
                      <Col xs={12}>
                        <DistributionDefault key={"div-kids"}>
                          <Row>
                            {defaultOccupancy.kids.map((cv, i) => {
                              return (
                                <Col xs={6} lg={4} key={`div-kids-col${i}`}>
                                  <FormControOccuppancy variant="outlined" size="small">
                                    <TextField
                                      id={`btn-kid-${i}`}
                                      defaultValue={formOccupancy.types.kids.refAge}
                                      label={"Niño " + i}
                                      type="number"
                                      size="small"
                                      variant="outlined"
                                      value={cv}
                                      onChange={(value) => changeYearKid(parseInt(value.target.value), i)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </FormControOccuppancy>
                                </Col>
                              );
                            })}
                          </Row>
                        </DistributionDefault>
                      </Col>
                    </Row>
                  </ContOccupancy>
                  <br />
                </Col>
                <Col xs={12} md={8}>
                  {/* <ContOccupancy>
                    <UsCardTitle>
                      <span>Personas permitidas</span>
                    </UsCardTitle>
                    <Row>
                      <Col>
                        <UsCardTitle>
                          <h6>Niños</h6>
                          <LeftEnable>
                            <ButtonGroup disableElevation aria-label="outlined  primary button group">
                              <Button
                                variant={formOccupancy.types.kids.accept ? "contained" : "outlined"}
                                color={"primary"}
                                onClick={changeStatusKids}
                              >
                                SI
                              </Button>
                              <Button
                                variant={formOccupancy.types.kids.accept ? "outlined" : "contained"}
                                color={formOccupancy.types.kids.accept ? "" : "primary"}
                                onClick={changeStatusKids}
                              >
                                NO
                              </Button>
                            </ButtonGroup>
                          </LeftEnable>
                        </UsCardTitle>
                        {formOccupancy.types.kids.accept && (
                          <div>
                            <DistributionDefault>
                              <FormControOccuppancy variant="outlined" size="small" fullWidth>
                                <TextField
                                  label="Edad Máxima"
                                  onChange={(value) => changeValueConfi(value.target.value, "kids", "maxAge")}
                                  defaultValue={formOccupancy.types.kids.maxAge}
                                  type="number"
                                  size="small"
                                  variant="outlined"
                                  value={formOccupancy.types.kids.maxAge}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControOccuppancy>
                            </DistributionDefault>
                            <DistributionDefault>
                              <FormControOccuppancy variant="outlined" size="small" fullWidth>
                                <TextField
                                  label="Edad Mínima"
                                  onChange={(value) => changeValueConfi(value.target.value, "kids", "minAge")}
                                  defaultValue={formOccupancy.types.kids.minAge}
                                  type="number"
                                  size="small"
                                  value={formOccupancy.types.kids.minAge}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControOccuppancy>
                            </DistributionDefault>
                            <DistributionDefault>
                              <FormControOccuppancy variant="outlined" size="small" fullWidth>
                                <TextField
                                  label="Edad por defecto"
                                  onChange={(value) => changeValueConfi(value.target.value, "kids", "refAge")}
                                  defaultValue={formOccupancy.types.kids.refAge}
                                  type="number"
                                  size="small"
                                  variant="outlined"
                                  value={formOccupancy.types.kids.refAge}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControOccuppancy>
                            </DistributionDefault>
                          </div>
                        )}
                      </Col>
                      <Col>
                        <UsCardTitle>
                          <h6>Bebés</h6>
                          <LeftEnable>
                            <ButtonGroup disableElevation aria-label="outlined  primary button group">
                              <Button
                                variant={formOccupancy.types.babies.accept ? "contained" : "outlined"}
                                color={"primary"}
                                onClick={changeStatusBabies}
                              >
                                SI
                              </Button>
                              <Button
                                variant={formOccupancy.types.babies.accept ? "outlined" : "contained"}
                                color={formOccupancy.types.babies.accept ? "" : "primary"}
                                onClick={changeStatusBabies}
                              >
                                NO
                              </Button>
                            </ButtonGroup>
                          </LeftEnable>
                        </UsCardTitle>
                        {formOccupancy.types.babies.accept && (
                          <div>
                            <DistributionDefault>
                              <FormControOccuppancy variant="outlined" size="small" fullWidth>
                                <TextField
                                  label="Edad Máxima"
                                  onChange={(value) =>
                                    changeValueConfi(value.target.value, "babies", "maxAge")
                                  }
                                  defaultValue={formOccupancy.types.babies.maxAge}
                                  type="number"
                                  size="small"
                                  variant="outlined"
                                  value={formOccupancy.types.babies.maxAge}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControOccuppancy>
                            </DistributionDefault>
                            <DistributionDefault>
                              <FormControOccuppancy variant="outlined" size="small" fullWidth>
                                <TextField
                                  label="Edad Mínima"
                                  onChange={(value) =>
                                    changeValueConfi(value.target.value, "babies", "minAge")
                                  }
                                  defaultValue={formOccupancy.types.babies.minAge}
                                  type="number"
                                  size="small"
                                  variant="outlined"
                                  value={formOccupancy.types.babies.minAge}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControOccuppancy>
                            </DistributionDefault>
                            <DistributionDefault>
                              <FormControOccuppancy variant="outlined" size="small" fullWidth>
                                <TextField
                                  label="Edad por defecto"
                                  onChange={(value) =>
                                    changeValueConfi(value.target.value, "babies", "refAge")
                                  }
                                  defaultValue={formOccupancy.types.babies.refAge}
                                  type="number"
                                  size="small"
                                  variant="outlined"
                                  value={formOccupancy.types.babies.refAge}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControOccuppancy>
                            </DistributionDefault>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </ContOccupancy> */}
                  <br />
                </Col>
                {/* <Col xs={12} md={12}>
                  <ContOccupancy>
                    <Distribution distributions={formOccupancy.occupants} onChange={onChangeDistribution} />
                  </ContOccupancy>
                </Col> */}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Occupancy;
