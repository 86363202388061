import React, { useState, useEffect } from "react";
import FenixTableCustomPagination from "@molecules/Tables/FenixTable/FenixTableCustomPagination";
import Modal from "react-bootstrap/Modal";
import Grid from "@material-ui/core/Grid";
import { AiOutlineSave } from "react-icons/ai";
import { TextField } from "@material-ui/core";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { useCookiesGroup, putCookie, deleteCookie } from "../../hooks/userCookieGroupFetch";
import SectionTitle from "@atoms/Titles/SectionTitle";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import { InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import { uuid_v4 } from "@lib/helpers/uuid_v4";
import { MdClear, MdOutlineModeEditOutline } from "react-icons/md";
import { LS_getItem, LS_removeItem } from "@lib/storage/localStorage";
import { useHistory } from "react-router-dom";

const CookiesList = ({}) => {
  const history = useHistory();
  const [cookiesGroupCode, setCookiesGroupCode] = useState(
    LS_getItem("cookieGroupCode") === null ? "" : LS_getItem("cookieGroupCode")
  );
  const [forceReloadTable, setForceReloadTable] = useState(0);
  const [alertData, setAlertData] = useState({ text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorModal, setErrorModal] = useState({ open: false, errors: [] });
  const [modal, setModal] = useState({
    open: false,
    id: "",
    isNew: true,
    name: "",
    description: { es: "", fr: "", pt: "", it: "" },
    cookieGroupCode: "",
    "@created_on": "",
    "@updated_on": "",
    tld: "es",
  });
  const [cookiesGroup, setCookiesGroup] = useState({
    loadingList: true,
    list: [],
  });

  useEffect(() => {
    handleUseCookiesGroup();
    LS_removeItem("cookieGroupCode");
  }, []);

  const handleUseCookiesGroup = async () => {
    let cookiesGroup = await useCookiesGroup();
    setCookiesGroup(cookiesGroup);
    const newModal = { ...modal };
    newModal.cookieGroupCode = cookiesGroup.list[0].code || "";
    setModal(newModal);
  };

  const resetModal = () => {
    setModal({
      open: false,
      id: "",
      isNew: true,
      name: "",
      description: { es: "", fr: "", pt: "", it: "" },
      cookieGroupCode: "",
      "@created_on": "",
      "@updated_on": "",
      tld: "es",
    });
  };

  const addNewCookie = () => {
    resetModal();
    const newModal = { ...modal };
    newModal.id = uuid_v4();
    newModal.open = true;
    newModal.isNew = true;
    setModal(newModal);
  };

  const actionVisualice = async (rowData) => {
    setModal({
      open: true,
      isNew: false,
      ...rowData,
      tld: "es",
    });
  };

  const cookieValidation = (point) => {
    const errors = [];
    if (point.name === "") {
      errors.push(`El código de hotel está vacío.`);
    }
    if (point.description["es"] === "") {
      errors.push(`La descripción en Español está vacía.`);
    }
    if (point.description["pt"] === "") {
      errors.push(`La descripción en portugués está vacía.`);
    }
    if (point.description["fr"] === "") {
      errors.push(`La descripción en francés está vacía.`);
    }
    if (point.description["it"] === "") {
      errors.push(`La descripción en Italiano está vacía.`);
    }
    if (point.cookieGroupCode === "") {
      errors.push(`Tienes que seleccionar un grupo.`);
    }

    return errors;
  };

  const saveCookie = async (row) => {
    const nuRow = { ...row };
    const error = cookieValidation(nuRow);
    if (error.length > 0) {
      setErrorModal({
        open: true,
        errors: error,
      });
    } else {
      const response = await putCookie(nuRow);
      if (response.ok) {
        setAlertOpen(true);
        setAlertData({ text: `Cookie modificada con éxito` });
        setForceReloadTable(forceReloadTable + 1);
        resetModal();
      } else {
        resetModal();
        setErrorModal({
          open: true,
          errors: [`Error al guardar la información de ${row.id}: ${response.error}`],
        });
      }
    }
  };

  const backToTable = () => {
    LS_removeItem("cookieGroupCode");
    history.push(`/app/content/cookies-group/list`);
  };

  const actionDeleteCookie = async (rowData) => {
    const deleteConfirm = confirm(`¿Está seguro de eliminar la cookie ${rowData.id}?`);
    if (deleteConfirm) {
      const response = await deleteCookie(rowData);
      if (response.ok) {
        setAlertOpen(true);
        setAlertData({ text: `Cookie borrada con éxito` });
      } else {
        setErrorModal({
          open: true,
          errors: [`Error al borrar la cookie de ${rowData.code}`],
        });
      }
      resetModal();
      setForceReloadTable(forceReloadTable + 1);
    }
  };

  const tableHead = [
    { code: "id", name: "id", type: "string" },
    { code: "cookieGroupCode", name: "Grupo", type: "string" },
    { code: "name", name: "Nombre", type: "string" },
    { code: "@updated_on", name: "Ultima vez Actualizado", type: "string" },
  ];

  const actions = [
    { name: "Editar", icon: <MdOutlineModeEditOutline />, func: actionVisualice },
    { name: "Borrar", icon: <MdClear />, func: actionDeleteCookie },
  ];

  return (
    <>
      <SectionTitle
        title={"🍪 Listado de Cookies 🍪"}
        backFunction={backToTable}
        buttons={[
          <ButtonAdd
            key={"buton_add"}
            size="small"
            onClick={() => {
              addNewCookie();
            }}
          >
            Añadir
          </ButtonAdd>,
        ]}
      />

      <FenixTableCustomPagination
        loading={cookiesGroup.loadingList}
        tableHead={tableHead}
        actions={actions}
        customPaginationFetchUrl={`/api/v1/product-bc/content/cookies`}
        searchQueryParam={["cookieGroupCode[regex]", "name[regex]", "id[regex]"]}
        withSearch={true}
        maxNumberPerPages={15}
        pagination={true}
        isSelectable={false}
        forceReload={forceReloadTable}
        inputTextExternal={cookiesGroupCode}
      />

      <Modal show={modal.open} onHide={resetModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Información de la cookie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={5}>
              <TextField disabled={true} label="ID" value={modal.id} />
            </Grid>
            <Grid item>
              <TextField
                disabled={false}
                label="Nombre"
                value={modal.name}
                onChange={(nVal) => {
                  const nuModal = { ...modal };
                  nuModal.name = nVal.target.value;
                  setModal(nuModal);
                }}
              />
            </Grid>
            <Grid item>
              <InputLabel id="GDC">Grupo de cookie</InputLabel>
              <Select
                labelId="GDCS"
                labelWidth={130}
                autoWidth={true}
                value={modal.cookieGroupCode}
                onChange={(selectedNow) => {
                  const nuModal = { ...modal };
                  nuModal.cookieGroupCode = selectedNow.target.value;
                  setModal(nuModal);
                }}
              >
                {cookiesGroup.list.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.code}>
                      {item.code}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                  <h6 style={{ color: "silver" }}>Descripción</h6>
                </Grid>
                <Grid item>
                  <SelectMarket
                    selection={"es"}
                    onChangeMarket={(nVal) => {
                      const nuModal = { ...modal };
                      nuModal.tld = nVal;
                      setModal(nuModal);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <textarea
                    style={{ height: "170px", width: "100%" }}
                    name="Texto"
                    rows="10"
                    cols="50"
                    value={modal.description[modal.tld]}
                    disabled={false}
                    placeholder={"Texto de la publicación"}
                    onChange={(nVal) => {
                      const nuModal = { ...modal };
                      nuModal.description[modal.tld] = nVal.target.value;
                      setModal(nuModal);
                    }}
                  ></textarea>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            {!modal.isNew && (
              <Grid item>
                <ButtonCustom
                  key={"btDelete"}
                  customcolor="tomato"
                  icon={<MdClear />}
                  onClick={() => {
                    actionDeleteCookie(modal);
                  }}
                >
                  Borrar
                </ButtonCustom>
              </Grid>
            )}
            <Grid item>
              <ButtonCustom
                key={"btSend"}
                customcolor="green"
                icon={<AiOutlineSave />}
                onClick={() => {
                  const newModal = { ...modal };
                  delete newModal.open;
                  saveCookie(newModal);
                }}
              >
                Guardar
              </ButtonCustom>
            </Grid>
          </Grid>
        </Modal.Footer>
      </Modal>
      <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
      <ErrorModal
        show={errorModal.open}
        listErrors={errorModal.errors}
        onClose={() => setErrorModal({ open: false, errors: [] })}
      />
    </>
  );
};

export default CookiesList;
