import styled from "styled-components";
import { Button } from "react-bootstrap";

export const ButtonDialog = styled(Button)`
  background-color: #006699;
  color: #fff;
  border-color: #006699;
  &:hover {
    background-color: #0a4c6d;
    border-color: #0a4c6d;
    color: #fff;
  }
`;
