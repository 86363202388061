import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Popover,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { MdFilterList } from "react-icons/md";
import { ContButtonsFileter } from "./PopoverFilters.styles";

const useStyles = makeStyles((theme) => ({ typography: { padding: theme.spacing(2) } }));

const PopoverFilters = ({ columnConfig, column, onChange }) => {
  const classes = useStyles();
  const popRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [optionsChecked, setOptionsChecked] = useState([]);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setOptionsChecked(column.options.map((value) => ({ value, checked: false })));
  }, [column]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeFilter = (e) => {
    const index = e.target.value;
    const nuOp = [...optionsChecked];
    nuOp[index].checked = !nuOp[index].checked;
    setOptionsChecked(nuOp);
  };

  const deleteFilters = () => {
    const nuOp = [...optionsChecked].map((op) => ({ ...op, checked: false }));
    setOptionsChecked(nuOp);
    onChange(nuOp);
    handleClose();
  };

  const applyFilters = () => {
    onChange(optionsChecked);
    handleClose();
  };

  return (
    <>
      <IconButton
        size="small"
        aria-label="Filter"
        aria-describedby={id}
        variant="contained"
        color={optionsChecked.filter((op) => op.checked).length > 0 ? "primary" : "secondary"}
        onClick={handleClick}
        ref={popRef}
      >
        <MdFilterList fontSize="small" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={popRef.current}
        anchorReference="anchorEl"
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Typography component={"div"} className={classes.typography}>
          <FormGroup>
            {optionsChecked.map((option, index) => {
              let labelText = "";
              switch (columnConfig.type) {
                case "label":
                  if (columnConfig.translation[option.value]) {
                    labelText = columnConfig.translation[option.value].name;
                  } else {
                    labelText = option.value;
                  }
                  break;

                default:
                  labelText = option.value;
                  break;
              }

              return (
                <FormControlLabel
                  key={`option_f_${index}`}
                  value={index}
                  control={<Checkbox color="primary" />}
                  label={labelText}
                  labelPlacement="end"
                  checked={option.checked}
                  onChange={changeFilter}
                />
              );
            })}
          </FormGroup>
          <ContButtonsFileter>
            <ButtonDelete size="small" variant="outlined" onClick={deleteFilters}>
              Borrar
            </ButtonDelete>
            <ButtonCustom size="small"  color="primary"  onClick={applyFilters}>
              OK
            </ButtonCustom>
          </ContButtonsFileter>
        </Typography>
      </Popover>
    </>
  );
};

PopoverFilters.propTypes = {
  column: PropTypes.object,
  onChange: PropTypes.func,
};

export default PopoverFilters;
