import React, { Fragment } from "react";
import moment from "moment";
import { Alert } from "react-bootstrap";
import { FormControl, Select, InputLabel, Input } from "@material-ui/core";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import CardTitle from "@atoms/Titles/CardTitle";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import { FiPlusSquare } from "react-icons/fi";
import { Card, Row, Col } from "react-bootstrap";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { DivCenter, ButtonOK, ButtonDelete } from "./OfferTags.styles";
import FxSwitch from "@atoms/Switches/FxSwitch";
import FxDatePicker from "@atoms/Pickers/FxDatePicker";
import OfferTagsList from "../../components/OfferTagsComponents/OfferTagsList/OfferTagsList";
const OfferTagsView = ({
  wordcontrol,
  errorState,
  tableHead,
  actions,
  specs,
  head,
  tagTypes,
  newTag,
  showInputForNewTag,
  offerTags,
  type,
  market,
  resetNewTag,
  setNewTag,
  setShowInput,
  setErrors,
  setType,
  onChangeMarket,
  setMarket,
  saveTags,
  saveCSV,
}) => {
  return (
    <Fragment>
      <ContainerFenix>
        <CardTitle
          title={"Etiquetas para ofertas"}
          buttons={
            !offerTags.loadingList
              ? [
                  <SelectMarket
                    key={"bt1"}
                    selection={market}
                    onChangeMarket={(value) => {
                      setMarket(value);
                    }}
                  />,
                ]
              : []
          }
        />
        <ErrorModal
          show={errorState.show}
          listErrors={errorState.list}
          onClose={() => setErrors({ show: false, list: [] })}
        />

        {showInputForNewTag && (
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <h3>Nueva Etiqueta</h3>
                </Col>
                <Col xs={1}>
                  <ButtonOK
                    onClick={() => {
                      saveTags([newTag]);
                      setShowInput(false);
                      resetNewTag();
                    }}
                  />
                </Col>
                <Col xs={1}>
                  <ButtonDelete
                    onClick={() => {
                      setShowInput(false);
                      resetNewTag();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormControl key={"btF2"} variant="outlined">
                    <InputLabel>Selecciona un tipo</InputLabel>
                    <Select
                      native
                      value={newTag.type}
                      onChange={(e) => {
                        const nuTag = { ...newTag };
                        nuTag.type = e.target.value;
                        setNewTag(nuTag);
                      }}
                      labelWidth={130}
                      inputProps={{
                        name: "type",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      {Object.keys(tagTypes).map((tag) => {
                        return (
                          <option key={tag} value={tag}>
                            {tagTypes[tag]} ({tag})
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Col>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="component-outlined">Etiqueta</InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      value={newTag.tag}
                      onChange={(e) => {
                        const nuTag = { ...newTag };
                        nuTag.tag = e.target.value;
                        setNewTag(nuTag);
                      }}
                      label="Name"
                    />
                  </FormControl>
                </Col>
                <Col>
                  <Col>
                    <Input
                      type={"number"}
                      defaultValue={0}
                      onChange={(nuValue) => {
                        const nuTag = { ...newTag };
                        nuTag.order = nuValue.target.value;
                        setNewTag(nuTag);
                      }}
                    />
                  </Col>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
        {market === null || type === "-" ? (
          <Alert variant={"info"}>
            <span>Selecciona un mercado</span>
          </Alert>
        ) : (
          <OfferTagsList
            listTags={offerTags.list}
            onChange={(type, list) => {
              saveTags(type, list);
            }}
          />
        )}
      </ContainerFenix>
    </Fragment>
  );
};

export default OfferTagsView;
