import React from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

import BankTransferUpload from "@organisms/Operations/BankTransferUpload";

function BankTransfersUploadPage() {
  return (
    <ContainerFenix>
      <CardTitle title={"Carga de transferencias bancarias"} />
      <BankTransferUpload />
    </ContainerFenix>
  );
}

BankTransfersUploadPage.propTypes = {};

export default BankTransfersUploadPage;
