import React from "react";
import { TextField, FormLabel, Grid, ButtonGroup, Button } from "@material-ui/core";
import ListEditable from "@atoms/Lists/ListEditable";
import FxSwitch from "@atoms/Switches/FxSwitch";
import { TravCardBorder, TravBodyCard } from "@atoms/Card/FxCard";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import { Alert } from "@material-ui/lab";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import SelectLang from "@atoms/Selectors/SelectLang";

function RoomingConfigurationSelector({ channel, onChange }) {
  const isEnableRooming = () => {
    return channel.rooming.enabled;
  };

  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12}>
        <RoomingHead
          rooming={channel.rooming}
          onChange={(newConf) => onChange({ ...channel, rooming: { ...newConf } })}
        />
      </Grid>

      {isEnableRooming() && (
        <>
          <Grid item xs={12}>
            <RoomingDaysToDate
              rooming={channel.rooming}
              onChange={(newConf) => onChange({ ...channel, rooming: { ...newConf } })}
            />
          </Grid>
          <Grid item xs={12}>
            <RoomingLangSelector
              rooming={channel.rooming}
              onChange={(newConf) => onChange({ ...channel, rooming: { ...newConf } })}
            />
          </Grid>
          <Grid item xs={12}>
            <RoomingSendTimeSelector
              rooming={channel.rooming}
              onChange={(newConf) => onChange({ ...channel, rooming: { ...newConf } })}
            />
          </Grid>
          <Grid item xs={12}>
            <RoomingAgeGroup
              rooming={channel.rooming}
              onChange={(newConf) => onChange({ ...channel, rooming: { ...newConf } })}
            />
            <Grid item xs={12}>
              <RoomingEmailStandart
                rooming={channel.rooming}
                onChange={(newRooming) => onChange({ ...channel, rooming: { ...newRooming } })}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

const RoomingHead = ({ rooming, onChange }) => {
  return (
    <Grid container>
      <Grid item>
        <FormLabel style={{ marginTop: "10px" }} component="legend">
          <div className="titlesection">Configuracion de la Rooming</div>
        </FormLabel>
      </Grid>
      <Grid item style={{ marginTop: "5px" }}>
        <Grid item>
          <FxSwitch
            labels={["", ""]}
            status={rooming.enabled}
            onChange={(nuVal) => {
              onChange({ ...rooming, agesGroup: [], emails: { to: [], bcc: [] }, enabled: nuVal });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const RoomingLangSelector = ({ rooming, onChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <FormLabel className="subtitlesection" component="legend">
          Idioma del email
        </FormLabel>
      </Grid>
      <Grid item>
        <SelectLang
          selection={rooming.lang}
          onChangeLang={(nVal) => {
            const nuRooming = { ...rooming };
            nuRooming.lang = nVal;
            onChange(nuRooming);
          }}
        />
      </Grid>
    </Grid>
  );
};

const RoomingSendTimeSelector = ({ rooming, onChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <FormLabel className="subtitlesection" component="legend">
          Momento de envio
        </FormLabel>
      </Grid>

      <Grid item>
        <ButtonGroup className="market-no-select-class" color="primary" aria-label="marketSelector">
          {["AM", "PM"].map((time) => {
            return (
              <Button
                key={`lang_${time}`}
                color={time === rooming.sendTime ? "secondary" : "primary"}
                className={time === rooming.sendTime ? "market-select-class" : "market-no-select-class"}
                onClick={() => {
                  onChange({ ...rooming, sendTime: time });
                }}
              >
                {time === "AM" ? "Mañana (7am/8am)" : "Tarde (7pm/8pm)"}
              </Button>
            );
          })}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

const RoomingDaysToDate = ({ rooming, onChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <FormLabel className="subtitlesection" component="legend">
          Dias de antelación a fecha de servicio
        </FormLabel>
      </Grid>
      <Grid item>
        <TextField
          type={"number"}
          label="Dias"
          variant="outlined"
          size="small"
          InputProps={{ inputProps: { min: 1 } }}
          value={rooming.daysToDate}
          onChange={(nVal) => {
            const nuRooming = { ...rooming };
            nuRooming.daysToDate = nVal.target.value;
            onChange(nuRooming);
          }}
        />
      </Grid>
    </Grid>
  );
};

const RoomingAgeGroup = ({ rooming, onChange }) => {
  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12}>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <FormLabel className="subtitlesection" component="legend">
              Grupo de edades
            </FormLabel>
          </Grid>
          <Grid item>
            <ButtonAdd
              size={"small"}
              key={"btA"}
              onClick={() => {
                const nuRooming = { ...rooming };
                nuRooming.agesGroup.push({
                  ageMin: 0,
                  ageMax: 99,
                  name: `Grupo de edades ${rooming.agesGroup.length + 1}`,
                });
                onChange(nuRooming);
              }}
            >
              Añadir Grupo
            </ButtonAdd>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* <Alert severity={"info"}> */}
        {rooming.agesGroup.length > 0 ? (
          <Grid container spacing={1} justify="space-between">
            {/* <Grid item xs={12}> */}
            {/* <Grid container justify="space-between" direction="row">
                <Alert severity={"info"} icon={false}> */}
            {rooming.agesGroup.map((group, i) => {
              return (
                <Grid item xs={12} key={i}>
                  <TravCardBorder borderleft="4px solid #ddd">
                    <TravBodyCard>
                      <Grid container justify="space-between" direction="row" spacing={2}>
                        <Grid item xs={3}>
                          <TextField
                            label="Nombre"
                            value={group.name}
                            onChange={(nVal) => {
                              const nuRooming = { ...rooming };
                              nuRooming.agesGroup[i].name = nVal.target.value;
                              onChange(nuRooming);
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            type={"number"}
                            label="Min"
                            value={group.ageMin}
                            onChange={(nVal) => {
                              const nuRooming = { ...rooming };
                              nuRooming.agesGroup[i].ageMin = nVal.target.value;
                              onChange(nuRooming);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            type={"number"}
                            label="Max"
                            value={group.ageMax}
                            onChange={(nVal) => {
                              const nuRooming = { ...rooming };
                              nuRooming.agesGroup[i].ageMax = nVal.target.value;
                              onChange(nuRooming);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <ButtonDelete
                            size={"small"}
                            key={"btW"}
                            onClick={() => {
                              const nuRooming = { ...rooming };
                              nuRooming.agesGroup.splice(i, 1);
                              onChange(nuRooming);
                            }}
                          />
                        </Grid>
                        {/* </Grid> */}
                      </Grid>
                    </TravBodyCard>
                  </TravCardBorder>
                </Grid>
              );
            })}
            {/* </Alert>
              </Grid> */}
            {/* </Grid> */}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Alert severity={"info"}>No hay grupos configurados</Alert>
          </Grid>
        )}
        {/* </Alert> */}
      </Grid>
    </Grid>
  );
};

const RoomingEmailStandart = ({ rooming, onChange }) => {
  const to = rooming.emails.to;
  const bcc = rooming.emails.bcc;
  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12}>
        <FormLabel className="subtitlesection" component="legend">
          Emails de notificacion
        </FormLabel>
      </Grid>
      <Grid item xs={6}>
        <ListEditable
          titleLabel={"Emails"}
          info={to}
          onChangeList={(nuList) => {
            const nuRooming = { ...rooming };
            nuRooming.emails.to = nuList;
            return onChange(nuRooming);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <ListEditable
          titleLabel={"Emails BCC"}
          info={bcc}
          onChangeList={(nuList) => {
            const nuRooming = { ...rooming };
            nuRooming.emails.bcc = nuList;
            return onChange(nuRooming);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RoomingConfigurationSelector;
