import axios from "axios";

const parentPath = "/api/v1/apis-accommodation-bc/apis";

export const getSuppliersHyperguest = async () => {
  try {
    const { status, data } = await axios.get(`${parentPath}/hyperguest/suppliers`);
    if (status === 200) {
      return data.map((ctt) => ({ ...ctt, onerror: ctt.onerror !== undefined ? ctt.onerror : false }));
    }
    throw new Error("Error while requesting");
  } catch (error) {
    return [];
  }
};

export const saveSuppliersHyperguest = async (supplier) => {
  await axios.post(`${parentPath}/hyperguest/suppliers`, supplier);
};

export const deleteSupplierHyperguest = async (supplier) => {
  const { status, data } = await axios.delete(
    `${parentPath}/hyperguest/suppliers/${supplier.api}/${supplier.code}`
  );
  if (status === 200) {
    return data.data;
  }
  throw new Error("Error while deleting");
};
