import React from "react";
import Grid from "@material-ui/core/Grid";
import { GoKebabHorizontal } from "react-icons/go";
import { TiMediaRecord } from "react-icons/ti";
import { AiOutlineHeart } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { GoComment } from "react-icons/go";
import { AiFillLike } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";

function PreviewFacebook({ form, cardImg, formaterText }) {
  const getLikes = () => {
    return Math.floor(Math.random() * (100000 - 100 + 1) + 100);
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={2}
      // style={{ border: "solid 1px #D3D3D3" }}
    >
      <Grid item xs={8}>
        <img src={cardImg} width={"100%"} style={{ marginRight: "16px" }} />
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          direction="column"
          justify="space-between"
          spacing={2}
          // style={{ borderLeft: "solid 1px #D3D3D3" }}
        >
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between" alignItems="baseline" spacing={2}>
              <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="baseline" spacing={1}>
                  <Grid item>
                    <img
                      src={
                        "https://tr3.traventia.com/graphic-design/logos/logos_traventia/travis_logo_telegram.jpg"
                      }
                      width={"50px"}
                      style={{ border: "solid 1px #FF8F18", borderRadius: "100px", marginTop: "5px" }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                      <Grid item xs={12}>
                        <b style={{ marginTop: "-10px" }}>Traventia</b>
                      </Grid>
                      <Grid item>
                        <p style={{ marginTop: "-10px" }}>
                          6 d
                          <TiMediaRecord style={{ fontSize: "8px", color: "silver" }} />
                          <BiWorld style={{ fontSize: "15px" }} />
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <GoKebabHorizontal style={{ fontSize: "20px" }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ maxHeight: "380px", overflowY: "auto" }}>
            {/* {form.socialNetwork.text.split("\n").map((cv) => (
              <p>{cv}</p>
            ))} */}
            {formaterText(form.socialNetwork.text)}
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              spacing={2}
              // style={{ borderTop: "solid 1px #D3D3D3" }}
            >
              <Grid item xs={12}>
                <AiFillLike
                  style={{
                    backgroundColor: "#1877F2",
                    fontSize: "15px",
                    color: "white",
                    borderRadius: "100px",
                    // width: "25px",
                    // height: "25px",
                  }}
                />
                {" " + getLikes()}
              </Grid>
              <Grid
                item
                xs={12}
                style={{ borderTop: "solid 1px #D3D3D3", borderBottom: "solid 1px #D3D3D3" }}
              >
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                  <Grid item xs={6}>
                    <AiFillLike style={{ color: "grey", fontSize: "20px" }} />{" "}
                    <span style={{ color: "grey" }}> Me gusta</span>
                  </Grid>
                  <Grid item xs={6}>
                    <GoComment style={{ color: "grey", fontSize: "20px" }} />
                    <span style={{ color: "grey" }}> Comentar</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PreviewFacebook;
