import { useState, useEffect } from "react";

import {
  useAffiliatesReportsManagement,
  useSaveAffiliateReport,
  useDeleteAffiliateReport,
  useAffiliatesReportsSentList,
} from "../../shared/hooks/useAffiliatesReportsFetch";
import { uuid_v4 } from "@lib/helpers/uuid_v4";
import { useAffiliatesList } from "@organisms/Marketing/Affiliates/shared/hooks/useAffiliatesFetch";
import AffiliatesReportsSentActions from "@organisms/Marketing/AffiliatesReports/management/components/AffiliatesReportsSent.actions";

const AffiliatesReportsManagementState = ({ creation, affiliateReportId }) => {
  const [affiliateReport, setAffiliateReport] = useState({
    loading: false,
    data: {},
  });
  const [affiliates, setAffiliates] = useState([]);
  const [affiliatesReportsSent, setAffiliatesReportsSent] = useState({
    loading: false,
    data: [],
  });

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };
  const updateAffiliateReport = async () => {
    setAffiliateReport({
      loading: true,
      data: {},
    });
    if (creation === true) {
      setAffiliateReport({
        loading: false,
        data: {
          lang: "es",
          id: uuid_v4(),
          enable: false,
          freq: null,
          affiliatesId: [],
          by: null,
          emails: [],
          emailsBcc: [],
        },
      });
      return;
    }
    try {
      const { affiliateReportData } = await useAffiliatesReportsManagement(affiliateReportId);
      const { affiliatesData } = await useAffiliatesList();
      setAffiliates(
        affiliatesData.list.filter((affiliate) => {
          return affiliateReportData.affiliateReport.affiliatesId.includes(affiliate.affiliateId);
        })
      );
      setAffiliateReport({
        loading: affiliateReportData.loading,
        data: affiliateReportData.affiliateReport,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateAffiliateReportSent = async () => {
    setAffiliatesReportsSent({
      loading: true,
      data: [],
    });
    try {
      const { affiliatesReportsSentData } = await useAffiliatesReportsSentList(affiliateReportId);
      setAffiliatesReportsSent({
        loading: affiliatesReportsSentData.loadingList,
        data: affiliatesReportsSentData.list,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const saveAffiliateReport = async () => {
    const validations = [];
    if (!affiliates.length) {
      validations.push("Debe seleccionar al menos un afiliado.");
    }
    if (!affiliateReport.data.freq) {
      validations.push("Debe seleccionar una frecuencia del reporte.");
    }
    if (!affiliateReport.data.by) {
      validations.push("Debe seleccionar un tipo de reporte (Obtener reporte por)");
    }
    if (!affiliateReport.data.lang) {
      validations.push("Debe seleccionar un idioma.");
    }

    if (validations.length > 0) {
      setValidationErrors(validations);
      return null;
    }

    try {
      const newAffiliate = { ...affiliateReport.data };
      newAffiliate.affiliatesId = affiliates.map((affiliate) => affiliate.affiliateId);
      const { id } = await useSaveAffiliateReport(newAffiliate.id, newAffiliate);
      return id;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const deleteAffiliateReport = async () => {
    try {
      await useDeleteAffiliateReport(affiliateReportId);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    async function loadData() {
      await updateAffiliateReport();
      await updateAffiliateReportSent();
    }
    loadData();
  }, []);

  const affiliatesReportsSentHeadTable = [
    { code: "id", name: "ID", type: "string" },
    { code: "affiliateReportId", name: "ID de reporte", type: "string" },
    { code: "timestamp", name: "Envío", type: "timestamp" },
    { code: "location", name: "Ubicación", type: "string" },
    { code: "emails", name: "Emails", type: "tooltiplist" },
  ];

  const downloadAffiliateReportSent = async (id) => {
    alert("Eres muy pro");
  };

  const { affiliatesReportsSentActions } = AffiliatesReportsSentActions({ downloadAffiliateReportSent });

  return {
    affiliateReport,
    creation,
    setAffiliateReport,
    affiliateReportId,
    saveAffiliateReport,
    deleteAffiliateReport,
    affiliates,
    setAffiliates,
    alertOpen,
    alertData,
    handleAlertSnackBar,
    setAlertOpen,
    affiliatesReportsSent,
    affiliatesReportsSentHeadTable,
    affiliatesReportsSentActions,
    validationErrors,
    setValidationErrors,
  };
};

export default AffiliatesReportsManagementState;
