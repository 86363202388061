import React, { Fragment, useState, useEffect } from "react";
import SearchInput from "@atoms/Inputs/SearchInput/SearchInput";
import FileSuggestions from "./components/FileSuggestions";
import { useSearchInput } from "../../FileManagerContext";

const FileSearcher = ({ onExecuteSearch }) => {
  const minchar = 3;
  const [{ searchInput, bucket }, setSearcherInput] = useSearchInput();
  const [searcherString, setSearcherString] = useState("");
  const [openSuggestions, setOpenSuggestions] = useState(false);

  useEffect(() => {
    setSearcherString(searchInput);
  }, [searchInput]);

  const searchFunction = (withThisString) => {
    if (withThisString !== "" && withThisString.length < minchar) {
      return;
    }

    setSearcherInput(withThisString);
    setSearcherString(withThisString);
    setOpenSuggestions(false);

    onExecuteSearch({ show: withThisString !== "" });
  };

  return (
    <Fragment>
      <SearchInput
        label={"Busca un archivo"}
        searcherString={searcherString}
        onChangeInput={(newInput) => {
          setSearcherString(newInput);
          if (newInput.length > minchar) {
            setOpenSuggestions(true);
          }
        }}
        searchFunction={() => searchFunction(searcherString)}
      />

      {openSuggestions && (
        <FileSuggestions
          searcherString={searcherString}
          bucket={bucket}
          maxsuggestions={20}
          onSelectOption={(newOption) => searchFunction(newOption)}
        />
      )}
    </Fragment>
  );
};

export default FileSearcher;
