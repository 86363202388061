import React from "react";
import { useHistory } from "react-router-dom";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import ButtonNavegation from "../shared/ButtonNavegation";

function TicketingSectionCalendarBlock() {
  const history = useHistory();

  function goToCalendarSection() {
    history.push(`${history.location.pathname}/calendar/products`);
  }

  return (
    <TravCard>
      <TravBodyCard>
        <div style={{display:"flex", justifyContent:"space-between",alignItems:"center", flexWrap:"wrap"}}>
          <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
            <BsFillCalendarDateFill size={20} />
            <h2 style={{margin:"0 0 0 5px"}}>{"Calendario"}</h2>
          </div>
          <div>
            <ButtonNavegation direction={"right"} label={"Acceder"} size="small" onClick={goToCalendarSection} />
          </div>
        </div>
      </TravBodyCard>
    </TravCard>
  );
}

export default TicketingSectionCalendarBlock;
