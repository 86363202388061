import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-clouds";

const HTMLEditor = ({ html, onChange, options = {}, width = "500px", height = "350px", fontSize }) => {
  return (
    <AceEditor
      value={html}
      mode="html"
      theme="clouds"
      onChange={onChange}
      name="UNIQUE_ID_OF_DIV"
      editorProps={{ $blockScrolling: true }}
      setOptions={options}
      width={width}
      height={height}
      wrapEnabled={true}
      fontSize={fontSize}
    />
  );
};

export default HTMLEditor;
