import React, { Fragment } from "react";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { makeStyles } from "@material-ui/core/styles";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import Modal from "react-bootstrap/Modal";
import AccommodationsProviderModalInfo from "@organisms/providers/accommodations/pages/AccommodationsProvidersList/AccommodationsProviderModalInfo";

const useStyles = makeStyles({ root: { flexGrow: 1 } });

const AccommodationsProvidersListView = ({
  alertData,
  alertOpen,
  setAlertOpen,
  tableHead,
  tableHeadModal,
  actions,
  tabSelected,
  INFO_DATA,
  loading,
  obj,
  getDataByProvider,
  getDataBySubprovider,
  modal,
  resetModal,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <SectionTitle title={`Proveedores de ${INFO_DATA[tabSelected].title}`} buttons={[]} />
      {/* HABILITAR CUANDO SEA NECESARIO TENER EL RESTO DE PROVEEDORES
        RECORDAR: Rellenar en INFO_DATA los datos necesarios y añadir la tab
      */}
      {/* <Paper className={classes.root} style={{ marginBottom: "10px" }}>
        <Tabs
          value={tabSelected}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab label="Alojamientos" />
        </Tabs>
      </Paper> */}
      <FenixTable
        key={"main"}
        pagination={true}
        maxNumberPerPages={10}
        cellContentDefault={""}
        tableHead={tableHead}
        tableContent={Object.values(obj).map((accommodationProvider) => {
          if (accommodationProvider.subrows.length > 0) {
            return {
              ...getDataByProvider(accommodationProvider.row),
              subrows: accommodationProvider.subrows.map((cvsubrows) => getDataBySubprovider(cvsubrows)),
            };
          }
          return getDataByProvider(accommodationProvider.row);
        })}
        loading={loading}
        actions={actions}
      />
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
      <Modal show={modal.open} onHide={resetModal} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Proveedor {modal.data.row.provider}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
          <AccommodationsProviderModalInfo
            content={modal.data}
            tableHeadModal={tableHeadModal}
            loading={loading}
            getDataBySubprovider={getDataBySubprovider}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default AccommodationsProvidersListView;
