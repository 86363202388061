import styled from "styled-components";

/**
 * Mobile drawer
 */
const DrawerMobileContainer = styled.div`
  position: fixed;
  padding: 0 0 70px 0;
  padding-top: ${props => (props.boxTitle ? props.boxTitle : "")};
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow: hidden;
`;

const DrawerMobile = ({ children, boxTitle }) => {
  return (
    <DrawerMobileContainer boxTitle={boxTitle}>
      {children}
      <style jsx global>{`
        html,
        body {
          overflow: hidden !important;
          touch-action: none;
          -ms-touch-action: none;
        }
      `}</style>
    </DrawerMobileContainer>
  );
};

/**
 * Unique Id that will be set in
 */
//idDate: PropTypes.string().isRequired(),
/*
DrawerMobile.propTypes = {
  boxTitle: PropTypes.bool,
};

DrawerMobile.defaultProps = {
  boxTitle: false,
};
*/
export default DrawerMobile;
