import { useEffect, useState } from "react";
import { CookieItem } from "@lib/storage/cookie";

const useFeatureFlag = (name) => {
  const [allowed, setAllowed] = useState(false);
  const cookieItem = new CookieItem(name);
  useEffect(() => {
    if (cookieItem.read()) {
      setAllowed(true);
    } else {
    }
  }, []);
  return { allowed };
};

export default useFeatureFlag;
