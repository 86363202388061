import React, { useEffect } from "react";
import {
  PaymentsToSuppliersContextProvider,
  usePendingPayments,
  useRemittance,
  useLockdown,
} from "../contexts/PaymentsToSuppliersContext";
import { PaymentsToSuppliersApi } from "../api/PaymentsToSuppliersApi";
import useRemittanceView from "./RemittanceView/useRemittanceView";

const PaymentsToSuppliersHOC = ({ children }) => {
  const initProps = {
    lockdown: false,
    pendingLoading: true,
    pendingList: [],
    remittanceLoading: true,
    remittance: null,
  };

  return (
    <PaymentsToSuppliersContextProvider {...initProps}>
      <PaymentsToSuppliersHOCImplementation>{children}</PaymentsToSuppliersHOCImplementation>
    </PaymentsToSuppliersContextProvider>
  );
};

const PaymentsToSuppliersHOCImplementation = ({ children }) => {
  const { setPendingList, setLoading } = usePendingPayments();
  const { setLockdown } = useLockdown();
  const { remittance, remittanceLoading } = useRemittance();

  const { fetchRemittance } = useRemittanceView();

  useEffect(() => {
    fetchRemittance().catch((error) => {
      console.error(error);
      setLockdown(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchPendingPayments = async () => {
      const pendingList = await PaymentsToSuppliersApi.getPendingInfoFromSuppliers();
      setPendingList(pendingList);
      setLoading(false);
    };

    if (!remittanceLoading) {
      fetchPendingPayments().catch((error) => {
        console.error(error);
        setLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remittance, remittanceLoading]);

  return <>{children}</>;
};

export default PaymentsToSuppliersHOC;
