/* eslint-disable no-undef */
/* eslint-disable no-alert */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MdEdit, MdControlPointDuplicate, MdClear } from "react-icons/md";
// eslint-disable-next-line camelcase
import { LS_getItem, LS_setItem } from "@lib/storage/localStorage";
import { uuid_v4 } from "@lib/helpers/uuid_v4";
import { useJsonPagesList } from "@organisms/contenido/json-pages/hooks/useJsonPagesList";

const useJsonPagesListState = ({ type }) => {
  const MAX_PAGES_VIEW = 25;
  const [errorState, setErrors] = useState({ show: false, list: [] });
  const [market, setMarket] = useState(LS_getItem("market") === null ? "es" : LS_getItem("market"));
  const { loading, list, deleteJsonPage, putJsonPage } = useJsonPagesList({ market });
  const history = useHistory();

  const [rowsSelected, setRowSelected] = useState({ es: [], fr: [], pt: [], it: [] });

  const onChangeMarketSelections = (newList) => {
    setRowSelected({ ...rowsSelected, [market]: newList });
  };
  const handleMarket = async (nuMarket) => {
    setMarket(nuMarket);
    LS_setItem("market", nuMarket);
  };

  const tableHead = [
    { code: "name", name: "Nombre", type: "string" },
    { code: "tld", name: "Mercado", type: "market" },
    { code: "head.title", name: "Titulo", type: "string" },
    { code: "head.description", name: "Descripción", type: "string" },
    { code: "path", name: "Path", type: "string" },
    { code: "head.indexable", name: "Indexado", type: "boolean" },
    // { code: "node", name: "Nodo", type: "string" },
    // { code: "nodetype", name: "Tipo de nodo", type: "string" },
    // { code: "publishDate", name: "Publicada", type: "date" },
    // { code: "expirationDate", name: "Caducidad", type: "date", format: "DD/MM/YYYY HH:mm:ss" },
  ];

  const actionEditJsonPage = (rowData) => {
    history.push(`${history.location.pathname}/${LS_getItem("market")}/${rowData.id}`);
  };

  const actionDuplicate = async (rowData, duplicateMarket) => {
    const newJsonPage = { ...rowData };
    newJsonPage.id = uuid_v4();
    newJsonPage.tld = duplicateMarket;
    newJsonPage.path = `${rowData.path}/${newJsonPage.id}`;
    newJsonPage.name = `${rowData.name} - DUPLICADO DESDE ${market.toUpperCase()}`;
    await putJsonPage(newJsonPage);
  };

  const actionDeleteJsonPage = async (rowData) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "ATENCIÓN: Ha elegido eliminar la página guardada\nEsta acción no es reversible y eliminará también la página publicada asociada\n¿Está Seguro?"
      )
    ) {
      await deleteJsonPage(rowData.id);
    }
  };

  const actions = [
    { name: "Editar", icon: <MdEdit />, func: actionEditJsonPage },
    {
      name: "Duplicar ES",
      icon: <MdControlPointDuplicate />,
      func: (rowData) => actionDuplicate(rowData, "es"),
    },
    {
      name: "Duplicar PT",
      icon: <MdControlPointDuplicate />,
      func: (rowData) => actionDuplicate(rowData, "pt"),
    },
    {
      name: "Duplicar FR",
      icon: <MdControlPointDuplicate />,
      func: (rowData) => actionDuplicate(rowData, "fr"),
    },
    {
      name: "Duplicar IT",
      icon: <MdControlPointDuplicate />,
      func: (rowData) => actionDuplicate(rowData, "it"),
    },
    { name: "Eliminar", icon: <MdClear />, func: actionDeleteJsonPage },
  ];

  const addNewJsonPage = () => {
    const uniqueValue = uuid_v4();
    history.push(`${history.location.pathname}/${market}/${uniqueValue}`);
  };

  return {
    MAX_PAGES_VIEW,
    type,
    errorState,
    setErrors,
    market,
    setMarket,
    loading,
    list,
    history,
    rowsSelected,
    setRowSelected,
    onChangeMarketSelections,
    handleMarket,
    addNewJsonPage,
    tableHead,
    actions,
  };
};

export default useJsonPagesListState;
