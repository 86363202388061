import { tlds } from "./domainVariables";

function contentCsvRowValidation(ticketContent, csvSpecContent, contentFieldsAsArray) {
  // Mandatory
  Object.keys(csvSpecContent).forEach((field) => {
    if (ticketContent[field] === undefined) {
      throw new Error(`Campo <${field}> es obligatorio`);
    }
  });

  // Not Empty
  Object.keys(csvSpecContent).forEach((field) => {
    if (ticketContent[field].trim() === "") {
      throw new Error(`Campo <${field}> esta vacio`);
    }
  });

  if (!tlds.includes(ticketContent.tld.trim())) {
    throw new Error(`Tipo <${ticketContent.tld}> es desconocido.`);
  }

  if (!contentFieldsAsArray.includes(ticketContent.field.trim())) {
    throw new Error(
      `Campo <${ticketContent.field}> no es un campo aplicable. Validos: ${contentFieldsAsArray.join("|")}`
    );
  }
}

export default contentCsvRowValidation;
