import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import SectionTitle from "@atoms/Titles/SectionTitle";
import RemittanceInProgress from "../components/RemittanceInProgress";
import RemittancePaid from "../components/RemittancePaid";

const RemittancesPage = () => {
  return (
    <ContainerFenix>
      <SectionTitle
        title={"💸 Remesas de pagos a suppliers"}
        info={["Remesas creadas listas para ser pagadas"]}
      />

      <RemittanceInProgress />

      <RemittancePaid />
    </ContainerFenix>
  );
};

export default RemittancesPage;
