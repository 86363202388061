import axios from "axios";
import { useState, useEffect } from "react";
import { slugify } from "@lib/helpers/string";
import * as offerMock from "../mocks/OF1605866989868_mock.json";

const { validate } = require("@traventia/validation");

const urlOffer = "/api/v1/product-bc/offers-managment/offers";
const urlOfferTags = "/api/v1/product-bc/offerTags-managment/offerTags";

export const validateSchema = (parameters, schema) => {
  const response = validate(parameters, schema, { format: "flat" });
  if (response !== undefined) {
    return response;
  }
  return [];
};

export const useOfferList = async (tld, lang) => {
  let thestateFinal = { loadingList: true, list: [], marketApplied: null };
  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { loadingList: true, list: [], marketApplied: null };
      const response = await axios.get(`${urlOffer}?market=${tld}&_limit=1000`);
      if (response.status === 200) {
        thestate = { loadingList: false, list: response.data.data, marketApplied: tld };
      }
      thestateFinal = { ...thestate };
    } catch (error) {
      thestateFinal = { loadingList: false, list: [], marketApplied: null };
      throw error;
    }
    return thestateFinal;
  };
  if ((thestateFinal.loadingList && tld !== null) || tld !== thestateFinal.marketApplied) {
    return (thestateFinal = fetchData(thestateFinal));
  }
  return thestateFinal;
};

export const putOffer = async (offer) => {
  let response = {};
  try {
    const nuOffer = { ...offer, url: `${slugify(offer.title)}-${slugify(offer.idOffer)}` };
    if (!nuOffer.flash.enable) {
      nuOffer.flash.datetime = new Date().toISOString();
    }

    response = await axios.put(`${urlOffer}/${nuOffer.idOffer}`, nuOffer);
    response.ok = true;
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const deleteOffer = async (offer) => {
  try {
    await axios.delete(`${urlOffer}/${offer.idOffer}`);
  } catch (error) {
    console.error(error);
  }
};

export const getOfferAsync = async (code) => {
  try {
    const codeOffer = code || "NEW";
    const response = await axios.get(`${urlOffer}/${codeOffer}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const getOffer = (code) => {
  const [offerData, setOfferData] = useState({ loading: true, offer: {} });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, campaign: {} };
        const codeOffer = code || "NEW";
        const response = await axios.get(`${urlOffer}/${codeOffer}`);

        if (response.status === 200) {
          thestate = { loading: false, offer: response.data };
        }
        setOfferData(thestate);
      } catch (error) {
        setOfferData({ loading: false, offer: {} });
        console.error(error);
      }
    };

    if (offerData.loading) {
      fetchData();
    }
  }, [offerData.loading, code]);
  return { loadingOffer: offerData.loading, offer: offerData.offer };
};

// TAGS
export const putTags = async (id, tag) => {
  let response = {};
  try {
    response = await axios.put(`${urlOfferTags}/${id}`, tag);
    response.ok = true;
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const deleteTags = async (offers) => {
  try {
    for (const offer of offers) {
      await axios.delete(`${urlOfferTags}/${offer.idOfferTag}`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const useOfferTags = async (tld) => {
  let thestateFinal = { loadingList: true, list: [], marketApplied: null };
  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { loadingList: true, list: [], marketApplied: null };
      const response = await axios.get(`${urlOfferTags}?market=${tld}`);
      if (response.status === 200) {
        thestate = { loadingList: false, list: response.data.data, marketApplied: tld };
      }
      thestateFinal = { ...thestate };
    } catch (error) {
      thestateFinal = { loadingList: false, list: [], marketApplied: null };
      throw error;
    }
    return thestateFinal;
  };
  if ((thestateFinal.loadingList && tld !== null) || tld !== thestateFinal.marketApplied) {
    return (thestateFinal = fetchData(thestateFinal));
  }
  return thestateFinal;
};

export const getOfferTagsForMarketAndType = async (tld, type) => {
  let thestateFinal = { loadingList: true, list: [] };
  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { loadingList: true, list: [] };
      const response = await axios.get(`${urlOfferTags}?market=${tld}&type=${type}`);
      if (response.status === 200) {
        if (response.data.data.length === 0) {
          thestate = { loadingList: false, list: [] };
        } else {
          thestate = { loadingList: false, list: response.data.data[0].tags };
        }
      }
      thestateFinal = { ...thestate };
    } catch (error) {
      thestateFinal = { loadingList: false, list: [] };
      throw error;
    }
    return thestateFinal;
  };
  if (thestateFinal.loadingList && tld !== null) {
    return (thestateFinal = fetchData(thestateFinal));
  }
  return thestateFinal;
};

export const getTagsById = (market, type) => {
  let thestateFinal = { loadingList: true, list: [] };
  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { loadingList: true, list: [] };
      const response = await axios.get(`${urlOfferTags}?id=${market}-${type}`);

      if (response.status === 200) {
        thestate = { loadingList: false, list: response.data };
      }
      thestateFinal = thestate;
    } catch (error) {
      thestateFinal = { loadingList: false, list: {} };
      console.error(error);
    }
    return thestateFinal;
  };
  if (thestateFinal.loadingList) {
    return (thestateFinal = fetchData(thestateFinal));
  }
  return thestateFinal;
};

export const generateNewOfferCode = () => {
  return `OF${new Date().getTime() + Math.trunc(365 * Math.random())}`;
};

export const generateNewOfferTagCode = () => {
  return `OFT${new Date().getTime() + Math.trunc(365 * Math.random())}`;
};
