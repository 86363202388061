import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Grid, TextareaAutosize } from "@material-ui/core";
import { MdPreview, MdFactCheck, MdOutlineStopCircle } from "react-icons/md";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import useInterval from "@lib/hooks/useInterval";
import FxAlert from "@atoms/Alerts/FxAlert";
import { prettyJson, openInNewTab, parsedFormInJsonPage } from "../../utility/utility";
import { postPreview } from "../../hooks/useJsonPages";

function JsonPageEditorJsonEditor({ onChange, form }) {
  const [json, setJson] = useState(form.json);
  const [jsonError, setJsonError] = useState(null);
  const [isPreview, setPreview] = useState(false);
  const [jsonValidate, setJsonValidate] = useState(true);

  const getJSON = () => {
    try {
      prettyJson(json);
      return parsedFormInJsonPage(form);
    } catch (error) {
      return { error: error.message };
    }
  };

  useInterval(
    async () => {
      await postPreview(form.id, getJSON(form));
    },
    isPreview ? 1300 : null
  );

  const handleJsonEditor = (newForm, newJson) => {
    setJsonValidate(false);
    onChange(newForm);
    setJson(newJson);
  };

  const handlePreview = () => {
    setPreview(!isPreview);
    if (!isPreview) {
      openInNewTab(`https://www.traventia.es/temporal-json-pages/${form.id}`);
    }
  };

  const handlePretty = () => {
    try {
      const jsonParsed = prettyJson(json);
      setJson(jsonParsed);
      setJsonError(null);
      setJsonValidate(true);
    } catch (error) {
      const errorIsCharPosition = Number(error.message.slice(-2));
      //   try {
      //   const errorIsCharPosition = 805 + 1;
      // const errorMessage = `____________${error.message}__________________`;
      //     const newjson = `${json.substr(
      //       0,
      //       errorIsCharPosition
      //     )}\n${errorMessage}\n${json.substr(errorIsCharPosition)}`;
      setJsonError(`Error en el caracter ${errorIsCharPosition}`);
      setJson(json);
      setJsonValidate(true);
      //   } catch (error) {
      //     console.log(error);
      //   }
    }
  };

  //   useEffect(() => {
  //     if (jsonError !== null) {
  //         setJsonError(null);
  //         setTimeout(() => {
  //           const { errorIsCharPosition } = jsonError;
  //           const messageSize = jsonError.errorMessage.length + 2;
  //           const newJson = `${json.substr(0, errorIsCharPosition)}${json.substr(
  //             errorIsCharPosition + messageSize
  //           )}`;
  //           setJsonError(null);
  //           setJson(newJson);
  //         }, 1000);
  //     }
  //   }, [json, jsonError]);

  return (
    <Card>
      <Card.Body>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                {jsonError !== null && jsonValidate && <FxAlert variant={"error"}>{jsonError}</FxAlert>}
                {jsonError === null && jsonValidate && (
                  <FxAlert variant={"success"}>JSON Sin errores.</FxAlert>
                )}
                {!jsonValidate && <FxAlert variant={"warning"}>Hace falta validar el JSON.</FxAlert>}
              </Grid>
              <Grid item xs={8}>
                <div
                  style={{ display: "flex", gap: "10px", justifyContent: "flex-end", marginBottom: "10px" }}
                >
                  <ButtonCustom icon={<MdFactCheck />} key={"btV"} color="primary" onClick={handlePretty}>
                    Validar
                  </ButtonCustom>

                  {!isPreview ? (
                    <ButtonCustom icon={<MdPreview />} key={"btPV"} color="secondary" onClick={handlePreview}>
                      Iniciar Preview
                    </ButtonCustom>
                  ) : (
                    <ButtonCustom
                      icon={<MdOutlineStopCircle />}
                      key={"btPV"}
                      color="secondary"
                      onClick={handlePreview}
                    >
                      Parar Preview
                    </ButtonCustom>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12}>
            <TextareaAutosize
              variant="outlined"
              placeholder="JSON"
              //   multiline={true}
              value={json}
              onChange={(nVal) => {
                const nuForm = { ...form };
                nuForm.json = nVal.currentTarget.value;
                handleJsonEditor(nuForm, nVal.currentTarget.value);
              }}
              aria-label="maximum weight"
              rowsMin={8}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
}

export default JsonPageEditorJsonEditor;
