/* eslint-disable no-use-before-define */
import { fetchProducts, fetchStype } from "./useProducts";
import { fetchPrecinct } from "../Precincts/usePrecincts";
import difference from "../shared/differenceBetweenTwoLists";
import { contentFields } from "./constants";
import { tlds } from "../shared/domainVariables";
import saveProductList from "./bulkUpload";

/**
 *
 * @param {*} productList
 * @returns
 * saved: number, number of element send in the request after filtered
 * errors: string[], errors received during the execution
 */
const bulkUploadProductChannels = async (productList) => {
  try {
    //* ** Validation
    const newProductList = parseProducts(productList);
    const precinctCodes = newProductList.reduce((allP, product) => {
      if (!allP.includes(product.precinctCode)) {
        allP.push(product.precinctCode);
      }
      return allP;
    }, []);
    if (precinctCodes.length > 1) {
      throw new Error(`No puede enviar una lista con distintos precinctCodes`);
    }

    const stypes = await fetchStype();
    if (stypes.total === 0) {
      return { errors: ["Error en la peticion de los stype"] };
    }

    const errorsValidation = await productListValidation(newProductList, stypes.data);
    if (errorsValidation.length > 0) {
      return { errors: errorsValidation };
    }

    const params = { precinctCode: precinctCodes[0] };
    const currentProducts = await fetchProducts({ params });

    //* ** Get the intersection between the two
    const { toInsert, toUpdate } = difference(currentProducts, newProductList, false, contentFields);

    // console.log(`toInsert ${toInsert.length}`, `toUpdate ${toUpdate.length}`);

    //* ** Save the results
    const { saved, errors } = await saveProductList([...toInsert, ...toUpdate]);

    return { saved, errors };
  } catch (e) {
    return { errors: [e.message] };
  }
};

const productListValidation = async (productList, stypes) => {
  const precinctCodesChecked = {};
  const ncodes = [];
  let errors = [];

  const stypesInArray = stypes.map((cv) => cv.id);

  for (let index = 0; index < productList.length; index += 1) {
    const rowId = index + 2;

    const product = productList[index];

    errors = [...errors, ...productValidation(product, stypesInArray).map((ev) => `Fila ${rowId}: ${ev}`)];

    if (precinctCodesChecked[product.precinctCode] === undefined) {
      try {
        await fetchPrecinct(product.precinctCode);
        precinctCodesChecked[product.precinctCode] = true;
      } catch (e) {
        errors.push(`Fila ${rowId}: Codigo de recinto <${product.precinctCode}> inexistente`);
        precinctCodesChecked[product.precinctCode] = false;
      }
    } else if (!precinctCodesChecked[product.precinctCode]) {
      errors.push(`Fila ${rowId}: Codigo de recinto <${product.precinctCode}> inexistente`);
    }

    if (ncodes.includes(product.code)) {
      errors.push(`Fila ${rowId}: Codigo <${product.code}> duplicado`);
    } else {
      ncodes.push(product.code);
    }
  }

  return errors;
};

const parseProducts = (productList) => {
  return productList.map((product) => {
    const newProduct = { ...product };

    // packageMarkup
    if (
      Number.isNaN(newProduct.packageMarkup) ||
      newProduct.packageMarkup === "" ||
      newProduct.packageMarkup === 0
    ) {
      delete newProduct.packageMarkup;
    }
    // minQuota
    if (Number.isNaN(newProduct.minQuota)) {
      newProduct.minQuota = 0;
    }

    // People Requirements
    if (!newProduct.peopleRequirements.enable) {
      if (newProduct.peopleRequirements.min === undefined || newProduct.peopleRequirements.min === "") {
        newProduct.peopleRequirements.min = 0;
      }
      if (newProduct.peopleRequirements.max === undefined || newProduct.peopleRequirements.max === "") {
        newProduct.peopleRequirements.max = 0;
      }
    }
    if (newProduct.peopleRequirements.ages.length > 0) {
      newProduct.peopleRequirements.ages = newProduct.peopleRequirements.ages.map((age) => {
        const [min, max] = age.split("-").map((a) => parseInt(a, 10));
        return { min, max };
      });
    }

    return newProduct;
  });
};

const productValidation = (product, stypes) => {
  const errors = [];

  if (!stypes.includes(product.packageRules.type)) {
    errors.push(`Stype <${product.packageRules.type}> no encontrado`);
  }

  // Mandatory
  ["code", "precinctCode"].forEach((field) => {
    if (product[field] === undefined) {
      errors.push(`El campo <${field}> es requerido`);
    }
  });

  // Not Empty
  ["code", "precinctCode"].forEach((field) => {
    if (product[field].trim() === "") {
      errors.push(`El campo <${field}> esta vacio`);
    }
  });

  product.markets.forEach((market) => {
    if (!tlds.includes(market)) {
      errors.push(`Mercado <${tlds}> no valido`);
    }
  });

  // People Requirements
  if (product.peopleRequirements.enable) {
    if (product.peopleRequirements.min > product.peopleRequirements.max) {
      errors.push(
        `Personas: Minimas <${product.peopleRequirements.min}> mayor que maximas <${product.peopleRequirements.max}> para <${product.code}>`
      );
    }
    product.peopleRequirements.ages.forEach((age) => {
      if (age.min > age.max) {
        errors.push(
          `Personas: Edades: Edad minima <${age.min}> mayor que edad maxima <${age.max}> para <${product.code}>`
        );
      }
    });
  }

  return errors;
};

export default bulkUploadProductChannels;
