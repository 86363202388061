import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert } from "@material-ui/lab";
import { MdAssignmentTurnedIn } from "react-icons/md";
import FenixTable from "@molecules/Tables/FenixTable";
import BankTransfersModalAssign from "./BankTransfersModalAssign";
import { getBankData, fetchUnmatchedBankTransfers } from "./useBankTransfers";

function BankTransfersUnmatched({}) {
  const [loading, setLoading] = useState(false);
  const [unmatchedBankTransfers, setUnmatchedBankTransfers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [bankTransferSelected, setBankTransferSelected] = useState();

  const assingBankTransferToBook = (row) => {
    setBankTransferSelected(row);
    setOpenModal(true);
  };

  const tableHead = [
    // { code: "key", name: "Clave", type: "string" },
    { code: "concept", name: "Concepto", type: "string" },
    { code: "payer", name: "Pagador", type: "string" },
    { code: "date", name: "Fecha pago", type: "date" },
    { code: "amount", name: "Cantidad", type: "money" },
    {
      code: "bank",
      name: "Banco",
      type: "label",
      translation: getBankData(),
      filterable: true,
    },
    { code: "processed", name: "Procesada", type: "boolean", text: { y: "Si", n: "No" } },
    { code: "processed_date", name: "Fecha procesado", type: "date" },
  ];

  const actions = [{ name: "Asignar", icon: <MdAssignmentTurnedIn />, func: assingBankTransferToBook }];

  async function getUnmatchedBankTransfers() {
    setLoading(true);

    const response = await fetchUnmatchedBankTransfers();

    setLoading(response.loading);
    setUnmatchedBankTransfers(response.list);
  }

  const onCloseModal = async ({ reload }) => {
    setOpenModal(false);
    if (reload) {
      await getUnmatchedBankTransfers();
    }
  };

  useEffect(() => {
    getUnmatchedBankTransfers();
  }, []);

  return (
    <>
      <Alert severity="info">
        En esta sección puedes revisar las transferencias que no han podido ser asignadas a reservas porque el
        sistema las ha considerado lo suficientemente ambiguas como para que requieran de una revisión manual.
        <br />
        Podrás asignarlas manualmente a una reserva (algunas de ellas tendrán sugerencias).
      </Alert>
      <FenixTable
        loading={loading}
        tableHead={tableHead}
        tableContent={unmatchedBankTransfers}
        actions={actions}
      />

      <BankTransfersModalAssign banktransfer={bankTransferSelected} open={openModal} onClose={onCloseModal} />
    </>
  );
}

BankTransfersUnmatched.propTypes = {};

export default BankTransfersUnmatched;
