import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MdEdit, MdControlPointDuplicate, MdClear } from "react-icons/md";
import { useRappels } from "../../hooks/useRappels";
import { uuid_v4 } from "@lib/helpers/uuid_v4";
import { upsertRappel } from "../../hooks/useRappelsEditor";

const RappelsListState = () => {
  const { loading, list, deleteRappel } = useRappels();
  const MAX_ITENS_FOR_VIEW = 25;
  const history = useHistory();
  const [errorState, setErrors] = useState({ show: false, list: [] });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");

  const actionEditTemplate = (rowData) => {
    history.push(`${history.location.pathname}/${rowData.id}`);
  };

  const actionDeleteRappel = async (rowData) => {
    const deleteConfirm = confirm(`¿Está seguro de eliminar el rappel ${rowData.id}?`);
    if (deleteConfirm) {
      await deleteRappel(rowData.id);
    }
  };

  const addNewRappel = () => {
    const uniqueValue = uuid_v4();
    history.push(`${history.location.pathname}/${uniqueValue}`);
  };

  const actionDuplicate = async (rowData) => {
    const nuRappel = { ...rowData };
    const uniqueValue = uuid_v4();
    nuRappel.id = uniqueValue;

    await saveRapple(nuRappel);
    history.push(`${history.location.pathname}/${rowData.id}?duplicate=true`);
  };

  const saveRapple = async (nuRappel) => {
    const responseFromSaving = await upsertRappel(nuRappel);
    if (!responseFromSaving.ok) {
      setErrors({
        show: true,
        list: [`No se ha podido guardar por un error al enviar: ${responseFromSaving.message}`],
      });
    } else {
      handleAlertSnackBar(true, "Guardada correctamente.");
    }
  };

  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };

  const actions = [
    { name: "Editar", icon: <MdEdit />, func: actionEditTemplate },
    {
      name: "Duplicar",
      icon: <MdControlPointDuplicate />,
      func: (rowData) => actionDuplicate(rowData),
    },
    { name: "Borrar", icon: <MdClear />, func: actionDeleteRappel },
  ];

  const tableHead = [
    { code: "id", name: "Código", type: "string" },
    { code: "provider", name: "Proveedor", type: "string" },
    { code: "status", name: "Estado", type: "string" },
    { code: "rules", name: "Regla", type: "string" },
    { code: "dateoFrom", name: "Desde", type: "string" },
    { code: "dateoTo", name: "Hasta", type: "string" },
    { code: "rappelPercentage", name: "Porcentaje", type: "string" },
  ];

  return {
    list,
    loading,
    setErrors,
    errorState,
    tableHead,
    actions,
    addNewRappel,
    MAX_ITENS_FOR_VIEW,
    alertData,
    alertOpen,
    setAlertOpen,
  };
};

export default RappelsListState;
