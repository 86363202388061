import React, { Fragment } from "react";
/* ATOMS */
import SectionTitle from "@atoms/Titles/SectionTitle";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import ButtonSend from "@atoms/Buttons/ButtonSend";
/* import ButtonCheck from "@atoms/Buttons/ButtonCheck"; */
import ErrorModal from "@atoms/Modals/ErrorModal";
import Modal from "@atoms/Modals/Modal";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";

import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
/* ICONS */
import { AiFillEye } from "react-icons/ai";
/* MATERIAL */
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
/* COMPONENTS */
import { ThreeDotMenu, useThreeDotMenu } from "@molecules/Menus/ThreeDotMenu";
import JsonPageEditorInfo from "../components/JsonPageEditor/JsonPageEditorInfo";
import JsonPageEditorHeaders from "../components/JsonPageEditor/JsonPageEditorHeaders";
import JsonPageEditorJsonEditor from "../components/JsonPageEditor/JsonPageEditorJsonEditor";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function JsonPageEditorView({
  backToTable,
  handleSaveJsonPage,
  handlePublishJsonPage,
  onChangeForm,
  closeErrorModal,
  form,
  errors,
  alertOpen,
  setAlertOpen,
  alertData,
  openInNewTab,
  jsonPageDispatch,
  loadingDispatch,
  loading,
  restorelLastSavedVersion,
  restorelLastPublisedVersion,
  confirmationDialog,
  setConfirmationDialog,
  infoModal,
  closeInfoModal,
  setErrors,
  ok,
  tld,
}) {
  const classes = useStyles();
  const [tabSelected, setTabSelected] = React.useState(0);
  const { anchorEl, onOpenMenu, handleClose } = useThreeDotMenu();

  const handleChange = (event, newValue) => {
    setTabSelected(newValue);
  };

  const getDate = () => {
    const date = new Date(jsonPageDispatch.publishedDate);
    return `${date.toLocaleString("es-ES", {
      timeZone: "UTC",
      dateStyle: "full",
    })} `;
  };

  const getTime = () => {
    const date = new Date(jsonPageDispatch.publishedDate);
    return ` ${date.getHours()}:${date.getMinutes()}`;
  };

  const getInfo = () => {
    let info = [];
    if (Object.keys(jsonPageDispatch).length > 0) {
      info = [`Publicado el ${getDate()} a las ${getTime()}`];
    } else if (Object.keys(jsonPageDispatch).length === 0 && ok) {
      info = [`Esta página no ha sido publicada.`];
    } else if (Object.keys(jsonPageDispatch).length === 0 && !ok && !loading) {
      info = [`Esta página es nueva.`];
    }
    return info;
  };

  return (
    <Fragment>
      {/* Modals */}
      <ErrorModal show={errors.show} listErrors={errors.list} onClose={closeErrorModal} />
      <Modal show={infoModal.show} onClose={closeInfoModal}>
        <h3>Recuerda</h3>
        <p>{infoModal.text}</p>
      </Modal>
      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={() => {
          confirmationDialog.onConfirm();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
        onDeny={() => {
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
      <SectionTitle
        title={`Pagina ${form.path}`}
        backFunction={backToTable}
        info={getInfo()}
        buttons={[
          <ButtonCustom
            key={"btV"}
            customcolor="lightblue"
            icon={<AiFillEye />}
            onClick={() => openInNewTab(`https://www.traventia.${tld ?? "es"}/${form.path}`)}
          >
            Visualizar
          </ButtonCustom>,
          <ButtonSave key={"btG"} color="primary" onClick={handleSaveJsonPage}>
            Guardar
          </ButtonSave>,
          <ButtonSend key={"btP"} color="primary" onClick={handlePublishJsonPage}>
            Publicar
          </ButtonSend>,
          <ThreeDotMenu key={0} style={{ minWidth: "0" }} {...{ anchorEl, onOpenMenu, handleClose }}>
            <ButtonSave disabled={loading} key={"btVG"} color="primary" onClick={restorelLastSavedVersion}>
              Volver a la ultima version guardada
            </ButtonSave>
            <ButtonSend
              disabled={loadingDispatch}
              key={"btVP"}
              color="primary"
              onClick={restorelLastPublisedVersion}
            >
              Volver a la ultima version publicada
            </ButtonSend>
          </ThreeDotMenu>,
        ]}
      />

      <Paper className={classes.root} style={{ marginBottom: "10px" }}>
        <Tabs
          value={tabSelected}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab label="Información" />
          <Tab label="Cabeceras" />
          <Tab label="JSON" />
        </Tabs>
      </Paper>
      {tabSelected === 0 && <JsonPageEditorInfo onChange={onChangeForm} form={form} setErrors={setErrors} />}
      {tabSelected === 1 && <JsonPageEditorHeaders onChange={onChangeForm} form={form} />}
      {tabSelected === 2 && <JsonPageEditorJsonEditor onChange={onChangeForm} form={form} />}
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
    </Fragment>
  );
}

export default JsonPageEditorView;
