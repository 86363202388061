import styled from "styled-components";

export const SelectMarketPosition = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const infoDescription = styled.span`
  color: gray;
`;

export const MainIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -10px;
  top: -5px;
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 6px;
`;

export const AlertIcon = styled(MainIcon)`
  background-color: #ffae17;
`;

export const FaqIcon = styled(MainIcon)`
  background-color: #1bc5bd;
`;
