import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import JsonPageEditor from "@organisms/contenido/json-pages/JsonPageEditor/index";

function JSONPagesEditorPage() {
  return (
    <ContainerFenix>
      <JsonPageEditor />
    </ContainerFenix>
  );
}

export default JSONPagesEditorPage;
