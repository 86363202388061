import React from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import { MdFormatListBulleted, MdFormatListNumbered } from "react-icons/md";

function HtmlStructures({ addToHTML }) {
  const html = {
    ul: `
<ul>
    <li></li>
</ul>`,
    ol: `
<ol>
    <li></li>
</ol>`,
    row: `
<Row></Row>`,
    col: `
<Col></Col>`,
  };

  return (
    <ButtonGroup color="secondary" aria-label="outlined primary button group" size="large">
      <Button onClick={() => addToHTML(html.ul)}>
        <MdFormatListBulleted />
      </Button>
      <Button onClick={() => addToHTML(html.ol)}>
        <MdFormatListNumbered />
      </Button>
      <Button onClick={() => addToHTML(html.row)}>Row</Button>
      <Button onClick={() => addToHTML(html.col)}>Col</Button>
    </ButtonGroup>
  );
}

export default HtmlStructures;
