import React, { Fragment, useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import CardTitle from "@atoms/Titles/CardTitle";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";

import { useQueryConfigurationsData, saveConcepts, saveQueries } from "./hooks/useQueryConfigurations";

const QueryConfigurations = () => {
  const wcConcepts = "SEO_CONCEPTS_V1";
  const wcQueries = "SEO_QUERIES_V1";

  const { loading, concepts, queries } = useQueryConfigurationsData();

  const [loadingSection, setLoadingSection] = useState(loading);
  const [market, setMarket] = useState("es");
  const [errorsQ, setErrorsQ] = useState([]);

  const head = ["Concepto"];
  const specs = { c: "" };
  // const specs = { c1: "", c2: "", c3: "" };

  // const headQ = ["Query", "Concepto"];
  const headQ = ["Query", "Concepto1", "Concepto2", "Concepto3"];
  const specsQ = { q: "", c1: "", c2: "", c3: "" };
  // const specsQ = { q: "", c: "" };

  useEffect(() => {
    setLoadingSection(loading);
  }, [loading]);

  const saveConceptsInThisMarket = async (list) => {
    setLoadingSection(true);
    const res = await saveConcepts(list, market);
    if (res.ok) {
      // setLoadingSection(false);
      window.location = "";
    } else {
      setErrorsQ(["Ha ocurrido un error al subir el archivo."]);
    }
  };

  const saveQueriesInThisMarket = async (list) => {
    setLoadingSection(true);
    setErrorsQ([]);
    let vConcepts = [];
    if (concepts[market] !== undefined) {
      vConcepts = concepts[market].map((co) => co.c);
    }

    const errors = [];

    // Group by concepts
    const byConcept = {};
    // list.forEach((l) => {
    //   if (vConcepts.indexOf(l.c) === -1) {
    //     errors.push(`Concepto "${l.c}" no existe en el mercado ${market}.`);
    //   } else {
    //     if (byConcept[l.c] === undefined) {
    //       byConcept[l.c] = [];
    //     }
    //     byConcept[l.c].push(l.q);
    //   }
    // });

    list.forEach((item) => {
      let keysList = Object.keys(item);
      keysList.shift();
      keysList.forEach((ele) => {
        if (vConcepts.indexOf(item[ele]) === -1) {
          errors.push(`Concepto "${item[ele]}" no existe en el mercado ${market}.`);
        } else {
          if (byConcept[item[ele]] === undefined) {
            byConcept[item[ele]] = [];
          }
          byConcept[item[ele]].push(item[ele]);
        }
      });
    });
    if (errors.length === 0) {
      const toSave = {
        type: "queries",
        tld: market,
        data: [],
      };
      vConcepts.forEach((concept) => {
        if (byConcept[concept] !== undefined) {
          byConcept[concept].forEach((q) => {
            toSave.data.push({ q, c: concept });
          });
        }
      });

      const res = await saveQueries({
        type: "queries",
        tld: market,
        data: list,
      });
      console.log(res);
      if (res.ok) {
        setLoadingSection(false);
        window.location = "";
      } else {
        setErrorsQ(["Ha ocurrido un error al subir el archivo."]);
        setLoadingSection(false);
      }
    } else {
      setErrorsQ(errors);
      setLoadingSection(false);
    }
  };

  return (
    <ContainerFenix>
      {loadingSection ? (
        <CenterLoaderCircle />
      ) : (
        <Fragment>
          <Alert variant="info">
            <p>
              La conversión de queries a conceptos se actualiza <b>una vez al dia (por la noche)</b>, por lo
              que los cambios que efectues en esta sección se verán reflejados al día siguiente.
            </p>
          </Alert>

          <SelectMarket selection={market} onChangeMarket={(nVal) => setMarket(nVal)} />

          <CardTitle
            title={"Conceptos"}
            buttons={[
              <ButtonUploadCSV
                key={"bt1"}
                wordcontrol={`${wcConcepts}_${market.toUpperCase()}`}
                ignoreWordcontrol={false}
                specs={specs}
                buttonLabel={`Subir (${market.toUpperCase()})`}
                onResultReady={async (list) => {
                  await saveConceptsInThisMarket(list);
                }}
              />,
              <ButtonDownloadCSV
                key={"bt2"}
                wordcontrol={`${wcConcepts}_${market.toUpperCase()}`}
                head={head}
                specs={specs}
                table={concepts[market] || []}
                filename={`seo_concepts_${market.toUpperCase()}`}
                buttonLabel={`Descargar (${market.toUpperCase()})`}
                info={["Subelo completo. El orden que uses es relavante!"]}
              />,
            ]}
          />
          <Alert variant="info">
            <p>
              Deben ser codigos únicos (por mercado), se debe subir siempre el csv entero y el orden de subida
              es relevante.
            </p>
            {concepts[market] !== undefined && (
              <p>
                Actualmente hay <b>{concepts[market].length}</b> conceptos para el mercado <b>{market}</b>.
              </p>
            )}
          </Alert>

          <hr />

          <CardTitle
            title={"Queries"}
            buttons={[
              <ButtonUploadCSV
                key={"bt3"}
                wordcontrol={`${wcQueries}_${market.toUpperCase()}`}
                ignoreWordcontrol={false}
                specs={specsQ}
                buttonLabel={`Subir (${market.toUpperCase()})`}
                onResultReady={async (list) => {
                  await saveQueriesInThisMarket(list);
                }}
              />,
              <ButtonDownloadCSV
                key={"bt4"}
                wordcontrol={`${wcQueries}_${market.toUpperCase()}`}
                head={headQ}
                specs={specsQ}
                table={queries[market] || []}
                filename={`seo_queries_${market.toUpperCase()}`}
                buttonLabel={`Descargar (${market.toUpperCase()})`}
                info={["Subelo completo."]}
              />,
            ]}
          />
          <Alert variant="info">
            <p>
              Palabras. Siempre asociadas a un concepto existente en ese mercado, se debe subir siempre el csv
              entero y se ordenaran al guardarlas en el mismo orden en el que estan guardados los conceptos.
            </p>
            {queries[market] !== undefined && (
              <p>
                Actualmente hay <b>{queries[market].length}</b> queries para el mercado <b>{market}</b>.
              </p>
            )}
          </Alert>

          {errorsQ.length > 0 && (
            <Alert variant="danger">
              <Alert.Heading>¡Atencion! Errores encontrados al subir el csv de queries:</Alert.Heading>
              <ul>
                {errorsQ.map((err, index) => {
                  return <li key={`e${index}`}>{err}</li>;
                })}
              </ul>
            </Alert>
          )}
        </Fragment>
      )}
    </ContainerFenix>
  );
};

export default QueryConfigurations;
