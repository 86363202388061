/* eslint-disable no-use-before-define */
/*
  {
    1: {
      precinctCalendar:{'yyyy-01-01':{date:'yyyy-01-01',...}...}, 
      month:1, 
      products:[
        {
          productCode:'XXXXXX',
          calendar: {'yyyy-01-01':{date:'yyyy-01-01',...}...}
          tickets: [
            {
              ticketId:'---------',
              calendar: {'yyyy-01-01':{date:'yyyy-01-01',...}...}
            }
          ],
          sessions: [
            {
              sessionId:'---------',
              calendar: {'yyyy-01-01':{date:'yyyy-01-01',...}...}
              ticketSessions: [
                {
                  ticketId:'---------',
                  calendar: {'yyyy-01-01':{date:'yyyy-01-01',...}...}
                }
              ]
            }
          ]
        }
      ]
    }
  }
  */
function getViewCalendar({
  precinctCalendar,
  productCalendars,
  ticketCalendars,
  sessionCalendars,
  ticketSessionCalendars,
}) {
  const calendarForPrecinct =
    precinctCalendar !== undefined ? organizeInMonths(precinctCalendar.calendar) : [];
  const products =
    productCalendars !== undefined
      ? productCalendars.map((product) => ({
          ...product,
          calendar: organizeInMonths(product.calendar),
        }))
      : [];
  const tickets =
    ticketCalendars !== undefined
      ? ticketCalendars.map((ticket) => ({
          ...ticket,
          calendar: organizeInMonths(ticket.calendar),
        }))
      : [];

  const sessions =
    sessionCalendars !== undefined
      ? sessionCalendars.map((session) => {
          const { sessionId } = session;

          // Ticket sessions
          let ticketSessions = [];
          if (ticketSessionCalendars !== undefined && ticketSessionCalendars[session.sessionId]) {
            ticketSessions = ticketSessionCalendars[sessionId].map((tsc) => ({
              ...tsc,
              calendar: organizeInMonths(tsc.calendar),
            }));
          }

          const calendar = organizeInMonths(session.calendar);

          return { ...session, calendar, ticketSessions };
        })
      : [];

  return Array(12 - 1 + 1)
    .fill()
    .map((_, idx) => 1 + idx)
    .map((month) => {
      return {
        month,
        precinctCode: precinctCalendar.precinctCode,
        precinctCalendar: calendarForPrecinct[month],
        products: products.map((product) => ({
          productCode: product.productCode,
          calendar: product.calendar[month],
          tickets: tickets
            .filter((t) => t.productCode === product.productCode)
            .map((ticket) => ({
              ticketId: ticket.ticketId,
              alias: ticket.alias,
              priceModel: ticket.priceModel,
              quotaEnable: ticket.quotaEnable,
              priceCommissionable: ticket.priceCommissionable,
              calendar: ticket.calendar[month],
            })),
          sessions: sessions
            .filter((t) => t.productCode === product.productCode)
            .map((session) => ({
              sessionId: session.sessionId,
              calendar: session.calendar[month],
              ticketSessions: session.ticketSessions.map((ts) => ({
                ticketSessionId: [ts.sessionId, ts.ticketId].join(":"),
                alias: ts.alias,
                ticketId: ts.ticketId,
                sessionId: ts.sessionId,
                calendar: ts.calendar[month],
              })),
            })),
        })),
      };
    });
}

function organizeInMonths(calendar) {
  const monthDateList = calendar.reduce((accu, thisDayData) => {
    const dt = new Date(thisDayData.date);
    const month = dt.getMonth() + 1;

    const nAccu = { ...accu };
    if (nAccu[month] === undefined) {
      nAccu[month] = {};
    }

    nAccu[month][thisDayData.date] = {
      ...thisDayData,
      weekDay: dt.getDay(),
    };

    return nAccu;
  }, {});

  return monthDateList;
}

export default getViewCalendar;
