import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Input,
  InputLabel,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FxSwitch from "@atoms/Switches/FxSwitch";
import withDraggableList from "@atoms/Lists/withDraggableList";
/* Styles */
import {
  BoxContentConfiguration,
  BoxContentElements,
  NumberSlide,
  ElementsConfiguration,
} from "./SliderConfiguration.styles";

const SlideContent = ({ index, item, onChangeItem }) => {
  const [expanded, setExpanded] = useState(item.html.enable);

  const changeEnableLink = (nuValue) => {
    const nu = { ...item };
    nu.link.enable = nuValue;
    onChangeItem(nu);
  };

  const handleChangeAccordion = (nu) => {
    if (item.html.enable) {
      setExpanded(nu);
    }
  };

  return (
    <Fragment>
      <BoxContentConfiguration>
        <BoxContentElements>
          <NumberSlide>
            <span>{`#${index + 1}`}</span>
          </NumberSlide>
          <ElementsConfiguration>
            <TextField
              label="Imagen del banner"
              value={item.image.src}
              onChange={(nVal) => {
                const nu = { ...item };
                nu.image.src = nVal.target.value;
                onChangeItem(nu);
              }}
            />
          </ElementsConfiguration>
          <ElementsConfiguration>
            <FormControl>
              <InputLabel htmlFor={`inputLink#${index}`}>
                Enlace al clicar ({item.link.enable ? "Activado" : "Desactivado"})
              </InputLabel>
              <Input
                id={`inputLink#${index}`}
                disabled={!item.link.enable}
                label="Enlace al clicar"
                value={item.link.url}
                onChange={(e) => {
                  const nu = { ...item };
                  nu.link.url = e.target.value;
                  onChangeItem(nu);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <FxSwitch
                      labels={["activado", "desactivado"]}
                      status={item.link.enable}
                      onChange={(nuValue) => changeEnableLink(nuValue)}
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
          </ElementsConfiguration>
        </BoxContentElements>
        <div>
          <Accordion expanded={expanded} onChange={() => handleChangeAccordion(!expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <FxSwitch
                labels={["HTML activado", "HTML desactivado"]}
                status={item.html.enable}
                onChange={(nuValue) => {
                  const nu = { ...item };
                  nu.html.enable = nuValue;
                  onChangeItem(nu);
                  setExpanded(nuValue);
                }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                disabled={!item.html.enable}
                variant="outlined"
                label="Pequeño fragmento de HTML que ira sobre la imagen del slide"
                multiline={true}
                rows="8"
                value={item.html.content}
                onChange={(e) => {
                  item.html.content = e.target.value;
                  onChangeItem(item);
                }}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </BoxContentConfiguration>
    </Fragment>
  );
};

export default withDraggableList(SlideContent);
