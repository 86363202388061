/* eslint-disable import/no-unresolved */
import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import Button from "@material-ui/core/Button";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { UsCardTitle, LeftEnable } from "@atoms/Card/UsCard.styles";
import { ButtonNights } from "./Nights.styles";

function Nights({ form, onChange }) {
  const MAX_NIGHTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const handleNights = (button) => {
    const nuForm = { ...form };
    const value = parseInt(button.target.innerText);
    if (form.offerCriteria.nights.find((element) => element === value) === undefined) {
      nuForm.offerCriteria.nights.push(parseInt(button.target.innerText));
    } else {
      nuForm.offerCriteria.nights.splice(nuForm.offerCriteria.nights.indexOf(value), 1);
    }
    nuForm.offerCriteria.nights.sort((a, b) => a - b);
    onChange(nuForm);
  };

  const addAllNights = () => {
    const nuForm = { ...form };
    nuForm.offerCriteria.nights = MAX_NIGHTS;
    onChange(nuForm);
  };

  const removeAllNights = () => {
    const nuForm = { ...form };
    nuForm.offerCriteria.nights = [];
    onChange(nuForm);
  };

  const getNights = () => {
    return MAX_NIGHTS.map((cv, i) => {
      return (
        <Col key={`btn-${i}`}>
          <ButtonNights
            disableElevation
            color={
              form.offerCriteria.nights.find((element) => element === cv) === undefined
                ? "inherit"
                : "primary"
            }
            variant={
              form.offerCriteria.nights.find((element) => element === cv) === undefined
                ? "outlined"
                : "contained"
            }
            onClick={(button) => {
              handleNights(button);
            }}
          >
            {cv}
          </ButtonNights>
        </Col>
      );
    });
  };

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <UsCardTitle>
            Noches validas
            <LeftEnable>
              <ButtonGroup aria-label="outlined  primary button group">
                <Button onClick={addAllNights}>
                  <FiPlusSquare /> Marcar todas
                </Button>
                <Button onClick={removeAllNights}>
                  <FiMinusSquare /> Quitar todas
                </Button>
              </ButtonGroup>
            </LeftEnable>
          </UsCardTitle>
          <br />
          <Row>{getNights()}</Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default Nights;
