import React from "react";
import Grid from "@material-ui/core/Grid";
import { Card, Alert } from "react-bootstrap";
import FxSwitch from "@atoms/Switches/FxSwitch";
import AddEmails from "@molecules/Forms/AddEmails";

function CrossSellingNotification({ form, onChange }) {
  const changeEmailList = (newEmailList) => {
    const nuForm = { ...form };
    nuForm.emailsManager = newEmailList;
    onChange(nuForm);
  };

  return (
    <Card>
      <Card.Header className="text-right">
        <Grid container justify="space-between" alignItems="baseline">
          <Grid item>
            <span style={{ fontSize: "1.2em" }}>Notificaciones</span>
          </Grid>
          <Grid item>
            <FxSwitch
              key="FxSwitch"
              labels={["Notificar al enviar", "Notificar al enviar"]}
              status={form.notificateWhenSend}
              onChange={(nuValue) => {
                const nuForm = { ...form };
                nuForm.notificateWhenSend = nuValue;
                onChange(nuForm);
              }}
            />
          </Grid>
        </Grid>
      </Card.Header>
      <Card.Body>
        <Alert variant="info">
          <Alert.Heading>Lista de distribucion para notificaciones</Alert.Heading>
          <ul>
            <li>Añadiendo emails</li>
            <li>
              Se notificara:
              <ul>
                <li>Resumen de envío de la campaña (Sólo si se marca el check de arriba).</li>
                <li>Errores en la ejecución de una campaña.</li>
                <li>
                  Warnings: Códigos de reserva salen en el mercado que no corresponde, en el status que no
                  corresponde o han repetido la misma campaña.
                </li>
              </ul>
            </li>
          </ul>
        </Alert>

        <Grid container>
          <Grid item xs={12}>
            <AddEmails emails={form.emailsManager} onChangeList={changeEmailList} />
          </Grid>
          {/* <Grid item>
            <TextField
              label="Lista de notificacion"
              placeholder="Lista de notificacion"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={""}
              onChange={(nVal) => {
                const nuForm = { ...form };
                nuForm.notificationList = nVal.target.value;
                setForm(nuForm);
              }}
            />
          </Grid> */}
        </Grid>
      </Card.Body>
    </Card>
  );
}

export default CrossSellingNotification;
