import React from "react";
import InputNumber from "@atoms/Inputs/InputNumber";
import MonthSelector from "./MonthSelector";

function DayOfMonthSelector({ dayOfMonthSelected, disabled, onChange }) {
  function onChangeSelection(field, value) {
    onChange({ ...dayOfMonthSelected, [field]: parseInt(value, 10) });
  }

  return (
    <div style={{ display: "flex" }}>
      <InputNumber
        size="small"
        min={0}
        max={31}
        style={{ borderRadius: "4px 0 0 4px" }}
        disabled={disabled}
        label={"Día"}
        value={dayOfMonthSelected.day}
        onChange={(d) => onChangeSelection("day", d)}
      />
      <MonthSelector
        disabled={disabled}
        style={{ height: "37px", borderRadius: "0 4px 4px 0" }}
        monthSelected={dayOfMonthSelected.month}
        onChangeMonth={(m) => onChangeSelection("month", m)}
      />
    </div>
  );
}

export default DayOfMonthSelector;
