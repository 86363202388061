import React, { useState, useEffect } from "react";
import SectionTitle from "../../../../../1-atoms/Titles/SectionTitle";
import ButtonCustom from "../../../../../1-atoms/Buttons/ButtonCustom";
import ButtonSave from "../../../../../1-atoms/Buttons/ButtonSave";
import { BiPlus } from "react-icons/bi";
import { TravBodyCard, TravCard } from "../../../../../1-atoms/Card/FxCard";
import { Grid } from "@material-ui/core";
import InputText from "../../../../../1-atoms/Inputs/InputText";
import Modal from "../../../../../1-atoms/Modals/Modal";
import {
  ACCOUNTING_SUPPLIER_BANK_ACCOUNTS_URL,
  useAccountingSupplierBankAccountSave,
  useAccountingSuppliersManagement,
} from "../../hooks";
import FenixTableCustomPagination from "../../../../../2-molecules/Tables/FenixTable/FenixTableCustomPagination";
import { userAuthorized } from "../../../../../entries/fenix/auth/FenixAuthContext";
import { uuid_v4 } from "../../../../../lib/helpers/uuid_v4";

import CustomModal from "@molecules/Modal/CustomModal";
export const AccountingSupplierBankAccountsManagement = ({ supplier, backFunction, loading }) => {
  const user = userAuthorized();
  const [bankAccountMode, setBankAccountMode] = useState("list");
  const [supplierData, setSupplierData] = useState({});
  const [bankAccountForm, setBankAccountForm] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const [forceReloadList, setForceReloadList] = useState(0);
  const [query, setQuery] = useState("");
  const buttons = () => {
    const btns = [
      <ButtonCustom
        label={"Añadir cuenta bancaria"}
        aria-label={"Añadir cuenta bancaria"}
        icon={<BiPlus />}
        onClick={() => {
          setBankAccountMode("add");
        }}
      >
        Añadir cuenta bancaria
      </ButtonCustom>,
    ];
    return btns;
  };
  useEffect(() => {
    const updateSupplier = async () => {
      if (supplier !== undefined) {
        const { accountingSupplierData } = await useAccountingSuppliersManagement(supplier);
        setSupplierData(accountingSupplierData.accountingSupplier);
      }
    };
    updateSupplier();
  }, [supplier]);

  const updateForm = (newForm) => {
    setBankAccountForm({ ...newForm });
  };

  const validateForm = () => {
    const errors = [];
    if (bankAccountForm.cc === undefined || bankAccountForm.cc.trim().length === 0) {
      errors.push("La cuenta corriente es obligatoria");
    }
    if (bankAccountForm.holder === undefined || bankAccountForm.holder.trim().length === 0) {
      errors.push("El titular es obligatorio");
    }
    setValidationErrors(errors);
    return errors.length === 0;
  };

  const resetMode = () => {
    setBankAccountForm({});
    setBankAccountMode("list");
    setForceReloadList(forceReloadList + 1);
  };

  const saveBankAccount = async () => {
    const isValid = validateForm();
    if (isValid) {
      const bankAccount = {
        id: uuid_v4(),
        cc: bankAccountForm.cc,
        holder: bankAccountForm.holder,
        supplier: supplier,
        createdBy: user.profile.email,
        dateCreation: new Date(),
      };
      const { error, errorText } = await useAccountingSupplierBankAccountSave(bankAccount.id, bankAccount);
      if (error) {
        setValidationErrors([errorText]);
      } else {
        resetMode();
      }
    }
  };
  const tableHead = [
    {
      code: "supplier",
      name: "Supplier",
      type: "string",
    },
    {
      code: "name",
      name: "Número de cuenta",
      type: "string",
    },
    {
      code: "holder",
      name: "Titular",
      type: "string",
    },
    {
      code: "createdBy",
      name: "Creado por",
      type: "string",
    },
    {
      code: "dateCreation",
      name: "Fecha de creación",
      type: "date",
    },
  ];
  return (
    <>
      <Grid item xs={12}>
        <TravCard>
          <SectionTitle
            title={`💳 Cuentas bancarias ${supplier}`}
            backFunction={backFunction}
            hx="h5"
            buttons={buttons()}
          />
          <TravBodyCard>
            <FenixTableCustomPagination
              tableHead={tableHead}
              actions={[]}
              customPaginationFetchUrl={ACCOUNTING_SUPPLIER_BANK_ACCOUNTS_URL}
              searchQueryParam={["id[regex]"]}
              searchQueryAdditionalParams={`supplier=${supplier}`}
              withSearch={true}
              inputText={query}
              maxNumberPerPages={30}
              pagination={true}
              isSelectable={false}
              forceReload={forceReloadList}
            />
          </TravBodyCard>
        </TravCard>
      </Grid>
      <CustomModal
        loading={false}
        title={
          <SectionTitle
            title="Nueva cuenta bancaria"
            backFunction={() => setBankAccountMode("list")}
            hx="h5"
            buttons={[<ButtonSave onClick={saveBankAccount} />]}
          />
        }
        open={bankAccountMode === "add"}
        handleClose={() => {
          setBankAccountMode("list");
        }}
        bigModal={true}
        closeOnClickOutside={false}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <InputText
              title="IBAN"
              value={bankAccountForm.cc}
              onChange={(e) => {
                updateForm({ ...bankAccountForm, cc: e.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <InputText
              title="Titular"
              value={bankAccountForm.holder}
              onChange={(e) => {
                updateForm({ ...bankAccountForm, holder: e.value });
              }}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <Modal
        show={validationErrors.length > 0}
        fullWidth={false}
        onClose={() => {
          setValidationErrors([]);
        }}
      >
        <ul>
          {validationErrors.map((error, index) => (
            <li key={`error${index}`}>{error}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
};
