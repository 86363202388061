import axios from "axios";

const prefix = "/api/v1/ticketing-bc/tickets";

export const fetchTickets = async (filters) => {
  let params = {};
  if (filters.params !== undefined) {
    params = filters.params;
  }
  if (filters.precinctCode !== undefined) {
    params = { params: { ...filters } };
  }

  const { data } = await axios.get(`${prefix}`, { params });
  return data.data;
};

export const fetchTicket = async (ticketId) => {
  const { data } = await axios.get(`${prefix}/${ticketId}`, {});
  return data;
};

export const saveTicket = async (ticket) => {
  await axios.put(`${prefix}/${ticket.id}`, ticket);
};
