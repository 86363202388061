import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import FxSwitch from "@atoms/Switches/FxSwitch";
// import HTMLEditor from "@molecules/Forms/HTMLEditor";
import { useRelevantInfoChannels } from "./useRelevantInformation";
import ChannelSelector from "../../Calendar/ChannelSelector";
import { getChannelID } from "../../shared/domainVariables";
import HtmlTextEditor from "@atoms/Inputs/HtmlTextEditor/HtmlTextEditor";

function RelevantInformationForm({
  relevantInformationGroup,
  productCode,
  precinctCode,
  selectedInnerIndex,
  setSelectedInnerIndex,
  onChange,
}) {
  const [relevantInformationSelected, setRelevantInformationSelected] = useState(
    relevantInformationGroup[selectedInnerIndex]
  );
  let [channelSelected, setChannelSelected] = useState({ provider: "Todos los canales", none: true });
  let loadingChannels, channels, onChangeChannel;
  ({ loadingChannels, channels, onChangeChannel } = useRelevantInfoChannels(
    precinctCode,
    productCode,
    channelSelected,
    setChannelSelected
  ));

  const findChannelInnerIndex = (selected) => {
    const channelIndex = channels.findIndex(
      (ch) =>
        relevantInformationGroup[selected].channel &&
        getChannelID(ch) === getChannelID(relevantInformationGroup[selected].channel)
    );
    return channelIndex !== -1 ? channelIndex : 0;
  };

  useEffect(() => {
    if (selectedInnerIndex !== undefined) {
      setRelevantInformationSelected(relevantInformationGroup[selectedInnerIndex]);
      setChannelSelected(channels[findChannelInnerIndex(selectedInnerIndex)]);
    }
  }, [selectedInnerIndex]);

  return (
    <Grid key={`container`} container spacing={2} alignItems={"flex-start"} direction="row">
      <Grid item xs={10} sm={4} key={`item`}>
        <TextField
          key={`TF`}
          label="Titulo"
          value={relevantInformationSelected.title}
          onChange={(evt) => {
            const newInfo = { ...relevantInformationSelected };
            newInfo.title = evt.target.value;
            setRelevantInformationSelected(newInfo);
            onChange(newInfo, selectedInnerIndex);
          }}
        />
      </Grid>
      <Grid item xs={7} sm={4} key={`channelSelect`}>
        <ChannelSelector
          key={`ChSl`}
          channelSelected={channelSelected}
          onChange={async (evt) => {
            onChangeChannel(evt);

            let selected = relevantInformationGroup.findIndex((info) => {
              return (
                (info.channel && getChannelID(info.channel) === getChannelID(evt.target.value)) ||
                (evt.target.value.none && !info.channel)
              );
            });
            let newInfo = {};
            if (selected !== -1) {
              setRelevantInformationSelected(relevantInformationGroup[selected]);
              newInfo = relevantInformationGroup[selected];
            } else {
              newInfo = {
                enable: false,
                content: "",
                title: "",
                lang: relevantInformationSelected.lang,
                productCode,
                precinctCode,
                channel: evt.target.value,
              };
              relevantInformationGroup.push(newInfo);
              selected = relevantInformationGroup.length - 1;
              setRelevantInformationSelected(newInfo);
            }
            setSelectedInnerIndex(findChannelInnerIndex(selected));
            onChange(newInfo, selected);
          }}
          {...{ loadingChannels, channels }}
        />
      </Grid>
      <Grid item xs={5} sm={2}>
        <FxSwitch
          key={`FXS`}
          labels={["Activo", "Activo"]}
          status={relevantInformationSelected.enable}
          onChange={(value) => {
            const newInfo = { ...relevantInformationSelected };
            newInfo.enable = value;
            onChange(newInfo, selectedInnerIndex);
            setRelevantInformationSelected(newInfo);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <HtmlTextEditor
          value={relevantInformationSelected.content}
          width={"100%"}
          onChange={(html) => {
            const newInfo = { ...relevantInformationSelected };
            newInfo.content = html;
            setRelevantInformationSelected(newInfo);
            onChange(newInfo, selectedInnerIndex);
          }}
        />
      </Grid>
    </Grid>
  );
}

RelevantInformationForm.propTypes = {};

export default RelevantInformationForm;
