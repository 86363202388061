import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { TextField, InputAdornment, MenuList, MenuItem, ClickAwayListener } from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import axios from "axios";
import {
  DwnInputQuery,
  ContInputQuery,
} from "@atoms/Inputs/InputQueryDropdownSearch/InputQueryDropdown.styles";
import { getOfferTagsForMarketAndType } from "../../../hooks/useOfferFetch";

const InputQueryDropdownForTagSearchers = ({ label, optionFormat, onSelectOption, market, type }) => {
  const MINCHAR = 2;
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const [options, setOptions] = useState([]);
  const [allOptions, setAllOptions] = useState({ loadingList: false, list: [] });

  const handleUseTagList = async () => {
    let offers = await getOfferTagsForMarketAndType(market, type);
    setAllOptions(modifyTags(offers));
    setIsLoading(offers.loadingList);
  };

  useEffect(() => {
    handleUseTagList();
  }, [market]);

  const findInAllOptions = (searched) => {
    let exp = new RegExp(`${searched.toLowerCase()}`);
    return allOptions.reduce((acc, cv, i) => {
      if (exp.test(cv.toLowerCase())) {
        acc.push(cv);
      }
      return acc;
    }, []);
  };

  const modifyTags = (tags) => {
    return tags.list.reduce((acc, cv, i) => {
      if (cv.enable) {
        acc.push(cv.tag);
      }
      return acc;
    }, []);
  };

  const handleSearch = async (event) => {
    const query = event.target.value;
    setInputText(query);
    if (query.length === 0) {
      setIsOpen(false);
      setOptions([]);
    }
    if (query.length <= MINCHAR) {
      // setIsLoading(false);
      setIsOpen(false);
      setOptions([]);
      return;
    }

    // setIsLoading(true);
    setIsOpen(false);
    let optionsFound = findInAllOptions(query);
    if (query.length > MINCHAR) {
      if (optionsFound.length > 0) {
        //setIsLoading(false);
        setIsOpen(true);
        setOptions(optionsFound);
      } else {
        //setIsLoading(false);
        setIsOpen(true);
      }
    } else {
      //setIsLoading(true);
      setIsOpen(true);
      setOptions([]);
    }
  };

  /**
   *
   * @param {*} option
   */
  const selectOption = (option) => {
    setIsLoading(false);
    setIsOpen(false);
    setOptions([]);
    setInputText("");
    onSelectOption(option);
  };

  return (
    <ContInputQuery>
      <TextField
        label={label}
        variant="outlined"
        value={inputText}
        size="small"
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdSearch />
            </InputAdornment>
          ),
          endAdornment: isLoading && <Spinner animation="border" role="status" size="sm" />,
        }}
      />

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Paper style={{ maxHeight: "230px", overflowY: "auto" }}>
            <MenuList>
              {options.length === 0 && <MenuItem>No hay resultados</MenuItem>}
              {/* Menu List */}
              {options.length > 0 &&
                options.map((option, index) => {
                  const ixKey = `searchoption_${index}`;
                  return (
                    <MenuItem key={ixKey} onClick={() => selectOption(option)}>
                      {optionFormat(option)}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      )}
    </ContInputQuery>
  );
};

InputQueryDropdownForTagSearchers.propTypes = {
  label: PropTypes.string,
  getRequestPath: PropTypes.string,
  optionFormat: PropTypes.func,
  onSelectOption: PropTypes.func,
};

export default InputQueryDropdownForTagSearchers;
