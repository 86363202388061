import React from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { FiPlusSquare } from "react-icons/fi";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import { Alert } from "react-bootstrap";
import useJsonPagesListState from "./JsonPagesList.state";

const JsonPagesListView = ({ type }) => {
  const {
    MAX_PAGES_VIEW,
    errorState,
    setErrors,
    market,
    loading,
    list,
    rowsSelected,
    onChangeMarketSelections,
    handleMarket,
    tableHead,
    addNewJsonPage,
    actions,
  } = useJsonPagesListState({ type });
  return (
    <>
      <CardTitle
        title={"Listado de páginas JSON"}
        buttons={[
          <SelectMarket key={"bt1"} selection={market} onChangeMarket={handleMarket} />,
          <ButtonAdd key={"bt5"} icon={<FiPlusSquare />} onClick={addNewJsonPage}>
            Nueva Página
          </ButtonAdd>,
        ]}
      />
      <ErrorModal
        show={errorState.show}
        listErrors={errorState.list}
        onClose={() => setErrors({ show: false, list: [] })}
      />
      {market === null ? (
        <Alert variant={"info"}>
          <span>Selecciona un mercado</span>
        </Alert>
      ) : (
        <>
          {market === null ? (
            <Alert variant={"info"}>
              <span>Selecciona un mercado</span>
            </Alert>
          ) : (
            <FenixTable
              loading={loading}
              tableHead={tableHead}
              tableContent={list}
              actions={actions}
              maxNumberPerPages={MAX_PAGES_VIEW}
              pagination={true}
              // handleRowsChecked={(values) => {
              //   handleCheckboxReload(values);
              // }}
              // checkboxInit={{ active: true, list: reload.valuesReload }}
              isSelectable={true}
              selectedRows={rowsSelected[market]}
              onChangeSelections={onChangeMarketSelections}
            />
          )}
        </>
      )}
    </>
  );
};

export default JsonPagesListView;
