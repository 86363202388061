import React, { useState } from "react";
import FenixTableCustomPagination from "@molecules/Tables/FenixTable/FenixTableCustomPagination";
import Modal from "react-bootstrap/Modal";
import Grid from "@material-ui/core/Grid";
import { AiFillEye, AiOutlineSave } from "react-icons/ai";
import { TextField } from "@material-ui/core";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { putCookieGroup, deleteCookieGroup } from "../../hooks/userCookieGroupFetch";
import CardTitle from "@atoms/Titles/CardTitle";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import FxSwitch from "@atoms/Switches/FxSwitch";
import { InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import { MdClear, MdOutlineModeEditOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { LS_setItem } from "@lib/storage/localStorage";

const CookiesGroupList = ({}) => {
  const history = useHistory();
  const CONSENT_TYPE = {
    ad_storage: "Marketing",
    analytics_storage: "Analítica",
  };
  const [emailSearchQuery, setEmailSearchQuery] = useState("");
  const [forceReloadTable, setForceReloadTable] = useState(0);
  const [alertData, setAlertData] = useState({ text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorModal, setErrorModal] = useState({ open: false, errors: [] });
  const [modal, setModal] = useState({
    open: false,
    disabledCode: true,
    code: "",
    name: { es: "", fr: "", pt: "", it: "" },
    description: { es: "", fr: "", pt: "", it: "" },
    enable: false,
    consentType: "",
    "@created_on": "",
    "@updated_on": "",
    tld: "es",
  });

  const resetModal = () => {
    setModal({
      open: false,
      disabledCode: true,
      code: "",
      name: { es: "", fr: "", pt: "", it: "" },
      description: { es: "", fr: "", pt: "", it: "" },
      enable: false,
      consentType: "",
      "@created_on": "",
      "@updated_on": "",
      tld: "es",
    });
  };

  const addNewCookieGroup = () => {
    resetModal();
    const newModal = { ...modal };
    newModal.code = "";
    newModal.open = true;
    newModal.disabledCode = false;
    setModal(newModal);
  };

  const actionVisualice = async (rowData) => {
    setModal({
      open: true,
      disabledCode: true,
      ...rowData,
      tld: "es",
    });
  };

  const actionDeleteGroup = async (rowData) => {
    const deleteConfirm = confirm(`¿Está seguro de eliminar el grupo de ${rowData.code}?`);
    if (deleteConfirm) {
      const response = await deleteCookieGroup(rowData);
      if (response.ok) {
        setAlertOpen(true);
        setAlertData({ text: `Grupo borrada con éxito` });
      } else {
        setErrorModal({
          open: true,
          errors: [`Error al borrar el grupo de ${rowData.code}`],
        });
      }
      resetModal();
      setForceReloadTable(forceReloadTable + 1);
    }
  };

  const cookieGroupValidation = (point) => {
    const errors = [];
    if (point.name === "") {
      errors.push(`El código de hotel está vacío.`);
    }
    if (point.description["es"] === "") {
      errors.push(`La descripción en Español está vacía.`);
    }
    if (point.description["pt"] === "") {
      errors.push(`La descripción en portugués está vacía.`);
    }
    if (point.description["fr"] === "") {
      errors.push(`La descripción en francés está vacía.`);
    }
    if (point.description["it"] === "") {
      errors.push(`La descripción en Italiano está vacía.`);
    }
    if (point.name["es"] === "") {
      errors.push(`El nombre en Español está vacío.`);
    }
    if (point.name["pt"] === "") {
      errors.push(`El nombre en portugués está vacío.`);
    }
    if (point.name["fr"] === "") {
      errors.push(`El nombre en francés está vacío.`);
    }
    if (point.name["it"] === "") {
      errors.push(`El nombre en Italiano está vacío.`);
    }
    if (point.code === "") {
      errors.push(`El código esta vacio`);
    }
    if (point.consentType === "") {
      errors.push(`El tipo de consentimiento esta vacio`);
    }
    return errors;
  };

  const saveCookieGroup = async (row) => {
    const nuRow = { ...row };
    const error = cookieGroupValidation(nuRow);
    if (error.length > 0) {
      setErrorModal({
        open: true,
        errors: error,
      });
    } else {
      const response = await putCookieGroup(nuRow);
      if (response.ok) {
        setAlertOpen(true);
        setAlertData({ text: `Grupo modificada con éxito` });
        setForceReloadTable(forceReloadTable + 1);
        resetModal();
      } else {
        resetModal();
        setErrorModal({
          open: true,
          errors: [`Error al guardar la información de ${row.code}: ${response.error}`],
        });
      }
    }
  };

  const handleAvailability = async (group) => {
    const nuGroup = { ...group };
    nuGroup.enable = !group.enable;
    const response = await putCookieGroup(nuGroup);
    setAlertOpen(true);
    setAlertData({ text: `Grupo modificada con éxito` });
    setForceReloadTable(forceReloadTable + 1);
    return response;
  };

  const tableHead = [
    { code: "code", name: "Código", type: "string" },
    {
      code: "consentType",
      name: "Tipo de consentimiento",
      type: "customFromRow",
      customFromRow: (rowData) => {
        return CONSENT_TYPE[rowData.rowData.consentType];
      },
    },
    {
      code: "enable",
      name: "Habilitado",
      type: "boolean",
      func: async (group) => {
        return await handleAvailability(group);
      },
    },
    { code: "@updated_on", name: "Ultima vez Actualizado", type: "string" },
  ];

  const actionManageCookies = (rowData) => {
    LS_setItem("cookieGroupCode", rowData.code);
    history.push(`/app/content/cookies/list`);
  };

  const actions = [
    { name: "Editar", icon: <MdOutlineModeEditOutline />, func: actionVisualice },
    { name: "Ver cookies", icon: <AiFillEye />, func: actionManageCookies },
    { name: "Borrar", icon: <MdClear />, func: actionDeleteGroup },
  ];

  return (
    <>
      <CardTitle
        title={"🍪 Listado de Grupos de Cookies 🍪"}
        buttons={[
          <ButtonAdd
            key={"buton_add"}
            size="small"
            onClick={() => {
              addNewCookieGroup();
            }}
          >
            Añadir Grupo
          </ButtonAdd>,
        ]}
      />
      <FenixTableCustomPagination
        loading={false}
        tableHead={tableHead}
        actions={actions}
        customPaginationFetchUrl={`/api/v1/product-bc/content/cookies-group`}
        searchQueryParam={["code[regex]"]}
        withSearch={true}
        inputText={emailSearchQuery}
        maxNumberPerPages={15}
        pagination={true}
        isSelectable={false}
        forceReload={forceReloadTable}
      />

      <Modal show={modal.open} onHide={resetModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Información del grupo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={4}>
            <Grid item>
              <TextField
                disabled={modal.disabledCode}
                label="Código"
                value={modal.code}
                onChange={(nVal) => {
                  const nuModal = { ...modal };
                  nuModal.code = nVal.target.value;
                  setModal(nuModal);
                }}
              />
            </Grid>
            <Grid item>
              <InputLabel id="GDC">Tipo de consentimiento</InputLabel>
              <Select
                labelId="GDCS"
                labelWidth={130}
                autoWidth={true}
                value={modal.consentType}
                onChange={(selectedNow) => {
                  const nuModal = { ...modal };
                  nuModal.consentType = selectedNow.target.value;
                  setModal(nuModal);
                }}
              >
                {Object.keys(CONSENT_TYPE).map((key, index) => {
                  return (
                    <MenuItem key={index} value={key}>
                      {CONSENT_TYPE[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item>
              <FxSwitch
                value="end"
                labels={["Grupo habilitado", "Grupo deshabilitado"]}
                status={modal.enable}
                onChange={(nuValue) => {
                  const nuModal = { ...modal };
                  nuModal.enable = nuValue;
                  setModal(nuModal);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                  <h6 style={{ color: "silver" }}>Configuración por idioma</h6>
                </Grid>
                <Grid item>
                  <SelectMarket
                    selection={"es"}
                    onChangeMarket={(nVal) => {
                      const nuModal = { ...modal };
                      nuModal.tld = nVal;
                      setModal(nuModal);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
                    <Grid item xs={4}>
                      <TextField
                        disabled={false}
                        label={`Nombre`}
                        value={modal.name[modal.tld]}
                        onChange={(nVal) => {
                          const nuModal = { ...modal };
                          nuModal.name[modal.tld] = nVal.target.value;
                          setModal(nuModal);
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid
                        container
                        direction="column"
                        // justify="space-between"
                        // alignItems="flex-start"
                        spacing={0}
                      >
                        <Grid item>
                          <p style={{ fontSize: "0.8em", color: "silver" }}>Descripcion</p>
                        </Grid>
                        <Grid item>
                          <textarea
                            style={{ height: "150px", width: "100%", marginTop: "-10px" }}
                            name="Texto"
                            rows="10"
                            cols="50"
                            value={modal.description[modal.tld]}
                            disabled={false}
                            placeholder={"Texto de la publicación"}
                            onChange={(nVal) => {
                              const nuModal = { ...modal };
                              nuModal.description[modal.tld] = nVal.target.value;
                              setModal(nuModal);
                            }}
                          ></textarea>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            {modal.disabledCode && (
              <Grid item>
                <ButtonCustom
                  key={"btDelete"}
                  customcolor="tomato"
                  icon={<MdClear />}
                  onClick={() => {
                    actionDeleteGroup(modal);
                  }}
                >
                  Borrar
                </ButtonCustom>
              </Grid>
            )}

            <Grid item>
              <ButtonCustom
                key={"btSend"}
                customcolor="green"
                icon={<AiOutlineSave />}
                onClick={() => {
                  const newModal = { ...modal };
                  delete newModal.open;
                  saveCookieGroup(newModal);
                }}
              >
                Guardar
              </ButtonCustom>
            </Grid>
          </Grid>
        </Modal.Footer>
      </Modal>
      <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
      <ErrorModal
        show={errorModal.open}
        listErrors={errorModal.errors}
        onClose={() => setErrorModal({ open: false, errors: [] })}
      />
    </>
  );
};

export default CookiesGroupList;
