import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ButtonGroup, Button } from "@material-ui/core";
import MarketChip from "@atoms/Content/MarketChip";

function SelectMarketMulti({ selection, onChangeMarket }) {
  const markets = ["es", "pt", "fr", "it"];
  const [market, setMarket] = useState(selection || ["es"]);

  useEffect(() => {
    if (selection !== undefined) {
      setMarket(selection);
    }
  }, [selection]);

  const onClickMarket = (imarket) => {
    const nuMarket = [...market];
    const ix = market.indexOf(imarket);
    if (ix !== -1) {
      nuMarket.splice(ix, 1);
    } else {
      nuMarket.push(imarket);
    }
    setMarket(nuMarket);
    onChangeMarket(nuMarket);
  };

  return (
    <Fragment>
      <ButtonGroup
        className="market-no-select-class"
        size="large"
        color="primary"
        aria-label="marketSelector"
      >
        {markets.map((imarket) => {
          return (
            <Button
              key={`market_${imarket}`}
              color={market.includes(imarket) ? "secondary" : "primary"}
              className={market.includes(imarket) ? "market-select-class" : "market-no-select-class"}
              onClick={() => onClickMarket(imarket)}
            >
              <MarketChip market={imarket} />
            </Button>
          );
        })}
      </ButtonGroup>
    </Fragment>
  );
}

SelectMarketMulti.propTypes = {
  selection: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChangeMarket: PropTypes.func,
};

SelectMarketMulti.defaultProps = {};

export default SelectMarketMulti;
