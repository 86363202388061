import styled from "styled-components";
import { Alert } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { ImCheckmark, ImPlus, ImMinus, ImCross } from "react-icons/im";

export const ContListEditable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputListEditable = styled.div`
  flex-grow: 4;
`;

export const ButtonImg = styled(Button)`
  height: 40px;
  padding: 6px !important;
  min-width: 40px !important;
  margin-left: 10px;
`;

export const Check = styled(ImCheckmark)`
  color: #fff;
`;

export const Cross = styled(ImCross)`
  color: #1f1f1f;
`;

export const Plus = styled(ImPlus)`
  color: #006699;
  &:hover {
    color: #17a2b8;
  }
`;
