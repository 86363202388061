/* eslint-disable no-use-before-define */
import React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ButtonGroup, Button } from "@material-ui/core";

function ButtonsGlobalVisibility({ onClickShow, onClickHide }) {
  return (
    <div style={{ display: "inline-grid" }}>
      {/* <span>Visibilidad</span> */}
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button size="large" onClick={onClickShow}>
          <FaEye />
        </Button>
        <Button size="large" onClick={onClickHide}>
          <FaEyeSlash />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default ButtonsGlobalVisibility;
