/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import ListEditable from "@atoms/Lists/ListEditable";
import { Row, Col, Card } from "react-bootstrap";
import { MdDateRange, MdLocationOn, MdAccountBalance } from "react-icons/md";
import { OfferIconInclude } from "./OfferEditorInfo.styles";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function OfferTagsList({ listTags, onChange }) {
  const [updating, setUpdating] = useState(false);
  const [updatePending, setUpdatePending] = useState(false);

  const updateIt = async (type, list) => {
    sleep(5000);
    onChange(type, list);
  };

  const ejecutarActualización = async (type, list) => {
    if (updatePending) {
      setUpdatePending(false);
      ejecutarActualización();
    } else {
      setUpdating(false);
      // updateIt(type, list);
      onChange(type, list);
    }
  };

  const updateInServer = (type, list) => {
    if (updating) {
      if (!updatePending) {
        setUpdatePending(true);
      }
    } else {
      setUpdating(true);
      ejecutarActualización(type, list);
    }
  };

  const tagFormatter = (type) => {
    return listTags
      .reduce((acc, cv, i) => {
        if (cv.type === type) {
          acc = cv.tags;
        }
        return acc;
      }, [])
      .reduce((acc, cv) => {
        acc.push(cv.tag);
        return acc;
      }, []);
  };

  return (
    <Row>
      <Col xs={4}>
        <Card>
          <Card.Body>
            <OfferIconInclude>
              <MdAccountBalance />
            </OfferIconInclude>
            <ListEditable
              key={1}
              title={"Temas (Topic)"}
              info={tagFormatter("topic")}
              onChangeList={(topics) => {
                updateInServer("topic", topics);
                //onChange("topic", topics);
              }}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col xs={4}>
        <Card>
          <Card.Body>
            <OfferIconInclude>
              <MdDateRange />
            </OfferIconInclude>
            <ListEditable
              key={2}
              title={"Fechas (Dates)"}
              info={tagFormatter("dates")}
              onChangeList={(dates) => {
                onChange("dates", dates);
              }}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col xs={4}>
        <Card>
          <Card.Body>
            <OfferIconInclude>
              <MdLocationOn />
            </OfferIconInclude>
            <ListEditable
              key={3}
              title={"Destinos (Location)"}
              info={tagFormatter("location")}
              onChangeList={(locations) => {
                onChange("location", locations);
              }}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default OfferTagsList;
