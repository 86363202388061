import React from "react";
import Grid from "@material-ui/core/Grid";
import { FaShare } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { GoKebabVertical } from "react-icons/go";
import { AiOutlineSearch } from "react-icons/ai";
import { BsReverseLayoutSidebarReverse } from "react-icons/bs";

function PreviewTelegram({ form, cardImg, formaterText }) {
  const getHour = () => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes}`;
  };

  const getLikes = () => {
    return Math.floor(Math.random() * (500 - 50 + 1) + 50);
  };

  const formatTelegramMessage = (html) => {
    let newHtml = html
      .replace(/<b>/g, "<strong>")
      .replace(/<\/b>/g, "</strong>")
      .replace(/<i>/g, "<em>")
      .replace(/<\/i>/g, "</em>")
      .replace(/<\/p>/g, "\n")
      .replace(
        /<\/?(?!strong|em|a|code|pre|u|strike|\/strong|\/em|\/a|\/code|\/pre|\/u|\/strike)[^>]+>/g,
        ""
      );
    return newHtml.slice(0, 1024);
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={0}>
                <Grid item xs={12}>
                  <b>Traventia España - Viajes y Escapadas Chollo</b>
                </Grid>
                <Grid item xs={12}>
                  <span style={{ color: "silver", fontSize: "0.8em" }}>{getLikes() * 100} suscriptores</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <AiOutlineSearch style={{ fontSize: "20px", color: "grey" }} />
                </Grid>
                <Grid item>
                  <BsReverseLayoutSidebarReverse style={{ fontSize: "20px", color: "grey" }} />
                </Grid>
                <Grid item>
                  <GoKebabVertical style={{ fontSize: "20px", color: "grey" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          spacing={2}
          style={{
            backgroundColor: "#AFDBF3",
            backgroundRepeat: "repeat",
            backgroundImage: "url(https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-12.svg)",
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <Grid item xs={8}>
            <Grid
              container
              direction="column"
              spacing={2}
              style={{
                backgroundColor: "white",
                width: "400px",
                margin: "10px",
                borderRadius: "12px 12px 12px 0",
              }}
            >
              <Grid item xs={12}>
                <span style={{ color: "#1D98DC", marginLeft: "20px" }}>
                  <b>Traventia España - Viajes y Escapadas Chollo</b>
                </span>
              </Grid>
              <Grid item xs={12}>
                <img
                  src={cardImg}
                  width={"100%"}
                  style={{ marginRight: "16px", marginTop: "-15px", padding: "-20px" }}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0px 10px 0px 10px", margin: "-10px 0px 0px 10px" }}>
                <div
                // dangerouslySetInnerHTML={{
                //   __html: formaterText(formatTelegramMessage(form.socialNetwork.text)),
                // }}
                >
                  {formaterText(form.socialNetwork.text.slice(0, 1024))}
                </div>
              </Grid>
              <Grid item xs={12} style={{ margin: "10px 5px 2px 5px" }}>
                <Grid container direction="row" justify="space-between" spacing={2}>
                  <Grid item>
                    <span
                      style={{
                        borderRadius: "10px",
                        color: "#1d98dc",
                        backgroundColor: "rgb(98, 175, 252, 0.3)",
                        padding: "3px",
                      }}
                    >
                      🔥 {getLikes()}
                    </span>
                  </Grid>
                  <Grid item>
                    <span style={{ color: "silver" }}>
                      <AiFillEye /> {getLikes() + getLikes() + 50}
                    </span>
                    <span style={{ color: "silver", marginLeft: "10px" }}>{getHour()}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div
              style={{
                backgroundColor: "rgb(0, 0, 0, 0.3)",
                width: "30px",
                height: "30px",
                borderRadius: "100%",
                padding: "5px",
                marginLeft: "-90px",
                marginTop: "-40px",
              }}
            >
              <FaShare style={{ color: "white", marginLeft: "4px" }} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PreviewTelegram;
