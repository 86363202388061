export const sanitizeTextQuery = (text) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

/**
 * Convert a string into a valid URL
 * https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
 * @param {*} string
 */
export const slugify = (string) => {
  const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w-]+/g, "") // Remove all non-word characters
      // .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/--+/g, "-") // Replace multiple - with single -
      // .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ); // Trim - from end of text
};

/*
It removes all symbols except:

\p{L} - all letters from any language
\p{N} - numbers
\p{P} - punctuation
\p{Z} - whitespace separators
^$\n - add any symbols you want to keep- in this case jump line
^$|  - add any symbols you want to keep- in this case the character "|"

*/

export const sanitizeInvalidCharacters = (text) => {
  if (!text.trim()) {
    return "";
  }
  return text.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n^$|]/gu, "").trim();
};
