import { uuid_v4 } from "@lib/helpers/uuid_v4";
import axios from "axios";

const AFFILIATES_REPORTS_URL = "/api/v1/marketing-bc/affiliates-report";
const AFFILIATES_REPORTS_SENDED_URL = "/api/v1/marketing-bc/affiliates-report-sended/";
export const useAffiliatesReportsList = async () => {
  let affiliatesReportsData = {
    loadingList: true,
    list: [],
    status: 0,
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(AFFILIATES_REPORTS_URL);
      if (response.status === 200) {
        return { loadingList: false, list: response.data.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return { loadingList: false, list: [] };
      // throw error;
    }
  };
  affiliatesReportsData = await fetchData();
  return { affiliatesReportsData };
};

export const useAffiliatesReportsManagement = async (id) => {
  let affiliateReportData = {
    loading: true,
    affiliateReport: {},
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${AFFILIATES_REPORTS_URL}/${id}`);
      if (response.status === 200) {
        return { loading: false, affiliateReport: response.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return { loading: false, affiliateReport: {} };
      // throw error;
    }
  };
  affiliateReportData = await fetchData();
  return { affiliateReportData };
};

export const useSaveAffiliateReport = async (id, data) => {
  try {
    const response = await axios.put(`${AFFILIATES_REPORTS_URL}/${id}`, data);
    if (response.status === 201) {
      return { id };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const useDeleteAffiliateReport = async (id) => {
  try {
    const response = await axios.delete(`${AFFILIATES_REPORTS_URL}/${id}`);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const useAffiliatesReportsSentList = async (id) => {
  let affiliatesReportsSentData = {
    loadingList: true,
    list: [],
    status: 0,
  };
  const fetchData = async (id) => {
    try {
      const response = await axios.get(`${AFFILIATES_REPORTS_SENDED_URL}/`);
      if (response.status === 200) {
        return {
          loadingList: false,
          list: response.data.data.filter((x) => x.affiliateReportId === id),
          status: response.status,
        };
      }
    } catch (error) {
      console.log(error);
      return { loadingList: false, list: [] };
      // throw error;
    }
  };

  affiliatesReportsSentData = await fetchData(id);
  return { affiliatesReportsSentData };
};

export const useAffiliateReportSentDownload = async (id) => {
  try {
    const response = await axios.get(`${AFFILIATES_REPORTS_SENDED_URL}/${id}/sent/download`);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
