import React, { useRef } from "react";
// import ReactQuill from "react-quill";
// import EditorToolbar, { modules, formats } from "./HtmlTextInputToolbar";
// import AceEditor from "react-ace";
// import "react-quill/dist/quill.snow.css";
import "./styles.css";
// import "ace-builds/src-noconflict/mode-html";
// import "ace-builds/src-noconflict/theme-clouds";
import { Editor } from "@tinymce/tinymce-react";
import useFeatureFlag from "@lib/hooks/useFeatureFlag";
import HTMLEditor from "@molecules/Forms/HTMLEditor";

const api_key = "7znnu6250ukw4pmscyzqstwtmmi0xdpi0thc2gv7pyxwqqpr";
const HtmlTextEditor = ({
  value,
  onChange,
  placeholder,
  id,
  valid = true,
  width = "500px",
  height = "500px",
}) => {
  let { allowed } = useFeatureFlag("html-editor");

  allowed =
    allowed ||
    window.location.pathname.includes("newsletter/third-party") ||
    window.location.pathname.includes("/offers/") ||
    window.location.pathname.includes("key-pickup") ||
    window.location.pathname.includes("cross-selling");

  const editorRef = useRef(null);

  if (allowed === true) {
    return (
      <>
        <Editor
          apiKey={api_key}
          onInit={(evt, editor) => (editorRef.current = editor)}
          onEditorChange={(val, editor) => {
            onChange(val);
          }}
          value={value}
          init={{
            height: height || 500,
            menubar: true,
            valid_children: "+body[style]",
            plugins:
              "autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media code wordcount",
            toolbar:
              "h1 h2 h3 | code | " +
              "undo redo | formatselect | " +
              "bold italic forecolor backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat",
            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            branding: false,
            custom_elements: { [value]: "style" },
            entity_encoding: "raw",
            color_map: [
              "FFAE17",
              "Corportative color",
              "FF0000",
              "Red",
              "FF9900",
              "Orange",
              "FFFF00",
              "Yellow",
              "00FF00",
              "Lime",
              "00FFFF",
              "Aqua",
              "0000FF",
              "Blue",
              "9900FF",
              "Purple",
              "FF00FF",
              "Magenta",
              "000000",
              "Black",
              "993300",
              "Burnt orange",
              "333300",
              "Dark olive",
              "003300",
              "Dark green",
              "003366",
              "Dark azure",
            ],
          }}
        />
      </>
    );
  } else {
    return <HTMLEditor html={value} onChange={onChange} width={width} height={height} />;
  }

  // No borrar esto por si acaso.
  // const [editorHtml, setEditorHtml] = React.useState(true);
  // const handleChange = (nVal) => {
  //   onChange(nVal);
  // };
  // const className = [`text-editor ${valid === true ? "" : "border border-danger"}`];
  // return (
  //   <div className={className}>
  //      <EditorToolbar
  //       id={id}
  //       viewSource={() => {
  //         setEditorHtml(!editorHtml);
  //       }}
  //     />
  //     <div>
  //       <ReactQuill
  //         value={value}
  //         theme="snow"
  //         onChange={(nval, delta, source) => {
  //           if (source === "user") onChange(nval);
  //         }}
  //         placeholder={placeholder || ""}
  //         modules={modules(id)}
  //         formats={formats}
  //       />
  //     </div>
  //     {editorHtml === false && (
  //       <AceEditor
  //         value={value}
  //         mode="html"
  //         theme="clouds"
  //         onChange={handleChange}
  //         name="UNIQUE_ID_OF_DIV"
  //         editorProps={{ $blockScrolling: false }}
  //         setOptions={{ enableLiveAutocompletion: true }}
  //         width={"100%"}
  //         height={"100px"}
  //         wrapEnabled={true}
  //       />
  //     )}
  //   </div>
  // );
};

export default HtmlTextEditor;
