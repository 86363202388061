import styled from "styled-components";
import { Card } from "react-bootstrap";

/**
 * RMR 07'2021 PROCURAD NO USAR ESTE CARD, USAR FXCARD
 */

export const ContainerFenix = styled.div`
  /* padding: 10px 20px; */
  @media (max-width: 576px) {
    padding: 0;
  }
`;

export const UsCardCenterContent = styled(Card)`
  align-items: center;
  padding: 30px;
`;

export const UsCard = styled(Card)`
  align-items: center;
  padding: 30px;
`;

export const UsCardBody = styled(Card.Body)`
  padding: 0;
`;
export const UsCardTitle = styled(Card.Title)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const UsCardTitleEnd = styled(UsCardTitle)`
  justify-content: flex-end;
`;

export const LeftEnable = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
`;

export const SeparatorLine = styled.div`
  background-color: #ffae17;
  width: 20px;
  height: 2px;
  margin: 0 auto 10px;
`;
export const TitleWithSeparator = styled.h5`
  color: #ffae17;
  margin-bottom: 0.8rem;
`;
