import { withHook } from "@lib/helpers/withHook";
import NewsletterThirdPartyManagementState from "./NewsletterThirdPartyManagement.state";
import NewsletterThirdPartyManagementView from "./NewsletterThirdPartyManagement.view";

const NewsletterThirdPartyManagementPage = withHook(
  NewsletterThirdPartyManagementState,
  NewsletterThirdPartyManagementView
);

export default NewsletterThirdPartyManagementPage;
