import React, { Fragment, useState } from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import FenixTableCustomPagination from "@molecules/Tables/FenixTable/FenixTableCustomPagination";
import ErrorModal from "@atoms/Modals/ErrorModal";
import CardTitle from "@atoms/Titles/CardTitle";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import AcceptModal from "@atoms/Modals/AcceptModal";
import Modal from "react-bootstrap/Modal";
import Grid from "@material-ui/core/Grid";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import { TextField, Select, InputLabel, MenuItem} from "@material-ui/core";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { AiOutlineSave } from "react-icons/ai";
import { Alert } from "@material-ui/lab";
import HtmlTextEditor from "@atoms/Inputs/HtmlTextEditor/HtmlTextEditor";
import useFeatureFlag from "@lib/hooks/useFeatureFlag";

const KeyPickupListView = ({
  tableHead,
  searchQuery,
  wordcontrol,
  onCSVUpload,
  headActions,
  specs,
  alertOpen,
  setAlertOpen,
  alertData,
  errorModal,
  onCSVDownload,
  limitTable,
  generateTable,
  actions,
  forceReloadTable,
  confirmationDialog,
  setConfirmationDialog,
  acceptDialog,
  setAcceptDialog,
  modal,
  setModal,
  setErrorModal,
  saveRow,
  onChangeType,
  KEY_PICKUP_TYPES,
  resetModal,
  loading,
}) => {
  const [errorInfo, setErrorInfo] = useState(false);
  const [errorTipInfo, setErrorTipInfo] = useState(null);
  const { allowed } = useFeatureFlag("key-pickup");
  const checkHTML = function (html) {
    const htmlString = `<div>${html}</div>`;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "application/xml");
    return doc.querySelector("parsererror");
  };

  return (
    <Fragment>
      <ContainerFenix>
        <CardTitle
          title={"🗝️ Recogida de llaves 🔑"}
          buttons={[
            <ButtonUploadCSV
              wordcontrol={wordcontrol}
              specs={specs}
              ignoreWordcontrol={false}
              onResultReady={onCSVUpload}
              buttonLabel={"Subir Información"}
            />,
            <ButtonDownloadCSV
              wordcontrol={wordcontrol}
              key={"btDownloadCSV"}
              head={headActions}
              specs={specs}
              table={[]}
              filename={`key_pickup_info`}
              buttonLabel={"Descargar Información"}
              info={[""]}
              generateTable={generateTable}
              onDownloadEnded={onCSVDownload}
            />,
          ]}
        />

        <FenixTableCustomPagination
          loading={loading}
          tableHead={tableHead}
          actions={actions}
          customPaginationFetchUrl={"/api/v1/accommodation-bc/key-pickup/information"}
          searchQueryAdditionalParams={"_fields=id,info,hcode,pickingType,updateBy,@updated_on"}
          withSearch={true}
          searchQueryParam={"hcode[regex]"}
          inputText={searchQuery}
          maxNumberPerPages={limitTable}
          pagination={true}
          isSelectable={false}
          forceReload={forceReloadTable}
          forceLoading={loading}
        />
      </ContainerFenix>
      <ErrorModal
        show={errorModal.open}
        listErrors={errorModal.errors}
        onClose={() => setErrorModal({ open: false, errors: [] })}
      />
      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={() => {
          confirmationDialog.onConfirm();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
        onDeny={() => {
          confirmationDialog.onDenied();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
      <AcceptModal
        show={acceptDialog.open}
        title={acceptDialog.text}
        body={acceptDialog.body}
        onAccept={() => {
          acceptDialog.onAccept();
          setAcceptDialog({ ...acceptDialog, open: false });
        }}
      />
      <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
      <Modal show={modal.open} onHide={resetModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Información de la recogida de llaves {modal.hcode}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <TextField
                disabled={true}
                label="Código de Hotel"
                value={modal.hcode}
                onChange={(nVal) => {
                  const nuForm = { ...modal };
                  nuForm.hcode = nVal.target.value;
                  setModal(nuForm);
                }}
              />
            </Grid>

            <Grid item>
              <InputLabel id="demo-simple-select-helper-label">{"Tipo"}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={false}
                value={KEY_PICKUP_TYPES[modal.pickingType]}
                label={"Tipo"}
                onChange={(e) => {
                  const nuForm = { ...modal };
                  nuForm.pickingType = Object.keys(KEY_PICKUP_TYPES)[e.target.value];
                  setModal(nuForm);
                }}
              >
                {Object.keys(KEY_PICKUP_TYPES).map((key, index) => (
                  <MenuItem key={`${key}`} value={index}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {modal.pickingType === "PICKUP" && (
              <>
                <br />
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity={"info"}>
                      Recuerda que con el tipo PICKUP tendrás que rellenar la información en todos los idiomas
                    </Alert>
                  </Grid>
                  <Grid item>
                    <SelectMarket
                      selection={modal.lang.split("-")[0]}
                      onChangeMarket={(nVal) => {
                        const nuForm = { ...modal };
                        nuForm.lang = `${nVal}-${nVal.toUpperCase()}`;
                        setModal(nuForm);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ color: "grey", fontSize: "12px" }}>
                      Información de la recogida de llaves
                    </span>
                    <HtmlTextEditor
                      style={{ height: "250px", width: "100%" }}
                      name="Texto"
                      rows="10"
                      cols="50"
                      value={modal.info[modal.lang]}
                      onChange={(nVal) => {
                        const nuForm = { ...modal };
                        nuForm.info[modal.lang] = nVal;
                        setModal(nuForm);
                        const checkVal = checkHTML(nVal);
                        setErrorInfo(checkVal !== null);
                        if (checkVal !== null) {
                          setErrorTipInfo(checkVal.children);
                        } else {
                          setErrorTipInfo(null);
                        }
                      }}
                      placeholder={"Informacion"}
                      valid={!errorInfo}
                    ></HtmlTextEditor>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Modal.Body>

        <Modal.Footer>
          <ButtonCustom
            key={"btSend"}
            customcolor="green"
            icon={<AiOutlineSave />}
            // variant={"outlined"}
            onClick={() => {
              const newModal = { ...modal };
              delete newModal.open;
              saveRow(newModal);
            }}
          >
            Guardar
          </ButtonCustom>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default KeyPickupListView;
