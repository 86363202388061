import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "@material-ui/core";

export function TabStructure({ defaultTab, variant, onChange, children }) {
  const [tab, setTab] = useState(defaultTab);

  useEffect(() => {
    setTab(defaultTab);
  }, [defaultTab]);

  const handleChange = (_, newTab) => {
    setTab(newTab);
    if (onChange !== undefined) {
      onChange(newTab);
    }
  };

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant={variant}
        // aria-label="full width tabs example"
      >
        {children.map((child, index) => {
          return <Tab key={`tab${index}`} label={child.props.label} id={`simple-tab-${index}`} />;
        })}
      </Tabs>

      {children.map((child, index) => {
        return <TabItem key={`tabcontent${index}`} {...child.props} value={tab} index={index} />;
      })}
    </>
  );
}

function TabItem(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabStructure.propTypes = {
  defaultTab: PropTypes.number,
  variant: PropTypes.string, // scrollable, fullWidth, standard
  onChange: PropTypes.func,
};
TabStructure.defaultProps = { defaultTab: 0, variant: "fullWidth", onChange: undefined };

export default TabStructure;
