import React, { useState } from "react";
import { FaCheck, FaChevronDown } from "react-icons/fa";
import { Menu, MenuItem } from "@material-ui/core";

function YearSelector({ year, limitPast, limitFuture, onChangeYear }) {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - limitPast;
  const endYear = currentYear + limitFuture;

  const yearList = Array(endYear - startYear + 1)
    .fill()
    .map((_, idx) => startYear + idx);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function onClickYear(newYear) {
    handleClose();
    if (newYear !== year) {
      onChangeYear(newYear);
    }
  }

  return (
    <>
      <span style={{ textAlign: "center", fontSize: "25px" }}>
        {"Año "}
        <b style={{ color: "darkslateblue", cursor: "pointer" }}>
          <span onClick={handleClick}>
            {year} <FaChevronDown />
          </span>
        </b>
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {yearList.map((tYear) => (
          <MenuItem key={`year${tYear}`} onClick={() => onClickYear(tYear)}>
            {tYear === year ? (
              <>
                <FaCheck /> <b style={{ marginLeft: "5px" }}>{tYear}</b>
              </>
            ) : (
              tYear
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

YearSelector.defaultProps = { limitPast: 5, limitFuture: 5 };

export default YearSelector;
