import React from "react";
import { MdCode, MdTextFields } from "react-icons/md";

export const brickModes = [
  { code: "code", name: "Código", icon: <MdCode />, status: true },
  { code: "text", name: "Texto", icon: <MdTextFields />, status: false },
];

export const brickStyles = [
  { code: "web-legacy", name: "Web actual" },
  { code: "web-react", name: "Web nueva" },
];
