import React from "react";
import { Switch, Route, Link } from "react-router-dom";

import RouterAgentPanel from "./agentPanel/RouterAgentPanel";
import RoutesAccounting from "./accounting/RoutesAccounting";
import RoutesProduct from "./producto/RoutesProduct";
import RoutesMarketing from "./marketing/RoutesMarketing";
import RoutesContenido from "./contenido/RoutesContenido";
import RoutesCDN from "./cdn/RoutesCDN";
import RoutesSummary from "./summary/RoutesSummary";
import RoutesTicketing from "./ticketing/RoutesTicketing";
import RouteMoebius from "./moebius/RouteMoebius";
import RoutesAdministration from "./administration/RoutesAdministration";
import RoutesOperations from "./operations/RoutesOperations";
import QueryConfigurations from "./analytics/seo/queryconfigurations/QueryConfigurations";
import AppLegacy, { LegacyDashboard } from "./legacy";

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
);

function Routes() {
  const mainUrl = "/app";

  return (
    <Switch>
      <Route exact path="/app/hello-world">
        <div>Hola Mundo</div>
      </Route>
      {/* <Redirect from="/" to="/app-legacy" /> */}

      <Route exact path="/" component={LegacyDashboard} />
      <Route path="/app-legacy" component={AppLegacy} />

      {/* ADMINISTRATION */}
      <Route path={`${mainUrl}/administration`} component={RoutesAdministration} />

      {/* PRODUCTO */}
      <Route path={`${mainUrl}/product`} component={RoutesProduct} />
      <Route exact path={`${mainUrl}/seo/query-configurations`}>
        <QueryConfigurations />
      </Route>
      <Route path={`${mainUrl}/agentPanel`} component={RouterAgentPanel} />

      {/* OPERACIONES */}
      <Route path={`${mainUrl}/operations`} component={RoutesOperations} />

      {/* CONTABILIDAD */}
      <Route path={`${mainUrl}/accounting`} component={RoutesAccounting} />

      <Route path={`${mainUrl}/summary`} component={RoutesSummary} />
      <Route path={`${mainUrl}/marketing`} component={RoutesMarketing} />
      <Route path={`${mainUrl}/content`} component={RoutesContenido} />
      <Route path={`${mainUrl}/cdn`} component={RoutesCDN} />
      <Route path={`${mainUrl}/ticketing`} component={RoutesTicketing} />
      <Route path={`${mainUrl}/moebius`} component={RouteMoebius} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
