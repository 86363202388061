const now = new Date();

export const getFormEmpty = (id) => {
  return {
    id: id,
    dateoFrom: now.getFullYear() + "-01-01",
    dateoTo: now.getFullYear() + "-12-31",
    provider: "",
    rules: "ALL",
    rappelPercentage: null,
    status: "ACTIVE",
  };
};
