import React from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import {
  TextCounterVouchers,
  TextCounterVouchersInfrigement,
  TextCounterVouchersFerrys,
  TextCounterVouchersManual,
  TextCounterTotal,
  TextCounterVouchersPending,
} from "./VoucherList.styles";
import VoucherManageDetail from "./VoucherManageDetail.view";

const VoucherViewList = ({
  voucherList,
  tableHead,
  actionsVouchersPending,
  actionsVouchersInfrigement,
  actionsVouchersFerrys,
  actionsVouchersTravis,
  MAX_VOUCHERS_VIEW,
  isManageVoucherActive,
  setIsManageVoucherActive,
  voucherToManage,
  setVoucherToManage,
  updateVoucherData,
  isLoading,
  returnToList,
}) => {
  return isLoading === true ? (
    <>Cargando...</>
  ) : (
    <>
      {isManageVoucherActive ? (
        <VoucherManageDetail
          voucherToManage={voucherToManage}
          setVoucherToManage={setVoucherToManage}
          setIsManageVoucherActive={setIsManageVoucherActive}
          updateVoucher={updateVoucherData}
          returnToList={returnToList}
        />
      ) : (
        <>
          <TextCounterVouchers>
            <TextCounterVouchersInfrigement>
              Incumplimientos: {voucherList.infoList.infringements}
            </TextCounterVouchersInfrigement>
            <TextCounterVouchersFerrys>Ferrys: {voucherList.infoList.ferrys}</TextCounterVouchersFerrys>
            <TextCounterVouchersManual>
              Travis: {voucherList.infoList.travisWorking}
            </TextCounterVouchersManual>
            <TextCounterTotal>Total: {voucherList.infoList.total} </TextCounterTotal>
            <TextCounterVouchersPending>
              Resto no incumplidos: {voucherList.infoList.pendings}
            </TextCounterVouchersPending>
          </TextCounterVouchers>

          <CardTitle title={"🔴Incumplimientos"} />
          <FenixTable
            loading={voucherList.loadingList}
            tableHead={tableHead}
            tableContent={voucherList.list.infringements}
            actions={actionsVouchersInfrigement}
            maxNumberPerPages={MAX_VOUCHERS_VIEW}
            pagination={true}
            isSelectable={false}
          />
          <CardTitle title={"🛳️Ferries"} />
          <FenixTable
            loading={voucherList.loadingList}
            tableHead={tableHead}
            tableContent={voucherList.list.ferrys}
            actions={actionsVouchersFerrys}
            maxNumberPerPages={MAX_VOUCHERS_VIEW}
            pagination={true}
            isSelectable={false}
          />

          <CardTitle title={"🆗Resto no incumplidos"} />
          <FenixTable
            loading={voucherList.loadingList}
            tableHead={tableHead}
            tableContent={voucherList.list.pendings}
            actions={actionsVouchersPending}
            maxNumberPerPages={MAX_VOUCHERS_VIEW}
            pagination={true}
            isSelectable={false}
          />
          <CardTitle title={"🫱En manos de Travis🫲"} />
          <FenixTable
            loading={voucherList.loadingList}
            tableHead={tableHead}
            tableContent={voucherList.list.travisWorking}
            actions={actionsVouchersTravis}
            maxNumberPerPages={MAX_VOUCHERS_VIEW}
            pagination={true}
            isSelectable={false}
          />
        </>
      )}
    </>
  );
};

export default VoucherViewList;
