import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import ButtonCopyToClipboard from "@atoms/Buttons/ButtonCopyToClipboard";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const HotelbedsApitudeSearchResults = ({ frozenSearchParams, searchResult }) => {
  const { zone, datei, dateo, adl, chl } = frozenSearchParams;

  // Calculate list as modality
  const modalityList =
    searchResult !== undefined && searchResult.length > 0
      ? searchResult
          .map((activity) => {
            return activity.modalities.map((modality) => ({
              ...modality,
              activityCode: activity.code,
              activityName: activity.name,
            }));
          })
          .reduce((accu, tarray) => [...accu, ...tarray])
      : [];

  // Paxes as string linear
  const paxes = [
    ...Array.from(Array(parseInt(frozenSearchParams.adl, 10))).map(() => 30),
    ...frozenSearchParams.chla,
  ]
    .sort((a, b) => b - a)
    .join("|");

  return (
    <Row className="justify-content-md-center">
      <Col xs={12}>
        <Alert severity="info">
          {"Búsqueda a "}
          <b>{zone.text}</b>
          {", del "}
          <b>{`${datei} al ${dateo}`}</b>
          {", con "}
          <b>{`${adl} adulto(s) y ${chl} niño(s)`}</b>
        </Alert>

        {searchResult !== undefined && searchResult.length === 0 ? (
          <Alert severity="warning">No se han encontrado resultados para esta busqueda.</Alert>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Actividad</b>
                  </TableCell>
                  <TableCell>
                    <b>Modalidad</b>
                  </TableCell>
                  <TableCell>
                    <b>Nombre</b>
                  </TableCell>
                  <TableCell>
                    <b>Detalle</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modalityList.map((row) => (
                  <RowResult key={row.code} row={row} searchParameters={{ ...frozenSearchParams, paxes }} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Col>
    </Row>
  );
};

const RowResult = ({ searchParameters, row }) => {
  const [open, setOpen] = useState(false);
  const classes = makeStyles({ root: { "& > *": { borderBottom: "unset" } } });

  const [modalityCodeNumeral, modalityCodeAlphanumeric] = row.code.split("#");

  async function detailModality() {
    setOpen(!open);
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.activityCode}
          <ButtonCopyToClipboard size="small" text={""} textToCopy={row.activityCode} />
        </TableCell>
        <TableCell component="th" scope="row">
          {modalityCodeAlphanumeric}
          <span style={{ color: "red" }}>#</span>
          {modalityCodeNumeral}
          <ButtonCopyToClipboard
            size="small"
            text={""}
            textToCopy={[modalityCodeAlphanumeric, modalityCodeNumeral].join("#")}
          />
        </TableCell>
        <TableCell>
          <b style={{ color: "grey" }}>{row.activityName}</b>
          <br />
          {row.name}
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={detailModality}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {row.rates[0].rateDetails !== undefined &&
                row.rates[0].rateDetails.map((detail, index) => {
                  return (
                    <React.Fragment key={`detail${index}`}>
                      <DetailView detail={detail} searchParameters={searchParameters} />
                    </React.Fragment>
                  );
                })}
              <QuestionsView row={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

function DetailView({ detail, searchParameters }) {
  return (
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell>
            <b>Idioma & sesiones</b>
          </TableCell>
          <TableCell>
            <b>Duración</b>
            <br />
            <span style={{ fontSize: "10px" }}>(Min-Max)</span>
          </TableCell>
          <TableCell>
            <b>Fechas activo</b>
          </TableCell>
          {detail.paxAmounts.map((pax, index) => (
            <TableCell key={`paxam${index}`}>
              <b>Unitario {pax.paxType}</b>
              <br />
              <span style={{ fontSize: "10px" }}>
                {pax.paxType}-{pax.ageFrom}-{pax.ageTo}{" "}
                <ButtonCopyToClipboard
                  text={""}
                  textToCopy={[pax.paxType, pax.ageFrom, pax.ageTo].join("-")}
                />
              </span>
            </TableCell>
          ))}
          <TableCell>
            <b>Precio total</b>
            <br />
            <span style={{ fontSize: "10px" }}>({searchParameters.paxes})</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            {detail.languages && detail.languages.map((l) => l.code).join(" - ")}
            <br />
            {detail.sessions && detail.sessions.map((s) => s.code).join(" - ")}
          </TableCell>
          <TableCell>
            {detail.minimumDuration.value} {detail.minimumDuration.metric}-{detail.maximumDuration.value}{" "}
            {detail.maximumDuration.metric}
          </TableCell>
          <TableCell>
            {detail.operationDates.map((dt, index) => (
              <p key={`date${index}`}>{`${dt.from} > ${dt.to}`}</p>
            ))}
          </TableCell>
          {detail.paxAmounts.map((pax, index) => (
            <TableCell key={`pax${index}`}>
              <PriceCell hbPriceObject={pax} />
            </TableCell>
          ))}
          <TableCell>
            <PriceCell hbPriceObject={detail.totalAmount} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function QuestionsView({ row }) {
  return (
    row.questions?.length > 0 && (
      <Alert severity="warning">
        <p>
          <b>Preguntas</b>
          <span style={{ marginLeft: "5px", color: "red" }}>
            ¡Atención! Este producto require que el cliente indique ciertos datos adicionales en la reserva.
          </span>
        </p>

        <Table aria-label="collapsible table" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>Codigo</TableCell>
              <TableCell>Texto</TableCell>
              <TableCell>Condiciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row.questions.map((question, index) => (
              <TableRow key={`quiestion${index}`}>
                <TableCell>
                  <b>{question.code}</b>
                </TableCell>
                <TableCell>{question.text}</TableCell>
                <TableCell>
                  {question.required ? <span style={{ color: "red" }}>Requerida</span> : "Opcional"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Alert>
    )
  );
}

const PriceCell = ({ hbPriceObject }) => {
  return (
    <>
      <b>Amount: </b>
      <br />
      {hbPriceObject.amount}€
      <br />
      <b>BoxOfficeAmount: </b>
      <br />
      {hbPriceObject.boxOfficeAmount}€
    </>
  );
};

export default HotelbedsApitudeSearchResults;
