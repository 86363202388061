import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { FiCheck, FiX } from "react-icons/fi";

function ButtonSelected({ size, variant, disabled, onClick, selected, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      icon={selected ? <FiCheck /> : <FiX />}
      customcolor={selected ? "green" : "white"}
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonSelected;
