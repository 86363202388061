import React from "react";
import { Row, Col } from "react-bootstrap";
import { TextField, InputAdornment } from "@material-ui/core";
import { MdFace, MdChildCare } from "react-icons/md";

const OccupancyInputs = ({ adults, childs, childAges, onChange }) => {
  const defaultChildrenAge = 8;

  const onChangeAdults = (nVal) => {
    const rawVal = parseInt(nVal.target.value, 10) || 0;
    const nadl = rawVal > 0 ? rawVal : 0;

    onChange({ adults: nadl, childs, childAges });
  };

  const onChangeChildren = (nVal) => {
    const rawVal = parseInt(nVal.target.value, 10) || 0;
    let nchl = rawVal > 0 ? rawVal : 0;

    if (nchl > 20) {
      nchl = 20;
    }

    const nuChildrenAges = Array.from(Array(parseInt(nchl, 10))).map((_, ia) => {
      const nuCAges = [...childAges];
      if (nuCAges[ia] === undefined) {
        nuCAges[ia] = defaultChildrenAge;
      }
      return nuCAges[ia];
    });

    onChange({ adults, childs: nchl, childAges: nuChildrenAges });
  };

  const onChangeChildreAge = (nVal, index) => {
    const nuChildrenAges = [...childAges];
    nuChildrenAges[index] = parseInt(nVal.target.value, 10);
    onChange({ adults, childs, childAges: nuChildrenAges });
  };

  return (
    <>
      <Row>
        <Col>
          <TextField
            label="Adultos"
            type={"number"}
            value={adults}
            onChange={onChangeAdults}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdFace />
                </InputAdornment>
              ),
            }}
          />
        </Col>
        <Col>
          <TextField
            label="Niños"
            type={"number"}
            value={childs}
            onChange={onChangeChildren}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdChildCare />
                </InputAdornment>
              ),
            }}
          />
        </Col>
      </Row>
      <Row>
        {Array.from(Array(parseInt(childs, 10))).map((_, ia) => {
          return (
            <Col xs={3} key={`edad${ia}`}>
              <TextField
                label={`Edad niño ${ia + 1}`}
                value={childAges[ia]}
                onChange={(nVal) => onChangeChildreAge(nVal, ia)}
                error={childAges[ia] > 17}
                helperText={childAges[ia] > 17 && "Introduce un numero entre 1 y 17"}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default OccupancyInputs;
