import { tlds } from "../shared/domainVariables";

export const wordcontrol = "ChannelManager_Ticketing_Products_v3";
export const filename = "products";
export const wordcontrolContent = "ChannelManager_Ticketing_Products_Content_v1";
export const filenameContent = "ticketing_products_content";
export const wordcontrolRelevantInformation = "ChannelManager_Relevant_Information_v1";
export const filenameRelevantInformation = "relevant_information";

export const contentFields = {
  name: "lang",
  description: "lang",
  priority: "tld",
};

export const csvHead = [
  "Codigo",
  "Codigo Recinto",
  "Nombre (es, aqui solo lectura)",
  "Descripción (es, aqui solo lectura)",
  "Prioridad (es, aqui solo lectura)",
  "Mercados (separadas por coma)",
  "Margen de paquete",
  "¿Cupo activado?",
  "Minimo de cupo",
  "Tipo de paquete (stype)",
  "Personas: ¿Activado?",
  "Personas: Mínimas",
  "Personas: Máximas",
  "Personas: Edades (edadMinima-edadMaxima, ed...)",
];

export const csvSpecs = {
  code: "",
  precinctCode: "",
  name: { "es-ES": "" },
  description: { "es-ES": "" },
  priority: { es: "" },
  markets: "sc",
  packageMarkup: "dz",
  quotaEnable: "b",
  minQuota: "dz",
  packageRules: { type: "" },
  peopleRequirements: { enable: "b", min: "d", max: "d", ages: "sc" },
};

export function contentFieldsToStringArray(theContentFields, parentString = "") {
  return Object.keys(theContentFields).reduce((ac, field) => {
    let newAc = [...ac];
    const name = parentString === "" ? field : [(parentString, field)].join(".");
    if (typeof theContentFields[field] === "string") {
      newAc.push(name);
    } else {
      const r = contentFieldsToStringArray(theContentFields[field], name);
      newAc = [...newAc, ...r];
    }
    return newAc;
  }, []);
}

export const csvHeadContent = [
  "Código de producto",
  `Campo (${contentFieldsToStringArray(contentFields).join("|")})`,
  `Idioma (${tlds.join("|")})`,
  "Contenido",
];
export const csvSpecContent = { code: "", field: "", tld: "", value: "" };

export const csvHeadRelevantInformation = [
  "Código de recinto",
  "Código de producto",
  `Idioma (${tlds.join("|")})`,
  "Channel Provider",
  "Channel Merchant",
  "¿Activado?",
  "Titulo",
  "Contenido",
];
export const csvSpecRelevantInformation = {
  precinctCode: "",
  productCode: "",
  lang: "",
  channel: { provider: "", merchant: "" },
  enable: "b",
  title: "",
  content: "",
};
