import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";

function FxDateTimePicker({ label, dateInitial, onChange }) {
  const format = "YYYY-MM-DD HH:mm";
  const [pickerDateTime, setPickerDateTime] = useState(dateInitial);
  useEffect(() => {
    setPickerDateTime(dateInitial);
  }, [dateInitial]);

  const handleChange = (momentSelectedDateTime) => {
    const nuDate = momentSelectedDateTime.format(format);
    setPickerDateTime(nuDate);
    onChange(nuDate);
  };

  return (
    <DateTimePicker
      label={label}
      value={pickerDateTime}
      onChange={handleChange}
      animateYearScrolling={false}
      allowKeyboardControl={true}
      format={"DD/MM/YYYY HH:mm"}
    />
  );
}

FxDateTimePicker.propTypes = {
  label: PropTypes.string,
  dateInitial: PropTypes.string,
  onChange: PropTypes.func,
};

export default FxDateTimePicker;
