import React from "react";
import axios from "axios";
import ItemSelectorWithQuery from "@molecules/Forms/ItemSelectorWithQuery";

function SelectorTicketProduct({ selected, onChange }) {
  const onSearch = async (query) => {
    let products = [];
    try {
      const { status, data } = await axios.get("/product/axticketing/queryTicketingProducts", {
        params: { key: query },
      });

      if (status === 200) {
        products = data.map((product) => {
          return { ...product, text: [product.code, product.name.es].join(" - ") };
        });
      }
    } catch (e) {
      console.error(e);
    }

    return products;
  };

  return (
    <ItemSelectorWithQuery
      title={"Productos a los que se aplicara este formulario"}
      inputPlaceholder={"Escribe aqui el código o nombre del producto que quieres añadir"}
      selected={selected}
      onSearch={onSearch}
      onChange={onChange}
    />
  );
}

export default SelectorTicketProduct;
