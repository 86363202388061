import styled from "styled-components";
import { Col, Badge } from "react-bootstrap";
import { MdClose } from "react-icons/md";

export const LoadingCenter = styled.div`
  text-align: center;
`;

export const StrechCol = styled(Col)`
  align-items: strech;
`;

// export const LeftEnable = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   font-size: 1.25rem;
//   font-weight: 500;
//   line-height: 1.2;
// `;

// export const SeparatorLine = styled.div`
// background-color: #ffae17;
// width: 20px;
// height: 2px;
// margin: 0 auto 10px;
// }
// `;
// export const TitleWithSeparator = styled.h5`
//   color: #ffae17;
//   margin-bottom: 0.8rem;
// `;

export const RowPickersClose = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  align-items: ceneter;
`;

export const ClosedRow = styled.div`
  text-align: right;
  padding: 10px;
`;

export const ClosedIcon = styled(MdClose)`
  cursor: pointer;
  font-size: 1.5rem;
  color: #666;
  &:hover {
    color: #000;
  }
`;

export const BadgeRound = styled(Badge)`
  border-radius: 20px;
  margin: 10px 0;
  color: #fff;
  background-color: #ffae17;
`;
