/* eslint-disable no-use-before-define */
import useFilterInStorage from "./useFilterInStorage";

function useCalendarVisibility({ precinctCode, loading, productList, key = "" }) {
  const [productVisibility, onChangeVisibility] = useFilterInStorage(
    precinctCode,
    ["visibility", key].join("_"),
    {}
  );

  function changeProductVisibility(productCode) {
    const current = { ...productVisibility };
    current[productCode] = current[productCode] === undefined ? false : !current[productCode];
    onChangeVisibility(current);
  }

  function changeAllProductVisibility(newValue) {
    if (loading) {
      return;
    }
    const nuProductVisibility = productList.reduce(
      (accu, pr) => ({ ...accu, [pr.productCode]: newValue }),
      {}
    );
    onChangeVisibility(nuProductVisibility);
  }

  function isVisible(productCode) {
    if (Object.keys(productVisibility).length === 0) {
      return true;
    }
    if (productVisibility[productCode] === undefined) {
      return true;
    }
    return productVisibility[productCode];
  }

  return {
    productVisibility,
    isVisible,
    changeProductVisibility,
    changeAllProductVisibility,
  };
}

export default useCalendarVisibility;
