import React from "react";
import PrecinctsSection from "@organisms/Ticketing/Precincts/PrecinctsSection";
import ChannelPageTemplate from "./ChannelPageTemplate";

const PrecinctsPage = () => {
  return (
    <ChannelPageTemplate>
      <PrecinctsSection />
    </ChannelPageTemplate>
  );
};

export default PrecinctsPage;
