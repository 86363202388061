import axios from "axios";

const parentPath = "/api/accommodations_private";

export const getCityTaxTemplates = async () => {
  try {
    const { status, data } = await axios.get(`${parentPath}/api/citytaxtemplates`);

    if (status === 200) {
      return data.map((ctt) => ({ ...ctt, onerror: ctt.onerror !== undefined ? ctt.onerror : false }));
    }

    throw new Error("Error while requesting");
  } catch (error) {
    return [];
  }
};

export const getSingleCityTaxTemplate = async (cityTaxCode) => {
  try {
    const { status, data } = await axios.get(`${parentPath}/api/citytaxtemplates/${cityTaxCode}`);

    if (status === 200) {
      return data;
    }

    throw new Error("Error while requesting");
  } catch (error) {
    return [];
  }
};

export const getCityTaxParameters = async () => {
  try {
    const { status, data } = await axios.get(`${parentPath}/api/citytaxparameters`);

    if (status === 200) {
      return data;
    }

    throw new Error("Error while requesting");
  } catch (error) {
    return [];
  }
};

export const postCityTaxTemplate = async (ctTemplate) => {
  const { status, data } = await axios.post(
    `${parentPath}/api/citytaxtemplates/${ctTemplate.code}`,
    ctTemplate
  );

  if (status === 200 && data.ok) {
    return data.data;
  }

  throw new Error("Error while requesting");
};

export const deleteCityTaxTemplate = async (ctTemplate) => {
  const { status, data } = await axios.delete(`${parentPath}/api/citytaxtemplates/${ctTemplate.code}`);

  if (status === 200 && data.ok) {
    return data.data;
  }

  throw new Error("Error while deleting");
};

export const changeEnableTemplate = async (cTemplate, newEnable) => {
  const { status, data } = await axios.post(`${parentPath}/api/citytaxtemplates/${cTemplate.code}/enable`, {
    enable: newEnable,
  });

  if (status === 200 && data.ok) {
    return data.data;
  }

  throw new Error("Error while requesting");
};
