import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { FiPlusSquare } from "react-icons/fi";

function ButtonAdd({ size, variant, disabled, onClick, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="add"
      icon={<FiPlusSquare />}
      customcolor="blue"
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonAdd;
