export const wordcontrol = "ChannelManager_Ticketing_Sessions_v1";
export const filename = "sesiones";

export const contentFields = {
  id: "original",
  productCode: "original",
  precinctCode: "original",
  enable: "original",
  time: "original",
  lang: "original",
  packageMarkup: "original",
};

export const csvHead = [
  "ID autogenerado (vacio para nuevo)",
  "Codigo Producto",
  "Codigo Recinto",
  "Activo",
  `Lang`,
  "Hora (HH:mm)",
  "Margen al empaquetar",
];

export const csvSpecs = {
  id: "",
  productCode: "",
  precinctCode: "",
  enable: "b",
  lang: "",
  time: "",
  packageMarkup: "d",
};
