import React, { Fragment, useState } from "react";
import InputQueryDropdownSearch from "@atoms/Inputs/InputQueryDropdownSearch";
import TagTitle from "@atoms/Titles/TagTitle";
import SectionTitle from "@atoms/Titles/SectionTitle";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import { Row, Col, Card } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { UsCardTitle } from "@atoms/Card/UsCard.styles";
import FxSwitch from "@atoms/Switches/FxSwitch";
import { ChipLabel } from "@atoms/Content/Chips";
import ComponentShowDown from "./DropDownComponent";
import TicketsProducts from "../TicketProducts/TicketProducts";
import OfferBoatComponent from "../OffersBoatComponents/OfferBoatComponent";
import OfferVacationalPackageComponent from "../OfferVacationalPackageComponents/OfferVacationalPackageComponent";
import {
  AlertNoAccommodation,
  CardTickets,
  HeaderTicket,
  ButtonDelete,
  BodyTicket,
  BoxAutoContent,
  TitleListService,
  ChipService,
  ChipDeleteService,
} from "./DropDownConfiServicesIncludedInOffer.styles";
import MealSelector from "../MealSelector/MealSelector";
import RoomOptionSelector from "../RoomOptionSelector/RoomOptionSelector";
import useFeatureFlag from "@lib/hooks/useFeatureFlag";

function DropDownConfiServicesIncludedInOffer({
  handle,
  form,
  ticketsProducts,
  deleteTicketProduct,
  handleFetch,
}) {
  const [handleTags, setHandleTags] = useState({
    accommodations: { title: "Alojamiento", active: true },
    tickets: { title: "Tikets", active: false },
    boats: { title: "Ferrys", active: false },
    vacationalPackage: { title: "Caribe", active: false },
  });

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <UsCardTitle>
            <span>Configuracion De Servicios Incluidos En La Oferta</span>
          </UsCardTitle>
        </Card.Body>
      </Card>
      <Card>
        <TagTitle
          title={"Alojamientos"}
          numberSelected={form.services.accommodations.length}
          active={handleTags.accommodations.active}
          onShowDown={() => {
            const nuObject = { ...handleTags };
            nuObject.accommodations.active = !handleTags.accommodations.active;
            setHandleTags(nuObject);
          }}
        />
        {handleTags.accommodations.active && (
          <Card.Body>
            <ComponentShowDown>
              <InputQueryDropdownSearch
                label={"Buscador de alojamientos"}
                getRequestPath={`/api/private/accommodations?query=%%QUERY%%`}
                optionFormat={(option) => {
                  return (
                    <div>
                      <b>
                        {option.code} | {option.name}
                      </b>
                    </div>
                  );
                }}
                onSelectOption={(nuValue) => {
                  form.services.accommodations.push({ hcode: nuValue.code, name: nuValue.name });
                  handle({ ...form });
                }}
              />
              <div>
                <br />
                <TitleListService>Alojamiento seleccionados</TitleListService>
              </div>

              {form.services.accommodations.length === 0 && (
                <AlertNoAccommodation variant="info">Ningun alojamiento añadido</AlertNoAccommodation>
              )}
              {form.services.accommodations.length > 0 && (
                <>
                  {form.services.accommodations.map((item, index) => (
                    <Card key={index}>
                      <SectionTitle
                        hx={"h4"}
                        title={`${item.hcode} | ${item.name}`}
                        buttons={[
                          <ChipDeleteService
                            key={"bt1"}
                            onClick={() => {
                              form.services.accommodations.splice(index, 1);
                              handle({ ...form });
                            }}
                          />,
                        ]}
                      />
                      <Row>
                        <Col xs={6}>
                          <MealSelector
                            mealPlans={form.services.accommodations[index].mealPlans}
                            onChange={(mealPlans) => {
                              const accommodations = [...form.services.accommodations];
                              accommodations[index].mealPlans = mealPlans;
                              handle({ ...form, services: { ...form.services, accommodations } });
                            }}
                          />
                        </Col>
                        <Col xs={6}>
                          <RoomOptionSelector
                            roomOptions={form.services.accommodations[index].roomOptions}
                            onChange={(roomOptions) => {
                              const accommodations = [...form.services.accommodations];
                              accommodations[index].roomOptions = roomOptions;
                              handle({ ...form, services: { ...form.services, accommodations } });
                            }}
                          />
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </>
              )}
            </ComponentShowDown>
          </Card.Body>
        )}
      </Card>
      <Card>
        {/* FERRYS */}
        <TagTitle
          title={"Ferrys"}
          numberSelected={form.services.boats.length}
          active={handleTags.boats.active}
          onShowDown={() => {
            const nuObject = { ...handleTags };
            nuObject.boats.active = !handleTags.boats.active;
            setHandleTags(nuObject);
          }}
        />
        {handleTags.boats.active && (
          <Card.Body>
            <ComponentShowDown>
              <OfferBoatComponent form={form} handle={handle} />
            </ComponentShowDown>
          </Card.Body>
        )}
      </Card>
      <Card>
        {/* TICKETS */}
        <TagTitle
          title={"Tickets"}
          numberSelected={form.services.tickets.length}
          active={handleTags.tickets.active}
          onShowDown={() => {
            const nuObject = { ...handleTags };
            nuObject.tickets.active = !handleTags.tickets.active;
            setHandleTags(nuObject);
          }}
        />

        {handleTags.tickets.active && (
          <Card.Body>
            <ComponentShowDown>
              <InputQueryDropdownSearch
                label={"Buscador de tickets"}
                getRequestPath={`/api/v1/web-bff-bc/ticketing/mandalorian-legacy/precincts?precinctId[regex]=%%QUERY%%`}
                optionFormat={(option) => {
                  return (
                    <Fragment>
                      <b>
                        {option.precinctId} | {option.name[form.market]}
                      </b>
                    </Fragment>
                  );
                }}
                onSelectOption={async (nuValue) => {
                  const productsToUse = await handleFetch(nuValue, true);
                  form.services.tickets.push({
                    precinctId: nuValue.precinctId,
                    code: nuValue.precinctId,
                    name: nuValue.name.hasOwnProperty(form.market)
                      ? nuValue.name[form.market]
                      : nuValue.name.es,
                    contentFromPrecinct: true,
                    content: [],
                    useAllProducts: true,
                    productsToUse: productsToUse[nuValue.precinctId],
                  });
                  handle({ ...form });
                }}
              />
              <div>
                <br />
                <TitleListService>Tickets seleccionados</TitleListService>
              </div>

              {form.services.tickets.length === 0 && (
                <AlertNoAccommodation variant="info">Ningun ticket añadido</AlertNoAccommodation>
              )}
              {form.services.tickets.length > 0 && (
                <Fragment>
                  <Grid container spacing={3} alignItems="stretch">
                    {form.services.tickets.map((item, index) => {
                      return (
                        <Grid item xs={12} sm={6} key={`Brick-${index}`}>
                          <CardTickets>
                            <HeaderTicket>
                              <div>
                                Codigo: <strong>{item.code}</strong>{" "}
                              </div>
                              <div>
                                Nombre: <strong>{item.name}</strong>
                              </div>

                              <Tooltip title="Delete">
                                <ButtonDelete
                                  onClick={() => {
                                    form.services.tickets.splice(index, 1);
                                    const nuTicketsProducts = { ...ticketsProducts };
                                    delete nuTicketsProducts[item.code];
                                    deleteTicketProduct(nuTicketsProducts);
                                    handle({ ...form });
                                  }}
                                  disableElevation
                                  variant="contained"
                                >
                                  <AiOutlineDelete />
                                </ButtonDelete>
                              </Tooltip>
                            </HeaderTicket>
                            <BodyTicket>
                              <BoxAutoContent>
                                <FxSwitch
                                  value="useAllProducts"
                                  labels={["Usar todos los productos", "Usar todos los productos"]}
                                  status={item.useAllProducts}
                                  onChange={(nuValue) => {
                                    form.services.tickets[index].useAllProducts = nuValue;
                                    // handleTicketProductsFetch(item);
                                    handle({ ...form });
                                  }}
                                />
                              </BoxAutoContent>
                              {item.useAllProducts && (
                                <Alert variant="outlined" severity="info">
                                  Se usaran todos los productos relacionados con este tickets.
                                </Alert>
                              )}
                              {!item.useAllProducts && (
                                <TicketsProducts
                                  products={ticketsProducts[item.code]}
                                  productsToUse={item.productsToUse}
                                  onChange={(nuValue) => {
                                    form.services.tickets[index].productsToUse = nuValue;
                                    handle({ ...form });
                                  }}
                                />
                              )}

                              <BoxAutoContent>
                                <FxSwitch
                                  value="automaticContent"
                                  labels={["Contenido Automático", "Contenido Automático"]}
                                  status={item.contentFromPrecinct}
                                  onChange={(nuValue) => {
                                    form.services.tickets[index].contentFromPrecinct = nuValue;
                                    form.services.tickets[index].content = [];
                                    handle({ ...form });
                                  }}
                                />
                              </BoxAutoContent>
                              {item.contentFromPrecinct && (
                                <Alert variant="outlined" severity="info">
                                  El contenido sera asignado de manera automática.
                                </Alert>
                              )}
                              {!item.contentFromPrecinct && (
                                <Fragment>
                                  <div>
                                    <InputQueryDropdownSearch
                                      label={"Buscador de bricks"}
                                      getRequestPath={`/api/v1/product-bc/content/bricks?type=brick&name[regex]=%%QUERY%%`}
                                      optionFormat={(option) => {
                                        return <Fragment>{`${option.code} | ${option.name}`}</Fragment>;
                                      }}
                                      onSelectOption={(nuValue) => {
                                        form.services.tickets[index].content.push({
                                          code: nuValue.code,
                                          name: nuValue.name,
                                        });
                                        handle({ ...form });
                                      }}
                                    />
                                  </div>
                                  <br />
                                  <div>
                                    {form.services.tickets[index].content.length === 0 && (
                                      <AlertNoAccommodation variant="info">
                                        Ningun brick añadido.
                                      </AlertNoAccommodation>
                                    )}
                                  </div>
                                </Fragment>
                              )}

                              {form.services.tickets[index].content.length > 0 && (
                                <Fragment>
                                  {form.services.tickets[index].content.map((brick, brickIndex) => {
                                    return (
                                      <ChipService key={brickIndex}>
                                        <ChipLabel>
                                          {brick.code} | {brick.name}
                                        </ChipLabel>
                                        <ChipDeleteService
                                          onClick={(e) => {
                                            form.services.tickets[index].content.splice(brickIndex, 1);
                                            handle({ ...form });
                                          }}
                                        />
                                      </ChipService>
                                    );
                                  })}
                                </Fragment>
                              )}
                            </BodyTicket>
                          </CardTickets>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Fragment>
              )}
            </ComponentShowDown>
          </Card.Body>
        )}
      </Card>
      <Card>
        <TagTitle
          title={"Caribe"}
          numberSelected={form.services.vacationalPackage.length}
          active={handleTags.vacationalPackage.active}
          onShowDown={() => {
            const nuObject = { ...handleTags };
            nuObject.vacationalPackage.active = !handleTags.vacationalPackage.active;
            setHandleTags(nuObject);
          }}
        />
        {handleTags.vacationalPackage.active && (
          <Card.Body>
            <ComponentShowDown>
              <OfferVacationalPackageComponent
                form={form}
                handle={(vacationalPackage) => {
                  handle({ ...form, services: { ...form.services, vacationalPackage } });
                }}
              />
            </ComponentShowDown>
          </Card.Body>
        )}
      </Card>
    </Fragment>
  );
}

export default DropDownConfiServicesIncludedInOffer;
