import React, { useState, Children } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BorderDecorated = styled.fieldset`
  display: flex;
  position: relative;
  align-items: ${(props) => (props.alignContent ? props.alignContent : "center")};
  align-content: center;
  padding-right: ${(props) => (props.iconSpace ? props.iconSpace : "1.5rem!important")};
  border-bottom-right-radius: ${(props) => (props.RadiusRightBottom ? props.RadiusRightBottom : "0.28rem")};
  border-top-right-radius: ${(props) => (props.RadiusRightTop ? props.RadiusRightTop : "0.28rem")};
  border-bottom-left-radius: ${(props) => (props.RadiusLeftBottom ? props.RadiusLeftBottom : "0.28rem")};
  border-top-left-radius: ${(props) => (props.RadiusLeftTop ? props.RadiusLeftTop : "0.28rem")};
  padding: ${(props) => (props.paddingReset ? props.paddingReset : "0.75rem")};

  cursor: pointer;
  margin-inline-start: 0;
  margin-inline-end: 0;
  height: ${(props) => (props.height ? props.height : "68px")};
  width: ${(props) => (props.width ? props.width : "")};
  border: ${(props) => (props.open ? "2px solid #ffae17" : "1px solid rgba(0, 0, 0, 0.23)")};
  border: ${(props) => (props.error ? "2px solid #f44336" : "1px solid rgba(0, 0, 0, 0.23)")};
  background-color: ${(props) => (props.BackgroundColor ? BackgroundColor : "#fff")};

  &:hover {
    border: ${(props) => (props.open ? "2px solid #ffae17" : "1px solid #1f1f1f")};
  }
  &:focus {
    border: 2px solid #ffae17 !important;
  }
`;
const BorderDecoratedText = styled.legend`
  width: auto;
  font-size: 12px;
  color: ${(props) => (props.open ? "#ffae17" : "rgba(0, 0, 0, 0.54)")};
  margin-inline-start: 5px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  margin-bottom: -14px;
`;

const HelperText = styled.div`
  position: absolute;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  bottom: 3px;
  font-size: 0.625rem;
  color: ${(props) => (props.error ? "#f44336" : "")};
`;

const FormBorderDecoration = ({
  height,
  width,
  BackgroundColor,
  RadiusRightBottom,
  RadiusRightTop,
  RadiusLeftBottom,
  RadiusLeftTop,
  onClick,
  focus,
  title,
  children,
  alignContent,
  iconSpace,
  paddingReset,
  error = false,
  errorText = "",
}) => {
  return (
    <>
      <BorderDecorated
        error={error}
        height={height}
        width={width}
        BackgroundColor={BackgroundColor}
        RadiusRightTop={RadiusRightTop}
        RadiusRightBottom={RadiusRightBottom}
        RadiusLeftBottom={RadiusLeftBottom}
        RadiusLeftTop={RadiusLeftTop}
        open={focus}
        iconSpace={iconSpace}
        autoComplete="off"
        onClick={onClick}
        alignContent={alignContent}
        paddingReset={paddingReset}
      >
        {title && title !== "" && <BorderDecoratedText open={focus}>{title}</BorderDecoratedText>}
        {/*  <div>Hola quiero centrar esto </div> */}
        {children}
        {error && <HelperText error={error}>{errorText}</HelperText>}
      </BorderDecorated>
    </>
  );
};

/**
 * Unique Id that will be set in
 */
//idDate: PropTypes.string().isRequired(),

FormBorderDecoration.propTypes = {
  /**
   * Title to show in border
   */
  title: PropTypes.string,

  /**
   * height of shape. Default: 54px
   */
  height: PropTypes.string,

  /**
   * align Items of shape. Default: center
   */
  alignContent: PropTypes.string,

  /**
   * Externally focus contro. If focus is true style will change
   */
  focus: PropTypes.bool,

  /**
   * On click in shape
   */
  onClick: PropTypes.func,
};

FormBorderDecoration.defaultProps = {
  title: "",
  focus: false,
  //onClick: () => {},
};

export default FormBorderDecoration;
