import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";

function WeekDaySelector({ selection, onChange }) {
  const [weekdaySelected, setWeekdaySelected] = useState(selection || 1);

  const handleChange = (selectedNow) => {
    onChange(selectedNow.target.value);
    setWeekdaySelected(selectedNow.target.value);
  };

  return (
    <FormControl variant="outlined">
      <InputLabel id="wdslabel">Día de la semana</InputLabel>
      <Select labelId="wdslabel" labelWidth={130} value={weekdaySelected} onChange={handleChange}>
        <MenuItem value={0}>-</MenuItem>
        <MenuItem value={1}>Lunes</MenuItem>
        <MenuItem value={2}>Martes</MenuItem>
        <MenuItem value={3}>Miércoles</MenuItem>
        <MenuItem value={4}>Jueves</MenuItem>
        <MenuItem value={5}>Viernes</MenuItem>
        <MenuItem value={6}>Sábado</MenuItem>
        <MenuItem value={7}>Domingo</MenuItem>
      </Select>
    </FormControl>
  );
}

WeekDaySelector.propTypes = {
  selection: PropTypes.number,
  onChange: PropTypes.func,
};

export default WeekDaySelector;
