import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { FiSend } from "react-icons/fi";

function ButtonSend({ size, variant, disabled, customcolor = "primary", onClick, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="Send"
      icon={<FiSend />}
      customcolor={customcolor}
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonSend;
