import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MdEdit, MdClear } from "react-icons/md";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import SectionTitle from "@atoms/Titles/SectionTitle";
import FenixTable from "@molecules/Tables/FenixTable";
import { getCityTaxTemplates, deleteCityTaxTemplate, changeEnableTemplate } from "./useCityTax";

const CityTax = () => {
  const history = useHistory();
  const [loadingTable, setLoadingTable] = useState(true);
  const [cityTaxList, setCityTaxList] = useState([]);

  async function getAllCityTaxTemplates() {
    setLoadingTable(true);
    const list = await getCityTaxTemplates();
    setCityTaxList(list);
    setLoadingTable(false);
  }

  useEffect(() => {
    getAllCityTaxTemplates();
  }, []);

  const changeEnable = async (row) => {
    return await changeEnableTemplate(row, !row.enable);
  };

  const tableHead = [
    {
      code: "code",
      name: "Codigo",
      type: "string",
    },
    {
      code: "name",
      name: "Nombre",
      type: "string",
    },
    {
      code: "enable",
      name: "Estado",
      type: "boolean",
      func: changeEnable,
    },
    {
      code: "onerror",
      name: "¿Tienen error?",
      type: "boolean",
      text: { y: "Sí", n: "No" },
      color: { y: "red", n: "green" },
    },
  ];

  const newTemplate = () => {
    history.push(`/app/product/accommodations/citytax-editor/NEW`);
  };
  const editTemplate = (row) => {
    history.push(`/app/product/accommodations/citytax-editor/${row.code}`);
  };
  const deleteTemplate = async (row) => {
    if (confirm(`Vas a borrar ${row.code}`)) {
      try {
        await deleteCityTaxTemplate(row);
      } catch (e) {
        alert(e);
      }
      await getAllCityTaxTemplates();
    }
  };

  const actions = [
    {
      name: "Editar",
      func: editTemplate,
      icon: <MdEdit />,
    },
    {
      name: "Borrar",
      func: deleteTemplate,
      icon: <MdClear />,
    },
  ];

  return (
    <>
      <SectionTitle
        title={"Plantillas para aplicar City Tax"}
        buttons={[<ButtonAdd onClick={newTemplate} >Nueva plantilla</ButtonAdd>]}
      />

      <FenixTable loading={loadingTable} tableHead={tableHead} tableContent={cityTaxList} actions={actions} />
    </>
  );
};

export default CityTax;
