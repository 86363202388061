import React from "react";
import { Card } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { FiPlusSquare } from "react-icons/fi";
import moment from "moment";
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import FxDatePicker from "@atoms/Pickers/FxDatePicker";
import FxTimePicker from "@atoms/Pickers/FxTimePicker";
import WeekDaySelector from "@atoms/Selectors/WeekDaySelector";
import Grid from "@material-ui/core/Grid";
import { RowPickersClose, ClosedRow, ClosedIcon, BadgeRound } from "../CrossSellingCampaignsEditor.styles";

function CrossSellingExecutingRules({ form, onChange }) {
  const addNewRule = () => {
    const nuForm = { ...form };
    nuForm.executionRules.configuration.push({
      date: moment().format("YYYY-MM-DD"),
      hour: "00:00",
      month: 1,
      weekDay: 1,
    });
    onChange(nuForm);
  };
  const deleteRule = (index) => {
    const nuForm = { ...form };
    nuForm.executionRules.configuration.splice(index, 1);
    onChange(nuForm);
  };

  return (
    <Card>
      <Card.Header>
        <Grid container justify="space-between" alignItems="baseline">
          <Grid item>
            <span style={{ fontSize: "1.2em" }}>Reglas de ejecución</span>
          </Grid>

          <Grid item>
            <ButtonAdd variant="info" size="sm" onClick={addNewRule} style={{ marginBottom: "-2px" }}>
              <FiPlusSquare /> Añade regla
            </ButtonAdd>
          </Grid>
        </Grid>
      </Card.Header>
      <Card.Body>
        <Grid container>
          <Grid item xs={2}>
            <RadioGroup
              name="executionRules"
              value={form.executionRules.frequency}
              onChange={(event) => {
                const nuForm = { ...form };
                nuForm.executionRules.frequency = event.target.value;
                onChange(nuForm);
              }}
            >
              <FormControlLabel value="oneshoot" control={<Radio color="primary" />} label="Dia puntual" />
              <FormControlLabel value="monthly" control={<Radio color="primary" />} label="Mensual" />
              <FormControlLabel value="weekly" control={<Radio color="primary" />} label="Semanal" />
              <FormControlLabel value="daily" control={<Radio color="primary" />} label="Diario" />
            </RadioGroup>
          </Grid>
          <Grid item xs={10}>
            {form.executionRules.configuration.map((rule, index) => {
              return (
                <RowPickersClose key={`rule_${index}`}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <BadgeRound variant="info">Regla #{index + 1}</BadgeRound>
                    </Grid>

                    <Grid item>
                      <TextField
                        id="time"
                        label="Hora"
                        type="time"
                        defaultValue={rule.hour}
                        sx={{ width: 150 }}
                        onChange={(nuDate) => {
                          const nuForm = { ...form };
                          nuForm.executionRules.configuration[index].hour = nuDate.target.value;
                          onChange(nuForm);
                        }}
                      />
                      {/* <FxTimePicker
                        label={"Hora"}
                        timeInitial={rule.hour}
                        onChange={(nuDate) => {
                          const nuForm = { ...form };
                          let newHour = nuDate.split(":");
                          newHour[0] = nuDate[0].padStart(2, "0");
                          nuForm.executionRules.configuration[index].hour = newHour.join(":");
                          onChange(nuForm);
                        }}
                      /> */}
                    </Grid>

                    {form.executionRules.frequency === "oneshoot" && (
                      <Grid item>
                        <FxDatePicker
                          label={"Fecha"}
                          dateInitial={rule.date}
                          onChange={(nuDate) => {
                            const nuForm = { ...form };
                            nuForm.executionRules.configuration[index].date = nuDate;
                            onChange(nuForm);
                          }}
                        />
                      </Grid>
                    )}

                    {form.executionRules.frequency === "monthly" && (
                      <Grid item>
                        <TextField
                          variant="outlined"
                          type="number"
                          label="Día del mes (1-31)"
                          value={rule.day || 1}
                          onChange={(nVal) => {
                            const nuForm = { ...form };
                            nuForm.executionRules.configuration[index].day = nVal.target.value;
                            onChange(nuForm);
                          }}
                        />
                      </Grid>
                    )}

                    {form.executionRules.frequency === "weekly" && (
                      <Grid item>
                        <WeekDaySelector
                          selection={rule.weekDay}
                          onChange={(nuSelection) => {
                            const nuForm = { ...form };
                            nuForm.executionRules.configuration[index].weekDay = nuSelection;
                            onChange(nuForm);
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item>
                      <ClosedRow onClick={() => deleteRule(index)}>
                        <ClosedIcon />
                      </ClosedRow>
                    </Grid>
                  </Grid>
                </RowPickersClose>
              );
            })}
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
}

export default CrossSellingExecutingRules;
