import React, { useState, useEffect } from "react";
import FenixTableCustomPagination from "@molecules/Tables/FenixTable/FenixTableCustomPagination";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ErrorModal from "@atoms/Modals/ErrorModal";
import CardTitle from "@atoms/Titles/CardTitle";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import { MdClear, MdOutlineModeEditOutline } from "react-icons/md";
import { uuid_v4 } from "@lib/helpers/uuid_v4";
import { useHistory } from "react-router-dom";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import { useJackSparrowRuleList, putRule, deleteRule } from "../../hooks/useJackSparrowRules";

const JackSparrowRulesList = ({}) => {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const limitForQuery = 100;
  const [loading, setLoading] = useState(false);
  const [forceReloadTable, setForceReloadTable] = useState(0);
  const [alertData, setAlertData] = useState({ text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorModal, setErrorModal] = useState({ open: false, errors: [] });
  const [listLength, setListLength] = useState(0);
  const NAME_RULES = {
    base: "Base",
  };

  useEffect(() => {
    async function loadData() {
      await updateInitialList();
    }
    loadData();
  }, []);

  const updateInitialList = async () => {
    const keyData = await useJackSparrowRuleList(0, "", 1);
    setListLength(keyData.total);
  };

  const addNewRule = () => {
    history.push(`${history.location.pathname}/${uuid_v4()}`);
  };

  const actionEdit = async (rowData) => {
    history.push(`${history.location.pathname}/${rowData.id}`);
  };

  const actionDeleteRule = async (rowData) => {
    const deleteConfirm = confirm(`¿Está seguro de eliminar la regla de ${rowData.id}?`);
    if (deleteConfirm) {
      const response = await deleteRule(rowData);
      if (response.ok) {
        setAlertOpen(true);
        setAlertData({ text: `Regla borrada con éxito` });
      } else {
        setErrorModal({
          open: true,
          errors: [`Error al borrar la regla de ${rowData.id}`],
        });
      }
      setForceReloadTable(forceReloadTable + 1);
    }
  };

  const tableHead = [
    // { code: "id", name: "Id", type: "string" },
    { code: "hcode", name: "Codigo Hotel", type: "string" },
    { code: "market", name: "Mercado", type: "market" },
    // { code: "rules.base", name: "PVP Nocturno", type: "boolean" },
    { code: "tags", name: "Reglas Activas", type: "tags" },
  ];

  const actions = [
    { name: "Editar", icon: <MdOutlineModeEditOutline />, func: actionEdit },
    { name: "Borrar", icon: <MdClear />, func: actionDeleteRule },
  ];
  const wordcontrol = `jack-sparrow-rules-v1`;

  const specs = {
    id: "",
    hcode: "",
    market: "",
    rule_base: "b",
    delete: "b",
  };

  const headActions = [
    "Id (Autogenerado)",
    "Código de Hotel",
    "Mercado (es | fr | pt | it)",
    "Regla Base (0 | 1)",
    "Borrar",
  ];

  const generateTable = async () => {
    const allList = [];
    for (let i = 0; i < listLength; i += limitForQuery) {
      const rules = await useJackSparrowRuleList(i, "", limitForQuery);
      const list = rules.list.map((cv) => {
        cv.delete = false;
        cv.rule_base = cv.rules.base;
        return cv;
      });
      allList.push(...list);
    }
    return allList;
  };

  const onCSVDownload = async (csvList) => {
    setAlertOpen(true);
    setAlertData({ text: `Se han descargado todas las reglas.` });
  };

  const checkUniqueElements = (list) => {
    const uniqueElements = new Set(list.map((item) => `${item.market}-${item.hcode}`));
    return uniqueElements.size === list.length;
  };

  const ruleValidation = (point, ln) => {
    const errors = [];
    if (point.hcode === "") {
      errors.push(`${ln}: El código de hotel está vacío.`);
    }
    // console.log(typeof point.rules.base);
    // if (typeof point.rules.base !== "boolean") {
    //   errors.push(`${ln}: La regla base es incorrecta.`);
    // }
    if (point.market === "") {
      errors.push(`${ln}: El Mercado está vacío.`);
    }
    if (!["es", "fr", "pt", "it"].includes(point.market)) {
      errors.push(`${ln}: El Mercado tiene que ser uno de estos: es, fr, pt, it.`);
    }

    return errors;
  };

  const difference = (currentList, newList) => {
    const toInsert = [];
    const toUpdate = [];
    const toDelete = [];
    newList.forEach((itemToCheck) => {
      if (itemToCheck.delete === true) {
        toDelete.push(itemToCheck);
      } else {
        const theStoredOne = currentList.find((current) => itemToCheck["id"] === current["id"]);
        if (theStoredOne !== undefined) {
          const a1 = JSON.parse(JSON.stringify(itemToCheck));
          const a2 = JSON.parse(JSON.stringify(theStoredOne));

          delete a1["delete"];
          delete a2["delete"];
          delete a2["rule_base"];
          delete a2["rule_base"];

          const isEqual = _.isEqual(a1, a2);
          if (!isEqual) {
            toUpdate.push(itemToCheck);
          }
        } else {
          toInsert.push(itemToCheck);
        }
      }
    });
    return { toInsert, toUpdate, toDelete };
  };

  const bulkUploadRules = async (rules) => {
    try {
      const list = await generateTable();
      const { toInsert, toUpdate, toDelete } = difference(list, rules);

      const r = confirm(
        `Se va a proceder a insertar ${toInsert.length}, actualizar ${toUpdate.length} y borrar ${toDelete.length} entradas.`
      );
      if (r) {
        for (const item of [...toInsert, ...toUpdate]) {
          await putRule(item);
        }
        for (const item of toDelete) {
          await deleteRule(item);
        }
      }
      return { saved: [...toInsert, ...toUpdate].length, deleted: toDelete.length, errors: [] };
    } catch (e) {
      return { errors: [e.message] };
    }
  };

  const onCSVUpload = async (csvList) => {
    let mapsList = [];
    let alertMsg = [];

    if (!checkUniqueElements(csvList)) {
      alert("No se puede subir el fichero. Hay elementos duplicados.");
      return;
    }
    try {
      const csvListModified = csvList.map((row) => {
        return {
          id: row.id === "" ? uuid_v4() : row.id,
          hcode: row.hcode,
          market: row.market,
          rules: {
            base: row.rule_base,
          },
          delete: row.delete,
        };
      });
      csvListModified.forEach((point, i) => {
        const ln = `Linea ${i + 3}`;
        const errorsValidation = ruleValidation(point, ln);
        if (errorsValidation.length > 0) {
          alertMsg.push(errorsValidation);
        }
        mapsList.push(point);
      });
    } catch (eX) {
      console.error(eX);
      alert(eX);
      return;
    }

    if (alertMsg.length > 0) {
      alert(alertMsg.join("\n"));
      return;
    }
    setLoading(true);
    alert("Se ha lanzado la tarea de subida. Tardara unos minutos.");
    const response = await bulkUploadRules(mapsList);
    setLoading(false);
    setAlertOpen(true);
    setAlertData({ text: `Se han realizado ${response.saved + response.deleted} operaciones con exito.` });
    setForceReloadTable(forceReloadTable + 1);
  };

  return (
    <>
      <CardTitle
        title={"🏴‍☠️ Reglas Jack Sparrow de Precios 🦜"}
        buttons={[
          <ButtonUploadCSV
            wordcontrol={wordcontrol}
            specs={specs}
            ignoreWordcontrol={false}
            buttonLabel={"Subir"}
            onResultReady={onCSVUpload}
          />,
          <ButtonDownloadCSV
            wordcontrol={wordcontrol}
            key={"btDownloadCSV"}
            head={headActions}
            specs={specs}
            table={[]}
            filename={`Reglas_Jack_Sparrow`}
            buttonLabel={"Descargar"}
            info={[""]}
            generateTable={generateTable}
            onDownloadEnded={onCSVDownload}
          />,
          <ButtonAdd
            key={"buton_add"}
            size="small"
            onClick={() => {
              addNewRule();
            }}
          >
            Añadir Regla
          </ButtonAdd>,
        ]}
      />
      <FenixTableCustomPagination
        loading={loading}
        tableHead={tableHead}
        actions={actions}
        customPaginationFetchUrl={`/api/v1/product-bc/jack-sparrow-rules`}
        searchQueryParam={["hcode[regex]"]}
        withSearch={true}
        inputText={query}
        maxNumberPerPages={15}
        pagination={true}
        isSelectable={false}
        forceReload={forceReloadTable}
        modifyContentTable={(content) => {
          const newContent = content.map((item) => {
            item["tags"] = Object.keys(item.rules).reduce((acc, key) => {
              let cv = item.rules[key];
              if (cv) {
                acc.push(NAME_RULES[key]);
              }
              return acc;
            }, []);
            if (item["tags"].length === 0) item["tags"] = "-";
            return item;
          });

          return newContent;
        }}
      />

      <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
      <ErrorModal
        show={errorModal.open}
        listErrors={errorModal.errors}
        onClose={() => setErrorModal({ open: false, errors: [] })}
      />
    </>
  );
};

export default JackSparrowRulesList;
