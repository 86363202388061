import React, { useContext } from "react";

export const FileManagerContext = React.createContext();

const initialState = {
  path: "/",
  bucket: "hoteles",
  searchInput: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "CHANGE_PATH":
      return { ...state, ...{ path: action.payload } };

    case "CHANGE_SEARCH_INPUT":
      return { ...state, ...{ searchInput: action.payload } };

    case "CHANGE_SEARCH_FOLDER_INPUT":
      return { ...state, ...{ searchFolderInput: action.payload } };

    default:
      return state;
  }
}

export function FileManagerContextProvider(props) {
  if (props.bucket !== undefined) {
    initialState.bucket = props.bucket;
  }
  if (props.path !== undefined) {
    initialState.path = props.path;
  }

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = [state, dispatch];
  return <FileManagerContext.Provider value={value}>{props.children}</FileManagerContext.Provider>;
}

export function usePath() {
  const [state, dispatch] = useContext(FileManagerContext);
  return [
    { path: state.path, bucket: state.bucket },
    (path) => {
      dispatch({ type: "CHANGE_PATH", payload: path });
    },
  ];
}

export function useSearchInput() {
  const [state, dispatch] = useContext(FileManagerContext);
  return [
    { searchInput: state.searchInput, bucket: state.bucket },
    (newSearchInput) => {
      dispatch({ type: "CHANGE_SEARCH_INPUT", payload: newSearchInput });
    },
  ];
}

export function useSearchFolderInput() {
  const [state, dispatch] = useContext(FileManagerContext);
  return [
    { searchFolderInput: state.searchFolderInput, bucket: state.bucket },
    (newSearchInput) => {
      dispatch({ type: "CHANGE_SEARCH_FOLDER_INPUT", payload: newSearchInput });
    },
  ];
}
