import React, { useState, useEffect } from "react";
import { Grid, TextField, FormLabel } from "@material-ui/core";
import ChannelConfigurationSelector from "../shared/ChannelConfigurationSelector/ChannelConfigurationSelector";
import RoomingConfigurationSelector from "../shared/RoomingConfigurationSelector/RoomingConfigurationSelector";
import SelectorDefault from "@molecules/Forms/SelectorDefault";
import FxSwitch from "@atoms/Switches/FxSwitch";

function ProductChannelsForm({ channel, productsListCodes = [], precinctChannels, onChangeProductChannel }) {
  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectorDefault
              title={"Producto"}
              selected={channel.productCode || productsListCodes[0]}
              list={productsListCodes}
              onChange={(nVal) => {
                const nuChannel = { ...channel };
                nuChannel.productCode = nVal.target.value;
                onChangeProductChannel(nuChannel);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectorDefault
              title={"Canal del recincto"}
              selected={channel.channel.provider}
              list={precinctChannels.providers}
              onChange={(nVal) => {
                const nuChannel = { ...channel };
                const channelPrecinct =
                  precinctChannels.values[precinctChannels.providers.indexOf(nVal.target.value)].channel;
                nuChannel.channel = channelPrecinct;
                onChangeProductChannel(nuChannel);
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label="Proveedor" disabled={true} value={channel.channel.provider ?? " "} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth disabled={true} label="Merchant" value={channel.channel.merchant ?? " "} />
          </Grid>

          <Grid item>
            <FormLabel style={{ marginTop: "10px" }} component="legend">
              <div className="titlesection">Heredar configuracion del Recincto</div>
            </FormLabel>
          </Grid>
          <Grid item style={{ marginTop: "5px" }}>
            <FxSwitch
              labels={["", ""]}
              status={channel.configurationInheritedPrecinct}
              onChange={(nVal) => {
                const nuChannel = { ...channel };
                nuChannel.configurationInheritedPrecinct = nVal;
                onChangeProductChannel(nuChannel);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <hr style={{ margin: "0" }} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {!channel.configurationInheritedPrecinct && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <ChannelConfigurationSelector
                      channel={channel}
                      onChange={(nVal) => {
                        onChangeProductChannel(nVal);
                      }}
                    ></ChannelConfigurationSelector>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RoomingConfigurationSelector
              channel={channel}
              onChange={(nVal) => {
                onChangeProductChannel(nVal);
              }}
            ></RoomingConfigurationSelector>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ProductChannelsForm.propTypes = {};

export default ProductChannelsForm;
