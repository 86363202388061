import React, { Fragment, useState, useEffect } from "react";

// Styles
import { ListFileDirectory } from "./FileDirectory.style";

// Components Material-ui
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// Icons Material-ui
import FolderIcon from "@material-ui/icons/Folder";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { getFolders, putFolder, deleteFolder, MoveFolders } from "../../hooks/useFolderFetch";
import { usePath } from "../../FileManagerContext";
import { sanitizeFolder } from "../../shared/sanitize";

import Modal from "react-bootstrap/Modal";
import Paper from "@material-ui/core/Paper";
import { TextField, IconButton,  makeStyles} from "@material-ui/core";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import ButtonCancel from "@atoms/Buttons/ButtonCancel";
import { AiOutlineFolderAdd } from "react-icons/ai";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { MdClear } from "react-icons/md";
import { FiMove, FiXSquare } from "react-icons/fi";

import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";

import FolderSearcher from "../FolderSearcher/FolderSearcher";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiListItemIcon-root": {
      minWidth: "10px",
    },
    "& .MuiSvgIcon-root": {
      width: "0.75rem",
      height: "0.75rem",
    },
    "& .MuiListItem-gutters ": {
      padding: "8px",
    },
  },
}));

export const FileDirectory = ({}) => {
  const [{ path, bucket }, setPath] = usePath();
  const [folders, setFolders] = useState({ loading: true, data: [], path: null, error: null });
  const [modalCreate, setModalCreate] = useState({ open: false });
  const [modalMove, setModalMove] = useState({ open: false, newPath: "" });
  const [textNewFolder, setTextNewFolder] = useState("");
  const [textMoveFolder, setTextMoveFolder] = useState("");
  const [alertData, setAlertData] = useState({ text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [selectedFolderPath, setSelectedFolderPath] = useState("");
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });
  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [stateContextMenu, setStateContextMenu] = useState(initialState);

  const handleCloseModalMove = () => setModalMove({ open: false });
  const handleShowModalMove = () => setModalMove({ open: true });

  useEffect(() => {
    setFolders({ ...folders, loading: true });
    getFoldersData();
  }, [path]);

  const getFoldersData = () => {
    getFolders(bucket, `pathParent=${path}`).then((data) => {
      if (data.error !== null) {
        setErrors({
          show: true,
          list: [`Error al obtener carpetas`, JSON.stringify(data.error)],
        });
      }
      setFolders({ ...data });
    });
  };

  const onClickFolder = async (folder) => {
    setFolders({ ...folders, loading: true });
    setPath(folder);
  };

  const getParentPath = () => {
    let pathParent = path.split("/");
    pathParent.pop();
    if (pathParent.length === 1) {
      pathParent = "/";
    } else {
      pathParent = pathParent.join("/");
    }
    return pathParent;
  };

  const handleClickContextMenu = (event, folderPath) => {
    event.preventDefault();
    setSelectedFolderPath(folderPath);
    setStateContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseContextMenu = () => {
    setStateContextMenu(initialState);
  };

  const handleDeleteContextMenu = () => {
    handleCloseContextMenu();
    setConfirmationDialog({
      open: true,
      text: `¿Estas seguro que borrar la carpeta ${selectedFolderPath}?`,
      body:
        "Se borrarán todas las carpetas y archivos que haya dentro. \nEsta acción no puede revertirse. ¿Seguro que desea borrar permanentemente esta carpeta?",
      onConfirm: () => {
        onClickDelete(selectedFolderPath);
      },
    });
  };

  const handleMoveContextMenu = (evt) => {
    handleCloseContextMenu();
    setConfirmationDialog({
      open: true,
      text: `¿Estas seguro que borrar la carpeta ${selectedFolderPath}?`,
      body:
        "Se moveran todas las carpetas y archivos que haya dentro. \nEsta acción no puede revertirse. ¿Seguro que desea mover permanentemente esta carpeta?",
      onConfirm: () => {
        setConfirmationDialog({
          open: false,
        });
        onClickMove(selectedFolderPath);
      },
    });
  };
  const onClickReturn = async () => {
    onClickFolder(getParentPath());
  };

  const onChangeTextFieldModal = (text) => {
    setTextNewFolder(sanitizeFolder(text));
  };

  const onClickSave = async () => {
    if (textNewFolder === "") {
      setErrors({
        show: true,
        list: [`El nombre la carpeta no puede estar vacío`],
      });
    } else if (folderExists()) {
      setErrors({
        show: true,
        list: [`Ya existe una carpeta con este nombre en este directorio`],
      });
    } else {
      setModalCreate({ open: false });
      setFolders({ ...folders, loading: true });
      const nuPath = path !== "/" ? `${path}/${textNewFolder}` : `/${textNewFolder}`;
      const response = await putFolder({ path: nuPath, pathParent: path }, bucket);
      if (response.status !== 201) {
        setErrors({
          show: true,
          list: [`Error al crear la carpeta  ${nuPath}`, JSON.stringify(response.error)],
        });
      } else {
        setAlertOpen(true);
        setAlertData({ text: `Carpeta creada con éxito` });
        folders.data.push({
          path: nuPath,
          pathParent: path,
          bucket: bucket,
        });
      }
      setPath(nuPath);
      setTextNewFolder("");
      setFolders({ ...folders });
      setModalCreate({ open: false });
    }
  };

  const folderExists = () => {
    const newFolderPath = path + textNewFolder;
    const previousFolderInPath = folders.data.find((folder) => folder.path === newFolderPath);
    return previousFolderInPath !== undefined;
  };

  const onClickMove = async (currentPath) => {
    handleShowModalMove();
  };

  const onClickDelete = async (currentPath) => {
    setFolders({ ...folders, loading: true });
    const response = await deleteFolder(bucket, currentPath);
    if (response.status !== 204) {
      setErrors({
        show: true,
        list: [`Error al Borrar la carpeta  ${currentPath}`, JSON.stringify(response.error)],
      });
    } else {
      setAlertOpen(true);
      setAlertData({ text: `Carpeta borrada con éxito` });
      getFoldersData();
    }
    setFolders({ ...folders });
    setConfirmationDialog({ ...confirmationDialog, open: false });
  };

  const modalCreateBody = () => {
    const classes = useStyles();
    return (
      <Modal
        show={modalCreate.open}
        onHide={() => {
          setModalCreate({ open: false, currentPath: "" });
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Crear nueva carpeta</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TextField
            id="outlined-basic"
            label="Nombre de la nueva carpeta"
            variant="outlined"
            value={textNewFolder}
            onChange={(evt) => {
              onChangeTextFieldModal(evt.target.value);
            }}
          />
          <div>La carpeta se creara en la siguiente ruta: {path}</div>
          <div>Ruta completa: {path === "/" ? "/" + textNewFolder : path + "/" + textNewFolder}</div>
        </Modal.Body>

        <Modal.Footer>
          <div style={{display:"inline-block", marginRight: "5px" }}>
            <ButtonCancel
              size="small"
              onClick={() => {
                setModalCreate({ open: false });
              }}
            >
              Cancelar
            </ButtonCancel>
          </div>
          <ButtonCustom
            color="primary"
            size="small"
            icon={<AiOutlineFolderAdd />}
            onClick={onClickSave}
          >
            Crear
          </ButtonCustom>
        </Modal.Footer>
      </Modal>
    );
  };

  const onExecuteSearchFolder = (newPath) => {
    // MoveFolders(selectedFolderPath, newPath, bucket);
    setTextMoveFolder(newPath);
    // setPath(newPath);
  };

  const onClickConfirmMove = async () => {
    await MoveFolders(selectedFolderPath, textMoveFolder, bucket);
    handleCloseModalMove();
    setPath(textMoveFolder);
    resetMove();
  };

  const resetMove = () => {
    setTextMoveFolder("");
    setSelectedFolderPath("");
  };

  const modalMoveBody = () => {
    const classes = useStyles();
    return (
      <Modal
        show={modalMove.open}
        onHide={() => {
          handleCloseModalMove();
          resetMove();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Mover carpeta</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FolderSearcher onExecuteSearch={onExecuteSearchFolder} />
          {textMoveFolder === "" ? (
            <Alert severity="warning">No has seleccionado ninguna carpeta de destino.</Alert>
          ) : (
            <Fragment>
              <Alert severity="info">
                El contenido de <b>{selectedFolderPath}</b> será movido a la carpeta <b>{textMoveFolder}</b>.
              </Alert>
            </Fragment>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div style={{display:"inline-block", marginRight: "5px" }}>
            <ButtonCancel
              size="small"
              onClick={() => {
                setModalMove({ open: false });
              }}
            >
              Cancelar
            </ButtonCancel>
          </div>
          <ButtonCustom
            color="primary"
            size="small"
            icon={<AiOutlineFolderAdd />}
            onClick={() => {
              if (textMoveFolder !== "") {
                onClickConfirmMove();
              }
            }}
          >
            Mover
          </ButtonCustom>
        </Modal.Footer>
      </Modal>
    );
  };
  const classes = useStyles();

  return (
    <Paper elevation={3} style={{ overflow: "hidden" }}>
      <ListFileDirectory>
        <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
        <ErrorModal
          show={errors.show}
          listErrors={errors.list}
          onClose={() => {
            setErrors({ show: false, list: [] });
          }}
        />
        <ConfirmationModal
          show={confirmationDialog.open}
          title={confirmationDialog.text}
          body={confirmationDialog.body}
          onConfirm={confirmationDialog.onConfirm}
          onDeny={() => {
            setConfirmationDialog({ ...confirmationDialog, open: false });
          }}
        />

        {modalCreateBody()}
        {modalMoveBody()}

        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
          <IconButton disabled={path === "/"} color="secondary" size="small" onClick={onClickReturn}>
            <KeyboardReturnIcon />
          </IconButton>

          <ButtonCustom
            size="small"
            color="secondary"
            icon={<AiOutlineFolderAdd />}
            onClick={() => {
              setModalCreate({ open: true });
            }}
          >
            Crear carpeta
          </ButtonCustom>
        </div>
        {folders.loading ? (
          <CenterLoaderCircle role="status" />
        ) : (
          <Fragment>
            <Menu
              keepMounted
              open={stateContextMenu.mouseY !== null}
              onClose={handleCloseContextMenu}
              anchorReference="anchorPosition"
              anchorPosition={
                stateContextMenu.mouseY !== null && stateContextMenu.mouseX !== null
                  ? { top: stateContextMenu.mouseY, left: stateContextMenu.mouseX }
                  : undefined
              }
            >
              <MenuItem onClick={handleMoveContextMenu}>
                <ListItemIcon>
                  <FiMove />
                </ListItemIcon>
                Mover carpeta "{selectedFolderPath.split("/").pop()}"
              </MenuItem>
              <MenuItem onClick={handleDeleteContextMenu}>
                <ListItemIcon>
                  <MdClear />
                </ListItemIcon>
                Borrar carpeta "{selectedFolderPath.split("/").pop()}"
              </MenuItem>
            </Menu>
            {folders.data.map((folder, indexFolder) => {
              return (
                <ListItem
                  key={indexFolder}
                  className={classes.root}
                  button
                  onClick={() => {
                    onClickFolder(folder.path);
                  }}
                >
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>

                  <ListItemText
                    primary={folder.path.split("/").pop()}
                    onContextMenu={(e) => handleClickContextMenu(e, folder.path)}
                  />
                </ListItem>
              );
            })}
          </Fragment>
        )}
      </ListFileDirectory>
    </Paper>
  );
};
