import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { MdEdit, MdControlPointDuplicate, MdClear } from "react-icons/md";
import { FiPlusSquare } from "react-icons/fi";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import CardTitle from "@atoms/Titles/CardTitle";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import FenixTableCustomPagination from "@molecules/Tables/FenixTable/FenixTableCustomPagination";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ErrorModal from "@atoms/Modals/ErrorModal";
import {
  useCrossSellingCampaigns,
  enableCrossSellingCampaign,
  generateNewCampaignCode,
  saveCrossSellingCampaign,
  deleteCrossSellingCampaign,
} from "./hooks/useCrossSellingFetch";
import ModalNextExecutions from "./ModalNextExecutions";
import ModalExecuteNow from "./ModalExecuteNow";

const CrossSellingCampaigns = () => {
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const { loadingCampaigns, campaigns } = useCrossSellingCampaigns();
  const [forceReloadTable, setForceReloadTable] = useState(0);
  const [openNextModal, setOpenNextModal] = useState(false);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [nextCampaign, setNextCampaign] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [errorModal, setErrorModal] = useState({ open: false, errors: [] });

  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };

  const enableCampaign = async (rowData) => {
    const data = { ...rowData };
    data.enable = !data.enable;
    const res = await enableCrossSellingCampaign(data);
    setForceReloadTable(forceReloadTable + 1);
    return res;
  };

  const editTemplate = (rowData) => {
    history.push(`${history.location.pathname}/${rowData.id}`);
  };

  const duplicate = async (rowData) => {
    const newData = { ...rowData };
    newData.id = generateNewCampaignCode();
    newData.name = `DUPLICADA - ${rowData.name}`;
    newData.enable = false;
    await saveCrossSellingCampaign(newData);
    history.push(`${history.location.pathname}/${newData.id}?duplicate=true`);
  };

  const actionDelete = async (rowData) => {
    const deleteConfirm = confirm(`¿Está seguro de eliminar la camapaña ${rowData.id}?`);
    if (deleteConfirm) {
      const response = await deleteCrossSellingCampaign(rowData);
      if (response.ok) {
        handleAlertSnackBar(true, "Campaña guardada correctamente.");
      } else {
        setErrorModal({
          open: true,
          errors: [`Error al borrar la camapaña de ${rowData.code}`],
        });
      }
      setForceReloadTable(forceReloadTable + 1);
    }
  };

  const head = ["Codigo", "Estado", "Nombre", "Mercado", "Habilitado?", "Tipo", "Tickets", "Validez", "SQL"];

  const specs = {
    code: "",
    name: "",
    market: "",
    enable: "b",
    type: "",
    ticketsArray: "sc",
    validDatesRanges: "sc",
    content: { sql: "" },
  };
  const actions = [
    { name: "Editar", icon: <MdEdit />, func: editTemplate },
    { name: "Duplicar", icon: <MdControlPointDuplicate />, func: duplicate },
    { name: "Borrar", icon: <MdClear />, func: actionDelete },
    // { name: "Próximas ejecuciones", icon: <GoCalendar />, func: nextExecutions },
    // { name: "Recibir simulacion de campaña", icon: <MdSend />, func: sendCampaignNow },
  ];
  const tableHead = [
    { code: "id", name: "Código", type: "string" },
    // {
    //   code: "status",
    //   name: "Estado",
    //   type: "string",
    //   coloured: { ERROR: "red", WARNING: "#fd7e14" },
    //   filterable: true,
    // }, // OK WARNING ERROR
    { code: "name", name: "Nombre", type: "string" },
    { code: "market", name: "Mercado", type: "market", filterable: true },
    {
      code: "enable",
      name: "Disponibilidad",
      type: "boolean",
      text: { y: "Habilitado", n: "Deshabilitado" },
      func: async (row) => {
        return enableCampaign(row);
      },
    },
  ];

  const addNewCampaign = () => {
    const uniqueValue = generateNewCampaignCode();
    history.push(`${history.location.pathname}/${uniqueValue}`);
  };

  return (
    <Fragment>
      <CardTitle
        title={"Campañas de cross-selling"}
        buttons={[
          <ButtonDownloadCSV
            key={"bt1"}
            head={head}
            specs={specs}
            table={campaigns}
            filename={"crossselling_campaings"}
            buttonLabel={"Descargar"}
          />,

          <ButtonCustom customcolor={"blue"} key={"bt3"} icon={<FiPlusSquare />} onClick={addNewCampaign}>
            Añadir
          </ButtonCustom>,
        ]}
      />
      <FenixTableCustomPagination
        loading={false}
        tableHead={tableHead}
        actions={actions}
        customPaginationFetchUrl={`/api/v1/marketing-bc/cross-selling/campaigns`}
        withSearch={true}
        maxNumberPerPages={15}
        pagination={true}
        isSelectable={false}
        forceReload={forceReloadTable}
      />
      {/* Executions Modal */}
      {openNextModal && (
        <ModalNextExecutions
          campaign={nextCampaign}
          onClose={() => {
            setOpenNextModal(false);
            setNextCampaign(null);
          }}
        />
      )}
      {/* Execute now Modal */}
      {openSendModal && (
        <ModalExecuteNow
          campaign={nextCampaign}
          onClose={() => {
            setOpenSendModal(false);
            setNextCampaign(null);
          }}
        />
      )}

      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} />
      <ErrorModal
        show={errorModal.open}
        listErrors={errorModal.errors}
        onClose={() => setErrorModal({ open: false, errors: [] })}
      />
    </Fragment>
  );
};

export default CrossSellingCampaigns;
