import React, { Fragment } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { Card, Alert } from "react-bootstrap";
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import { FiPlusSquare } from "react-icons/fi";
import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import { RowPickersClose, ClosedRow, ClosedIcon } from "../CrossSellingCampaignsEditor.styles";

function CrossSellingDateRangeValidation({ form, onChange }) {
  const addNewValidityDates = () => {
    const nuForm = { ...form };
    nuForm.validDatesRanges.push({
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
    });
    onChange(nuForm);
  };
  return (
    <Card>
      <Card.Header>
        {/* <UsCardTitle> */}
        <Grid container justify="space-between" alignItems="baseline">
          <Grid item>
            <span style={{ fontSize: "1.2em" }}>Rangos de validez</span>
          </Grid>
          <Grid item>
            <ButtonAdd
              style={{ marginBottom: "-2px" }}
              variant="info"
              size="sm"
              onClick={addNewValidityDates}
            >
              <FiPlusSquare /> Añade nuevo rango
            </ButtonAdd>
          </Grid>
        </Grid>

        {/* </UsCardTitle> */}
      </Card.Header>
      <Card.Body>
        {form.validDatesRanges.length > 0 ? (
          <Fragment>
            {form.validDatesRanges.map((adate, index) => {
              return (
                <RowPickersClose key={`validDate_${index}`}>
                  <Grid container justify="space-between">
                    <Grid item xs={10}>
                      <FxDatePickersFromTo
                        labelFrom={"Desde"}
                        dateFromInitial={adate.from}
                        labelTo={"Hasta"}
                        dateToInitial={adate.to}
                        onChange={({ datei, dateo }) => {
                          const nuForm = { ...form };
                          nuForm.validDatesRanges[index].from = datei;
                          nuForm.validDatesRanges[index].to = dateo;
                          onChange(nuForm);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <ClosedRow
                        onClick={() => {
                          const nuForm = { ...form };
                          nuForm.validDatesRanges.splice(index, 1);
                          onChange(nuForm);
                        }}
                      >
                        <ClosedIcon />
                      </ClosedRow>
                    </Grid>
                  </Grid>
                </RowPickersClose>
              );
            })}
          </Fragment>
        ) : (
          <Alert variant="info">No hay rangos de valiez configurados</Alert>
        )}
      </Card.Body>
    </Card>
  );
}

export default CrossSellingDateRangeValidation;
