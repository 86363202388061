import React from "react";
import ListEditable from "@atoms/Lists/ListEditable";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { Card } from "react-bootstrap";
import { FaqIcon } from "../pages/ActionEditor/ActionsListEditor.styles";

function Faqs(form, onChange) {
  return (
    <Card>
      <Card.Body>
        <FaqIcon>
          <QuestionAnswerIcon />
        </FaqIcon>
        <ListEditable
          title={"Etiquetas para FAQ "}
          info={form.faqIds || []}
          form={form}
          typeInfo={"FAQs"}
          onChangeList={onChange}
        />
      </Card.Body>
    </Card>
  );
}

export default Faqs;
