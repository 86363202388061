import React from "react";
import { Button } from "@material-ui/core";

// We should'nt use this component directly: use a wrapp-button component instead

function ButtonCustom({
  icon = "",
  color = "default", // primary|secondary|error|warning|info|success
  variant = "contained", // container|outlined|text
  ariaLabel = "",
  size = "large", // small|medium|large
  customcolor = "",
  onClick,
  disabled,
  children,
  fullWidth = false,
  height,
}) {
  const className = [`btn-override`];
  if (variant === "outlined") {
    className.push(`${customcolor}-outlined`);
  } else if (variant === "text") {
    className.push(`${customcolor}-text`);
  } else {
    className.push(customcolor);
  }

  // Square class if no children and have icon
  if (children === undefined && icon !== undefined) {
    className.push("square");
  }

  return (
    <>
      <Button
        variant={variant}
        size={size}
        color={color}
        aria-label={ariaLabel}
        disableElevation // Para quitar la sombra!
        startIcon={icon}
        className={className.join(" ")}
        onClick={onClick}
        disabled={disabled}
        fullWidth={fullWidth}
        height={height}
      >
        {children}
      </Button>
      <style jsx="true">
        {`
          .btn-override {
            padding: 0.5em !important;
            line-height: 1em !important;
            text-transform: none !important;
            font-family: Montserrat, sans-serif !important;
          }
          .btn-override .MuiButton-startIcon {
            margin-left: 0 !important;
          }

          .btn-override:disabled {
            color: #fff;
            opacity: 0.5;
          }

          .square {
            min-width: min-content !important;
          }

          .square .MuiButton-startIcon {
            margin: 0 !important;
          }

          /*red*/
          .tomato {
            background-color: tomato !important;
            color: #fff;
            border: transparent !important;
          }
          .tomato:hover {
            background-color: #e65035 !important;
          }
          .tomato-outlined {
            color: #e65035 !important;
            border: 1px solid rgb(228, 80, 54, 0.5) !important;
            background-color: transparent !important;
          }
          .tomato-outlined:hover {
            color: #e65035 !important;
            border: 1px solid rgb(228, 80, 54, 1) !important;
            background-color: rgb(255, 99, 71, 0.1) !important;
          }

          /* blue */
          .blue {
            background-color: #006699 !important;
            color: #fff;
            border: transparent !important;
          }
          .blue:hover {
            background-color: #014e74 !important;
          }

          .blue-outlined {
            color: #014e74 !important;
            border: 1px solid rgb(1, 78, 116, 0.5) !important;
            background-color: transparent !important;
          }
          .blue-outlined:hover {
            color: #014e74 !important;
            border: 1px solid rgb(1, 78, 116, 1) !important;
            background-color: rgb(0, 102, 153, 0.1) !important;
          }

          /* lightblue */
          .lightblue {
            background-color: #17a2b8 !important;
            color: #fff;
            border: transparent !important;
          }
          .lightblue:hover {
            background-color: #138496 !important;
          }
          .lightblue-outlined {
            color: #138496 !important;
            border: 1px solid rgb(19, 132, 150, 0.5) !important;
            background-color: transparent !important;
          }
          .lightblue-outlined:hover {
            color: #014e74 !important;
            border: 1px solid rgb(19, 132, 150, 1) !important;
            background-color: rgb(0, 102, 153, 0.1) !important;
          }

           {
            /* Green */
          }
          .red {
            background-color: #e65035 !important;
            color: #fff;
            border: transparent !important;
          }
          .red:hover {
            background-color: #cf4a32 !important;
          }
          .red-outlined {
            color: #e65035 !important;
            border: 1px solid rgb(228, 80, 54, 0.5) !important;
            background-color: transparent !important;
          }
          .red-outlined:hover {
            color: #e65035 !important;
            border: 1px solid rgb(228, 80, 54, 1) !important;
            background-color: rgb(255, 99, 71, 0.1) !important;
          }
          .green {
            background-color: #1bc5bd !important;
            color: #fff;
            border: transparent !important;
          }
          .green:hover {
            background-color: #138496 !important;
          }
          .green-outlined {
            color: #138496 !important;
            border: 1px solid rgb(23, 158, 152, 0.5) !important;
            background-color: transparent !important;
          }
          .green-outlined:hover {
            color: #014e74 !important;
            border: 1px solid rgb(23, 158, 152, 1) !important;
            background-color: rgb(27, 197, 189, 0.1) !important;
          }

          .telegram {
            background-color: #1d98dc !important;
            color: #fff;
            border: transparent !important;
          }

          .telegram:hover {
            background-color: #1c94d6 !important;
          }

          .telegram-outlined {
            color: #1d98dc !important;
            border: 1px solid rgb(28, 148, 214, 0.5) !important;
            background-color: transparent !important;
          }

          .telegram-outlined:hover {
            color: #1c94d6 !important;
            border: 1px solid rgb(28, 148, 214, 1) !important;
            background-color: rgb(28, 148, 214, 0.1) !important;
          }

          .facebook {
            background-color: #3b5998 !important;
            color: #fff;
            border: transparent !important;
          }

          .facebook:hover {
            background-color: #324d85 !important;
          }

          .facebook-outlined {
            color: #3b5998 !important;
            border: 1px solid rgb(50, 77, 133, 0.5) !important;
            background-color: transparent !important;
          }

          .facebook-outlined:hover {
            color: #324d85 !important;
            border: 1px solid rgb(50, 77, 133, 1) !important;
            background-color: rgb(50, 77, 133, 0.1) !important;
          }

          .instagram {
            background: linear-gradient(
              to bottom right,
              #405de6,
              #5b51d8,
              #c13584,
              #e1306c,
              #fd1d1d,
              #f56040,
              #f77737,
              #fcaf45,
              #ffdc80
            ) !important;
            color: #fff;
            border: transparent !important;
          }

          .instagram:hover {
            background: linear-gradient(
              to bottom right,
              #344cbb,
              #473fa9,
              #992968,
              #ac2251,
              #c31616,
              #c64e34,
              #c65d29,
              #cd8f3a,
              #d3b569
            ) !important;
          }

          .instagram-outlined {
            color: #e1306c !important;
            border: 1px solid rgb(193, 53, 132, 0.5) !important;
            background-color: transparent !important;
          }

          .instagram-outlined:hover {
            color: #e1306c !important;
            border: 1px solid rgb(193, 53, 132, 0.5) !important;
            background-color: rgb(193, 53, 132, 0.1) !important;
          }
        `}
      </style>
    </>
  );
}

export default ButtonCustom;
