import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DialogContentText } from "@material-ui/core";
import Modal from "./Modal";

function ErrorModal({ show, listErrors, onClose }) {
  const [errors, setErrors] = useState(listErrors || []);

  useEffect(() => {
    setErrors(listErrors);
  }, [show, listErrors]);

  return (
    <Modal show={show} onClose={onClose}>
      <>
        <DialogContentText>Se han encontrado los siguientes errores:</DialogContentText>
        <ul>
          {errors.map((err, index) => (
            <li key={index}>{err}</li>
          ))}
        </ul>
      </>
    </Modal>
  );
}

ErrorModal.propTypes = {
  show: PropTypes.bool,
  listErrors: PropTypes.array,
  onClose: PropTypes.func,
};

export default ErrorModal;
