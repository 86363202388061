import React from "react";
import SuppliersHyperguest from "@organisms/Product/Accommodations/SuppliersHyperguest/SuppliersHyperguest";

//Styles
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

const SuppliersHyperguestPage = () => {
  return (
    <ContainerFenix>
      <SuppliersHyperguest />
    </ContainerFenix>
  );
};

export default SuppliersHyperguestPage;
