import axios from "axios";

const prefix = "/api/us_booking_operations";

export const fetchConcilliationTraventia = async (target, filters) => {
  try {
    const params = parseConcilliationParams(target, filters);

    const { status, data } = await axios.get(`${prefix}/conciliation/books-conciliated`, { params });
    const { ok, data: dataT } = data;

    let list = [];
    if (status === 200 && ok && dataT.length > 0) {
      list = dataT;
    }

    return { loading: false, list };
  } catch (error) {
    console.error(error);
    return { loading: false, list: [] };
  }
};

export const fetchConcilliationV2Traventia = async (target, filters) => {
  try {
    const params = parseConcilliationParams(target, filters);

    const { status, data } = await axios.get(`/api/v1/booking-bc/booking-reconciliation/results/bookings`, {
      params,
    });

    let list = [];
    if (status === 200) {
      list = data;
    }

    return { loading: false, list };
  } catch (error) {
    console.error(error);
    return { loading: false, list: [] };
  }
};

export const fetchConcilliationProvider = async (target, filters) => {
  try {
    const params = parseConcilliationParams(target, filters);

    const { status, data } = await axios.get(`${prefix}/conciliation/provider-books-conciliated`, { params });
    const { ok, data: dataT } = data;

    let list = [];
    if (status === 200 && ok && dataT.length > 0) {
      list = dataT;
    }

    return { loading: false, list };
  } catch (error) {
    console.error(error);
    return { loading: false, list: [] };
  }
};

export const fetchConcilliationV2Provider = async (target, filters) => {
  try {
    const params = parseConcilliationParams(target, filters);

    const { status, data } = await axios.get(
      `/api/v1/booking-bc/booking-reconciliation/results/provider-bookings`,
      { params }
    );

    let list = [];
    if (status === 200) {
      list = data;
    }

    return { loading: false, list };
  } catch (error) {
    console.error(error);
    return { loading: false, list: [] };
  }
};

export const fetchConcilliationProviderStatus = async () => {
  try {
    const { status, data } = await axios.get(`${prefix}/conciliation/provider-conciliated-status`);
    const { ok, data: dataT } = data;

    let list = [];
    if (status === 200 && ok && dataT.length > 0) {
      list = dataT;
    }

    return { loading: false, list };
  } catch (error) {
    console.error(error);
    return { loading: false, list: [] };
  }
};

export const ignoreBookTraventia = async (servref, value, user, errorCode = "") => {
  const toSend = { servref, value, user };
  if (errorCode !== "") {
    toSend.error = errorCode;
  }

  const { data } = await axios.post(`${prefix}/conciliation/books-conciliated/ignore`, toSend);
  return data;
};

export const ignoreBookTraventiaV2 = async (servref, value, user, errorCode = "") => {
  const toSend = { servref, value, user };
  if (errorCode !== "") {
    toSend.error = errorCode;
  }

  const { data } = await axios.post(
    `/api/v1/booking-bc/booking-reconciliation/results/ignore/bookings`,
    toSend
  );
  return data;
};

export const ignoreBookProvider = async (provider, locata, value, user, errorCode = "") => {
  const toSend = { provider, locata, value, user };
  if (errorCode !== "") {
    toSend.error = errorCode;
  }

  const { data } = await axios.post(`${prefix}/conciliation/provider-books-conciliated/ignore`, toSend);
  return data;
};

export const ignoreBookProviderV2 = async (provider, locata, value, user, errorCode = "") => {
  const toSend = { provider, locata, value, user };
  if (errorCode !== "") {
    toSend.error = errorCode;
  }

  const { data } = await axios.post(
    `/api/v1/booking-bc/booking-reconciliation/results/ignore/provider-bookings`,
    toSend
  );
  return data;
};

const parseConcilliationParams = (target, filters) => {
  const params = { target, ...filters };

  if (params.ignore) {
    delete params.ignore;
  }
  if (params.ok) {
    delete params.ok;
  }
  if (!params.checked) {
    delete params.checked;
  }

  return params;
};
