import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import Paper from "@material-ui/core/Paper";
import { FiCheckCircle, FiChevronsRight, FiChevronsLeft, FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import TagTitle from "@atoms/Titles/TagTitle";

function SelectorAccommodationTypes({ title, selected, onChange }) {
  const [typeList, setTypeList] = useState({});

  const classes = useStyles();
  const [checked, setChecked] = useState([]);

  const [left, setLeft] = useState([]);

  async function getAccommodationTypes() {
    try {
      const { status, data } = await axios.get(`/api/us_accommodations/types`);
      if (status === 200 && data.ok) {
        const { data: fullList } = data;

        const kvList = fullList.reduce((accu, value) => {
          accu[value.code] = value;
          return accu;
        }, {});
        setTypeList(kvList);

        const notSelected = not(
          fullList.map((f) => f.code),
          selected
        );
        setLeft(notSelected);
      }
    } catch (error) {}
  }

  useEffect(() => {
    getAccommodationTypes();
    return () => {};
  }, []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, selected);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    onChange(selected.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    onChange(selected.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    onChange(not(selected, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(selected));
    onChange([]);
  };

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value) => (
          <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": `transfer-list-item-${value}-label` }}
              />
            </ListItemIcon>
            <ListItemText
              id={`transfer-list-item-${value}-label`}
              primary={value}
              secondary={typeList[value]?.name?.es || ""}
            />
          </ListItem>
        ))}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <>
      <TagTitle title={title} numberSelected={selected.length} />
      <Grid container spacing={0} justifycontent="center" alignItems="center" className={classes.root}>
        <Grid item>
          <span style={{ color: "grey" }}>No incluidos</span>
          {customList(left)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <ButtonCustom
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
              icon={<FiChevronsRight/>}
            />
             
            <ButtonCustom
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
              icon={<FiChevronRight/>}
            />
            <ButtonCustom
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
              icon={<FiChevronLeft/>}
            />
            <ButtonCustom
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={selected.length === 0}
              aria-label="move all left"
              icon={<FiChevronsLeft/>}/>
          </Grid>
        </Grid>
        <Grid item>
          <span>
            <b>
              <FiCheckCircle /> {selected.length} seleccionados
            </b>
          </span>
          {customList(selected)}
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

SelectorAccommodationTypes.defaultProps = { title: "Selecciona tipos de alojamientos" };

export default SelectorAccommodationTypes;
