import React, { useState, useEffect, useCallback } from "react";
// Atoms
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
// Material UI
import Grid from "@material-ui/core/Grid";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { Alert } from "@material-ui/lab";
// Components
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import ProductConfigurationsCard from "./ProductConfigurationsCard";
// CustomHooks
import {
  fetchProductConfigurations,
  saveProductConfiguration,
  fetchProducts,
  deleteProductConfiguration,
} from "../Products/useProducts";
import ProductConfigurationsMenu from "./ProductConfigurationsMenu";
import { PRODUCT_CONFIGURATION_TYPES } from "./constants";

function ProductConfigurationsSection({ precinctCode }) {
  // product
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [products, setProducts] = useState([]);
  // change Counter
  const [changeCounter, setChangeCounter] = useState([]);
  // productConfigurationsAvailable
  const [productsWitoutConfiguration, setProductWitoutConfiguration] = useState([]);
  // productConfigurations
  const [loadingProductConfigurations, setLoadingProductConfigurations] = useState(true);
  const [productConfigurations, setProductConfigurations] = useState([]);
  const [productConfigurationsCopy, setProductConfigurationsCopy] = useState([]);
  // Feedback
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [alertData, setAlertData] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });

  const fetchProductConfigurationsList = useCallback(async () => {
    setLoadingProductConfigurations(true);
    const productConfigurationsList = await fetchProductConfigurations({ params: { precinctCode } });
    const newroductConfigurationsList = productConfigurationsList;
    setProductConfigurations(productConfigurationsList);
    setProductConfigurationsCopy(newroductConfigurationsList);
    setLoadingProductConfigurations(false);
  }, [precinctCode]);

  const fetchProductList = useCallback(async () => {
    setLoadingProducts(true);
    const product = await fetchProducts({ params: { precinctCode } });
    const productsList = product;
    setProducts(productsList);
    setLoadingProducts(false);
  }, [precinctCode]);

  const getProductsWitoutConfiguration = useCallback(() => {
    const productConfigurationCodes = productConfigurations.map(
      (productConfiguration) => productConfiguration.productCode
    );
    return products.filter((product) => !productConfigurationCodes.includes(product.code));
  }, [productConfigurations, products]);

  const handleProductsWitoutConfiguration = useCallback(() => {
    const products2 = getProductsWitoutConfiguration();
    setProductWitoutConfiguration(products2);
  }, [getProductsWitoutConfiguration]);

  const searchData = useCallback(async () => {
    await fetchProductList();
    await fetchProductConfigurationsList();
  }, [fetchProductConfigurationsList, fetchProductList]);

  useEffect(() => {
    searchData();
  }, [precinctCode, searchData]);

  useEffect(() => {
    handleProductsWitoutConfiguration();
  }, [handleProductsWitoutConfiguration, productConfigurations]);

  const resetProductsConfigurations = async () => {
    setProductConfigurations(productConfigurationsCopy);
  };

  const thisProductConfigurationContainsChanges = (target) => {
    const unchangedTarget = productConfigurationsCopy.filter(
      (product) => product.productCode === target.productCode
    );
    return JSON.stringify(unchangedTarget[0]) !== JSON.stringify(target);
  };

  const validateProductConfigurationsToSave = (product) => {
    if (product.type === "BLOCK_SKI_STATION" && Object.keys(product).includes("jsonSki")) {
      if (product.jsonSki === "") {
        return { ok: false, error: ["El Código de configuración esta incompleto"] };
      }
      try {
        JSON.parse(product.jsonSki);
        return { ok: true, error: [] };
      } catch (error) {
        return { ok: false, error: ["El Código de configuración contiene errores"] };
      }
    }

    return { ok: true, error: [] };
  };

  const saveProductsConfigurations = async () => {
    const productConfigurationsToSave = productConfigurations.filter((productConfiguration) =>
      thisProductConfigurationContainsChanges(productConfiguration)
    );
    const errors = [];
    let saves = 0;
    if (productConfigurationsToSave.length > 0) {
      for (let index = 0; index < productConfigurationsToSave.length; index += 1) {
        const validation = validateProductConfigurationsToSave(productConfigurationsToSave[index]);
        if (validation.ok) {
          try {
            await saveProductConfiguration(productConfigurationsToSave[index]);
            saves++;
          } catch (error) {
            errors.push(error);
          }
        } else {
          errors.push(validation.error);
        }
      }
      if (errors.length > 0) {
        setErrors({
          show: true,
          list: errors,
        });
      } else {
        searchData();
        setAlertData("Configuraciones guardados con éxito");
        setAlertOpen(true);
        handleProductsWitoutConfiguration();
        setChangeCounter(0);
      }
    }
  };

  const deleteProductsConfigurationHandle = async (poductConfiguration) => {
    try {
      const newPoductConfigurations = [...productConfigurations];
      await deleteProductConfiguration(poductConfiguration);
      setProductConfigurations(newPoductConfigurations);
      searchData();
      setAlertData("Configuración borrado con éxito");
      setAlertOpen(true);
    } catch (error) {
      setErrors({
        show: true,
        list: [error],
      });
    }
  };

  const getEmptyProductConfiguration = (productCode) => {
    return {
      productCode,
      precinctCode,
      type: PRODUCT_CONFIGURATION_TYPES.BLOCK_GEN_DATE,
      status: false,
      jsonSki: "",
    };
  };

  const addNewProductConfiguration = () => {
    const newproductConfigurationsl = [...productConfigurations];
    const productsWitoutConfiguration2 = getProductsWitoutConfiguration();
    const newProductConfiguration = getEmptyProductConfiguration(productsWitoutConfiguration2[0].code);
    newproductConfigurationsl.unshift(newProductConfiguration);
    setProductConfigurations(newproductConfigurationsl);
  };

  const productsAreAvailable = () => {
    return productsWitoutConfiguration.length > 0;
  };

  const thereAreChanges = () => {
    const productConfigurationsToSave = productConfigurations.filter((productConfiguration) =>
      thisProductConfigurationContainsChanges(productConfiguration)
    );
    if (productConfigurationsToSave.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <ContainerFenix>
      <ProductConfigurationsMenu
        productConfigurations={productConfigurations}
        saveProductsConfigurations={{ func: saveProductsConfigurations, available: thereAreChanges() }}
        resetProductsConfigurations={{ func: resetProductsConfigurations, available: thereAreChanges() }}
        addNewProductConfiguration={{ func: addNewProductConfiguration, available: productsAreAvailable() }}
      />

      {loadingProductConfigurations !== true && loadingProducts !== true ? (
        productConfigurations.length === 0 ? (
          <Alert severity={"info"}>No hay canales de producto.</Alert>
        ) : (
          <Grid
            container
            spacing={2}
            alignItems={"flex-start"}
            justify={productConfigurations.length === 1 ? "center" : "space-between"}
            direction="row"
          >
            {productConfigurations.map((configuration, i) => {
              return (
                <ProductConfigurationsCard
                  key={`ProductConfigurationCard-${i}`}
                  deleteProductConfiguration={() => {
                    setConfirmationDialog({
                      open: true,
                      text: `¿Estas seguro que quieres borrar esta configuración?`,
                      body: "Esta acción no puede revertirse.",
                      onConfirm: () => {
                        deleteProductsConfigurationHandle(configuration);
                      },
                    });
                  }}
                  productConfiguration={configuration}
                  productsWithoutConfiguration={getProductsWitoutConfiguration()}
                  onChange={(productConfiguration) => {
                    const newProductConfigurations = [...productConfigurations];
                    newProductConfigurations.splice(i, 1, productConfiguration);
                    setProductConfigurations(newProductConfigurations);
                    setChangeCounter(changeCounter + 1);
                  }}
                />
              );
            })}
          </Grid>
        )
      ) : (
        <CenterLoaderCircle />
      )}
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => {
          setErrors({ show: false, list: [] });
        }}
      />
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={() => {
          confirmationDialog.onConfirm();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
        onDeny={() => {
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
    </ContainerFenix>
  );
}

ProductConfigurationsSection.propTypes = {};

export default ProductConfigurationsSection;
