import React, { useState, useEffect, useCallback } from "react";
// Atoms
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
// Molecules
import CustomModal from "@molecules/Modal/CustomModal";
// Material UI
import Grid from "@material-ui/core/Grid";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { Alert } from "@material-ui/lab";
// Components
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import ProductChannelCard from "./ProductChannelCard";
// CustomHooks
import { fetchPrecinctChannels } from "../Precincts/usePrecincts";
import { fetchProducts, fetchProductChannels, deleteProductChannel } from "../Products/useProducts";
import ProductChannelsMenu from "./ProductChannelsMenu";
import UploadResultAlert from "@molecules/Alerts/UploadResultAlert";
import saveProductChannelList from "./bulkUpload";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";

function ProductChannelsSection({ precinctCode }) {
  const [uploadResult, setUploadResult] = useState();
  const [openModal, setOpenModal] = useState(false);
  //Precincts
  const [loadingPrecinctChannels, setLoadingPrecinctChannels] = useState(true);
  const [precinctChannels, setPrecinctChannels] = useState([]);
  //Prroducts
  const [loadingProductsList, setLoadingProductsList] = useState(true);
  const [productsList, setProductsList] = useState({});
  //productChannels
  const [loadingProductChannels, setLoadingProductChannels] = useState(true);
  const [productChannels, setProductChannels] = useState([]);
  const [productChannelsCopy, setProductChannelsCopy] = useState([]);
  // Feedback
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [alertData, setAlertData] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const searchPrecintChannels = useCallback(async () => {
    setLoadingPrecinctChannels(true);
    let precinctChannelsFound = await fetchPrecinctChannels(precinctCode);
    const precinctChannels = precinctChannelsFound.reduce(
      (acc, cv) => {
        acc.providers.push(cv.channel.provider);
        acc.values.push(cv);
        return acc;
      },
      { providers: [], values: [] }
    );
    setPrecinctChannels(precinctChannels);
    setLoadingPrecinctChannels(false);
  }, [precinctCode]);

  const fetchProductList = useCallback(async () => {
    setLoadingProductsList(true);
    const product = await fetchProducts({ params: { precinctCode } });
    const productsList = product.reduce(
      (acc, cv) => {
        acc.codes.push(cv.code);
        acc.values.push(cv);
        return acc;
      },
      { codes: [], values: [] }
    );

    setProductsList(productsList);
    setLoadingProductsList(false);
  }, [precinctCode]);

  const fetchProductChannelsList = useCallback(async () => {
    setLoadingProductChannels(true);
    const productChannelsList = await fetchProductChannels({ params: { precinctCode } });
    console.log({ productChannelsList });
    const { booking, cancellation, voucher, rooming } = getStartChannelConfigurationSelector();
    const newPC = productChannelsList.map((cv) => {
      if (!cv.booking) {
        cv.booking = addNewProductsChannel.booking;
      } else {
        if (!cv.booking.configuration) {
          cv.booking.configuration = booking.configuration;
        } else {
          if (!cv.booking.configuration.delayItToStartDate) {
            cv.booking.configuration.delayItToStartDate = booking.configuration.delayItToStartDate;
          } else {
            if (!cv.booking.configuration.delayItToStartDate.enabled) {
              cv.booking.configuration.delayItToStartDate.enabled =
                booking.configuration.delayItToStartDate.enabled;
            }

            if (!cv.booking.configuration.delayItToStartDate.daysBeforeStartDate) {
              cv.booking.configuration.delayItToStartDate.daysBeforeStartDate =
                booking.configuration.delayItToStartDate.daysBeforeStartDate;
            }
          }

          if (!cv.booking.configuration.multiBooking) {
            cv.booking.configuration.multiBooking = booking.configuration.multiBooking;
          } else {
            if (!cv.booking.configuration.multiBooking.enabled === undefined) {
              cv.booking.configuration.multiBooking.enabled = booking.configuration.multiBooking.enabled;
            }
            if (!cv.booking.configuration.multiBooking.daysPerBooking) {
              cv.booking.configuration.multiBooking.daysPerBooking =
                booking.configuration.multiBooking.daysPerBooking;
            }
          }
        }
        if (!cv.voucher) {
          cv.voucher = voucher;
        }
        if (!cv.cancellation) {
          cv.cancellation = cancellation;
        }
        if (!cv.rooming) {
          cv.rooming = rooming;
        }
        if (!cv.rooming.enabled) {
          cv.rooming.enabled = rooming.enabled;
        }
        if (!cv.rooming.agesGroup) {
          cv.rooming.agesGroup = rooming.agesGroup;
        }
        if (!cv.rooming.lang) {
          cv.rooming.lang = rooming.lang;
        }
      }

      return cv;
    });
    setProductChannels(newPC);
    setProductChannelsCopy(newPC);
    setLoadingProductChannels(false);
  }, [precinctCode]);

  const searchData = async () => {
    searchPrecintChannels();
    fetchProductList();
    fetchProductChannelsList();
  };

  useEffect(() => {
    searchPrecintChannels();
    fetchProductList();
    fetchProductChannelsList();
  }, [precinctCode, searchPrecintChannels]);

  const parseProductsChannels = () => {
    return productChannels.map((productChannel) => {
      const productChannelClone = Object.assign({}, productChannel);
      if (productChannelClone.configurationInheritedPrecinct) {
        delete productChannelClone.voucher;
        delete productChannelClone.booking;
        delete productChannelClone.cancellation;
      }
      if (productChannelClone.channel.merchant === "") {
        delete productChannelClone.channel.merchant;
      }
      return productChannelClone;
    });
  };

  const resetProductsChannels = async () => {
    setProductChannels(productChannelsCopy);
  };

  const saveProductsChannels = async () => {
    let nuProductChannels = parseProductsChannels(productChannels);
    nuProductChannels = nuProductChannels.filter((cv) => cv.flag === "new" || cv.flag === "edit");
    if (nuProductChannels.length > 0) {
      const { save, errors } = await saveProductChannelList(nuProductChannels);
      if (errors.length > 0) {
        setErrors({
          show: true,
          list: errors,
        });
      } else {
        searchData();
        setAlertData("Canales guardados con éxito");
        setAlertOpen(true);
      }
    }
  };

  const deleteProductsChannelHandle = async (index, channel) => {
    try {
      const newChannels = [...productChannels];
      await deleteProductChannel(channel);
      setProductChannels(newChannels);
      searchData();
      setAlertData("Canal borrado con éxito");
      setAlertOpen(true);
    } catch (error) {
      setErrors({
        show: true,
        list: [error],
      });
    }
  };

  const getStartChannelConfigurationSelector = () => {
    return {
      booking: {
        mode: "",
        inherited: true,
        configuration: {
          emails: [],
          cco: [],
          include: {
            phone: false,
            accommodation: false,
          },
          multiBooking: {
            enabled: false,
            daysPerBooking: 1,
          },
          delayItToStartDate: {
            enabled: false,
            daysBeforeStartDate: 1,
          },
        },
      },
      cancellation: {
        inherited: true,
        mode: "",
        configuration: {
          emails: [],
          cco: [],
        },
      },
      voucher: {
        inherited: true,
        mode: "",
        configuration: { payable: "", logo: "", address: "", email: "", phone: "" },
      },
      rooming: {
        enabled: false,
        daysToDate: 1,
        emails: {
          to: [],
          bcc: [],
        },
        agesGroup: [],
        lang: "es-ES",
      },
    };
  };

  const getEmptyProductChannel = () => {
    return {
      productCode: productsList.codes[0],
      precinctCode,
      channel: precinctChannels.values[0].channel || { provider: "" },
      configurationInheritedPrecinct: true,
      booking: {
        mode: "",
        inherited: true,
        configuration: {
          emails: [],
          cco: [],
          include: {
            phone: false,
            accommodation: false,
          },
          multiBooking: {
            enabled: false,
            daysPerBooking: 1,
          },
          delayItToStartDate: {
            enabled: false,
            daysBeforeStartDate: 1,
          },
        },
      },
      cancellation: {
        inherited: true,
        mode: "",
        configuration: {
          emails: [],
          cco: [],
        },
      },
      voucher: {
        inherited: true,
        mode: "",
        configuration: { payable: "", logo: "", address: "", email: "", phone: "" },
      },
      rooming: {
        enabled: false,
        daysToDate: 1,
        emails: {
          to: [],
          bcc: [],
        },
        agesGroup: [],
      },
    };
  };

  const addNewProductsChannel = () => {
    const copyProductChannel = [...productChannels];
    const newChannel = getEmptyProductChannel();
    newChannel.flag = "new";
    copyProductChannel.push(newChannel);
    setProductChannels(copyProductChannel);
  };

  return (
    <ContainerFenix>
      <ProductChannelsMenu
        addNewProductsChannel={addNewProductsChannel}
        searchData={searchData}
        onOpenModal={onOpenModal}
        precinctCode={precinctCode}
        productChannels={productChannels}
        setUploadResult={setUploadResult}
        saveProductsChannels={saveProductsChannels}
        resetProductsChannels={resetProductsChannels}
      />

      {uploadResult && (
        <UploadResultAlert uploadResult={uploadResult} onClose={() => setUploadResult({ status: "close" })} />
      )}
      {loadingProductsList !== true && loadingProductChannels !== true && loadingPrecinctChannels !== true ? (
        productChannels.length === 0 ? (
          <Alert severity={"info"}>No hay canales de producto.</Alert>
        ) : (
          <Grid
            container
            spacing={2}
            alignItems={"flex-start"}
            justify={productChannels.length === 1 ? "center" : "space-between"}
            direction="row"
          >
            {productChannels.map((channel, i) => {
              return (
                <ProductChannelCard
                  key={`ProductChannelsCard-${i}`}
                  productsListCodes={productsList.codes}
                  precinctChannels={precinctChannels}
                  deletePC={() => {
                    console.log("HOLA");
                    setConfirmationDialog({
                      open: true,
                      text: `¿Estas seguro que quieres borrar este canal?`,
                      body: "Esta acción no puede revertirse.",
                      onConfirm: () => {
                        deleteProductsChannelHandle(i, channel);
                      },
                    });
                  }}
                  channel={channel}
                  precinctLength={productChannels.length}
                  onChange={(nuChannel) => {
                    const newChannels = [...productChannels];
                    nuChannel.flag = "edit";
                    newChannels.splice(i, 1, nuChannel);
                    setProductChannels(newChannels);
                  }}
                />
              );
            })}
          </Grid>
        )
      ) : (
        <CenterLoaderCircle />
      )}
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => {
          setErrors({ show: false, list: [] });
        }}
      />
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={() => {
          confirmationDialog.onConfirm();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
        onDeny={() => {
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
    </ContainerFenix>
  );
}

ProductChannelsSection.propTypes = {};

export default ProductChannelsSection;
