// useFetch.js
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

const url = "/api/v1/marketing-bc/nevasport";

const responseModifier = (data) => {
  const dataM = [];
  for (const cv of data) {
    cv.startDate = moment(cv.startDate * 1000).format("DD/MM/YYYY");
    cv.endDate = moment(cv.endDate * 1000).format("DD/MM/YYYY");
    cv.visible = cv.visible ? 1 : 0;
    dataM.push(cv);
  }
  return dataM;
};

export const fetchOffersDataF = async () => {
  let thestate = { loading: true, offers: [] };
  try {
    const response = await axios.get(`${url}/offers`);
    if (response.status === 200 && response.data.ok) {
      thestate = { loading: false, offers: responseModifier(response.data.data) };
    }
  } catch (error) {
    thestate = { loading: false, offers: [] };
    throw error;
  }

  return thestate;
};

export const useNevasportOffers = () => {
  const [offersData, setOffersData] = useState({ loading: true, offers: [] });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, offers: [] };
        const response = await axios.get(`${url}/offers`);
        if (response.status === 200 && response.data.ok) {
          thestate = { loading: false, offers: responseModifier(response.data.data) };
        }
        setOffersData(thestate);
      } catch (error) {
        setOffersData({ loading: false, offers: [] });
        throw error;
      }
    };

    if (offersData.loading) {
      fetchData();
    }
  }, [offersData.loading]);

  return { loadingOffers: offersData.loading, offersNevasport: offersData.offers };
};

export const useNevasportSeasons = () => {
  const [seasonsData, setSeasonsData] = useState({ loading: true, seasons: [] });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, seasons: [] };
        const response = await axios.get(`${url}/seasons`);
        if (response.status === 200 && response.data.ok) {
          thestate = { loading: false, seasons: response.data.data };
        }
        setSeasonsData(thestate);
      } catch (error) {
        setSeasonsData({ loading: false, seasons: [] });
        throw error;
      }
    };

    if (seasonsData.loading) {
      fetchData();
    }
  }, [seasonsData.loading]);

  return { loadingSeasons: seasonsData.loading, seasonsNevasport: seasonsData.seasons };
};

export const useNevasportSkiResort = () => {
  const [SkiResortsData, setSkiResortsData] = useState({ loading: true, skiResort: [] });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, skiResort: [] };
        const response = await axios.get(`${url}/skiResorts`);
        if (response.status === 200 && response.data.ok) {
          thestate = { loading: false, skiResort: response.data.data };
        }
        setSkiResortsData(thestate);
      } catch (error) {
        setSkiResortsData({ loading: false, skiResort: [] });
        throw error;
      }
    };

    if (SkiResortsData.loading) {
      fetchData();
    }
  }, [SkiResortsData.loading]);

  return { loadingSkiResorts: SkiResortsData.loading, skiResortsNevasport: SkiResortsData.skiResort };
};

export const saveNevasportOffers = async (offers) => {
  if (offers.length === 0) {
    return;
  }
  try {
    await axios.post(`${url}/offers`, offers);
  } catch (error) {
    console.error(error);
  }
};

export const deleteNevasportOffers = async (offers) => {
  if (offers.length === 0) {
    return;
  }
  try {
    await axios.post(`${url}/offersDelete`, offers);
  } catch (error) {
    console.error(error);
  }
};
