import React from "react";
import PropTypes from "prop-types";
import ButtonUpload from "@atoms/Buttons/ButtonUpload";
import useCSVManagement from "@lib/hooks/useCSVManagement";

function ButtonUploadCSV({ wordcontrol, specs, buttonLabel, ignoreWordcontrol, onResultReady, ...props }) {
  const { loading, ref, handleFileInput, getFile } = useCSVManagement({
    wordcontrol,
    specs,
    buttonLabel,
    ignoreWordcontrol,
    onResultReady,
  });

  return (
    <>
      <ButtonUpload
        {...props}
        variant="outlined"
        size="medium"
        onClick={getFile}
        disabled={loading || props.disabled}
      >
        {buttonLabel && buttonLabel}
      </ButtonUpload>
      <div style={{ display: "none" }}>
        <input type="file" ref={ref} onChange={(event) => handleFileInput(event.target.files)} />
      </div>
    </>
  );
}

ButtonUploadCSV.propTypes = {
  wordcontrol: PropTypes.string,
  specs: PropTypes.object,
  buttonLabel: PropTypes.string,
  ignoreWordcontrol: PropTypes.bool,
  onResultReady: PropTypes.func,
};

ButtonUploadCSV.defaultProps = {
  ignoreWordcontrol: false,
};

export default ButtonUploadCSV;
