import React from "react";
import axios from "axios";
import ItemSelectorWithQuery from "@molecules/Forms/ItemSelectorWithQuery";

function SelectorHzones({ title, selected, onChange }) {
  const onSearch = async (query) => {
    const hzones = {};
    try {
      const url =
        "/api/v1/accommodation-bc/accommodation/zones?_offset=0&_limit=10000&_fields=code,alias,name";
      const promises = [axios.get(`${url}&code[regex]=${query}`), axios.get(`${url}&alias[regex]=${query}`)];

      const responses = await Promise.all(promises);
      responses.forEach((res) => {
        if (res.status === 200) {
          res.data.data.forEach((acc) => {
            hzones[acc.code] = { code: acc.code, text: [acc.code, acc.name.es].join(" - ") };
          });
        }
      });
    } catch (e) {
      console.error(e);
    }

    return Object.values(hzones);
  };

  return (
    <ItemSelectorWithQuery
      title={title}
      inputPlaceholder={"Escribe aqui el código o nombre de la zona de alojamiento que quieres añadir"}
      selected={selected}
      onSearch={onSearch}
      onChange={onChange}
    />
  );
}

SelectorHzones.defaultProps = { title: "Selecciona zonas de alojamiento" };

export default SelectorHzones;
