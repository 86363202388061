import React from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import FenixTableCustomPagination from "@molecules/Tables/FenixTable/FenixTableCustomPagination";
import { ACCOUNTING_SUPPLIER_URL } from "../hooks";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";

import { AccountingSupplierManagement } from "./AccountingSupplierManagement/AccountingSupplierManagement";
import { AccountingSupplierBankAccountsManagement } from "./AccountingSupplierManagement/AccountingSupplierBankAccountsManagement";
const AccountingSuppliersView = ({
  accountingSupplierToManageId,
  accountingSupplierToManage,
  tableHead,
  accountingSuppliersActions,
  alertOpen,
  alertData,
  setAlertOpen,
  query,
  resetAccountingSupplierToManage,
  headActions,
  specs,
  generateTable,
  managementMode,
  changeManagementMode,
  editAccountingSupplierMode,
  setAccountingSupplierToManageId,
}) => {
  const wordcontrol = `suppliers-v2`;
  return (
    <>
      {accountingSupplierToManageId === "" && (
        <>
          <CardTitle
            title={`Suppliers`}
            buttons={[
              <ButtonAdd size={"medium"} onClick={() => editAccountingSupplierMode("new_supplier", "edit")}>
                Nuevo supplier
              </ButtonAdd>,
              <ButtonDownloadCSV
                wordcontrol={wordcontrol}
                key={"btDownloadCSV"}
                head={headActions}
                specs={specs}
                table={[]}
                filename={`suppliers_v2_info`}
                buttonLabel={"Descargar suppliers"}
                info={[""]}
                generateTable={generateTable}
                onDownloadEnded={() => {}}
              />,
            ]}
          />
          <FenixTableCustomPagination
            tableHead={tableHead}
            actions={accountingSuppliersActions}
            customPaginationFetchUrl={ACCOUNTING_SUPPLIER_URL}
            searchQueryParam={["code[regex]"]}
            withSearch={true}
            inputText={query}
            maxNumberPerPages={15}
            pagination={true}
            isSelectable={false}
            minCharsToSearch={1}
            // forceReload={forceReloadTable}
            // modifyContentTable={(content) => {
            //   const newContent = content.map((item) => {
            //     item["tags"] = Object.keys(item.rules).reduce((acc, key) => {
            //       let cv = item.rules[key];
            //       if (cv) {
            //         acc.push(NAME_RULES[key]);
            //       }
            //       return acc;
            //     }, []);
            //     if (item["tags"].length === 0) item["tags"] = "-";
            //     return item;
            //   });

            //   return newContent;
            // }}
          />
          ;
          <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
        </>
      )}
      {accountingSupplierToManageId !== "" && (
        <>
          {managementMode === "edit" && (
            <AccountingSupplierManagement
              isNew={accountingSupplierToManageId === "new_supplier"}
              supplier={accountingSupplierToManageId}
              loading={accountingSupplierToManage === undefined}
              backFunction={() => {
                resetAccountingSupplierToManage();
              }}
              setManagementMode={changeManagementMode}
              setSupplierToManageId={setAccountingSupplierToManageId}
            />
          )}
          {managementMode === "bankAccounts" && (
            <AccountingSupplierBankAccountsManagement
              supplier={accountingSupplierToManageId}
              backFunction={() => {
                resetAccountingSupplierToManage();
              }}
              loading={accountingSupplierToManage === undefined}
            />
          )}
        </>
      )}
    </>
  );
};

export default AccountingSuppliersView;
