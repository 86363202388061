import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TableCell, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import { MdMoreVert } from "react-icons/md";

const FenixTableRowAction = ({ actions, rowData }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const onOptionMenuSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickInSectionOfMenu = (option) => {
    setAnchorEl(null);
    option.func(rowData);
  };

  return (
    <TableCell align="center" style={{ maxWidth: "42px" }}>
      <TdRightActions onClick={onOptionMenuSelect}>
        <MdMoreVert />
      </TdRightActions>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {actions
          .filter((action) => {
            if (action.showIf) {
              return action.showIf(rowData);
            }
            return true;
          })
          .map((option, index) => (
            <MenuItem key={index} onClick={() => clickInSectionOfMenu(option)}>
              <ListItemIcon>{option.icon}</ListItemIcon> {option.name}
            </MenuItem>
          ))}
      </Menu>
    </TableCell>
  );
};

const TdRightActions = styled.span`
  border-radius: 50%;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export default FenixTableRowAction;
