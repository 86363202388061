import { useState, useEffect, useCallback } from "react";
import { getCellContentWithFieldCode } from "./shared/utils";

const useFenixTable = (loading, tableHead, tableContent, sortDefault, cellContentDefault) => {
  // Create the state of the table
  const [tableData, setTableData] = useState([]);

  // Order 'asc' / 'desc' && Field we are ordering by
  const [order, setOrder] = useState(sortDefault);

  // Search (filter) input
  const [inputText, setInputText] = useState("");

  // Active filters
  const [filters, setFilters] = useState({});

  const initializeFilters = useCallback(() => {
    // console.log("Inicializo los filtros");

    const nuFilterOptions = tableHead
      .filter((h) => h.filterable)
      .reduce((foptions, h) => {
        const field = h.code;
        const options = tableContent.reduce((opt, row) => {
          if (!opt.includes(row[field])) {
            opt.push(row[field]);
          }
          return opt;
        }, []);
        return { ...foptions, [field]: { field, options, values: [] } };
      }, {});

    return nuFilterOptions;
  }, [tableContent, tableHead]);

  const sortTable = (table, direction, field) => {
    // console.log(direction, field);

    return direction === "desc"
      ? table.sort((a, b) => (b[field] < a[field] ? -1 : 1))
      : table.sort((a, b) => (a[field] < b[field] ? -1 : 1));
  };

  const applyFilters = (tableWhere, filtersToApply) => {
    const ftable = tableWhere.filter((tableRow) => {
      let goThrough = true;
      Object.keys(filtersToApply).forEach((keyF) => {
        if (filtersToApply[keyF].values.length > 0) {
          goThrough = goThrough && filtersToApply[keyF].values.indexOf(tableRow[keyF]) !== -1;
        }
      });
      return goThrough;
    });

    return ftable;
  };

  const applyQuerySearch = useCallback(
    (tableWhere, querySearch) => {
      // console.log("Apply query search ", querySearch);
      let queryTable = [...tableWhere];

      if (querySearch.length >= 3) {
        queryTable = queryTable.filter((tableRow) => {
          let goThrough = false;
          tableHead.forEach((h) => {
            if (h.type === "string" || h.type === "numeric") {
              const cellContent = getCellContentWithFieldCode(tableRow, h, cellContentDefault);
              goThrough =
                goThrough || cellContent.toString().toLowerCase().indexOf(querySearch.toLowerCase()) !== -1;
            } else if (h.searchableText !== undefined) {
              const textToEval = h.searchableText(tableRow);
              goThrough = goThrough || textToEval.toLowerCase().indexOf(querySearch.toLowerCase()) !== -1;
            }
          });
          return goThrough;
        });
      }

      return queryTable;
    },
    [cellContentDefault, tableHead]
  );

  const initializeTableData = useCallback(() => {
    // console.log("Inicializo table data ", filters, inputText, order.by, order.direction, tableContent.length);

    const orderedContent = [...tableContent];
    sortTable(orderedContent, order.direction, order.by);
    let orderedIndexedContent = orderedContent.map((row, index) => ({
      ...row,
      key_id: `FxTableRow_${index}`,
    }));

    if (inputText !== "") {
      orderedIndexedContent = applyQuerySearch(orderedIndexedContent, inputText);
    }

    if (filters !== undefined) {
      orderedIndexedContent = applyFilters(orderedIndexedContent, filters);
    }

    setTableData(orderedIndexedContent);
  }, [applyQuerySearch, filters, inputText, order.by, order.direction, tableContent]);

  useEffect(() => {
    if (!loading) {
      initializeTableData();
    }
  }, [initializeTableData, loading, tableContent, tableHead]);

  useEffect(() => {
    setFilters(initializeFilters());
  }, [initializeFilters, tableHead, tableContent]);

  const onRequestSort = (property) => {
    let nuOrder = "desc";
    if (order.by === property && order.direction === "desc") {
      nuOrder = "asc";
    }

    const orderedContent = [...tableData];
    sortTable(orderedContent, nuOrder, property);

    const orderedIndexedContent = orderedContent.map((row, index) => ({
      ...row,
      key_id: `FxTableRow_${index}`,
    }));
    setTableData(orderedIndexedContent);
    setOrder({ direction: nuOrder, by: property });
  };

  const onChangeFilters = ({ column, value }) => {
    const newFilters = { ...filters };
    newFilters[column].values = value;

    const queryTable = applyQuerySearch(tableContent, inputText);
    const filteredTable = applyFilters(queryTable, newFilters);
    setTableData(filteredTable);
    setFilters(newFilters);
  };

  const onChangeInputSearch = (newInputText) => {
    // console.log("newInputText>>>>", newInputText);

    setInputText(newInputText);
    const filteredTable = applyFilters(tableContent, filters);
    const queryTable = applyQuerySearch(filteredTable, newInputText);
    setTableData(queryTable);
  };

  // Update the data of a row (for enable/disable action)
  const updateRowData = (index, nuRow) => {
    const nuTableData = [...tableData];
    nuTableData[index] = nuRow;
    setTableData(nuTableData);
  };

  return {
    tableData,
    order,
    inputText,
    filters,
    onRequestSort,
    onChangeFilters,
    onChangeInputSearch,
    updateRowData,
  };
};

export default useFenixTable;
