const sanitizeTag = (tagRaw) => {
  let nuTag = commonSanitize(tagRaw);
  nuTag = nuTag.replace(/[^a-z0-9_-]+/g, "");
  return nuTag;
};

const sanitizeName = (pathRaw) => {
  let nuPath = commonSanitize(pathRaw);
  nuPath = nuPath.replace(/[^a-z0-9_\-.]+/g, "");
  return nuPath;
};

const sanitizeFolder = (pathRaw) => {
  let nuPath = commonSanitize(pathRaw);
  nuPath = nuPath.replace(/[^a-z0-9_\-]+/g, "");
  return nuPath;
};

const commonSanitize = (string) => {
  let creanText = string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  creanText = creanText.replace(/ /g, "_");
  creanText = creanText.replace(/[()\+*[\]\\]/g, "-");
  return creanText;
};

module.exports = { sanitizeTag, sanitizeName, sanitizeFolder };
