import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { AiOutlineSearch } from "react-icons/ai";

function ButtonSearch({ size, variant, disabled, onClick, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="add"
      icon={<AiOutlineSearch />}
      customcolor="primary"
      onClick={onClick}
    >
      {" "}
      {children === undefined ? "Buscar" : children}
    </ButtonCustom>
  );
}

export default ButtonSearch;
