import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FxSwitch from "@atoms/Switches/FxSwitch";

function OfferBoatCheckElementConfiguration({ title, propertyKey, indexBoatInfo, boatsInfo, setBoatsInfo }) {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <h6 style={{ fontWeight: "bold", margin: "0" }}>{title}</h6>
      <ButtonGroup aria-label="outlined  primary button group">
        <FxSwitch
          labels={["Desactivar", "Activar"]}
          status={boatsInfo[indexBoatInfo][propertyKey]}
          onChange={() => {
            boatsInfo[indexBoatInfo][propertyKey] = !boatsInfo[indexBoatInfo][propertyKey];
            setBoatsInfo([...boatsInfo]);
          }}
        />
      </ButtonGroup>
    </div>
  );
}

export default OfferBoatCheckElementConfiguration;
