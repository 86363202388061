import React, { useState } from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { AiOutlineSearch } from "react-icons/ai";
import OfferBoatGridOptionsConfiguration from "./OfferBoatGridOptionsConfiguration";

function OfferBoatCreateConfiguration({
  form,
  handle,
  setLoadingConfiguration,
  routePorts,
  destinations,
  portZones,
}) {
  const [boatsNewConfigResult, setBoatsNewConfigResult] = useState({
    destination: "",
    routes: [],
    excludeProviders: [],
    forceProviders: [],
    scheduleFilter: {
      goDepartureHourInterval: [],
      goArrivalHourInterval: [],
      backDepartureHourInterval: [],
      backArrivalHourInterval: [],
    },
    accommodationFilter: {
      go: [],
      back: [],
    },
    defaultPriceWithVehicle: false,
    offerOnlyWithVehicle: false,
  });

  const initBoatsConfig = () => {
    boatsNewConfigResult.routes = [
      ...routePorts.list
        .filter((route) => route.destination_code === boatsNewConfigResult.destination)
        .map((item) => item.code),
    ];
    boatsNewConfigResult.excludeProviders = [];
    boatsNewConfigResult.forceProviders = [];
    boatsNewConfigResult.scheduleFilter.goDepartureHourInterval = [];
    boatsNewConfigResult.scheduleFilter.goArrivalHourInterval = [];
    boatsNewConfigResult.scheduleFilter.backDepartureHourInterval = [];
    boatsNewConfigResult.scheduleFilter.backArrivalHourInterval = [];
    boatsNewConfigResult.accommodationFilter.go = [];
    boatsNewConfigResult.accommodationFilter.back = [];
  };

  const getDestinationsMatch = () => {
    const allDestinationCodesByRoutes = routePorts.list.map((route) => route.destination_code);
    const portsWithRoutes = destinations.list.filter((destination) =>
      allDestinationCodesByRoutes.includes(destination.code)
    );
    const portsZonesWithRoutes = portZones.list.filter((portZone) =>
      allDestinationCodesByRoutes.includes(portZone.code)
    );
    return [...portsZonesWithRoutes, ...portsWithRoutes];
  };

  return (
    <>
      <OfferBoatGridOptionsConfiguration
        title="Seleccionar destino"
        propertyKey="destination"
        listOptions={getDestinationsMatch() || []}
        allowMultipleChoice={false}
        boatsInfo={boatsNewConfigResult}
        setBoatsInfo={setBoatsNewConfigResult}
        filterActive={true}
      />

      <br></br>
      {boatsNewConfigResult.destination !== "" && (
        <ButtonCustom
          icon={<AiOutlineSearch />}
          customcolor={"lightblue"}
          onClick={() => {
            initBoatsConfig();
            setBoatsNewConfigResult({
              ...boatsNewConfigResult,
            });
            if (form.services.boats.length > 0) {
              form.services.boats.pop(boatsNewConfigResult);
              handle({ ...form });
            }
            form.services.boats.push(boatsNewConfigResult);
            handle({ ...form });
            setLoadingConfiguration(false);
          }}
        >
          Buscar rutas {boatsNewConfigResult.destination}
        </ButtonCustom>
      )}

      <br></br>
      <br></br>
    </>
  );
}

export default OfferBoatCreateConfiguration;
