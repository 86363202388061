import React, { useState } from "react";
import { InputGroup } from "react-bootstrap";
import { FiUploadCloud } from "react-icons/fi";
// We should'nt use this component directly: use a wrapp-button component instead

function InputUploadFile({ ariaLabel = "", id = "", disabled, handleUpload }) {
  const [fileSelected, setFileSelected] = useState(null);

  const handleSend = () => {
    if (fileSelected) {
      handleUpload(fileSelected);
    } else {
      console.log("no eligió archivo");
    }
  };

  const onChangeInput = (e) => {
    if (e.target.files[0]?.name !== undefined) {
      setFileSelected(e.target.files[0]);
    }
  };

  return (
    <>
      <div className="input-group custom-file-button">
        <InputGroup>
          <input type="file" id={id} disabled={disabled} aria-label={ariaLabel} onChange={onChangeInput} />
          {fileSelected && (
            <label className="input-group-text uploadlabel" onClick={handleSend}>
              <FiUploadCloud /> Subir archivo
            </label>
          )}
        </InputGroup>
      </div>

      <style jsx="true">
        {`
          .custom-file-button input[type="file"] {
            margin-left: -2px !important;
          }

          .custom-file-button:hover label {
            background-color: #dde0e3;
            cursor: pointer;
          }
          .custom-file-button label.uploadlabel {
            background-color: #17a2b8 !important;
            color: #fff;
          }
          .custom-file-button:hover label.uploadlabel {
            background-color: #138496 !important;
            color: #fff;
          }
        `}
      </style>
    </>
  );
}

export default InputUploadFile;
