import React, { useState, useEffect } from "react";
import { useKeyPickupList, putKeyPickups, deleteKeyPickups } from "../../hooks/useKeyPickupFetch";
import { userAuthorized } from "../../../../entries/fenix/auth/FenixAuthContext";
import { MdClear, MdOutlineModeEditOutline } from "react-icons/md";

const KeyPickupListState = () => {
  const user = userAuthorized();
  const [listLength, setListLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [alertData, setAlertData] = useState({ text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorModal, setErrorModal] = useState({ open: false, errors: [] });
  const [loading, setLoading] = useState(false);
  const limitForQuery = 100;
  const limitTable = 10;
  const maxCaracter = 1000;
  const [forceReloadTable, setForceReloadTable] = useState(0);
  const [modal, setModal] = useState({
    open: false,
    info: "",
    hcode: "",
    pickingType: "",
    lang: "es-ES",
    info: { "es-ES": "", "fr-FR": "", "pt-PT": "", "it-IT": "" },
  });
  const wordcontrol = `key-pickup-information-v3`;
  const KEY_PICKUP_TYPES = { PICKUP: 0, "24H": 1 };
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
    onDenied: () => {},
  });

  const resetModal = () => {
    setModal({
      open: false,
      info: "",
      hcode: "",
      pickingType: "",
      lang: "es-ES",
      info: { "es-ES": "", "fr-FR": "", "pt-PT": "", "it-IT": "" },
    });
  };

  const [acceptDialog, setAcceptDialog] = useState({
    open: false,
    text: "",
    body: "",
    onAccept: () => {},
  });
  const updateList = async () => {
    setForceReloadTable(forceReloadTable + 1);
  };

  useEffect(() => {
    async function loadData() {
      await updateInitialList();
    }
    loadData();
  }, []);

  const updateInitialList = async () => {
    const keyData = await useKeyPickupList(0, "", 1);

    setListLength(keyData.total);
  };

  const keyPickupsValidation = (point, ln) => {
    const errors = [];
    if (point.hcode === "") {
      errors.push(`${ln}: El código de hotel está vacío.`);
    }
    if (point.pickingType === "") {
      errors.push(`${ln}: El tipo está vacío.`);
    } else if (!["PICKUP", "24H"].includes(point.pickingType)) {
      errors.push(
        `${ln}: El tipo es invalido, tiene que ser uno de los siguientes: ${["PICKUP", "24H"].join(",")}`
      );
    }
    if (point.pickingType === "PICKUP") {
      if (point.info["es-ES"] === "") {
        errors.push(`${ln}: La información en Español está vacía.`);
      }
      if (point.info["es-ES"].length > maxCaracter) {
        errors.push(`${ln}: La información en Español supera el limite de ${maxCaracter} caracteres.`);
      }
      if (point.info["pt-PT"] === "") {
        errors.push(`${ln}: La información en Portugues está vacía.`);
      }
      if (point.info["pt-PT"].length > maxCaracter) {
        errors.push(`${ln}: La información en Portugues supera el limite de ${maxCaracter} caracteres.`);
      }
      if (point.info["it-IT"] === "") {
        errors.push(`${ln}: La información en Italiano está vacía.`);
      }
      if (point.info["it-IT"].length > maxCaracter) {
        errors.push(`${ln}: La información en Italiano supera el limite de ${maxCaracter} caracteres.`);
      }
      if (point.info["fr-FR"] === "") {
        errors.push(`${ln}: La información en Frances está vacía.`);
      }
      if (point.info["fr-FR"].length > maxCaracter) {
        errors.push(`${ln}: La información en Frances supera el limite de ${maxCaracter} caracteres.`);
      }
    }
    if (point.pickingType === "24H") {
      if (point.info["es-ES"].lengh > 0) {
        errors.push(`${ln}: La información en Español tiene que estar vacía.`);
      }
      if (point.info["pt-PT"]) {
        errors.push(`${ln}: La información en Portugues tiene que estar vacía.`);
      }
      if (point.info["it-IT"]) {
        errors.push(`${ln}: La información en Italiano tiene que estar vacía.`);
      }
      if (point.info["fr-FR"].lengh > 0) {
        errors.push(`${ln}: La información en Frances tiene que estar vacía.`);
      }
    }
    if (point.info === "") {
      errors.push(`${ln}: La información está vacío.`);
    }

    return errors;
  };

  const difference = (currentList, newList) => {
    const toInsert = [];
    const toUpdate = [];
    const toDelete = [];
    newList.forEach((itemToCheck) => {
      if (itemToCheck.delete === true) {
        toDelete.push(itemToCheck);
      } else {
        const theStoredOne = currentList.find((current) => itemToCheck["hcode"] === current["hcode"]);
        if (theStoredOne !== undefined) {
          const a1 = JSON.parse(JSON.stringify(itemToCheck));
          const a2 = JSON.parse(JSON.stringify(theStoredOne));

          delete a1.updateBy;
          delete a2.updateBy;
          delete a1["delete"];
          delete a2["delete"];
          delete a1["@created_on"];
          delete a2["@created_on"];
          delete a1["@updated_on"];
          delete a2["@updated_on"];
          delete a2["info_es"];
          delete a2["info_it"];
          delete a2["info_fr"];
          delete a2["info_pt"];

          const isEqual = _.isEqual(a1, a2);
          if (!isEqual) {
            const modifUpdate = { ...itemToCheck };
            modifUpdate.updateBy = user.profile.email;
            toUpdate.push(modifUpdate);
          }
        } else {
          const modifInsertion = { ...itemToCheck };
          modifInsertion.updateBy = user.profile.email;
          toInsert.push(modifInsertion);
        }
      }
    });
    return { toInsert, toUpdate, toDelete };
  };

  const bulkUploadKeysPickups = async (keyPickList) => {
    try {
      const list = await generateTable();
      const { toInsert, toUpdate, toDelete } = difference(list, keyPickList);

      const r = confirm(
        `Se va a proceder a insertar ${toInsert.length}, actualizar ${toUpdate.length} y borrar ${toDelete.length} entradas.`
      );
      if (r) {
        for (const item of [...toInsert, ...toUpdate]) {
          await putKeyPickups(item);
        }
        for (const item of toDelete) {
          await deleteKeyPickups(item);
        }
      }
      return { saved: [...toInsert, ...toUpdate].length, deleted: toDelete.length, errors: [] };
    } catch (e) {
      return { errors: [e.message] };
    }
  };

  const onCSVDownload = async (csvList) => {
    setAlertOpen(true);
    setAlertData({ text: `Se han descargado todas las informaciones de PICKUPs de llaves.` });
  };

  const generateTable = async () => {
    const allList = [];
    for (let i = 0; i < listLength; i += limitForQuery) {
      const keyData = await useKeyPickupList(i, "", limitForQuery);
      const list = keyData.list.map((cv) => {
        cv.delete = false;
        cv.info_es = cv.info["es-ES"];
        cv.info_pt = cv.info["pt-PT"];
        cv.info_fr = cv.info["fr-FR"];
        cv.info_it = cv.info["it-IT"];
        return cv;
      });
      allList.push(...list);
    }
    return allList;
  };

  const checkUniqueElements = (list) => {
    const uniqueElements = new Set(list.map((item) => `${item.hcode}}`));
    return uniqueElements.size === list.length;
  };

  const onCSVUpload = async (csvList) => {
    let mapsList = [];
    let alertMsg = [];

    if (!checkUniqueElements(csvList)) {
      alert("No se puede subir el fichero. Hay elementos duplicados.");
      return;
    }

    try {
      const csvListModified = csvList.map((row) => {
        return {
          hcode: row.hcode,
          pickingType: row.pickingType,
          info: {
            "es-ES": row.pickingType === "24H" ? "" : row.info_es,
            "fr-FR": row.pickingType === "24H" ? "" : row.info_fr,
            "pt-PT": row.pickingType === "24H" ? "" : row.info_pt,
            "it-IT": row.pickingType === "24H" ? "" : row.info_it,
          },
          delete: row.delete,
        };
      });
      csvListModified.forEach((point, i) => {
        const ln = `Linea ${i + 3}`;
        const errorsValidation = keyPickupsValidation(point, ln);
        if (errorsValidation.length > 0) {
          alertMsg.push(errorsValidation);
        }
        mapsList.push(point);
      });
    } catch (eX) {
      console.error(eX);
      alert(eX);
      return;
    }

    if (alertMsg.length > 0) {
      alert(alertMsg.join("\n"));
      return;
    }
    setLoading(true);
    alert("Se ha lanzado la tarea de subida. Tardara unos minutos.");
    const response = await bulkUploadKeysPickups(mapsList);
    setLoading(false);
    setAlertOpen(true);
    updateList();
    setAlertData({ text: `Se han realizado ${response.saved + response.deleted} operaciones con exito.` });
    setForceReloadTable(forceReloadTable + 1);
  };

  const saveRow = async (row) => {
    const nuRow = { ...row };
    nuRow.updateBy = user.profile.email;
    if (nuRow.pickingType === "24H") {
      nuRow.info = {
        "es-ES": "",
        "fr-FR": "",
        "pt-PT": "",
        "it-IT": "",
      };
    }
    const error = keyPickupsValidation(nuRow, "Error");
    if (error.length > 0) {
      setErrorModal({
        open: true,
        errors: error,
      });
    } else {
      const response = await putKeyPickups(nuRow);
      if (response.ok) {
        setAlertOpen(true);
        setAlertData({ text: `Informacion guardada con éxito` });
        updateList(0);
        resetModal();
      } else {
        resetModal();
        setErrorModal({
          open: true,
          errors: [`Error al guardar la informacion de ${row.hcode}: ${response.error}`],
        });
        // resetModal();
      }
      // return response;
    }
  };

  const tableHead = [
    { code: "hcode", name: "Hotel", type: "string" },
    { code: "pickingType", name: "Tipo (24H | PICKUP)", type: "string" },
    { code: "updateBy", name: "Actualizado por", type: "string" },
    { code: "@updated_on", name: "Ultima vez Actualizado", type: "date" },
  ];

  const headActions = [
    "Código de Hotel",
    "Tipo",
    "Informacion ES",
    "Informacion PT",
    "Informacion FR",
    "Informacion IT",
    "Actualizado (No editable)",
    "Modificado por (No editable)",
    "Borrar",
  ];

  const specs = {
    hcode: "",
    pickingType: "",
    info_es: "",
    info_pt: "",
    info_fr: "",
    info_it: "",
    "@updated_on": "",
    updateBy: "",
    delete: "b",
  };

  const actionDelete = async (rowData) => {
    const deleteConfirm = confirm(`¿Está seguro de eliminar la información de ${rowData.hcode}?`);
    if (deleteConfirm) {
      await deleteKeyPickups(rowData);
      updateList();
    }
  };

  const onChangeType = async (type) => {
    const nuForm = { ...modal };
    nuForm.pickingType = type;
    setModal(nuForm);
  };

  const actionModify = async (rowData) => {
    setModal({
      open: true,
      hcode: rowData.hcode,
      pickingType: rowData.pickingType,
      lang: "es-ES",
      info: rowData.info,
    });
  };

  const actions = [
    { name: "Modificar", icon: <MdOutlineModeEditOutline />, func: actionModify },
    { name: "Borrar", icon: <MdClear />, func: actionDelete },
  ];

  return {
    tableHead,
    listLength,
    updateList,
    searchQuery,
    setSearchQuery,
    wordcontrol,
    onCSVUpload,
    headActions,
    specs,
    alertOpen,
    setAlertOpen,
    setAlertData,
    alertData,
    errorModal,
    setErrorModal,
    onCSVDownload,
    generateTable,
    limitTable,
    actions,
    forceReloadTable,
    confirmationDialog,
    setConfirmationDialog,
    acceptDialog,
    setAcceptDialog,
    modal,
    setModal,
    saveRow,
    onChangeType,
    KEY_PICKUP_TYPES,
    resetModal,
    loading,
  };
};

export default KeyPickupListState;
