export const priceInputData = [
  {
    code: "enable",
    parent: "",
    label: "Activo",
    unit: "",
    isCurrency: false,
    show: ["precinct", "product", "ticket", "session", "ticketsession"],
    inFilter: false,
  },
  {
    code: "quota",
    parent: "",
    label: "Cupos",
    unit: "ud.",
    isCurrency: false,
    show: ["product", "ticket", "session", "ticketsession"],
    inFilter: true,
  },
  {
    code: "packageMarkup",
    parent: "",
    label: "Margen",
    unit: "%",
    isCurrency: false,
    show: ["product", "ticket"],
    inFilter: true,
  },
  {
    code: "commission",
    parent: "",
    label: "Comisión",
    unit: "%",
    isCurrency: false,
    show: ["ticket"],
    inFilter: false,
  },
  {
    code: "cost",
    parent: "price",
    label: "Coste",
    unit: "€",
    isCurrency: true,
    show: ["ticket", "session", "ticketsession"],
    inFilter: true,
  },
  {
    code: "sellingPrice",
    parent: "price",
    label: "P.V.P.",
    unit: "€",
    isCurrency: true,
    show: ["ticket", "session", "ticketsession"],
    inFilter: true,
  },
];

export function getChannelAsQueryParms(channel, fieldParent) {
  const channelParam = { [`${fieldParent}.provider`]: channel.provider };
  if (channel.merchant !== undefined) {
    channelParam[`${fieldParent}.merchant`] = channel.merchant;
  }
  return channelParam;
}

export function numberListBetweenTwo(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export function getDayID(type, code, dayDate) {
  return `${type}_${code}_${dayDate.date}`;
}

export function emptyDatabaseCalendar({
  type,
  precinctCode,
  productCode,
  ticketId,
  sessionId,
  year,
  channel,
}) {
  let calendarDB = {};
  switch (type) {
    case "precinct":
      calendarDB = { year, precinctCode, calendar: [] };
      break;
    case "product":
      calendarDB = { precinctCode, productCode, year, channel, calendar: [] };
      break;
    case "ticket":
      calendarDB = { ticketId, productCode, precinctCode, year, channel, calendar: [] };
      break;
    case "session":
      calendarDB = { sessionId, productCode, precinctCode, year, channel, calendar: [] };
      break;
    default:
      break;
  }

  return calendarDB;
}

export function completeCalendarMissingDays(calendarFromRequest, dayListOfYear, type) {
  const calendarOfRequest = calendarFromRequest.calendar || [];

  const currentCalendar = [...calendarOfRequest].reduce((a, d) => ({ ...a, [d.date]: d }), {});

  const completeCalendar = dayListOfYear.reduce((list, date) => {
    let thisDayData = { date, enable: type === "precinct" };
    if (currentCalendar[date] !== undefined) {
      thisDayData = { ...currentCalendar[date] };
    }

    return { ...list, [date]: thisDayData };
  }, {});

  return { ...calendarFromRequest, calendar: Object.values(completeCalendar) };
}

export function getCompleteProductsObject({ products, tickets }) {
  return products.map((product) => ({
    precinctCode: product.precinctCode,
    productCode: product.code,
    tickets: tickets
      .filter((t) => t.productCode === product.code)
      .map((ticket) => ({ ticketId: ticket.id, alias: ticket.alias })),
  }));
}

export function getTypeOfCsvRow(row) {
  if (row.precinctCode && row.productCode && row.ticketId) {
    return { type: "ticket", code: "ticketId" };
  }
  if (row.precinctCode && row.productCode) {
    return { type: "product", code: "productCode" };
  }
  if (row.precinctCode) {
    return { type: "precinct", code: "precinctCode" };
  }

  throw new Error("No type identified");
}
