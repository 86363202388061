import React from "react";
import { Grid, FormControl, InputLabel, MenuItem, Select, TextareaAutosize } from "@material-ui/core";
import FxSwitch from "@atoms/Switches/FxSwitch";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import { PRODUCT_CONFIGURATION_TYPES } from "./constants";

function ProductConfigurationForm({
  productConfiguration,
  productsWithoutConfiguration,
  onChangeProductConfiguration,
  deleteProductConfiguration,
}) {
  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={3}>
        <FormControl variant="standard">
          <InputLabel id="demo-simple-select-standard-label">{"Producto"}</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            label={"Producto"}
            value={productConfiguration.productCode ?? "no lo encuentro"}
            onChange={(e) => {
              const newProductConfiguration = { ...productConfiguration };
              newProductConfiguration.productCode = e.target.value;
              onChangeProductConfiguration(newProductConfiguration);
            }}
          >
            {[{ code: productConfiguration.productCode }, ...productsWithoutConfiguration].map(
              (product, i) => (
                <MenuItem value={product.code} key={`product${i}`}>
                  {product.code}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FxSwitch
          labels={["Activado", "Desactivado"]}
          status={productConfiguration.status || false}
          onChange={(nVal) => {
            const newProductConfiguration = { ...productConfiguration };
            newProductConfiguration.status = nVal;
            onChangeProductConfiguration(newProductConfiguration);
          }}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row" justify={"space-between"} spacing={2}>
          <Grid style={{ marginTop: "10px" }} item>
            <ButtonDelete
              size="small"
              key={"btW"}
              onClick={() => {
                deleteProductConfiguration();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard">
          <InputLabel id="demo-simple-select-standard-label">{"Bloque de configuración"}</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            label={"Bloque de configuración"}
            value={productConfiguration.type ?? ""}
            onChange={(e) => {
              const newProductConfiguration = { ...productConfiguration };
              newProductConfiguration.type = e.target.value;
              onChangeProductConfiguration(newProductConfiguration);
            }}
          >
            {Object.keys(PRODUCT_CONFIGURATION_TYPES).map((type, i) => (
              <MenuItem value={type} key={`configurations${i}`}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {productConfiguration.type === PRODUCT_CONFIGURATION_TYPES.BLOCK_SKI_STATION && (
        <Grid item xs={12}>
          <InputLabel id="demo-simple-select-standard-label">{"Código de configuración"}</InputLabel>
          <TextareaAutosize
            variant="outlined"
            placeholder="Código de configuración"
            // multiline={true}
            value={productConfiguration.jsonSki}
            onChange={(text) => {
              const newProductConfiguration = { ...productConfiguration };
              newProductConfiguration.jsonSki = text.currentTarget.value;
              onChangeProductConfiguration(newProductConfiguration);
            }}
            aria-label="maximum weight"
            rowsMin={8}
            rowsMax={20}
            style={{ width: "100%" }}
          />
        </Grid>
      )}
    </Grid>
  );
}

ProductConfigurationForm.propTypes = {};

export default ProductConfigurationForm;
