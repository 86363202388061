import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { dateToSpanishStr } from "@lib/helpers/dates";
import CustomModal from "@molecules/Modal/CustomModal";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import FxSwitch from "@atoms/Switches/FxSwitch";
import InputNumber from "@atoms/Inputs/InputNumber";
import { priceInputData } from "../utils";

function CalendarDayEditingModal({ type, dataForDay, open, fieldExclusions, onSend, onClose }) {
  const [dataForm, setDataForm] = useState(dataForDay);
  const [changes, setChanges] = useState(false);

  useEffect(() => {
    const isEqual = _.isEqual(dataForm, dataForDay);
    setChanges(isEqual);
  }, [dataForDay, dataForm]);

  function changeValue(field, newValue) {
    const nuDataForm = { ...dataForm };
    nuDataForm[field] = newValue;
    setDataForm(nuDataForm);
  }

  function executeDayChange() {
    onSend(dataForm);
    onClose();
  }

  function onChange(newValue, thisInput) {
    const theValue = parseFloat(newValue, 10);

    if (thisInput.parent === "") {
      return changeValue(thisInput.code, theValue);
    }
    return changeValue(thisInput.parent, {
      ...dataForm[thisInput.parent],
      [thisInput.code]: theValue,
    });
  }

  return (
    <CustomModal
      loading={false}
      title={
        <>
          <span>
            Modifica el día <b>{dateToSpanishStr(new Date(dataForDay.date))}</b>
          </span>
          <FxSwitch
            labels={["Abierto", "Cerrado"]}
            status={dataForm.enable}
            onChange={(newValue) => changeValue("enable", newValue)}
          />
        </>
      }
      open={open}
      bigModal={false}
      handleClose={onClose}
    >
      <Grid container spacing={2}>
        {priceInputData
          .filter(
            (ip) =>
              ip.show.includes(type) &&
              ip.code !== "enable" &&
              (fieldExclusions[ip.code] === undefined ||
                (fieldExclusions[ip.code] !== undefined && fieldExclusions[ip.code]))
          )
          .map((thisInput) => {
            const key = `input${thisInput.code}${dataForDay.date}`;

            let newCompletData = { ...dataForm };
            delete newCompletData.type;

            if (thisInput.parent !== "") {
              if (newCompletData[thisInput.parent] === undefined) {
                // return <Fragment key={key}></Fragment>;
                newCompletData = { [thisInput.parent]: {} };
              }
              if (newCompletData[thisInput.parent][thisInput.code] === undefined) {
                newCompletData[thisInput.parent][thisInput.code] = 0;
              }
            }

            return (
              <Grid item xs={6} key={key}>
                <InputNumber
                  isCurrency={thisInput.isCurrency}
                  size="small"
                  label={`${thisInput.label} (${thisInput.unit})`}
                  value={
                    (thisInput.parent !== ""
                      ? newCompletData[thisInput.parent][thisInput.code]
                      : newCompletData[thisInput.code]) || 0
                  }
                  onChange={(nv) => onChange(nv, thisInput)}
                />
              </Grid>
            );
          })}

        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <ButtonCustom onClick={onClose}>{"Cerrar"} </ButtonCustom>
            <ButtonCustom disabled={changes} onClick={executeDayChange} color="primary">
              {"Cambiar datos"}
            </ButtonCustom>
          </div>
        </Grid>
      </Grid>
    </CustomModal>
  );
}

export default CalendarDayEditingModal;
