import React from "react";
import { useHistory } from "react-router-dom";
// Atoms
import ButtonSave from "@atoms/Buttons/ButtonSave";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import ButtonReset from "@atoms/Buttons/ButtonReset";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
// Molecules
import { ThreeDotMenu, useThreeDotMenu } from "@molecules/Menus/ThreeDotMenu";
//Csv
import MenuItemUploadCSV from "../shared/MenuItemUploadCSV";
import MenuItemDownloadCSV from "../shared/MenuItemDownloadCSV";
// Material UI
import { csvHead, csvSpecs, wordcontrol, filename } from "./constants";

import bulkUploadProductChannels from "./bulkUploadProductChannels";

function ProductChannelsMenu({
  searchData,
  precinctCode,
  productChannels,
  onOpenModal,
  setUploadResult,
  saveProductsChannels,
  resetProductsChannels,
  addNewProductsChannel,
}) {
  const itemsName = "product_channels";
  const { anchorEl, onOpenMenu, handleClose } = useThreeDotMenu();
  const history = useHistory();

  function goBackToPrecinct() {
    const ticketingSectionPathname = history.location.pathname.split("/");
    ticketingSectionPathname.pop();
    ticketingSectionPathname.pop();
    history.push(`${ticketingSectionPathname.join("/")}`);
  }

  const onUploadEnded = (resultUpload) => {
    let status = "success";
    if (resultUpload.errors.length > 0) {
      status = resultUpload.saved && resultUpload.saved > 0 ? "warning" : "error";
    }
    setUploadResult({ status, ...resultUpload });
  };

  function onUploadCSVEnded(result) {
    handleClose();
    onUploadEnded(result);
    searchData();
  }

  function generateProductChannelCSV() {
    return productChannels.map((productChannel) => {
      const nuPC = { ...productChannel };
      return nuPC;
    });
  }

  return (
    <ContainerFenix>
      <SectionTitle
        title={"Canales Producto "}
        backFunction={goBackToPrecinct}
        buttons={[
          <ButtonAdd
          size="small"
            key={"btA"}
            onClick={addNewProductsChannel}
          >
            Añadir
          </ButtonAdd>,
          <ButtonReset
            size="small"
            key={"btW"}
            onClick={resetProductsChannels}
          >Restablecer
          </ButtonReset>,
          <ButtonSave
          size="small"
            key={"btG"}
            onClick={saveProductsChannels}
          >
            Guardar
          </ButtonSave>,
          <ThreeDotMenu key={0} style={{ minWidth: "0" }} {...{ anchorEl, onOpenMenu, handleClose }}>
            <MenuItemUploadCSV
              itemsName={itemsName}
              wordcontrol={wordcontrol}
              filename={`${precinctCode}_${filename}`}
              buttonLabel={"Subir Canales"}
              specs={csvSpecs}
              uploadFunction={bulkUploadProductChannels}
              onUploadEnded={onUploadCSVEnded}
            />
            <MenuItemDownloadCSV
              wordcontrol={wordcontrol}
              filename={`${precinctCode}_${filename}`}
              head={csvHead}
              specs={csvSpecs}
              generateTable={generateProductChannelCSV} //productChannels
              buttonLabel={"Descargar Canales"}
              onDownloadEnded={handleClose}
            />
          </ThreeDotMenu>,
        ]}
      />
    </ContainerFenix>
  );
}

ProductChannelsMenu.propTypes = {};

export default ProductChannelsMenu;
