import React, { useCallback, useState, useEffect, Fragment } from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FxSwitchAsync from "@atoms/Switches/FxSwitchAsync";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import { userAuthorized } from "../../../entries/fenix/auth/FenixAuthContext";
import { getCalls, getAgent, getAgentsInQueue, upsertAgent, useAgents } from "./hooks/useAgentPanel";

const AgentPanel = () => {
  const user = userAuthorized();
  const { loadingCalls, dataCalls } = getCalls();
  const [calls, setCalls] = useState(dataCalls);
  const { agentLoading, dataAgent } = getAgent(user.profile.email);
  const [agent, setAgent] = useState(dataAgent);
  const [agents, setAgents] = useState({ loadingAgents: true, listAgents: [] });
  const { queueLoading, dataQueue } = getAgentsInQueue();

  useEffect(() => {
    setAgent(dataAgent);
  }, [agentLoading, dataAgent]);

  useEffect(() => {
    setCalls(dataCalls);
  }, [dataCalls, loadingCalls]);

  useEffect(() => {
    setCalls(dataCalls);
  }, [dataCalls, queueLoading]);

  const handleFetchAgents = useCallback(async () => {
    const result = await useAgents();
    const nuAgent = result.listAgents.reduce((acc, cv) => {
      if (cv.idAgent === user.profile.email) {
        acc = cv;
      }
      return acc;
    }, {});
    setAgent(nuAgent);
    setAgents(result);
  }, [user.profile.email]);

  useEffect(() => {
    handleFetchAgents();
  }, [handleFetchAgents]);

  const tableHead = [
    { code: "idCall", name: "Id", type: "string" },
    { code: "phone", name: "Telefono", type: "string" },
    { code: "tld", name: "Mercado", type: "market" },
    {
      code: "type",
      name: "Tipo",
      type: "label",
      filterable: false,
      translation: { A: { name: "Emergencia", colour: "red" }, B: { name: "Normal" } },
    },
    {
      code: "created_on",
      name: "Fecha",
      type: "date",
      format: "DD/MM/YYYY HH:mm:ss",
    },
  ];

  const loginOrlogoutAgent = async (agent) => {
    const nuAgent = agent;
    nuAgent.logon = !agent.logon;
    await upsertAgent(agent);
    handleFetchAgents();
    return { ok: true };
  };

  const loginOrlogoutSwitchAgent = async (nuValue) => {
    dataAgent.logon = nuValue;
    await upsertAgent(dataAgent);
    handleFetchAgents();
  };

  const tableHeadAgents = [
    { code: "idAgent", name: "Email", type: "string" },
    { code: "phone", name: "Telefono del agente", type: "string" },
    {
      code: "rolAgentName",
      name: "Rol",
      type: "tags",
    },
    {
      code: "logon",
      name: "Logeado",
      type: "boolean",
      text: { y: "Si", n: "No" },
      filterable: false,
    },
  ];

  const tableHeadSuper = [
    { code: "idAgent", name: "Email", type: "string" },
    { code: "phone", name: "Telefono del agente", type: "string" },
    {
      code: "rolAgentName",
      name: "Rol",
      type: "tags",
    },
    {
      code: "logon",
      name: "Logeado",
      type: "boolean",
      func: loginOrlogoutAgent,
    },
  ];

  const actions = [
    // { name: "Borrar Imágen", icon: <MdClear />, func: deleteFile },
    // { name: "Añadir/editar Tags en Imágen", icon: <MdLabel />, func: tagAssignation },
    // { name: "Asignar Imágen a Hotel", icon: <MdHotel />, func: accommodationAssignation },
    // { name: "Descargar Imágen", icon: <MdFileDownload />, func: filesDownload },
  ];

  const actionsAgents = [
    // { name: "Habilitar/Deshabilitar Agente", icon: <ExitToAppIcon />, func: loginOrlogoutAgent },
  ];

  const enableEmergencyCallForAgent = () => {
    return !dataQueue.includes(dataAgent.idAgent)
      ? ["No estas dado de alta para recibir llamadas de emergencias."]
      : [
          <FxSwitchAsync
            labels={["Recibir llamadas de emergencias ON", "Recibir llamadas de emergencias OFF"]}
            status={agent.logon}
            onChange={(nuValue) => {
              loginOrlogoutSwitchAgent(nuValue);
            }}
          />,
        ];
  };

  return (
    <Fragment>
      <CardTitle
        title={"Panel de agente"}
        info={[
          "Al activar las llamadas de emergencia, se le habilitara para recibir llamadas de clientes ",
          `Actualmente hay ${agents.listAgents.reduce((acc, cv) => {
            if (cv.logon) acc += 1;
            return acc;
          }, 0)} agentes habilitados para recibir llamadas`,
        ]}
        buttons={enableEmergencyCallForAgent()}
        status={dataAgent.logon}
        onChange={(nuValue) => {
          upsertAgentAvailable(user.profile.email);
          setEmergencyCalls(nuValue);
        }}
      />
      <AccordionItem open={true} title={<div className="card-title h5">{"Registros de llamadas"}</div>}>
        <FenixTable
          loading={loadingCalls}
          tableHead={tableHead}
          tableContent={calls}
          actions={actions}
          showInputFilter={true}
          maxNumberPerPages={15}
          pagination={true}
        />
      </AccordionItem>
      <AccordionItem title={<div className="card-title h5">{`Listado de agentes `}</div>}>
        <FenixTable
          loading={agents.loadingAgents}
          tableHead={dataAgent.rolAgent === "super" ? tableHeadSuper : tableHeadAgents}
          tableContent={agents.listAgents}
          actions={actionsAgents}
          showInputFilter={true}
          maxNumberPerPages={10}
          pagination={false}
        />
      </AccordionItem>
    </Fragment>
  );
};

export default AgentPanel;
