import axios from "axios";

const API_CREATE_STORY_URL = "/api/v1/marketing-bc/social-network/create-story"

const createStoryImage = async (idOffer, setLoadingStoryImg) => {
    let response = {};
    setLoadingStoryImg(true);
    const urlImageRequest = `${API_CREATE_STORY_URL}/${idOffer}`
    try {
        response = await axios.post(`${urlImageRequest}`);
        response.ok = true;
        window.open(response.data, '_blank');
    } catch (error) {
        setLoadingStoryImg(false);
        console.log(error);
    }
    return response;
}



export default createStoryImage;