import React from "react";
import { Route } from "react-router-dom";

import PrecinctChannelsPage from "@src/5-pages/Ticketing/PrecinctChannelsPage";
import ProductChannelsPage from "@src/5-pages/Ticketing/ProductChannelsPage";
import ProductConfigurationsPage from "@src/5-pages/Ticketing/ProductConfigurationsPage";
import TicketingPage from "@src/5-pages/Ticketing/TicketingPage";
import PrecinctsPage from "@src/5-pages/Ticketing/PrecinctsPage";
import PrecinctCalendarPage from "@src/5-pages/Ticketing/PrecinctCalendarPage";
import PrecinctCalendarUpdateRulesPage from "@src/5-pages/Ticketing/PrecinctCalendarUpdateRulesPage";
import HotelbedsApitudeApiPage from "@src/5-pages/Ticketing/ApiSearchers/HotelbedsApitudeApiPage";
import CaldeaApiPage from "@src/5-pages/Ticketing/ApiSearchers/CaldeaApiPage";

function RoutesTicketing({ match }) {
  const mainUrlTicketing = `${match.path}`;

  return (
    <>
      <Route exact path={`${mainUrlTicketing}/precincts`} component={PrecinctsPage} />
      <Route exact path={`${mainUrlTicketing}/precincts/:precinctCode`} component={TicketingPage} />
      <Route
        exact
        path={`${mainUrlTicketing}/precincts/:precinctCode/channels`}
        component={PrecinctChannelsPage}
      />
      <Route
        exact
        path={`${mainUrlTicketing}/precincts/:precinctCode/calendar/:tabName`}
        component={PrecinctCalendarPage}
      />
      <Route
        exact
        path={`${mainUrlTicketing}/precincts/:precinctCode/calendar-update-rules`}
        component={PrecinctCalendarUpdateRulesPage}
      />
      <Route
        exact
        path={`${mainUrlTicketing}/precincts/:precinctCode/products/channels`}
        component={ProductChannelsPage}
      />
      <Route
        exact
        path={`${mainUrlTicketing}/precincts/:precinctCode/products/configurations`}
        component={ProductConfigurationsPage}
        channels
      />
      {/* Buscadores de api */}
      <Route exact path={`${mainUrlTicketing}/api-searcher/hotelbeds`} component={HotelbedsApitudeApiPage} />
      <Route exact path={`${mainUrlTicketing}/api-searcher/caldea`} component={CaldeaApiPage} />
    </>
  );
}

export default RoutesTicketing;
