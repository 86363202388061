import React from "react";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { ThreeDotMenu, useThreeDotMenu } from "@molecules/Menus/ThreeDotMenu";
import bulkUploadTickets from "./bulkUploadSessions";
import { csvHead, csvSpecs, wordcontrol, filename } from "./constants";
import MenuItemUploadCSV from "../shared/MenuItemUploadCSV";
import MenuItemDownloadCSV from "../shared/MenuItemDownloadCSV";

function SessionsSectionMenu({ precinctCode, ticketList, onUploadEnded }) {
  const { anchorEl, onOpenMenu, handleClose } = useThreeDotMenu();

  const onUploadCsvEnded = (results) => {
    handleClose();
    onUploadEnded(results);
  };

  const itemsName = "sesiones";

  function generateCsvFromList() {
    return ticketList.map((ticket) => ({
      ...ticket,
    }));
  }

  return (
    <>
      <SectionTitle
        style={{ width: "100%", marginTop: "0" }}
        title={"Sesiones"}
        buttons={[
          <ThreeDotMenu key={0} style={{ minWidth: "0" }} {...{ anchorEl, onOpenMenu, handleClose }}>
            <MenuItemUploadCSV
              itemsName={itemsName}
              wordcontrol={wordcontrol}
              filename={`${precinctCode}_${filename}`}
              buttonLabel={`Subir ${itemsName}`}
              specs={csvSpecs}
              uploadFunction={bulkUploadTickets}
              onUploadEnded={onUploadCsvEnded}
            />
            <MenuItemDownloadCSV
              wordcontrol={wordcontrol}
              filename={`${precinctCode}_${filename}`}
              head={csvHead}
              specs={csvSpecs}
              generateTable={generateCsvFromList}
              buttonLabel={`Descargar ${itemsName}`}
              onDownloadEnded={handleClose}
            />
          </ThreeDotMenu>,
        ]}
      />
    </>
  );
}

SessionsSectionMenu.propTypes = {};

export default SessionsSectionMenu;
