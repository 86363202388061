import React, { useState, useEffect } from "react";
import moment from "moment";
import { MdClear } from "react-icons/md";
import { useOfferTags, putTags, deleteTags, getTagsById } from "../../hooks/useOfferFetch";
import { csvTrated } from "../../utils/offersUtils";
const OfferTagsState = () => {
  // Market, type & wordcontrol
  const VERSION = 1;
  const [market, setMarket] = useState("es");
  const [type, setType] = useState("topic");
  const wordcontrol = `OFFERTAGS_${type}_${market}_V${VERSION}`;

  // Errors in CSV
  const [errorState, setErrors] = useState({ show: false, list: [] });

  // State for new tag
  const [showInputForNewTag, setShowInput] = useState(false);
  const [newTag, setNewTag] = useState({
    type: "topic",
    tag: "",
    enable: true,
    order: 0,
  });
  const onChangeMarket = (nuMarket) => {
    // setErrors(nuMarket);
  };
  const resetNewTag = () => {
    setNewTag({
      type: "topic",
      tag: "",
      enable: true,
      order: 0,
    });
  };

  const [offerTags, setOfferTags] = useState({
    loadingList: true,
    list: [],
    marketApplied: null,
  });

  const handleUseOfferTags = async () => {
    setOfferTags(await useOfferTags(market));
  };

  useEffect(() => {
    handleUseOfferTags();
  }, [market, type]);

  const actionDeleteOfferTag = async (rowData) => {
    await deleteTags([rowData]);
    handleUseOfferTags();
  };

  const parseFormTags = (tags) => {
    return tags.map((cv) => {
      const nuCv = { ...cv };
      if (typeof nuCv.enable === "boolean") nuCv.enable = nuCv.enable ? 1 : 0;
      return nuCv;
    });
  };

  const tagFormer = (tags) => {
    return tags.reduce((acc, cv, i) => {
      acc.push({ tag: cv, enable: true });
      return acc;
    }, []);
  };

  const saveTags = async (type, tags) => {
    const responsePutTags = await putTags(`${market}-${type}`, {
      idOfferTag: `${market}-${type}`,
      type: type,
      market: market,
      tags: [...tagFormer(tags)],
    });
    handleUseOfferTags();
    return responsePutTags;
  };

  // const saveCSV = async (tags) => {
  //   const response = csvTrated(tags);
  //   console.log(response);
  //   if (response.listErrors.length > 0) {
  //     setErrors({ show: true, list: response.listErrors });
  //   } else {
  //     console.log("ELSE");
  //     tags = response.tags;
  //     if (response.deleted.length > 0) await deleteTags(response.deleted);
  //     if (response.upupdated.length > 0) await putTags(response.upupdated);
  //     handleUseOfferTags();
  //   }
  // };

  const tagTypes = {
    topic: "Temas",
    dates: "Fechas",
    location: "Destinos",
  };
  // const head = [
  //   "Codigo",
  //   "Etiqueta",
  //   "Tipo",
  //   "Mercado",
  //   "Orden (en el filtro)",
  //   "Activado (0|1)",
  //   "Fecha de expiracion (DD/MM/YYYY HH:mm:ss)",
  //   "Borrado (escribir la palabra ELIMINAR)",
  // ];
  // const specs = {
  //   idOfferTag: "",
  //   name: "",
  //   type: "",
  //   lang: "",
  //   order: "d",
  //   enable: "",
  //   expirationDate: "",
  //   toDelete: "",
  // };
  // const actions = [
  //   // { name: "Editar", icon: <MdEdit />, func: actionEditTemplate },
  //   // { name: "Duplicar", icon: <MdControlPointDuplicate />, func: actionDuplicate },
  //   { name: "Borrar", icon: <MdClear />, func: actionDeleteOfferTag },
  // ];

  const handleAvailability = async (tag) => {
    const nuTag = { ...tag };
    nuTag.enable = !nuTag.enable;
    return saveTags([nuTag]);
  };

  // const tableHead = [
  //   { code: "idOfferTag", name: "Codigo", type: "string" },
  //   { code: "name", name: "Etiqueta", type: "string" },
  //   { code: "order", name: "Orden", type: "numeric" },
  //   // { code: "type", name: "Tipo", type: "string" },
  //   { code: "expirationDate", name: "Fecha Fin", type: "string" },
  //   // { code: "expirationDate", name: "Caducidad", type: "date", format: "DD/MM/YYYY HH:mm:ss" },
  //   {
  //     code: "enable",
  //     name: "Disponibilidad",
  //     type: "boolean",
  //     text: { y: "Habilitado", n: "Deshabilitado" },
  //     func: handleAvailability,
  //   },
  //   // { code: "expirationDate", name: "Caducidad", type: "date", format: "DD/MM/YYYY HH:mm:ss" },
  // ];

  return {
    wordcontrol,
    errorState,
    tagTypes,
    newTag,
    showInputForNewTag,
    offerTags,
    type,
    market,
    onChangeMarket,
    resetNewTag,
    setNewTag,
    setShowInput,
    setErrors,
    setType,
    setMarket,
    saveTags,
  };
};

export default OfferTagsState;
