import React from "react";
import { Grid } from "@material-ui/core";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import CheckoutRequirementsEditor from "@organisms/producto/CheckoutRequirementsEditor";

const CheckoutRequirementsPageEditor = ({}) => {
  return (
    <ContainerFenix>
      <Grid container>
        <Grid item xs={12}>
          <CheckoutRequirementsEditor />
        </Grid>
      </Grid>
    </ContainerFenix>
  );
};

export default CheckoutRequirementsPageEditor;
