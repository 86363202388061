/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import { fetchProducts } from "../../Products/useProducts";
import { fetchTickets } from "../../Tickets/useTickets";
import {
  fetchCalendarUpdateConfigurations,
  saveCalendarUpdateConfigurations,
} from "./daoCalendarUpdateConfiguration";
import { fetchCalendarUpdateRules } from "../../CalendarUpdateRules/daoCalendarUpdateRules";
import ProductRuleConfiguration from "./ProductRuleConfiguration";

function CalendarUpdateConfiguration({ precinctCode, channel }) {
  const [loadingConfigurations, setLoadingConfigurations] = useState(false);
  const [productsWithRules, setProdcustWithRules] = useState([]);
  const [rulesOfPrecinct, setRulesOfPrecinct] = useState([]);
  const [ticketsOfPrecinct, setTicketsOfPrecinct] = useState([]);

  const fetchProductsCalendarUpdate = useCallback(async () => {
    setLoadingConfigurations(true);
    try {
      const [products, tickets] = await Promise.all([
        fetchProducts({ params: { precinctCode } }),
        fetchTickets({ params: { precinctCode } }),
      ]);

      const ticketsKV = tickets.reduce((kv, ticket) => {
        const { id, alias, productCode } = ticket;
        const kv2 = { ...kv };
        if (kv2[productCode] === undefined) {
          kv2[productCode] = [];
        }
        kv2[productCode].push({ id, alias, productCode });
        return kv2;
      }, {});
      setTicketsOfPrecinct(ticketsKV);

      const { data: rules } = await fetchCalendarUpdateRules(precinctCode);
      setRulesOfPrecinct(rules);

      const { data: calendarUpdateProducts } = await fetchCalendarUpdateConfigurations(precinctCode, channel);

      const pr = products.map((product) => ({
        productCode: product.code,
        enable: false,
        precinctCode,
        channel,
        rules: [],
        ...calendarUpdateProducts.find((up) => up.productCode === product.code),
      }));
      setProdcustWithRules(pr);
    } catch (e) {
      console.error(e);
      // setLoadingCalendar(false);
    }
  }, [channel, precinctCode]);

  useEffect(() => {
    fetchProductsCalendarUpdate();
  }, [fetchProductsCalendarUpdate]);

  async function saveConfigurations() {
    setLoadingConfigurations(true);
    try {
      await saveCalendarUpdateConfigurations(productsWithRules);
      alert("Guardados");
    } catch (e) {
      alert(e.message);
    }
    setLoadingConfigurations(false);
  }

  return (
    <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
          <Grid item>
            <h4>Actualización automática</h4>
          </Grid>
          <Grid item>
            <ButtonSave onClick={saveConfigurations}>Guardar</ButtonSave>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
          {productsWithRules.map((product, index) => (
            <Grid item xs={12} key={`product${product.productCode}`}>
              <TravCard>
                <TravBodyCard>
                  <ProductRuleConfiguration
                    indexProduct={index}
                    {...{ product, productsWithRules, rulesOfPrecinct }}
                    ticketsOfProduct={ticketsOfPrecinct[product.productCode]}
                    onChangeRules={setProdcustWithRules}
                    onChangeRulesOfPrecinct={setRulesOfPrecinct}
                  />
                </TravBodyCard>
              </TravCard>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CalendarUpdateConfiguration;
