import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonDialog } from "./Modal.styles";

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});

function Modal({ show, onClose, children, fullWidth = true, size = "lg", showButton = true }) {
  const [open, setOpen] = useState(show || false);
  const classes = useStyles();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Dialog
      open={open}
      maxWidth={size}
      fullWidth={fullWidth}
      scroll="paper"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogContent>{children}</DialogContent>
      {showButton && (
        <DialogActions>
          <ButtonDialog variant="outlined" color="info" onClick={onClose}>
            Entendido
          </ButtonDialog>
        </DialogActions>
      )}
    </Dialog>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Modal;
