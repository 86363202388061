import React, { Fragment } from "react";
import { Row, Col, Card, Alert } from "react-bootstrap";
import { TextField, MenuItem } from "@material-ui/core";
import { MdWarning } from "react-icons/md";
import SectionTitle from "@atoms/Titles/SectionTitle";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import ErrorModal from "@atoms/Modals/ErrorModal";
import ModalNewBrick from "../components/ModalNewBrick";
import HTMLPreview from "../components/HTMLPreview";
import { brickModes, brickStyles } from "../constants";

function BrickEditorView({
  idPreview,
  form,
  errors,
  openNewModal,
  isEdition,
  isDuplication,
  closeErrorModal,
  executeSelection,
  backToTable,
  onChangeHTML,
  setForm,
  saveBrickTemplate,
}) {
  return (
    <Fragment>
      {/* Errors modal */}
      <ErrorModal show={errors.show} listErrors={errors.list} onClose={closeErrorModal} />

      {/* Execute now Modal */}
      <ModalNewBrick open={openNewModal} type={form.type} executeSelection={executeSelection} />

      <SectionTitle
        title={`Edita un ${form.type} (${isDuplication ? "Duplicado de " : ""}${form.code})`}
        backFunction={backToTable}
        buttons={[
          <ButtonSave key={"btG"} onClick={saveBrickTemplate}>
            Guardar {form.type}
          </ButtonSave>,
        ]}
      />

      {!isEdition && (
        <Alert variant="info">
          El {form.type} <b>{form.code} es NUEVO</b> y será creado al guardar.
        </Alert>
      )}

      {isDuplication && (
        <Alert variant="warning">
          <MdWarning size={60} />
          ESTAS DUPLICANDO EL {form.type.toUpperCase()} <b>{form.code}</b>.
        </Alert>
      )}

      <Card>
        <Card.Body>
          <Row>
            <Col>
              <TextField disabled={true} label="Código" value={form.code} />
            </Col>
            <Col>
              <TextField
                label="Nombre"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
            </Col>
            <Col>
              <TextField select disabled={true} label="Modo" value={form.mode}>
                {brickModes.map((mod, i) => {
                  return (
                    <MenuItem key={`mod${i}`} value={mod.code}>
                      {mod.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Col>
            <Col>
              <TextField
                select
                disabled={true}
                label="Web de destino"
                value={form.destination}
                onChange={(e) => setForm({ ...form, destination: e.target.value })}
              >
                {brickStyles.map((sty, i) => {
                  return (
                    <MenuItem key={`styl${i}`} value={sty.code}>
                      {sty.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <HTMLPreview
        idPreview={idPreview}
        html={form.content.value}
        destination={form.destination}
        onChangeHTML={onChangeHTML}
      />
    </Fragment>
  );
}

export default BrickEditorView;
