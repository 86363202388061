import React, { Fragment, useState, useEffect } from "react";
import SearchInput from "@atoms/Inputs/SearchInput/SearchInput";
import FolderSuggestions from "./components/FolderSuggestions";
import { useSearchInput } from "../../FileManagerContext";

const FolderSearcher = ({ onExecuteSearch }) => {
  const minchar = 3;
  const [{ searchFolderInput, bucket }, setSearchFolderInput] = useSearchInput();
  const [searcherString, setSearcherString] = useState("");
  const [openSuggestions, setOpenSuggestions] = useState(false);

  useEffect(() => {
    setSearcherString(searchFolderInput);
  }, [searchFolderInput]);

  const searchFunction = (withThisString) => {
    if (withThisString !== "" && withThisString.length < minchar) {
      return;
    }

    setSearchFolderInput(withThisString);
    setSearcherString(withThisString);
    setOpenSuggestions(false);
    setSearcherString("");
    onExecuteSearch(withThisString);
  };

  return (
    <Fragment>
      <SearchInput
        buttonInInput={false}
        label={"Busca una carpeta"}
        searcherString={searcherString}
        onChangeInput={(newInput) => {
          setSearcherString(newInput);
          if (newInput.length > minchar) {
            setOpenSuggestions(true);
          }
        }}
        // searchFunction={() => searchFunction(searcherString)}
      />

      {openSuggestions && (
        <FolderSuggestions
          searcherString={searcherString}
          bucket={bucket}
          maxsuggestions={20}
          onSelectOption={(newOption) => searchFunction(newOption)}
        />
      )}
    </Fragment>
  );
};

export default FolderSearcher;
