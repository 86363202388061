import { savePrecinct } from "./usePrecincts";

const savePrecinctList = async (fullList) => {
  let saved = 0;
  const errors = [];
  for (let index = 0; index < fullList.length; index += 1) {
    const precinct = fullList[index];
    try {
      if (precinct.channels === undefined) {
        precinct.channels = [];
      }

      await savePrecinct(precinct);
      saved += 1;
      // console.log("Saved", precinct);
    } catch (e) {
      errors.push(
        `Error en el codigo ${precinct.code}: ${e.response.data.errors
          .reduce((acc, cv) => {
            acc.push(cv.error);
            return acc;
          }, [])
          .join(",")}`
      );
    }
  }

  return { saved, errors };
};

export default savePrecinctList;
