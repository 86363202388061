/* eslint-disable no-use-before-define */
import React, { Fragment } from "react";
import { List, ListItem } from "@material-ui/core";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { priceInputData } from "../utils";
import MassUpdateCell from "./MassUpdateCell";
import { StyDivInteractionButton } from "./CalendarRow.styles";
import { Height } from "@material-ui/icons";

function MassUpdatePrecinctList({
  loading,
  selections,
  onChangeSelectionPrecinct,
  onChangeSelectionProduct,
  onChangeSelectionTicket,
  onChangeSelectionSession,
  isVisible,
  changeProductVisibility,
}) {
  return (
    <List>
      {Object.values(selections).map((precinct, index) => (
        <Fragment key={`precinct${index}`}>
          <ListItem style={{ padding: "0", backgroundColor: "lightgrey" }}>
            <MassUpdateCell
              loading={loading}
              label={precinct.precinctCode}
              selection={precinct}
              priceInputs={[]}
              onChange={(newSelection) => onChangeSelectionPrecinct(newSelection, precinct.precinctCode)}
            />
          </ListItem>
          {Object.values(precinct.products).map((product, indexP) => (
            <ListProductsItem
              key={`product${index}${indexP}`}
              {...{
                loading,
                product,
                onChangeSelectionProduct,
                onChangeSelectionTicket,
                onChangeSelectionSession,
                isVisible,
                changeProductVisibility,
              }}
            />
          ))}
        </Fragment>
      ))}
    </List>
  );
}

function ListProductsItem({
  loading,
  product,
  onChangeSelectionProduct,
  onChangeSelectionTicket,
  onChangeSelectionSession,
  isVisible,
  changeProductVisibility,
}) {
  const visible = isVisible(product.productCode);
  return (
    <>
      <ListItem
        className="updateprecintlabel"
        style={{ alignItems: "stretch", padding: "0", marginBottom: "5px", backgroundColor: "lavender" }}
      >
        <div style={{ backgroundColor: "#fff", minHeight: "min-content", width: "min-content" }}>
          <StyDivInteractionButton
            style={{ paddingTop: "15px", backgroundColor: "grey", minHeight: "58px" }}
            onClick={() => changeProductVisibility(product.productCode)}
          >
            {visible ? <FaEye /> : <FaEyeSlash />}
          </StyDivInteractionButton>
        </div>
        <MassUpdateCell
          loading={loading}
          label={product.productCode}
          selection={product}
          priceInputs={priceInputData.filter((ip) => ip.show.includes("product"))}
          onChange={(newSelection) => onChangeSelectionProduct(newSelection)}
        />
      </ListItem>
      {visible && (
        <>
          {/* Tickets */}
          {product.tickets !== undefined &&
            Object.keys(product.tickets).length > 0 &&
            Object.values(product.tickets).map((ticket, indexT) => {
              return (
                <ListItem
                  className="updateprecintlabel"
                  key={`ticket${product.productCode}${indexT}`}
                  style={{
                    padding: "0",
                    paddingLeft: "36px",
                    borderBottom: "1px solid #ddd",
                    marginTop: "-1px",
                  }}
                >
                  <MassUpdateCell
                    loading={loading}
                    label={ticket.alias}
                    selection={ticket}
                    priceInputs={priceInputData.filter((ip) => ip.show.includes("ticket"))}
                    onChange={(newSelection) =>
                      onChangeSelectionTicket(newSelection, product.precinctCode, product.productCode)
                    }
                  />
                </ListItem>
              );
            })}
          {/* Sessions */}
          {product.sessions !== undefined &&
            Object.keys(product.sessions).length > 0 &&
            Object.values(product.sessions).map((session, indexS) => (
              <ListItem key={`session${product.productCode}${indexS}`} style={{ padding: "0" }}>
                <span style={{ width: "30%" }}>{session.sessionId}</span>
                <MassUpdateCell
                  loading={loading}
                  selection={session}
                  priceInputs={priceInputData.filter((ip) => ip.show.includes("session"))}
                  onChange={(newSelection) =>
                    onChangeSelectionSession(newSelection, product.precinctCode, product.productCode)
                  }
                />
              </ListItem>
            ))}
        </>
      )}
    </>
  );
}

export default MassUpdatePrecinctList;
