import React, { Fragment, useState, useEffect, useRef } from "react";
import { MdHighlightOff, MdHotel } from "react-icons/md";

import { Modal } from "react-bootstrap";
import InputQueryDropdownSearchTwoRequest from "@atoms/Inputs/InputQueryDropdownSearchTwoRequest";

import { Chip } from "@material-ui/core";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import ButtonCancel from "@atoms/Buttons/ButtonCancel";
import _ from "lodash";
import { assignImagesToAccommodation } from "../../../hooks/useAccommodationAssign";
import { addTagToFile } from "../../../hooks/useTag";
import CenterLoader from "@atoms/Loaders/CenterLoader";
import { sanitizeTag } from "../../../shared/sanitize";
import ErrorModal from "@atoms/Modals/ErrorModal";

const HotelAssignmentModal = ({ open, bucket, selectedRows, handleCloseModal = () => {} }) => {
  const [files, setFiles] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [accommodationSelected, setAccommodationSelected] = useState(null);
  const [errors, setErrors] = useState({ show: false, list: [] });

  useEffect(() => {
    const rowsClone = _.cloneDeep(selectedRows);
    setFiles(rowsClone);
  }, [selectedRows]);

  const selectOption = (option) => {
    setAccommodationSelected(option);
  };

  async function executeAssign() {
    if (accommodationSelected === null) {
      setCurrentError("Ningún hotel seleccionado.");
    } else {
      setModalLoading(true);
      const { ok, error } = await assignAccommodationToFiles(accommodationSelected, files, bucket);
      if (!ok || error.length > 0) {
        setErrors({
          show: true,
          list: error,
        });
      }
      setAccommodationSelected(null);
      handleCloseModal({ execution: true, error: error.length > 0 });
      setModalLoading(false);
    }
  }

  return (
    <Fragment>
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => {
          setErrors({ show: false, list: [] });
        }}
      />
      <Modal
        show={open}
        onHide={() => handleCloseModal({ execution: false, error: false })}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Asignar ${files.length} archivo(s) a hotel`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalLoading ? (
            <CenterLoader />
          ) : (
            <Fragment>
              <InputQueryDropdownSearchTwoRequest
                label={"Buscador de alojamientos"}
                getRequestPath={[
                  `/api/v1/accommodation-bc/main/accommodations?code[regex]=%%QUERY%%`,
                  `/api/v1/accommodation-bc/main/accommodations?name[regex]=%%QUERY%%`,
                ]}
                optionFormat={(option) => {
                  return (
                    <div>
                      <b>
                        {option.code} | {option.name}
                      </b>
                    </div>
                  );
                }}
                onSelectOption={(nuValue) => {
                  selectOption(nuValue);
                }}
              />
              <hr />
              Hotel seleccionado:
              {accommodationSelected !== null && (
                <Chip
                  label={`${accommodationSelected.code} - ${accommodationSelected.name}`}
                  colour="#54b2dd"
                  icon={<MdHotel color="white" />}
                  onDelete={() => {
                    setAccommodationSelected(null);
                  }}
                  deleteIcon={<MdHighlightOff />}
                />
              )}
              <hr />
              Archivos seleccionados:
              {files.map((file, ixFile) => {
                return (
                  <div key={`file${ixFile}`}>
                    <h5>
                      <b>{file.name}</b>
                    </h5>
                  </div>
                );
              })}
            </Fragment>
          )}
        </Modal.Body>

        <Modal.Footer>
          {!modalLoading && (
            <div style={{ display: "flex", gap: "5px" }}>
              <ButtonCancel size="small" onClick={() => handleCloseModal({ execution: false, error: false })}>
                Cancelar
              </ButtonCancel>
              <ButtonCustom size="small" color="primary" onClick={executeAssign}>
                Asignar a hotel
              </ButtonCustom>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export async function assignAccommodationToFiles(accommodationSelected, files, bucket) {
  const filesUrls = files.map((obj) => {
    return { url: obj.url, alt: "" };
  });

  let errorList = [];
  const { ok } = await assignImagesToAccommodation(accommodationSelected.code, filesUrls);
  if (!ok) {
    errorList.push(`No se ha podido asignar lo(s) archivo(s) al hotel ${accommodationSelected.name}`);
  }

  const accommodationTag = sanitizeTag(`${accommodationSelected.code}-${accommodationSelected.name}`);
  for (let i = 0; i < files.length; i++) {
    if (files[i].tags.indexOf(accommodationTag) === -1) {
      const { ok } = await addTagToFile(files[i].path, bucket, accommodationTag);
      if (!ok) {
        errorList.push(
          `No se ha podido guardar el tag ${accommodationTag} en el archivo ${files[i].path} - ${bucket}`
        );
      }
    }
  }

  if (errorList.length > 0) {
    return { ok: false, error: errorList };
  }
  return { ok: true, error: [] };
}

export default HotelAssignmentModal;
