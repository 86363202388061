/* eslint-disable no-use-before-define */
import _ from "lodash";
import { returnModelArrayFromContentIdentificator } from "./domainVariables";

/**
 * Compare two different arrays of the same structure and returns an object with the items that should
 * be updated and the ones that are new (inserted)
 * @param {*} currentList
 * @param {*} newList
 * @param {*} withContentFields Compare using the content fields?
 * @param {*} contentFields Object of fields that have content. Key indicates the field and the value indicates
 * either if it is a tld construction ("tld") or a language one ("lang")
 * @returns
 */
const difference = (
  currentList,
  newList,
  withContentFields = false,
  contentFields = {},
  identifierField = "code"
) => {
  const toInsert = [];
  const toUpdate = [];

  newList.forEach((itemToCheck) => {
    const theStoredOne = currentList.find(
      (current) => itemToCheck[identifierField] === current[identifierField]
    );

    if (theStoredOne !== undefined) {
      const a1 = JSON.parse(JSON.stringify(itemToCheck));
      const a2 = JSON.parse(JSON.stringify(theStoredOne));

      if (!withContentFields) {
        deleteContentFields(contentFields, a1);
        deleteContentFields(contentFields, a2);
      }

      const isEqual = _.isEqual(a1, a2);

      if (!isEqual) {
        const modifUpdate = { ...itemToCheck };
        if (!withContentFields) {
          completeContentFields(contentFields, modifUpdate, theStoredOne);
        }
        toUpdate.push(modifUpdate);
      }
    } else {
      const modifInsertion = { ...itemToCheck };
      completeContentFields(contentFields, modifInsertion, itemToCheck);
      toInsert.push(modifInsertion);
    }
  });

  return { toInsert, toUpdate };
};

function deleteContentFields(contentFields, item) {
  Object.keys(contentFields).forEach((field) => {
    if (typeof contentFields[field] === "string") {
      delete item[field];
    } else {
      deleteContentFields(contentFields[field], item[field]);
    }
  });
}

function completeContentFields(contentFields, modifInsertion, itemToCheck) {
  Object.keys(contentFields).forEach((field) => {
    if (modifInsertion[field] === undefined) {
      modifInsertion[field] = {};
    }
    if (typeof contentFields[field] === "string") {
      const modelArray = returnModelArrayFromContentIdentificator(contentFields[field]);

      if (modelArray.length === 0) {
        modifInsertion[field] = itemToCheck[field];
      } else {
        modifInsertion[field] = modelArray.reduce(
          (accu, item) => ({ ...accu, [item]: Object.values(itemToCheck[field])[0] }),
          {}
        );
      }
    } else {
      completeContentFields(contentFields[field], modifInsertion[field], itemToCheck[field]);
    }
  });
}

export default difference;
