import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
// Atoms - Feedback
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ErrorModal from "@atoms/Modals/ErrorModal";
// Components-Tickets
import TicketsSection from "../Tickets/TicketsSection";
// Components-Products
import ProductsSection from "../Products/ProductsSection";
// Components-Sessions
import SessionsSection from "../Sessions/SessionsSection";
// Components-Precints
import { fetchPrecinct, fetchPrecinctChannels, savePrecinct } from "../Precincts/usePrecincts";
import PrecinctSection from "../Precincts/PrecinctSection";
import ChannelTable from "./ChannelTable";
import UploadResultAlert from "../shared/UploadResultAlert";
import TicketingSectionTitle from "./TicketingSectionTitle";
import TicketingSectionCalendarBlock from "./TicketingSectionCalendarBlock";

function TicketingSection({ precinctCode }) {
  const history = useHistory();
  const [uploadResult, setUploadResult] = useState();
  const [loadingPrecinct, setLoadingPrecinct] = useState(true);
  const [precinct, setPrecinct] = useState({});
  const [formPrecinct, setFormPrecinct] = useState({ enable: false, form: { ...precinct } });
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });
  const searchPrecint = useCallback(async () => {
    setLoadingPrecinct(true);
    const precinctFound = await fetchPrecinct(precinctCode);
    const precinctChannelsFound = await fetchPrecinctChannels(precinctCode);
    setPrecinct({ ...precinctFound, channels: precinctChannelsFound });
    setLoadingPrecinct(false);
  }, [precinctCode]);

  useEffect(() => {
    searchPrecint();
  }, [precinctCode, searchPrecint, uploadResult]);

  useEffect(() => {
    setFormPrecinct({ enable: false, form: { ...precinct } });
  }, [precinct, precinctCode]);

  const onUploadEnded = (resultUpload) => {
    let status = "success";
    if (resultUpload.errors.length > 0) {
      status = resultUpload.saved && resultUpload.saved > 0 ? "warning" : "error";
    }
    setUploadResult({ status, ...resultUpload });
  };

  const savePrecinctHandler = async (newPrecinct) => {
    const svErrors = [];
    try {
      await savePrecinct(newPrecinct);
    } catch (e) {
      svErrors.push(`Error en el precinct ${precinctCode}: ${e.message}`);
    }

    if (svErrors.length > 0) {
      setErrors({
        show: true,
        list: [`Error al guardar el recincto`, JSON.stringify(svErrors)],
      });
    } else {
      setAlertOpen(true);
      setAlertText("Se han guardado los correctamente los cambios");
    }
    searchPrecint();
  };

  function goBackToPrecincts() {
    const ticketingSectionPathname = history.location.pathname.split("/");
    ticketingSectionPathname.pop();
    history.push(`${ticketingSectionPathname.join("/")}`);
  }

  function handlePrecinct(newPrecinct) {
    setFormPrecinct(newPrecinct);
  }

  return (
    <Fragment>
      {uploadResult && <UploadResultAlert uploadResult={uploadResult} />}
      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={() => {
          confirmationDialog.onConfirm();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
        onDeny={() => {
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
      <TicketingSectionTitle {...{ precinct, precinctCode, goBackToPrecincts, onUploadEnded }} />
      <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
        <Grid item xs={7}>
          <PrecinctSection
            precinct={precinct}
            loadingPrecinct={loadingPrecinct}
            formPrecinct={formPrecinct}
            onChange={handlePrecinct}
            onChangeDialog={() => {
              setConfirmationDialog({
                open: true,
                text: `¿Estas seguro que quieres guardar los cambios realizados?`,
                body: "Esta acción no puede revertirse. ¿Seguro que desea guardar los cambios?",
                onConfirm: () => {
                  savePrecinctHandler(formPrecinct.form);
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TicketingSectionCalendarBlock />
          <ChannelTable
            precinctCode={precinctCode}
            tableLoading={loadingPrecinct}
            tableContent={precinct.channels || []}
            onUploadEnded={onUploadEnded}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
        <Grid item xs={12}>
          <ProductsSection precinctCode={precinctCode} />
        </Grid>
        <Grid item xs={8}>
          <TicketsSection precinctCode={precinctCode} />
        </Grid>
        <Grid item xs={4}>
          <SessionsSection precinctCode={precinctCode} />
        </Grid>
      </Grid>
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => {
          setErrors({ show: false, list: [] });
        }}
      />
      <AlertSnackbar show={alertOpen} text={alertText} setOpen={setAlertOpen} />
    </Fragment>
  );
}

TicketingSection.propTypes = {};

export default TicketingSection;
