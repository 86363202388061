import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { GrPowerReset } from "react-icons/gr";

function ButtonReset({ size, variant, disabled, onClick, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="Reset"
      icon={<GrPowerReset />}
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonReset;
