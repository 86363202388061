import React from "react";
import axios from "axios";
import ItemSelectorWithQuery from "@molecules/Forms/ItemSelectorWithQuery";

function SelectorAccommodations({ title, selected, onChange }) {
  const onSearch = async (query) => {
    if (query.length < 3) {
      return [];
    }

    const hcodes = {};
    try {
      const url = "/api/v1/accommodation-bc/main/accommodations?_offset=0&_limit=100&_fields=code,name";
      const promises = [axios.get(`${url}&code[regex]=${query}`), axios.get(`${url}&name[regex]=${query}`)];

      const responses = await Promise.all(promises);
      responses.forEach((res) => {
        if (res.status === 200) {
          res.data.data.forEach((acc) => {
            hcodes[acc.code] = { code: acc.code, text: [acc.code, acc.name].join(" - ") };
          });
        }
      });
    } catch (e) {
      console.error(e);
    }

    return Object.values(hcodes);
  };

  return (
    <ItemSelectorWithQuery
      title={title}
      inputPlaceholder={"Escribe aqui el código o nombre de los alojamientos que quieres añadir"}
      selected={selected}
      onSearch={onSearch}
      onChange={onChange}
    />
  );
}

SelectorAccommodations.defaultProps = { title: "Selecciona alojamientos" };

export default SelectorAccommodations;
