import React, { useState, useEffect } from "react";
import { getHomeContent } from "./hooks/useHomeContent";

function useHomeContent({}) {
  const { loading, homeContent, setHomeContent, market, setMarket, saveHomeContent } = getHomeContent();

  const [tabSelected, setTabSelected] = useState(1);
  const changeTab = (event, newValue) => {
    setTabSelected(newValue);
  };

  const changeMarket = (newMarket) => {
    setMarket(newMarket);
  };

  const changeHomeContent = (nuHomeContent) => {
    setHomeContent(nuHomeContent);
  };

  return {
    loading,
    market,
    homeContent,
    tabSelected,
    changeMarket,
    changeHomeContent,
    changeTab,
    saveHomeContent,
  };
}

export default useHomeContent;
