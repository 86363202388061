import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

import BrickEditorPage from "@organisms/contenido/bricks/BrickEditor";

function BrickEditor({ type }) {
  return (
    <ContainerFenix>
      <BrickEditorPage type={type} />
    </ContainerFenix>
  );
}

export default BrickEditor;
