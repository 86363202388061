/* eslint-disable no-use-before-define */
import { useState } from "react";
import Storage from "@lib/storage/storageClass";

function useFilterInStorage(precinctCode, key, defaultValue) {
  const thisStorage = new Storage("session");

  const inStorage = getItemFromStorage();
  const current = inStorage !== null ? inStorage : defaultValue;
  const [item, setItem] = useState(current);

  function getStorageKey() {
    return [precinctCode, "calendar", key].join(":");
  }

  function getItemFromStorage() {
    const k = getStorageKey();
    const itm = thisStorage.getItem(k);
    if (itm === "") {
      return null;
    }
    return itm;
  }

  function onChange(newOne) {
    thisStorage.setItem(getStorageKey(key), newOne);
    setItem(newOne);
  }

  return [item, onChange];
}

export default useFilterInStorage;
