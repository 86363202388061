import axios from "axios";

const URL = "/api/v1/product-bc/files-manager";

export const getFilesInFolder = (bucket, folderPath) => {
  let thestateFinal = { loading: true, data: [], error: null };

  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { loading: true, data: [], error: null };
      const response = await axios.get(`${URL}/${bucket}/files?pathParent=${cleanPath(folderPath)}`);

      if (response.status === 200) {
        thestate = { loading: false, data: response.data.data, error: null };
      }
      thestateFinal = { ...thestate };
    } catch (error) {
      thestateFinal = { loading: false, data: [], error: error.response.data };
      console.error(error);
    }
    return thestateFinal;
  };

  if (thestateFinal.loading) {
    return (thestateFinal = fetchData(thestateFinal));
  }
  return thestateFinal;
};

export const deleteFileInFolder = async (bucket, filePath) => {
  let thestateFinal = { loading: true, ok: true, error: null };

  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { ok: true, error: null };
      const response = await axios.delete(`${URL}/${bucket}/files/${cleanPath(filePath)}`);
      if (response.status === 200) {
        thestate = { loading: false, ok: true, error: null };
      }
      thestateFinal = { ...thestate };
    } catch (error) {
      thestateFinal = { loading: false, ok: false, error: error.response.data };
      console.error(error);
    }
    return thestateFinal;
  };

  if (thestateFinal.loading) {
    return (thestateFinal = fetchData(thestateFinal));
  }
  return thestateFinal;
};

export const createFileInFolder = async (formData, path, bucket, name) => {
  let response = {};
  try {
    const pathF = path === "/" ? `%2F${name}` : `${path.replaceAll("/", "%2F")}%2F${name}`;
    response = await axios.put(`${URL}/${bucket}/files/${pathF}`, formData);
  } catch (error) {
    response = { status: 400, error: error.response.data };
    console.error(error);
  }
  return response;
};

function cleanPath(path) {
  return path.replaceAll("/", "%2F");
}

export const downloadFile = (bucket, filePath) => {
  const thestateFinal = { loading: true, ok: false, data: {} };

  const fetchData = async () => {
    let newThestateFinal = { ...thestateFinal };

    try {
      let thestate = { loading: true, ok: true, data: {} };
      const path = filePath.split("");
      path.shift();
      const newPath = cleanPath(path.join(""));
      const response = await axios.get(`${URL}/${bucket}/files/download/${newPath}`);
      if (response.status === 200) {
        thestate = { loading: false, ok: true, data: response.data };
      }
      newThestateFinal = { ...thestate };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        newThestateFinal = { loading: false, ok: false, data: {} };
      }
      console.error(error);
    }
    return newThestateFinal;
  };

  if (thestateFinal.loading) {
    return fetchData();
  }
  return thestateFinal;
};
