export const withHook = (hook = null, view, skeleton = null) => {
  return (props) => {
    let viewProps = props;
    if (hook !== null) viewProps = hook(props);
    if (skeleton !== null && (viewProps.loading || props.propsLoading)) {
      return skeleton(viewProps);
    }
    return view(viewProps);
  };
};

export const withHookInBrowser = (hook = null, view, skeleton = null) => {
  return (props) => {
    if (skeleton !== null && (typeof window === "undefined" || props._debugsk)) {
      return skeleton(props);
    }
    let viewProps = props;
    if (hook !== null) viewProps = hook(props);
    if (skeleton !== null && (viewProps.loading || props.propsLoading)) {
      return skeleton(viewProps);
    }
    return view(viewProps);
  };
};

/**
 * Usage:
   import { withHook } from 'shared/helpers/withHook'
   import  useComponentState  from './Component.state'
   import  ComponentView  from './Component.view'
   import  ComponentSkeleton  from './Component.skeleton'

    const Component = withHook(useComponentState, ComponentView [, ComponentSkeleton]);
    export default Component;
 */
