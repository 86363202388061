import React, { Fragment } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

function SelectorDefault({ title, selected, list, onChange }) {
  return (
    <Fragment>
      <InputLabel id="demo-simple-select-helper-label">{title}</InputLabel>
      <Select
        style={{ width: "100%" }}
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        defaultValue={selected ?? ""}
        onChange={onChange}
      >
        {list.map((value, i) => {
          return (
            <MenuItem value={value ?? ""} key={i}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </Fragment>
  );
}

SelectorDefault.defaultProps = {};

export default SelectorDefault;
