import React from "react";
import { Grid } from "@material-ui/core";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import ChannelConfigurationSelector from "../shared/ChannelConfigurationSelector/ChannelConfigurationSelector";
import ProductConfigurationsForm from "./ProductConfigurationsForm";

function ProductConfigurationsCard({
  productConfiguration,
  productsWithoutConfiguration,
  onChange,
  deleteProductConfiguration,
}) {
  return (
    <Grid item xs={12}>
      <AccordionItem title={productConfiguration.productCode}>
        <ProductConfigurationsForm
          productsWithoutConfiguration={productsWithoutConfiguration}
          productConfiguration={productConfiguration}
          onChangeProductConfiguration={(nVal) => onChange(nVal)}
          deleteProductConfiguration={deleteProductConfiguration}
        ></ProductConfigurationsForm>
      </AccordionItem>
    </Grid>
  );
}

ProductConfigurationsCard.propTypes = {};

export default ProductConfigurationsCard;
