import React from "react";
import {  TableCell  } from "@material-ui/core";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import {  FiChevronRight, FiChevronLeft } from "react-icons/fi";
const FenixTablePagination = ({ limit, onPageChangeByOne }) => {
  return (
    <>
      <TableCell align="right" colSpan={13}>
        <ButtonCustom variant="text" disabled={limit.indexI < 1} icon={<FiChevronLeft />} color="primary" onClick={() => onPageChangeByOne("less")}/>
       
        {` Página ${limit.pageActive} de ${limit.pages} `}


        <ButtonCustom
          variant="text"
          disabled={limit.pageActive + 1 > limit.pages}
          color="primary"
          icon={<FiChevronRight/>}
          onClick={() => onPageChangeByOne("more")}
        >
        </ButtonCustom>
      </TableCell>
      {/* <TablePagination
        colSpan={13}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onPageChange={handleChangePage}
        //onRowsPerPageChange={handleChangeRowsPerPage}
        //ActionsComponent={TablePaginationActions}
        onChangePage={handleChangePage}
      /> */}
    </>
  );
};

export default FenixTablePagination;
