import React, { Fragment } from "react";
import { Route, Link } from "react-router-dom";
import NewsletterListPage from "../../5-pages/Marketing/NewsletterThirdPartyListPage";
import NewsletterManagementPage from "../../5-pages/Marketing/NewsletterThirdPartyManagementPage";

// Cross-Selling
import CrossSelling from "./cross-selling/CrossSelling";
import CrossSellingCampaigns from "./cross-selling/CrossSellingCampaigns";
import CrossSellingCampaignsEditor from "./cross-selling/CrossSellingCampaignsEditor";

// Nevasport
import Nevasport from "./nevasport/Nevasport";
import NevasportOffers from "./nevasport/NevasportOffers";

//Afiliados
import AffiliateListPage from "../../5-pages/Marketing/AffiliateListPage";
import AffiliateManagementPage from "../../5-pages/Marketing/AffiliateManagementPage";
import AffiliateReportListPage from "../../5-pages/Marketing/AffiliateReportListPage";
import AffiliateReportManagementPage from "../../5-pages/Marketing/AffiliateReportManagementPage";

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
);

// confliecto?
function RoutesMarketing({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/marketing`;

  return (
    <Fragment>
      <Route exact path={`${mainUrl}/affiliate/affiliates`} component={AffiliateListPage} />
      <Route
        exact
        path={`${mainUrl}/affiliate/affiliates/:affiliateId`}
        component={AffiliateManagementPage}
      />
      <Route exact path={`${mainUrl}/affiliate/reports`} component={AffiliateReportListPage} />
      <Route
        exact
        path={`${mainUrl}/affiliate/reports/:affiliateReportId`}
        component={AffiliateReportManagementPage}
      />
      <Route exact path={`${mainUrl}/newsletter/third-party`} component={NewsletterListPage} />
      <Route
        exact
        path={`${mainUrl}/newsletter/third-party/:newsletterId`}
        component={NewsletterManagementPage}
      />
      <Route exact path={`${mainUrl}/cross-selling`}>
        <CrossSelling />
      </Route>
      <Route exact path={`${mainUrl}/cross-selling/campaigns`}>
        <CrossSellingCampaigns />
      </Route>
      <Route
        path={`${mainUrl}/cross-selling/campaigns/:campaignCode`}
        component={CrossSellingCampaignsEditor}
      />
      <Route exact path={`${mainUrl}/nevasport`}>
        <Nevasport />
      </Route>
      <Route exact path={`${mainUrl}/nevasport/offers`}>
        <NevasportOffers />
      </Route>
    </Fragment>
  );
}

export default RoutesMarketing;
