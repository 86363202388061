import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import AgentPanel from "./panel/AgentPanel";

function RoutesAgentPanel({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}`;
  return (
    <Fragment>
      <Route exact path={`${mainUrl}/agentPanel`}>
        <AgentPanel />
      </Route>
    </Fragment>
  );
}

export default RoutesAgentPanel;
