import React from "react";
import { createAccountInfoTaxFromCsv } from "./AccountInfoTax";

const UploadCsvSii = (props) => {
  const handleFileInput = async (fileList) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      const content = fileReader.result;
      const accountInfoTax = createAccountInfoTaxFromCsv(content);
      //TODO Errors control
      props.onReady(accountInfoTax);
    };
    fileReader.readAsText(fileList.item(0));
  };
  return (
    <>
      <div>
        <input
          type="file"
          accept=".csv"
          id="csvFile"
          name="csvFile"
          onChange={(event) => handleFileInput(event.target.files)}
        />
        <br />
      </div>
    </>
  );
};
export default UploadCsvSii;
