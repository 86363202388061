import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import CookiesGroupList from "@organisms/contenido/cookies/pages/list/CookiesGroupList";

function CookiesGroupPageList() {
  return (
    <ContainerFenix>
      <CookiesGroupList></CookiesGroupList>
    </ContainerFenix>
  );
}

export default CookiesGroupPageList;
