const useRemittances = () => {
  const tableHeadRemittances = [
    { code: "id", name: "ID", type: "string" },
    { code: "createdBy", name: "Creada por", type: "string" },
    { code: "datecreation", name: "Fecha creacion", type: "date" },
    { code: "npayments", name: "Nº Pagos", type: "string" },
    { code: "ntotal", name: "Importe total", type: "money" },
  ];

  const tableHeadInvoices = [
    { code: "invoice", name: "Nº Factura", type: "string" },
    { code: "amount", name: "Importe (€)", type: "money" },
    { code: "date", name: "Fecha", type: "date" },
    { code: "supplier", name: "Supplier", type: "string" },
  ];

  function parseRemittanceResponse(remittancesResp) {
    const nRemittances = remittancesResp.map((remit) => ({
      ...remit,
      npayments: remit.payments.length,
      ntotal: remit.payments.reduce((acc, cur) => acc + cur.amount, 0),
      payments: remit.payments.map((payment) => ({
        ...payment,
        ninvoices: payment.invoices.length,
      })),
    }));

    return nRemittances;
  }

  return { tableHeadRemittances, tableHeadInvoices, parseRemittanceResponse };
};

export default useRemittances;
