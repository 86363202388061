import React, { useState } from "react";
import PropTypes from "prop-types";
import { Fab, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CustomModal from "@molecules/Modal/CustomModal";
import InputBookref from "@atoms/Inputs/InputBookref";
import { executeChangeBookOfPayment } from "./usePayments";

const PaymentsModalChangeBook = ({ originalBookref, paymentId, open, onClose }) => {
  const [bookrefSelected, setBookrefSelected] = useState(null);

  const selectBookref = (thisBook) => {
    const newBookref = thisBook.bookref;
    if (originalBookref === newBookref) {
      alert(`El codigo de reserva ${newBookref} es al que ya pertenece este pago.`);
      setBookrefSelected(null);
    } else {
      setBookrefSelected(newBookref);
    }
  };

  const executeBookChange = async () => {
    await executeChangeBookOfPayment(bookrefSelected, paymentId);
    onClose(true);
  };

  return (
    <CustomModal
      loading={false}
      title={"Mover pago a otra reserva"}
      open={open}
      handleClose={() => onClose(false)}
      bigModal={false}
    >
      <>
        {!paymentId ? (
          <Alert severity="error">¡Ningun pago seleccionado!</Alert>
        ) : (
          <>
            <Alert severity="info">
              Al mover este pago a otra reserva, se recalcularán los precios de ambas reservas.
            </Alert>
            <Grid container spacing={4}>
              <Grid container item xs={12} justify="space-between">
                <InputBookref onSelect={selectBookref} />
              </Grid>
            </Grid>
            {/* ---Botón--- */}
            <Grid container spacing={4}>
              <Grid container item xs={12} justify="flex-end">
                <Fab
                  variant="extended"
                  color="primary"
                  disabled={bookrefSelected === null}
                  onClick={executeBookChange}
                >
                  {"Cambiar de reserva"}
                </Fab>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </CustomModal>
  );
};

PaymentsModalChangeBook.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PaymentsModalChangeBook.defaultProps = {};

export default PaymentsModalChangeBook;
