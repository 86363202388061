import React, { useState, Fragment } from "react";
import { Select, FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { RiFileTransferFill } from "react-icons/ri";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ButtonDownload from "@atoms/Buttons/ButtonDownload";
import CardTitle from "@atoms/Titles/CardTitle";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import CustomModal from "@molecules/Modal/CustomModal";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import { usePayments, executeConfirmRefund } from "./hooks/usePayments";
import generatePendingXML from "./generatePendingXML";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "150px!important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransfersRefundPending() {
  const classes = useStyles();
  const VERSION = 2.0;
  const wordcontrol = `TRANSFERSPEDING_V${VERSION}`;

  const { loadingTransfers, dataTransfers } = usePayments();
  const [methodPayment, setMethodPayment] = useState(0);
  const [modal, setModal] = useState(false);
  const [bankAccount, setBankAccount] = useState({
    loadingBookAccount: true,
    dataBookAccount: {},
  });

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const confirmRefund = async (row) => {
    const r = confirm(
      `¿Seguro que quieres confirmar esta devolución de ${row.amount.amount / 100}${row.amount.currency}?`
    );
    if (r) {
      await executeConfirmRefund(row.paymentId);
    }
  };

  const seeIBAN = async (row) => {
    if (row.dataAccount !== undefined) {
      setBankAccount(row.dataAccount);
    }
    handleOpenModal();
  };

  const handleChangeMethodPayment = (event) => {
    setMethodPayment(event.target.value);
  };

  const actions = [
    { name: "Confirmar", icon: <RiFileTransferFill />, func: confirmRefund },
    { name: "Ver IBAN", icon: <VisibilityIcon />, func: seeIBAN },
  ];

  const tableHead = [
    { code: "paymentId", name: "PaymentId", type: "string" },
    { code: "bookref", name: "Bookref", type: "string" },
    { code: "refundedFromPaymentMethodName", name: "Metodo de pago", type: "string" },
    { code: "amount.toFixed", name: "Importe", type: "string" },
    {
      code: "hasIban",
      name: "¿Tiene IBAN?",
      type: "label",
      filterable: false,
      translation: { A: { name: "Si", colour: "#17a2b8" }, B: { name: "No" } },
    },
  ];

  const headOffers = ["Titular", "IBAN", "Codido de reserva", "Cantidad", "Metodo de pago"];
  const specsOffers = {
    dataAccount: { holder: "", bankAccount: "" },
    reservationCode: "",
    amount: { toFixedReplace: "" },
    refundedFromPaymentMethodName: "",
  };

  function generateXML() {
    generatePendingXML(dataTransfers.filter((cv) => cv.dataAccount));
  }

  return (
    <Fragment>
      <CustomModal
        loading={false}
        title={"Datos bancarios"}
        open={modal}
        handleClose={() => handleCloseModal()}
        bigModal={false}
      >
        <div style={{ padding: "50px" }}>
          <Fragment>
            {bankAccount.bankAccount ? (
              <Fragment>
                <p>
                  <b>Titular:</b> {bankAccount.holder}
                </p>
                <p>
                  <b>IBAN:</b> {bankAccount.bankAccount}
                </p>
              </Fragment>
            ) : (
              "No disponemos de los datos de la cuenta bancaria del usuario."
            )}
          </Fragment>
        </div>
      </CustomModal>

      <CardTitle
        title={"Transferencias pendientes"}
        buttons={[
          <FormControl key={"nv-bt1"} className={classes.formControl}>
            <Select
              labelId="transfer-pending-simple-select-label"
              id="transfer-pending-simple-select"
              value={methodPayment}
              onChange={handleChangeMethodPayment}
            >
              <MenuItem value={0}>Todas</MenuItem>
              <MenuItem value={3}>Transferencias</MenuItem>
              <MenuItem value={9}>Multibanco</MenuItem>
            </Select>
          </FormControl>,
          <ButtonDownloadCSV
            key={"nv-bt2"}
            disabled={loadingTransfers}
            wordcontrol={wordcontrol}
            head={headOffers}
            specs={specsOffers}
            table={dataTransfers.filter((cv) => cv.dataAccount)}
            filename={`TransferenciasPendientes`}
            buttonLabel={`Pendientes (CSV)`}
          />,
          <ButtonDownload key={"nv-bt3"} disabled={loadingTransfers} onClick={generateXML}>
            {"Pendientes (XML)"}
          </ButtonDownload>,
        ]}
      />
      <FenixTable
        loading={loadingTransfers}
        tableHead={tableHead}
        tableContent={
          methodPayment === 0
            ? dataTransfers
            : dataTransfers.filter((t) => t.refundedFromPaymentMethod === methodPayment)
        }
        actions={actions}
        maxNumberPerPages={10}
        pagination={true}
      />
    </Fragment>
  );
}

export default TransfersRefundPending;
