/* eslint-disable no-await-in-loop */
import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import processXmlFile from "./processXmlFile";
import XmlProcessorStatsAndErrors from "./XmlProcessorStatsAndErrors";
import sleep from "../../../lib/helpers/sleep";

const ProcessXmlSii = (props) => {
  const [processingErrors, setProcessingErrors] = React.useState([]);
  const [processingOkMessages, setProcessingOkMessages] = React.useState([]);

  // Función para crear y descargar un archivo
  function download(filename, text) {
    const element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  // Función para descargar todos los archivos al hacer clic en el botón
  async function downloadAll(xmls) {
    for (let i = 0; i < xmls.length; i += 1) {
      download(`output_${xmls[i].name.replace(".xml", "")}.xml`, xmls[i].content);
      await sleep(1000);
      download(`originial_${xmls[i].name.replace(".xml", "")}.xml`, xmls[i].originalContent);
      await sleep(1000);
    }
  }

  async function processFiles() {
    const newsXml = [];
    const { accountInfoTax } = props;
    const xmlProcessorStatsAndErrors = new XmlProcessorStatsAndErrors();
    props.xmls.forEach((xml) => {
      const [newXml, originalXml] = processXmlFile(
        xml.content,
        props.accountInfoTax,
        xmlProcessorStatsAndErrors
      );
      newsXml.push({ name: xml.name, content: newXml, originalContent: originalXml });
    });
    const notProcessedAccountNumbers = accountInfoTax.checkNotProcessed();
    const newProcessingErrors = [];
    let newProcessingOkMessages = [];
    if (xmlProcessorStatsAndErrors.hasErrors() || notProcessedAccountNumbers.length > 0) {
      const errors = xmlProcessorStatsAndErrors.getErrors();
      errors.forEach((error) => {
        newProcessingErrors.push(error);
      });
      if (notProcessedAccountNumbers.length > 0) {
        const errText = notProcessedAccountNumbers.join(", ");
        newProcessingErrors.push(`Algunos números de factura del csv no estaban en los xmls: ${errText}`);
      }
    } else {
      newProcessingOkMessages = xmlProcessorStatsAndErrors.getSummaryMessages();
      await downloadAll(newsXml);
    }
    setProcessingErrors(newProcessingErrors);
    setProcessingOkMessages(newProcessingOkMessages);
  }

  return (
    <>
      <ButtonCustom aria-label="Procesar y descargar" onClick={() => processFiles()}>
        Procesar y descargar
      </ButtonCustom>

      {processingErrors.length > 0 && (
        <div style={{ color: "red" }}>
          <br />
          Errores procesando:
          <br />
          {processingErrors.map((error, index) => {
            return (
              <div key={`index_${index}`}>
                {`${error}`}
                <br />
              </div>
            );
          })}
        </div>
      )}

      {processingOkMessages.length > 0 && (
        <div style={{ color: "green" }}>
          <br />
          {processingOkMessages.map((message, index) => {
            return (
              <div key={`index_${index}`}>
                {`${message}`}
                <br />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default ProcessXmlSii;
