import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { FiXSquare } from "react-icons/fi";

function ButtonCancel({ size, variant, customcolor, onClick, children }) {
  return (
    <ButtonCustom
      size={size}
      variant={variant}
      aria-label="Cancel"
      icon={<FiXSquare />}
      onClick={onClick}
      customcolor={customcolor ?? ""}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonCancel;
