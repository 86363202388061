import React from "react";
import { BsShop } from "react-icons/bs";
import { RiCharacterRecognitionLine } from "react-icons/ri";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { ThreeDotMenu, useThreeDotMenu } from "@molecules/Menus/ThreeDotMenu";
import bulkUploadTickets from "./bulkUploadTickets";
import bulkUploadTicketsChannels from "./bulkUploadTicketsChannels";
import {
  contentFields,
  csvHead,
  csvSpecs,
  wordcontrol,
  filename,
  wordcontrolChannels,
  filenameChannels,
  csvHeadChannels,
  csvSpecChannels,
  wordcontrolContent,
  filenameContent,
  csvHeadContent,
  csvSpecContent,
} from "./constants";
import MenuItemUploadCSV from "../shared/MenuItemUploadCSV";
import MenuItemDownloadCSV from "../shared/MenuItemDownloadCSV";
import reorganizeListIntoCsvContent from "../shared/reorganizeListIntoCsvContent";
import bulkUploadTicketsContent from "./bulkUploadTicketsContent";

function TicketsSectionMenu({ precinctCode, ticketList, onUploadEnded }) {
  const { anchorEl, onOpenMenu, handleClose } = useThreeDotMenu();

  const onUploadCsvEnded = (results) => {
    handleClose();
    onUploadEnded(results);
  };

  const itemsName = "tarifas";

  function generateCsvFromList() {
    return ticketList.map((ticket) => ({
      ...ticket,
      people: ticket.people.ages.map((p) => `${p.min}|${p.max}`),
      cancellation: {
        isCancellable: ticket.cancellation.isCancellable,
        conditions: ticket.cancellation.conditions.map((c) => [c.daysToDate, c.percent].join("|")),
      },
    }));
  }

  function reorganizeListIntoCsvChannels() {
    return ticketList.reduce((csv, ticket) => {
      return [
        ...csv,
        ...ticket.channels.map((channel) => ({
          ...channel,
          id: ticket.id,
          alias: ticket.alias,
          productCode: ticket.productCode,
        })),
      ];
    }, []);
  }

  return (
    <SectionTitle
      style={{ width: "100%", marginTop: "0" }}
      title={"Tarifas"}
      buttons={[
        <ThreeDotMenu key={0} style={{ minWidth: "0" }} {...{ anchorEl, onOpenMenu, handleClose }}>
          <MenuItemUploadCSV
            itemsName={itemsName}
            wordcontrol={wordcontrol}
            filename={`${precinctCode}_${filename}`}
            buttonLabel={`Subir ${itemsName}`}
            specs={csvSpecs}
            uploadFunction={bulkUploadTickets}
            onUploadEnded={onUploadCsvEnded}
          />
          <MenuItemDownloadCSV
            wordcontrol={wordcontrol}
            filename={`${precinctCode}_${filename}`}
            head={csvHead}
            specs={csvSpecs}
            generateTable={generateCsvFromList}
            buttonLabel={`Descargar ${itemsName}`}
            onDownloadEnded={handleClose}
          />
          {/* Contenido */}
          <MenuItemUploadCSV
            icon={<RiCharacterRecognitionLine size={20} />}
            itemsName={itemsName}
            wordcontrol={wordcontrolContent}
            filename={filenameContent}
            buttonLabel={"Subir contenido"}
            specs={csvSpecContent}
            uploadFunction={bulkUploadTicketsContent}
            onUploadEnded={onUploadCsvEnded}
          />
          <MenuItemDownloadCSV
            icon={<RiCharacterRecognitionLine size={20} />}
            wordcontrol={wordcontrolContent}
            filename={filenameContent}
            head={csvHeadContent}
            specs={csvSpecContent}
            generateTable={() => reorganizeListIntoCsvContent(ticketList, contentFields, "id")}
            buttonLabel={"Descargar contenido"}
            onDownloadEnded={handleClose}
          />
          {/* Canales */}
          <MenuItemUploadCSV
            icon={<BsShop size={20} />}
            itemsName={`canales de ${itemsName}`}
            wordcontrol={wordcontrolChannels}
            filename={`${precinctCode}_${filenameChannels}`}
            buttonLabel={`Subir canales (mapeos)`}
            specs={csvSpecChannels}
            uploadFunction={(csv) => bulkUploadTicketsChannels(csv, precinctCode)}
            onUploadEnded={onUploadCsvEnded}
          />
          <MenuItemDownloadCSV
            icon={<BsShop size={20} />}
            wordcontrol={wordcontrolChannels}
            filename={`${precinctCode}_${filenameChannels}`}
            head={csvHeadChannels}
            specs={csvSpecChannels}
            generateTable={reorganizeListIntoCsvChannels}
            buttonLabel={`Descargar canales (mapeos)`}
            onDownloadEnded={handleClose}
          />
        </ThreeDotMenu>,
      ]}
    />
  );
}

TicketsSectionMenu.propTypes = {};

export default TicketsSectionMenu;
