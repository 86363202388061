import { useState, useEffect } from "react";
import {
  getAllVacationalPackageDestination,
  getAllVacationalPackageDNodes,
} from "./useVacationalPackageFetch";

export default function useVacationalPackageManagement(tld) {
  const [destinations, setDestinations] = useState({
    loadingDestinations: true,
    destinations: [],
  });

  const handleUseAllDestination = async () => {
    const responseDestinations = await getAllVacationalPackageDestination();
    setDestinations(responseDestinations);
  };

  useEffect(() => {
    if (destinations.loadingDestinations) {
      handleUseAllDestination();
    }
  }, [destinations.loadingDestinations]);

  const [nodes, setNodes] = useState({
    loadingNodes: true,
    nodes: [],
  });

  const handleUseAllNodes = async () => {
    const responseNodes = await getAllVacationalPackageDNodes(tld);
    setNodes(responseNodes);
  };

  useEffect(() => {
    if (nodes.loadingNodes) {
      handleUseAllNodes();
    }
  }, [nodes.loadingNodes]);

  return {
    destinations,
    setDestinations,
    nodes,
    setNodes,
  };
}
