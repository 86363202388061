/* eslint-disable no-use-before-define */
import { useState, useEffect, useCallback } from "react";
import { getDatesBetweenTwo } from "@lib/helpers/dates";
import { fetchProducts } from "../../Products/useProducts";
import { fetchTickets } from "../../Tickets/useTickets";
import { fetchSessions } from "../../Sessions/useSessions";
import getViewCalendar from "../shared/calendarForView";
import { getChannelAsQueryParms, completeCalendarMissingDays } from "../utils";
import {
  fetchPrecinctCalendar,
  fetchProductCalendarWithPrecinctCode,
  fetchSessionCalendarWithPrecinctCode,
  fetchTicketSessionCalendarWithPrecinctCode,
} from "../shared/daoCalendars";

export const useCalendarSessions = ({ precinctCode, channel, year }) => {
  const [loadingCalendar, setLoadingCalendar] = useState(true);
  const [calendars, setCalendars] = useState();
  const [precinctChildren, setPrecinctChildren] = useState({ products: [], tickets: [], sessions: [] });

  const fetchCalendar = useCallback(
    async (initLoading = true) => {
      setLoadingCalendar(initLoading);
      try {
        // console.log("Fetch", precinctCode, channel, year);
        const { calendarsFull, products, tickets, sessions } = await fetchFullSessionCalendar(
          precinctCode,
          channel,
          year
        );

        setCalendars(calendarsFull);
        setPrecinctChildren({ products, tickets, sessions });
        setLoadingCalendar(false);
      } catch (e) {
        alert(e.message);
        console.error(e);
        // setLoadingCalendar(false);
      }
    },
    [channel, precinctCode, year]
  );

  useEffect(() => {
    if (channel) {
      fetchCalendar();
    }
  }, [channel, year, fetchCalendar]);

  let calendar = {};
  if (calendars !== undefined) {
    calendar = getViewCalendar(calendars);
  }

  return {
    loadingCalendar,
    calendar,
    fetchCalendar,
    products: precinctChildren.products.map((p) => ({ ...p, productCode: p.code })),
  };
};

export const fetchFullSessionCalendar = async (precinctCode, channel, year) => {
  const [
    products,
    tickets,
    sessions,
    precinctCalendarR,
    { data: productCalendarsR },
    { data: sessionCalendarsR },
    { data: ticketSessionCalendarsR },
  ] = await Promise.all([
    fetchProducts({ params: { precinctCode } }),
    fetchTickets({ params: { precinctCode, ...getChannelAsQueryParms(channel, "channels.channel") } }),
    fetchSessions({ params: { precinctCode } }),
    fetchPrecinctCalendar(precinctCode, year),
    fetchProductCalendarWithPrecinctCode(precinctCode, channel, year),
    fetchSessionCalendarWithPrecinctCode(precinctCode, channel, year),
    fetchTicketSessionCalendarWithPrecinctCode(precinctCode, channel, year),
  ]);

  // Complete the calendar results with all the products & tickets available
  const dayListOfYear = getDatesBetweenTwo(`${year}-01-01`, `${year}-12-31`);
  const precinctCalendar = completeCalendarMissingDays(precinctCalendarR, dayListOfYear, "precinct");
  const productCalendars = products.map((product) => {
    const { code: productCode } = product;
    let newCalendar = { precinctCode, productCode, channel, year, calendar: [] };
    const calendar = productCalendarsR.find((c) => c.productCode === productCode);
    if (calendar !== undefined) {
      newCalendar = { ...calendar };
    }
    return completeCalendarMissingDays(newCalendar, dayListOfYear, "product");
  });
  const sessionCalendars = sessions.map((session) => {
    const { id: sessionId, productCode } = session;
    let newCalendar = { sessionId, precinctCode, productCode, channel, year, calendar: [] };
    const calendarEntry = sessionCalendarsR.find((c) => c.sessionId === sessionId);
    if (calendarEntry !== undefined) {
      newCalendar = { ...calendarEntry };
    }
    return completeCalendarMissingDays({ ...newCalendar }, dayListOfYear, "session");
  });

  const kvTicket = tickets.reduce((accu, tk) => ({ ...accu, [tk.id]: tk }), {});

  const ticketSessionCalendars = ticketSessionCalendarsR.reduce((accu, tsc) => {
    const { sessionId } = tsc;

    const nuAcc = { ...accu };
    if (nuAcc[sessionId] === undefined) {
      nuAcc[sessionId] = [];
    }

    if (kvTicket[tsc.ticketId] !== undefined) {
      const calendar = {
        ...completeCalendarMissingDays(tsc, dayListOfYear, "ticketsession"),
        alias: kvTicket[tsc.ticketId].alias,
      };

      nuAcc[sessionId].push(calendar);
    }

    return nuAcc;
  }, {});

  return {
    calendarsFull: { precinctCalendar, productCalendars, sessionCalendars, ticketSessionCalendars },
    products,
    tickets,
    sessions,
  };
};
