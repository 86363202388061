import React from "react";
import { Table } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import FxAlert from "@atoms/Alerts/FxAlert";
import { TravCard, TravHeaderCard } from "@atoms/Card/FxCard";
import AccordionItem from "@molecules/Accordions/AccordionItem";

const DoblementeApiTicketList = ({ tickets }) => {
  return (
    <>
      <FxAlert variant="info">
        <p>{tickets.length} productos encontrados</p>
      </FxAlert>

      <TravCard>
        <TravHeaderCard style={{ backgroundColor: "lightgrey" }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <span style={{ color: "#006699", fontWeight: "bold" }}>Codigo de servicio</span>
            </Grid>
            <Grid item xs={3}>
              <span style={{ color: "#006699", fontWeight: "bold" }}>Codigo de Producto</span>
            </Grid>
            <Grid item xs={3}>
              Nombre del producto
            </Grid>
          </Grid>
        </TravHeaderCard>
      </TravCard>
      {tickets.map((t, i) => (
        <AccordionItem
          key={`tk${i}`}
          title={
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <span style={{ color: "#006699", fontWeight: "bold" }}>{t.serviceTypeCode}</span>
              </Grid>
              <Grid item xs={3}>
                <span style={{ color: "#006699", fontWeight: "bold" }}>{t.productCode}</span>
              </Grid>
              <Grid item xs={3}>
                {t.productName}
              </Grid>
            </Grid>
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <h3>Sectores</h3>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Sector</th>
                    <th>Nombre</th>
                  </tr>
                </thead>
                <tbody>
                  {t.sectors.map((sector, is) => (
                    <tr key={`sector${i}${is}`}>
                      <td>{sector.sectorCode}</td>
                      <td>{sector.sectorName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Grid>
            <Grid item xs={6}>
              <h3>Conceptos</h3>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Código concepto</th>
                    <th>Nombre concepto</th>
                    <th>Desde la edad</th>
                    <th>Hasta la edad</th>
                    <th>Persona</th>
                  </tr>
                </thead>
                <tbody>
                  {t.concepts.map((concept, ic) => (
                    <tr key={`sector${i}${ic}`}>
                      <td>{concept.conceptCode}</td>
                      <td>{concept.conceptName}</td>
                      <td>{concept.ageFrom}</td>
                      <td>{concept.ageTo}</td>
                      <td>{concept.isChild}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Grid>
          </Grid>
        </AccordionItem>
      ))}
    </>
  );
};

export default DoblementeApiTicketList;
