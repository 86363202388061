import React, { useState, useEffect } from "react";

import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import FxAlert from "@atoms/Alerts/FxAlert";
import SectionTitle from "@atoms/Titles/SectionTitle";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { IoIosColorFilter } from "react-icons/io";
import { MdSend } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";
import InputUploadFile from "@atoms/Inputs/InputUploadFile/InputUploadFile";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import VoucherManageDetailVoucherList from "./VoucherManageDetailVoucherList.view";
import userVoucherActions from "../hooks/useVoucherActions";
import { userAuthorized } from "../../../../entries/fenix/auth/FenixAuthContext";
import ButtonReset from "@atoms/Buttons/ButtonReset";

const VoucherManageDetail = ({
  voucherToManage,
  setVoucherToManage,
  setIsManageVoucherActive,
  updateVoucher,
  returnToList,
}) => {
  const user = userAuthorized();
  const {
    seeVoucherPDFAction,
    sendEmailVoucherAction,
    combineVoucherPDFsAction,
    uploadVoucherAction,
    blockVoucherBackground,
    regenerateVoucherAction,
  } = userVoucherActions({
    user,
    setIsManageVoucherActive,
    setVoucherToManage,
    updateVoucher,
  });

  const checkMutexBlock = () => {
    return voucherToManage.detail?.mutex?.id !== undefined
      ? !voucherToManage.detail?.mutex?.id?.includes(user.profile.email) &&
          Math.floor(new Date().getTime() / 1000) < voucherToManage.detail?.mutex.timestamp + 10
      : voucherToManage.detail?.allow === false;
  };

  const [alarmVoucherMutexActive, setAlarmVoucherMutexActive] = useState(checkMutexBlock());

  const messageAlarmVoucherMutexActive = (
    <FxAlert variant={"error"}>
      LO SENTIMOS PERO ESTA RESERVA NO ES MODIFICABLE PORQUE EL USUARIO{" "}
      <strong>${voucherToManage.detail?.mutex?.id || voucherToManage.detail?.id}</strong> la mantiene
      bloqueada
    </FxAlert>
  );

  useEffect(() => {
    if (!alarmVoucherMutexActive) {
      const interval = setInterval(() => {
        blockVoucherBackground({ bookref: voucherToManage.bookref });
      }, 2000);
      return () => clearInterval(interval);
    }
  }, []);

  const handleComeBack = () => {
    returnToList();
  };
  return (
    <>
      {alarmVoucherMutexActive && (
        <div>
          <h4>{messageAlarmVoucherMutexActive}</h4>
        </div>
      )}
      <SectionTitle title={`Bono para la reserva ${voucherToManage.bookref}`} backFunction={handleComeBack} />

      <TravCard>
        <TravBodyCard>
          <TravTitleCard>Enviar bono completo</TravTitleCard>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell width="30%" style={{ borderBottom: "none" }}>
                  <div className="namebono">
                    <span className="namebonotag">Bono final</span>
                    <input
                      placeholder={
                        voucherToManage.detail?.voucherList?.main
                          ? voucherToManage.detail?.voucherList?.main.path
                          : "Ruta del bono en pdf"
                      }
                    ></input>
                  </div>
                  {voucherToManage.detail?.voucherList?.main?.path !== "" && (
                    <ButtonCustom
                      variant="contained"
                      customcolor="blue"
                      disabled={alarmVoucherMutexActive}
                      // eslint-disable-next-line no-undef
                      onClick={() =>
                        seeVoucherPDFAction({
                          bookref: voucherToManage.bookref,
                          typeKey: "main",
                          voucherList: voucherToManage.detail.voucherList,
                        })
                      }
                    >
                      Ver bono
                    </ButtonCustom>
                  )}
                  {voucherToManage.detail?.voucherList?.main?.created === 1 && (
                    <ButtonCustom
                      variant="contained"
                      color="secondary"
                      icon={<MdSend />}
                      disabled={alarmVoucherMutexActive}
                      // eslint-disable-next-line no-undef
                      onClick={sendEmailVoucherAction(
                        (res) => {
                          if (res) {
                            handleComeBack();
                          }
                        },
                        0,
                        voucherToManage
                      )}
                    >
                      Enviar bono
                    </ButtonCustom>
                  )}
                </TableCell>
                <TableCell width="30%" style={{ borderBottom: "none" }}>
                  <InputUploadFile
                    id={voucherToManage.bookref}
                    disabled={alarmVoucherMutexActive}
                    handleUpload={(file) => {
                      uploadVoucherAction({
                        bookref: voucherToManage.bookref,
                        isComplete: true,
                        file,
                      });
                    }}
                  />
                </TableCell>
                <TableCell width="20%" style={{ borderBottom: "none" }}></TableCell>
                <TableCell width="20%" style={{ borderBottom: "none" }}>
                  <ButtonCustom
                    variant="contained"
                    color="primary"
                    icon={<BiRefresh />}
                    disabled={alarmVoucherMutexActive}
                    // eslint-disable-next-line no-undef
                    onClick={async () => {
                      const isRegenerated = await regenerateVoucherAction(voucherToManage);
                      if (isRegenerated) {
                        handleComeBack();
                      }
                    }}
                  >
                    Regenerar Bonos
                  </ButtonCustom>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TravBodyCard>
      </TravCard>
      <TravCard>
        <TravBodyCard>
          <div>
            <div className="rowitems">
              <TravTitleCard>Bonos de los elementos</TravTitleCard>
              <ButtonCustom
                variant="contained"
                color="secondary"
                icon={<IoIosColorFilter />}
                disabled={alarmVoucherMutexActive}
                // eslint-disable-next-line no-undef
                onClick={() =>
                  combineVoucherPDFsAction({
                    bookref: voucherToManage.bookref,
                    voucherList: voucherToManage.detail.voucherList,
                  })
                }
              >
                Fusionar pdfs
              </ButtonCustom>
            </div>

            {/* Listado de alojamientos */}
            <VoucherManageDetailVoucherList
              bookref={voucherToManage.bookref}
              voucherListElements={voucherToManage?.detail?.voucherList?.accommodations}
              elementKey={"accomodation"}
              typeKey={"acc"}
              completeBook={voucherToManage?.detail}
              voucherType={"Bono de alojamiento"}
              disabled={alarmVoucherMutexActive}
              seeVoucherPDFAction={seeVoucherPDFAction}
              uploadVoucherAction={uploadVoucherAction}
            />
            {/* Listado de servicios */}
            <VoucherManageDetailVoucherList
              bookref={voucherToManage.bookref}
              voucherListElements={voucherToManage?.detail?.voucherList?.services}
              key="service"
              typeKey={"services"}
              completeBook={voucherToManage?.detail}
              voucherType="Bono de servicio"
              disabled={alarmVoucherMutexActive}
              seeVoucherPDFAction={seeVoucherPDFAction}
              uploadVoucherAction={uploadVoucherAction}
            />
            {/* Listado de barcos */}
            <VoucherManageDetailVoucherList
              bookref={voucherToManage.bookref}
              voucherListElements={voucherToManage?.detail?.voucherList?.boats}
              elementKey={"boat"}
              typeKey={"boats"}
              completeBook={voucherToManage?.detail}
              voucherType={"Bono de ferry"}
              disabled={alarmVoucherMutexActive}
              seeVoucherPDFAction={seeVoucherPDFAction}
              uploadVoucherAction={uploadVoucherAction}
            />

            {/* Listado de vuelos */}
            <VoucherManageDetailVoucherList
              bookref={voucherToManage.bookref}
              voucherListElements={voucherToManage?.detail?.voucherList?.flights}
              elementKey={"flight"}
              typeKey={"flights"}
              completeBook={voucherToManage?.detail}
              voucherType={"Bono de vuelo"}
              disabled={alarmVoucherMutexActive}
              seeVoucherPDFAction={seeVoucherPDFAction}
              uploadVoucherAction={uploadVoucherAction}
            />

            {/* Listado de trenes */}
            <VoucherManageDetailVoucherList
              bookref={voucherToManage.bookref}
              voucherListElements={voucherToManage?.detail?.voucherList?.trains}
              elementKey={"train"}
              typeKey={"trains"}
              completeBook={voucherToManage?.detail}
              voucherType={"Bono de tren"}
              disabled={alarmVoucherMutexActive}
              seeVoucherPDFAction={seeVoucherPDFAction}
              uploadVoucherAction={uploadVoucherAction}
            />

            {/* Listado de seguros */}
            <VoucherManageDetailVoucherList
              bookref={voucherToManage.bookref}
              voucherListElements={voucherToManage?.detail?.voucherList?.insurances}
              elementKey={"insurance"}
              typeKey={"insurances"}
              completeBook={voucherToManage?.detail}
              voucherType={"Bono de seguros"}
              disabled={alarmVoucherMutexActive}
              seeVoucherPDFAction={seeVoucherPDFAction}
              uploadVoucherAction={uploadVoucherAction}
            />
          </div>
        </TravBodyCard>
      </TravCard>
      <style jsx="true">
        {`
          .rowitems {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid #ddd;
            margin-bottom: 20px;
          }
          .rowitems:last-child {
            border-bottom: 1px solid transparent;
          }

          .namebono {
            display: flex;
            align-items: stretch;
          }
          .namebono input {
            padding-left: 5px;
            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
            border: 1px solid #ddd;
            flex-basis: 80%;
          }
          .namebonotag {
            display: inline-flex;
            align-items: center;
            padding: 10px;
            background-color: #c9f7f5;
            color: #06a29b;
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
            flex-basis: 60%;
          }
          .iconitems {
            display: inline-flex;
            justify-content: center;
            align-items: centre;
            padding: 10px;
            margin-right: 5px;
            background-color: #c9f7f5;
            color: #1bc5bd;
            border-radius: 50%;
            font-size: 20px;
          }
          @media (max-width: 768px) {
            .order1 {
              order: 2;
            }
            .order2 {
              order: 3;
            }
            .order3 {
              order: 1;
            }
          }
        `}
      </style>
    </>
  );
};

export default VoucherManageDetail;
