import React, { Fragment, useState } from "react";
import { IoIosClose } from "react-icons/io";
import SearchQueryResultsLine from "@atoms/Inputs/SearchQueryResultsLine";
import SearchQueryResultsLineTitle from "@atoms/Inputs/SearchQueryResultsLineTitle";
import InputQueryDropdown from "@atoms/Inputs/InputQueryDropdown";
import DrawerMobileScrollableContent from "@atoms/Drawers/DrawerMobileScrollableContent";
import QueryDropDownListLayoutDesktop from "./QueryDropDownListLayoutDesktop";
import QueryDropDownListLayoutMobile from "./QueryDropDownListLayoutMobile";

const QueryDropDownList = ({
  elements,
  device = "desktop",
  onSelect = () => {},
  onChangeQuery = () => {},
  closeMe = () => {},
}) => {
  /**
   * Row to highlight. Higlight == hover
   */
  const [rowHighlighted, setRowHighlighted] = useState(-1);

  /**
   * Internal state of actual query
   */
  const [query, setQuery] = useState("");
  const onChangeQueryHandler = (e) => {
    setQuery(e.target.value);
    onChangeQuery(e.target.value);
  };

  /**
   * Layouts of QueryDropDown depends on device
   */
  const Layout = device === "desktop" ? QueryDropDownListLayoutDesktop : QueryDropDownListLayoutMobile;
  const LayoutList = device === "desktop" ? Fragment : DrawerMobileScrollableContent;

  return (
    <>
      <Layout
        elements={elements}
        rowHighlighted={rowHighlighted}
        setRowHighlighted={setRowHighlighted}
        closeMe={() => {
          setQuery("");
          onChangeQuery(""), closeMe();
        }}
        selected={onSelect}
      >
        <InputQueryDropdown onChange={onChangeQueryHandler} value={query}>
          <IoIosClose
            onClick={() => {
              setQuery("");
              onChangeQuery(""), closeMe();
            }}
          />
        </InputQueryDropdown>
        <LayoutList>
          <List elements={elements} rowHighlighted={rowHighlighted} onSelect={onSelect} query={query} />
        </LayoutList>
      </Layout>
    </>
  );
};

const List = ({ elements, rowHighlighted, onSelect, query }) => {
  return elements.map((element, index) =>
    element.type === "title" ? (
      <SearchQueryResultsLineTitle key={index + element.text} element={element} />
    ) : (
      <SearchQueryResultsLine
        key={index + element.text}
        element={element}
        query={query}
        highlight={index === rowHighlighted}
        onSelect={() => onSelect(element)}
      />
    )
  );
};

export default QueryDropDownList;
