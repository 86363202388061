/* eslint-disable no-use-before-define */
import _ from "lodash";
import { dateToStStr, getDatesBetweenTwo } from "@lib/helpers/dates";
import { fetchFullCalendar } from "../CalendarPrecinct/useCalendar";
import { priceInputData, getTypeOfCsvRow, getChannelAsQueryParms } from "../utils";

const Trcsvjs = require("trcsvjs");
// import * as ca from "./__test__/calendarYears.json";

async function csvCalendarGenerator(precinctCode, channel, yearsSelected, wordcontrol) {
  let yearsData = [];
  let dayListOfYear = [];
  if (yearsSelected.length > 0) {
    const promises = yearsSelected.map((year) => fetchFullCalendar(precinctCode, channel, year));
    yearsData = await Promise.all(promises);

    // Get dateFrom (today's whole month)
    const now = new Date();
    // If year is this year, get dateFrom (whole month)
    let from = dateToStStr(new Date(yearsSelected[0], now.getMonth(), 1));

    // If year is not this year, from should be first day of the year
    if (yearsSelected[0] !== now.getFullYear()) {
      from = dateToStStr(new Date(yearsSelected[0], 0, 1));
    }
    dayListOfYear = getDatesBetweenTwo(from, `${yearsSelected[yearsSelected.length - 1]}-12-31`);
  }

  const csv = Trcsvjs.getEmptyCsv({ delimiter: ";" });

  const channelString = getChannelAsString(channel);

  // Wordcontrol
  csv.insertRow(["WORD_CONTROL", wordcontrol]);

  const head = ["Proveedor", "Merchant", "Recinto", "Producto", "Tarifa", "Alias", "Campos"];
  csv.insertRow([...head, ...dayListOfYear]);

  const calendarRows = {};
  yearsData.forEach(({ calendarsFull, products, tickets }) => {
    Object.keys(calendarsFull).forEach((key) => {
      const calendarsList = Array.isArray(calendarsFull[key]) ? calendarsFull[key] : [calendarsFull[key]];

      calendarsList.forEach((item) => {
        const { type } = getTypeOfCsvRow(item);
        const dataFields = _.omit(item, ["calendar", "year"]);

        const filteredFieldsToShow = filterFieldsToShow(type, item, channelString, { products, tickets });
        const filteredFieldsToShowKV = filteredFieldsToShow.reduce((a, v) => ({ ...a, [v.code]: v }), {});

        // Initialize all the fields
        const defaultRows = filteredFieldsToShow.reduce((accu, { code, show, label }) => {
          if (show.includes(type)) {
            const id = getFieldItemId(item, code);
            return { ...accu, [id]: { id, ...dataFields, field: code, fieldLabel: label } };
          }
          return accu;
        }, {});

        // Get all the rows from every field of the data for this year
        const rowsByField = {
          ...defaultRows,
          ...item.calendar.reduce(
            (accu, day) => createRowsWithCalendarObject(accu, day, dataFields, filteredFieldsToShowKV),
            {}
          ),
        };

        // Iterate and save the result in the results object
        Object.keys(rowsByField).forEach((id) => {
          if (calendarRows[id] === undefined) {
            calendarRows[id] = rowsByField[id];
          } else {
            calendarRows[id] = { ...calendarRows[id], ...rowsByField[id] };
          }
        });
      });
    });
  });

  const specs = {
    channel: { provider: "", merchant: "" },
    precinctCode: "",
    productCode: "",
    ticketId: "",
    alias: "",
    // field: "",
    fieldLabel: "",
  };
  // const fields = ["Abierto", "Cupos", "Margen", "Comisión", "Coste", "P.V.P."];
  const fieldSpecs = {
    enable: "b",
    quota: "f",
    packageMarkup: "f",
    commission: "f",
    cost: "f",
    sellingPrice: "f",
  };

  Object.values(calendarRows)
    .sort((a, b) => (b.id > a.id ? -1 : 1))
    .forEach((row) => {
      const fullSpecs = {
        ...specs,
        ...dayListOfYear.reduce((a, d) => ({ ...a, [d]: fieldSpecs[row.field] }), {}),
      };

      csv.insertRowFromObj(row, fullSpecs);
    });

  csv.browseCsv(["calendar", precinctCode, channelString, yearsSelected.join("#")].join("_"));

  return csv;
}

function getChannelAsString(channel) {
  return Object.values(getChannelAsQueryParms(channel)).join("#");
}

const fieldsOrder = {
  quota: "a",
  packageMarkup: "d",
  enable: "c",
  commission: "b",
  sellingPrice: "f",
  cost: "e",
};
function getFieldItemId(dataFields, field) {
  return [
    dataFields.precinctCode,
    dataFields.productCode || "-",
    dataFields.ticketId || "-",
    fieldsOrder[field],
    field,
  ].join("|");
}

function filterFieldsToShow(type, item, channelString, data) {
  const filteredFields = [...priceInputData];
  if (type === "ticket") {
    const { tickets } = data;
    // const thisProductData = products.find((t) => t.code === item.productCode);
    // if (thisProductData !== undefined) {
    // Remove quota if not active
    // if (!thisProductData.quotaEnable) {
    //   const ixQuota = filteredFields.findIndex((f) => f.code === "quota");
    //   filteredFields.splice(ixQuota, 1);
    // }
    // }

    const thisTicketData = tickets.find((t) => t.id === item.ticketId);
    if (thisTicketData !== undefined) {
      // Find channel in order to search for configurations only applied there...
      const channel = thisTicketData.channels.find(
        (ch) => ch.enable && channelString === getChannelAsString(ch.channel)
      );
      if (channel !== undefined) {
        // If a ticket is not commissionable, remove commission
        if (!channel.priceCommissionable) {
          const ixComm = filteredFields.findIndex((f) => f.code === "commission");
          filteredFields.splice(ixComm, 1);
        }
      }
    }
  }

  return filteredFields;
}

function createRowsWithCalendarObject(accu, day, dataFields, filteredFieldsToShowKV) {
  const newAccu = { ...accu };

  // Create an object with all the object-like fields at the same level
  let dayRaw = { ...day };
  if (dayRaw.price !== undefined) {
    dayRaw = { ...dayRaw, ...dayRaw.price };
    delete dayRaw.price;
  }

  // Remove fields that don't apply in the CSV
  const dayWithCSVData = _.omit(dayRaw, ["date", "weekDay"]);

  // Iterate object fields and add them to the final day (every new field should be a new row)
  Object.keys(dayWithCSVData).forEach((field) => {
    if (filteredFieldsToShowKV[field] !== undefined) {
      const id = getFieldItemId(dataFields, field);
      if (newAccu[id] === undefined) {
        newAccu[id] = {
          id,
          ...dataFields,
          field,
          fieldLabel: filteredFieldsToShowKV[field].label,
        };
      }
      newAccu[id][dayRaw.date] = formatCalendarDataToCSVCell(dayRaw[field], field);
    }
  });

  return newAccu;
}

function formatCalendarDataToCSVCell(valueFromDB, field) {
  if (["cost", "sellingPrice"].includes(field)) {
    return valueFromDB / 100;
  }
  return valueFromDB;
}

export default csvCalendarGenerator;
