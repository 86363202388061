import React, { useState } from "react";
import { FiPlusSquare } from "react-icons/fi";
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { Spinner } from "react-bootstrap";
import { Grid, MenuItem, Select, InputLabel } from "@material-ui/core";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import InputText from "@atoms/Inputs/InputText";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import InputNumber from "@atoms/Inputs/InputNumber";
import FxSwitch from "@atoms/Switches/FxSwitch";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import Modal from "@atoms/Modals/Modal";

const AffiliateManagementButtons = ({
  affiliate,
  affiliateId,
  creation,
  setAffiliate,
  saveAffiliate,
  deleteAffiliate,
  handleAlertSnackBar,
}) => {
  const sectionTitleButtons = [];
  if (creation === false) {
    sectionTitleButtons.push(
      <FxSwitch
        labels={["Habilitado", "Deshabilitado"]}
        status={affiliate.data.enable}
        onChange={(val) => {
          const confirm = window.confirm(
            "¿Está seguro de cambiar el estado del afiliado? Recuerda guardar después."
          );
          if (confirm === true) {
            const newAff = { ...affiliate };
            newAff.data.enable = val;
            setAffiliate(newAff);
          }
        }}
      />
    );
    sectionTitleButtons.push(
      <ButtonDelete
        key={"btD"}
        color="primary"
        onClick={async () => {
          const confirm = window.confirm("¿Está seguro de eliminar el afiliado?");
          if (confirm === true) {
            await deleteAffiliate(affiliateId);
            window.location = "/app/marketing/affiliate/affiliates";
          }
        }}
      />
    );
  }
  sectionTitleButtons.push(
    <ButtonSave
      key={"btG"}
      color="primary"
      aria-label="add"
      onClick={async () => {
        const confirm = window.confirm("¿Está seguro de guardar los cambios?");
        if (confirm === true) {
          const id = await saveAffiliate();
          if (id !== null) {
            handleAlertSnackBar(true, "Guardado correctamente");
            window.location = "/app/marketing/affiliate/affiliates/" + id;
          }
        }
      }}
    />
  );
  return sectionTitleButtons;
};

const AffiliatesManagementView = ({
  affiliate,
  affiliateId,
  creation,
  setAffiliate,
  modsToAdd,
  setModsToAdd,
  saveAffiliate,
  deleteAffiliate,
  alertOpen,
  alertData,
  setAlertOpen,
  handleAlertSnackBar,
  validationErrors,
  setValidationErrors,
}) => {
  const [clearCPA, setClearCPA] = useState(false);
  return (
    <>
      {affiliate.loading === true && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status" size="xl" />
        </div>
      )}
      {affiliate.loading === false && affiliate.data.type && (
        <Grid container>
          <Grid item xs={12}>
            <SectionTitle
              title={(creation === true && "Nuevo afiliado") || affiliate.data.id}
              backFunction={() => {
                window.location = "/app/marketing/affiliate/affiliates";
              }}
              buttons={AffiliateManagementButtons({
                affiliate,
                affiliateId,
                creation,
                setAffiliate,
                saveAffiliate,
                setAffiliate,
                deleteAffiliate,
                handleAlertSnackBar,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TravCard>
              <TravBodyCard>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <h4>Id</h4>
                    <InputText
                      title="Id"
                      value={affiliate.data.id}
                      onChange={(e) => {
                        const newAff = { ...affiliate };
                        newAff.data.id = e.value;
                        setAffiliate(newAff);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h4>Tipo afiliado</h4>
                    <FxSwitch
                      labels={["Referido"]}
                      status={affiliate.data.type === "referal" ? false : true}
                      disabled={!creation}
                      onChange={(val) => {
                        const newAff = { ...affiliate };
                        if (val === true) {
                          newAff.data.type = "whitelabel";
                          delete newAff.data.referralConf;
                          newAff.data.whitelabelConf = {
                            domain: "",
                            defLang: "es",
                          };
                        } else {
                          newAff.data.type = "referal";
                          delete newAff.data.whitelabelConf;
                          newAff.data.referralConf = {
                            cookieDaysRetention: 0,
                          };
                        }
                        setAffiliate(newAff);
                      }}
                    />
                    <span> Marca blanca</span>
                  </Grid>
                </Grid>
                <hr />
                {affiliate.data.type === "whitelabel" && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h4>Configuración de la marca blanca</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputText
                        title="Dominio"
                        value={affiliate.data.whitelabelConf.domain}
                        onChange={(e) => {
                          const newAff = { ...affiliate };
                          newAff.data.whitelabelConf.domain = e.value;
                          setAffiliate(newAff);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectMarket
                        selection={affiliate.data.whitelabelConf.defLang}
                        onChangeMarket={(nVal) => {
                          const newAff = { ...affiliate };
                          newAff.data.whitelabelConf.defLang = nVal;
                          setAffiliate(newAff);
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                )}
                {affiliate.data.type === "referal" && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <h4>Configuración de referido</h4>
                      <InputNumber
                        label="Días de retención de cookies"
                        value={affiliate.data.referralConf.cookieDaysRetention}
                        onChange={(e) => {
                          const newAff = { ...affiliate };
                          newAff.data.referralConf.cookieDaysRetention = parseInt(e);
                          setAffiliate(newAff);
                        }}
                        min={0}
                      />
                    </Grid>
                  </Grid>
                )}
                <hr />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="d-flex justify-content-between">
                      <h4>CPA</h4>
                      <ButtonDelete
                        onClick={() => {
                          const newAff = { ...affiliate };
                          if (newAff.data.cpa?.defaultNumber) {
                            newAff.data.cpa.defaultNumber = undefined;
                            setAffiliate(newAff);
                          }
                          delete newAff.data.cpa;
                          setClearCPA(true);
                          setAffiliate(newAff);
                          if (modsToAdd.length > 0) {
                            setModsToAdd([]);
                          }
                        }}
                      >
                        Limpiar CPA
                      </ButtonDelete>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {clearCPA === true && (
                      <InputNumber
                        label="Valor por defecto (%)"
                        value={0}
                        onChange={(e) => {
                          const newAff = { ...affiliate };
                          if (!newAff.data.cpa) {
                            newAff.data.cpa = {
                              defaultNumber: null,
                              applyInPrice: null,
                              modifications: [],
                            };
                          }
                          newAff.data.cpa.defaultNumber = parseFloat(e);
                          setAffiliate(newAff);
                          setClearCPA(false);
                        }}
                        min={0}
                        endAdornment={<>%</>}
                      />
                    )}
                    {clearCPA === false && (
                      <InputNumber
                        label="Valor por defecto"
                        value={affiliate.data.cpa?.defaultNumber ?? null}
                        onChange={(e) => {
                          const newAff = { ...affiliate };
                          if (!newAff.data.cpa) {
                            newAff.data.cpa = {
                              defaultNumber: null,
                              applyInPrice: null,
                              modifications: [],
                            };
                          }
                          newAff.data.cpa.defaultNumber = parseFloat(e);
                          setAffiliate(newAff);
                          setClearCPA(false);
                        }}
                        min={0}
                        endAdornment={<>%</>}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel id="select-aplicar-sobre">Aplicar sobre</InputLabel>
                    <Select
                      fullWidth={true}
                      labelId="select-aplicar-sobre"
                      title="Aplicar sobre"
                      label="Aplicar sobre"
                      value={affiliate.data.cpa?.applyInPrice ?? "-"}
                      onChange={(nVal) => {
                        const newAff = { ...affiliate };
                        if (!newAff.data.cpa) {
                          newAff.data.cpa = {
                            defaultNumber: null,
                            applyInPrice: null,
                            modifications: [],
                          };
                        }
                        newAff.data.cpa.applyInPrice = nVal.target.value;
                        setAffiliate(newAff);
                      }}
                    >
                      <MenuItem value={"-"} style={{ color: "grey" }}>
                        Selecciona un valor
                      </MenuItem>
                      <MenuItem key={`valuespvp`} value={"pvp"}>
                        PVP
                      </MenuItem>
                      <MenuItem key={`valuesamount`} value={"amount"}>
                        Amount
                      </MenuItem>
                      <MenuItem key={`valuesmargin`} value={"margin"}>
                        Margen
                      </MenuItem>
                      <MenuItem key={`valuesmarginnet`} value={"margin_net"}>
                        Margen neto
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <SectionTitle
                      hx="h5"
                      title={"Modificaciones"}
                      buttons={[
                        <ButtonAdd
                          key={"bt5"}
                          variant="primary"
                          size="md"
                          onClick={() => {
                            const newAff = { ...affiliate };
                            const newMods = [...modsToAdd];
                            if (!newAff.data.cpa) {
                              newAff.data.cpa = {
                                defaultNumber: null,
                                applyInPrice: null,
                                modifications: [],
                              };
                            }
                            setAffiliate(newAff);
                            newMods.push({
                              type: "BL",
                              code: "",
                              cpa: 0,
                            });
                            setModsToAdd(newMods);
                          }}
                        >
                          <FiPlusSquare />
                        </ButtonAdd>,
                      ]}
                    />
                    {(affiliate.data.cpa ? affiliate.data.cpa.modifications : []).map((mod, index) => (
                      <Grid container spacing={3} key={"modification" + index}>
                        <Grid item xs={12} md={2}>
                          <InputLabel id={"bl-pl-cpa-" + index}>Tipo</InputLabel>
                          <Select
                            fullWidth={true}
                            labelId={"bl-pl-cpa-" + index}
                            value={mod.type}
                            onChange={(nVal) => {
                              const newAff = { ...affiliate };
                              newAff.data.cpa.modifications[index].type = nVal.target.value;
                              setAffiliate(newAff);
                            }}
                          >
                            <MenuItem value={"-"} style={{ color: "grey" }}>
                              Selecciona un valor
                            </MenuItem>
                            <MenuItem key={`valuesbl`} value={"BL"}>
                              Business Line
                            </MenuItem>
                            <MenuItem key={`valuespl`} value={"PL"}>
                              Product Line
                            </MenuItem>
                            <MenuItem key={"valueshl"} value={"HL"}>
                              Hotel Line
                            </MenuItem>
                          </Select>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <InputText
                            title="Código"
                            value={affiliate.data.cpa.modifications[index].code}
                            onChange={(e) => {
                              const newAff = { ...affiliate };
                              newAff.data.cpa.modifications[index].code = e.value;
                              setAffiliate(newAff);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <InputNumber
                            label="CPA"
                            value={mod.cpa}
                            onChange={(e) => {
                              const newAff = { ...affiliate };
                              newAff.data.cpa.modifications[index].cpa = parseFloat(e);
                              setAffiliate(newAff);
                            }}
                            min={0}
                            endAdornment={<>%</>}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <InputLabel id="select-aplicar-sobre">Aplicar sobre</InputLabel>
                          <Select
                            fullWidth={true}
                            labelId="select-aplicar-sobre"
                            title="Aplicar sobre"
                            label="Aplicar sobre"
                            value={affiliate.data.cpa?.modifications[index].applyInPrice ?? "-"}
                            onChange={(nVal) => {
                              const newAff = { ...affiliate };
                              newAff.data.cpa.modifications[index].applyInPrice = nVal.target.value;
                              setAffiliate(newAff);
                            }}
                          >
                            <MenuItem value={"-"} style={{ color: "grey" }} disabled>
                              Selecciona un valor
                            </MenuItem>
                            <MenuItem key={`valuespvp`} value={"pvp"}>
                              PVP
                            </MenuItem>
                            <MenuItem key={`valuesamount`} value={"amount"}>
                              Amount
                            </MenuItem>
                            <MenuItem key={`valuesmargin`} value={"margin"}>
                              Margen
                            </MenuItem>
                            <MenuItem key={`valuesmarginnet`} value={"margin_net"}>
                              Margen neto
                            </MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={1} className="d-flex align-content-center">
                          <ButtonDelete
                            key={"btD" + index}
                            variant="contained"
                            size="large"
                            onClick={() => {
                              const newAff = { ...affiliate };
                              newAff.data.cpa.modifications.splice(index, 1);
                              setAffiliate(newAff);
                            }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                    {modsToAdd.map((mod, index) => (
                      <Grid
                        container
                        spacing={3}
                        key={"modificationToAdd" + index}
                        className="bg-enable-muted mt-3"
                      >
                        <Grid item xs={12} md={2}>
                          <InputLabel id={"bl-pl-mods-" + index}>Tipo</InputLabel>
                          <Select
                            fullWidth={true}
                            labelId={"bl-pl-mods-" + index}
                            value={mod.type}
                            onChange={(nVal) => {
                              const newMods = [...modsToAdd];
                              newMods[index].type = nVal.target.value;
                              setModsToAdd(newMods);
                            }}
                          >
                            <MenuItem value={"-"} style={{ color: "grey" }}>
                              Selecciona un valor
                            </MenuItem>
                            <MenuItem key={`valuesbl`} value={"BL"}>
                              Business Line
                            </MenuItem>
                            <MenuItem key={`valuespl`} value={"PL"}>
                              Product Line
                            </MenuItem>
                            <MenuItem key={`valueshl`} value={"HL"}>
                              Hotel Line
                            </MenuItem>
                          </Select>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <InputText
                            title="Código"
                            value={mod.code}
                            onChange={(e) => {
                              const newMods = [...modsToAdd];
                              newMods[index].code = e.value;
                              setModsToAdd(newMods);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <InputNumber
                            label="CPA"
                            value={mod.cpa}
                            onChange={(e) => {
                              const newMods = [...modsToAdd];
                              newMods[index].cpa = parseFloat(e);
                              setModsToAdd(newMods);
                            }}
                            min={0}
                            endAdornment={<>%</>}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <InputLabel id="select-aplicar-sobre">Aplicar sobre</InputLabel>
                          <Select
                            fullWidth={true}
                            labelId="select-aplicar-sobre"
                            title="Aplicar sobre"
                            label="Aplicar sobre"
                            value={modsToAdd[index].applyInPrice ?? "-"}
                            onChange={(nVal) => {
                              const newMods = [...modsToAdd];
                              newMods[index].applyInPrice = nVal.target.value;
                              setModsToAdd(newMods);
                            }}
                          >
                            <MenuItem value={"-"} style={{ color: "grey" }} disabled>
                              Selecciona un valor
                            </MenuItem>
                            <MenuItem key={`valuespvp`} value={"pvp"}>
                              PVP
                            </MenuItem>
                            <MenuItem key={`valuesamount`} value={"amount"}>
                              Amount
                            </MenuItem>
                            <MenuItem key={`valuesmargin`} value={"margin"}>
                              Margen
                            </MenuItem>
                            <MenuItem key={`valuesmarginnet`} value={"margin_net"}>
                              Margen neto
                            </MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={1} className="d-flex align-content-center">
                          <ButtonDelete
                            key={"btD" + index}
                            variant="contained"
                            size="large"
                            onClick={() => {
                              const newMods = [...modsToAdd];
                              newMods.splice(index, 1);
                              setModsToAdd(newMods);
                            }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </TravBodyCard>
            </TravCard>
          </Grid>
        </Grid>
      )}
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
      <Modal
        show={validationErrors.length > 0}
        fullWidth={false}
        onClose={() => {
          setValidationErrors([]);
        }}
      >
        <ul>
          {validationErrors.map((error, index) => (
            <li key={`error${index}`}>{error}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default AffiliatesManagementView;
