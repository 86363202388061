import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  Box,
  Chip,
} from "@material-ui/core";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { Alert } from "react-bootstrap";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";

const DoblementeApiSearchTableResults = ({ searchResult }) => {
  return (
    <Fragment>
      {searchResult.ok !== undefined && !searchResult.ok && (
        <Alert variant="danger">Ha ocurrido un error al buscar resultados.</Alert>
      )}
      {searchResult.ok && searchResult.results.length === 0 && (
        <Alert variant="info">No hay resultado para estos términos de busqueda.</Alert>
      )}
      {searchResult.ok && searchResult.results.length > 0 && (
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Código Concepto</TableCell>
                <TableCell>Nombre Concepto</TableCell>
                <TableCell>Código Reserva Concepto</TableCell>
                <TableCell>Neto total</TableCell>
                <TableCell>Pvp total </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResult.results.map((row) =>
                Array.isArray(row.Concepts.AvailResponseV2Concept) ? (
                  row.Concepts.AvailResponseV2Concept.map((concept) => (
                    <RowTable key={concept.ConceptCode} row={concept} />
                  ))
                ) : (
                  <RowTable
                    key={row.Concepts.AvailResponseV2Concept.ConceptCode}
                    row={row.Concepts.AvailResponseV2Concept}
                  />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
};

function RowTable(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  const priceTotal = { pvp: 0, net: 0 };

  const { BookOptions } = row;
  let detailList = row.Details.AvailResponseV2Detail;
  if (!Array.isArray(detailList)) {
    detailList = [detailList];
  }

  const details = detailList.map((detail) => {
    const prday = { ...detail };

    let pvp = 0;
    let net = 0;
    if (prday.Total.Taxes.Commissions !== undefined) {
      if (prday.Total.Taxes.Commissions.SellingPrice !== undefined) {
        pvp = parseFloat(prday.Total.Taxes.Commissions.SellingPrice);
      }
      if (prday.Total.Taxes.Commissions.NetAmount !== undefined) {
        net = parseFloat(prday.Total.Taxes.Commissions.NetAmount);
      }
    } else if (prday.Total.Taxes.$.Amount !== undefined) {
      net = parseFloat(prday.Total.Taxes.$.Amount);
      pvp = parseFloat(prday.Total.Taxes.$.Amount);
    }

    prday.price = { pvp, net };

    if (pvp !== 0 && net !== 0) {
      priceTotal.pvp += pvp;
      priceTotal.net += net;
    }

    // Line for the hours
    if (BookOptions !== undefined) {
      if (!Array.isArray(BookOptions.Availability.AvailResponseV2Avail)) {
        BookOptions.Availability.AvailResponseV2Avail = [BookOptions.Availability.AvailResponseV2Avail];
      }

      // Hours availability
      prday.availableHours = BookOptions.Availability.AvailResponseV2Avail.map((hour) => hour.$).filter(
        (dates) => dates.Date === prday.Date
      );
    } else {
      prday.availableHours = [{ Hour: "NA" }];
    }

    return prday;
  });

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.ConceptCode}
        </TableCell>
        <TableCell>{row.ConceptName}</TableCell>
        <TableCell>{row.ConceptBookingCode}</TableCell>
        <TableCell>{priceTotal.net}</TableCell>
        <TableCell>{priceTotal.pvp}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Offer?</TableCell>
                    <TableCell>IsOptional?</TableCell>
                    <TableCell>PerPerson?</TableCell>
                    <TableCell>Neto</TableCell>
                    <TableCell>PvP</TableCell>
                    <TableCell>Horas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map((priceRow, index) => (
                    <TableBodyContent key={`TableBodyContent${index}`} priceRow={priceRow} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const TableBodyContent = ({ priceRow }) => {
  const [openH, setOpenH] = useState(false);

  return (
    <Fragment key={priceRow.Date}>
      <TableRow>
        <TableCell component="th" scope="row">
          {priceRow.Date}
        </TableCell>
        <TableCell>{priceRow.Offer}</TableCell>
        <TableCell>{priceRow.IsOptional}</TableCell>
        <TableCell>{priceRow.PerPerson}</TableCell>
        <TableCell>{priceRow.price.net}</TableCell>
        <TableCell>{priceRow.price.pvp}</TableCell>
        <TableCell>
          <ButtonCustom onClick={() => setOpenH(!openH)} color="primary">
            Ver
          </ButtonCustom>
        </TableCell>
      </TableRow>
      {openH && <RowHours priceRow={priceRow} />}
    </Fragment>
  );
};

const RowHours = ({ priceRow }) => {
  return (
    <TableRow>
      <TableCell colSpan={6}>
        {priceRow.availableHours
          .sort((a, b) => {
            if (a.Hour > b.Hour) return 1;
            if (a.Hour < b.Hour) return -1;
            return 0;
          })
          .map((h) => (
            <Chip key={`${priceRow.Date}_hour${h.Hour}`} label={h.Hour} />
          ))}
      </TableCell>
    </TableRow>
  );
};

RowTable.propTypes = {
  row: PropTypes.object,
};

DoblementeApiSearchTableResults.propTypes = {
  searchResult: PropTypes.object,
};

export default DoblementeApiSearchTableResults;
