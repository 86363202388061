import React, { useState, useEffect, useRef, useCallback } from "react";
import BoxRadius from "@atoms/Boxes/BoxRadius";
import useOutsideAlerter from "@lib/hooks/useOutsideAlerter";

const QueryDropDownListLayoutDesktop = ({
  elements,
  rowHighlighted,
  setRowHighlighted,
  selected,
  closeMe,
  children,
}) => {
  const [elementsState, setElementsState] = useState(elements);
  const elementsStateRef = useRef(elementsState);
  const rowHighlightedRef = useRef(rowHighlighted);

  /**
   * Detect changes by ref
   */
  useEffect(() => {
    elementsStateRef.current = elements;
    setElementsState(elementsStateRef);
  }, [elements]);

  /**
   * Detect any click outside the dropdown to close it
   */
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    closeMe();
  });

  /**
   * Set highlighted row selected by user using keyboard
   */
  const _setRowHighlighted = (data) => {
    rowHighlightedRef.current = data;
    setRowHighlighted(data);
  };

  /**
   * onKeyDown detect. Ops:
   * ArrowUp -> Scroll up pointer to selection
   * ArrowDown -> Scroll Down pointer selection
   * Enter -> Select option where pointer is
   * Escape -> Close Drop down
   */
  const onKeyDown = useCallback((event) => {
    const rowHighlightedRefCurrent = rowHighlightedRef.current;
    const elementsLen = elementsStateRef.current.length;
    let prevent = true;
    switch (event.key) {
      case "ArrowUp": {
        if (rowHighlightedRefCurrent > 0) {
          let newValue = rowHighlightedRefCurrent - 1;
          if (elementsStateRef.current[newValue].type === "title") {
            newValue = newValue > 0 ? newValue - 1 : 1;
          }
          _setRowHighlighted(newValue);
        }
        break;
      }
      case "ArrowDown": {
        if (rowHighlightedRefCurrent < elementsLen - 1) {
          let newValue = rowHighlightedRefCurrent + 1;
          if (elementsStateRef.current[newValue].type === "title") {
            newValue++;
          }
          _setRowHighlighted(newValue);
        }
        break;
      }
      case "Enter": {
        if (rowHighlightedRefCurrent >= 0) {
          selected(elementsStateRef.current[rowHighlightedRefCurrent]);
        }
        break;
      }
      case "Escape": {
        closeMe();
      }
      default:
        prevent = false;
    }
    if (prevent) {
      event.preventDefault();
    }
  }, []);

  /**
   * Detecting any key to execute key down ops
   */
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <BoxRadius
      ref={wrapperRef}
      style={{
        position: "absolute",
        left: "-2px",
        top: "-9px",
        width: "101.5%",
        maxHeight: "670px",
        minHeight: "100px",
        overflowX: "auto",
        zIndex: "2",
        minWidth: "300px",
      }}
    >
      {children}
    </BoxRadius>
  );
};

export default QueryDropDownListLayoutDesktop;
