import React from "react";
import { Grid, TextField, FormControlLabel, Checkbox, MenuItem, Select, InputLabel } from "@material-ui/core";
import { ClickableTitleElements, ElementTagLeft } from "@atoms/Titles/Titles.styles";
import voucherModes from "./data/voucherModes.json";
import { voucherModesEnabled } from "./data/modesEnabled";
import FxAlert from "@atoms/Alerts/FxAlert";

function VoucherConfigurationSelector({ channel, onChange }) {
  function hasVoucherConfiguration() {
    return channel.voucher !== undefined && !channel.voucher?.inherited;
  }
  return (
    <>
      <ClickableTitleElements className="check-box-title">
        <InputLabel>
          <FormControlLabel
            control={
              <ElementTagLeft marginright="10px">
                <Checkbox
                  color="primary"
                  checked={channel.voucher.inherited}
                  onChange={(e) => onChange("voucher", { ...channel.voucher, inherited: e.target.checked })}
                />
              </ElementTagLeft>
            }
            label={"¿Modo de bono igual al de reserva?"}
          />
        </InputLabel>
      </ClickableTitleElements>
      {hasVoucherConfiguration() && (
        <>
          <Select
            fullWidth
            value={channel.voucher.mode}
            onChange={(e) => onChange("voucher", { ...channel.voucher, mode: e.target.value })}
          >
            {voucherModes
              .filter((bookMode) => voucherModesEnabled.includes(bookMode.code))
              .map((bm, i) => (
                <MenuItem value={bm.code} key={`voucherMode${i}`}>
                  {bm.txt}
                </MenuItem>
              ))}
          </Select>
          {channel.voucher.mode === "frominboundemail" && (
            <FxAlert variant="info">
              <span>
                Este modo de bono necesita un desarrollo específico. Antes de seleccionarlo, consúltalo con el
                equipo de tecnología.
              </span>
            </FxAlert>
          )}
          {["stemail", "doblementev2", "doblementev2_esqui", "qrcode"].includes(channel.voucher.mode) && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  key={"voucherConfiguration.payable"}
                  label="Pagadero"
                  value={channel.voucher.configuration.payable}
                  onChange={(e) => {
                    const newVocucher = { ...channel.voucher };
                    newVocucher.configuration.payable = e.target.value;
                    onChange("voucher", newVocucher);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  key={"voucherConfiguration.logo"}
                  label="Logo"
                  value={channel.voucher.configuration.logo}
                  onChange={(e) => {
                    const newVocucher = { ...channel.voucher };
                    newVocucher.configuration.logo = e.target.value;
                    onChange("voucher", newVocucher);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  key={"voucherConfiguration.address"}
                  label="Dirección"
                  value={channel.voucher.configuration.address}
                  onChange={(e) => {
                    const newVocucher = { ...channel.voucher };
                    newVocucher.configuration.address = e.target.value;
                    onChange("voucher", newVocucher);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  key={"voucherConfiguration.Email"}
                  label="Email"
                  value={channel.voucher.configuration.email}
                  onChange={(e) => {
                    const newVocucher = { ...channel.voucher };
                    newVocucher.configuration.email = e.target.value;
                    onChange("voucher", newVocucher);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  key={"voucherConfiguration.phone"}
                  label="Teléfono"
                  value={channel.voucher.configuration.phone}
                  onChange={(e) => {
                    const newVocucher = { ...channel.voucher };
                    newVocucher.configuration.phone = e.target.value;
                    onChange("voucher", newVocucher);
                  }}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}

export default VoucherConfigurationSelector;
