import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { useLocation } from "react-router-dom";

const START_HEIGHT = 512;

const useIframeHeight = (location) => {
  const [iframeHeight, setIframeHeight] = useState(START_HEIGHT);
  const messageReceivedFromInsideIframe = (event) => {
    if (event.data["x-tr-fenix-height"]) {
      console.log("mensaje", event.data["x-tr-fenix-height"]);
      setIframeHeight(event.data["x-tr-fenix-height"]);
    }
  };
  useEffect(() => {
    window.addEventListener("message", messageReceivedFromInsideIframe);
    return () => window.removeEventListener("message", messageReceivedFromInsideIframe);
  }, []);
  useEffect(() => {
    setIframeHeight(START_HEIGHT);
  }, [location]);
  console.log(iframeHeight);
  return iframeHeight;
};

const AppLegacy = () => {
  const location = useLocation();
  const iframeHeight = useIframeHeight(location);
  let url = location.pathname.replace("/app-legacy", "");
  if (url === "") {
    url = "/";
  }
  return <Iframe url={url} width="100%" height={iframeHeight} frameBorder="0" style={{ boder: "0px" }} />;
};

export const LegacyDashboard = () => {
  const iframeHeight = useIframeHeight();
  return (
    <Iframe url={"/dashboard"} width="100%" height={iframeHeight} frameBorder="0" style={{ boder: "0px" }} />
  );
};

export default AppLegacy;
