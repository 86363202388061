import axios from "axios";

const prefix = "/api/v1/ticketing-bc/sessions";

export const fetchSessions = async (filters) => {
  let params = {};
  if (filters.params !== undefined) {
    params = filters.params;
  }
  if (filters.precinctCode !== undefined) {
    params = { params: { ...filters } };
  }
  const { data } = await axios.get(`${prefix}`, { params });
  return data.data;
};

export const fetchSession = async (session) => {
  const { data } = await axios.get(`${prefix}/${session.sessionId}`, {});
  return data;
};

export const saveSession = async (session) => {
  await axios.put(`${prefix}/${session.id}`, session);
};
