import React from "react";
import { MdDownload, MdOutlineDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";

const AffiliatesReportsSentActions = ({ downloadAffiliateReportSent }) => {
  const downloadAction = {
    code: "download",
    name: "Descargar",
    icon: <MdDownload />,
    func: downloadAffiliateReportSent,
  };

  const affiliatesReportsSentActions = [downloadAction];

  return {
    affiliatesReportsSentActions,
  };
};

export default AffiliatesReportsSentActions;
