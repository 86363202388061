import React, { Fragment, useState, useEffect } from "react";
import { getRule, putRule } from "../../hooks/useJackSparrowRules";
import SectionTitle from "@atoms/Titles/SectionTitle";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { useParams, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Card, Alert } from "react-bootstrap";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import { TextField } from "@material-ui/core";
import FxSwitch from "@atoms/Switches/FxSwitch";
import Typography from "@material-ui/core/Typography";
import InputQueryDropdownSearch from "@atoms/Inputs/InputQueryDropdownSearch";
import { ChipDeleteService, TitleListService, AlertNoAccommodation } from "./JackSparrowRulesEditor.styles";
import { UsChipEmail, ChipLabel, ChipDelete } from "@atoms/Content/Chips";

const JackSparrowRulesEditor = ({}) => {
  const history = useHistory();
  const { idRule } = useParams();
  const { loadingRule, rule } = getRule(idRule);
  const [form, setForm] = useState({
    id: idRule,
    hcode: "",
    market: "es",
    rules: {
      base: false,
    },
  });
  const isEdition = Object.keys(rule).length > 0;
  const [alertData, setAlertData] = useState({ text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorModal, setErrorModal] = useState({ open: false, errors: [] });
  const [expanded, setExpanded] = useState({ 1: true, 2: false });

  useEffect(() => {
    if (isEdition) {
      setForm(rule);
    } else {
      setForm({
        id: idRule,
        hcode: "",
        market: "es",
        rules: {
          base: false,
        },
      });
    }
  }, [loadingRule]);

  const backToTable = () => {
    history.push(`/app/product/jack-sparrow-rules/list`);
  };

  const ruleValidation = (point) => {
    const errors = [];
    if (point.hcode === "") {
      errors.push(`El código de hotel está vacíO.`);
    }
    return errors;
  };

  const saveRule = async () => {
    const error = ruleValidation(form);
    if (error.length > 0) {
      setErrorModal({
        open: true,
        errors: error,
      });
    } else {
      const response = await putRule(form);
      if (response.ok) {
        setAlertOpen(true);
        setAlertData({ text: `Regla modificada con éxito` });
      } else {
        setErrorModal({
          open: true,
          errors: [`Error al guardar la regla de ${form.id}: ${response.error}`],
        });
      }
    }
  };

  return (
    <Fragment>
      <SectionTitle
        title={`Regla ${form.id}`}
        info={[]}
        backFunction={backToTable}
        buttons={[
          <ButtonSave key={"btG"} color="primary" onClick={saveRule}>
            Guardar Regla
          </ButtonSave>,
        ]}
      />
      <Card>
        <Card.Header>Informacion General</Card.Header>
        <Card.Body>
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Grid item xs={3}>
                  <TextField disabled={true} label="Id" value={form.id} onChange={(nVal) => {}} />
                </Grid>
                <Grid item>
                  <SelectMarket
                    key={"bt1"}
                    selection={form.market}
                    onChangeMarket={(market) => {
                      const newForm = { ...form };
                      newForm.market = market;
                      setForm(newForm);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Alojamiento</Card.Header>
        <Card.Body>
          <Grid item xs={12}>
            <InputQueryDropdownSearch
              label={"Buscador de alojamiento"}
              getRequestPath={`/api/private/accommodations?query=%%QUERY%%`}
              optionFormat={(option) => {
                return (
                  <div>
                    <b>
                      {option.code} | {option.name}
                    </b>
                  </div>
                );
              }}
              onSelectOption={(nuValue) => {
                const newForm = { ...form };
                newForm.hcode = nuValue.code;
                setForm(newForm);
              }}
            />
            <br />
            {form.hcode === "" ? (
              <AlertNoAccommodation variant="info">Ningun alojamiento añadido</AlertNoAccommodation>
            ) : (
              <>
                <Grid container alignItems="baseline">
                  <Grid item>
                    {/* <div>
                                <br /> */}
                    <TitleListService>Alojamiento seleccionado</TitleListService>
                    {/* </div> */}
                  </Grid>
                  <Grid item>
                    <UsChipEmail key={form.hcode}>
                      <ChipLabel>{form.hcode}</ChipLabel>
                      <ChipDelete
                        onClick={() => {
                          const newForm = { ...form };
                          newForm.hcode = "";
                          setForm(newForm);
                        }}
                      />
                    </UsChipEmail>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Reglas</Card.Header>
        <Card.Body>
          <SectionTitle
            title={`Regla Base`}
            info={[
              "Esta regla permite saltarse el PVP desde el Viernes a las 21:00 hasta el Domingo a las 23:59.",
            ]}
            buttons={[
              <FxSwitch
                value="RULES.BASE"
                labels={["Habilitada", "Deshabilitada"]}
                status={form.rules.base}
                onChange={(nuValue) => {
                  const nuForm = { ...form };
                  nuForm.rules.base = nuValue;
                  setForm(nuForm);
                }}
              />,
            ]}
          />
        </Card.Body>
      </Card>
      <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
      <ErrorModal
        show={errorModal.open}
        listErrors={errorModal.errors}
        onClose={() => setErrorModal({ open: false, errors: [] })}
      />
    </Fragment>
  );
};

export default JackSparrowRulesEditor;
