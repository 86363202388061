import React, { useState } from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import { FiPlusSquare } from "react-icons/fi";
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";

const AffiliatesReportsListView = ({
  affiliatesReportsList,
  tableHead,
  affiliatesReportsActions,
  MAX_AffiliatesReports_VIEW,
  affiliates,
  alertOpen,
  alertData,
  setAlertOpen,
}) => {
  const goToAdd = () => {
    window.location = "/app/marketing/affiliate/reports/_new";
  };
  return (
    <>
      {affiliates.length > 0 && (
        <>
          <CardTitle
            title={`Reportes de afiliados`}
            buttons={[
              <ButtonAdd key={"bt5"} variant="primary" size="md" onClick={goToAdd}>
                <FiPlusSquare />
              </ButtonAdd>,
            ]}
          />
          <FenixTable
            loading={affiliatesReportsList.loadingList}
            tableHead={tableHead}
            tableContent={affiliatesReportsList.list}
            actions={affiliatesReportsActions}
            maxNumberPerPages={MAX_AffiliatesReports_VIEW}
            pagination={true}
            isSelectable={false}
          />
        </>
      )}
      {affiliates.length === 0 && (
        <CardTitle title={`Reportes de afiliados`} buttons={[]} description={`No hay afiliados creados.`} />
      )}
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
    </>
  );
};

export default AffiliatesReportsListView;
