import React, { useState } from "react";
import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import moment from "moment";
import ButtonSearch from "@atoms/Buttons/ButtonSearch";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import FenixTable from "@molecules/Tables/FenixTable";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import RemittancesApi from "../api/RemittancesApi";
import useRemittances from "./useRemittances";

const RemittancePaid = () => {
  const [searchParams, setSearchParams] = useState({
    datei: moment().subtract(1, "months").format("YYYY-MM-DD"),
    dateo: moment().format("YYYY-MM-DD"),
  });

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remittances, setRemittances] = useState([]);

  const { tableHeadRemittances, parseRemittanceResponse } = useRemittances();

  async function onSearchRemittancePaid() {
    setLoading(true);

    const remittancesResp = await RemittancesApi.getRemittancesPaid(searchParams.datei, searchParams.dateo);
    setRemittances(parseRemittanceResponse(remittancesResp));

    setLoading(false);
  }

  return (
    <AccordionItem open={open} title={<h4>Remesas pagadas</h4>} onChange={() => setOpen(!open)}>
      <TravCard>
        <TravBodyCard>
          <h5>Busca por fecha de pago</h5>
          <div style={{ display: "flex" }}>
            <FxDatePickersFromTo
              labelFrom={"Desde"}
              dateFromInitial={searchParams.datei}
              labelTo={"Hasta"}
              dateToInitial={searchParams.dateo}
              onChange={({ datei, dateo }) => setSearchParams({ datei, dateo })}
            />
            <ButtonSearch onClick={onSearchRemittancePaid} />
          </div>

          <FenixTable
            loading={loading}
            tableHead={[
              ...tableHeadRemittances,
              { code: "paidBy", name: "Pagado por", type: "string" },
              { code: "paidAt", name: "Fecha pagado", type: "date" },
            ]}
            tableContent={remittances}
            pagination
          />
        </TravBodyCard>
      </TravCard>
    </AccordionItem>
  );
};

export default RemittancePaid;
