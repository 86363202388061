import axios from "axios";

const URL = "/api/v1/accommodation-bc/main/accommodations";

export const assignImagesToAccommodation = async (hcode, filesUrls) => {
  let thestate = { loading: true, ok: false };
  try {
    const response = await axios.post(`${URL}/${hcode}/add-images`, filesUrls);
    if (response.status === 201) {
      thestate = { loading: false, ok: true };
    }
    thestate = { ...thestate };
  } catch (error) {
    thestate = { loading: false, ok: false };
    // console.error(error);
  }
  return thestate;
};
