import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";

const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

function MonthSelectorButtons({ monthSelected, onChangeMonth }) {
  return (
    <ButtonGroup
      style={{ width: "100%", margin: "25px 0" }}
      variant="contained"
      aria-label="outlined primary button group"
    >
      {monthNames.map((month, index) => (
        <Button
          key={`month${month}`}
          style={{ width: "100%", textTransform: "none" }}
          color={monthSelected === index && "primary"}
          onClick={() => onChangeMonth(index)}
        >
          {month}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export default MonthSelectorButtons;
