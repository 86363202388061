import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import {
  PrincipalTitleElements,
  ElementsTitleRight,
  PrincipalTitleElement,
  AlerTitle,
} from "./Titles.styles";
import SectionTitle from "@atoms/Titles/SectionTitle";

/* RMR: Niños, no usemos este si no SectionTitle. Dejo este pendiente de quitarlo de donde se llame */

function CardTitle({ title, info = [], buttons, children }) {
  return <SectionTitle title={title} info={info} buttons={buttons} />;
}

// CardTitle.propTypes = {
//   title: PropTypes.string,
//   info: PropTypes.string,
//   buttons: PropTypes.array,
// };

export default CardTitle;
