import React from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { FiPlusSquare } from "react-icons/fi";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import MenuItemUploadCSV from "@molecules/Buttons/MenuItemUploadCSV";
import MenuItemDownloadCSV from "@molecules/Buttons/MenuItemDownloadCSV";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import { Alert } from "react-bootstrap";
import usePagesAliasesListState from "./PagesAliasesList.state";
import { wordcontrol, filename, csvHead, csvSpecs, contentFields } from "./constants";
import { ThreeDotMenu, useThreeDotMenu } from "@molecules/Menus/ThreeDotMenu";
import UploadResultAlert from "@molecules/Alerts/UploadResultAlert";

const PagesAliasesListView = ({ type }) => {
  const {
    MAX_PAGES_VIEW,
    errorState,
    setErrors,
    market,
    loading,
    newList,
    rowsSelected,
    onChangeMarketSelections,
    handleMarket,
    tableHead,
    addNewPageAlias,
    actions,
    generateCsvFromList,
    bulkUploadPagesAliases,
    reloadAliases,
    uploadResult,
    onUploadEnded,
  } = usePagesAliasesListState({ type });
  const { anchorEl, onOpenMenu, handleClose } = useThreeDotMenu();
  return (
    <>
      <CardTitle
        title={"Listado de Alias de páginas"}
        buttons={[
          <SelectMarket key={"bt1"} selection={market} onChangeMarket={handleMarket} />,
          <ThreeDotMenu key={0} style={{ minWidth: "0" }} {...{ anchorEl, onOpenMenu, handleClose }}>
            <MenuItemUploadCSV
              itemsName={"itemsName"}
              wordcontrol={wordcontrol}
              filename={`${filename}`}
              buttonLabel={`Subir Alias (${market.toUpperCase()})`}
              specs={csvSpecs}
              uploadFunction={bulkUploadPagesAliases}
              onUploadEnded={onUploadEnded}
            />
            <MenuItemDownloadCSV
              wordcontrol={wordcontrol}
              filename={`${filename}`}
              head={csvHead}
              specs={csvSpecs}
              generateTable={generateCsvFromList}
              buttonLabel={`Descargar Alias (${market.toUpperCase()})`}
              onDownloadEnded={reloadAliases}
            />
          </ThreeDotMenu>,
        ]}
      />
      <ErrorModal
        show={errorState.show}
        listErrors={errorState.list}
        onClose={() => setErrors({ show: false, list: [] })}
      />
      {uploadResult && (
        <UploadResultAlert uploadResult={uploadResult} onClose={() => setUploadResult({ status: "close" })} />
      )}
      {market === null ? (
        <Alert variant={"info"}>
          <span>Selecciona un mercado</span>
        </Alert>
      ) : (
        <>
          {market === null ? (
            <Alert variant={"info"}>
              <span>Selecciona un mercado</span>
            </Alert>
          ) : (
            <FenixTable
              loading={loading}
              tableHead={tableHead}
              tableContent={newList}
              actions={actions}
              maxNumberPerPages={MAX_PAGES_VIEW}
              pagination={true}
              isSelectable={true}
              selectedRows={rowsSelected[market]}
              onChangeSelections={onChangeMarketSelections}
            />
          )}
        </>
      )}
    </>
  );
};

export default PagesAliasesListView;
