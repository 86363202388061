import React from "react";
import { Grid } from "@material-ui/core";
import BookingConfigurationSelector from "./BookingConfigurationSelector";
import VoucherConfigurationSelector from "./VoucherConfigurationSelector";
import CancellationConfigurationSelector from "./CancellationConfigurationSelector";
import BookingGroupConfigurationSelector from "./BookingGroupConfigurationSelector";
import { AiOutlineConsoleSql } from "react-icons/ai";

function ChannelConfigurationSelector({ channel, onChange, isPrecinct = false }) {
  function onChangeConfiguration(field, newValue) {
    const newChannel = { ...channel, [field]: newValue };
    if ((field === "voucher" || field === "cancellation") && newChannel[field].mode === undefined) {
      newChannel[field].mode = channel.booking.mode;
    }

    if (
      field === "cancellation" &&
      newChannel[field].mode === "stemail" &&
      newChannel[field].configuration === undefined
    ) {
      newChannel[field].configuration = { emails: [], cco: [] };
    }

    if (field === "voucher" && newChannel[field].mode === "stemail") {
      if (newChannel[field].configuration === undefined) {
        newChannel[field].configuration = { payable: "", logo: "", address: "", email: "", phone: "" };
      } else {
        if (newChannel[field].configuration.payable === undefined) {
          newChannel[field].payable = "";
        }
        if (newChannel[field].configuration.logo === undefined) {
          newChannel[field].logo = "";
        }
        if (newChannel[field].configuration.address === undefined) {
          newChannel[field].address = "";
        }
        if (newChannel[field].configuration.email === undefined) {
          newChannel[field].email = "";
        }
        if (newChannel[field].configuration.phone === undefined) {
          newChannel[field].phone = "";
        }
      }
    }
    onChange(newChannel);
  }

  return (
    <>
      <Grid item xs={12}>
        <BookingConfigurationSelector
          channel={channel}
          onChange={onChangeConfiguration}
          isPrecinct={isPrecinct}
        />
      </Grid>
      <Grid item xs={12}>
        <VoucherConfigurationSelector channel={channel} onChange={onChangeConfiguration} />
      </Grid>
      <Grid item xs={12}>
        <CancellationConfigurationSelector channel={channel} onChange={onChangeConfiguration} />
      </Grid>
      {isPrecinct && (
        <Grid item xs={12}>
          <BookingGroupConfigurationSelector channel={channel} onChange={onChangeConfiguration} />
        </Grid>
      )}
    </>
  );
}

export default ChannelConfigurationSelector;
