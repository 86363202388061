/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

const AccordionItem = ({
  open,
  title,
  subText,
  leftIcon,
  hideSubTextOnOpen,
  onChange,
  children,
  ...props
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(open || false);

  useEffect(() => {
    onChange(expanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const changeExpanded = () => {
    setExpanded(!expanded);
    // onChange(!expanded);
  };

  return (
    <Accordion {...props} expanded={expanded} onChange={changeExpanded}>
      <AccordionSummary aria-label="Expand" expandIcon={<ExpandMoreIcon />}>
        {title}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    overflow: "hidden",
    // backgroundColor: "transparent",
    "&.Mui-expanded": { boxShadow: "none!important" },
    "&.MuiAccordion-root.Mui-expanded": { marginTop: "0!important" },
    "& .MuiAccordionSummary-root.Mui-expanded": { minHeight: "48px" },
    "& .MuiAccordionSummary-content.Mui-expanded": { margin: "12px 0" },
  },
  heading: {
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    " &:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  details: { display: "block", padding: "20px", paddingTop: "10px", backgroundColor: "#fafafa" },
}));

AccordionItem.propTypes = {
  /**
   * External control for the opening of the Accordion (optional)
   */
  open: PropTypes.bool,
  /**
   * Function fired when opened (optional)
   */
  onChange: PropTypes.func,
  /**
   * Title of the section
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Subtitle
   */
  subText: PropTypes.string,
  /**
   * Mark if subtext is shown when open
   */
  hideSubTextOnOpen: PropTypes.bool,
  /**
   * Left icon.
   */
  leftIcon: PropTypes.node,
};

AccordionItem.defaultProps = { open: false, hideSubTextOnOpen: false, onChange: () => {} };

export default AccordionItem;
