import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { Card } from "react-bootstrap";
import ListEditable from "@atoms/Lists/ListEditable";
import FxSwitch from "@atoms/Switches/FxSwitch";

function JsonPageEditorHeaders({ onChange, form }) {
  return (
    <Card>
      <Card.Body>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <TextField
                  label="Titulo"
                  value={form.title}
                  onChange={(nVal) => {
                    const nuForm = { ...form };
                    nuForm.title = nVal.target.value;
                    onChange(nuForm);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FxSwitch
                  // disabled={getParentId()}
                  value="end"
                  labels={["Indexado", "No indexado"]}
                  status={form.indexable}
                  onChange={(nVal) => {
                    const nuForm = { ...form };
                    nuForm.indexable = nVal;
                    onChange(nuForm);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descripción"
                  value={form.description}
                  onChange={(nVal) => {
                    const nuForm = { ...form };
                    nuForm.description = nVal.target.value;
                    onChange(nuForm);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ListEditable
              title={"Palabras clave"}
              info={form.keywords}
              form={form}
              typeInfo={"tick"}
              onChangeList={(newValue) => {
                const nuForm = { ...form };
                nuForm.keywords = newValue;
                onChange(nuForm);
              }}
            />
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
}

export default JsonPageEditorHeaders;
