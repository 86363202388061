import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { MdSearch } from "react-icons/md";

function FenixTableInputSearch({ inputText, onChangeInput }) {
  const onChange = (e) => {
    onChangeInput(e.target.value);
  };

  return (
    <TextField
      variant="outlined"
      label="Buscar en la tabla"
      value={inputText}
      type="search"
      size="small"
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MdSearch />
          </InputAdornment>
        ),
      }}
    />
  );
}

FenixTableInputSearch.propTypes = {};

export default FenixTableInputSearch;
