import React, { useState } from "react";
import CustomModal from "@molecules/Modal/CustomModal";
import Money from "@atoms/Money/Money";
import ButtonCancel from "@atoms/Buttons/ButtonCancel";
import ButtonCheck from "@atoms/Buttons/ButtonCheck";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import FxAlert from "@atoms/Alerts/FxAlert";
import { useRemittance } from "../../contexts/PaymentsToSuppliersContext";
import useRemittanceView from "./useRemittanceView";

const RemittanceConfirmationModal = ({ open, onClose }) => {
  const { remittance, remittanceLoading, getRemittanceTotalAmount } = useRemittance();
  const { confirmRemittance } = useRemittanceView();

  const [onError, setOnError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [onSuccess, setOnSuccess] = useState(false);

  const ninvoices = remittance?.invoices.length ?? 0;
  const ntotal = getRemittanceTotalAmount();

  async function onConfirmRemittance() {
    setOnError(false);
    try {
      await confirmRemittance(remittance);
      setOnSuccess(true);
    } catch (error) {
      setOnError(true);
      const errorMessage = error.response?.data ? JSON.stringify(error.response.data) : error;
      setErrorMsg(errorMessage);
    }
  }

  function onCloseSuccess() {
    window.location.reload();
  }

  return (
    <CustomModal
      loading={false}
      title={`Vas a crear una remesa nueva`}
      open={open}
      handleClose={onClose}
      bigModal={true}
      closeOnClickOutside={false}
    >
      {remittanceLoading ? (
        <>
          <CenterLoaderCircle />
          <p>Creando remesa...</p>
        </>
      ) : (
        <>
          {onSuccess ? (
            <FxAlert variant={"success"}>
              {
                "¡Enhorabuena! Has creado una nueva remesa. Ahora puedes ir a la sección de remesas para verla y abonarla."
              }
              <ButtonCheck onClick={onCloseSuccess}>{"¡Entendido!"}</ButtonCheck>
            </FxAlert>
          ) : (
            <>
              {onError && (
                <FxAlert variant={"error"}>
                  {
                    "¡Lo sentimos! Ha ocurrido un error al confirmar la remesa. Es posible que alguna factura no este en el estado necesario para ello. Revisa esta información y vuelve a intentarlo."
                  }
                  {`\nDetalles del error: ${errorMsg}`}
                </FxAlert>
              )}
              <ul>
                <li>{`${ninvoices} factura${ninvoices !== 1 ? "s" : ""}`}</li>
                <li>
                  {`Total acumulado: `}
                  <Money amount={ntotal} />
                </li>
              </ul>
              <div>
                <p>¿Quieres confirmar la creacion de esta remesa?</p>
                <ButtonCheck onClick={onConfirmRemittance}>{"Confirmar"}</ButtonCheck>
                <ButtonCancel customcolor={"tomato"} onClick={onClose}>
                  {"Cerrar"}
                </ButtonCancel>
              </div>
            </>
          )}
        </>
      )}
    </CustomModal>
  );
};

export default RemittanceConfirmationModal;
