import React, { Fragment, useState, useEffect } from "react";
import { DragAndDrop } from "@lib/layout/DnD";
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import { DraggableListBox, RemoveOption } from "./CrossSellingTicketsDraggableList.styles";
import FxSwitch from "@atoms/Switches/FxSwitch";
import FxAlert from "@atoms/Alerts/FxAlert";
import TextField from "@material-ui/core/TextField";
import HtmlTextEditor from "@atoms/Inputs/HtmlTextEditor/HtmlTextEditor";
import Grid from "@material-ui/core/Grid";
import { Card } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import CrossSellingProductsList from "./CrossSellingProductsList";
import { AlertNoAccommodation } from "./CrossSellingProductsList.styles";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";

const CrossSellingTicketsDraggableList = ({ title, list, onChangeList, ticketsProducts }) => {
  const [errorInfo, setErrorInfo] = useState(false);
  const [listLocal, setListLocal] = useState(list);
  const [hoverIndex, setHoverIndex] = useState(-1);
  // eslint-disable-next-line no-unused-vars
  const [hideIndex, setHideIndex] = useState(-1);
  const [editObjectFromList, setEditObjectFromList] = useState({ index: -1, value: "" });
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });

  useEffect(() => {
    setListLocal(list);
  }, [list]);

  const onDrop = (dragged, dropArea) => {
    const oldIndex = dragged.uid;
    const newIndex = dropArea.uid;
    if (newIndex !== oldIndex) {
      const newList = [...list];
      const oldElement = newList.splice(oldIndex, 1);
      newList.splice(newIndex, 0, oldElement[0]);
      onChangeList(newList);
    }
    setHoverIndex(-1);
  };

  const onHover = (dragging, dropArea) => {
    setHoverIndex(dropArea.uid);
  };

  const removeFromList = (index) => {
    setConfirmationDialog({
      open: true,
      text: `¿Estas seguro que desea eliminar?`,
      body: listLocal[index][0],
      onConfirm: () => {
        list.splice(index, 1);
        setConfirmationDialog({
          open: false,
          text: "",
          body: "",
          onConfirm: () => {},
        });
        onChangeList(list);
      },
    });
  };

  const resetEdit = () => {
    setEditObjectFromList({ index: -1, value: "" });
  };

  return (
    <Fragment>
      {listLocal.map((objectFromList, index) => (
        <Fragment key={index}>
          <DragAndDrop group={title} uid={index} onDrop={onDrop} startDrag={() => {}} onHover={onHover}>
            <DraggableListBox active={hoverIndex === index} hide={hideIndex === index}>
              <Fragment>
                <Card>
                  <Card.Header>
                    <Grid container justify="space-between" alignItems="baseline">
                      <Grid item>
                        <GiHamburgerMenu
                          style={{
                            fontSize: "1.4em",
                            fontWeight: "0",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <p
                          style={{
                            fontWeight: "700",
                            color: " #ffae17",
                            fontSize: "1.3em",
                          }}
                        >
                          {objectFromList.name}
                        </p>
                      </Grid>
                      <Grid item>
                        <RemoveOption
                          onClick={(evt) => {
                            evt.preventDefault();
                            removeFromList(index);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Card.Header>
                  <Card.Body>
                    <Grid container justify="space-between" spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          label="Titulo del bloque"
                          variant="outlined"
                          size="small"
                          value={objectFromList.title}
                          onChange={(e) => {
                            const newList = [...list];
                            newList[index].title = e.target.value;
                            onChangeList(list);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Nombre del ticket"
                          variant="outlined"
                          size="small"
                          value={objectFromList.name}
                          onChange={(e) => {
                            const newList = [...list];
                            newList[index].name = e.target.value;
                            onChangeList(list);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Imagen del ticket"
                          variant="outlined"
                          size="small"
                          value={objectFromList.image}
                          onChange={(e) => {
                            const newList = [...list];
                            newList[index].image = e.target.value;
                            onChangeList(list);
                          }}
                        />
                      </Grid>
                      <br />
                      <Grid item xs={12}>
                        <HtmlTextEditor
                          id="shortDescription"
                          value={objectFromList.description}
                          onChange={(e) => {
                            const newList = [...list];
                            newList[index].description = e;
                            onChangeList(list);
                          }}
                          placeholder={"Escribe aquí la descripcion."}
                          valid={!errorInfo}
                          // width={"500px"}
                          height={300}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ backgroundColor: "#eef0f8", borderRadius: "5px", padding: "10px" }}>
                          <Grid container justify="center">
                            <Grid item>
                              <b>
                                <span style={{ fontSize: "1.3em" }}>Listado de Productos</span>
                              </b>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container justify="space-between" alignItems="baseline">
                                <Grid item xs={12}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={3}>
                                      <FormControl>
                                        <InputLabel id="demo-simple-select">Precio de referencia</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={["", ...objectFromList.productsToUse].findIndex((cv) => {
                                            return cv === objectFromList.productForPriceRef;
                                          })}
                                          label={"Precio referencia"}
                                          onChange={(e) => {
                                            const newList = [...list];
                                            newList[index].productForPriceRef =
                                              objectFromList.productsToUse[e.target.value];
                                            if (e.target.value === 0) {
                                              newList[index].productForPriceRef = "";
                                            } else {
                                              newList[index].productForPriceRef =
                                                objectFromList.productsToUse[e.target.value];
                                            }
                                            onChangeList(newList);
                                          }}
                                        >
                                          {["MAS BARATO", ...objectFromList.productsToUse].map(
                                            (cv, index) => (
                                              <MenuItem key={`key${cv}`} value={index}>
                                                {cv}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item>
                                      <FxSwitch
                                        value="useAllProducts"
                                        labels={["Usar todos los productos", "Usar todos los productos"]}
                                        status={objectFromList.useAllProducts}
                                        onChange={(nuValue) => {
                                          const newList = [...list];
                                          newList[index].useAllProducts = nuValue;
                                          newList[index].productsToUse =
                                            ticketsProducts[objectFromList.precinctId];
                                          onChangeList(newList);
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <br />
                            {objectFromList.useAllProducts && (
                              <Grid item xs={12} style={{ marginTop: "15px" }}>
                                <FxAlert severity="info" variant="info">
                                  Se usaran todos los productos relacionados con este tickets.
                                </FxAlert>
                              </Grid>
                            )}
                            {!objectFromList.useAllProducts && (
                              <Grid item xs={12}>
                                <CrossSellingProductsList
                                  products={ticketsProducts[objectFromList.precinctId]}
                                  productsToUse={objectFromList.productsToUse}
                                  onChange={(nuValue) => {
                                    const newList = [...list];
                                    newList[index].productsToUse = nuValue;
                                    if (!nuValue.includes(objectFromList.productForPriceRef)) {
                                      newList[index].productForPriceRef = "";
                                    }
                                    onChangeList(newList);
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Card.Body>
                </Card>
              </Fragment>
            </DraggableListBox>
          </DragAndDrop>
        </Fragment>
      ))}

      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={confirmationDialog.onConfirm}
        onDeny={() => {
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
    </Fragment>
  );
};

export default CrossSellingTicketsDraggableList;
