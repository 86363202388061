import React, { useState, useEffect } from "react";
import axios from "axios";
import { MdEdit, MdMoney } from "react-icons/md";
import { BsCurrencyDollar } from "react-icons/bs";
import { useAccountingSuppliersManagement, useAccountingSuppliersList } from "../hooks";
// import AccountingSuppliersListActions from "./AccountingSuppliers.actions";
import { Tooltip } from "@material-ui/core";
import MarketChip from "@atoms/Content/MarketChip";
import { FaSuitcase } from "react-icons/fa";

const AccountingSuppliersState = ({ supplierCode }) => {
  const MAX_ACCOUNTINGSUPPLIERS_VIEW = 25;
  const [accountingSuppliersList, setAccountingSuppliersList] = useState({
    loadingList: true,
    list: [],
    infoList: { total: 0 },
    pages: 1,
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [query, setQuery] = useState("");
  const [accountingSupplierToManageId, setAccountingSupplierToManageId] = useState(supplierCode);
  const [accountingSupplierToManage, setAccountingSuppliersToManage] = useState(undefined);
  const [managementMode, setManagementMode] = useState(supplierCode ? "edit" : "list");
  const [previousMMode, setPreviousMMode] = useState(["list"]);
  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };

  const headActions = ["Subcuenta", "Nombre", "Codigo", "NIF/CIF", "IBAN"];
  const specs = {
    subcount: "",
    name: "",
    code: "",
    identificationDocumentNumber: "",
    bankAccount: "",
  };

  const updateAccountingSuppliersList = async () => {
    setAccountingSuppliersList({
      loadingList: true,
      list: [],
      infoList: { total: 0 },
      pages: 1,
    });
    const { accountingSuppliersData } = await useAccountingSuppliersList();
    setAccountingSuppliersList({
      loadingList: accountingSuppliersData.loadingList,
      list: accountingSuppliersData.list,
      infoList: {
        total: accountingSuppliersData.list.length ?? 0,
      },
      pages: accountingSuppliersData.list.length / MAX_ACCOUNTINGSUPPLIERS_VIEW,
    });
  };

  const deleteAccountingSuppliers = async (id) => {};

  const editAccountingSupplierMode = (id, mode) => {
    setAccountingSupplierToManageId(id);
    const prev = previousMMode;
    prev.push(managementMode);
    setPreviousMMode(prev);
    changeManagementMode(mode);
  };

  const accountingSuppliersActions = [
    {
      code: "management",
      name: "Editar",
      icon: <MdEdit />,
      func: (supplier) => {
        editAccountingSupplierMode(supplier.code, "edit");
      },
    },
    {
      code: "bankAccounts",
      name: "Cuentas bancarias",
      icon: "💳",
      func: (supplier) => {
        editAccountingSupplierMode(supplier.code, "bankAccounts");
      },
    },
  ];

  useEffect(() => {
    async function loadData() {
      await updateAccountingSuppliersList();
    }
    loadData();
  }, []);

  useEffect(() => {
    if (accountingSupplierToManageId !== "new_supplier" && accountingSupplierToManageId !== "") {
      async function loadData() {
        await updateAccountingSupplierToManage();
      }
      loadData();
    } else {
      setAccountingSuppliersToManage({});
    }
  }, [accountingSupplierToManageId]);

  const updateAccountingSupplierToManage = async () => {
    if (accountingSupplierToManageId) {
      const { accountingSupplierData } = await useAccountingSuppliersManagement(accountingSupplierToManageId);
      setAccountingSuppliersToManage(accountingSupplierData.accountingSupplier);
    }
  };

  const tableHead = [
    { code: "code", name: "Código", type: "string" },
    { code: "name", name: "Nombre", type: "string" },
    { code: "identificationDocumentNumber", name: "NIF/CIF", type: "string" },
    { code: "regimen", name: "Regimen", type: "string" },
    { code: "isValid", name: "Valido", type: "boolean" },
  ];

  const generateTable = async () => {
    const allList = await useAccountingSuppliersList();
    return allList.accountingSuppliersData.list;
  };

  const resetAccountingSupplierToManage = () => {
    const prev = previousMMode;
    const previousMode = prev.pop(managementMode);
    setPreviousMMode(prev);
    setManagementMode(previousMode);
    if (previousMode === "list") {
      setAccountingSuppliersToManage({});
      setAccountingSupplierToManageId("");
    }

    // if (supplierCode) {
    //   window.location = `/app/accounting/suppliers`;
    // } else {
    //   setAccountingSuppliersToManage({});
    //   setAccountingSupplierToManageId("");
    // }
  };

  const changeManagementMode = (mode) => {
    const prev = previousMMode;
    prev.push(managementMode);
    setPreviousMMode(prev);
    setManagementMode(mode);
  };

  return {
    accountingSuppliersList,
    accountingSupplierToManageId,
    setAccountingSupplierToManageId,
    accountingSupplierToManage,
    tableHead,
    accountingSuppliersActions,
    MAX_ACCOUNTINGSUPPLIERS_VIEW,
    alertOpen,
    alertData,
    handleAlertSnackBar,
    setAlertOpen,
    query,
    headActions,
    specs,
    generateTable,
    resetAccountingSupplierToManage,
    managementMode,
    changeManagementMode,
    editAccountingSupplierMode,
    setAccountingSupplierToManageId,
  };
};

export default AccountingSuppliersState;
