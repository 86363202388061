import styled from "styled-components";

export const OfferIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -10px;
  top: -5px;
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 6px;
`;

export const OfferIconInclude = styled(OfferIcon)`
  background-color: #ffae17;
  font-size: 1.3em;
`;
