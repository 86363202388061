/* eslint-disable no-use-before-define */
import { fetchProduct } from "../Products/useProducts";
import { fetchTicket } from "./useTickets";
import difference from "../shared/differenceBetweenTwoLists";
import { contentFields, sellingAllowed, priceModels } from "./constants";
import saveTicketList from "./bulkUpload";

/**
 *
 * @param {*} ticketList
 * @returns
 * saved: number, number of element send in the request after filtered
 * errors: string[], errors received during the execution
 */
const bulkUploadTickets = async (ticketList) => {
  //* ** Validation
  const errorsValidation = await ticketListValidation(ticketList);
  if (errorsValidation.length > 0) {
    return { errors: errorsValidation };
  }

  //* ** Turn the list into a body object list for the request,
  // because trcsv did not cover every object structure
  const ticketListParsed = parseTicketListIntoBody(ticketList);
  try {
    //* ** Get tickets saved
    const promises = [...new Set(ticketListParsed.filter((t) => t.id !== "").map((t) => t.id))].map((tk) =>
      fetchTicket(tk)
    );
    const currentTickets = await Promise.all(promises);

    //* ** Get the intersection between the two
    const { toInsert, toUpdate } = difference(currentTickets, ticketListParsed, false, contentFields, "id");

    // console.log(`toInsert ${toInsert.length}`, `toUpdate ${toUpdate.length}`);

    //* ** Save the results
    const { saved, errors } = await saveTicketList([...toInsert, ...toUpdate]);

    return { saved, errors };
  } catch (e) {
    return { errors: [e.message] };
  }
};

const ticketListValidation = async (ticketList) => {
  const productCodesChecked = {};
  const ncodes = [];
  const errors = [];
  for (let index = 0; index < ticketList.length; index += 1) {
    const rowId = index + 2;

    const ticket = ticketList[index];

    try {
      ticketValidation(ticket);
    } catch (e) {
      errors.push(`Fila ${rowId}: ${e.message}`);
    }

    if (productCodesChecked[ticket.productCode] === undefined) {
      try {
        await fetchProduct(ticket.productCode);
        productCodesChecked[ticket.productCode] = true;
      } catch (e) {
        errors.push(`Fila ${rowId}: Codigo de producto <${ticket.productCode}> inexistente`);
        productCodesChecked[ticket.productCode] = false;
      }
    } else if (!productCodesChecked[ticket.productCode]) {
      errors.push(`Fila ${rowId}: Codigo de producto <${ticket.productCode}> inexistente`);
    }

    if (ticket.id !== "") {
      if (ncodes.includes(ticket.id)) {
        errors.push(`Fila ${rowId}: ID <${ticket.id}> duplicado`);
      } else {
        ncodes.push(ticket.id);
      }
    }
  }

  return errors;
};

const ticketValidation = (product) => {
  // Mandatory
  ["id", "precinctCode", "productCode", "enable"].forEach((field) => {
    if (product[field] === undefined) {
      throw new Error(`Field <${field}> is required`);
    }
  });

  // Not Empty
  ["precinctCode", "productCode"].forEach((field) => {
    if (product[field].trim() === "") {
      throw new Error(`Field <${field}> is empty`);
    }
  });

  if (!sellingAllowed.includes(product.sellingAllowed)) {
    throw new Error(
      `'Tipo de venta' <${product.sellingAllowed}> not valid. Use: ${sellingAllowed.join("|")}`
    );
  }

  if (!priceModels.includes(product.priceModel)) {
    throw new Error(`'Modelo de precio' <${product.priceModel}> not valid. Use: ${priceModels.join("|")}`);
  }
};

function parseEnable(enable) {
  const newEnable = enable;
  if (typeof newEnable === "number") {
    newEnable = ticket.release.enable > 0;
  }
  return newEnable;
}

function parseTicketListIntoBody(ticketList) {
  return ticketList.map((ticket) => {
    const newTicket = ticket;
    const newPeople = {
      people: ticket.people.length,
      ages: ticket.people.map((p) => {
        const [min, max] = p.split("|");
        return { min: parseInt(min, 10), max: parseInt(max, 10) };
      }),
    };

    const newCancellation = {
      isCancellable: ticket.cancellation.isCancellable,
      conditions: ticket.cancellation.conditions.map((c) => {
        const [daysToDate, percent] = c.split("|");
        return { daysToDate: parseInt(daysToDate, 10), percent: parseInt(percent, 10) };
      }),
    };

    if (!parseEnable(ticket.release.enable)) {
      const newRelease = { enable: ticket.release.enable, daysToDate: 0, time: "23:59" };
      if (ticket.release.daysToDate !== "") {
        newRelease.daysToDate = ticket.release.daysToDate;
      }
      if (ticket.release.time !== "") {
        newRelease.time = ticket.release.time;
      }
      newTicket.release = newRelease;
    }

    if (!parseEnable(ticket.salesBoundaries.startSales.enable)) {
      const newSalesBoundariesStart = {
        enable: ticket.salesBoundaries.startSales.enable,
        date: "1970-01-01",
        time: "23:59",
      };
      if (ticket.salesBoundaries.startSales.date !== "") {
        newSalesBoundariesStart.daysToDate = ticket.salesBoundaries.startSales.date;
      }
      if (ticket.salesBoundaries.startSales.time !== "") {
        newSalesBoundariesStart.time = ticket.salesBoundaries.startSales.time;
      }
      newTicket.salesBoundaries.startSales = newSalesBoundariesStart;
    }

    if (!parseEnable(ticket.salesBoundaries.stopSales.enable)) {
      const newSalesBoundariesStop = {
        enable: ticket.salesBoundaries.stopSales.enable,
        date: "1970-01-01",
        time: "23:59",
      };
      if (ticket.salesBoundaries.stopSales.date !== "") {
        newSalesBoundariesStop.daysToDate = ticket.salesBoundaries.stopSales.date;
      }
      if (ticket.salesBoundaries.stopSales.time !== "") {
        newSalesBoundariesStop.time = ticket.salesBoundaries.stopSales.time;
      }
      newTicket.salesBoundaries.stopSales = newSalesBoundariesStop;
    }

    if (typeof newTicket.packageMarkup === "string" && newTicket.packageMarkup.trim() === "") {
      delete newTicket.packageMarkup;
    }

    if (Number.isNaN(newTicket.minQuota)) {
      newProduct.minQuota = 0;
    }

    return {
      ...newTicket,
      people: newPeople,
      cancellation: newCancellation,
    };
  });
}

export default bulkUploadTickets;
