import React, { useState } from "react";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import CircularProgress from "@material-ui/core/CircularProgress";
import bulkUploadPrecinctsChannels from "./bulkUploadPrecinctsChannels";
import { wordcontrolChannels, filenameChannels, csvHeadChannels, csvSpecChannels } from "./constants";
import { fetchAllPrecinctChannels } from "./usePrecincts";

function ButtonsCsvChannel({ onUploadEnded }) {
  const [loading, setLoading] = useState(false);

  async function getChannels() {
    return fetchAllPrecinctChannels();
  }

  async function onResultReady(resultList) {
    if (confirm(`Se van a subir canales de recintos. ¿Continuar?`)) {
      setLoading(true);
      const uploadResult = await bulkUploadPrecinctsChannels(resultList);
      setLoading(false);
      onUploadEnded({ total: resultList.length, ...uploadResult });
    }
  }

  return (
    <>
      {loading ? (
        <>
          <CircularProgress color="primary" /> Cargando CSV...
        </>
      ) : (
        <>
          <div style={{position: "relative",display:"flex", gap:"10px"}}>
            <div style={{position: "absolute", top:"-20px"}}>
              <b>Canales:</b>
            </div>
            <ButtonUploadCSV
                wordcontrol={wordcontrolChannels}
                specs={csvSpecChannels}
                ignoreWordcontrol={false}
                onResultReady={onResultReady}
            />
            <ButtonDownloadCSV
                wordcontrol={wordcontrolChannels}
                head={csvHeadChannels}
                specs={csvSpecChannels}
                filename={filenameChannels}
                generateTable={getChannels} 
            />
          </div>
        </>
      )}
    </>
  );
}

ButtonsCsvChannel.propTypes = {};

export default ButtonsCsvChannel;
