import generateTicketId from "../shared/ticketId";
import { saveTicket } from "./useTickets";

const saveTicketList = async (fullList) => {
  let saved = 0;
  const errors = [];
  for (let index = 0; index < fullList.length; index += 1) {
    const ticket = fullList[index];
    try {
      // Generate id if necessary
      if (ticket.id.trim() === "") {
        ticket.id = generateTicketId();
      }

      if (ticket.archived === undefined) {
        ticket.archived = false;
      }

      if (ticket.channels === undefined) {
        ticket.channels = [];
      }

      await saveTicket(ticket);
      saved += 1;
      // console.log("Saved", ticket);
    } catch (e) {
      errors.push(
        `Error en id ${ticket.id}: ${e.response.data.errors
          .reduce((acc, cv) => {
            acc.push(cv.error);
            return acc;
          }, [])
          .join(",")}`
      );
    }
  }

  return { saved, errors };
};

export default saveTicketList;
