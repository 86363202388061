import styled from "styled-components";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";

export const ImageCardWidget = styled(Image)`
  width: 125;
  height: 125px;
`;

export const LinkCardWidget = styled(Link)`
  &:hover {
    text-decoration: none !important;
  }
`;

export const CardWidget = styled(Card)`
  overflow: hidden;
`;

export const ButtonCardWidget = styled(Button)`
  width: 100%;
  background-color: #ffae17;
  color: #fff;
  border-color: #ffae17;
  border-radius: 0;
  &:hover {
    background-color: #ff8f18;
    border-color: #ff8f18;
    color: #fff;
  }
`;
