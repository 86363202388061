export const TYPES_ACTIONS = {
  cancellation: "Cancelación",
  "transfer-receipt": "Recibo de la transferencia",
  voucher: "Bono",
  message: "Mensaje",
  group: "Grupo",
  payment: "Pago",
  "cancellation-cost": "Coste de cancelacion",
  modification: "Modificación",
};
export const TYPES_ALERT = {
  warning: "Amarillo",
  info: "Azul",
  error: "Rojo",
};
