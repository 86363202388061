import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import FxChip from "@atoms/Content/FxChip";
import TagTitle from "@atoms/Titles/TagTitle";
import SelectorWithInputQuery from "@molecules/Selectors/SelectorWithInputQuery";
import Grid from "@material-ui/core/Grid";

// RMR: Solo sirve de momento para añadir a listas simples de array de strings, selected mas
// complejos queda TBD

function ItemSelectorWithQuery({
  title,
  inputPlaceholder,
  selected,
  keyToSelect,
  openDefault = true,
  onSearch,
  onChange,
  withCard = true,
}) {
  const [open, setOpen] = useState(openDefault);
  const [elements, setElements] = useState([]);

  const onClickOpen = () => {
    setOpen(!open);
  };

  const onChangeQuery = async (query) => {
    if (query.length < 3) {
      return;
    }

    const res = await onSearch(query);
    setElements(res);
  };

  const selectElement = (newElement) => {
    if (newElement[keyToSelect] === undefined) {
      alert("Unable to locate the key this component needs to add.");
      return;
    }

    const codeToAdd = newElement[keyToSelect];
    if (!selected.includes(codeToAdd)) {
      const nuSelected = [...selected];
      nuSelected.push(codeToAdd);
      onChange(nuSelected);
    }
  };

  const onDeleteSelected = (index) => {
    const nuSelected = [...selected];
    nuSelected.splice(index, 1);
    onChange(nuSelected);
  };

  return (
    <>
      {withCard ? (
        <TravCard>
          <TagTitle title={title} numberSelected={selected.length} active={open} onShowDown={onClickOpen} />
          {open && (
            <TravBodyCard>
              <div style={{ padding: "16px 0" }}>
                <SelectorWithInputQuery
                  additionalFormBorderProps={{ width: "100%" }}
                  elements={elements}
                  textOfAoutside={" "}
                  onSelect={selectElement}
                  onChangeQuery={onChangeQuery}
                  placeholder={inputPlaceholder}
                  icon={"search"}
                  cleanOnSelect={true}
                />

                <TitleListService>Elementos añadidos</TitleListService>

                {selected.length === 0 && <Alert severity="info">Ningun elemento añadido</Alert>}
                {selected.length > 0 && (
                  <>
                    {selected.map((item, index) => (
                      <FxChip
                        key={`itemChip${index}`}
                        label={item}
                        onDelete={() => onDeleteSelected(index)}
                      />
                    ))}
                  </>
                )}
              </div>
            </TravBodyCard>
          )}
        </TravCard>
      ) : (
        <Grid container>
          <TagTitle title={title} numberSelected={selected.length} active={open} onShowDown={onClickOpen} />
          {open && (
            <Grid item xs={12}>
              <div style={{ padding: "16px 0" }}>
                <SelectorWithInputQuery
                  additionalFormBorderProps={{ width: "100%" }}
                  elements={elements}
                  textOfAoutside={" "}
                  onSelect={selectElement}
                  onChangeQuery={onChangeQuery}
                  placeholder={inputPlaceholder}
                  icon={"search"}
                  cleanOnSelect={true}
                />

                <TitleListService>Elementos añadidos</TitleListService>

                {selected.length === 0 && <Alert severity="info">Ningun elemento añadido</Alert>}
                {selected.length > 0 && (
                  <>
                    {selected.map((item, index) => (
                      <FxChip
                        key={`itemChip${index}`}
                        label={item}
                        onDelete={() => onDeleteSelected(index)}
                      />
                    ))}
                  </>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export const TitleListService = styled.h6`
  position: relative;
  font-weight: bold;
  padding: 10px 10px;
  margin-top: 20px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0.1rem;
    width: 4px;
    height: 100%;
    background-color: #1bc5bd;
  }
`;

FxChip.propTypes = {
  /**
   * Title of the card
   */
  title: PropTypes.string,
  /**
   * Placeholder for the input search
   */
  inputPlaceholder: PropTypes.string,
  /**
   * Elements already selected
   */
  selected: PropTypes.arrayOf(PropTypes.string),
  /**
   * Key of the selected result of the search that it is going to be inserted
   * in the selected list (Default: 'code')
   */
  keyToSelect: PropTypes.string,
  /**
   * Search function, that will be executed when the input changes value
   */
  onSearch: PropTypes.func,
  /**
   * Function triggered everytime something changes the selected list
   */
  onChange: PropTypes.func,
};

ItemSelectorWithQuery.defaultProps = {
  title: "",
  inputPlaceholder: "Busca elementos",
  selected: [],
  keyToSelect: "code",
};

export default ItemSelectorWithQuery;
