import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { LinkCardWidget, ButtonCardWidget, CardWidget, ImageCardWidget } from "./PhotoCard.styles";
import { SeparatorLine, TitleWithSeparator } from "@atoms/Card/UsCard.styles";

function PhotoCard({ title, image, linkText, link }) {
  return (
    <LinkCardWidget to={link}>
      <CardWidget className="text-center">
        <Card.Body>
          <SeparatorLine />
          <TitleWithSeparator>{title}</TitleWithSeparator>
          <ImageCardWidget
            src={image || "https://tr3.traventia.com/graphic-design/sprites/temporary/cross_sellin_icon.jpg"}
            roundedCircle
          />
        </Card.Body>
        <ButtonCardWidget>{linkText}</ButtonCardWidget>
      </CardWidget>
    </LinkCardWidget>
  );
}

PhotoCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
};

export default PhotoCard;
