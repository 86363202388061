import styled from "styled-components";

export const TextCounterOffers = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  margin-top: -20px;
`;

export const TextCounterOffersEnabled = styled.div`
  color: #39d706;
  margin-right: 20px;
`;

export const TextCounterOffersDisabled = styled.div`
  color: #ffc107;
  margin-right: 20px;
`;

export const TextCounterTotal = styled.div`
  color: grey;
  margin-right: 5px;
`;
export const BoxPaginationOffers = styled.div`
  text-align: right;
`;
