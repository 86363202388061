import React from "react";
import { MdOutlineDelete } from "react-icons/md";
import useAffiliatesReportsActions from "../hooks/useAffiliatesReportsActions";
import { MdEdit } from "react-icons/md";

const AffiliatesReportsListActions = ({
  setAffiliatesReportsToManage,
  updateAffiliatesReportsList,
  deleteAffiliatesReports,
}) => {
  const { manageAffiliatesReportsAction, deleteAffiliatesReportsAction } = useAffiliatesReportsActions({
    setAffiliatesReportsToManage,
    deleteAffiliatesReports,
  });

  const managementAction = {
    code: "management",
    name: "Editar",
    icon: <MdEdit />,
    func: manageAffiliatesReportsAction,
  };
  const deleteAction = {
    code: "delete",
    name: "Eliminar",
    icon: <MdOutlineDelete />,
    func: deleteAffiliatesReportsAction(updateAffiliatesReportsList),
  };

  const affiliatesReportsActions = [managementAction, deleteAction];

  return {
    affiliatesReportsActions,
  };
};

export default AffiliatesReportsListActions;
