import React, { Fragment, useState, useEffect, useRef } from "react";
import { MdHighlightOff, MdSearch } from "react-icons/md";
import { BsTagFill } from "react-icons/bs";
import { Spinner, Modal } from "react-bootstrap";
import { TextField, Chip, InputAdornment, Paper, MenuList, MenuItem } from "@material-ui/core";
import ButtonCancel from "@atoms/Buttons/ButtonCancel";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import _ from "lodash";
import { getTagsOfBucket, addTagToFile, deleteTagFromFile } from "../../../hooks/useTag";
import CenterLoader from "@atoms/Loaders/CenterLoader";
import { sanitizeTag } from "../../../shared/sanitize";
import ErrorModal from "@atoms/Modals/ErrorModal";

const TagAssignmentModal = ({ open, bucket, selectedRows, handleCloseModal = () => {} }) => {
  const minchar = 3;
  const maxsuggestions = 10;

  const [files, setFiles] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tagSearcherString, setTagSearcherString] = useState("");
  const [currentError, setCurrentError] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [errors, setErrors] = useState({ show: false, list: [] });

  useEffect(() => {
    const rowsClone = _.cloneDeep(selectedRows);
    setFiles(rowsClone);
  }, [selectedRows]);

  useEffect(() => {
    if (tagSearcherString.length >= minchar) {
      setIsLoading(true);
      getTagsOfBucket(bucket, tagSearcherString)
        .then((r) => {
          setSuggestions(r.data.slice(0, maxsuggestions));
          setIsLoading(r.loading);
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    } else {
      setSuggestions([]);
    }
  }, [tagSearcherString]);

  const sanitizeInput = (evt, text) => {
    const nuTag = sanitizeTag(text);
    setTagSearcherString(nuTag);
  };

  const deleteTag = (ixFile, index) => {
    const nFile = [...files];
    nFile[ixFile].tags.splice(index, 1);
    setFiles(nFile);
  };

  const sentTagToList = () => {
    if (tagSearcherString.length === 0) {
      setCurrentError("No se puede crear un tag sin texto");
    } else {
      insertNewTag(tagSearcherString);
    }
  };

  const selectOption = (option) => {
    insertNewTag(option.idTag);
  };

  const insertNewTag = (tagToInsert) => {
    const nFiles = [...files];
    files.forEach((file, ixFile) => {
      if (file.tags.indexOf(tagToInsert) === -1) {
        nFiles[ixFile].tags.push(tagToInsert);
        setFiles(nFiles);
      } else {
        setErrors({
          show: true,
          list: [`La etiqueta ${tagToInsert} ya esta incluida`],
        });
      }
    });

    setSuggestions([]);
    setTagSearcherString("");
  };

  const saveTags = async () => {
    setModalLoading(true);

    const srows = selectedRows.reduce((map, obj) => {
      map[obj.name] = obj.tags;
      return map;
    }, {});

    let errorList = [];

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const originalTags = srows[file.name];

      let toInsert = file.tags.filter((x) => !originalTags.includes(x));
      let toDelete = originalTags.filter((x) => !file.tags.includes(x));

      for (let ix1 = 0; ix1 < toInsert.length; ix1++) {
        const { ok } = await addTagToFile(file.path, bucket, toInsert[ix1]);
        if (!ok) {
          errorList.push(`No se ha podido guardar ${toInsert[ix1]} en archivo ${file.path} - ${bucket}`);
        }
      }

      for (let ix2 = 0; ix2 < toDelete.length; ix2++) {
        const { ok } = await deleteTagFromFile(file.path, bucket, toDelete[ix2]);
      }
    }

    if (errorList.length > 0) {
      setErrors({
        show: true,
        list: errorList,
      });
    }

    handleCloseModal({ execution: true, error: errorList.length > 0 });
    setModalLoading(false);
  };

  return (
    <Fragment>
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => {
          setErrors({ show: false, list: [] });
        }}
      />
      <Modal
        show={open}
        onHide={() => handleCloseModal({ execution: false, error: false })}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Gestiona las etiquetas de ${files.length} archivo(s)`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalLoading ? (
            <CenterLoader />
          ) : (
            <Fragment>
              <TextField
                error={currentError}
                label="Busca una etiqueta para añadirla o escribe un texto válido y pulsa 'Intro'"
                variant="outlined"
                value={tagSearcherString}
                helperText="Acepta únicamente letras minúsculas, números y barras bajas"
                onChange={(evt) => {
                  sanitizeInput(evt, evt.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    sentTagToList();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdSearch />
                    </InputAdornment>
                  ),
                  endAdornment: isLoading && <Spinner animation="border" role="status" size="sm" />,
                }}
              />

              {suggestions.length > 0 && (
                <Paper style={{ maxHeight: "230px", overflowY: "auto" }}>
                  <MenuList>
                    {suggestions.length === 0 && <MenuItem>No hay resultados</MenuItem>}
                    {/* Menu List */}
                    {suggestions.length > 0 &&
                      suggestions.map((option, index) => {
                        return (
                          <MenuItem key={`searchoption_${index}`} onClick={() => selectOption(option)}>
                            {option.idTag}
                          </MenuItem>
                        );
                      })}
                  </MenuList>
                </Paper>
              )}

              <hr />
              {files.map((file, ixFile) => {
                return (
                  <div key={`file${ixFile}`}>
                    <h5>
                      Archivo <b>{file.name}</b>:
                    </h5>
                    {file.tags.map((tag, index) => {
                      return (
                        <Chip
                          key={`tag${ixFile}-${index}`}
                          icon={<BsTagFill color="white" />}
                          label={tag}
                          colour="#54b2dd"
                          onDelete={() => deleteTag(ixFile, index)}
                          deleteIcon={<MdHighlightOff />}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </Fragment>
          )}
        </Modal.Body>

        <Modal.Footer>
          {!modalLoading && (            
            <div style={{display:"flex", gap:"5px"}}>
              <ButtonCancel              
                size="small"
                onClick={() => handleCloseModal({ execution: false, error: false })}
              >
                Cancelar
              </ButtonCancel>
              <ButtonCustom size="small"  color="primary" onClick={() => saveTags()}>
                Guardar etiquetas
              </ButtonCustom>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default TagAssignmentModal;
