//import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { useEffect, useState } from "react";
import DrawerMobile from "@atoms/Drawers/DrawerMobile";

const QueryDropDownListLayoutMobile = ({ children }) => {
  const [afterRender, setAfterRender] = useState(false); // internal state
  useEffect(() => {
    setAfterRender(true);
  }, []);
  useEffect(() => {
    if (afterRender) {
      window.scrollTo(0, 0);
    }
  }, [afterRender]);
  return <DrawerMobile>{children}</DrawerMobile>;
};
export default QueryDropDownListLayoutMobile;
