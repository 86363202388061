/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from "react";
import FenixTable from "@molecules/Tables/FenixTable";
// import { GiTicket } from "react-icons/gi";
import CustomModal from "@molecules/Modal/CustomModal";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import { BsInfoLg } from "react-icons/bs";
import ProductsSectionMenu from "./ProductsSectionMenu";
import { fetchProducts } from "./useProducts";
import UploadResultAlert from "../shared/UploadResultAlert";
import RelevantInformationSection from "./RelevantInformation/RelevantInformationSection";

function ProductsSection({ precinctCode }) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [loadingTable, setLoadingTable] = useState(true);
  const [productList, setProductList] = useState([]);
  const [uploadResult, setUploadResult] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [productCodeSelected, setProductCodeSelected] = useState("");

  const fetchProductList = useCallback(async () => {
    setLoadingTable(true);
    const products = await fetchProducts({ params: { precinctCode } });
    setProductList(products);
    setLoadingTable(false);
  }, [precinctCode]);

  useEffect(() => {
    fetchProductList();
  }, [fetchProductList, uploadResult]);

  const tableHead = [
    { code: "code", name: "Código", type: "string" },
    { code: "name.es-ES", name: "Nombre (es)", type: "string" },
    { code: "packageRules.type", name: "Paquete", type: "string" },
  ];

  const onUploadEnded = (resultUpload) => {
    let status = "success";
    if (resultUpload.errors.length > 0) {
      status = resultUpload.saved && resultUpload.saved > 0 ? "warning" : "error";
    }
    setUploadResult({ status, ...resultUpload });
  };

  function showFormRelevantInformation(row) {
    setProductCodeSelected(row.code);
    onOpenModal();
  }

  const actions = [{ name: "Información Relevante", icon: <BsInfoLg />, func: showFormRelevantInformation }];

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onChangeRelevantInformation = () => {
    setOpenModal(false);
    setAlertData("Información relevante guardada con éxito");
    setAlertOpen(true);
  };

  return (
    <>
      <CustomModal
        loading={false}
        open={openModal}
        handleClose={onCloseModal}
        bigModal={true}
        customWidthModal={1000}
      >
        <RelevantInformationSection
          precinctCode={precinctCode}
          productCode={productCodeSelected}
          onChange={onChangeRelevantInformation}
        />
      </CustomModal>

      {uploadResult && (
        <UploadResultAlert uploadResult={uploadResult} onClose={() => setUploadResult({ status: "close" })} />
      )}
      <FenixTable
        loading={loadingTable}
        tableTitle={
          <ProductsSectionMenu
            precinctCode={precinctCode}
            productList={productList}
            onUploadEnded={onUploadEnded}
          />
        }
        tableHead={tableHead}
        tableContent={productList}
        actions={actions}
      />
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
    </>
  );
}

ProductsSection.propTypes = {};

export default ProductsSection;
