import React from "react";
import { useParams } from "react-router-dom";
import CalendarSection from "@organisms/Ticketing/Calendar/CalendarSection";
import ChannelPageTemplate from "./ChannelPageTemplate";

const PrecinctCalendarPage = () => {
  const { precinctCode, tabName } = useParams();
  return (
    <ChannelPageTemplate>
      <CalendarSection precinctCode={precinctCode} url={tabName} />
    </ChannelPageTemplate>
  );
};

export default PrecinctCalendarPage;
