import React from "react";
import Grid from "@material-ui/core/Grid";
import { Card } from "react-bootstrap";
import FxSwitch from "@atoms/Switches/FxSwitch";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import { TextField } from "@material-ui/core";

function CrossSellingInfo({ form, onChange }) {
  return (
    <Card>
      <Card.Header className="text-right">
        <Grid container justify="space-between" alignItems="baseline">
          <Grid item>
            <span style={{ fontSize: "1.2em" }}>información</span>
          </Grid>
          <Grid item>
            <FxSwitch
              labels={["Campaña habilitada", "Campaña deshabilitada"]}
              status={form.enable}
              onChange={(nuValue) => {
                const nuForm = { ...form };
                nuForm.enable = nuValue;
                onChange(nuForm);
              }}
            />
          </Grid>
        </Grid>
      </Card.Header>
      <Card.Body>
        <Grid container justify="space-between">
          <Grid item xs={3}>
            <TextField disabled={true} label="Código" value={form.id} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Nombre"
              value={form.name}
              onChange={(nVal) => {
                const nuForm = { ...form };
                nuForm.name = nVal.target.value;
                onChange(nuForm);
              }}
            />
          </Grid>
          <Grid item>
            <SelectMarket
              selection={form.market}
              onChangeMarket={(nVal) => {
                const nuForm = { ...form };
                nuForm.market = nVal;
                onChange(nuForm);
              }}
            />
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
}

export default CrossSellingInfo;
