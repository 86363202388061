import React from "react";
import { FormControlLabel, Checkbox, MenuItem, Select, InputLabel, Grid } from "@material-ui/core";
import FxAlert from "@atoms/Alerts/FxAlert";
import ListEditable from "@atoms/Lists/ListEditable";
import { ClickableTitleElements, ElementTagLeft } from "@atoms/Titles/Titles.styles";
import cancellationModes from "./data/cancellationModes.json";
import { cancellationModesEnabled } from "./data/modesEnabled";

function CancellationConfigurationSelector({ channel, onChange }) {
  function hasCancellationConfiguration() {
    return channel.cancellation !== undefined && !channel.cancellation?.inherited;
  }

  function onChangeCancellationInherited(e) {
    onChange("cancellation", { ...channel.cancellation, inherited: e.target.checked });
  }

  return (
    <>
      <ClickableTitleElements className="check-box-title">
        <InputLabel>
          <FormControlLabel
            control={
              <ElementTagLeft marginright="10px">
                <Checkbox
                  color="primary"
                  checked={channel.cancellation.inherited}
                  onChange={onChangeCancellationInherited}
                />
              </ElementTagLeft>
            }
            label={"¿Modo de cancelación igual al de reserva?"}
          />
        </InputLabel>
      </ClickableTitleElements>
      {hasCancellationConfiguration() && (
        <>
          <Select
            fullWidth
            value={channel.cancellation.mode}
            onChange={(e) => onChange("cancellation", { ...channel.cancellation, mode: e.target.value })}
          >
            {cancellationModes
              .filter((bookMode) => cancellationModesEnabled.includes(bookMode.code))
              .map((bm, i) => (
                <MenuItem value={bm.code} key={`voucherMode${i}`}>
                  {bm.txt}
                </MenuItem>
              ))}
          </Select>
          {channel.cancellation.mode === "stemail" && (
            <Grid container spacing={2}>
              <CancellationModeEmailStandart
                configuration={channel.cancellation.configuration}
                onChange={(newConf) =>
                  onChange("cancellation", { ...channel.cancellation, configuration: newConf })
                }
              />
            </Grid>
          )}
          {channel.cancellation.mode === "zendesk" && (
            <div style={{ marginTop: "10px" }}>
              <FxAlert variant={"info"}>Se crea un ticket en el Zendesk de Booking</FxAlert>
            </div>
          )}
        </>
      )}
    </>
  );
}

const CancellationModeEmailStandart = ({ configuration, onChange }) => {
  const msgError = "El email no es válido";
  function validateInputEmail(elementValue) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue);
  }
  return (
    <>
      <Grid items xs={12} sm={6}>
        <ListEditable
          key={"CANCELLATION-EMAILS"}
          titleLabel={"Emails"}
          info={configuration.emails}
          onChangeList={(nuList) => onChange({ ...configuration, emails: nuList })}
          validation={validateInputEmail}
          msgError={msgError}
        />
      </Grid>
      <Grid items xs={12} sm={6}>
        <ListEditable
          key={"CANCELLATION-CCO"}
          titleLabel="Emails CCO"
          info={configuration.cco}
          onChangeList={(nuList) => onChange({ ...configuration, cco: nuList })}
          validation={validateInputEmail}
          msgError={msgError}
        />
      </Grid>
    </>
  );
};

export default CancellationConfigurationSelector;
