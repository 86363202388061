import React from "react";
import {
  MdOutlineDragHandle,
  MdBlock,
  MdOutlineScheduleSend,
  MdSend,
  MdSearch,
  MdLockOpen,
} from "react-icons/md";
import userVoucherActions from "../hooks/useVoucherActions";
import { userAuthorized } from "../../../../entries/fenix/auth/FenixAuthContext";

const voucherListActions = ({ setIsManageVoucherActive, setVoucherToManage, updateVoucherList }) => {
  const user = userAuthorized();
  const {
    managementVoucherAction,
    sendMarkVoucherAction,
    blockVoucherAction,
    seeVoucherPDFAction,
    sendEmailVoucherAction,
    unBlockVoucherAction,
  } = userVoucherActions({ user, setIsManageVoucherActive, setVoucherToManage });

  function isBlocked(book) {
    return book.voucherBlock !== undefined && book.voucherBlock !== "";
  }

  function hasMainVoucher(book) {
    return book.voucherList?.main?.created === 1;
  }

  function voucherNotSended(book) {
    return book.voucher === 0;
  }

  const managmentAction = {
    code: "management",
    name: "Gestionar",
    icon: <MdOutlineDragHandle />,
    func: managementVoucherAction,
    showIf: (book) => {
      return !isBlocked(book) && book.voucherList !== undefined;
    },
  };

  const sendMarkAction = {
    code: "sendMark",
    name: "Marcar enviado",
    icon: <MdOutlineScheduleSend />,
    func: sendMarkVoucherAction(updateVoucherList),
    showIf: (book) => {
      return !isBlocked(book) && voucherNotSended(book);
    },
  };

  const blockAction = {
    code: "block",
    name: "Bloquear",
    icon: <MdBlock />,
    func: blockVoucherAction(updateVoucherList),
    showIf: (book) => {
      return !isBlocked(book);
    },
  };
  const unblockAction = {
    code: "unBlock",
    name: "Desbloquear",
    icon: <MdLockOpen />,
    func: unBlockVoucherAction(updateVoucherList),
    showIf: (book) => {
      return isBlocked(book);
    },
  };

  const seeAction = {
    code: "see",
    name: "Ver",
    icon: <MdSearch />,
    func: seeVoucherPDFAction,
    showIf: (book) => {
      return !isBlocked(book) && hasMainVoucher(book);
    },
  };

  const sendAction = {
    code: "send",
    name: "Enviar",
    icon: <MdSend />,
    func: sendEmailVoucherAction(updateVoucherList, 1),
    showIf: (book) => {
      return !isBlocked(book) && voucherNotSended(book) && hasMainVoucher(book);
    },
  };

  const actionsVouchersInfrigement = [
    managmentAction,
    sendMarkAction,
    blockAction,
    unblockAction,
    seeAction,
    sendAction,
  ];

  const actionsVouchersFerrys = [
    managmentAction,
    sendMarkAction,
    blockAction,
    unblockAction,
    seeAction,
    sendAction,
  ];

  const actionsVouchersTravis = [
    /*  managmentAction,
    sendMarkAction,
    blockAction,
    unblockAction,
    seeAction,
    sendAction, */
  ];

  const actionsVouchersPending = [
    managmentAction,
    sendMarkAction,
    blockAction,
    unblockAction,
    seeAction,
    sendAction,
  ];

  return {
    actionsVouchersPending,
    actionsVouchersInfrigement,
    actionsVouchersFerrys,
    actionsVouchersTravis,
  };
};

export default voucherListActions;
