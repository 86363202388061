import axios from "axios";

const url = `/api/v1/web-bff-bc/ticketing/mandalorian-legacy/products`;

export const getAllTicketProducts = async (tcode) => {
  let thestateFinal = { loadingList: true, list: [] };
  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { loadingList: true, list: [] };
      const response = await axios.get(`${url}?tcode=${tcode}`);
      if (response.status === 200) {
        thestate = { loadingList: false, list: response.data.data.flat().map((product) => product.code) };
      }
      thestateFinal = thestate;
    } catch (error) {
      thestateFinal = { loadingList: false, list: {} };
      console.error(error);
    }
    return thestateFinal;
  };
  if (thestateFinal.loadingList) {
    thestateFinal = await fetchData(thestateFinal);
    return thestateFinal;
  }
  return thestateFinal;
};
