import axios from "axios";
import { getDatesBetweenTwo } from "@lib/helpers/dates";

const bankData = {
  BANCOSANTANDER: { name: "Banco Santander", colour: "#EC0000" },
  BBVA: { name: "BBVA", colour: "#004680" },
  CAIXAGERAL: { name: "Caixa Geral de Depósitos", colour: "rgb(46, 212, 162)" },
};

export const getBankData = () => {
  return bankData;
};

export const fetchUnmatchedBankTransfers = async () => {
  try {
    const { status, data } = await axios.get(
      `/api/v1/booking-bc/payments/customer-transfer-received/processed/unassigned`
    );
    const transfers = data;

    let list = [];
    if (status === 200 && transfers.length > 0) {
      list = transfers;
    }

    return {
      loading: false,
      list: list.map((banktransfer) => {
        return { ...banktransfer, amount: banktransfer.amount.amount };
      }),
    };
  } catch (error) {
    console.error(error);
    return { loading: false, list: [] };
  }
};

export const searchBankTransfers = async (datei, dateo) => {
  try {
    const promises = [];

    const rangeDates = getDatesBetweenTwo(datei, dateo);
    for (let i = 0; i < rangeDates.length; i++) {
      promises.push(
        axios.get(`/api/v1/booking-bc/payments/customer-transfer-received/processed/all/${rangeDates[i]}`)
      );
    }
    const response = await Promise.all(promises);
    const banktransfers = [];
    response.forEach((res) => {
      if (res.status === 200 && res.data) {
        const transfers = res.data;
        for (let i = 0; i < transfers.length; i++) {
          const found = banktransfers.find((banktransfer) => banktransfer.id === transfers[i].id);
          if (!found) banktransfers.push(transfers[i]);
        }
      }
    });
    return {
      error: false,
      banktransfers: banktransfers.map((banktransfer) => {
        return { ...banktransfer, amount: banktransfer.amount.amount };
      }),
    };
  } catch (error) {
    console.error(error);
    return { error: true, banktransfers: [] };
  }
};

export const getBookWithBookref = async (bookref) => {
  try {
    const { status, data } = await axios.get(`/api/v1/booking-bc/projections/books`, {
      params: {
        bookref,
        _fields: ["bookref", "email", "contactPerson", "pvp", "payed"].join(","),
      },
    });

    if (status === 200 && Object.keys(data.data).length > 0) {
      return data.data[0];
    }
    throw new Error("Book not found");
  } catch (e) {
    alert(`No hemos encontrado ninguna reserva con codigo "${bookref}"`);
    console.error(e);
  }
};

export const assingBankTransferToBook = async (bankTransferKey, bookref) => {
  try {
    const { status } = await axios.post(
      `/api/v1/booking-bc/payments/customer-transfer-received/processed/assign-to-book`,
      {
        key: bankTransferKey,
        bookref,
      }
    );

    if (status === 200) {
      return { ok: true };
    }
    throw new Error("Error");
  } catch (e) {
    alert(`No se ha podido asignar esta transferencia a la reserva "${bookref}"`);
    console.error(e);
    return { ok: false };
  }
};

export const launchBankTransferRecaculation = async () => {
  try {
    const { status } = await axios.post(
      `/api/v1/booking-bc/payments/customer-transfer-received/automatch`,
      {}
    );

    if (status === 200) {
      return { ok: true };
    }
    throw new Error("Error");
  } catch (e) {
    alert(`No se ha podido lanzar el recálculo de transferencias.`);
    console.error(e);
    return { ok: false };
  }
};

export const uploadCSVofBanks = async (bankid, csvBanks) => {
  try {
    const { status, data } = await axios.post(
      `/api/v1/booking-bc/payments/customer-transfer-received/insert-by-csv/${bankid}`,
      { rows: csvBanks }
    );

    if (status === 200) {
      return data;
    }
    throw new Error("Error");
  } catch (e) {
    alert(`No se ha podido subir este csv. Intentelo de nuevo más tarde.`);
    console.error(e);
    return { ok: false };
  }
};
