import { saveProductChannel } from "../Products/useProducts";

const saveProductChannelList = async (fullList) => {
  let saved = 0;
  const errors = [];
  for (let index = 0; index < fullList.length; index += 1) {
    const productChannel = fullList[index];
    try {
      await saveProductChannel(productChannel);
      saved += 1;
    } catch (e) {
      errors.push(
        `Error en el codigo ${productChannel.productCode}: ${e.response.data.errors
          .reduce((acc, cv) => {
            acc.push(cv.error);
            return acc;
          }, [])
          .join(",")}`
      );
    }
  }

  return { saved, errors };
};

export default saveProductChannelList;
