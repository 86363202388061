import styled from "styled-components";

export const IconChannel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 500px; */
  /* top: 450px; */
  height: 30px;
  width: 100px;
  color: #fff;
  border-radius: 6px;
  font-size: 0.6em;
`;
export const IconNew = styled(IconChannel)`
  background-color: #1bc5bd  !important;
  border-radius:0;
`;

export const IconEdit = styled(IconChannel)`
  background-color: #EF4A68 !important;
  border-radius:0;
`;

export const IconDefault = styled(IconChannel)`
  background-color: #17a2b8 !important;
  border-radius:0;
`;
