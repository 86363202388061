import { userAuthorized } from "@src/entries/fenix/auth/FenixAuthContext";
import { v4 } from "uuid";
import { useRemittance, useLockdown } from "../../contexts/PaymentsToSuppliersContext";
import { PaymentsToSuppliersApi } from "../../api/PaymentsToSuppliersApi";

const ID_TEMPORAL = "ID-TEMPORAL";

const useRemittanceView = () => {
  const { setLockdown } = useLockdown();
  const { remittance, remittanceLoading, setRemittance, setRemittanceLoading } = useRemittance();
  const { profile } = userAuthorized();

  function newRemittance() {
    return {
      id: ID_TEMPORAL,
      datecreation: new Date().toISOString(),
      createdBy: profile.email,
      invoices: [],
    };
  }

  const fetchRemittance = async () => {
    console.log(`🥶 fetchRemittance: Leyendo remesa...`);

    try {
      if (!remittance) {
        const { data, total } = await PaymentsToSuppliersApi.getRemittanceOfUser(profile.email);
        if (total === 0 && data.length === 0) {
          setRemittance(newRemittance()); // Error empty
        } else {
          if (data.length > 1) {
            throw new Error("More than one remittance per user? Lockdown for now");
          }
          setRemittance(data[0]);
        }
      } else if (remittance.id !== ID_TEMPORAL) {
        const remittanceResponse2 = await PaymentsToSuppliersApi.getRemittanceByID(remittance.id);
        setRemittance(remittanceResponse2);
      }
    } catch (error) {
      setLockdown(true); // Error not empty
    }

    setRemittanceLoading(false);
  };

  async function addInvoicesToRemittance(invoices) {
    setRemittanceLoading(true);
    console.log(`🥶 addInvoicesToRemittance: Añade facturas a remesa...`);

    try {
      const newRemittanceObject = { ...remittance };

      if (newRemittanceObject.id === ID_TEMPORAL) {
        newRemittanceObject.id = v4();
        await PaymentsToSuppliersApi.createRemittance(newRemittanceObject);
      }

      await PaymentsToSuppliersApi.addInvoicesToRemittance(newRemittanceObject.id, invoices);
      setRemittance({ ...newRemittanceObject, invoices: [...newRemittanceObject.invoices, ...invoices] });
      setRemittanceLoading(false);
    } catch (error) {
      setRemittanceLoading(false);
      throw error;
    }
  }

  async function removeInvoicesFromRemittance(invoicesToDelete) {
    setRemittanceLoading(true);

    try {
      await PaymentsToSuppliersApi.removeInvoicesFromRemittance(remittance.id, invoicesToDelete);
      const invoicesToDeleteIDs = invoicesToDelete.map(({ invoice }) => invoice);
      setRemittance({
        ...remittance,
        invoices: remittance.invoices.filter(({ invoice }) => !invoicesToDeleteIDs.includes(invoice)),
      });
    } catch (error) {
      console.log("🚀 ~ :", error);
      alert(`ERROR! No se han podido eliminar las facturas de esta remesa. Error: ${error.message}`);
    }

    setRemittanceLoading(false);
  }

  async function confirmRemittance() {
    setRemittanceLoading(true);

    try {
      await PaymentsToSuppliersApi.confirmRemittance(remittance);
      setRemittance(newRemittance());
      setRemittanceLoading(false);
    } catch (error) {
      setRemittanceLoading(false);
      throw error;
    }
  }

  return { fetchRemittance, addInvoicesToRemittance, removeInvoicesFromRemittance, confirmRemittance };
};

export default useRemittanceView;
