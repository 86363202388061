import { useEffect, useState } from "react";
import axios from "axios";

const catchErrorMessage = (errorObject) => {
  let message = "Error no capturado";
  if (errorObject.message) {
    message = errorObject.message;
  }

  const { response } = errorObject;
  if (response !== undefined) {
    if (response.data !== undefined) {
      if (response.data.err !== undefined) {
        if (response.data.err.message !== undefined) {
          message = response.data.err.message;
        }
      }
    }
  }

  return message;
};
/**
 *
 * @param {*} tld
 * @param {*} path
 */
// eslint-disable-next-line import/prefer-default-export
export const useJsonPage = (id) => {
  const [state, setState] = useState({ loading: true, list: [], error: "", ok: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { list: {}, loading: false };
        const response = await axios.get(`/api/v1/product-bc/content/pages/${id}`);
        if (response.status === 200 && response.data) {
          thestate = { list: response.data, loading: false, error: "", ok: true };
        }
        setState(thestate);
      } catch (error) {
        setState({ list: {}, loading: false, error: catchErrorMessage(error), ok: false });
        throw error;
      }
    };
    if (state.loading) {
      fetchData();
    }
  }, [state.loading, state, id]);

  return { loading: state.loading, jsonPage: state.list, error: state.error, ok: state.ok };
};

export const useJsonPageDispatch = (id = "fb7b0141-7f28-4cce-82f3-7f0659c33633") => {
  const [state, setState] = useState({ loading: true, list: [], error: "", ok: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { list: {}, loading: false };
        const response = await axios.get(`/api/v1/product-bc/content/pages/${id}/publish`);
        if (response.status === 200 && response.data) {
          thestate = { list: response.data, loading: false, error: "", ok: true };
        }
        setState(thestate);
      } catch (error) {
        setState({ list: {}, loading: false, error: catchErrorMessage(error), ok: false });
        throw error;
      }
    };
    if (state.loading) {
      fetchData();
    }
  }, [state.loading, state, id]);

  return {
    loadingDispatch: state.loading,
    jsonPageDispatch: state.list,
    errorDispatch: state.error,
    okDispatch: state.ok,
  };
};

export const upsertJsonPage = async (jsonPage) => {
  let responseRet = { ok: false };
  try {
    const requestRes = await axios.put(`/api/v1/product-bc/content/pages/${jsonPage.id}`, jsonPage);
    if (requestRes.status === 201 && requestRes) {
      responseRet = { ok: true };
    }
  } catch (error) {
    const message = catchErrorMessage(error);
    responseRet = { ok: false, message };
  }
  return responseRet;
};

export const publishJsonPage = async (jsonPageId, body = { versionComment: "" }) => {
  let responseRet = { ok: false };
  try {
    const requestRes = await axios.post(`/api/v1/product-bc/content/pages/${jsonPageId}/publish`, body);
    if (requestRes.status === 201 && requestRes) {
      responseRet = { ok: true };
    }
  } catch (error) {
    const message = catchErrorMessage(error);
    responseRet = { ok: false, message };
  }
  return responseRet;
};

// eslint-disable-next-line consistent-return
export const postPreview = async (key, json) => {
  try {
    if (json !== "") {
      const response = await axios.put(`/api-private/v1/web-bff-bc/content/preview-json-page/${key}`, json);
      if (response.status === 201) {
        return { ok: true, key };
      }
    } else {
      return { ok: true, key };
    }
  } catch (error) {
    return { ok: false, key };
  }
};

export const useJsonPageTags = (query) => {
  console.log("useJsonPageTags");
  const [state, setState] = useState({ loading: true, list: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { list: {}, loading: false };
        console.log("PETICION");
        const response = await axios.get(`/api/v1/product-bc/content/pages-tags?tag[regex]=${query}`);
        console.log("response", response);
        if (response.status === 200 && response.data) {
          thestate = { list: response.data, loading: false, error: "", ok: true };
        }
        setState(thestate);
      } catch (error) {
        setState({ list: {}, loading: false, error: catchErrorMessage(error), ok: false });
        throw error;
      }
    };
    if (state.loading) {
      fetchData();
    }
  }, [state.loading, state, query]);

  return { loadingTags: state.loading, listTags: state.list };
};
