import React, { useState } from "react";
import axios from "axios";
// import InputQueryDropdown from "@atoms/Inputs/InputQueryDropdown";
import SelectorWithInputQuery from "@molecules/Selectors/SelectorWithInputQuery";

function BrickQuerySearcher({
  label = "Buscador de bricks",
  type = "template", // DRM Añado esto para poder elegir qué tipo de brick quieres
  onSelect,
}) {
  const [elements, setElements] = useState([]);

  const onChangeQuery = async (query) => {
    let bricks = [];
    try {
      const { status, data } = await axios.get("/api/v1/product-bc/content/bricks", {
        params: { type, "code[regex]": query },
      });

      if (status === 200) {
        bricks = data.data.map((b) => ({
          code: b.code,
          name: b.name,
          text: [b.code, b.name].join(" - "),
          content: b.content,
        }));
      }
    } catch (e) {
      console.error(e);
    }

    setElements(bricks);
  };

  return (
    <SelectorWithInputQuery
      additionalFormBorderProps={{ width: "100%" }}
      elements={elements}
      textOfAoutside={""}
      onSelect={onSelect}
      onChangeQuery={onChangeQuery}
      placeholder={label}
      icon={"search"}
    />
  );
}

export default BrickQuerySearcher;
