import React from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";
import { getChannelID } from "../shared/domainVariables";

function ChannelSelector({ loadingChannels, channelSelected, channels, onChange }) {
  return (
    <>
      {!loadingChannels && (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Canal</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={channelSelected}
            label="Canales"
            onChange={onChange}
          >
            {channels.map((channel, index) => (
              <MenuItem key={`channel${index}`} value={channel}>
                {getChannelID(channel)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export default ChannelSelector;
