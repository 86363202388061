import axios from "axios";
// eslint-disable-next-line camelcase

const NEWSLETTER_URL = "/api/v1/marketing-bc/third-party-newsletter";
const OFFERS_URL = "/api/v1/product-bc/offers-managment/offers";

export const useNewsletterList = async (channelSelected) => {
  console.log(channelSelected);
  let newsletterData = {
    loadingList: true,
    list: [],
    status: 0,
  };
  const fetchData = async () => {
    try {
      const params = {_sort: '-@updated_on'};
      const response = await axios.get(NEWSLETTER_URL, { params });
      if (response.status === 200) {
        return { loadingList: false, list: response.data.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return { loadingList: false, list: [] };
      // throw error;
    }
    return { loadingList: true, list: [] };
  };
  newsletterData = await fetchData();
  return { newsletterData };
};

export const useOffers = async (idOffers) => {
  let offersData = {
    loading: true,
    offers: {},
    status: 0,
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${OFFERS_URL}?idOffer[regex]=${idOffers.join("|")}`);
      if (response.status === 200) {
        return { loading: false, offers: response.data.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return idOffers.map((cv) => {
        return { idOffer: cv, title: "" };
      });
    }
    return { loading: true, offers: [] };
  };
  offersData = await fetchData();
  return { offersData };
};

export const useNewsletter = async (id) => {
  let newsletterData = {
    loading: true,
    newsletter: {},
    status: 0,
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${NEWSLETTER_URL}/${id}`);
      if (response.status === 200) {
        return { loading: false, newsletter: response.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return {
        loading: false,
        newsletter: {
          id: "",
          title: "",
          name: "",
          subject: "",
          market: "",
          template: {
            code: "",
            config: {
              topImage: {
                url: "",
                alt: "",
                image: "",
              },
              offers: [],
              emailTop: "",
              bottomImage: {
                url: "",
                alt: "",
                image: "",
              },
            },
          },
        },
      };
      // throw error;
    }
    return { loading: true, newsletter: {} };
  };
  newsletterData = await fetchData();
  return { newsletterData };
};

export const useNewsletterCreate = async (data, uniqueValue) => {
  let newsletterData = {
    loading: true,
    newsletter: {},
    status: 0,
  };
  const newsLetterToSave = {
    id: uniqueValue,
    title: data.title,
    name: data.name,
    subject: data.subject,
    market: data.market,
    template: {
      code: "DEFAULT_OFFERS",
      config: {
        topImage: {
          url: data.template.config.topImage.url ?? "",
          alt: data.template.config.topImage.alt ?? "",
          image: data.template.config.topImage.image ?? "",
        },
        offers: data.template.config.offers ?? [],
        emailTop: data.template.config.emailTop ?? "",
        bottomImage: {
          url: data.template.config.bottomImage.url ?? "",
          alt: data.template.config.bottomImage.alt ?? "",
          image: data.template.config.bottomImage.image ?? "",
        },
        preview: data.template.config.preview ?? "",
      },
    },
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(NEWSLETTER_URL, newsLetterToSave);
      if (response.status === 201) {
        return { loading: false, newsletter: response.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      alert(`Error al crear la newsletter: ${error.message}`);
      return { loading: false, newsletter: {} };
      // throw error;
    }
    return { loading: true, newsletter: {} };
  };
  newsletterData = await fetchData();
  return { newsletterData };
};

export const useNewsletterSpamCheck = async (id) => {
  let scoreData = {
    loading: true,
    score: {},
    status: 0,
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${NEWSLETTER_URL}/${id}/spam-check`);
      if (response.status === 200) {
        return { loading: false, score: response.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      alert(`Error al realizar el spam check: ${error.message}`);
      return { loading: false, score: {} };
      // throw error;
    }
    return { loading: true, score: {} };
  };
  scoreData = await fetchData();
  return { scoreData };
};

export const useNewsletterUpdate = async (data) => {
  let newsletterData = {
    loading: true,
    newsletter: {},
    status: 0,
  };
  const fetchData = async () => {
    try {
      const response = await axios.put(`${NEWSLETTER_URL}/${data.id}`, data);
      if (response.status === 204) {
        return { loading: false, newsletter: response.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      alert(`Error al actualizar la newsletter: ${error.message}`);
      return { loading: false, newsletter: {} };
      // throw error;
    }
    return { loading: true, newsletter: {} };
  };
  newsletterData = await fetchData();
  return { newsletterData };
};

export const useNewsletterPublish = async (id) => {
  let newsletterData = {
    loading: true,
    newsletter: {},
    status: 0,
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(`${NEWSLETTER_URL}/${id}/publish`);
      if (response.status === 204) {
        return { loading: false, newsletter: response.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      alert("Error al publicar la newsletter");
      return { loading: false, newsletter: {} };
      // throw error;
    }
    return { loading: true, newsletter: {} };
  };
  newsletterData = await fetchData();
  return { newsletterData };
};
