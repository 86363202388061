import React, { Fragment, useState, useEffect } from "react";
import { ContainerFenix, UsCardTitle } from "@atoms/Card/UsCard.styles";
import { useParams, useHistory } from "react-router-dom";
import { getAction, useActionsList, putAction } from "../../hooks/useActionsFetch";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { TextField } from "@material-ui/core";
import ButtonSave from "@atoms/Buttons/ButtonSave"; 
import { Row, Col, Card } from "react-bootstrap";
import { LS_getItem } from "@lib/storage/localStorage";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import { SelectMarketPosition } from "./ActionsListEditor.styles";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ErrorModal from "@atoms/Modals/ErrorModal";
import FxSwitch from "@atoms/Switches/FxSwitch";
import { TYPES_ACTIONS } from "../../utils/types";
//COMPONENTS
import Faqs from "../../components/Faqs";
import ActionAlert from "../../components/ActionAlert";
import ActionAdditionalSettings from "../../components/ActionAdditionalSettings";

function ActionEditor() {
  const { ActionCode } = useParams();
  const { loadingAction, action } = getAction(ActionCode);
  const [form, setForm] = useState(action);
  const [market, setMarket] = useState(LS_getItem("market") === null ? "es" : LS_getItem("market"));
  const [actionsList, setActionsList] = useState({
    loadingList: true,
    list: [],
    marketApplied: null,
  });
  const [actionAlert, setActionAlert] = useState({
    level: "",
    message: "",
    state: false,
  });
  const [alertData, setAlertData] = useState({ text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [errors, setErrors] = useState({ show: false, list: [] });
  const history = useHistory();
  const isEdition = Object.keys(action).length > 0;

  useEffect(() => {
    handleUseActionsList();
  }, [market]);

  useEffect(() => {
    if (isEdition) {
      const nuActionAlert = {
        level: "",
        message: "",
        state: false,
      };
      if (action.alert !== null) {
        nuActionAlert.level = action.alert.level;
        nuActionAlert.message = action.alert.message;
        nuActionAlert.state = true;
      }
      setActionAlert(nuActionAlert);
      setForm(action);
    } else {
      setForm({
        id: ActionCode,
        parentId: "",
        title: "",
        tld: market,
        alert: null,
        faqIds: [],
        type: "",
        forCommentWithBook: false,
      });
    }
  }, [loadingAction]);

  const handleUseActionsList = async () => {
    let actions = await useActionsList(market);
    setActionsList(actions);
  };

  const backToTable = () => {
    history.push(`/app/content/contact-center/actions`);
  };

  const selectParentId = (value) => {
    const nuForm = { ...form };
    if (value === "-1") {
      nuForm.parentId = "";
      nuForm.type = "";
      nuForm.forCommentWithBook = false;
    } else {
      const actionSelectedAsHisParent = actionsList.list[value];
      nuForm.parentId = actionSelectedAsHisParent.id;
      nuForm.type = nuForm.type;
      nuForm.forCommentWithBook = actionSelectedAsHisParent.forCommentWithBook;
    }
    setForm(nuForm);
  };

  const selectActionType = (event) => {
    const nuForm = { ...form };
    nuForm.type = event.target.value;
    setForm(nuForm);
  };

  const onChangeActionType = (value) => {
    const nuActionAlert = { ...actionAlert };
    const nuForm = { ...form };
    nuActionAlert.state = value;
    if (value) {
      nuActionAlert.level = "warning";
      nuForm.alert = {
        level: "warning",
        message: "",
      };
    } else {
      nuForm.alert = null;
      nuActionAlert.level = "";
      nuActionAlert.message = "";
    }
    setActionAlert(nuActionAlert);
    setForm(nuForm);
  };

  const onChangeLevelActionAlert = (value) => {
    const nuActionAlert = { ...actionAlert };
    const nuForm = { ...form };
    nuActionAlert.level = value.target.value;
    nuForm.alert = {
      level: value.target.value,
      message: actionAlert.message,
    };
    setActionAlert(nuActionAlert);
    setForm(nuForm);
  };

  const onChangeFaqs = (faqIdsList) => {
    const nuForm = { ...form };
    nuForm.faqIds = faqIdsList;
    setForm(nuForm);
  };

  const onChangeActionAlertMessage = (nVal) => {
    const nuForm = { ...form };
    nuForm.alert.message = nVal.target.value;
    setForm(nuForm);
    const nuActionAlert = { ...actionAlert };
    nuActionAlert.message = nVal.target.value;
    setActionAlert(nuActionAlert);
  };

  const onChangeActionForCommentWithBook = (nuValue) => {
    const nuForm = { ...form };
    nuForm.forCommentWithBook = nuValue;
    setForm(nuForm);
  };

  const onChangeActionEnable = (nuValue) => {
    const nuForm = { ...form };
    nuForm.enable = nuValue;
    setForm(nuForm);
  };

  const saveForm = async () => {
    const resultValidate = valiateForm();
    if (!resultValidate) return;
    const response = await putAction(form);
    if (response.ok) {
      setAlertOpen(true);
      setAlertData({ text: `Acción guardada con éxito` });
    }
  };

  const valiateForm = () => {
    const listErrors = [];
    if (form.title === "") {
      listErrors.push("El campo titulo no puede estar vacio.");
    }
    if (form.type === "") {
      listErrors.push("El campo tipo no puede estar vacio.");
    }
    if (actionAlert.state && actionAlert.message === "") {
      listErrors.push("El campo mensaje no puede estar vacio.");
    }

    const result = listErrors.length > 0;

    if (result) {
      setErrors({
        show: true,
        list: listErrors,
      });
    }

    return !result;
  };

  return (
    <Fragment>
      <ContainerFenix>
        <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
        <ErrorModal
          show={errors.show}
          listErrors={errors.list}
          onClose={() => {
            setErrors({ show: false, list: [] });
          }}
        />
        <SectionTitle
          title={`Acción (${TYPES_ACTIONS[form.type] || ""} - ${form.title})`}
          backFunction={backToTable}
          buttons={[
            <ButtonSave
              key={"btG"}
              color="primary"
              aria-label="add"
              onClick={saveForm}
            >
              Guardar acción
            </ButtonSave>,
          ]}
        />

        <Card>
          <Card.Body>
            <UsCardTitle>
              <span>Informacion de la acción</span>
              <FxSwitch
                value="enable"
                labels={["Acción Activada", "Acción Desactivada"]}
                status={form.enable}
                onChange={onChangeActionEnable}
              />
            </UsCardTitle>
            <br />
            <Row>
              <Col xs={6}>
                <TextField disabled={true} label="Código" value={form.id} />
              </Col>

              <Col xs={6}>
                <SelectMarketPosition>
                  <SelectMarket
                    selection={form.tld}
                    onChangeMarket={(nVal) => {
                      setMarket(nVal);
                      const nuForm = { ...form };
                      nuForm.tld = nVal;
                      setForm(nuForm);
                      selectParentId("-1");
                    }}
                  />
                </SelectMarketPosition>
              </Col>
            </Row>
            <br />
            <Row className="justify-content-md-center">
              <Col xs={12}>
                <div shrink id="demo-simple-select-placeholder-label-label">
                  Titulo *
                </div>
                <TextField
                  label=""
                  value={form.title}
                  onChange={(nVal) => {
                    const nuForm = { ...form };
                    nuForm.title = nVal.target.value;
                    setForm(nuForm);
                  }}
                />
              </Col>
            </Row>
            <br />
            {ActionAdditionalSettings(
              actionsList,
              form,
              selectParentId,
              selectActionType,
              onChangeActionForCommentWithBook
            )}
          </Card.Body>
        </Card>
        <Row className="justify-content-md-center">
          <Col xs={6}>
            {ActionAlert(
              actionAlert,
              onChangeActionType,
              onChangeActionAlertMessage,
              onChangeLevelActionAlert
            )}
          </Col>
          <Col xs={6}>{Faqs(form, onChangeFaqs)}</Col>
        </Row>
      </ContainerFenix>
    </Fragment>
  );
}

export default ActionEditor;
