/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { Grid, FormControlLabel, Checkbox, List, ListItem } from "@material-ui/core";
import FxSwitch from "@atoms/Switches/FxSwitch";
import InputNumber from "@atoms/Inputs/InputNumber";
import { numberListBetweenTwo } from "../utils";

function MassUpdateCell({ loading, label, selection, onChange, priceInputs }) {
  const dayNames = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
  // const dayNames = ["Lun", "Mar", "Mié", "Juv", "Vie", "Sáb", "Dom"];

  const [byWeek, setByWeek] = useState(false);

  const listWeekDays = numberListBetweenTwo(1, 7);

  function onChangeMain() {
    onChange({ ...selection, changed: !selection.changed });
  }

  function onChangeByWeek() {
    setByWeek(!byWeek);
  }

  function isDisabled() {
    return loading || !selection.changed;
  }

  function onChangeOne(field, newValue, dayToChange) {
    const value = { ...selection.value };
    if (dayToChange === "ALL") {
      value[field] = listWeekDays.reduce((a, n) => ({ ...a, [n]: newValue }), {});
    } else {
      value[field][dayToChange] = newValue;
    }
    onChange({ ...selection, value });
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} style={{ padding: "10px" }}>
        {/* Main checkbox: active/deactive changes */}
        <FormControlLabel
          control={
            <Checkbox
              disabled={loading}
              size="small"
              color="primary"
              checked={selection.changed}
              onChange={onChangeMain}
            />
          }
          label={<b>{label}</b>}
        />
        <FormControlLabel
          control={
            <Checkbox size="small" checked={byWeek} onChange={onChangeByWeek} disabled={isDisabled()} />
          }
          label={"Semanal"}
        />
      </Grid>
      {selection.changed && (
        <Grid item xs={12}>
          <div style={{ backgroundColor: "#fff" }}>
            {!byWeek && (
              <DayFormRow
                disabled={loading || !selection.changed}
                selectionValue={selection.value}
                weekDay={"ALL"}
                {...{ priceInputs, onChangeOne }}
              />
            )}
            {byWeek && (
              <List>
                {listWeekDays.map((weekDayIndex) => (
                  <ListItem
                    key={`wd${weekDayIndex}`}
                    style={{ paddingBottom: "0", paddingTop: "0", alignItems: "flex-start" }}
                  >
                    <span style={{ minWidth: "70px" }}>
                      <b>{dayNames[weekDayIndex - 1]}</b>
                    </span>
                    <DayFormRow
                      disabled={loading || !selection.changed}
                      selectionValue={selection.value}
                      weekDay={weekDayIndex}
                      {...{ priceInputs, onChangeOne }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        </Grid>
      )}
    </Grid>
  );
}

function DayFormRow({ disabled, selectionValue, priceInputs, weekDay, onChangeOne }) {
  const focusIndex = weekDay === "ALL" ? "1" : weekDay;

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ display: "flex", padding: "10px", flexBasis: "150px", flexGrow: "1" }}>
        <EnableCell
          disabled={disabled}
          selectionEnable={selectionValue.enable[focusIndex]}
          onChange={(nuEnable) => onChangeOne("enable", nuEnable, weekDay)}
        />
      </div>
      {priceInputs
        .filter((p) => p.code !== "enable")
        .map((thisInput) => (
          <div
            key={`input${thisInput.code}`}
            style={{ display: "flex", padding: "10px", flexBasis: "150px", flexGrow: "1" }}
          >
            <PriceCell
              disabled={disabled}
              selectionPriceElement={selectionValue[thisInput.code][focusIndex]}
              priceFieldData={thisInput}
              onChange={(nuP) => onChangeOne(thisInput.code, nuP, weekDay)}
            />
          </div>
        ))}
    </div>
  );
}

function EnableCell({ disabled, selectionEnable, onChange }) {
  function onChangeIsActive() {
    onChange({ ...selectionEnable, active: !selectionEnable.active });
  }

  function onChangeValue() {
    onChange({ ...selectionEnable, value: !selectionEnable.value });
  }

  return (
    <>
      <ActiveCheckbox checked={selectionEnable.active} onChange={onChangeIsActive} disabled={disabled} />
      <div
        style={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "lightgray",
          borderRadius: "0 4px 4px 0",
        }}
      >
        <FxSwitch
          labels={["Abierto", "Cerrado"]}
          status={selectionEnable.value}
          disabled={!selectionEnable.active || disabled}
          onChange={onChangeValue}
        />
      </div>
    </>
  );
}

function PriceCell({ disabled, selectionPriceElement, priceFieldData, onChange }) {
  function onChangeIsActive() {
    onChange({ ...selectionPriceElement, active: !selectionPriceElement.active });
  }

  function onChangeValue(newValue) {
    onChange({ ...selectionPriceElement, value: parseFloat(newValue) });
  }

  return (
    <>
      <ActiveCheckbox
        checked={selectionPriceElement.active}
        onChange={onChangeIsActive}
        disabled={disabled}
      />

      <InputNumber
        isCurrency={priceFieldData.isCurrency}
        style={{ borderRadius: "0 4px 4px 0" }}
        size="small"
        disabled={disabled || !selectionPriceElement.active}
        label={`${priceFieldData.label} (${priceFieldData.unit})`}
        value={selectionPriceElement.value}
        onChange={onChangeValue}
      />
    </>
  );
}

function ActiveCheckbox({ checked, onChange, disabled }) {
  return (
    <Checkbox
      style={{ backgroundColor: "#c9f7f5", borderRadius: "5px 0 0 5px" }}
      size="small"
      {...{ disabled, checked, onChange }}
    />
  );
}

export default MassUpdateCell;
