import React, { Fragment, useState } from "react";
import {
  Grid,
  Button,
  Menu,
  MenuItem,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import FxChip from "@atoms/Content/FxChip";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import { TravCard, TravHeaderCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import FxAlert from "@atoms/Alerts/FxAlert";
import InputNumber from "@atoms/Inputs/InputNumber";
import InputText from "@atoms/Inputs/InputText";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import MultiItemSelector from "@molecules/Forms/MultiItemSelector";
import SelectorAccommodationTypes from "@molecules/Forms/SelectorAccommodationTypes";
import { upDownItemInArray } from "@lib/helpers/array";

function CityTaxEditorRules({ ctTemplate, cityTaxParameters, onChange }) {
  const addNewRule = () => {
    const n = { ...ctTemplate };
    n.rules.push({ filters: [], alternatives: [] });
    onChange(n);
  };

  const addNewAlternative = (ixRule) => {
    const n = { ...ctTemplate };
    n.rules[ixRule].alternatives.push({ condition: [], rule: "", name: "" });
    onChange(n);
  };

  const changeAlternative = (newAlternative, ixRule, ixAlt) => {
    const n = { ...ctTemplate };
    n.rules[ixRule].alternatives[ixAlt] = newAlternative;
    onChange(n);
  };

  const onChangeRule = (ixRule, newRule) => {
    const n = { ...ctTemplate };
    n.rules[ixRule] = newRule;
    onChange(n);
  };

  const changeRuleName = (ixRule, inputResult) => {
    if (inputResult.valid) {
      const tRule = ctTemplate.rules[ixRule];
      onChangeRule(ixRule, { ...tRule, name: inputResult.value });
    }
  };

  const changeRuleOrder = (direction, ixRule) => {
    const nrules = upDownItemInArray(ctTemplate.rules, ixRule, direction === "up");
    const n = { ...ctTemplate };
    n.rules = nrules;
    onChange(n);
  };

  const changeAlternativeOrder = (direction, ixRule, ixAlt) => {
    const nruleAlternatives = upDownItemInArray(
      ctTemplate.rules[ixRule].alternatives,
      ixAlt,
      direction === "up"
    );
    const n = { ...ctTemplate };
    n.rules[ixRule].alternatives = nruleAlternatives;
    onChange(n);
  };

  const deleteRule = (index) => {
    if (confirm(`Vas a borrar esta regla "${ctTemplate.rules[index].name}"`)) {
      const n = { ...ctTemplate };
      n.rules.splice(index, 1);
      onChange(n);
    }
  };

  const deleteRuleAlternative = (ixRule, ixAlt) => {
    if (confirm(`Vas a borrar esta alternativa "${ctTemplate.rules[ixRule].alternatives[ixAlt].name}"`)) {
      const n = { ...ctTemplate };
      n.rules[ixRule].alternatives.splice(ixAlt, 1);
      onChange(n);
    }
  };

  return (
    <TravCard>
      <TravBodyCard>
        <TravTitleCard>Reglas</TravTitleCard>
        <FxAlert variant={"info"}>
          Aquí puedes seleccionar las diferentes reglas que aplicaran en los hoteles definidos en los
          requisitos. Cada regla tiene los siguientes componentes:
          <ul>
            <li>
              Una serie de <b>filtros</b>, para acotar los hoteles que hay dentro de los requisitos.{" "}
            </li>
            <li>
              <b>Alternativas</b>: cada alternativa tendrá asociada una <b>ecuación de regla</b>. Una ecuación
              de regla es una operación sencilla que podras definir escibiendola y usando las variables.{" "}
            </li>
            <li>
              Cada alternativa puede tener varias <b>condiciones</b>, que son una serie de condiciones
              específicas donde aplicará esa determinada ecuación de regla.{" "}
            </li>
          </ul>
        </FxAlert>

        <ButtonAdd onClick={addNewRule} >Añade nueva regla</ButtonAdd>

        {ctTemplate.rules.map((rule, index) => {
          return (
            <AccordionItem
              key={`Rule${index}`}
              title={
                <>
                  <ArrowUpDownControls
                    index={index}
                    onChangeOrder={(direction) => changeRuleOrder(direction, index)}
                    itemsLength={ctTemplate.rules.length}
                  >
                    <FxChip
                      style={{ height: "auto" }}
                      label={<b>#{index + 1}</b>}
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                    />
                  </ArrowUpDownControls>
                  <InputText
                    title={`Regla #${index + 1}`}
                    value={rule.name}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    onChange={(e) => changeRuleName(index, e)}
                  />
                  <ButtonDelete
                    size={"small"}
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteRule(index);
                    }}
                  />
                </>
              }
              open={false}
            >
              <>
                <ButtonAdd
                  text={"Añade nueva alternativa"}
                  onClick={() => addNewAlternative(index)}
                >Añade nueva alternativa</ButtonAdd>
                {rule.alternatives.map((alternative, indexAlt) => (
                  <CityTaxRuleAlternative
                    key={`alternative${indexAlt}`}
                    alternative={alternative}
                    index={indexAlt}
                    itemsLength={rule.alternatives.length}
                    cityTaxParameters={cityTaxParameters}
                    onChange={(newAlternative) => changeAlternative(newAlternative, index, indexAlt)}
                    onChangeOrder={(direction) => changeAlternativeOrder(direction, index, indexAlt)}
                    onDelete={() => deleteRuleAlternative(index, indexAlt)}
                  />
                ))}

                <CityTaxRuleFilter rule={rule} onChange={(nrule) => onChangeRule(index, nrule)} />
              </>
            </AccordionItem>
          );
        })}
      </TravBodyCard>
    </TravCard>
  );
}

const CityTaxRuleAlternative = ({
  alternative,
  index,
  itemsLength,
  cityTaxParameters,
  onChange,
  onChangeOrder,
  onDelete,
}) => {
  const onChangeName = ({ value, valid }) => {
    if (valid) {
      onChange({ ...alternative, name: value });
    }
  };

  return (
    <AccordionItem
      title={
        <>
          <ArrowUpDownControls index={index} itemsLength={itemsLength} onChangeOrder={onChangeOrder}>
            <FxChip
              style={{ height: "auto" }}
              label={<b>Alternativa #{index + 1}</b>}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
            />
          </ArrowUpDownControls>

          <InputText title={`Alternativa #${index + 1}`} value={alternative.name} onChange={onChangeName} />

          <ButtonDelete
            size={"small"}
            onClick={(event) => {
              event.stopPropagation();
              onDelete();
            }}
          />
        </>
      }
      open={false}
    >
      <Grid container>
        <Grid item xs={6}>
          <CityTaxAlternativeConditions alternative={alternative} onChange={onChange} />
        </Grid>
        <Grid item xs={6}>
          <CityTaxAlternativeEquation
            alternative={alternative}
            cityTaxParameters={cityTaxParameters}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </AccordionItem>
  );
};

const CityTaxAlternativeConditions = ({ alternative, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const conditionTypes = {
    NIGHTS: { code: "NIGHTS", name: "Número de noches", added: false, value: { min: "", max: "" } },
    SEASON: {
      code: "SEASON",
      name: "Por temporada",
      added: false,
      value: { min: { day: 1, month: 1 }, max: { day: 1, month: 1 } },
    },
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const addCondition = (key) => {
    const { condition } = alternative;
    condition.push({
      type: key,
      data: { value: { ...conditionTypes[key].value } },
    });

    onChange({ ...alternative, condition });
  };

  const changeConditionValue = (conditionIndex, newValue) => {
    const alt = { ...alternative };
    alt.condition[conditionIndex].data.value = newValue;
    onChange(alt);
  };

  const deleteCondition = (ixCondition) => {
    const alt = { ...alternative };
    alt.condition.splice(ixCondition, 1);
    onChange(alt);
  };

  return (
    <TravCard>
      <TravBodyCard>
        <TravTitleCard>Condiciones</TravTitleCard>

        <Button size="small" variant="outlined" color="secondary" onClick={handleClick}>
          Añade condiciones
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {Object.values(conditionTypes).map((cond, index) => (
            <MenuItem key={`cond${index}`} onClick={() => addCondition(cond.code)}>
              {cond.name}
            </MenuItem>
          ))}
        </Menu>

        <List>
          {alternative.condition.map((condition, ixCondition) => {
            const { value } = condition.data;
            return (
              <ListItem key={`Condition${ixCondition}`}>
                <FxChip label={conditionTypes[condition.type].name} color="secondary" />
                {condition.type === "NIGHTS" && (
                  <>
                    <InputNumber
                      label={"Min."}
                      value={value.min}
                      onChange={(e) => changeConditionValue(ixCondition, { ...value, min: e })}
                    />
                    <InputNumber
                      label={"Max."}
                      value={value.max}
                      onChange={(e) => changeConditionValue(ixCondition, { ...value, max: e })}
                    />
                  </>
                )}
                {condition.type === "SEASON" && (
                  <>
                    <InputNumber
                      label={"Desde (día)"}
                      value={value.min.day}
                      onChange={(newValue) =>
                        changeConditionValue(ixCondition, {
                          ...value,
                          min: { day: newValue || 1, month: value.min.month },
                        })
                      }
                    />
                    <InputNumber
                      label={"Desde (mes)"}
                      value={value.min.month}
                      onChange={(newValue) =>
                        changeConditionValue(ixCondition, {
                          ...value,
                          min: { month: newValue || 1, day: value.min.day },
                        })
                      }
                    />

                    <InputNumber
                      label={"Hasta (día)"}
                      value={value.max.day}
                      onChange={(newValue) =>
                        changeConditionValue(ixCondition, {
                          ...value,
                          max: { day: newValue || 1, month: value.max.month },
                        })
                      }
                    />
                    <InputNumber
                      label={"Hasta (mes)"}
                      value={value.max.month}
                      onChange={(newValue) =>
                        changeConditionValue(ixCondition, {
                          ...value,
                          max: { month: newValue || 1, day: value.max.day },
                        })
                      }
                    />
                  </>
                )}
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => deleteCondition(ixCondition)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </TravBodyCard>
    </TravCard>
  );
};

const CityTaxAlternativeEquation = ({ alternative, cityTaxParameters, onChange }) => {
  const [showVariables, setShowVariables] = useState(false);

  const changeRule = (newRule) => {
    onChange({ ...alternative, rule: newRule.value });
  };

  const checkRuleEquation = (nuRule) => {
    let betweenString = nuRule.replace(/\s/gi, "");
    betweenString = nuRule.replace(/[ {}[\] ]/gi, "");
    betweenString = betweenString.toLowerCase();

    if (betweenString.includes("edadminima") || betweenString.includes("edadmaxima")) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <TravCard>
      <TravBodyCard>
        <TravTitleCard>
          Ecuación de regla
          <ButtonCustom
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => setShowVariables(!showVariables)}
          >
            {showVariables ? "Ocultar" : "Ver"} variables
          </ButtonCustom>
        </TravTitleCard>
        <InputText
          title={"Ecuacion"}
          value={alternative.rule}
          onChange={changeRule}
          validation={checkRuleEquation}
        />
        {showVariables && (
          <>
            <h5>Variables que debes utilizar</h5>
            <table>
              <tbody>
                {cityTaxParameters.map((param, index) => (
                  <tr key={`ruleparameter${index}`}>
                    <td>{param.parameter}</td>
                    <td>
                      <span style={{ marginLeft: "10px" }}>{param.description}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </TravBodyCard>
    </TravCard>
  );
};

const CityTaxRuleFilter = ({ rule, onChange }) => {
  const categories = [
    { code: "-1" },
    { code: "0" },
    { code: "1" },
    { code: "2" },
    { code: "3" },
    { code: "4" },
    { code: "5" },
    { code: "6" },
  ];

  const filterTypes = {
    ACCOMMODATIONTYPE: { code: "ACCOMMODATIONTYPE", name: "Tipo alojamiento", added: false },
    ACCOMMODATIONCAT: { code: "ACCOMMODATIONCAT", name: "Categoría", added: false },
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const addFilter = (key) => {
    const { filters } = rule;
    filters.push({ type: key, data: [] });
    onChange({ ...rule, filters });
  };

  const changeFilter = (indexF, newFilter) => {
    const { filters } = rule;
    filters[indexF].data = newFilter;
    onChange({ ...rule, filters });
  };

  const deleteFilter = (indexF) => {
    const { filters } = rule;
    filters.splice(indexF, 1);
    onChange({ ...rule, filters });
  };

  return (
    <TravCard>
      <TravBodyCard>
        <TravTitleCard>Filtros</TravTitleCard>

        <Button size="small" variant="outlined" color="secondary" onClick={handleClick}>
          Añade filtros
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {Object.values(filterTypes).map((f, index) => (
            <MenuItem key={`f${index}`} onClick={() => addFilter(f.code)}>
              {f.name}
            </MenuItem>
          ))}
        </Menu>

        {rule.filters.length === 0 && <>{"Esta regla no tiene filtros: se aplicara siempre."}</>}

        <Grid container>
          {rule.filters.map((filter, indexF) => (
            <Grid item xs={6} key={`filter${indexF}`}>
              <TravCard>
                <TravHeaderCard>
                  <TravTitleCard>Filtro #{indexF + 1}</TravTitleCard>
                  <ButtonDelete size={"small"} onClick={() => deleteFilter(indexF)} />
                </TravHeaderCard>
                <TravBodyCard>
                  {filter.type === "ACCOMMODATIONCAT" && (
                    <MultiItemSelector
                      title={"Categorias"}
                      selected={filter.data.map((f) => ({ code: f.toString() }))}
                      optionsTemplate={categories}
                      onChange={(nf) =>
                        changeFilter(
                          indexF,
                          nf.map((f) => f.code)
                        )
                      }
                    />
                  )}

                  {filter.type === "ACCOMMODATIONTYPE" && (
                    <SelectorAccommodationTypes
                      title={"Tipos de alojamiento"}
                      selected={filter.data}
                      onChange={(nf) => changeFilter(indexF, nf)}
                    />
                  )}
                </TravBodyCard>
              </TravCard>
            </Grid>
          ))}
        </Grid>
      </TravBodyCard>
    </TravCard>
  );
};

const ArrowUpDownControls = ({ children, index, itemsLength, onChangeOrder }) => {
  const chipProps = {
    style: { height: "auto" },
    colour: "grey",
    onFocus: (event) => {
      event.stopPropagation();
    },
  };

  return (
    <>
      <FxChip
        {...chipProps}
        disabled={index === 0}
        label={<BsChevronUp />}
        onClick={(event) => {
          event.stopPropagation();
          onChangeOrder("up");
        }}
      />
      {children}
      <FxChip
        {...chipProps}
        disabled={index === itemsLength - 1}
        label={<BsChevronDown />}
        onClick={(event) => {
          event.stopPropagation();
          onChangeOrder("down");
        }}
      />
    </>
  );
};

CityTaxEditorRules.propTypes = {};

export default CityTaxEditorRules;
