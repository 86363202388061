import { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useRappelEditor, upsertRappel, useSuppliers } from "../../hooks/useRappelsEditor";
import { getFormEmpty } from "../../utility/utility";

function useJsonPageEditor() {
  const { rappelCode } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const { loading, rappel, ok } = useRappelEditor(rappelCode);
  const { loadingSuppliers, suppliers, okSuppliers } = useSuppliers();
  const [form, setForm] = useState(loading ? getFormEmpty(rappelCode) : rappel);
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [infoModal, setInfoModal] = useState({ show: false, text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });
  const isDuplication = new URLSearchParams(search).get("duplicate") !== null;
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const MINCHAR = 2;

  useEffect(() => {
    if (!loading) {
      if (ok) {
        setForm(rappel);
      } else {
        setForm({ ...form, rappelPercentage: 0 });
      }
    }
    if (errors.list.length > 0) {
      setErrors({ show: true, list: [errors.list] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rappel]);

  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };

  const backToTable = () => {
    history.push(`/app/product/rappel/list`);
  };

  const checkFormErrors = () => {
    const nuErrors = [];
    if (!form.dateoFrom) {
      nuErrors.push("Falta un fecha válida.");
    }
    if (!form.dateoTo) {
      nuErrors.push("Falta una fecha válida.");
    }
    if (!form.provider) {
      nuErrors.push("Falta un proveedor válido.");
    }
    if (form.provider.length === 0) {
      nuErrors.push("El provider no puede estar vacío.");
    }
    if (!form.rules) {
      nuErrors.push("Falta un regla válida.");
    }
    if (form.provider.length === "") {
      nuErrors.push("El porcentaje no puede estar vacío.");
    }
    if (!form.rappelPercentage) {
      nuErrors.push("Falta un porcentaje válido.");
    }
    return nuErrors;
  };

  const selectOption = (newOption) => {
    setOptions([]);
    const nuForm = { ...form };
    nuForm.provider = newOption.code;
    setForm(nuForm);
  };

  const handleSearchSupplier = async (event) => {
    const query = event.target.value;
    const nuForm = { ...form };
    nuForm.provider = query;
    setForm(nuForm);

    if (query.length === 0) {
      setIsOpen(false);
      setOptions([]);
    }
    if (query.length <= MINCHAR) {
      setIsOpen(false);
      setOptions([]);
      return;
    }
    setIsOpen(false);
    const optionsFound = suppliers.filter((provider) => provider.code.includes(query));

    if (query.length > MINCHAR) {
      if (optionsFound.length > 0) {
        setIsOpen(true);
        setOptions(optionsFound);
      } else {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
      setOptions([]);
    }
  };

  const saveRapple = async () => {
    const responseFromSaving = await upsertRappel(form);
    if (!responseFromSaving.ok) {
      setErrors({
        show: true,
        list: [`No se ha podido guardar por un error al enviar: ${responseFromSaving.message}`],
      });
    } else {
      handleAlertSnackBar(true, "Guardada correctamente.");
      backToTable();
    }
  };

  const handleSaveRapple = async () => {
    const nuErrors = checkFormErrors();
    if (nuErrors.length > 0) {
      setErrors({ show: true, list: nuErrors });
    } else {
      setConfirmationDialog({
        open: true,
        text: `Advertencia`,
        body: "Esta acción no puede revertirse. ¿Seguro que desea guardar los cambios?",
        onConfirm: () => {
          saveRapple();
        },
      });
    }
  };

  const onChangeForm = async (newValue) => {
    setForm(newValue);
  };

  const closeErrorModal = () => setErrors({ show: false, list: [] });
  const closeInfoModal = () => setInfoModal({ show: false, text: "" });

  return {
    backToTable,
    handleSaveRapple,
    onChangeForm,
    alertOpen,
    setAlertOpen,
    alertData,
    closeErrorModal,
    form,
    errors,
    loading,
    confirmationDialog,
    setConfirmationDialog,
    infoModal,
    closeInfoModal,
    loadingSuppliers,
    suppliers,
    okSuppliers,
    isOpen,
    options,
    handleSearchSupplier,
    selectOption,
    isDuplication,
  };
}

export default useJsonPageEditor;
