/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import ListEditable from "@atoms/Lists/ListEditable";
import { Row, Col, Card } from "react-bootstrap";
import { UsCardTitle } from "@atoms/Card/UsCard.styles";
import { OfferIconInclude, OfferIconNoInclude } from "./OfferEditorInfo.styles";
import { ImCheckmark, ImCross } from "react-icons/im";
import BrickQuerySearcher from "@organisms/contenido/bricks/components/BrickQuerySearcher";
import DraggableList from "@atoms/Lists/DraggableList";

function OffersEditorInfo({ form, onChangeList }) {
  const [contentBricksList, setContentBricksList] = useState([]);

  useEffect(() => {
    const nu = form.content.info_bricks.map((brick) => {
      return [`${brick.code}-${brick.name}`, brick];
    });
    setContentBricksList(nu);
  }, [form]);

  return (
    <Row>
      <Col xs={6}>
        <Card>
          <Card.Body>
            <OfferIconInclude>
              <ImCheckmark />
            </OfferIconInclude>
            <ListEditable
              title={"Incluye"}
              info={form.content.includes}
              form={form}
              typeInfo={"tick"}
              onChangeList={(includes) => {
                const nuForm = { ...form };
                nuForm.content.includes = includes;
                nuForm.content.excludes = form.content.excludes;
                onChangeList(nuForm);
              }}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col xs={6}>
        <Card>
          <Card.Body>
            {/* DRM : No me gusta que este componente te muestre la caja entera con titulo y todo. Es menos reutilizable yo creo */}
            <OfferIconNoInclude>
              <ImCross />
            </OfferIconNoInclude>
            <ListEditable
              title={"No Incluye"}
              info={form.content.excludes}
              form={form}
              typeInfo={"delete"}
              onChangeList={(excludes) => {
                const nuForm = { ...form };
                nuForm.content.excludes = excludes;
                nuForm.content.includes = form.content.includes;
                onChangeList(nuForm);
              }}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12}>
        <Card>
          <Card.Body>
            <UsCardTitle>
              <span>Información relevante</span>
              <BrickQuerySearcher
                type="brick"
                onSelect={(brick) => {
                  form.content.info_bricks.push({ code: brick.code, name: brick.name });
                  onChangeList({ ...form });
                }}
              />
            </UsCardTitle>
            <DraggableList
              title={"Content bricks"}
              list={contentBricksList}
              onChangeList={(newList) => {
                if (newList.length === 0) {
                  form.content.info_bricks = [];
                }
                //RMR: No entiendo que hace esto de abajo, pero al borrar no funciona

                form.content.info_bricks = newList.map((el) => el[1]);

                onChangeList({ ...form });
              }}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default OffersEditorInfo;
