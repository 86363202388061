import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { AiOutlineFolderView } from "react-icons/ai";
import { usePath, useSearchInput } from "../../FileManagerContext";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import { IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import CenterLoader from "@atoms/Loaders/CenterLoader";

const FileSearcherResults = ({ onCloseResults }) => {
  const { pathname } = useLocation();
  const [{ searchInput, bucket }, setSearcherInput] = useSearchInput();
  const [currentString, setCurrentString] = useState(searchInput);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentString(searchInput);
    if (searchInput !== "") {
      getResults()
        .then((resultList) => {
          setSearchResults(
            resultList.map((file) => {
              file.thumb = `${file.url}?thumb=true`;
              return file;
            })
          );
        })
        .catch(() => {});
    }
  }, [searchInput]);

  const getResults = async () => {
    setLoading(true);
    let listResults = [];
    try {
      const promises = [
        axios.get(`/api/v1/product-bc/files-manager/${bucket}/files?name[regex]=${searchInput}`),
        axios.get(`/api/v1/product-bc/files-manager/${bucket}/files?tags[regex]=${searchInput}`),
      ];

      const responses = await Promise.all(promises);
      responses.forEach((res) => {
        if (res.status === 200) {
          listResults = [...new Set([...listResults, ...res.data.data])];
        }
      });
    } catch (error) {
      console.error(error);
      alert(error);
    }

    setLoading(false);
    return listResults;
  };

  const goToThisPath = (row) => {
    window.open(`${pathname}?path=${encodeURIComponent(row.pathParent)}`, "_blank");
  };

  return (
    currentString !== "" && (
      <Fragment>
        <hr />
        {loading ? (
          <Fragment>
            <h3>{`Buscando con "${currentString}"...`}</h3>
            <CenterLoader />
          </Fragment>
        ) : (
          <Fragment>
            <h3>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => {
                  setSearcherInput("");
                  onCloseResults();
                }}
              >
                <KeyboardReturnIcon />
              </IconButton>
              {`Resultados para "${currentString}"`}
            </h3>

            {searchResults.length === 0 ? (
              <Alert severity="info">Ningún resultado obtenido para "{currentString}"</Alert>
            ) : (
              <FenixTable
                loading={loading}
                tableHead={[
                  { code: "thumb", name: "", type: "image" },
                  { code: "name", name: "Nombre", type: "string" },
                  { code: "url", name: "Url", type: "url" },
                  { code: "tags", name: "Tags", type: "tags" },
                ]}
                tableContent={searchResults}
                actions={[
                  { name: "Ver en carpeta", icon: <AiOutlineFolderView size={30} />, func: goToThisPath },
                ]}
                showInputFilter={false}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    )
  );
};

export default FileSearcherResults;
