import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import CookiesList from "@organisms/contenido/cookies/pages/list/CookiesList";

function CookiesPageList() {
  return (
    <ContainerFenix>
      <CookiesList></CookiesList>
    </ContainerFenix>
  );
}

export default CookiesPageList;
