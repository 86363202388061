import { tlds } from "../shared/domainVariables";

export const precinctTypes = ["themeparks", "naturalparks", "ski", "spa", "cultural", "adventure"];
export const precinctTypesTranslation = {
  themeparks: { name: "🎢 Parque Temático", colour: "#d13f3f" },
  naturalparks: { name: "🦁 Parque Natural", colour: "#72d13f" },
  ski: { name: "🏂 Esquí", colour: "#3fd1c5" },
  spa: { name: "🪔 Spa & balneario", colour: "#d881f0" },
  cultural: { name: "🎭 Cultural", colour: "#d17e3f" },
  adventure: { name: "🚵 Aventura", colour: "#287018" },
};
export const contentFields = { name: "lang" };

export const wordcontrol = "ChannelManager_Ticketing_Precincts_v2";
export const wordcontrolContent = "ChannelManager_Ticketing_Precincts_Content_v1";
export const filename = "ticketing_precincts";
export const filenameContent = "ticketing_precincts_content";
export const wordcontrolChannels = "ChannelManager_Ticketing_Precincts_Channels_v2";
export const filenameChannels = "ticketing_precincts_channels";

export const csvHead = [
  "Codigo",
  "Nombre (es, aqui solo lectura)",
  "Categorias (separadas por coma)",
  "Country Code",
  `Tipo (${precinctTypes.join("|")})`,
  "URL imagen Logo",
  "URL imagen Cabecera",
  "IVA",
  // "Canales (provider|merchant, separados por comas)",
  "Latitud",
  "Longitud",
  "Dirección",
  "Teléfono",
  "Email",
  "Hcity",
  "Hregion",
  "Hstate",
  "Hcountry",
];

export const csvSpecs = {
  code: "",
  name: { "es-ES": "" },
  categories: "sc",
  countryCode: "",
  type: "",
  logo: "",
  headImage: "",
  tax: "dz",
  // channels: "sc",
  location: {
    coordinates: {
      latitude: "fz",
      longitude: "fz",
    },
    address: "",
    phone: "",
    email: "",
    hcity: "",
    hregion: "",
    hstate: "",
    hcountry: "",
  },
};

export const csvHeadContent = [
  "Código de recinto",
  `Campo (${Object.keys(contentFields).join("|")})`,
  `Idioma (${tlds.join("|")})`,
  "Contenido",
];
export const csvSpecContent = { code: "", field: "", tld: "", value: "" };

export const csvHeadChannels = [
  "Recinto",
  "Proveedor",
  "Merchant",
  "Pais",
  "Modo de reserva",
  "Modo de reserva: Emails de envio (separado por comas)",
  "Modo de reserva: Emails en copia oculta (separado por comas)",
  "Modo de reserva: Emails: Incluir telefono",
  "Modo de reserva: Emails: Incluir alojamiento",
  "¿Modo de Bono igual Modo de reserva?",
  "Modo de Bono",
  "Pagadero del Bono",
  "Logo del Bono",
  "Dirección del Bono",
  "Email del Bono",
  "Teléfono del Bono",
  "¿Modo de cancelacion igual Modo de reserva?",
  "Modo de cancelacion",
  "Modo de cancelacion: Emails de envio (separado por comas)",
  "Modo de cancelacion: Emails en copia oculta (separado por comas)",
];
export const csvSpecChannels = {
  precinctCode: "",
  channel: {
    provider: "",
    merchant: "",
  },
  countryCode: "",
  booking: {
    mode: "",
    configuration: {
      emails: "sc",
      cco: "sc",
      include: {
        phone: "b",
        accommodation: "b",
      },
    },
  },
  voucher: {
    inherited: "b",
    mode: "",
    configuration: {
      payable: "",
      logo: "",
      address: "",
      email: "",
      phone: "",
    },
  },
  cancellation: {
    inherited: "b",
    mode: "",
    configuration: {
      emails: "sc",
      cco: "sc",
    },
  },
};

export const channelListToArray = (channelList) => {
  return channelList.map((ch) => {
    const channel = [ch.provider];
    if (ch.merchant !== undefined) {
      channel.push(ch.merchant);
    }
    return channel.join("|");
  });
};
