import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import PagesAliasesList from "@organisms/contenido/pages-aliases/PagesAliasesList/index";

function PagesAliasesListPage() {
  return (
    <ContainerFenix>
      <PagesAliasesList></PagesAliasesList>
    </ContainerFenix>
  );
}

export { PagesAliasesListPage };
