export const wordcontrol = "pages_aliases_v1";
export const filename = "pages_aliases";

export const csvHead = ["Mercado", "URL antigua", "URL nueva", "Tipo"];

export const csvSpecs = {
  tld: "",
  oldPath: "",
  newPath: "",
  type: "",
};

export const contentFields = {
  tld: "lang",
  oldPath: "lang",
  newPath: "lang",
  type: "lang",
};
