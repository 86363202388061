import React from "react";
import { useParams } from "react-router-dom";
import AffiliatesManagementPage from "@organisms/Marketing/Affiliates/management/components";

const AffiliateManagementPage = () => {
  const { affiliateId } = useParams();
  return <AffiliatesManagementPage creation={affiliateId === "_new"} affiliateId={affiliateId} />;
};

export default AffiliateManagementPage;
