import React from "react";
import axios from "axios";
import { Base64 } from "js-base64";

const COOKIE_NAME = "TRID";
const STORAGE_KEY = "TRRT";
const STORAGE_USER_KEY = "TRU";
const TEMP_COOKIE_SKIP = "x-tr-dev-skip-login";

export const setProfile = (userProfile) => {
  storageUserProfile(userProfile);
};

/**
 * This function removes login tokens
 */
export const logout = () => {
  removeCookieId();
  removeStorageValues();
};

/**
 * This function return the profile of user logged
 */
export const getUserProfile = () => {
  return getStorageUserProfile();
};

/**
 * This functio checks if is logged
 */
export const isLogged = () => {
  return (getCookieId() !== null && getStorageRefreshToken() !== null) || getCookieSkipSim() !== null;
};

/**
 * This function set Cookie TRID with accessToken
 */
function setCookieId(accessToken) {
  document.cookie = `${COOKIE_NAME}=${accessToken}; path=/; max-age=${30 * 60};`;
}

/**
 * This function get Cookie TRID
 */
function getCookieId() {
  var b = document.cookie.match("(^|;)\\s*" + COOKIE_NAME + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : null;
}

/**
 * This function get Cookie TRID
 */
function getCookieSkipSim() {
  var b = document.cookie.match("(^|;)\\s*" + TEMP_COOKIE_SKIP + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : null;
}

/**
 * This function remove Cookie TRID
 */
function removeCookieId() {
  document.cookie = `${COOKIE_NAME}=; path=/; max-age=0;`;
}

/**
 * This function get Storage TRRT
 */
function getStorageRefreshToken() {
  if (localStorage) {
    const value = localStorage.getItem(STORAGE_KEY);
    return value !== undefined ? value : null;
  }
  return null;
}

/**
 * this function set Storage TRU with userProfile
 */
function storageUserProfile(userProfile) {
  if (localStorage) {
    localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(userProfile));
  }
}

/**
 * This function get Storage TRU
 */
function getStorageUserProfile() {
  if (localStorage) {
    const value = localStorage.getItem(STORAGE_USER_KEY);
    try {
      return JSON.parse(Base64.decode(value));
    } catch (error) {
      return null;
    }
  }
  return null;
}

/**
 * This function remove Storage TRRT and TRU
 */
function removeStorageValues() {
  if (localStorage) {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(STORAGE_USER_KEY);
  }
}
