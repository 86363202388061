import React, { useState } from "react";
import CardTitle from "@atoms/Titles/CardTitle";
import { FiPlusSquare } from "react-icons/fi";
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";

const AffiliatesListView = ({
  affiliatesList,
  tableHead,
  affiliateActions,
  MAX_Affiliates_VIEW,
  goToAdd,
}) => {
  return (
    <>
      <CardTitle
        title={`Afiliados`}
        buttons={[
          <ButtonAdd key={"bt5"} variant="primary" size="md" onClick={goToAdd}>
            <FiPlusSquare />
          </ButtonAdd>,
        ]}
      />
      <FenixTable
        loading={affiliatesList.loadingList}
        tableHead={tableHead}
        tableContent={affiliatesList.list}
        actions={affiliateActions}
        maxNumberPerPages={MAX_Affiliates_VIEW}
        pagination={true}
        isSelectable={false}
      />
    </>
  );
};

export default AffiliatesListView;
