import React, { useEffect, useState } from "react";
import FenixTable from "@molecules/Tables/FenixTable";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import { PaymentsToSuppliersApi } from "../../api/PaymentsToSuppliersApi";
import useRemittanceView from "../RemittanceView/useRemittanceView";

const SupplierBlock = ({ open, supplier }) => {
  const [loading, setLoading] = useState(true);
  const [tableContent, setTableContent] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const invoicesSelected = selectedRows.length > 0;

  const { addInvoicesToRemittance } = useRemittanceView();

  const tableHead = [
    { code: "invoice", name: "Nº Factura", type: "string" },
    { code: "invoiceDate", name: "Fecha", type: "date" },
    {
      code: "status",
      name: "Estado",
      type: "label",
      translation: {
        OK: { name: "OK", colour: "green" },
        ERROR: { name: "Error", colour: "red" },
        PRICE_MISMATCH: { name: "Desajuste precio", colour: "orange" },
        PENDING: { name: "Pendiente", colour: "orange" },
        PROCESSING: { name: "Procesando", colour: "blue" },
        RECTIFICATIVE_PENDING: { name: "Procesando rectificativa", colour: "blue" },
      },
    },
    { code: "amount", name: "Importe (€)", type: "money" },
  ];

  async function fetchSupplierInvoices() {
    setLoading(true);
    setSelectedRows([]);
    const invoices = await PaymentsToSuppliersApi.getPendingInvoicesOfSupplier(supplier);
    setTableContent(invoices);
    setLoading(false);
  }

  useEffect(() => {
    if (open && tableContent.length === 0) {
      fetchSupplierInvoices().catch((error) => {
        console.error(error);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function onSelectRows(rowsSelected) {
    setSelectedRows(rowsSelected);
  }

  async function addInvoices() {
    setLoading(true);

    try {
      await addInvoicesToRemittance(
        selectedRows.map((row) => {
          // eslint-disable-next-line camelcase
          const { key_id, ...rest } = row; // Guarreria por culpa de FenixTable :S
          return { ...rest, supplier, date: row.invoiceDate };
        })
      );
      await fetchSupplierInvoices();
    } catch (error) {
      alert("Error al añadir las facturas a la remesa");
    }

    setLoading(false);
  }

  async function addAllInvoices() {
    setLoading(true);

    try {
      await addInvoicesToRemittance(
        tableContent.map((row) => {
          // eslint-disable-next-line camelcase
          return { ...row, supplier, date: row.invoiceDate };
        })
      );
      await fetchSupplierInvoices();
    } catch (error) {
      alert("Error al añadir las facturas a la remesa");
    }

    setLoading(false);
  }

  const pluralChar = selectedRows.length === 1 ? "" : "s";

  const csvHead = ["Factura", "Importe", "Fecha", "Estado"];
  const csvSpecs = { invoice: "", amount: "f", invoiceDate: "", status: "" };

  return (
    <>
      <ButtonAdd size={"large"} disabled={!invoicesSelected || loading} onClick={addInvoices}>
        {`Añadir ${selectedRows.length} factura${pluralChar} seleccionada${pluralChar} a remesa`}
      </ButtonAdd>
      <div style={{ float: "right" }}>
        <ButtonAdd size={"large"} disabled={loading} onClick={addAllInvoices}>
          {`Añadir todas`}
        </ButtonAdd>
        <ButtonDownloadCSV
          buttonLabel={"Descargar CSV"}
          disabled={!invoicesSelected || loading}
          wordcontrol={"SuppliersInvoicesToPay_v1"}
          head={csvHead}
          specs={csvSpecs}
          table={tableContent.map((t) => ({ ...t, amount: t.amount / 100 }))}
          filename={`Facturas_${supplier}`}
          onClick={addInvoices}
        />
      </div>
      <FenixTable
        loading={loading}
        tableHead={tableHead}
        tableContent={tableContent}
        isSelectable
        allowSelectAll
        selectedRows={selectedRows}
        onChangeSelections={onSelectRows}
        pagination
      />
    </>
  );
};

export default SupplierBlock;
