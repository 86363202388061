import React, { useState, useEffect, useCallback } from "react";
import FenixTable from "@molecules/Tables/FenixTable";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { Chip } from "@material-ui/core";
import TicketsSectionMenu from "./TicketsSectionMenu";
import { fetchTickets } from "./useTickets";
import UploadResultAlert from "../shared/UploadResultAlert";

function TicketsSection({ precinctCode }) {
  const [loadingTable, setLoadingTable] = useState(true);
  const [ticketList, setTicketList] = useState([]);
  const [uploadResult, setUploadResult] = useState();

  const fetchTicketList = useCallback(async () => {
    setLoadingTable(true);
    const tickets = await fetchTickets({ params: { precinctCode } });
    setTicketList(tickets);
    setLoadingTable(false);
  }, [precinctCode]);

  useEffect(() => {
    fetchTicketList();
  }, [fetchTicketList, uploadResult]);

  const tableHead = [
    { code: "id", name: "ID", type: "string" },
    { code: "productCode", name: "Producto", type: "string", filterable: true },
    { code: "alias", name: "Alias", type: "string" },
    { code: "peopleAges", name: "Edad", type: "string" },
    { code: "cancellation.isCancellable", name: "Cancelable", type: "boolean" },
    { code: "releaseDays", name: "Release", type: "string" },
    { code: "enable", name: "Activo", type: "boolean", text: { y: "Si", n: "No" } },
  ];

  const onUploadEnded = (resultUpload) => {
    let status = "success";
    if (resultUpload.errors.length > 0) {
      status = resultUpload.saved && resultUpload.saved > 0 ? "warning" : "error";
    }

    setUploadResult({ status, ...resultUpload });
  };

  function formatListItem(t) {
    return {
      ...t,
      peopleAges: t.people.ages?.map((age) => `[${age.min}-${age.max}]`).join(","),
      releaseDays: t.release.enable ? `${t.release?.daysToDate} dia(s)` : "-",
    };
  }

  const ticketListActive = ticketList.filter((t) => !t.archived).map((t) => formatListItem(t));
  const archived = ticketList.filter((t) => t.archived).map((t) => formatListItem(t));

  return (
    <>
      {uploadResult && <UploadResultAlert uploadResult={uploadResult} />}
      <FenixTable
        loading={loadingTable}
        tableTitle={
          <TicketsSectionMenu
            precinctCode={precinctCode}
            ticketList={ticketList}
            onUploadEnded={onUploadEnded}
          />
        }
        tableHead={tableHead}
        tableContent={ticketListActive}
      />
      {archived.length > 0 && (
        <AccordionItem
          open={false}
          title={
            <SectionTitle
              style={{ width: "100%", marginTop: "0" }}
              title={
                <>
                  {"Tarifas archivadas"}
                  <Chip label={archived.length} />
                </>
              }
            />
          }
        >
          <FenixTable loading={loadingTable} tableHead={tableHead} tableContent={archived} />
        </AccordionItem>
      )}
    </>
  );
}

TicketsSection.propTypes = {};

export default TicketsSection;
