import React, { useState } from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { InputScheduleBoxStyle, ListIntervaloStyle } from "./OfferBoat.styles";

function OfferBoatIntervalShedule({ title, propertyKey, indexBoatInfo, boatsInfo, setBoatsInfo }) {
  const [startShedule, setStartShedule] = useState("");
  const [endShedule, setEndShedule] = useState("");

  return (
    <div style={{ padding: "10px", border: "1px solid #ddd" }}>
      <h6>{title}</h6>
      <InputScheduleBoxStyle>
        <div>
          <input
            onChange={(e) => {
              setStartShedule(e.target.value);
            }}
            type="time"
            id="start"
            name="start"
            value={startShedule}
          ></input>
          <input
            onChange={(e) => {
              setEndShedule(e.target.value);
            }}
            type="time"
            id="end"
            name="end"
            value={endShedule}
          ></input>
        </div>
        <ButtonCustom
          customcolor={"blue"}
          size={"small"}
          onClick={() => {
            if (startShedule.length > 0 && endShedule.length > 0) {
              const newShedule = { from: startShedule, to: endShedule };
              boatsInfo[indexBoatInfo].scheduleFilter[propertyKey].push(newShedule);
              setBoatsInfo([...boatsInfo]);
              setStartShedule("--:--");
              setEndShedule("--:--");
            }
          }}
        >
          Añadir Intervalo
        </ButtonCustom>
      </InputScheduleBoxStyle>
      {boatsInfo[indexBoatInfo]?.scheduleFilter[propertyKey] !== undefined &&
        boatsInfo[indexBoatInfo]?.scheduleFilter[propertyKey].length > 0 && (
          <div>
            {boatsInfo[indexBoatInfo].scheduleFilter[propertyKey].map((item, index) => {
              return (
                <ListIntervaloStyle key={`${index}-${item.from}-${item.to}`}>
                  <span>
                    {item.from}-{item.to}
                  </span>{" "}
                  <ButtonCustom
                    customcolor={"tomato"}
                    size={"small"}
                    onClick={() => {
                      boatsInfo[indexBoatInfo].scheduleFilter[propertyKey].splice(index, 1);
                      setBoatsInfo([...boatsInfo]);
                    }}
                  >
                    {" "}
                    Eliminar
                  </ButtonCustom>
                </ListIntervaloStyle>
              );
            })}
          </div>
        )}
    </div>
  );
}

export default OfferBoatIntervalShedule;
