import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ButtonGroup, Button } from "@material-ui/core";
import MarketChip from "@atoms/Content/MarketChip";

function SelectLang({ selection, onChangeLang, withConfirm, confirmMessage, fullWidth = false }) {
  const langs = ["es-ES", "pt-PT", "fr-FR", "it-IT"];

  const [lang, setLang] = useState(selection || "es-ES");

  useEffect(() => {
    if (selection !== undefined) {
      setLang(selection);
    }
  }, [selection]);

  return (
    <Fragment>
      <ButtonGroup
        className="market-no-select-class"
        size="large"
        color="primary"
        aria-label="marketSelector"
        fullWidth={fullWidth}
      >
        {langs.map((ilang) => {
          return (
            <Button
              key={`lang_${ilang}`}
              color={ilang === lang ? "secondary" : "primary"}
              className={ilang === lang ? "market-select-class" : "market-no-select-class"}
              onClick={() => {
                const confirm = !withConfirm || window.confirm(confirmMessage);
                if (ilang !== undefined && confirm) {
                  setLang(ilang);
                  onChangeLang(ilang);
                }
              }}
            >
              <MarketChip market={ilang.split("-")[0]} />
            </Button>
          );
        })}
      </ButtonGroup>
    </Fragment>
  );
}

SelectLang.propTypes = {
  selection: PropTypes.string,
  onChangeLang: PropTypes.func,
};

export default SelectLang;
