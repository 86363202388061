import React from "react";
import { useHistory } from "react-router-dom";
// Icons
import EditIcon from "@material-ui/icons/Edit";
// Atoms
import SectionTitle from "@atoms/Titles/SectionTitle";
// Molecules
import { ThreeDotMenu, useThreeDotMenu } from "@molecules/Menus/ThreeDotMenu";
import FenixTable from "@molecules/Tables/FenixTable";
//
import MenuItemDownloadCSV from "../shared/MenuItemDownloadCSV";
import MenuItemUploadCSV from "../shared/MenuItemUploadCSV";
import {
  wordcontrolChannels as wordcontrolPrecinctChannels,
  csvHeadChannels as csvHeadPrecinctChannels,
  csvSpecChannels as csvSpecPrecinctChannels,
  filenameChannels as filenamePrecinctChannels,
} from "../Precincts/constants";
import bulkUploadPrecinctsChannels from "../Precincts/bulkUploadPrecinctsChannels";

function ChannelTable({ precinctCode, tableLoading, tableContent, actions, onUploadEnded }) {
  const history = useHistory();
  const tableHeadChannels = [
    { code: "channel.provider", name: "provider", type: "string" },
    { code: "channel.merchant", name: "merchant", type: "string" },
    { code: "booking.mode", name: "bookMode", type: "string" },
  ];

  const { anchorEl, onOpenMenu, handleClose } = useThreeDotMenu();

  function onUploadCSVEnded(result) {
    handleClose();
    onUploadEnded(result);
  }

  const editorChannels = () => {
    history.push(`${history.location.pathname}/channels`);
  };

  return (
    <>
      <FenixTable
        loading={tableLoading}
        tableTitle={
          <SectionTitle
            style={{ width: "100%", marginTop: "0" }}
            title={`Canales`}
            buttons={[
              <ThreeDotMenu key={"b1"} style={{ minWidth: "0" }} {...{ anchorEl, onOpenMenu, handleClose }}>
                <span style={{ width: "100%" }} onClick={editorChannels}>
                  <EditIcon size={25} /> Editar
                </span>
                <MenuItemUploadCSV
                  itemsName={"canales de recinto"}
                  wordcontrol={wordcontrolPrecinctChannels}
                  filename={`${precinctCode}_${filenamePrecinctChannels}`}
                  head={csvHeadPrecinctChannels}
                  specs={csvSpecPrecinctChannels}
                  buttonLabel={"Subir canales"}
                  uploadFunction={bulkUploadPrecinctsChannels}
                  onUploadEnded={onUploadCSVEnded}
                />
                <MenuItemDownloadCSV
                  wordcontrol={wordcontrolPrecinctChannels}
                  head={csvHeadPrecinctChannels}
                  specs={csvSpecPrecinctChannels}
                  filename={`${precinctCode}_${filenamePrecinctChannels}`}
                  buttonLabel={`Descargar canales`}
                  // generateTable={() => precinct.channels}
                  table={tableContent}
                  onDownloadEnded={handleClose}
                />
              </ThreeDotMenu>,
            ]}
          />
        }
        tableHead={tableHeadChannels}
        tableContent={tableContent || []}
        actions={actions}
      />
    </>
  );
}

ChannelTable.propTypes = {};

export default ChannelTable;
