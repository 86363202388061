import React, { Fragment } from "react";
import { Alert } from "react-bootstrap";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import CardTitle from "@atoms/Titles/CardTitle";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { FiPlusSquare } from "react-icons/fi";
import { HiOutlineMail, HiCloudDownload } from "react-icons/hi";
import CachedIcon from "@material-ui/icons/Cached";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import { sanitizeInvalidCharacters } from "../../../../lib/helpers/string";
import {
  TextCounterOffers,
  TextCounterTotal,
  TextCounterOffersDisabled,
  TextCounterOffersEnabled,
} from "./OfferList.styles";

const OfferListView = ({
  offerList,
  market,
  setMarket,
  setErrors,
  errorState,
  tableHead,
  actions,
  addNewOffer,
  handleMarket,
  MAX_OFFERS_VIEW,
  handleCheckboxReload,
  reload,
  reloadOffers,
  rowsSelected,
  onChangeMarketSelections,
  sendSummaryReport,
}) => {
  const headActions = [
    "Código Oferta",
    "Título",
    "Habilitado",
    "Mercado",
    "Destacado",
    "HCode",
    "Ticket",
    "Fecha de validez comienzo",
    "Fecha de validez final",
    "Periodos",
    "Noches",
    "Ofertas Flash",
    "Peso Oferta",
  ];

  const specsActions = {
    idOffer: "",
    title: "",
    enable: "",
    market: "",
    highlighted: "",
    hCode: "",
    ticket: "",
    startDate: "",
    endDate: "",
    dates: "",
    nights: "",
    offerFlash: "",
    relevanceScoring: "",
  };

  return (
    <Fragment>
      <ContainerFenix>
        {offerList.marketApplied !== null && (
          <TextCounterOffers>
            <TextCounterOffersEnabled>Habilitadas: {offerList.infoList.enabled} </TextCounterOffersEnabled>
            <TextCounterOffersDisabled>
              Deshabilitadas: {offerList.infoList.disabled}
            </TextCounterOffersDisabled>
            <TextCounterTotal>Total: {offerList.infoList.total}</TextCounterTotal>{" "}
          </TextCounterOffers>
        )}
        <CardTitle
          title={"Listado de ofertas"}
          buttons={[
            <SelectMarket key={"bt1"} selection={market} onChangeMarket={handleMarket} />,
            <ButtonCustom key={"bt7"} icon={<HiOutlineMail />} onClick={sendSummaryReport}>
              Enviar Informe
            </ButtonCustom>,
            <ButtonDownloadCSV
              key={"bt7"}
              head={headActions}
              specs={specsActions}
              table={offerList.list.map((offer) => {
                return {
                  idOffer: offer.idOffer,
                  title: sanitizeInvalidCharacters(offer.title).split(";").join(",").trim(),
                  enable: offer.enable ? "Habilitado" : "Deshabilitado",
                  market: offer.market,
                  highlighted: offer.highlighted ? "Destacado" : "No destacado",
                  hCode: offer?.services?.accommodations[0]?.hcode ?? "",
                  ticket: offer?.services?.tickets[0]?.code ?? "",
                  startDate: offer?.offerCriteria?.dates[0]?.startDate ?? "",
                  endDate:
                    offer?.offerCriteria?.dates[offer?.offerCriteria?.dates?.length - 1]?.endDate ?? "",
                  dates:
                    offer?.offerCriteria?.dates
                      .map((date) => [`[${date.startDate} : ${date.endDate}]`])
                      .join(",") ?? "",
                  nights: offer?.offerCriteria?.nights.join(",") ?? "",
                  offerFlash: offer?.flash?.enable ? offer.flash.datetime : "Desactivado",
                  relevanceScoring: offer.relevanceScoring ? offer.relevanceScoring : 0,
                };
              })}
              filename={`extract_offers_${market}`}
              buttonLabel={"Extraer ofertas CSV"}
              info={["Extraer ofertas"]}
            />,
            <ButtonAdd key={"bt5"} icon={<FiPlusSquare />} onClick={addNewOffer}>
              Nueva oferta
            </ButtonAdd>,
            <ButtonCustom
              key={"bt6"}
              customcolor="tomato"
              icon={<CachedIcon />}
              onClick={reloadOffers}
              disabled={rowsSelected[market].length < 1}
            >
              Recargar {rowsSelected[market].length}
            </ButtonCustom>,
          ]}
        />
        <ErrorModal
          show={errorState.show}
          listErrors={errorState.list}
          onClose={() => setErrors({ show: false, list: [] })}
        />
        {market === null ? (
          <Alert variant={"info"}>
            <span>Selecciona un mercado</span>
          </Alert>
        ) : (
          <Fragment>
            <FenixTable
              loading={offerList.loading}
              tableHead={tableHead}
              tableContent={offerList.list}
              actions={actions}
              maxNumberPerPages={MAX_OFFERS_VIEW}
              pagination={true}
              // handleRowsChecked={(values) => {
              //   handleCheckboxReload(values);
              // }}
              // checkboxInit={{ active: true, list: reload.valuesReload }}
              isSelectable={true}
              selectedRows={rowsSelected[market]}
              onChangeSelections={onChangeMarketSelections}
            />
          </Fragment>
        )}
      </ContainerFenix>
    </Fragment>
  );
};

export default OfferListView;
