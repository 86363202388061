import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { FiUploadCloud } from "react-icons/fi";

function ButtonUpload({ size, variant, disabled, onClick, label, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="Upload"
      label={label}
      icon={<FiUploadCloud />}
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonUpload;
