import React from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { ClickableTitleElements, PrincipalTitleTag, ElementTitleTag, ElementTagLeft } from "./Titles.styles";

function TagTitle({ title, counter = true, numberSelected = 0, active, onShowDown }) {
  return (
    <ClickableTitleElements onClick={onShowDown}>
      <PrincipalTitleTag>
        <ElementTagLeft>
          {numberSelected === 0 && <ImCheckboxUnchecked style={{ marginRight: "5px" }} />}
          {numberSelected > 0 && <ImCheckboxChecked style={{ marginRight: "5px" }} />}{" "}
          {counter && numberSelected}
        </ElementTagLeft>
        <ElementTitleTag>
          <h6 style={{ marginBottom: "0", fontWeight: "bold" }}>{title || " "}</h6>
          <div>
            {!active && <IoIosArrowForward onClick={onShowDown} />}
            {active && <IoIosArrowDown onClick={onShowDown} />}
          </div>
        </ElementTitleTag>
      </PrincipalTitleTag>
    </ClickableTitleElements>
  );
}

export default TagTitle;
