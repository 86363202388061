/* eslint-disable no-use-before-define */
import { hasDuplicates } from "@lib/helpers/array";
import { fetchRelevantInformationWithLang, saveRelevantInformation } from "./useProducts";
import { csvSpecRelevantInformation } from "./constants";
import { tldXLangMatch, tlds, getChannelID } from "../shared/domainVariables";
import difference from "../shared/differenceBetweenTwoLists";

const bulkUploadRelevantInformation = async (relevantInformationCsv) => {
  try {
    //* Get relevant Information stored
    const promises = [
      ...new Set(
        relevantInformationCsv.map((t) => {
          return { productCode: t.productCode, lang: tldXLangMatch[t.lang] };
        })
      ),
    ]
      .map((pr) => {
        return fetchRelevantInformationWithLang(pr.productCode, pr.lang);
      })
      .flat();
    const responsePromises = await Promise.all(promises);
    const currentInformation = responsePromises.flat();

    //*  Validation
    const errorsValidation = relevantInformationValidation(relevantInformationCsv);
    if (errorsValidation.length > 0) {
      return { errors: errorsValidation };
    }

    //* Change tld for lang
    const parsedRelevantInformation = relevantInformationCsv.map((ri) => ({
      ...ri,
      lang: tldXLangMatch[ri.lang],
    }));

    //* Get the intersection between the two
    const { toInsert, toUpdate } = difference(currentInformation, parsedRelevantInformation);

    //* Save the results
    const fullList = [...toInsert, ...toUpdate];
    let saved = 0;
    const errors = [];
    for (let index = 0; index < fullList.length; index += 1) {
      const relevantInformation = fullList[index];
      try {
        if (relevantInformation.channel.provider || relevantInformation.channel.merchant) {
          await saveRelevantInformation(relevantInformation, getChannelID(relevantInformation.channel));
        } else {
          delete relevantInformation.channel;
          await saveRelevantInformation(relevantInformation);
        }
        saved += 1;
      } catch (e) {
        errors.push(`Error en codigo ${relevantInformation.productCode}: ${e.message}`);
      }
    }

    return { saved, errors };
  } catch (e) {
    return { errors: [e.message] };
  }
};

const relevantInformationValidation = (relevantInformationList) => {
  const errors = [];

  // Check duplications of same concept
  const codeArray = relevantInformationList.map((ri) => [ri.productCode, ri.lang].join("_"));
  if (hasDuplicates(codeArray)) {
    throw new Error(`Más de una fila aplicando al mismo codigo de producto y lang.`);
  }

  relevantInformationList.forEach((info) => {
    try {
      // Mandatory
      Object.keys(csvSpecRelevantInformation).forEach((field) => {
        if (info[field] === undefined) {
          throw new Error(`Campo <${field}> es obligatorio`);
        }
      });

      // Not Empty
      Object.keys(csvSpecRelevantInformation).forEach((field) => {
        if (typeof info[field] === "string" && info[field].trim() === "") {
          throw new Error(`Campo <${field}> esta vacio`);
        }
      });

      if (!tlds.includes(info.lang)) {
        throw new Error(`Lang <${info.lang}> no valido`);
      }
    } catch (e) {
      errors.push(e.message);
    }
  });

  return errors;
};

export default bulkUploadRelevantInformation;
