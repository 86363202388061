import axios from "axios";
import { useState, useEffect } from "react";

const API_SOCIAL_NETWORKS = "/api/v1/product-bc/offers/social-network";
const URL_MARKETING_GENERATE_IMG = "/api/v1/product-bc/offers/social-network/generate/card-image";

export const useSocialNetworkPost = (offerCode) => {
  const [socialNetworkPost, setSocialNetworkPost] = useState({
    loadingSocialNetworkPost: true,
    socialNetworkPost: [],
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loadingSocialNetworkPost: true, socialNetworkPost: [] };
        // const response = await axios.get(`${API_SOCIAL_NETWORKS}`);
        const response = await axios.get(`${API_SOCIAL_NETWORKS}?idOffer=${offerCode}`);
        if (response.status === 200) {
          thestate = { loadingSocialNetworkPost: false, socialNetworkPost: response.data.data };
        }
        setSocialNetworkPost(thestate);
      } catch (error) {
        setSocialNetworkPost({ loadingSocialNetworkPost: false, socialNetworkPost: [] });
        throw error;
      }
    };
    if (socialNetworkPost.loadingSocialNetworkPost) {
      fetchData();
    }
  }, [socialNetworkPost, offerCode]);

  return {
    loadingSocialNetworkPost: socialNetworkPost.loadingSocialNetworkPost,
    socialNetworkPost: socialNetworkPost.socialNetworkPost,
  };
};

export const getCardImg = async (offerCode) => {
  let response = {};
  try {
    response = await axios.get(`${URL_MARKETING_GENERATE_IMG}/${offerCode}`);
    response.ok = true;
  } catch (error) {
    console.log(error);
  }
  return response.data.path;
};

export const publishPost = async (data) => {
  try {
    const response = await axios.post(`${API_SOCIAL_NETWORKS}/publish`, data);
    console.log(response);
  } catch (error) {
    console.log(error);
    const { response } = error;
    console.log(response);

    throw error;
  }
};
