import React, { useState } from "react";
// Styles
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { MdContentCopy } from "react-icons/md";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";

function ButtonCopyToClipboard({ size, variant, disabled, text, textToAlert, textToCopy }) {
  const [alertOpen, setAlertOpen] = useState(false);

  function copyToClipboard() {
    navigator.clipboard.writeText(textToCopy);
    setAlertOpen(true);
  }

  return (
    <>
      {document.queryCommandSupported("copy") && (
        <ButtonCustom
          size={size}
          disabled={disabled}
          variant={variant}
          aria-label="Copy to clipboard"
          icon={<MdContentCopy />}
          onClick={copyToClipboard}
        >
          {text !== "" && text}
        </ButtonCustom>
      )}
      <AlertSnackbar show={alertOpen} text={textToAlert} setOpen={setAlertOpen} severity={"info"} />
    </>
  );
}

ButtonCopyToClipboard.defaultProps = {
  text: "Copiar",
  textToAlert: "Copiado!",
};

export default ButtonCopyToClipboard;
