/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { Row, Col, Card, Alert } from "react-bootstrap";
import BrickQuerySearcher from "../BrickQuerySearcher";
import HtmlStructures from "./components/HtmlStructures";
import HtmlTextEditor from "@atoms/Inputs/HtmlTextEditor/HtmlTextEditor";

function HTMLPreview({ idPreview, html, destination, onChangeHTML }) {
  // useEffect(() => {
  //   onChangeHTML(html);
  // }, [html, onChangeHTML]);

  const changeHTML = (e) => {
    onChangeHTML(e);
  };

  const addTemplateToHTML = (templateHTML) => {
    addToHTML(templateHTML.content?.value);
  };

  const addToHTML = (nuHTML) => {
    let nu = html;
    nu += nuHTML;
    onChangeHTML(nu);
  };

  const renderPreview = () => {
    let url = "";
    switch (destination) {
      case "web-legacy":
        url = `https://www.traventia.es/html-preview/${idPreview}`;
        break;
      case "web-react":
        url = `https://www.traventia.es/app/html-preview/${idPreview}?headless=1`;
        break;

      default:
        return <Alert variant="info">Selecciona estilos a usar.</Alert>;
        break;
    }

    return <iframe style={{ width: "100%", height: "100%" }} src={url} />;
  };

  renderPreview();

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <BrickQuerySearcher
              label={"Busca un template para añadirlo al final del bloque HTML"}
              types={["templates"]}
              destinations={[destination]}
              onSelect={addTemplateToHTML}
            />
            <br />
            <HtmlStructures addToHTML={addToHTML} />
            <br />
            <br />

            <HtmlTextEditor
              id="brick-editor"
              value={html}
              onChange={changeHTML}
              placeholder={"Contenido de brick."}
            />
          </Col>
          <Col>{renderPreview()}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default HTMLPreview;
