import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import FxAlert from "@atoms/Alerts/FxAlert";
import { FiCornerUpLeft } from "react-icons/fi";
import {
  BoxSectionTitle,
  ButtonBack,
  PrincipalTitleElements,
  ElementsTitleRight,
  PrincipalTitleElement,
} from "./SectionTitle.styles";

function SectionTitle({ title, hx = "h2", info, buttons, backFunction, ...props }) {
  let infoArray = info;
  if (info !== undefined && !Array.isArray(info)) infoArray = [info];

  const CustomTitleTag = `${hx}`;

  return (
    <TravCard {...props}>
      <BoxSectionTitle>
        {backFunction && (
          <ButtonBack
            disableElevation
            variant="contained"
            startIcon={<FiCornerUpLeft />}
            onClick={backFunction}
          />
        )}
        <TravBodyCard>
          <PrincipalTitleElements>
            <PrincipalTitleElement>
              <CustomTitleTag style={{ marginBottom: "0" }}>{title || ""}</CustomTitleTag>
            </PrincipalTitleElement>
            {buttons && (
              <ElementsTitleRight>
                {buttons.map((buttonEl, indexB) => (
                  <Fragment key={`btTitle${indexB}`}>{buttonEl}</Fragment>
                ))}
              </ElementsTitleRight>
            )}
          </PrincipalTitleElements>
          {infoArray.length > 0 && (
            <div style={{ marginTop: "15px" }}>
              <FxAlert variant="info">
                <div dangerouslySetInnerHTML={{ __html: infoArray }}></div>
              </FxAlert>
            </div>
          )}
        </TravBodyCard>
      </BoxSectionTitle>
    </TravCard>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  info: PropTypes.array,
  buttons: PropTypes.array,
};

SectionTitle.defaultProps = { info: [] };

export default SectionTitle;
