import React from "react";
import FxAlert from "@atoms/Alerts/FxAlert";

const ValidationInstructions = () => {
  return (
    <FxAlert variant={"info"} dismissable>
      <p>
        <b>¿Cómo es este proceso?</b>
      </p>
      <ul>
        <li>
          Cuando inicies el proceso utilizando el botón de abajo, se enviará un email a tu correo electrónico.
        </li>
        <li>
          En ese email se enviará la informacion de la cuenta bancaria y un código de verificacion.{" "}
          <b>
            Compara la informacion del email con la que tienes en esta pantalla y asegúrate de que es la
            misma.
          </b>
        </li>
        <li>
          Introduce el código de verificación que aparece en el email en la casilla habilitada para ello.
        </li>
        <li>
          Paralelamente, podras utilizar un <b>botón para enviar un mensaje SMS al teléfono autorizado</b>. El
          SMS contendra un segundo código de verificación que podras introducir en otra casilla habilitada.
        </li>
        <li>
          Una vez tengas <b>los dos códigos de verificacion introducidos</b>, podrás enviar la verificación
          completa.
        </li>
      </ul>
    </FxAlert>
  );
};

export default ValidationInstructions;
