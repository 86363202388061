/* eslint-disable import/prefer-default-export */
class AccountInfoTax {
  allFacturasInfoTax = {};
  allFacturasProcessed = {};

  //constructor() {}

  add = (facturaInfoTax) => {
    //TODO: Check if facturaInfoTax is valid
    //TODO: Check if is repetida
    this.allFacturasInfoTax[facturaInfoTax.factura] = facturaInfoTax;
  };

  has = (accountNumber) => {
    return this.allFacturasInfoTax[accountNumber] !== undefined;
  };

  get = (accountNumber) => {
    return this.allFacturasInfoTax[accountNumber];
  };

  processed = (accountNumber) => {
    this.allFacturasProcessed[accountNumber] = true;
  };

  checkNotProcessed = () => {
    const notProcessed = [];
    Object.keys(this.allFacturasInfoTax).forEach((key) => {
      if (!this.allFacturasProcessed[key]) {
        notProcessed.push(key);
      }
    });
    return notProcessed;
  };
}

function isNumeric(str) {
  if (typeof str !== "string") return false; // we only process strings!
  const strToEval = str.replace(/,/g, ".");
  return (
    !Number.isNaN(strToEval) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(parseFloat(strToEval))
  ); // ...and ensure strings of whitespace fail
}

export const createAccountInfoTaxFromCsv = (csvFileContent) => {
  const facturasInfoTax = new AccountInfoTax();

  const facturas = csvFileContent
    .split("\r\n")
    .map((rows) => rows.split(";"))
    .map((row, index) => {
      if (index === 0) {
        return null;
      }
      if (row[0] === "") {
        return null;
      }
      return {
        factura: row[0],
        iva: isNumeric(row[1]) ? parseInt(row[1], 10) : null,
        BaseImponible: parseFloat(row[2].replace(",", ".")),
        cuotaRepercutida: isNumeric(row[3]) ? parseFloat(row[3].replace(",", ".")) : null,
      };
    })
    .filter((row) => row !== null);
  facturas.forEach((cur) => {
    facturasInfoTax.add(cur);
  });
  return facturasInfoTax;
};
