import React, { useState } from "react";
import CustomModal from "@molecules/Modal/CustomModal";
import Money from "@atoms/Money/Money";
import ButtonCancel from "@atoms/Buttons/ButtonCancel";
import ButtonCheck from "@atoms/Buttons/ButtonCheck";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import FxAlert from "@atoms/Alerts/FxAlert";
import { userAuthorized } from "@src/entries/fenix/auth/FenixAuthContext";
import RemittancesApi from "../api/RemittancesApi";

const RemittancePaymentModal = ({ open, remittance, onClose }) => {
  const { profile } = userAuthorized();
  const paidBy = profile.email;

  const [onError, setOnError] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);
  const [remittanceLoading, setRemittanceLoading] = useState(false);

  async function onConfirmRemittance() {
    setOnError(false);
    setRemittanceLoading(true);
    try {
      await RemittancesApi.paidRemittance(remittance.id, paidBy);
      setOnSuccess(true);
    } catch (e) {
      setOnError(true);
    }
    setRemittanceLoading(false);
  }

  function onCloseSuccess() {
    window.location.reload();
  }

  return (
    <CustomModal
      loading={false}
      title={`Pagar remesa`}
      open={open}
      handleClose={onClose}
      bigModal={true}
      closeOnClickOutside={false}
    >
      {remittanceLoading ? (
        <>
          <CenterLoaderCircle />
          <p style={{ textAlign: "center" }}>Pagando remesa...</p>
        </>
      ) : (
        <>
          {onSuccess ? (
            <FxAlert variant={"success"}>
              {"Has pagado esta remesa. Podras consultar la remesa en la tabla inferior 'Remesas pagadas'."}
              <ButtonCheck onClick={onCloseSuccess}>{"¡Entendido!"}</ButtonCheck>
            </FxAlert>
          ) : (
            <>
              {onError && (
                <FxAlert variant={"error"}>
                  {
                    "¡Lo sentimos! Ha ocurrido un error al pagar la remesa. Vuelve a intentarlo en unos momentos."
                  }
                </FxAlert>
              )}

              {remittance !== null && (
                <>
                  <p>Vas a pagar esta remesa, revisa los datos:</p>
                  <ul>
                    <li>ID: {remittance.id}</li>
                    <li>Creada por: {remittance.createdBy}</li>
                    <li>Fecha creacion: {remittance.datecreation}</li>
                    <li>Nº Facturas: {remittance.ninvoices}</li>
                    <li>
                      {`Total acumulado: `}
                      <b>
                        <Money amount={remittance.ntotal} />
                      </b>
                    </li>
                  </ul>

                  <div>
                    <p>¿Quieres confirmar el pago de esta remesa?</p>
                    <ButtonCheck onClick={onConfirmRemittance}>{"Confirmar"}</ButtonCheck>
                    <ButtonCancel customcolor={"tomato"} onClick={onClose}>
                      {"Cerrar"}
                    </ButtonCancel>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </CustomModal>
  );
};

export default RemittancePaymentModal;
