// useFetch.js
import { useEffect, useState } from "react";
import axios from "axios";

const url = "/api/v1/ticketing-bc/providers";

/**
 *
 * @param {*} searchParams
 */
export const searchInB2B = async (searchParams) => {
  let responseRet = { ok: false, results: [] };
  try {
    const body = {
      //DateFilters: {
      //"ServiceStartDate": {
      //    "StartDate": "2020-12-01",
      //    "EndDate": "2020-12-29"
      //}
      //"ServiceEndDate": {
      //    "StartDate": "2019-01-01",
      //    "EndDate": "2019-01-31"
      //}
      //},
      //BookingLocator: "GEJNTA",
    };

    if (searchParams.selection === "locata") {
      body["BookingLocator"] = searchParams.locata;
    } else if (searchParams.selection === "datesi") {
      body["ServiceStartDate"] = {
        StartDate: searchParams.datei,
        EndDate: searchParams.dateo,
      };
    } else if (searchParams.selection === "dateso") {
      body["ServiceEndDate"] = {
        StartDate: searchParams.datei,
        EndDate: searchParams.dateo,
      };
    }
    searchParams.provider = "CALDEA";

    const requestRes = await axios.get(`${url}/doblementev2/booking-list`, { params: searchParams });
    if (requestRes.status === 200 && requestRes.data !== undefined && requestRes.data !== null) {
      responseRet = { ok: true, results: requestRes.data };
    }
  } catch (error) {
    responseRet = { ok: false, results: [] };
  }

  return responseRet;
};
