/* eslint-disable no-use-before-define */
import React from "react";
import { StyDivDay } from "./CalendarDay.styles";
import { StyDivContainerLine, StyDivContainerLeftSide, StyDivContainerRightSide } from "./CalendarRow.styles";

function CalendarRowNumberedDays({ daysOfMonthArray }) {
  const weekDaysChar = ["D", "L", "M", "X", "J", "V", "S"];

  const today = new Date();

  return (
    <StyDivContainerLine>
      <StyDivContainerLeftSide>
        <></>
      </StyDivContainerLeftSide>
      <StyDivContainerRightSide>
        <>
          {/* Block 2 */}
          {daysOfMonthArray.map((day, index) => (
            <StyDivDay
              key={`day${index}`}
              calendarLength={daysOfMonthArray.length}
              backgroundColor={[0, 6].includes(day.weekDay) ? "lightgray" : "white"}
              isInThePast={today > new Date(day.date)}
            >
              <b>{index + 1}</b>
              <span style={{ fontSize: "9px" }}>{weekDaysChar[day.weekDay]}</span>
            </StyDivDay>
          ))}
        </>
      </StyDivContainerRightSide>
    </StyDivContainerLine>
  );
}

export default CalendarRowNumberedDays;
