import React, { useState } from "react";
// import PropTypes from "prop-types";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import CircularProgress from "@material-ui/core/CircularProgress";

function ButtonsCsv({
  itemsName,
  wordcontrol,
  filename,
  csvFunction,
  head,
  specs,
  uploadFunction,
  onUploadEnded,
}) {
  const [loading, setLoading] = useState(false);

  async function onResultReady(resultList) {
    if (confirm(`Se van a subir ${resultList.length} ${itemsName}. ¿Continuar?`)) {
      setLoading(true);
      const uploadResult = await uploadFunction(resultList);
      setLoading(false);

      onUploadEnded({ total: resultList.length, ...uploadResult });
    }
  }
  return (
    <div style={{display:"flex",  gap:"10px"}}>
      {loading ? (
        <>
          <CircularProgress color="primary" /> Cargando CSV...
        </>
      ) : (
        <>
          <ButtonUploadCSV
            wordcontrol={wordcontrol}
            specs={specs}
            ignoreWordcontrol={false}
            buttonLabel={`Subir`}
            onResultReady={onResultReady}
          />
          <ButtonDownloadCSV
            wordcontrol={wordcontrol}
            head={head}
            specs={specs}
            filename={filename}
            buttonLabel={`Descargar`}
            generateTable={csvFunction}
          />
        </>
      )}
    </div>
  );
}

ButtonsCsv.propTypes = {};

export default ButtonsCsv;
