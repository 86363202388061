import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { FiTrash2 } from "react-icons/fi";

function ButtonDelete({ size, variant, disabled, onClick, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="delete"
      icon={<FiTrash2 />}
      customcolor="blue"
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonDelete;
