import { useState } from "react";
import TaskQueue from "@lib/queues/TaskQueue";

const taskQueue = new TaskQueue();
const useCalendarDayClick = ({ executeOnFinish }) => {
  const [running, setRunning] = useState(false);
  const [leftTasks, setLeftTasks] = useState(0);

  taskQueue.onComplete(() => {
    setRunning(false);
    executeOnFinish();
  });
  taskQueue.onCompleteTasks((numberOfTasksLeftToComplete) => {
    setLeftTasks(numberOfTasksLeftToComplete);
  });

  const addTask = async (newFunc) => {
    setRunning(true);
    taskQueue.add(newFunc);
  };

  return { running, leftTasks, addTask };
};

export default useCalendarDayClick;
