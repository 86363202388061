import React from "react";
import { MdOutlineDragHandle, MdOutlineCopyAll, MdOutlineDelete } from "react-icons/md";
import useNewsletterActions from "../hooks/useNewsletterActions";
import { userAuthorized } from "../../../../../entries/fenix/auth/FenixAuthContext";
import { MdEdit } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";

const NewsletterThirdPartyListActions = ({
  setNewsletterToManage,
  updateNewsletterList,
  createNewsletter,
  deleteNewsletter,
  getNewsletterPreview,
}) => {
  const user = userAuthorized();
  const {
    manageNewsletterAction,
    duplicateNewsletterAction,
    deleteNewsletterAction,
    previewNewsletterAction,
  } = useNewsletterActions({
    user,
    setNewsletterToManage,
    createNewsletter,
    deleteNewsletter,
    getNewsletterPreview,
  });

  const managementAction = {
    code: "management",
    name: "Editar",
    icon: <MdEdit />,
    func: manageNewsletterAction,
  };
  const previewAction = {
    code: "preview",
    name: "Previsualizar",
    icon: <AiOutlineEye />,
    func: previewNewsletterAction,
  };
  const duplicateAction = {
    code: "duplicate",
    name: "Duplicar",
    icon: <MdOutlineCopyAll />,
    func: duplicateNewsletterAction(updateNewsletterList),
  };
  const deleteAction = {
    code: "delete",
    name: "Eliminar",
    icon: <MdOutlineDelete />,
    func: deleteNewsletterAction(updateNewsletterList),
  };

  const newsletterActions = [managementAction, previewAction, duplicateAction, deleteAction];

  return {
    newsletterActions,
  };
};

export default NewsletterThirdPartyListActions;
