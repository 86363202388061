import React, { useState } from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import SectionTitle from "@atoms/Titles/SectionTitle";
import SupplierBlockList from "../components/SupplierBlockList/SupplierBlockList";
import PaymentsToSuppliersHOC from "../components/PaymentsToSuppliersHOC";
import RemittanceView from "../components/RemittanceView/RemittanceView";
import RemittanceButton from "../components/RemittanceButton";

const PaymentsToSuppliersPage = () => {
  const [open, setOpen] = useState(false);

  return (
    <PaymentsToSuppliersHOC>
      <ContainerFenix>
        <div id="tr-outer-container">
          <div id="tr-page-wrap">
            <SectionTitle
              title={"💸 Pagos a suppliers"}
              buttons={[<RemittanceButton key={"bt1"} onClick={() => setOpen(true)} />]}
              info={["Añade facturas de suppliers para crear una nueva remesa."]}
            />

            <div style={{ padding: "10px 0" }}>
              <h4>Suppliers con pagos pendientes</h4>
              <SupplierBlockList />
            </div>

            <RemittanceView open={open} onClose={() => setOpen(!open)} />
          </div>
        </div>
      </ContainerFenix>
    </PaymentsToSuppliersHOC>
  );
};

export default PaymentsToSuppliersPage;
