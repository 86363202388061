import React, { useState, useEffect } from "react";
import axios from "axios";

import { useAffiliatesList, useDeleteAffiliate } from "../../shared/hooks/useAffiliatesFetch";
import AffiliatesListActions from "./AffiliatesList.actions";
import MarketChip from "@atoms/Content/MarketChip";

const AffiliatesListState = () => {
  const MAX_Affiliates_VIEW = 10;
  const [affiliatesList, setAffiliatesList] = useState({
    loadingList: true,
    list: [],
    infoList: { total: 0 },
    pages: 1,
  });

  const setAffiliateToManage = (id) => {
    window.location = `/app/marketing/affiliate/affiliates/${id}`;
  };

  const updateAffiliatesList = async () => {
    setAffiliatesList({
      loadingList: true,
      list: [],
      infoList: { total: 0 },
      pages: 1,
    });
    const { affiliatesData } = await useAffiliatesList();
    setAffiliatesList({
      loadingList: affiliatesData.loadingList,
      list: affiliatesData.list,
      infoList: {
        total: affiliatesData.list.length ?? 0,
      },
      pages: affiliatesData.list.length / MAX_Affiliates_VIEW,
    });
  };

  const deleteAffiliate = async (affiliateId) => {
    try {
      await useDeleteAffiliate(affiliateId);
      await updateAffiliatesList();
    } catch (error) {
      console.log(error);
      alert("Error al borrar el afiliado.");
    }
  };

  const { affiliateActions } = AffiliatesListActions({
    setAffiliateToManage,
    updateAffiliatesList,
    deleteAffiliate,
  });

  useEffect(() => {
    async function loadData() {
      await updateAffiliatesList();
    }
    loadData();
  }, []);

  const tableHead = [
    { code: "id", name: "Id", type: "string" },
    {
      code: "type",
      name: "Tipo",
      type: "translation",
      translation: {
        whitelabel: "Marca blanca",
        referal: "Referido",
      },
    },
    {
      code: "custom",
      name: "Dominio (Marca blanca)",
      type: "customFromRow",
      customFromRow: (rowData) => {
        const affiliate = rowData.rowData;
        if (affiliate.type === "whitelabel") {
          return (
            <div>
              <a target="_blank" href={"https://" + affiliate.whitelabelConf.domain}>
                {affiliate.whitelabelConf.domain}
              </a>
              {" - "}
              <MarketChip market={affiliate.whitelabelConf.defLang} />
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
    },
    { code: "enable", name: "Activo", type: "boolean" },
  ];

  const goToAdd = () => {
    window.location = "/app/marketing/affiliate/affiliates/_new";
  };

  return {
    affiliatesList,
    tableHead,
    affiliateActions,
    MAX_Affiliates_VIEW,
    goToAdd,
  };
};

export default AffiliatesListState;
