import React, { useState, useEffect } from "react";
import CustomModal from "@molecules/Modal/CustomModal";
import { FiCheckCircle, FiCheck, FiCornerDownRight } from "react-icons/fi";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import FxAlert from "@atoms/Alerts/FxAlert";
import InputText from "@atoms/Inputs/InputText";
import ButtonSend from "@atoms/Buttons/ButtonSend";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonCheck from "@atoms/Buttons/ButtonCheck";
import { userAuthorized } from "@src/entries/fenix/auth/FenixAuthContext";
import BankAccountApi from "../../api/BankAccountApi";
import styles from "./BankAccountValidationModal.module.css";
import ValidationInstructions from "./ValidationInstructions";
import BankAccountSummary from "./BankAccountSummary";

const BankAccountValidationModal = ({ open, onClose, bankAccount }) => {
  const { profile } = userAuthorized();
  const userValidator = profile.email;

  const [onErrorStartValidation, setOnErrorStartValidation] = useState(false);
  const [onErrorContinueValidation, setOnErrorContinueValidation] = useState(false);
  const [onErrorSignValidation, setOnErrorSignValidation] = useState(false);

  const [validationStarted, setValidationStarted] = useState(false);
  const [validationContinue, setValidationContinue] = useState(false);
  const [validationSign, setValidationSign] = useState(false);

  const [loadingStart, setLoadingStart] = useState(false);
  const [loadingContinue, setLoadingContinue] = useState(false);
  const [loadingSign, setLoadingSign] = useState(false);

  const [emailCode, setEmailCode] = useState("");
  const [smsCode, setSMSCode] = useState("");
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    setShowResend(false);
    const timer = setTimeout(() => setShowResend(true), 10000);
    return () => clearTimeout(timer);
  }, [loadingContinue]);

  if (!bankAccount) {
    return <></>;
  }

  const { id, supplier } = bankAccount;

  function onCloseModal() {
    onClose();
    setOnErrorStartValidation(false);
    setOnErrorContinueValidation(false);
    setOnErrorSignValidation(false);
    setValidationStarted(false);
    setValidationContinue(false);
    setValidationSign(false);
    setLoadingStart(false);
    setLoadingContinue(false);
    setEmailCode("");
    setSMSCode("");
  }

  async function startValidationSupplierBankAccount() {
    setLoadingStart(true);
    try {
      setValidationStarted(true);
      await BankAccountApi.startValidationSupplierBankAccount(id, userValidator);
    } catch (error) {
      console.log(error);
      setOnErrorStartValidation(true);
    }
    setLoadingStart(false);
  }

  function onChangeEmailCode({ value, valid }) {
    setEmailCode(value);
  }

  function validationEmailCode(value) {
    return true; // value.length === ;
  }

  async function continueValidationSupplierBankAccount() {
    setLoadingContinue(true);
    try {
      setValidationContinue(true);
      await BankAccountApi.continueValidationSupplerBankAccount(id, userValidator);
    } catch (error) {
      console.log(error);
      setOnErrorContinueValidation(true);
    }
    setLoadingContinue(false);
  }

  function onChangeSMSCode({ value, valid }) {
    setSMSCode(value);
  }

  function validationSMSCode(value) {
    return true; // value.length === ;
  }

  async function signValidationSupplierBankAccount() {
    setLoadingSign(true);
    try {
      setValidationSign(true);
      await BankAccountApi.signValidationSupplierBankAccount(id, userValidator, {
        email: emailCode,
        sms: smsCode,
      });
    } catch (error) {
      console.log(error);
      setOnErrorSignValidation(true);
    }
    setLoadingSign(false);
  }

  function finishValidation() {
    window.location.reload();
  }

  const btSignActive = emailCode.length > 0 && smsCode.length > 0;

  return (
    <CustomModal
      title={
        <>
          <FiCheckCircle size={30} color={"green"} />
          {` Confirmación de cuenta bancaria de ${supplier}`}
        </>
      }
      open={open}
      handleClose={onCloseModal}
      bigModal
      closeOnClickOutside={false}
      customWidthModal={1000}
    >
      {/*
        Quedaría:
        Paso 1  - El usuario acepta iniciar la validación ("Vas a validar datos, blablabna)? Aceptas?")
        Paso 2 - Desde Fenix se manda la peticion del email y se muestran dos input para validar los dos codigos
        Paso 3 - El usuario decide cuando se le envía el sms con el segundo código
        Paso 4 - Se envia una validacion de los dos codigos

        En vez de mostrar directamente los 2 input creo que deberiamos:
        Sacar un mensaje resumen con la info codificada y un: Oye te hemos enviado un email con lo que vas a validar aquí. Asegurate que está bien, etc.  Y un poco explicar el proceso

        LUego input código email
        Luego botón (no input) con "Enviar SMS con código sms", al darle hace la petición y aparece el input del sms (seguramente al cabo de unos segundos haría aparecer un enlace de "volver a enviar")
        Al enviar el sms tb aparece un botón de "Enviar codigos"
              
      */}

      <ValidationInstructions />
      <br />
      <BankAccountSummary bankAccount={bankAccount} />

      {!validationStarted && (
        <ButtonCustom
          color={"secondary"}
          icon={<FiCornerDownRight />}
          onClick={startValidationSupplierBankAccount}
        >
          Iniciar proceso de validacion
        </ButtonCustom>
      )}

      {validationStarted && (
        <div>
          {onErrorStartValidation ? (
            <FxAlert variant={"error"}>
              {
                "Ha ocurrido un error al inciar el proceso de validación. Posiblemente se deba a un error de comunicación. Intentalo de nuevo en unos minutos."
              }
            </FxAlert>
          ) : (
            <>
              {!validationSign && (
                <>
                  <div className={styles.InputBlock}>
                    <div className={styles.InputBlockSide}>
                      <div className={styles.InputBlockTitle}>
                        <b>Validacion por email</b>
                        {loadingStart ? (
                          <span className={styles.ProgressLoading}>
                            <CircularProgress size={15} className={styles.ProgressIcon} />
                            {"Enviando email..."}
                          </span>
                        ) : (
                          <span className={styles.ProgressSuccess}>
                            <FiCheck size={15} className={styles.ProgressIcon} />
                            {"Email enviado"}
                          </span>
                        )}
                      </div>
                      <InputText
                        title={"Codigo recibido por email"}
                        value={emailCode}
                        onChange={onChangeEmailCode}
                        validation={validationEmailCode}
                        disabled={loadingStart}
                      />
                    </div>
                    <div className={styles.InputBlockSide}>
                      <div className={styles.InputBlockTitle}>
                        <b>Validacion por SMS</b>
                        {validationContinue && !onErrorContinueValidation && (
                          <>
                            {loadingContinue ? (
                              <span className={styles.ProgressLoading}>
                                <CircularProgress size={15} className={styles.ProgressIcon} />
                                {"Enviando SMS..."}
                              </span>
                            ) : (
                              <span className={styles.ProgressSuccess}>
                                <FiCheck size={15} className={styles.ProgressIcon} />
                                {"SMS enviado"}
                              </span>
                            )}
                          </>
                        )}
                      </div>

                      {!validationContinue && (
                        <ButtonSend customcolor={"green"} onClick={continueValidationSupplierBankAccount}>
                          {"Enviar SMS"}
                        </ButtonSend>
                      )}

                      {onErrorContinueValidation && (
                        <FxAlert variant={"error"}>
                          {
                            "Ha ocurrido un error al enviar el SMS. Posiblemente sea debido a un error de comunicacion en nuestro sistema. Por favor, intentalo de nuevo en unos minutos."
                          }
                        </FxAlert>
                      )}

                      {!onErrorContinueValidation && validationContinue && (
                        <>
                          <InputText
                            title={"Codigo recibido por SMS"}
                            value={smsCode}
                            onChange={onChangeSMSCode}
                            validation={validationSMSCode}
                            disabled={loadingContinue}
                          />
                          {showResend && (
                            <span
                              onClick={continueValidationSupplierBankAccount}
                              className={styles.ReSendLink}
                            >
                              {"Volver a enviarlo"}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <ButtonSend
                      customcolor={"green"}
                      disabled={!btSignActive}
                      onClick={signValidationSupplierBankAccount}
                    >
                      {btSignActive ? "Enviar validacion" : "Esperando codigos..."}
                    </ButtonSend>
                  </div>
                </>
              )}

              {loadingSign ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress size={40} />
                  <p>{"Estamos validando los codigos. Espera un momento."}</p>
                </div>
              ) : (
                <>
                  {validationSign && (
                    <>
                      {onErrorSignValidation ? (
                        <FxAlert variant={"error"}>{"Ha ocurrido un error al firmar la validacion."}</FxAlert>
                      ) : (
                        <FxAlert variant={"success"}>
                          <>
                            {"Validacion firmada correctamente"}
                            <br />
                            <ButtonCheck onClick={finishValidation}>{"Entendido"}</ButtonCheck>
                          </>
                        </FxAlert>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </CustomModal>
  );
};

export default BankAccountValidationModal;
