import styled from "styled-components";
import List from "@material-ui/core/List";

export const ListFileDirectory = styled(List)`
  width: "100%";
  max-width: 360;

  background-color: #fff;
`;

export const ListFilesDirectory = styled(List)`
  min-height: 67px;
  background-color: #fff;
`;
