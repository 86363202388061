import React, { Fragment, useState } from "react";
import { Grid } from "@material-ui/core";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import FxAlert from "@atoms/Alerts/FxAlert";

function ConcilliationErrorInterface({ errorList }) {
  const [hideIgnored, setHideIgnored] = useState(true);

  const errors = errorList.filter((err) => !err.ignore);
  const errorsIgnored = errorList.filter((err) => err.ignore);

  return (
    <>
      {errors.map((error, index) => (
        <Fragment key={`error${index}`}>
          <ConcilliationErrorAlert error={error} />
        </Fragment>
      ))}

      {!hideIgnored &&
        errorsIgnored.map((error, index) => (
          <Fragment key={`error${index}`}>
            <ConcilliationErrorAlert error={error} />
          </Fragment>
        ))}

      {errors.length === 0 && hideIgnored && (
        <FxAlert variant={"info"}>
          <b>No se encuentran errores</b>
        </FxAlert>
      )}

      {/* RMR: En la seccion original de fe_fenix2 habia unos botones para ignorar errores concretos,
        pero estaban comentados, asi que no se usaban.
      */}

      {/*  Button Show all  */}
      {errorsIgnored.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <ButtonCustom color="primary" onClick={() => setHideIgnored(!hideIgnored)}>
              {hideIgnored ? "Mostrar" : "Ocultar"} Ignorados
            </ButtonCustom>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const ConcilliationErrorAlert = ({ error }) => {
  const errorText = {
    E0001: "Uno o más locatas no encontrados.",
    E0002: "Status no coincide con alguno de los locatas.",
    E0003: "Checkin no coincide con alguno de los locatas.",
    E0004: "Checkout no coincide con alguno de los locatas.",
    E0005: "Precio no coincide con alguno de los locatas.",
    E0006: "Reserva no encontrada en base de datos.",
  };
  let info = "";
  console.log(error);
  if (error.info.locatas) {
    info = error.info.locatas.join(", ");
  } else {
    if (error.info.locata) {
      if (Array.isArray(error.info.locata)) {
        info += `locatas: ${error.info.locata.join(", ")} `;
      } else {
        info += `locata: ${error.info.locata} `;
      }
    }
    if (error.info.status) {
      info += `status: ${error.info.status} `;
    }
    if (error.info.cost) {
      info += `precio: ${(error.info.cost / 100).toFixed(2)} `;
    }
    if (error.info.date) {
      info += `fecha: ${error.info.date} `;
    }
  }

  return (
    <FxAlert variant={error.ignore ? "disabled" : "error"}>
      <b>{errorText[error.code]}</b> ({error.code})
      <br />
      {error.info.originalValue && (
        <span>
          <b>Valor original</b>: {error.info.originalValue}
          <br />
        </span>
      )}
      <b>Información</b>: <span> {info}</span>
    </FxAlert>
  );
};

ConcilliationErrorInterface.propTypes = {};

export default ConcilliationErrorInterface;
