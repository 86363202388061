import React from "react";
import { Row, Col } from "react-bootstrap";
import FxDatePickersRangesFromTo from "@atoms/Pickers/FxDatePickersRangesFromTo";
import FlashOfferConfiguration from "./OffersEditorComponents/FlashOfferConfiguration/FlashOfferConfiguration";
import Nights from "./OffersEditorComponents/Nights/Nights";
import Occupancy from "./OffersEditorComponents/Occupancy/Occupancy";

function OffersEditorConfiguration({ form, onChangeForm, onChangeOccupancy }) {
  return (
    <>
      <Row>
        <Col xs={7}>
          <FxDatePickersRangesFromTo handle={onChangeForm} form={form} />
        </Col>
        <Col>
          <FlashOfferConfiguration form={form} onChange={onChangeForm} />
        </Col>
      </Row>

      <Nights form={form} onChange={onChangeForm} />
      <Occupancy form={form} onChange={onChangeOccupancy} />
    </>
  );
}

export default OffersEditorConfiguration;
