/* eslint-disable no-use-before-define */
import React, { Fragment, useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Box,
  Chip,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";

function RuleOfProduct({ rule, ruleList, ticketsOfProduct, onChangeThisRule, deleteRule, rulesOfPrecinct }) {
  const [withTickets, setWithTickets] = useState(rule.restrictedToTicketIds !== undefined);

  function changeRule(newValue) {
    const newRule = { ...rule };
    newRule.ruleId = newValue;
    onChangeThisRule(newRule);
  }

  function onChangeCheckbox() {
    setWithTickets(!withTickets);

    if (!withTickets && rule.restrictedToTicketIds === undefined) {
      const newRule = { ...rule };
      newRule.restrictedToTicketIds = [];
      onChangeThisRule(newRule);
    }
  }

  function addNewTicket(newTicketIdArray) {
    const newRule = { ...rule };
    newRule.restrictedToTicketIds = [...newTicketIdArray];
    onChangeThisRule(newRule);
  }

  function deleteTicket(e, value) {
    e.preventDefault();
    const newRule = { ...rule };
    const index = newRule.restrictedToTicketIds.findIndex((t) => t === value);
    newRule.restrictedToTicketIds.splice(index, 1);
    onChangeThisRule(newRule);
  }

  function getDateString() {
    const ruleIndex = rulesOfPrecinct.find((r) => r.id === rule.ruleId);
    if (
      ruleIndex === undefined ||
      ruleIndex.lastExecutionDatetime === undefined ||
      ruleIndex.lastExecutionDatetime === null
    ) {
      return <div style={{ color: "red" }}>Esta regla no ha sido ejecutada</div>;
    }
    const lastExecutionDatetime = new Date(ruleIndex.lastExecutionDatetime);
    return `Ultima fecha de la ejecucion de la regla ${lastExecutionDatetime
      .getDate()
      .toString()
      .padStart(2, 0)}/${(lastExecutionDatetime.getMonth() + 1)
      .toString()
      .padStart(2, 0)}/${lastExecutionDatetime.getFullYear()} a las ${lastExecutionDatetime
      .getHours()
      .toString()
      .padStart(2, 0)}:${lastExecutionDatetime.getMinutes().toString().padStart(2, 0)}`;
  }

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2} justify={"space-between"} direction="row">
          <Grid item xs={10}>
            <RuleSelector value={rule.ruleId} rules={ruleList} onChange={(e) => changeRule(e.target.value)} />
          </Grid>
          <Grid item>
            <ButtonDelete style={{ borderRadius: "100px" }} onClick={deleteRule} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox size="small" checked={withTickets} onChange={onChangeCheckbox} />}
            label="Asignar a tarifas"
          />
        </FormGroup>

        {withTickets && rule.restrictedToTicketIds && (
          <>
            {ticketsOfProduct !== undefined ? (
              <FormControl variant="outlined" sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">Tarifas</InputLabel>
                <Select
                  style={{ backgroundColor: "white" }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={rule.restrictedToTicketIds}
                  onChange={(event) => addNewTicket(event.target.value)}
                  input={<OutlinedInput id="select-multiple-chip" label="Tarifas" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const ticketSelected = ticketsOfProduct.find((t) => value === t.id);
                        if (!ticketSelected) {
                          return null;
                        }
                        return (
                          <Chip
                            key={value}
                            label={ticketSelected.alias || value}
                            clickable
                            onDelete={(e) => deleteTicket(e, value)}
                            deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          />
                        );
                      })}
                    </Box>
                  )}
                >
                  {ticketsOfProduct.map((ticket, indexTicket) => (
                    <MenuItem key={indexTicket} value={ticket.id}>
                      {ticket.alias}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <>{"Este producto aún no tiene tarifas"}</>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        {getDateString()}
      </Grid>
    </>
  );
}

function RuleSelector({ value, rules, onChange }) {
  return (
    <FormControl variant="outlined" style={{ width: "50%!important" }}>
      <InputLabel id="demo-simple-select-label">Regla</InputLabel>
      <Select
        style={{ backgroundColor: "white" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={"Regla"}
        onChange={onChange}
      >
        <MenuItem disabled value={""}>
          Selecciona una regla
        </MenuItem>
        {rules.map((rule) => (
          <MenuItem key={rule.id} value={rule.id}>
            <span>
              {rule.isDefaultRule && <Fragment>{"(Default) "}</Fragment>}
              {rule.name}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default RuleOfProduct;
