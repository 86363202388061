/* eslint-disable no-use-before-define */
/* eslint-disable no-await-in-loop */
// Giving and array of the format:
// [{precinctCode, productCode?, ticketId?}]
// ... return a list of the items that do not exist in database
import { fetchPrecinct } from "../../Precincts/usePrecincts";
import { fetchProduct } from "../../Products/useProducts";
import { fetchTicket } from "../../Tickets/useTickets";
import { getTypeOfCsvRow } from "../utils";

async function checkExistence(listOfItems) {
  const alreadyRead = {};

  const doNotExist = [];
  for (let index = 0; index < listOfItems.length; index += 1) {
    const item = listOfItems[index];
    const { type } = getTypeOfCsvRow(item);

    // console.log(type, item[code]);

    try {
      const precinctId = getAlreadyReadID("precinct", item.precinctCode);
      try {
        // Precinct is always present
        await checkPrecinct(item.precinctCode, alreadyRead);
        if (alreadyRead[precinctId] === undefined) {
          alreadyRead[precinctId] = true;
        }
      } catch (e) {
        alreadyRead[precinctId] = false;
        throw e;
      }

      if (type === "product" || type === "ticket") {
        const productId = getAlreadyReadID("product", item.productCode);
        try {
          await checkProduct(item.productCode, alreadyRead);
          if (alreadyRead[productId] === undefined) {
            alreadyRead[productId] = true;
          }
        } catch (e) {
          alreadyRead[productId] = false;
          throw e;
        }
      }

      if (type === "ticket") {
        const ticketKeyId = getAlreadyReadID("ticket", item.ticketId);
        try {
          await checkTicket(item.ticketId, alreadyRead);
          if (alreadyRead[ticketKeyId] === undefined) {
            alreadyRead[ticketKeyId] = true;
          }
        } catch (e) {
          alreadyRead[ticketKeyId] = false;
          throw e;
        }
      }
    } catch (finalError) {
      // console.error(finalError.message);
      doNotExist.push([type, item.precinctCode, item.productCode, item.ticketId].join(" > "));
    }
  }

  // console.log("alreadyRead", alreadyRead);
  // console.log("doNotExist", doNotExist);

  return doNotExist;
}

async function checkPrecinct(precinctCode, alreadyRead) {
  const key = getAlreadyReadID("precinct", precinctCode);
  if (alreadyRead[key] !== undefined) {
    if (alreadyRead[key]) {
      return;
    }
    throw new Error("Precinct alreadyRead Not Found");
  }

  const precinct = await fetchPrecinct(precinctCode);
  if (Object.keys(precinct).length === 0) {
    throw new Error("Precinct Not Found");
  }
}
async function checkProduct(productCode, alreadyRead) {
  const key = getAlreadyReadID("product", productCode);
  if (alreadyRead[key] !== undefined) {
    if (alreadyRead[key]) {
      return;
    }
    throw new Error("Product alreadyRead Not Found");
  }

  const product = await fetchProduct(productCode);
  if (Object.keys(product).length === 0) {
    throw new Error("Product Not Found");
  }
}
async function checkTicket(ticketId, alreadyRead) {
  const key = getAlreadyReadID("ticket", ticketId);
  if (alreadyRead[key] !== undefined) {
    if (alreadyRead[key]) {
      return;
    }
    throw new Error("Ticket alreadyRead Not Found");
  }

  const ticket = await fetchTicket(ticketId);
  if (Object.keys(ticket).length === 0) {
    throw new Error("Ticket Not Found");
  }
}

function getAlreadyReadID(type, identificator) {
  return [type, identificator].join("_");
}

export default checkExistence;
