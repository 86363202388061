import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/es";
import CssBaseline from "@material-ui/core/CssBaseline";
import { FenixUiContextProvider } from "./FenixUiContext";


const UiProvider = ({ children }) => {
  const theme = createMuiTheme({
    palette: {
     
      primary: {
        light: "#fff",
        main: "#ffae17",
        dark: "#FF8F18",
        contrastText: "#fff",
      },
      secondary: { light: "#c9f7f5", main: "#1bc5bd", dark: "#06a29b", contrastText: "#fff" },
      success: {
        light: "#fff",
        main: "#1bc5bd",
        dark: "#179e98",
        contrastText: "#fff",
      },
    },
    typography: {
      useNextVariants: true,
      body1: {
        fontSize: 13,
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },
      body2: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <FenixUiContextProvider>{children}</FenixUiContextProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default UiProvider;

/* 
myAwesomeColor: palette.augmentColor({ color: {
  main: "tomato",
} }), */