import React, { useContext } from "react";

const initialState = {
  translations: {},
};

export const i18TraventiaContext =
  React.createContext(initialState);

export function I18TraventiaContextProvider({ props, children }) {
  return <i18TraventiaContext.Provider value={{ state: props }}>{children}</i18TraventiaContext.Provider>;
}

export function useTranslation(ns) {
  const { state } = useContext(i18TraventiaContext);
  return {
    t: (key) => state?.translations[ns]?.[key] || key,
  };
}
