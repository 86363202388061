import React, { Fragment } from "react";
import InputQueryDropdown from "@atoms/Inputs/InputQueryDropdown";
import InputQueryDropdownForTagSearchers from "../InputQueryDropdownForTagSearchers/InputQueryDropdownForTagSearchers";
import { Card, Row, Col } from "react-bootstrap";
import { AlertNoTags, AlertTagsBorder, UsChipTag } from "./SearcherOfferTags.styles";
import { UsChipEmail, ChipLabel, ChipDelete } from "@atoms/Content/Chips";
import { ContainerFenix, UsCardTitle } from "@atoms/Card/UsCard.styles";

function SearcherTags({ onChangeOffersTags, tags, market }) {
  const addValueTagList = (nuTag, type) => {
    const nuTags = { ...tags };
    nuTags[type].push(nuTag);
    onChangeOffersTags(nuTags);
  };

  const removeValueTagList = (key, index) => {
    const nuTags = { ...tags };
    nuTags[key].splice(index, 1);
    onChangeOffersTags(nuTags);
  };

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <UsCardTitle>
            <span>Etiquetas</span>
          </UsCardTitle>
          <br />
          <Row>
            <Col>
              <InputQueryDropdownForTagSearchers
                label={"Buscador de Temas (Topic)"}
                market={market}
                type={"topic"}
                optionFormat={(option) => {
                  return (
                    <Fragment>
                      <b>{option}</b>
                    </Fragment>
                  );
                }}
                onSelectOption={(nuTag) => {
                  addValueTagList(nuTag, "topic");
                }}
              />
            </Col>
            <Col>
              <InputQueryDropdownForTagSearchers
                label={"Buscador de Fechas (Dates)"}
                market={market}
                type={"dates"}
                optionFormat={(option) => {
                  return (
                    <Fragment>
                      <b>{option}</b>
                    </Fragment>
                  );
                }}
                onSelectOption={(nuTag) => {
                  addValueTagList(nuTag, "dates");
                }}
              />
            </Col>
            <Col>
              <InputQueryDropdownForTagSearchers
                label={"Buscador de Destino (Location)"}
                market={market}
                type={"location"}
                optionFormat={(option) => {
                  return (
                    <Fragment>
                      <b>{option}</b>
                    </Fragment>
                  );
                }}
                onSelectOption={(nuTag) => {
                  addValueTagList(nuTag, "location");
                }}
              />
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs={4}>
              <h6>Temas seleccionadas</h6>
              <AlertTagsBorder variant="info">
                {tags.topic.length === 0 && "Ningun tema añadido"}
                <Row>
                  {tags.topic.map((item, index) => {
                    return (
                      <UsChipEmail key={index}>
                        <ChipLabel>{item}</ChipLabel>
                        <ChipDelete
                          onClick={() => {
                            removeValueTagList("topic", index);
                          }}
                        />
                      </UsChipEmail>
                    );
                  })}
                </Row>
              </AlertTagsBorder>
            </Col>
            <Col xs={4}>
              <h6>Fechas seleccionadas</h6>
              <AlertTagsBorder variant="info">
                {tags.dates.length === 0 && "Ninguna fecha añadida"}
                <Row>
                  {tags.dates.map((item, index) => {
                    return (
                      <UsChipEmail key={index}>
                        <ChipLabel>{item}</ChipLabel>
                        <ChipDelete
                          onClick={() => {
                            removeValueTagList("dates", index);
                          }}
                        />
                      </UsChipEmail>
                    );
                  })}
                </Row>
              </AlertTagsBorder>
            </Col>

            <Col xs={4}>
              <h6>Destinos seleccionadas</h6>
              <AlertTagsBorder variant="info">
                {tags.location.length === 0 && "Ningun destino añadida"}
                <Row>
                  {tags.location.map((item, index) => {
                    return (
                      <UsChipEmail key={index}>
                        <ChipLabel>{item}</ChipLabel>
                        <ChipDelete
                          onClick={() => {
                            removeValueTagList("location", index);
                          }}
                        />
                      </UsChipEmail>
                    );
                  })}
                </Row>
              </AlertTagsBorder>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default SearcherTags;
