import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import InputCode from "@atoms/Inputs/InputCode";
import InputText from "@atoms/Inputs/InputText";
import CityTaxEditorRequirements from "./CityTaxEditorRequirements";
import CityTaxEditorRules from "./CityTaxEditorRules";
import { getSingleCityTaxTemplate, getCityTaxParameters, postCityTaxTemplate } from "./useCityTax";

function CityTaxEditor({ cityTaxCode }) {
  const history = useHistory();
  const [loadingEditor, setLoadingEditor] = useState(true);
  const [ctTemplate, setCtTemplate] = useState({
    code: "",
    name: "",
    enable: false,
    rules: [],
    selector: {
      hcodes: { inclusives: [], exclusives: [] },
      hzones: { inclusives: [], exclusives: [] },
    },
  });
  const [cityTaxParameters, setCityTaxParameters] = useState([]);

  async function getCityTaxTemplate() {
    setLoadingEditor(true);
    const t = await getSingleCityTaxTemplate(cityTaxCode);
    const cityTaxParameters = await getCityTaxParameters();
    setCtTemplate(t);
    setCityTaxParameters(cityTaxParameters);
    setLoadingEditor(false);
  }

  useEffect(() => {
    if (cityTaxCode !== "NEW") {
      getCityTaxTemplate();
    } else {
      setLoadingEditor(false);
    }
    return () => {};
  }, []);

  const backToTable = () => {
    history.push("/app/product/accommodations/citytax");
  };

  const checkTemplateIsValid = () => {
    let errors = [];

    if (ctTemplate.code === "") {
      errors.push("Codigo esta vacio!");
    }

    if (
      ctTemplate.selector.hcodes.inclusives.length === 0 &&
      ctTemplate.selector.hcodes.exclusives.length === 0 &&
      ctTemplate.selector.hzones.inclusives.length === 0 &&
      ctTemplate.selector.hzones.exclusives.length === 0
    ) {
      errors.push("No has incluido ninguna restriccion de zonas u hoteles");
    }

    if (ctTemplate.rules.length === 0) {
      errors.push("No has incluido ninguna regla.");
    }

    const f = ctTemplate.rules.filter((r) => r.alternatives.length === 0);
    if (f.length > 0) {
      errors.push("Alguna regla no tiene alternativas.");
    }

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return false;
    }

    return true;
  };

  const saveCityTaxTemplate = async () => {
    setLoadingEditor(true);

    if (checkTemplateIsValid()) {
      try {
        await postCityTaxTemplate(ctTemplate);
        backToTable();
      } catch (e) {
        alert(e);
      }
    }

    setLoadingEditor(false);
  };

  const onChangeCode = ({ value, valid }) => {
    if (valid) {
      setCtTemplate({ ...ctTemplate, code: value });
    }
  };

  const onChangeName = ({ value, valid }) => {
    if (valid) {
      setCtTemplate({ ...ctTemplate, name: value });
    }
  };

  return (
    <>
      <SectionTitle
        title={"Editor de plantilla de City tax"}
        buttons={[<ButtonSave onClick={saveCityTaxTemplate}>{"Guardar plantilla"}</ButtonSave>]}
        backFunction={backToTable}
      />

      {loadingEditor ? (
        <>
          <Skeleton variant="rect" height={100} animation="wave" style={{ marginBottom: "20px" }} />
          <Skeleton variant="rect" height={300} animation="wave" />
        </>
      ) : (
        <>
          <TravCard>
            <TravBodyCard>
              <Grid container>
                <Grid item xs={6}>
                  <InputCode
                    title={"Codigo"}
                    value={ctTemplate.code}
                    helperText={`Escribe un codigo único para este elemento. 
                      La casilla únicamente te permitirá escribir letras básicas y numeros.`}
                    disabled={cityTaxCode !== "NEW"}
                    onChange={onChangeCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputText
                    title={"Nombre identificativo"}
                    value={ctTemplate.name}
                    onChange={onChangeName}
                  />
                </Grid>
              </Grid>
            </TravBodyCard>
          </TravCard>
          <CityTaxEditorRequirements ctTemplate={ctTemplate} onChange={setCtTemplate} />
          <CityTaxEditorRules
            ctTemplate={ctTemplate}
            cityTaxParameters={cityTaxParameters}
            onChange={setCtTemplate}
          />
        </>
      )}
    </>
  );
}

CityTaxEditor.propTypes = {};

export default CityTaxEditor;
