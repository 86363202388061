import axios from "axios";

const URL = "/api/v1/product-bc/files-manager";

export const getTagsOfBucket = (bucket, tagRgx) => {
  let thestateFinal = { loading: true, data: [] };

  const fetchData = async () => {
    let thestate = { loading: true, data: [] };
    try {
      const response = await axios.get(`${URL}/${bucket}/tags?idTag[regex]=${tagRgx}`);
      if (response.status === 200) {
        thestate = { loading: false, data: response.data.data };
      }
    } catch (error) {
      thestate = { loading: false, data: [] };
      console.error(error);
    }
    return thestate;
  };

  if (thestateFinal.loading) {
    thestateFinal = fetchData();
  }
  return thestateFinal;
};

export const addTagToFile = async (path, bucket, tag) => {
  let thestate = { loading: true, ok: false };
  try {
    const response = await axios.put(`${URL}/${bucket}/files/${encodeURIComponent(path)}/tags/${tag}`, {});
    // console.log(`Añado ${tag} a ${path} ${bucket}`);
    if (response.status === 201) {
      thestate = { loading: false, ok: true };
    }
    thestate = { ...thestate };
  } catch (error) {
    thestate = { loading: false, ok: false };
    // console.error(error);
  }
  return thestate;
};

export const deleteTagFromFile = async (path, bucket, tag) => {
  let thestate = { loading: true, ok: false };
  try {
    const response = await axios.delete(`${URL}/${bucket}/files/${encodeURIComponent(path)}/tags/${tag}`, {});
    // console.log(`Borro ${tag} de ${path} ${bucket}`);
    if (response.status === 204) {
      thestate = { loading: false, ok: true };
    }
    thestate = { ...thestate };
  } catch (error) {
    thestate = { loading: false, ok: false };
    // console.error(error);
  }
  return thestate;
};
