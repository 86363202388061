/* eslint-disable no-use-before-define */
import { useState, useEffect, useCallback } from "react";
import { getDatesBetweenTwo } from "@lib/helpers/dates";
import { fetchProducts } from "../../Products/useProducts";
import { fetchTickets } from "../../Tickets/useTickets";
import getViewCalendar from "../shared/calendarForView";
import { fetchSessions } from "../../Sessions/useSessions";
import { getChannelAsQueryParms } from "../utils";
import {
  fetchPrecinctCalendar,
  fetchProductCalendarWithPrecinctCode,
  fetchTicketCalendarWithPrecinctCode,
  fetchSessionCalendarWithPrecinctCode,
} from "../shared/daoCalendars";

const DEFAULT_PACKAGE_MARKUP = 10;

export const useCalendar = ({ precinctCode, channel, year }) => {
  const [loadingCalendar, setLoadingCalendar] = useState(true);
  const [calendars, setCalendars] = useState();
  const [precinctChildren, setPrecinctChildren] = useState({ products: [], tickets: [] });

  const fetchCalendar = useCallback(
    async (initLoading = true) => {
      setLoadingCalendar(initLoading);
      try {
        const { calendarsFull, products, tickets } = await fetchFullCalendar(precinctCode, channel, year);
        setCalendars(calendarsFull);
        setPrecinctChildren({ products, tickets });
        setLoadingCalendar(false);
      } catch (e) {
        alert(e.message);
        console.error(e);
        // setLoadingCalendar(false);
      }
    },
    [channel, precinctCode, year]
  );

  useEffect(() => {
    if (channel) {
      fetchCalendar();
    }
  }, [channel, year, fetchCalendar]);

  const calendar = calendars !== undefined ? getViewCalendar(calendars) : {};

  const completeProducts = getCompleteProductsObject(precinctChildren);
  return {
    loadingCalendar,
    calendar,
    products: completeProducts,
    fetchCalendar,
    tickets: getTicketObjectsByProductCode(precinctChildren.tickets),
  };
};

export const fetchFullCalendar = async (precinctCode, channel, year) => {
  const [
    products,
    tickets,
    sessions,
    precinctCalendarR,
    { data: productCalendarsR },
    { data: ticketCalendarsR },
    { data: sessionCalendarsR },
  ] = await Promise.all([
    fetchProducts({ params: { precinctCode } }),
    fetchTickets({ params: { precinctCode, ...getChannelAsQueryParms(channel, "channels.channel") } }),
    fetchSessions({ params: { precinctCode, ...getChannelAsQueryParms(channel, "channels.channel") } }),
    fetchPrecinctCalendar(precinctCode, year),
    fetchProductCalendarWithPrecinctCode(precinctCode, channel, year),
    fetchTicketCalendarWithPrecinctCode(precinctCode, channel, year),
    fetchSessionCalendarWithPrecinctCode(precinctCode, channel, year),
  ]);

  // Complete the calendar results with all the products & tickets available
  const dayListOfYear = getDatesBetweenTwo(`${year}-01-01`, `${year}-12-31`);
  const precinctCalendar = completeCalendarMissingDays(
    { precinctCode, ...precinctCalendarR },
    dayListOfYear,
    "precinct"
  );
  const productCalendars = products.map((product) => {
    const { code: productCode, packageMarkup } = product;
    let newCalendar = { precinctCode, productCode, channel, year, calendar: [] };
    const calendar = productCalendarsR.find((c) => c.productCode === productCode);
    if (calendar !== undefined) {
      newCalendar = { ...calendar };
    }

    const pcalendar = completeCalendarMissingDays(newCalendar, dayListOfYear, "product");

    const productPackageMarkup = packageMarkup !== undefined ? packageMarkup : DEFAULT_PACKAGE_MARKUP;
    pcalendar.calendar = addParentFieldToCalendar(pcalendar, { packageMarkup: productPackageMarkup });

    return pcalendar;
  });
  const ticketCalendars = tickets.map((ticket) => {
    const { id: ticketId, productCode, alias, priceModel, quotaEnable, channels } = ticket;

    // Get if commission is active
    let priceCommissionable = false; // ?
    const channelConf = channels.find(
      (ch) =>
        [ch.channel.provider, ch.channel.merchant].join("|") ===
        [channel.provider, channel.merchant].join("|")
    );
    if (channelConf !== undefined) {
      priceCommissionable =
        channelConf.priceCommissionable !== undefined ? channelConf.priceCommissionable : false;
    }

    let newCalendar = { ticketId, precinctCode, productCode, channel, year, calendar: [] };
    const calendarEntry = ticketCalendarsR.find((c) => c.ticketId === ticketId);
    if (calendarEntry !== undefined) {
      newCalendar = { ...calendarEntry };
    }

    const tcalendar = completeCalendarMissingDays(
      { ...newCalendar, alias, priceModel, quotaEnable, priceCommissionable },
      dayListOfYear,
      "ticket"
    );
    tcalendar.calendar = addParentMarkupToCalendar(tcalendar.calendar, ticket, products, productCalendars);

    return tcalendar;
  });
  const sessionsCalendars = sessions.map((session) => {
    const { id: sessionId, productCode } = session;
    let newCalendar = { sessionId, precinctCode, productCode, channel, year, calendar: [] };
    const calendarEntry = sessionCalendarsR.find((c) => c.sessionId === sessionId);
    if (calendarEntry !== undefined) {
      newCalendar = { ...calendarEntry };
    }
    return completeCalendarMissingDays(newCalendar, dayListOfYear, "session");
  });

  return {
    calendarsFull: { precinctCalendar, productCalendars, ticketCalendars, sessionsCalendars },
    products,
    tickets,
  };
};

function completeCalendarMissingDays(calendarFromRequest, dayListOfYear, type) {
  const calendarOfRequest = calendarFromRequest.calendar || [];

  const currentCalendar = [...calendarOfRequest].reduce((a, d) => ({ ...a, [d.date]: d }), {});

  const completeCalendar = dayListOfYear.reduce((list, date) => {
    let thisDayData = { date, enable: type === "precinct" };
    if (currentCalendar[date] !== undefined) {
      thisDayData = { ...currentCalendar[date] };
    }

    return { ...list, [date]: thisDayData };
  }, {});

  return { ...calendarFromRequest, calendar: Object.values(completeCalendar) };
}

function getCompleteProductsObject({ products, tickets }) {
  return products.map((product) => ({
    precinctCode: product.precinctCode,
    productCode: product.code,
    productName: product.name,
    quotaEnable: product.quotaEnable,
    tickets: tickets
      .filter((t) => t.productCode === product.code)
      .map((ticket) => ({
        ticketId: ticket.id,
        alias: ticket.alias,
        priceModel: ticket.priceModel,
        quotaEnable: ticket.quotaEnable,
        priceCommissionable: ticket.priceCommissionable,
      })),
  }));
}

function getTicketObjectsByProductCode(tickets) {
  // first make tickets an array of { id: ticket }
  const ticketsById = tickets.reduce((a, d) => ({ ...a, [d.id]: d }), {});
  // then group by productCode
  const ticketsByProductCode = Object.values(ticketsById).reduce((a, d) => {
    const { productCode } = d;
    if (a[productCode] === undefined) {
      a[productCode] = [];
    }
    a[productCode].push(d);
    return a;
  }, {});
  return ticketsByProductCode;
}

function addParentMarkupToCalendar(ticketCalendar, ticket, products, productCalendars) {
  const { productCode } = ticket;

  // Priority A - Ticket calendar day markup
  // Priority B - Ticket markup
  // Priority C - Product calendar day markup
  // Priority D - Product markup
  // Priority E - Default

  // Find the product
  const thisProduct = products.find((c) => c.code === productCode);

  // Construct an object for checking Priority C
  let kvPCalendar = {};
  const thisProductCalendar = productCalendars.find((c) => c.productCode === productCode);
  if (thisProductCalendar !== undefined) {
    kvPCalendar = thisProductCalendar.calendar.reduce((a, d) => ({ ...a, [d.date]: d }), {});
  }

  return ticketCalendar.map((day) => {
    const nday = { ...day, parent: {} };

    // Check Priority A
    if (nday.packageMarkup === undefined) {
      // Check Priority B
      if (ticket.packageMarkup === undefined) {
        // Check Priority C
        if (kvPCalendar[day.date] === undefined || kvPCalendar[day.date].packageMarkup === undefined) {
          // Check Priority D
          if (thisProduct === undefined || thisProduct.packageMarkup === undefined) {
            nday.parent.packageMarkup = DEFAULT_PACKAGE_MARKUP;
          } else {
            // else, Priority D remains
            nday.parent.packageMarkup = thisProduct.packageMarkup;
          }
        } else {
          // else, Priority C remains
          nday.parent.packageMarkup = kvPCalendar[day.date].packageMarkup;
        }
      } else {
        // else, Priority B remains
        nday.parent.packageMarkup = ticket.packageMarkup;
      }
    }
    // else, Priority A remains

    return nday;
  });
}

function addParentFieldToCalendar({ calendar }, parentValues) {
  const withParentdataCalendar = calendar.map((day) => {
    const nday = { ...day, parent: {} };

    Object.keys(parentValues).forEach((kPv) => {
      if (nday[kPv] === undefined) {
        nday.parent[kPv] = parentValues[kPv];
      }
    });

    return nday;
  });

  return withParentdataCalendar;
}
