// import sleep from "@lib/helpers/sleep";
import axios from "axios";

class RemittancesApi {
  static async getRemittancesInProgress() {
    const url = "/api/v1/booking-bc/suppliers/remittance";
    const { data, status } = await axios.get(url, { params: { status: "IN_PROGRESS" } });
    if (status !== 200 || data === null) {
      throw new Error("getRemittancesInProgress: Not valid response");
    }
    return data.data;
  }

  static async getRemittancesPaid(datei, dateo) {
    const url = `/api/v1/booking-bc/suppliers/remittance`;
    const { data, status } = await axios.get(url, {
      params: {
        status: "PAID",
        "paidAt[gte]": `${datei}T00:00:00.000Z`,
        "paidAt[lte]": `${dateo}T23:59:59.000Z`,
      },
    });
    if (status !== 200 || data === null) {
      throw new Error("getRemittancesPaid: Not valid response");
    }
    return data.data;
  }

  static async paidRemittance(remittanceID, paidBy) {
    const url = `/api/v1/booking-bc/suppliers/remittance-mark-as-paid/${remittanceID}`;
    const { data, status } = await axios.post(url, { paidBy });
    if (status !== 200 || data === null) {
      throw new Error("paidRemittance: Not valid response");
    }
  }
}

export default RemittancesApi;
