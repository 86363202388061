import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormControl, InputGroup, Card } from "react-bootstrap";
import { ChipAvatarRoot, UsChipEmail, ChipLabel, ChipDelete, ButtonDialog } from "./AddEmails.styles";
import ErrorModal from "@atoms/Modals/ErrorModal";

// Example of email list for copy-paste
// DomnicHarris@hello.com,GarrySobars@hello.com,StellaJohnson@hello.com,Alex Dolgove@hello.com,JohnSmith@hello.com,

const AddEmails = ({ title, emails, onChangeList }) => {
  const sectionTitle = title || "Lista de emails";

  const separatorChar = ",";

  const [emailState, setEmailState] = useState({
    emailList: emails,
    input: "",
    // errors: { show: false, list: [] },
  });

  const [errors, setErrors] = useState({ show: false, list: [] });

  useEffect(() => {
    if (emails !== undefined) {
      setEmailState({ emailList: emails, input: "" /* errors: { show: false, list: [] } */ });
    }
  }, [emails]);

  /**
   *
   * @param {*} email
   */
  const checkValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  /**
   *
   */
  const dumpEmailState = () => {
    const spl = emailState.input.split(separatorChar);

    const errorsL = [];
    const toAdd = [];
    spl.forEach((anemail) => {
      const cleanEmail = anemail.trim();
      if (cleanEmail) {
        if (checkValidEmail(cleanEmail)) {
          if (emailState.emailList.indexOf(cleanEmail) === -1) {
            toAdd.push(cleanEmail);
          }
        } else {
          errorsL.push(cleanEmail);
        }
      }
    });

    const errorInput = errorsL.join(separatorChar);
    let nuEmailList = emailState.emailList;

    if (errorsL.length > 0) {
      setErrors({ show: true, list: errorsL });
    }

    if (toAdd.length > 0) {
      nuEmailList = [...emails, ...toAdd];
      onChangeList(nuEmailList);
    }

    setEmailState({ emailList: nuEmailList, input: errorInput });

    // if (spl.length === 0) {
    //   setEmailState("");
    // }
  };

  /**
   *
   * @param {*} event
   */
  const handleInputChange = (event) => {
    const { target } = event;
    const { value } = target;
    setEmailState({ emailList: emailState.emailList, input: value });
  };

  /**
   *
   * @param {*} event
   */
  const handleEnterKey = (event) => {
    if (event.charCode === 13) {
      dumpEmailState();
    }
  };

  /**
   *
   * @param {*} event
   * @param {*} index
   */
  const deleteEmail = (event, index) => {
    event.preventDefault();
    emails.splice(index, 1);
    onChangeList(emails);
  };

  const closeErrorsDialog = () => {
    setErrors({ show: false, list: [] });
  };

  return (
    <Fragment>
      <Card.Title>
        <span>{sectionTitle}</span>
      </Card.Title>
      <div>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Añade uno o varios emails (separados por comas) y clica en añadir (o pulsa intro)"
            value={emailState.input}
            onChange={handleInputChange}
            onKeyPress={handleEnterKey}
          />
          <InputGroup.Append>
            <ButtonDialog variant="info" onClick={dumpEmailState}>
              Añadir
            </ButtonDialog>
          </InputGroup.Append>
        </InputGroup>
      </div>
      {emailState.emailList.map((thisEmail, index) => {
        return (
          <UsChipEmail key={index}>
            <ChipAvatarRoot>{thisEmail.charAt(0).toUpperCase()}</ChipAvatarRoot>
            <ChipLabel>{thisEmail}</ChipLabel>
            <ChipDelete onClick={(e) => deleteEmail(e, index)} />
          </UsChipEmail>
        );
      })}

      <ErrorModal show={errors.show} listErrors={errors.list} onClose={closeErrorsDialog} />
    </Fragment>
  );
};

AddEmails.propTypes = {
  title: PropTypes.string,
  emails: PropTypes.array,
  onChangeList: PropTypes.func,
};

export default AddEmails;
