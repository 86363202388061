/* eslint-disable no-use-before-define */
import axios from "axios";
import { getChannelAsQueryParms } from "../utils";

export const fetchCalendarUpdateConfigurations = async (precinctCode, channel) => {
  const url = `/api/v1/ticketing-bc/calendar/update-configuration`;
  return get(url, { precinctCode, ...getChannelAsQueryParms(channel, "channel") });
};

export const saveCalendarUpdateConfigurations = async (newConfigurations) => {
  for (let i = 0; i < newConfigurations.length; i += 1) {
    const { productCode } = newConfigurations[i];
    const url = `/api/v1/ticketing-bc/calendar/update-configuration/${productCode}`;
    await put(url, {}, newConfigurations[i]);
  }
};

async function get(url, params) {
  try {
    const { data } = await axios.get(url, { params });
    return data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    throw error;
  }
}

async function put(url, params, body) {
  try {
    const { data } = await axios.put(url, body, { params });
    return data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    throw error;
  }
}
