import React from "react";
import { useDrag, DragPreviewImage, useDrop } from "react-dnd";

export const Draggable = ({ children, group, uid, startDrag = () => {}, endDrag = () => {} }) => {
  if (group === undefined || uid === undefined) {
    throw new Error("group and uid is mandatory.");
  }
  const [{ opacity }, dragRef] = useDrag({
    item: { type: group, uid },
    begin: (monitor) => {
      startDrag({ group, uid });
    },
    end: (monitor) => {
      endDrag({ group, uid });
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 1 : 1,
    }),
  });
  return (
    <div ref={dragRef} style={{ opacity }}>
      {children}
    </div>
  );
};

export const DropArea = ({ children, group, uid, onDrop, onHover = () => {} }) => {
  if (group === undefined || uid === undefined) {
    throw new Error("group and uid is mandatory.");
  }
  const [{ isOver }, drop] = useDrop({
    accept: group,
    drop: (item) => {
      onChangeDrop(item);
    },
    hover: (item) => {
      onChangeHover(item);
    },

    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const onChangeDrop = (dragging) => {
    onDrop(dragging, { group, uid });
  };
  const onChangeHover = (dragging) => {
    onHover(dragging, { group, uid });
  };
  return <div ref={drop}>{children}</div>;
};

export const DragAndDrop = ({ group, uid, children, onDrop, onHover, endDrag, startDrag }) => {
  return (
    <DropArea group={group} uid={uid} onDrop={onDrop} onHover={onHover}>
      <Draggable group={group} uid={uid} startDrag={startDrag} endDrag={endDrag}>
        {children}
      </Draggable>
    </DropArea>
  );
};
