import React from "react";
import { Skeleton } from "@material-ui/lab";

const SupplierBlockListSkeleton = () => {
  return (
    <>
      {[...new Array(10)].map((_, index) => (
        <Skeleton key={`sk${index}`} height={80} style={{ marginBottom: "-30px", marginTop: "-20px" }} />
      ))}
    </>
  );
};

export default SupplierBlockListSkeleton;
