import React from "react";
import { useParams } from "react-router-dom";
import AffiliateReportsManagementPage from "@organisms/Marketing/AffiliatesReports/management/components";

const AffiliateReportManagementPage = () => {
  const { affiliateReportId } = useParams();
  return (
    <AffiliateReportsManagementPage
      creation={affiliateReportId === "_new"}
      affiliateReportId={affiliateReportId}
    />
  );
};

export default AffiliateReportManagementPage;
