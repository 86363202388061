import React from "react";
import styled from "styled-components";
import { IoIosClose } from "react-icons/io";
const InputQuery = styled.input`
  position: relative;
  font-family: "montserrat";
  display: flex;
  width: 100%;
  min-height: 3rem;
  margin-bottom: 10px;
  padding: 10px 50px 10px 16px;
  /* padding: ${(props) => (props.icon ? "10px 50px 10px 16px" : "10px 50px 10px 16px")}; */
  font-size: 1rem;
  color: #666;
  font-weight: bold;
  line-height: 1.375rem;
  border: none;
  border-bottom: 1px solid #ddd;
  &:focus {
    color: #000;
    outline: none;
  }
`;

const InputQueryDropdown = ({ onChange, value, children }) => {
  return (
    <div style={{ position: "relative" }}>
      <InputQuery onChange={onChange} value={value} autoFocus placeholder="Buscar" />
      <span
        style={{
          position: "absolute",
          top: "0.5rem",
          right: "0.5rem",
          height: "30px",
          widht: "30px",
          fontSize: "30px",
          textAlign: "center",
        }}
      >
        {children}
      </span>
    </div>
  );
};

export default InputQueryDropdown;
