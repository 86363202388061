import { tlds } from "../shared/domainVariables";

export const wordcontrol = "Ticketing_Tarifas_v2";
export const filename = "tickets";
export const wordcontrolContent = "Ticketing_Tarifas_Contenido_v1";
export const filenameContent = "ticketing_tarifas_contenido";
export const wordcontrolChannels = "Ticketing_Tarifas_Canales_v3";
export const filenameChannels = "ticketing_tarifas_canales";

export const sellingAllowed = ["onlyPackage", "onlySingle", "all"];
export const priceModels = ["Default", "PerOneDay"];

export const contentFields = {
  name: "lang",
  description: "lang",
  channels: "original",
  archived: "original",
};

export const csvHead = [
  "ID autogenerado (vacio para nuevo)",
  "Codigo Producto",
  "Codigo Recinto",
  "Alias (uso interno)",
  "Nombre (es, aqui solo lectura)",
  "Descripción (es, aqui solo lectura)",
  "Activo",
  "¿Tiene sesiones?",
  "¿Tiene cupos?",
  "Minimo de cupo",
  "Días: Minimos",
  "Días: Maximos",
  "Personas (minimo|maximo, ...)",
  `Tipo de venta (${sellingAllowed.join("|")})`,
  `Modelo de precio (${priceModels.join("|")})`,
  "Margen al empaquetar",
  "Cancelación: ¿Es cancelable?",
  "Cancelación: Condiciones (Días hasta la fecha|porcentaje, ...)",
  "Release: Activo",
  "Release: Días hasta la fecha",
  "Release: Hora",
  "Limite de venta: Inicio: Activo",
  "Limite de venta: Inicio: Fecha (y-m-d)",
  "Limite de venta: Inicio: Hora (HH:mm)",
  "Limite de venta: Final: Activo",
  "Limite de venta: Final: Fecha (y-m-d)",
  "Limite de venta: Final: Hora (HH:mm)",
];

export const csvSpecs = {
  id: "",
  productCode: "",
  precinctCode: "",
  alias: "",
  name: { "es-ES": "" },
  description: { "es-ES": "" },
  enable: "b",
  hasSessions: "b",
  quotaEnable: "b",
  minQuota: "dz",
  days: { min: "d", max: "d" },
  people: "sc",
  sellingAllowed: "",
  priceModel: "",
  packageMarkup: "d",
  cancellation: {
    isCancellable: "b",
    conditions: "sc",
  },
  release: {
    enable: "b",
    daysToDate: "d",
    time: "",
  },
  salesBoundaries: {
    startSales: {
      enable: "b",
      date: "dt",
      time: "",
    },
    stopSales: {
      enable: "b",
      date: "dt",
      time: "",
    },
  },
};

export const csvHeadContent = [
  "Identificador de tarifa",
  `Campo (${Object.keys(contentFields).join("|")})`,
  `Idioma (${tlds.join("|")})`,
  "Contenido",
];
export const csvSpecContent = { id: "", field: "", tld: "", value: "" };

export const csvHeadChannels = [
  "Identificador de tarifa",
  "Producto",
  "Alias (uso interno, aqui solo lectura)",
  `Proveedor`,
  `Merchant`,
  `Activo`,
  "Precios comisionables",
  "Comisión",
  "Codigo de mapeo (sólo se usa si el modo de reserva es vía api)",
  "¿Reservable en proveedor?",
  "Margen de paquete",
  "Email Reserva: Nombre oficial",
  "Email Reserva: ¿Botón de confirmación?",
  "Email Reserva: ¿Botón de cancelación?",
  "Email Reserva: Texto descuento especial",
  "Bono: Texto descuento especial",
  "Suppliers",
];

export const csvSpecChannels = {
  id: "",
  productCode: "",
  alias: "",
  channel: {
    provider: "",
    merchant: "",
  },
  enable: "b",
  priceCommissionable: "b",
  commission: "d",
  mapcode: "",
  hasToBeBook: "b",
  packageMarkup: "d",
  officialName: "",
  needConfirmation: "b",
  needCancellationConfirmation: "b",
  specialEmailDiscount: "",
  specialVoucherDiscount: "",
  suppliers: "sc",
};
