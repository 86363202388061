import React from "react";
import { useFenixAuthContext } from "@src/entries/fenix/auth/FenixAuthContext";
import FxAlert from "@atoms/Alerts/FxAlert";
import { ignoreBookTraventia, ignoreBookTraventiaV2 } from "./useConcilliation";
import ConcilliationTable from "./ConcilliationTable";

function ConcilliationTraventia({ loading, titleName, traventiaBooks, params, onChangeParams, useV2 }) {
  const [{ user }] = useFenixAuthContext();

  const onIgnore = async (newValue, row) => {
    try {
      if (useV2) {
        await ignoreBookTraventiaV2(row.servref, newValue, user?.profile?.email ?? "-");
      } else {
        await ignoreBookTraventia(row.servref, newValue, user?.profile?.email ?? "-");
      }
    } catch (e) {
      console.error(e);
      return;
    }
    await onChangeParams(params);
  };

  return (
    <>
      <FxAlert variant={"info"}>
        En esta sección se lista el resultado de la conciliación de <b>nuestras reservas (por {titleName})</b>
        . Por defecto listara los {titleName} que <b>ha podido chequear en los que ha encontrado errores</b>.
        Si se pulsa en cualquier resultado, se desplegara el listado de errores encontrados.
        <br />
        Se pueden <b>ignorar</b> resultados (Botón {'"Ignorar"'}). Al ignorar estos resultados, dejaran de
        mostrarse en esta lista.
        <br />
        En los botones de abajo podras añadir a la tabla resultados con otro estado que por defecto aparecerán
        ocultos.
      </FxAlert>
      <ConcilliationTable
        loading={loading}
        params={params}
        books={traventiaBooks}
        section={"traventia"}
        onChangeParams={onChangeParams}
        onIgnore={onIgnore}
      />
    </>
  );
}

export default ConcilliationTraventia;
