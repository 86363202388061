// useFetch.js
import { useEffect, useState } from "react";
import axios from "axios";
// eslint-disable-next-line camelcase
import { uuid_v4 } from "../../../../lib/helpers/uuid_v4";

// const url = "/api/mandalorian";
const url = "/api/v1/marketing-bc";
const url_products = `/api/v1/ticketing-bc/products`;

/*
[GET] 127.0.0.1/cross-selling/campaigns/ -> Listar todas las campañas
[GET] 127.0.0.1/cross-selling/campaigns/CODE -> Información de una campaña
[POST] 127.0.0.1/cross-selling/campaigns/ -> Crear una nueva campaña
[PUT] 127.0.0.1/cross-selling/campaigns/CODE -> Editar una campaña
[POST] 127.0.0.1/cross-selling/campaigns/CODE/disable-> Deshabilitar una campaña
[POST] 127.0.0.1/cross-selling/campaigns/CODE/enable-> Habilitar una campaña

[POST] 127.0.0.1/cross-selling/campaigns/execute-now/CODE-> Forzar envio ahora a emails en body
*/

export const generateNewCampaignCode = () => {
  return uuid_v4();
};

const catchErrorMessage = (errorObject) => {
  let message = "Error no capturado";

  const { response } = errorObject;
  if (response !== undefined) {
    if (response.data !== undefined) {
      if (response.data.err !== undefined) {
        if (response.data.err.message !== undefined) {
          message = response.data.err.message;
        }
      }
    }
  }
  return message;
};

/**
 *
 */
export const useCrossSellingCampaigns = () => {
  const [campaignsState, setState] = useState({ loading: true, list: [] });

  useEffect(() => {
    /**
     * We need to parse this list for the table and csv, with additional formats
     * @param {*} list
     */
    const parseListForTable = (list) => {
      const nuList = [...list];
      nuList.forEach((item) => {
        let ticketsArray = [];
        if (item.content !== undefined && item.content.tickets !== undefined) {
          ticketsArray = item.content.tickets.map((tk) => tk.code);
        }
        // eslint-disable-next-line no-param-reassign
        item.ticketsArray = ticketsArray;
        // eslint-disable-next-line no-param-reassign
        item.validDatesRanges = item.validDatesRanges.map((dt) => `${dt.start} al ${dt.end}`);
      });
      return nuList;
    };

    const fetchData = async () => {
      try {
        let thestate = { list: [], loading: false };
        const response = await axios.get(`${url}/cross-selling/campaigns`);
        if (response.status === 200) {
          const list = parseListForTable(response.data.data);
          thestate = { list, loading: false };
        }
        setState(thestate);
      } catch (error) {
        setState({ list: [], loading: false });
        throw error;
      }
    };

    if (campaignsState.loading) {
      fetchData();
    }
  }, [campaignsState.loading]);
  return { loadingCampaigns: campaignsState.loading, campaigns: campaignsState.list };
};

/**
 *
 * @param {*} code
 */
export const useCrossSellingCampaign = (code) => {
  const [campaignData, setCampaignData] = useState({ loading: true, campaign: {} });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, campaign: {} };

        const codeCamp = code || "NEW";
        const response = await axios.get(`${url}/cross-selling/campaigns/${codeCamp}`);
        if (response.status === 200) {
          thestate = { loading: false, campaign: response.data };
        }

        setCampaignData(thestate);
      } catch (error) {
        setCampaignData({ loading: false, campaign: {} });
        throw error;
      }
    };

    if (campaignData.loading) {
      fetchData();
    }
  }, [campaignData.loading, code]);

  return { loadingCampaign: campaignData.loading, campaign: campaignData.campaign };
};

/**
 *
 * @param {*} code
 * @param {*} maxlength
 */
export const useNextExecutions = (code, maxlength) => {
  const [campaignData, setCampaignData] = useState({ loading: true, listNext: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, listNext: [] };

        const response = await axios.get(`${url}/cross-selling/campaigns/next-executions/${code}`);
        if (response.status === 200 && response.data.ok) {
          // Apply lenght if exists
          let listNext = [...response.data.data];
          if (maxlength !== undefined) {
            listNext = listNext.slice(0, maxlength);
          }

          thestate = { loading: false, listNext };
        }
        setCampaignData(thestate);
      } catch (error) {
        setCampaignData({ loading: true, listNext: [] });
        throw error;
      }
    };

    if (campaignData.loading) {
      fetchData();
    }
  }, [campaignData.loading, code, maxlength]);

  return { loadingNext: campaignData.loading, list: campaignData.listNext };
};

/**
 *
 * @param {*} campaign
 */
export const saveCrossSellingCampaign = async (campaign) => {
  let response = { ok: false };
  try {
    const requestRes = await axios.put(`${url}/cross-selling/campaigns/${campaign.id}`, campaign);
    if (requestRes.status === 201) {
      // response = requestRes.data;
      response = { ok: true };
    }
  } catch (error) {
    console.log(error);
    const message = catchErrorMessage(error);
    response = { ok: false, message };
  }

  return response;
};

export const simulateCampaignCrossSelling = async (idCampaign, emails) => {
  let response = { ok: false };
  try {
    const requestRes = await axios.post(`${url}/cross-selling/execute/simulate/${idCampaign}`, { emails });
    if (requestRes.status === 201) {
      response.ok = true;
    }
  } catch (error) {
    console.log(error);
    const message = catchErrorMessage(error);
    response = { ok: false, message };
  }
  return response;
};

/**
 *
 * @param {*} campaign
 */
export const deleteCrossSellingCampaign = async (campaign) => {
  let response = { ok: false };
  try {
    const requestRes = await axios.delete(`${url}/cross-selling/campaigns/${campaign.id}`);
    if (requestRes.status === 204) {
      // response = requestRes.data;
      response = { ok: true };
    }
  } catch (error) {
    console.log(error);
    const message = catchErrorMessage(error);
    response = { ok: false, message };
  }

  return response;
};

/**
 *
 * @param {*} campaignCode
 * @param {*} newEnable
 */
export const enableCrossSellingCampaign = async (campaign) => {
  let responseRet = { ok: false };
  try {
    const requestRes = await axios.put(`${url}/cross-selling/campaigns/${campaign.id}`, campaign);
    if (requestRes.status === 201) {
      responseRet.ok = true;
    }
  } catch (error) {
    const message = catchErrorMessage(error);
    responseRet = { ok: false, message };
  }
  return responseRet;
};

/**
 *
 * @param {*} campaignCode
 * @param {*} emailsTo
 */
export const executeCrossSellingCampaign = async (campaignCode, emails) => {
  let responseRet = { ok: false };
  try {
    const requestRes = await axios.post(`${url}/cross-selling/campaigns/execute-simulation/${campaignCode}`, {
      emails,
    });

    if (requestRes.status === 200 && requestRes.data.ok) {
      responseRet = requestRes.data;
    }
  } catch (error) {
    const message = catchErrorMessage(error);
    responseRet = { ok: false, message };
  }

  return responseRet;
};

export const getAllTicketProducts = async (tcode) => {
  let thestateFinal = { loadingList: true, list: [] };
  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { loadingList: true, list: [] };
      const response = await axios.get(`${url_products}?precinctCode=${tcode}`);
      if (response.status === 200) {
        thestate = { loadingList: false, list: response.data.data.flat().map((product) => product.code) };
      }
      thestateFinal = thestate;
    } catch (error) {
      thestateFinal = { loadingList: false, list: {} };
      console.error(error);
    }
    return thestateFinal;
  };
  if (thestateFinal.loadingList) {
    thestateFinal = await fetchData(thestateFinal);
    return thestateFinal;
  }
  return thestateFinal;
};
