import React, { useState } from "react";
import { parseString } from "xml2js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const asyncParseString = function (file) {
  return new Promise((resolve, reject) => {
    parseString(file, { attrkey: "@", charkey: "#", explicitArray: false }, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

const UploadXmlSii = (props) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filesXmlName, setFilesXmlName] = useState([]);
  const processFiles = (fileList, callback, number = 0, xmls = []) => {
    const totalFiles = Object.keys(fileList).length;
    setProgress((number / totalFiles) * 100);
    if (number < totalFiles) {
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        const content = fileReader.result;
        asyncParseString(content)
          .then((result) => {
            xmls.push({ name: fileList.item(number).name, content: result });
            processFiles(fileList, callback, (number += 1), xmls);
          })
          .catch((err) => {
            console.error(err);
            processFiles(fileList, callback, (number += 1), xmls);
          });
      };
      fileReader.readAsText(fileList.item(number));
    } else {
      callback(xmls);
    }
  };

  const handleFileInput = async (fileList) => {
    setLoading(true);
    const xmlsName = Object.values(fileList).map((file) => file.name);
    setFilesXmlName(xmlsName);
    processFiles(fileList, (xmls) => {
      setLoading(false);
      props.onReady(xmls);
    });
  };
  return (
    <div>
      <input
        type="file"
        accept=".xml"
        id="xmlFiles"
        name="xmlFiles"
        multiple
        onChange={(event) => handleFileInput(event.target.files)}
      />
      <br />
      {loading && <CircularProgressWithLabel value={progress} />}
      {filesXmlName.length > 0 && loading === false && (
        <>
          {"Archivos subidos: "}
          <ul>
            {filesXmlName.sort().map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default UploadXmlSii;
