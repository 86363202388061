import styled from "styled-components";

function getGradient(highlighted) {
  const strippedDark = highlighted ? "#DB6847" : "#a73413";
  const strippedLight1 = highlighted ? "#D88269" : "#a63f21";
  const strippedLight2 = highlighted ? "#D88269" : "#a35640";

  return ["-45deg", strippedDark, `${strippedDark} 3px`, `${strippedLight1} 3px`, `${strippedLight2} 7px`];
}

function getCellColor(props) {
  const { highlighted, enable, parentEnabled, backgroundColor } = props;

  if (backgroundColor) {
    return backgroundColor;
  }

  let background = highlighted ? "red" : "#A73413";
  if (enable) {
    if (parentEnabled) {
      background = highlighted ? "limegreen" : "#0C7A0C";
    } else {
      const strippedGradient = getGradient(highlighted);
      background = `repeating-linear-gradient(${strippedGradient})`;
    }
  }

  return background;
}

export const StyDivDay = styled.div`
  height: 20px;
  float: left;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  cursor: ${(props) => props.pointer && "pointer"};
  text-align: center;
  background: ${(props) => getCellColor(props)};
  //background: ${(props) => props.openInClosedParent && `repeating-linear-gradient(${gradient.join(",")})`};
  opacity: ${(props) => props.isInThePast && 0.6};
  ${(props) =>
    props.calendarLength && {
      width: `${80 / props.calendarLength}%`,
    }}
`;

export default StyDivDay;
