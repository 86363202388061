import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LoadingCenter } from "./Loaders.styles";

function CenterLoaderCircle() {
  return (
    <LoadingCenter>
      <CircularProgress color="primary" />
    </LoadingCenter>
  );
}
export default CenterLoaderCircle;
