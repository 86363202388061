import React from "react";
import { useFenixAuthContext } from "@src/entries/fenix/auth/FenixAuthContext";
import FxAlert from "@atoms/Alerts/FxAlert";
import { ignoreBookProvider, ignoreBookProviderV2 } from "./useConcilliation";
import ConcilliationTable from "./ConcilliationTable";

function ConcilliationProvider({ loading, providerBooks, params, titleName, onChangeParams, useV2 }) {
  const [{ user }] = useFenixAuthContext();

  const onIgnore = async (newValue, row) => {
    try {
      if (useV2) {
        await ignoreBookProviderV2(row.provider, row.locata, newValue, user?.profile?.email ?? "-");
      } else {
        await ignoreBookProvider(row.provider, row.locata, newValue, user?.profile?.email ?? "-");
      }
    } catch (e) {
      console.error(e);
      return;
    }
    await onChangeParams(params);
  };

  return (
    <>
      <FxAlert variant={"info"}>
        En esta sección se lista el resultado de la conciliación de
        <b>las reservas ({titleName}) encontradas en el catalogo de los proveedores</b>. Por defecto listara
        los
        {titleName} que tiene registrados el proveedor y en los que <b>ha encontrado errores</b>. Si se pulsa
        en cualquier resultado, se desplegara el listado de errores encontrados.
        <br />
        Se pueden <b>ignorar</b> resultados (Botón {'"Ignorar"'}). Al ignorar estos resultados, dejaran de
        mostrarse en esta lista.
        <br />
        En los botones de abajo podras añadir a la tabla resultados con otro estado que por defecto aparecerán
        ocultos.
      </FxAlert>
      <ConcilliationTable
        loading={loading}
        params={params}
        books={providerBooks}
        section={"provider"}
        onChangeParams={onChangeParams}
        onIgnore={onIgnore}
      />
    </>
  );
}

ConcilliationProvider.propTypes = {};

export default ConcilliationProvider;
