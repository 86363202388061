import React from "react";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";

const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

function MonthSelector({ monthSelected, disabled, onChangeMonth, ...props }) {
  return (
    <FormControl variant="outlined">
      <InputLabel id="demo-simple-select-label">Mes</InputLabel>
      <Select
        {...props}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        disabled={disabled}
        value={monthSelected - 1}
        label={"Mes"}
        onChange={(e) => onChangeMonth(e.target.value + 1)}
      >
        {monthNames.map((month, index) => (
          <MenuItem key={`month${month}`} value={index}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MonthSelector;
