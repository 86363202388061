/* eslint-disable no-use-before-define */
import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Table, TableBody, TableRow, TableCell, TableFooter } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";

import FenixTableHead from "./FenixTableHead";
import FenixTableRow from "./FenixTableRow";
import FenixTableInputSearch from "./FenixTableInputSearch";
import FenixTablePagination from "./FenixTablePagination";
import useFenixTableWithCustomPagination from "./useFenixTableWithCustomPagination";

const FenixTableCustomPagination = ({
  loading,
  tableTitle,
  tableHead,
  actions,
  showInputFilter,

  // Pagination
  pagination,
  maxNumberPerPages,
  customPaginationFetchUrl,
  withSearch,
  searchQueryParam,
  searchQueryAdditionalParams,
  // Selection
  isSelectable,
  selectedRows,
  allowSelectAll,
  onChangeSelections,

  // Sort
  sortDefault,

  forceReload = 0,
  inputTextExternal = "",
  modifyContentTable,
  minCharsToSearch = 3,
}) => {
  const classes = useStyles();
  const {
    onRequestLoading,
    tableData,
    order,
    filters,
    onRequestSort,
    onChangeFilters,
    onPageChangeByOne,
    limit,
    tableContent,
    onChangeInputSearch,
    inputText,
  } = useFenixTableWithCustomPagination(
    tableHead,
    sortDefault,
    customPaginationFetchUrl,
    withSearch,
    searchQueryParam,
    maxNumberPerPages,
    searchQueryAdditionalParams,
    forceReload,
    inputTextExternal,
    modifyContentTable,
    minCharsToSearch
  );
  const withChildren = tableData.some((row) => row.children !== undefined);
  let tableColSpan = tableHead.length + 1;
  if (withChildren) {
    tableColSpan += 1;
  }

  // Head Main Checkbox
  const [mainCheckbox, setMainCheckbox] = useState(false);
  useEffect(() => {
    if (allowSelectAll) {
      // Listen for the selection list in order to check/uncheck main checkbox
      setMainCheckbox(selectedRows.length === tableContent.length && tableContent.length > 0);
    }
  }, [allowSelectAll, selectedRows, tableContent.length]);

  useEffect(() => {}, [forceReload]);

  const onChangeAllCheckbox = (checked) => {
    // Select all should delete all filters?
    if (checked) {
      const newTableContent = tableContent.map((value, i) => ({ ...value, key_id: `FxTableRow_${i}` }));
      onChangeSelections(newTableContent);
    } else {
      onChangeSelections([]);
    }
  };

  const onSelectRow = (row, checked) => {
    const newSelectedRows = [...selectedRows];
    if (checked) {
      newSelectedRows.push(row);
    } else {
      const ix = newSelectedRows.findIndex((nrow) => nrow.key_id === row.key_id);
      newSelectedRows.splice(ix, 1);
    }
    onChangeSelections(newSelectedRows);
  };

  return (
    <TravCard>
      <TravBodyCard style={{ overflowX: "auto" }}>
        {tableTitle !== "" && <TravTitleCard>{tableTitle}</TravTitleCard>}

        {/* Search Input Filter */}
        {showInputFilter && (
          <FenixTableInputSearch inputText={inputText} onChangeInput={onChangeInputSearch} />
        )}

        <Table className={classes.root}>
          <FenixTableHead
            tableHead={tableHead}
            filters={filters}
            order={order.direction}
            orderBy={order.by}
            actions={actions}
            onRequestSort={onRequestSort}
            onChangeFilters={onChangeFilters}
            withChildren={withChildren}
            // Selection
            withSelection={isSelectable}
            allowSelectAll={allowSelectAll}
            mainCheckbox={mainCheckbox}
            onChangeAllCheckbox={onChangeAllCheckbox}
          />
          <TableBody>
            {loading || onRequestLoading ? (
              <TableRow rowSpan={5}>
                <TableCell colSpan={tableColSpan}>
                  <CenterLoaderCircle role="status" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {/* Empty Table */}
                {tableContent.length === 0 ? (
                  <TableEmpty colSpan={tableColSpan}>
                    <Alert severity={"info"}>Esta tabla esta vacía</Alert>
                  </TableEmpty>
                ) : (
                  <>
                    {tableData.length === 0 && (
                      <TableEmpty colSpan={tableColSpan}>
                        <Alert severity={"info"}>Ninguna fila de la tabla aplica a estos filtros</Alert>
                      </TableEmpty>
                    )}
                  </>
                )}
                {tableData.slice(limit.indexI, limit.indexF).map((rowData, index) => (
                  <Fragment key={`TableRow${index}`}>
                    <FenixTableRow
                      rowData={rowData}
                      tableHead={tableHead}
                      actions={actions}
                      isSelectable={isSelectable}
                      selectedRows={selectedRows}
                      onSelectRow={onSelectRow}
                      tableColSpan={tableColSpan}
                    />
                    {/* Subrows */}
                    {rowData.subrows !== undefined && rowData.subrows.length > 0 && (
                      <>
                        {rowData.subrows.map((subrow, indexSr) => (
                          <FenixTableRow
                            style={{ backgroundColor: "#F0F0F0" }}
                            key={`TableRow${index}SubRow${indexSr}`}
                            rowData={subrow}
                            tableHead={tableHead}
                            tableColSpan={tableColSpan}
                          />
                        ))}
                      </>
                    )}
                  </Fragment>
                ))}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              {pagination && <FenixTablePagination limit={limit} onPageChangeByOne={onPageChangeByOne} />}
            </TableRow>
          </TableFooter>
        </Table>
      </TravBodyCard>
    </TravCard>
  );
};

const TableEmpty = ({ colSpan, children }) => {
  return (
    <TableRow rowSpan={5}>
      <TableCell colSpan={colSpan}>{children}</TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTableCell-root": {
      padding: "5px",
      // paddingLeft: "0"
    },
  },
}));

FenixTableCustomPagination.propTypes = {
  /**
   * Boolean for showing if the table data is loading
   */
  loading: PropTypes.bool,
  /**
   * A title for the table block
   */
  tableTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Head structure of the table
   */
  tableHead: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  /**
   * Array of actions available for each row of the table
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      name: PropTypes.string,
      func: PropTypes.func,
    })
  ),
  /**
   * Flag for show/hide the input filter
   */
  showInputFilter: PropTypes.bool,
  /**
   * Flag for show/hide (and apply) the pagination
   */
  pagination: PropTypes.bool,
  /**
   * Number of row per page (only if pagination:true)
   */
  maxNumberPerPages: PropTypes.number,
  /**
   * Flag for show/hide (and apply) the selection of rows
   */
  isSelectable: PropTypes.bool,
  /**
   * Flag for sho/hide a main selector for select/deselect all rows (only if isSelectable:true)
   */
  allowSelectAll: PropTypes.bool,
  /**
   * List of rows selected, with the same format of the rows in tableContent
   */
  selectedRows: PropTypes.arrayOf(PropTypes.shape),
  /**
   * Function executed every time some row is selected/deselected
   */
  onChangeSelections: PropTypes.func,
  /**
   * Object showing a sorting of the table by default, using one of the tableHead rows (by code)
   */
  sortDefault: PropTypes.shape({
    direction: PropTypes.oneOf(["asc", "desc"]),
    by: PropTypes.string,
  }),
};

FenixTableCustomPagination.defaultProps = {
  loading: false,
  tableTitle: "",
  actions: [],
  showInputFilter: true,
  pagination: false,
  maxNumberPerPages: 10,
  customPaginationAction: null,
  isSelectable: false,
  allowSelectAll: false,
  selectedRows: [],
  onChangeSelections: () => {},
  sortDefault: { direction: "asc", by: null },
};

export default FenixTableCustomPagination;
