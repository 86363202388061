import styled from "styled-components";

import { Alert } from "react-bootstrap";

export const AlertNoTags = styled(Alert)`
  margin-bottom: 0 !important;
  color: #666;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
`;

export const AlertTagsBorder = styled(Alert)`
  margin-bottom: 0 !important;
  color: #0d3c61;
  background-color: #e8f4fd;
  border-color: #e8f4fd;
`;

export const UsChipTag = styled.div`
  color: white;
  height: 32px;
  cursor: default;
  border: none;
  display: inline-flex;
  outline: none;
  padding: 0;
  font-size: 0.8125rem;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-sizing: border-box;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #ff8f18;
  margin: 0 10px 10px 0;
`;
