import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TableRow, TableCell, Checkbox, Collapse, IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FenixTableCell from "./FenixTableCell";
import FenixTableRowAction from "./FenixTableRowAction";

const FenixTableRow = ({
  rowData,
  tableHead,
  actions,
  indexRow,
  tableColSpan,
  isSelectable,
  selectedRows,
  onSelectRow,
  cellContentDefault,
  // onChange,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const isChecked = selectedRows.map((sr) => sr.key_id).includes(rowData.key_id);

  /**
   *
   */
  const getColourStatus = () => {
    let tColour;
    const coloured = {};
    tableHead.forEach((head) => {
      if (head.coloured) {
        coloured[head.code] = head.coloured;
      }
    });
    if (Object.keys(coloured).length > 0) {
      const field = Object.keys(coloured)[0];
      // if (Object.keys(coloured) > 1) {}

      if (rowData[field] && coloured[field][rowData[field]]) {
        tColour = coloured[field][rowData[field]];
      }
    }
    // return "red";
    return tColour;
  };

  const clickInRowCheckbox = (thisRowData, event) => {
    const { checked } = event.target;
    onSelectRow(thisRowData, checked);
  };

  // console.log(rowData);

  return (
    <>
      <TableRowDyn colour={getColourStatus()} {...props}>
        <>
          {/* Checkbox */}
          {isSelectable && (
            <TableCell style={{ width: "42px" }} className="status-cell">
              <Checkbox
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                // name={rowData.key_id.split("_").pop()}
                name={`Checkbox_Row${indexRow}`}
                checked={isChecked}
                onChange={(e) => clickInRowCheckbox(rowData, e)}
              />
            </TableCell>
          )}

          {/* Chevron */}
          {rowData.children !== undefined && (
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          )}

          {/* Cells */}
          {tableHead.map((hd) => (
            <FenixTableCell
              cellContentDefault={cellContentDefault}
              key={`${rowData.key_id}_${hd.code}`}
              interfaceForThisCell={hd}
              rowData={rowData}
            />
          ))}

          {/* Action cell */}
          {actions !== undefined && actions.length > 0 && (
            <FenixTableRowAction actions={actions} rowData={rowData} />
          )}
        </>
      </TableRowDyn>

      {/* Children content of a row (react element) */}
      {rowData.children !== undefined && (
        <TableRow>
          <TableCell colSpan={tableColSpan}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {rowData.children}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const TableRowDyn = styled(TableRow)`
  > * {
    color: ${(props) => `${props.colour} !important` || ""};
  }
`;

FenixTableRow.propTypes = {
  rowID: PropTypes.string,
  rowData: PropTypes.object,
  tableHead: PropTypes.array,
  actions: PropTypes.array,
  onChange: PropTypes.func,
};
FenixTableRow.defaultProps = {
  isSelectable: false,
  selectedRows: [],
  onSelectRow: () => {},
};

export default FenixTableRow;
