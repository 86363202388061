import React from "react";
import { Route } from "react-router-dom";

import BankTransfersManagmentPage from "../../5-pages/Operations/BankTransfersManagmentPage";
import BankTransfersUploadPage from "../../5-pages/Operations/BankTransfersUploadPage";
import VouchersManagmentPage from "../../5-pages/Operations/VouchersManagmentPage";
import {
  ConcilliationAccommodationsPage,
  ConcilliationTicketsPage,
  ConcilliationAccommodationsPageV2,
  ConcilliationTicketsPageV2,
} from "../../5-pages/Operations/ConcilliationPage";

function RoutesOperations({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/operations`;
  return (
    <>
      <Route path={`${mainUrl}/manage/vouchers`} component={VouchersManagmentPage} />
      <Route path={`${mainUrl}/bank-transfers/upload`} component={BankTransfersUploadPage} />
      <Route path={`${mainUrl}/bank-transfers/managment`} component={BankTransfersManagmentPage} />
      <Route
        path={`${mainUrl}/concilliation/table/accommodations`}
        component={ConcilliationAccommodationsPage}
      />
      <Route
        path={`${mainUrl}/concilliation/table/accommodationsv2`}
        component={ConcilliationAccommodationsPageV2}
      />
      <Route path={`${mainUrl}/concilliation/table/tickets`} component={ConcilliationTicketsPage} />
      <Route path={`${mainUrl}/concilliation/table/ticketsv2`} component={ConcilliationTicketsPageV2} />
    </>
  );
}

export default RoutesOperations;
