/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

function FxDatePickersFromTo({
  labelFrom,
  dateFromInitial,
  labelTo,
  dateToInitial,
  disabled,
  onChange,
  maxDate,
}) {
  const format = "YYYY-MM-DD";
  const [pickers, setPickers] = useState({
    datei: formatDate(dateFromInitial),
    dateo: formatDate(dateToInitial),
  });

  useEffect(() => {
    setPickers({
      datei: moment(dateFromInitial).format(format),
      dateo: moment(dateToInitial).format(format),
    });
  }, [dateFromInitial, dateToInitial]);

  function formatDate(theDate) {
    if (theDate !== undefined) {
      return moment(theDate).format(format);
    }
    return moment().format(format);
  }

  /**
   *
   * @param {*} which
   * @param {*} stringSelectedDate
   */
  const changeAPicker = (which, stringSelectedDate) => {
    let ndatei = moment(which === "i" ? stringSelectedDate : pickers.datei);
    let ndateo = moment(which === "o" ? stringSelectedDate : pickers.dateo);

    if (ndatei > ndateo) {
      if (which === "i") {
        ndateo = moment(ndatei).add(1, "days");
      } else {
        ndatei = moment(ndateo).subtract(1, "days");
      }
    }
    const tdates = { datei: formatDate(ndatei), dateo: formatDate(ndateo) };

    onChange(tdates);
    setPickers(tdates);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <DatePicker
          disabled={disabled}
          fullWidth
          label={labelFrom}
          value={pickers.datei}
          onChange={(e) => changeAPicker("i", e)}
          animateYearScrolling={false}
          autoOk={true}
          format={"DD/MM/YYYY"}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          maxDate={maxDate !== undefined ? formatDate(maxDate) : undefined}
          disabled={disabled}
          fullWidth
          label={labelTo}
          value={pickers.dateo}
          onChange={(e) => changeAPicker("o", e)}
          animateYearScrolling={false}
          allowKeyboardControl={true}
          autoOk={true}
          format={"DD/MM/YYYY"}
        />
      </Grid>
    </Grid>
  );
}
export default FxDatePickersFromTo;
