import React from "react";
import { Grid } from "@material-ui/core";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import ChannelConfigurationSelector from "../shared/ChannelConfigurationSelector/ChannelConfigurationSelector";
import ProductChannelForm from "./ProductChannelForm";
import { IconEdit, IconNew } from "../PrecinctChannels/PrecinctChannelsSection.styles";

function ProductChannelCard({
  channel,
  onChange,
  productChannelsLength,
  productsListCodes,
  precinctChannels,
  deletePC,
}) {
  return (
    <Grid item xs={12}>
      <AccordionItem title={`${channel.productCode} -- ${channel.channel.provider}`}>
        <TravCard>
          <TravTitleCard>
            <Grid container direction="row" justify={"space-between"} spacing={2}>
              <Grid item>
                {channel.flag === "new" && <IconNew>Nuevo</IconNew>}
                {channel.flag === "edit" && <IconEdit>Editado</IconEdit>}
              </Grid>
              <Grid style={{ marginTop: "10px" }} item>
                <ButtonDelete
                  size="small"
                  key={"btW"}
                  onClick={() => {
                    deletePC();
                  }}
                />
              </Grid>
            </Grid>
          </TravTitleCard>
          <TravBodyCard>
            <ProductChannelForm
              channel={channel}
              productsListCodes={productsListCodes}
              precinctChannels={precinctChannels}
              onChangeProductChannel={(nVal) => onChange(nVal)}
            ></ProductChannelForm>
          </TravBodyCard>
        </TravCard>
      </AccordionItem>
    </Grid>
  );
}

ProductChannelCard.propTypes = {};

export default ProductChannelCard;
