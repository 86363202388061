import axios from "axios";
import { getChannelID, langs } from "../shared/domainVariables";
// import prList from "./__test__/productList.json";

const prefix = "/api/v1/ticketing-bc/products";

export const fetchProducts = async (filters) => {
  let params = {};
  if (filters.params !== undefined) {
    params = filters.params;
  }

  const { data } = await axios.get(`${prefix}`, { params });
  return data.data;
};

export const fetchProduct = async (productCode) => {
  const { data } = await axios.get(`${prefix}/${productCode}`, {});
  return data;
};

export const saveProduct = async (precinct) => {
  await axios.put(`${prefix}/${precinct.code}`, precinct);
};

export const fetchRelevantInformationWithLang = async (productCode, lang) => {
  try {
    const response = await axios.get(`/api/v1/ticketing-bc/relevant-information/${productCode}/${lang}`);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Status != 200");
  } catch (e) {
    return [];
  }
};

export const fetchRelevantInformationWithCode = async (precinctCode, productCode) => {
  try {
    const response = await axios.get(
      `/api/v1/ticketing-bc/relevant-information?productCode=${productCode}&precinctCode=${precinctCode}`
    );
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Status != 200");
  } catch (e) {
    return [];
  }
};

export const fetchRelevantInformationListOfCodes = async (productCodes) => {
  try {
    const promises = productCodes
      .map((prcode) =>
        langs.map((lang) => `/api/v1/ticketing-bc/relevant-information?productCode=${prcode}&lang=${lang}`)
      )
      .flat();
    let responsePromises = [];
    for (let index = 0; index < promises.length; index += 1) {
      try {
        const r = await axios.get(promises[index]);
        if (r.status === 200) {
          responsePromises = [...responsePromises, ...r.data.data];
        } else {
          throw new Error("Status != 200");
        }
      } catch (e) {
        // console.error(e);
      }
    }
    return responsePromises;
  } catch (e) {
    return [];
  }
};

export const saveRelevantInformation = async (relevantInfoObject, channelId = null) => {
  if (channelId) {
    await axios.put(
      `/api/v1/ticketing-bc/relevant-information/${relevantInfoObject.productCode}/${relevantInfoObject.lang}/${channelId}`,
      relevantInfoObject
    );
  } else {
    await axios.put(
      `/api/v1/ticketing-bc/relevant-information/${relevantInfoObject.productCode}/${relevantInfoObject.lang}`,
      relevantInfoObject
    );
  }
};

export const deleteRelevantInformation = async (relevantInfoObject) => {
  if (relevantInfoObject.channel) {
    await axios.delete(
      `/api/v1/ticketing-bc/relevant-information/${relevantInfoObject.productCode}/${
        relevantInfoObject.lang
      }/${getChannelID(relevantInfoObject.channel)}`
    );
  } else {
    await axios.delete(
      `/api/v1/ticketing-bc/relevant-information/${relevantInfoObject.productCode}/${relevantInfoObject.lang}`
    );
  }
};

export const fetchStype = async () => {
  try {
    const response = await axios.get(`/api/v1/ticketing-bc/package-types`);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Status != 200");
  } catch (e) {
    return [];
  }
};

export const fetchProductChannels = async (filters) => {
  let params = {};
  if (filters.params !== undefined) {
    params = filters.params;
  }
  try {
    const { data } = await axios.get(`/api/v1/ticketing-bc/product-channels`, { params });
    return data.data;
  } catch (e) {
    return [];
  }
};

export const saveProductChannel = async (productChannel) => {
  const channelCode = Object.values(productChannel.channel).join("-");
  await axios.put(
    `/api/v1/ticketing-bc/product-channels/${productChannel.precinctCode}/${productChannel.productCode}/${channelCode}`,
    productChannel
  );
};

export const deleteProductChannel = async (productChannel) => {
  const channelCode = Object.values(productChannel.channel).join("-");
  await axios.delete(
    `/api/v1/ticketing-bc/product-channels/${productChannel.precinctCode}/${productChannel.productCode}/${channelCode}`
  );
};

// PRODUCT CONFIGURATIONS

export const fetchProductConfigurations = async (filters) => {
  let params = {};
  if (filters.params !== undefined) {
    params = filters.params;
  }
  try {
    const { data } = await axios.get(`/api/v1/ticketing-bc/product-configurations`, { params });
    return data.data;
  } catch (e) {
    return [];
  }
};

export const saveProductConfiguration = async (productConfiguration) => {
  await axios.put(
    `/api/v1/ticketing-bc/product-configurations/${productConfiguration.productCode}`,
    productConfiguration
  );
};

export const deleteProductConfiguration = async (productConfiguration) => {
  await axios.delete(
    `/api/v1/ticketing-bc/product-configurations/${productConfiguration.productCode}`,
    productConfiguration
  );
};
