import React, { Fragment, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import FxSwitch from "@atoms/Switches/FxSwitch";
import { TYPES_ACTIONS } from "../utils/types";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

function ActionAdditionalSettings(
  actionsList,
  form,
  selectParentId,
  selectActionType,
  onChangeActionForCommentWithBook
) {
  const getIndexAction = (id) => {
    return actionsList.list.reduce((acc, cv, i) => {
      if (cv.id === id) {
        acc = i;
      }
      return acc;
    }, "");
  };

  const searchParent = () => {
    return actionsList.list.filter((cv) => {
      return cv.id === form.parentId;
    });
  };

  const getParentId = () => {
    let response = false;
    if (form.parentId) {
      response = true;
    }
    return response;
  };

  return (
    <Row className="justify-content-md-center">
      <Col xs={5}>
        <div shrink id="demo-simple-select-placeholder-label-label">
          Depende de la acción
        </div>
        <FormControl variant="outlined">
          <Select
            native
            value={getIndexAction(form.parentId)}
            onChange={(evt) => selectParentId(evt.target.value)}
            label="ParentId"
            inputProps={{
              name: "ParentId",
              id: "outlined-age-native-simple",
            }}
          >
            <option aria-label="None" value="-1"></option>
            {actionsList.list.map((cv, i) => {
              if (cv.type === "group") {
                return <option value={i}>{`${TYPES_ACTIONS[cv.type]} - ${cv.title}`}</option>;
              }
            })}
          </Select>
        </FormControl>
      </Col>
      <Col xs={4}>
        <div shrink id="demo-simple-select-placeholder-label-label">
          Tipo de la acción *
        </div>
        <FormControl variant="outlined">
          <Select
            // disabled={form.parentId !== "" ? true : false}
            native
            value={form.type}
            onChange={selectActionType}
            label="Type"
            inputProps={{
              name: "Type",
              id: "outlined-age-native-simple",
            }}
          >
            <option aria-label="None" value=""></option>
            {Object.keys(TYPES_ACTIONS).map((cv, i) => {
              return <option value={cv}>{`${TYPES_ACTIONS[cv]}`}</option>;
            })}
          </Select>
        </FormControl>
      </Col>
      <Col xs={3}>
        <div shrink id="demo-simple-select-placeholder-label-label">
          Consultas con reserva
        </div>
        <FxSwitch
          disabled={getParentId()}
          value="end"
          labels={["", ""]}
          status={form.forCommentWithBook}
          onChange={onChangeActionForCommentWithBook}
        />
      </Col>
    </Row>
  );
}

export default ActionAdditionalSettings;
