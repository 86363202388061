import styled from "styled-components";
import { Alert } from "react-bootstrap";
import { Card } from "react-bootstrap";

export const ClickableTitleElements = styled.div`
  min-height: 52px;
  /* margin-bottom: 10px; */
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export const PrincipalTitleElements = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 576px) {
    display: block;
  }
`;

export const PrincipalTitleElement = styled.div`
  @media (max-width: 768px) {
    min-width: 404px;
  }
  @media (max-width: 576px) {
    text-align: center;
    margin-bottom: 20px;
    min-width: 100%;
  }
`;
export const ElementsTitleRight = styled.div`
  text-align: right;
  @media (max-width: 576px) {
    text-align: center;
  }
`;

export const ElementsTitleLeft = styled.div`
  text-align: left;
  @media (max-width: 576px) {
    text-align: center;
  }
`;

export const AlerTitle = styled(Alert)`
  border-radius: 0 !important;
  border: none;
  border-left: 7px solid !important;
`;

/* Tag title elements */

export const PrincipalTitleTag = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 576px) {
    display: block;
  }
`;

export const ElementTagLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  padding: 10px;
  margin-right: ${(props) => props.marginright};
  background-color: #c9f7f5;
  color: #1bc5bd;
`;

export const ElementTitleTag = styled.div`
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
