/* eslint-disable import/no-unresolved */
import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { UsCardTitle, LeftEnable } from "@atoms/Card/UsCard.styles";
import { ButtonTP, AlertNoAccommodation } from "./TicketProducts.styles";

function TicketsProducts({ products, productsToUse, onChange }) {
  const handleTicketsProducts = (button) => {
    let nuProducToUse = [...productsToUse];
    const value = button.target.innerText;
    if (nuProducToUse.find((element) => element === value) === undefined) {
      nuProducToUse.push(value);
    } else {
      nuProducToUse.splice(nuProducToUse.indexOf(value), 1);
    }
    onChange(nuProducToUse);
  };

  const addAllTP = () => {
    productsToUse = products;
    onChange(productsToUse);
  };

  const removeAllTP = () => {
    productsToUse = [];
    onChange(productsToUse);
  };

  const getTicketProducts = () => {
    if (products !== undefined) {
      return products.map((cv, i) => {
        return (
          <Col key={`btn-product-${i}`} xs={6}>
            <ButtonTP
              disableElevation
              color={productsToUse.find((element) => element === cv) === undefined ? " " : "primary"}
              variant={
                productsToUse.find((element) => element === cv) === undefined ? "outlined" : "contained"
              }
              onClick={(button) => {
                handleTicketsProducts(button);
              }}
            >
              {cv}
            </ButtonTP>
          </Col>
        );
      });
    }
  };

  return (
    <Fragment>
      <AlertNoAccommodation variant="info">
        <UsCardTitle>
          Productos disponibles
          <LeftEnable>
            <ButtonGroup aria-label="outlined  primary button group">
              <Button onClick={addAllTP}>
                <FiPlusSquare /> Marcar todos
              </Button>
              <Button onClick={removeAllTP}>
                <FiMinusSquare /> Quitar todos
              </Button>
            </ButtonGroup>
          </LeftEnable>
        </UsCardTitle>
        <Row className="justify-content-md-center">{getTicketProducts()}</Row>
      </AlertNoAccommodation>
    </Fragment>
  );
}

export default TicketsProducts;
