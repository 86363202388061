import { useState, useEffect } from "react";
import axios from "axios";
import {
  useNewsletter,
  useNewsletterCreate,
  useNewsletterUpdate,
  useNewsletterPublish,
  useOffers,
  useNewsletterSpamCheck,
} from "../../shared/hooks/useNewsletterThirdPartyFetch";
import { uuid_v4 } from "@lib/helpers/uuid_v4";

const NewsletterThirdPartyListState = ({ newsletterId, creation }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [newsletter, setNewsletter] = useState({
    loading: true,
    newsletter: {},
  });
  const [requestOffer, setRequestOffer] = useState(true);
  const [offers, setOffers] = useState({
    loading: true,
    offers: [],
  });
  const [score, setScore] = useState({
    pass: null,
    score: null,
    reasons: [],
  });

  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };

  const getNewsletterPreview = async (id) => {
    try {
      const response = await axios.get(`/api/v1/marketing-bc/third-party-newsletter/${id}/preview`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateNewsletter = async () => {
    setNewsletter({
      loading: true,
      newsletter: {
        market: "es",
        template: {
          config: {
            offers: [],
            topImage: {},
            bottomImage: {},
          },
        },
      },
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (!creation) {
      const { newsletterData } = await useNewsletter(newsletterId);
      // const {offfersData} = await useOffers()
      setNewsletter({
        loading: newsletterData.loading,
        newsletter: newsletterData.newsletter,
      });
    }
  };

  const getInfoOffers = async (idOffers) => {
    setOffers({
      loading: true,
      offers: [],
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { offersData } = await useOffers(idOffers);
    setOffers({
      loading: offersData.loading,
      offers: offersData.offers.map((cv) => {
        return { name: `${cv.idOffer} | ${cv.title}`, enable: cv.enable, idOffer: cv.idOffer };
      }),
    });
  };

  useEffect(() => {
    if (newsletter !== null) {
      if (newsletter.loading === false) {
        if (newsletter.newsletter.template.config.offers.length > 0) {
          if (requestOffer) {
            getInfoOffers(newsletter.newsletter.template.config.offers);
            setRequestOffer(false);
          }
        }
      }
    }
  }, [newsletter]);

  const previewNewsletterAction = async (id) => {
    const content = await getNewsletterPreview(id);
    var win = window.open(
      "",
      content.name,
      "redirection=yes,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=900,top=" +
        (screen.height - 400) +
        ",left=" +
        (screen.width - 840)
    );
    win.document.body.innerHTML = content;
    handleAlertSnackBar(true, "Preview inicializado.");
  };

  const goToList = () => {
    window.location = "/app/marketing/newsletter/third-party";
  };

  const checkTheUrlIsCorrectlyFormed = () => {
    let executeSet = false;
    const nuNewsletter = { ...newsletter };
    const topImage = newsletter.newsletter.template.config.topImage;
    const bottomImage = newsletter.newsletter.template.config.bottomImage;
    try {
      if (!topImage.image.includes("https://")) {
        executeSet = true;
        nuNewsletter.newsletter.template.config.topImage.image = "https://" + topImage.image;
      }

      if (!topImage.url.includes("https://")) {
        executeSet = true;
        nuNewsletter.newsletter.template.config.topImage.url = "https://" + topImage.url;
      }

      if (!bottomImage || (bottomImage.image === "" && bottomImage.url === "" && bottomImage.alt === "")) {
        executeSet = true;
        if (nuNewsletter.newsletter.template.config.bottomImage)
          delete nuNewsletter.newsletter.template.config.bottomImage;
      } else {
        if (!bottomImage.image.includes("https://")) {
          executeSet = true;
          nuNewsletter.newsletter.template.config.bottomImage.image = "https://" + bottomImage.image;
        }

        if (!bottomImage.url.includes("https://")) {
          executeSet = true;
          nuNewsletter.newsletter.template.config.bottomImage.url = "https://" + bottomImage.url;
        }
      }

      if (executeSet) {
        setNewsletter(nuNewsletter);
      }
    } catch (error) {
      executeSet = false;
      alert("Error en las Urls de las imagenes.");
    }
  };

  const saveNewsletter = async () => {
    try {
      checkTheUrlIsCorrectlyFormed();
      if (creation) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const uniqueValue = uuid_v4();
        const response = await useNewsletterCreate(newsletter.newsletter, uniqueValue);
        if (response.newsletterData?.status === 201) {
          handleAlertSnackBar(true, "Campaña creada con exito.");
          setTimeout(() => {
            window.location = `/app/marketing/newsletter/third-party/${uniqueValue}`;
          }, 1000);
          // goToList();
        }
      } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const response = await useNewsletterUpdate(newsletter.newsletter);
        if (response.newsletterData?.status === 204) {
          handleAlertSnackBar(true, "Camapaña actualizada con exito.");
          // goToList();
        }
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const loadScore = async () => {
    setScore({
      pass: null,
      score: null,
      reasons: [],
    });
    try {
      const { scoreData } = await useNewsletterSpamCheck(newsletterId);
      // console.log(scoreData);
      if (scoreData.status === 200) {
        setScore(scoreData.score);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const publishNewsletterAction = async () => {
    try {
      if (confirm("¿Está seguro que desea publicar esta campaña?")) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const response = await useNewsletterPublish(newsletter.newsletter.id);
        if (response.status === 204) {
          handleAlertSnackBar(true, "Camapaña publicada con exito.");
          goToList();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function loadData() {
      await updateNewsletter();
      if (!creation) {
        await loadScore();
      }
    }
    loadData();
  }, []);

  const countingWords = (value) => {
    if (value) {
      return value.length;
    }
    return 0;
  };

  return {
    isCreation: creation,
    newsletter,
    setNewsletter,
    updateNewsletter,
    goToList,
    saveNewsletter,
    previewNewsletterAction,
    publishNewsletterAction,
    alertOpen,
    setAlertOpen,
    alertData,
    offers,
    setOffers,
    countingWords,
    score,
    loadScore,
  };
};

export default NewsletterThirdPartyListState;
