import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Fab, Grid } from "@material-ui/core";
import { Skeleton, Alert, AlertTitle } from "@material-ui/lab";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import SectionTitle from "@atoms/Titles/SectionTitle";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import { fetchCheckoutRequirements, deleteCheckoutRequirement } from "./useCheckoutRequirements";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

const CheckoutRequirementsTable = ({}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [checkoutRequirementsList, setCheckoutRequirementsList] = useState([]);

  async function getCheckoutRequirements() {
    setLoading(true);

    const response = await fetchCheckoutRequirements();

    setLoading(response.loading);
    setCheckoutRequirementsList(response.list.map((requ) => ({ ...requ, markets: requ.tld.join(",") })));
  }

  const addNewRequirement = () => {
    history.push(`/app/product/checkout-requirements-services-editor/NEW`);
  };

  const editRequirements = (row) => {
    history.push(`/app/product/checkout-requirements-services-editor/${row.code}`);
  };

  const deleteRequirements = async (row) => {
    const r = confirm(`¿Seguro que quieres borrar este requisito "${row.name}"?`);
    if (r) {
      await deleteCheckoutRequirement(row.code);
      getCheckoutRequirements();
    }
  };

  useEffect(() => {
    getCheckoutRequirements();
  }, []);

  const tableHead = [
    { code: "code", name: "Codigo", type: "string" },
    { code: "name", name: "Nombre", type: "string" },
    {
      code: "enable",
      name: "Activo",
      type: "boolean",
      text: { y: "Activo", n: "Inactivo" },
    },
    { code: "tld", name: "Mercados", type: "tags" },
    { code: "pcodes", name: "Productos", type: "tags" },
  ];
  const tableActions = [
    { name: "Editar", icon: <AiFillEdit size={20} />, func: editRequirements },
    { name: "Borrar", icon: <AiFillDelete size={20} />, func: deleteRequirements },
  ];

  return loading ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ maxHeight: "72px", padding: "12px" }}>
            <Skeleton variant="circle" width={130} height={50} animation="wave" />
          </div>
        </Grid>
      </Grid>
      <Skeleton variant="rect" height={300} animation="wave" />
    </>
  ) : (
    <>
      <SectionTitle
        title={`Requerimientos de formularios con tickets`}
        buttons={[
          <ButtonAdd key={"bt1"} text={"Añadir nuevo requisito"} onClick={addNewRequirement} >Añade nuevo requisito</ButtonAdd>,
        ]}
      />
      <FenixTable
        //tableTitle={"Requerimientos de formularios con tickets"}
        tableHead={tableHead}
        tableContent={checkoutRequirementsList}
        actions={tableActions}
        pagination={true}
        sortDefault={"code"}
      />
    </>
  );
};

export default CheckoutRequirementsTable;
