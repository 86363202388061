import React from "react";
import { Row, Col } from "react-bootstrap";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import WidgetCardPhoto from "@atoms/Content/PhotoCard";
import SectionTitle from "@atoms/Titles/SectionTitle";

function BricksHub() {
  return (
    <ContainerFenix>
      <SectionTitle
        title={"Plantillas y Bricks de contenido"}
        info={`En esta sección puedes definir dos tipos de estructuras de contenido: 
        <ul>
          <li><b>Plantillas HTML</b>: Código HTML con datos genéricos y estructuras base. Servirán como ayuda para crear los bricks.</li>
          <li><b>Bricks</b>: Código HTML con datos concretos y forma definitiva (como las antiguas Piezas HTML).
        </ul>`}
        buttons={[]}
      />
      <Row>
        <Col xs={6} sm={6} md={3}>
          <WidgetCardPhoto
            title={"Templates"}
            image={"https://tr2.traventia.com/graphic-design/sprites/fenix_sections/brick_template.png"}
            linkText={"Acceder"}
            link={"/app/content/bricks/template"}
          />
        </Col>
        <Col xs={6} sm={6} md={3}>
          <WidgetCardPhoto
            title={"Bricks"}
            image={"https://tr2.traventia.com/graphic-design/sprites/fenix_sections/brick.png"}
            linkText={"Acceder"}
            link={"/app/content/bricks/brick"}
          />
        </Col>
      </Row>
    </ContainerFenix>
  );
}

export default BricksHub;
