import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { MdSave } from "react-icons/md";

function ButtonSave({ size, variant, disabled, onClick, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="Save"
      icon={<MdSave />}
      color="primary"
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonSave;
