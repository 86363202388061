import React, { useState, useEffect } from "react";

import OffersBoatList from "./OffersBoatsList";
import OfferBoatCreateConfiguration from "./OfferBoatCreateConfiguration";
import useBoatsManagement from "../../../hooks/useBoatsManagement";

const OfferBoatComponent = ({ form, handle }) => {
  const [loadingConfiguration, setLoadingConfiguration] = useState(true);
  const { destinations, routePorts, portZones, accommodationFares } = useBoatsManagement();

  useEffect(() => {
    if (form?.services?.boats?.length > 0) {
      setLoadingConfiguration(false);
    }
  }, [form.services.boats.length]);

  const getDestinationsMatch = () => {
    const allDestinationCodesByRoutes = routePorts.list.map((route) => route.destination_code);
    const portsWithRoutes = destinations.list.filter((destination) =>
      allDestinationCodesByRoutes.includes(destination.code)
    );
    const portsZonesWithRoutes = portZones.list.filter((portZone) =>
      allDestinationCodesByRoutes.includes(portZone.code)
    );
    return [...portsZonesWithRoutes, ...portsWithRoutes];
  };

  return (
    <>
      {loadingConfiguration && (
        <OfferBoatCreateConfiguration
          form={form}
          handle={handle}
          setLoadingConfiguration={setLoadingConfiguration}
          routePorts={routePorts}
          destinations={destinations}
          portZones={portZones}
        />
      )}
      {!loadingConfiguration && (
        <OffersBoatList
          form={form}
          handle={handle}
          setLoadingConfiguration={setLoadingConfiguration}
          destinations={getDestinationsMatch()}
          routePorts={routePorts}
          accommodationFares={accommodationFares}
        />
      )}
    </>
  );
};

export default OfferBoatComponent;
