/* eslint-disable no-use-before-define */
import { fetchPrecincts } from "./usePrecincts";
import { precinctTypes, contentFields } from "./constants";
import savePrecinctList from "./bulkUpload";
import difference from "../shared/differenceBetweenTwoLists";

/**
 *
 * @param {*} precinctList
 * @returns
 * saved: number, number of element send in the request after filtered
 * errors: string[], errors received during the execution
 */
const bulkUploadPrecincts = async (precinctList) => {
  //* ** Validation
  const errorsValidation = precinctListValidation(precinctList);
  if (errorsValidation.length > 0) {
    return { errors: errorsValidation };
  }

  //* ** Get precincts saved
  let currentPrecincts = [];
  try {
    currentPrecincts = await fetchPrecincts({});
  } catch (e) {
    return { errors: [e.message] };
  }

  //* ** Take channels out for separate insertion
  // const precinctListWithoutChannels = JSON.parse(JSON.stringify(precinctList));
  // precinctListWithoutChannels.forEach((precinct) => delete precinct.channels);

  //* ** Get the intersection between the two
  const { toInsert, toUpdate } = difference(currentPrecincts, precinctList, false, contentFields);

  //* ** Check the channels for changes
  // const { channelsToAdd, channelsToDelete } = await differenceChannels(precinctList);

  //* ** Save the results
  const { saved, errors } = await savePrecinctList([...toInsert, ...toUpdate]);

  //* ** Save channels
  // const { errorsChannels } = await requestsForChannels(channelsToAdd, channelsToDelete);

  return {
    saved,
    errors: [
      ...errors,
      /* , ...errorsChannels */
    ],
  };
};

const precinctListValidation = (precinctList) => {
  const ncodes = [];
  const errors = [];
  precinctList.forEach((precinct, index) => {
    const rowId = index + 2;

    try {
      precinctValidation(precinct);
    } catch (e) {
      errors.push(`Fila ${rowId}: ${e.message}`);
    }

    if (ncodes.includes(precinct.code)) {
      errors.push(`Fila ${rowId}: Codigo <${precinct.code}> duplicado`);
    } else {
      ncodes.push(precinct.code);
    }
  });

  return errors;
};

const precinctValidation = (precinct) => {
  // Mandatory
  ["code", "countryCode", "type", "tax" /* , "channels" */].forEach((field) => {
    if (precinct[field] === undefined) {
      throw new Error(`Field <${field}> is required`);
    }
  });

  // Not Empty
  ["code", "countryCode", "type"].forEach((field) => {
    if (precinct[field].trim() === "") {
      throw new Error(`Field <${field}> is empty`);
    }
  });

  // if (precinct.channels.length === 0) {
  //   throw new Error(`${precinct.code}: Field <channels> is empty`);
  // }
  if (!precinctTypes.includes(precinct.type.trim())) {
    throw new Error(`${precinct.code}: Tipo <${precinct.type}> es desconocido.`);
  }
};

export default bulkUploadPrecincts;
