import React from "react";

export const FenixAuthContext = React.createContext();

const getDomain = () => {
  const doms = {
    "fenix.traventia.es": "es",
    "fenix.traventia.pt": "pt",
    "fenix.traventia.fr": "fr",
    "fenix.traventia.it": "it",
    "fenix.stg1.traventia.es": "es",
    "fenix.stg1.traventia.pt": "pt",
    "fenix.stg1.traventia.fr": "fr",
    "fenix.stg1.traventia.it": "it",
    //localhost: "es",
  };
  return doms[window.location.hostname] !== undefined ? doms[window.location.hostname] : "com";
};

function getCookieValue(a) {
  var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

const initialState = { isAuth: false, user: null, domain: getDomain() };

function reducer(state, action) {
  switch (action.type) {
    case "login":
      return { ...state, ...{ user: action.user, isAuth: true } };
    case "logout": {
      const copyState = {};
      return { ...copyState };
    }
    default:
      return state;
  }
}

export function FenixAuthContextProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = [state, dispatch];
  return <FenixAuthContext.Provider value={value}>{props.children}</FenixAuthContext.Provider>;
}

export const useFenixAuthContext = () => {
  const context = React.useContext(FenixAuthContext);
  if (context === undefined) {
    throw new Error("useFenixAuthContext must be used within a FenixAuthContext");
  }
  return context;
};

export const isAuthorized = () => {
  const [state] = useFenixAuthContext();
  return state.isAuth;
};

export const userAuthorized = () => {
  const [state] = useFenixAuthContext();
  return state.user;
};

export const useDomainContext = () => {
  const [state] = useFenixAuthContext();
  return state.domain;
};
