import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { MdCheckCircle } from "react-icons/md";

function ButtonCheck({ size, variant, disabled, onClick, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="check"
      icon={<MdCheckCircle />}
      color="secondary"
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonCheck;
