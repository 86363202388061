import styled from "styled-components";
import { Dropdown } from "react-bootstrap";

export const ContInputQuery = styled.div`
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  width: 100%;
`;

export const DwnInputQuery = styled(Dropdown.Menu)`
  top: 100% !important;
  width: 100%;
  @media (max-width: 576px) {
    right: 0 !important;
    left: auto !important;
    width: 190%;
  }
`;
