import axios from "axios";
const ACCOMMODATIONS_PROVIDERS_URL = "/api/v1/moebius-bc/providers/accommodations";

export const accommodationProviderStatus = async (accommodationProvider, operation, user) => {
  let response = {};
  try {
    if (operation === "enable") {
      response = await axios.post(`${ACCOMMODATIONS_PROVIDERS_URL}/enable`, {
        ...accommodationProvider,
        user,
      });
    } else {
      response = await axios.post(`${ACCOMMODATIONS_PROVIDERS_URL}/disable`, {
        ...accommodationProvider,
        user,
      });
    }
    response.ok = true;
  } catch (error) {
    response.ok = false;
    response.error = error.message;
  }
  return response;
};

export const fetchAccommodationsProviders = async () => {
  try {
    const response = await axios.get(`${ACCOMMODATIONS_PROVIDERS_URL}?_sort=provider&_sort=subprovider`);
    let accommodationsProviders = [];
    if (response.status === 200) {
      accommodationsProviders = response.data.data;
    }
    return { loading: false, accommodationsProviders: accommodationsProviders };
  } catch (error) {
    console.error(error);
    return { loading: false, accommodationsProviders: [] };
  }
};

export const fetchAccommodationProviderByProvider = async (provider) => {
  try {
    const response = await axios.get(
      `${ACCOMMODATIONS_PROVIDERS_URL}?provider=${provider}&_sort=provider&_sort=subprovider}`
    );
    let accommodationsProviders = [];
    if (response.status === 200) {
      accommodationsProviders = response.data.data;
    }
    return { loading: false, accommodationsProviders: accommodationsProviders };
  } catch (error) {
    console.error(error);
    return { loading: false, accommodationsProviders: [] };
  }
};

export const fetchAccommodationProviderByProviderAndSubprovider = async (provider, subprovider) => {
  try {
    const response = await axios.get(
      `${ACCOMMODATIONS_PROVIDERS_URL}?provider=${provider}&subprovider=${subprovider}&_sort=provider&_sort=subprovider}`
    );
    let accommodationsProviders = [];
    if (response.status === 200) {
      accommodationsProviders = response.data.data;
    }
    return { loading: false, accommodationsProviders: accommodationsProviders };
  } catch (error) {
    console.error(error);
    return { loading: false, accommodationsProviders: [] };
  }
};
