import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import SectionTitle from "@atoms/Titles/SectionTitle";
import BankAccountList from "../components/BankAccountList";

const BackAccountValidationPage = () => {
  return (
    <ContainerFenix>
      <SectionTitle
        title={"💳 Validación de cuentas bancarias"}
        info={[
          "Valida las cuentas bancarias de tus proveedores mediante una validación segura en dos pasos para poder realizar pagos a través de Fenix.",
        ]}
      />

      <BankAccountList />
    </ContainerFenix>
  );
};

export default BackAccountValidationPage;
