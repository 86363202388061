/* eslint-disable import/no-unresolved */
import React, { Fragment } from "react";
import FxDateTimePicker from "@atoms/Pickers/FxDateTimePicker";
import FxSwitch from "@atoms/Switches/FxSwitch";
import { Row, Col, Card } from "react-bootstrap";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { UsCardTitle, LeftEnable } from "@atoms/Card/UsCard.styles";

function FlashOfferConfiguration({ form, onChange }) {
  const handleDateTime = (newDateTime) => {
    const date = new Date(newDateTime);
    form.flash.datetime = date.toISOString();
    onChange({ ...form });
  };

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <UsCardTitle>
            <span>Oferta Flash</span>
            <LeftEnable>
              <ButtonGroup aria-label="outlined  primary button group">
                <FxSwitch
                  labels={["Oferta flash", "Oferta flash"]}
                  status={form.flash.enable}
                  onChange={(nuValue) => {
                    form.flash.enable = nuValue;
                    onChange({ ...form });
                  }}
                />
              </ButtonGroup>
            </LeftEnable>
          </UsCardTitle>
          <Row>
            <br />
            {form.flash.enable && (
              <Fragment>
                <Col xs={12}>
                  <FxDateTimePicker
                    label={"Fecha Oferta flash"}
                    dateInitial={form.flash.datetime}
                    onChange={(nuDateTime) => {
                      handleDateTime(nuDateTime);
                    }}
                  />
                </Col>
              </Fragment>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default FlashOfferConfiguration;
