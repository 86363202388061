import generateNanoId from "@lib/helpers/nanoid";

const PREFIX = "TCK";
const CHARACTERS = "1234567890ABCDEFGHIJKLMNOPQRSTUWXYZ";
const CODELENGTH = 9;

function generateTicketId() {
  const code = generateNanoId(CHARACTERS, CODELENGTH);

  return `${PREFIX}${code}`;
}

export default generateTicketId;
