/* eslint-disable no-use-before-define */
import { tldXLangMatch, returnModelArrayFromContentIdentificator } from "./domainVariables";

const langXtld = Object.keys(tldXLangMatch).reduce(
  (accu, tld) => ({ ...accu, [tldXLangMatch[tld]]: tld }),
  {}
);

function reorganizeListIntoCsvContent(list, contentFields, identifierField = "code") {
  return list.reduce((csv, item) => {
    return [
      ...csv,
      ...extractCsvLinesFromItemOfList(contentFields, item, identifierField, item[identifierField]),
    ];
  }, []);
}

function extractCsvLinesFromItemOfList(contentFields, item, identifierField, itemCode) {
  let csv = [];
  Object.keys(contentFields).forEach((field) => {
    if (typeof contentFields[field] === "string") {
      const modelArray = returnModelArrayFromContentIdentificator(contentFields[field]);

      const newCsvLines = modelArray.map((k) => {
        const key = contentFields[field] === "lang" ? langXtld[k] : k;

        return {
          [identifierField]: itemCode,
          field,
          tld: key,
          value: item[field][k],
        };
      });

      csv = [...csv, ...newCsvLines];
    } else {
      csv = [
        ...csv,
        ...extractCsvLinesFromItemOfList(contentFields[field], item[field], identifierField, itemCode),
      ];
    }
  });
  return csv;
}

export default reorganizeListIntoCsvContent;
