import React from "react";
import { MdOutlineDelete } from "react-icons/md";
import useAffiliateActions from "../hooks/useAffiliateActions";
import { MdEdit } from "react-icons/md";

const AffiliatesListActions = ({ setAffiliateToManage, updateAffiliatesList, deleteAffiliate }) => {
  const { manageAffiliateAction, deleteAffiliateAction } = useAffiliateActions({
    setAffiliateToManage,
    deleteAffiliate,
  });

  const managementAction = {
    code: "management",
    name: "Editar",
    icon: <MdEdit />,
    func: manageAffiliateAction,
  };
  const deleteAction = {
    code: "delete",
    name: "Eliminar",
    icon: <MdOutlineDelete />,
    func: deleteAffiliateAction(updateAffiliatesList),
  };

  const affiliateActions = [managementAction, deleteAction];

  return {
    affiliateActions,
  };
};

export default AffiliatesListActions;
