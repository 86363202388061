import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import moment from "moment";
import { DatePicker } from "@material-ui/pickers";

function FxDatePicker({ label, dateInitial, onChange, minDate, disabled }) {
  const format = "YYYY-MM-DD";

  const [pickerDate, setPickerDate] = useState(dateInitial);
  useEffect(() => {
    setPickerDate(dateInitial);
  }, [dateInitial]);
  /**
   *
   * @param {*} momentSelectedDate
   */
  const changePickerDate = (momentSelectedDate) => {
    const nuDate = momentSelectedDate.format(format);
    setPickerDate(nuDate);
    onChange(nuDate);
  };

  return (
    <DatePicker
      fullWidth
      label={label}
      value={pickerDate}
      onChange={changePickerDate}
      animateYearScrolling={false}
      allowKeyboardControl={true}
      autoOk={true}
      disabled={disabled}
      format={"DD/MM/YYYY"}
      minDate={minDate ? minDate : new Date("01-01-1900")}
    />
  );
}

FxDatePicker.propTypes = {
  label: PropTypes.string,
  dateInitial: PropTypes.string,
  onChange: PropTypes.func,
};

export default FxDatePicker;
