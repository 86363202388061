import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import CityTaxPage from "../../../5-pages/Product/Accommodations/CityTaxPage";
import CityTaxEditorPage from "../../../5-pages/Product/Accommodations/CityTaxEditorPage";
import SuppliersHyperguestPage from "../../../5-pages/Product/Accommodations/SuppliersHyperguestPage";

function RoutesAccommodations({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/accommodations`;
  return (
    <Fragment>
      <Route exac path={`${mainUrl}/citytax`} component={CityTaxPage} />
      <Route exac path={`${mainUrl}/citytax-editor/:cityTaxCode`} component={CityTaxEditorPage} />
      <Route exac path={`${mainUrl}/suppliersHyperguest`} component={SuppliersHyperguestPage} />
    </Fragment>
  );
}

export default RoutesAccommodations;
