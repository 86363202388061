import { useEffect, useState } from "react";
import axios from "axios";

const urlRappelsProductBc = "/api/v1/product-bc/rappel";

// eslint-disable-next-line import/prefer-default-export
export const useRappels = () => {
  const [state, setState] = useState({ loading: true, list: [] });

  const fetchData = async () => {
    try {
      let thestate = { list: [], loading: false };
      const response = await axios.get(`${urlRappelsProductBc}?status=ACTIVE`);
      if (response.status === 200 && response.data) {
        thestate = { list: response.data.data, loading: false };
      }

      setState(thestate);
    } catch (error) {
      setState({ list: [], loading: false });
      throw error;
    }
  };

  const deleteRappel = async (idJsonPage) => {
    try {
      await axios.delete(`${urlRappelsProductBc}/${idJsonPage}`);
      await fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const putRappel = async (jsonPage) => {
    let response = {};
    try {
      const newJsonPage = { ...jsonPage };
      response = await axios.put(`${urlRappelsProductBc}/${newJsonPage.id}`, newJsonPage);
      response.ok = true;
      await fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (state.loading) {
      fetchData();
    }
  }, [state.loading, state.list]);

  return {
    fetchData,
    deleteRappel,
    putRappel,
    loading: state.loading,
    list: state.list,
  };
};
