import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
// Atoms
import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import ButtonEdit from "@atoms/Buttons/ButtonEdit";
import ButtonReset from "@atoms/Buttons/ButtonReset";

import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import PrecinctSectionForm from "./PrecinctSectionForm";

function PrecinctSection({ precinct, loadingPrecinct, formPrecinct, onChange, onChangeDialog }) {
  return (
    <Fragment>
      <TravCard key={"full_form_edit_precinct"}>
        <TravTitleCard>
          <Grid container spacing={2} alignItems={"flex-start"} justify={"flex-end"} direction="row">
            <Grid item xs={12}></Grid>
            {formPrecinct.enable ? (
              <Fragment>
                <Grid item>
                  <ButtonReset
                    size={"small"}
                    key={"btW"}
                    onClick={() => {
                      onChange({ enable: !formPrecinct.enable, form: { ...precinct } });
                    }}
                  >
                    Restablecer
                  </ButtonReset>
                </Grid>
                <Grid item>
                  <ButtonSave
                    size={"small"}
                    key={"btW"}
                    onClick={() => {
                      onChangeDialog();
                    }}
                  >
                    Guardar
                  </ButtonSave>
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid item>
                  <ButtonEdit
                    size="small"
                    key={"btW"}
                    onClick={() => {
                      onChange({ enable: !formPrecinct.enable, form: formPrecinct.form });
                    }}
                  >
                    Editar
                  </ButtonEdit>
                </Grid>
              </Fragment>
            )}
            <Grid item></Grid>
          </Grid>
        </TravTitleCard>
        <TravBodyCard>
          {loadingPrecinct ? (
            <CenterLoaderCircle />
          ) : (
            <PrecinctSectionForm
              precinct={precinct}
              formPrecinct={formPrecinct}
              onChange={(nuFormPrecinct) => {
                onChange(nuFormPrecinct);
              }}
            />
          )}
        </TravBodyCard>
      </TravCard>
    </Fragment>
  );
}

PrecinctSection.propTypes = {};

export default PrecinctSection;
