import React, { Fragment, useState } from "react";
import TextField from "@material-ui/core/TextField";
import DraggableList from "@atoms/Lists/DraggableList";
import { UsCardTitle } from "@atoms/Card/UsCard.styles";
import { ButtonImg, Check, Cross, Plus, ContListEditable, InputListEditable } from "./ListEditable.styles";
import FxAlert from "@atoms/Alerts/FxAlert";

// typeInfo = tick || delete
function ListEditable({
  title = "",
  titleLabel,
  info,
  typeInfo = "tick",
  onChangeList,
  validation = null,
  msgError = "Contiene errores",
  titleSmall = false,
}) {
  const infoList = info;
  const [showPlus, setShowPlus] = useState(false);
  const [error, setError] = useState(false);
  const [valueInput, setValueInput] = useState("");
  const addValueToList = () => {
    if (valueInput !== "") {
      if (validation !== null && !validation(valueInput)) {
        setError(true);
        setValueInput(`${valueInput} - ${msgError}`);
        return null;
      }
      const infoListAux = [...infoList];
      infoListAux.push(valueInput);
      onChangeList(infoListAux);
      setValueInput("");
      setShowPlus(false);
    }
  };

  const list = infoList.map((d) => {
    return [d];
  });

  const onChangeListLocal = (newList) => {
    onChangeList(newList.map((el) => el[0]));
  };

  const showLess = () => {
    setShowPlus(false);
  };

  return (
    <Fragment>
      {(titleSmall && <span>{title || ""}</span>) || (
        <UsCardTitle>
          <span>{title || ""}</span>
        </UsCardTitle>
      )}
      <ContListEditable>
        <InputListEditable>
          <TextField
            error={error}
            value={valueInput}
            size="small"
            id="outlined-basic"
            label={titleLabel}
            variant="outlined"
            onKeyPress={(e) => e.key === "Enter" && addValueToList()}
            onChange={(evt) => {
              setError(false);
              setValueInput(evt.target.value);
            }}
          />
        </InputListEditable>

        <ButtonImg color="secondary" onClick={addValueToList} variant="contained" disableElevation>
          <Check />
        </ButtonImg>
        {/* <ButtonImg onClick={showLess} variant="contained" disableElevation>
          <Cross />
        </ButtonImg> */}
      </ContListEditable>

      <DraggableList title={title} list={list} onChangeList={onChangeListLocal} />
    </Fragment>
  );
}

export default ListEditable;
