import React from "react";

export const FenixUiContext = React.createContext();

const initialState = {};

function reducer(state, action) {
  switch (action.type) {
    case "write":
      return { ...state, [action.parent]: action.payload };
    case "delete": {
      const copyState = state;
      delete copyState[action.parent];
      return { ...copyState };
    }
    default:
      return state;
  }
}

export function FenixUiContextProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = [state, dispatch];
  return <FenixUiContext.Provider value={value}>{props.children}</FenixUiContext.Provider>;
}

export const useFenixUiContext = () => {
  const context = React.useContext(FenixUiContext);
  if (context === undefined) {
    throw new Error("useFenixUiContext must be used within a FenixUiContext");
  }
  return context;
};
