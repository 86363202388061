/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
import {
  savePrecinctCalendar,
  saveProductCalendar,
  saveTicketCalendar,
  saveSessionCalendar,
  saveTicketSessionCalendar,
} from "./daoCalendars";
import checkUnpairedChannels from "./checkUnpairedChannels";

/**
 *
 * @param {*} calendarListToSave List of {type:(precinct|product|ticket|session|ticketSession), calendarEntry: data to save}
 * @returns
 */
async function saveCalendars(calendarListToSave) {
  const errors = [];
  const saved = { precincts: 0, products: 0, tickets: 0, sessions: 0, ticketSessions: 0 };
  const total = { precincts: 0, products: 0, tickets: 0, sessions: 0, ticketSessions: 0 };

  const duplicatedDates = checkDuplicatedDates(calendarListToSave);
  if (duplicatedDates.length > 0) {
    return { saved, total: calendarListToSave.length, errors: duplicatedDates };
  }

  // TODO: hay que comprobar en esta funcion que el producto, cuando no tiene channel, mire el del recinto
  // const unpaired = await checkUnpairedChannels(calendarListToSave);
  // if (unpaired.length > 0) {
  //   return { saved, total: calendarListToSave.length, errors: unpaired };
  // }

  for (let indexSave = 0; indexSave < calendarListToSave.length; indexSave += 1) {
    const { type, calendarEntry } = calendarListToSave[indexSave];
    const { year } = calendarEntry;

    if (type === "precinct") {
      total.precincts += 1;
      try {
        await savePrecinctCalendar(calendarEntry);
        saved.precincts += 1;
      } catch (e) {
        errors.push(getRequestError(type, e, [year, calendarEntry.precinctCode].join("#")));
      }
    }

    if (type === "product") {
      total.products += 1;
      try {
        await saveProductCalendar(calendarEntry);
        saved.products += 1;
      } catch (e) {
        errors.push(getRequestError(type, e, [year, calendarEntry.productCode].join("#")));
      }
    }

    if (type === "ticket") {
      total.tickets += 1;
      try {
        await saveTicketCalendar(calendarEntry);
        saved.tickets += 1;
      } catch (e) {
        errors.push(getRequestError(type, e, [year, calendarEntry.ticketId].join("#")));
      }
    }

    if (type === "session") {
      total.sessions += 1;
      try {
        await saveSessionCalendar(calendarEntry);
        saved.sessions += 1;
      } catch (e) {
        errors.push(getRequestError(type, e, [year, calendarEntry.ticketId].join("#")));
      }
    }

    if (type === "ticketSession") {
      total.ticketSessions += 1;
      try {
        await saveTicketSessionCalendar(calendarEntry);
        saved.ticketSessions += 1;
      } catch (e) {
        errors.push(getRequestError(type, e, [year, calendarEntry.ticketId].join("#")));
      }
    }
  }

  return { saved, total: calendarListToSave.length, total_breakdown: total, errors };
}

function checkDuplicatedDates(calendarListToSave) {
  return calendarListToSave
    .filter(
      (c) =>
        new Set(c.calendarEntry.calendar.map((item) => item.date)).size !== c.calendarEntry.calendar.length
    )
    .map(
      (c) =>
        `Error al guardar calendario ${[
          c.type,
          c.calendarEntry.year,
          c.calendarEntry.precinctCode,
          c.calendarEntry.productCode || "",
          c.calendarEntry.ticketId || "",
        ].join("#")}: valores de fecha duplicados.`
    );
}

function getRequestError(type, errorReceived, calendarId) {
  const message = [`Error al guardar calendario de ${type} ${calendarId}:`];

  if (errorReceived.response?.request?.responseText !== undefined) {
    const responseText = JSON.parse(errorReceived.response.request.responseText);
    if (responseText.errors !== undefined) {
      if (responseText.errors.length > 0) {
        responseText.errors.forEach((e) => message.push(e.error));
      }
    } else {
      message.push(responseText.error);
    }
  } else {
    message.push(errorReceived.message);
  }

  return message.join("\n");
}

export default saveCalendars;
