import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";

//Components & hooks
import FileSearcher from "../../components/FileSearcher/FileSearcher";
import FileSearcherResults from "../../components/FileSearcher/FileSearcherResults";
import FileBreadcrumbs from "../../components/FileBreadcrumbs/FileBreadcrumbs";
import { FileDirectory } from "../../components/FileDirectory";
import FileManagerTitle from "../../components/FileManagerTitle/FileManagerTitle";
import FileFolderContent from "../../components/FileFolderContent/FileFolderContent";

//Styles
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import { FileManagerContextProvider } from "../../FileManagerContext";

const FileManagerPage = ({ bucket }) => {
  const [isSearchMode, setSearchMode] = useState(false);

  //Check path
  const { search } = useLocation();
  const path = new URLSearchParams(search).get("path");

  const contextProps = { bucket };
  if (path !== null) {
    contextProps.path = path;
  }

  return (
    <FileManagerContextProvider {...contextProps}>
      <ContainerFenix>
        <FileManagerTitle bucket={bucket} />

        <Grid spacing={3} container>
          <Grid item xs={12}>
            <FileSearcher
              onExecuteSearch={({ show }) => {
                setSearchMode(show);
              }}
            />
          </Grid>
        </Grid>

        {/* Search Results */}
        {isSearchMode && (
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <FileSearcherResults
                onCloseResults={() => {
                  setSearchMode(false);
                }}
              />
            </Grid>
          </Grid>
        )}
        <br />
        {/* Directory Navigation */}
        {!isSearchMode && (
          <Fragment>
            <Grid spacing={1} container>
              <Grid item xs={12}>
                <FileBreadcrumbs />
              </Grid>
            </Grid>
            <Grid spacing={1} container>
              <Grid item xs={2}>
                <FileDirectory />
              </Grid>
              <Grid item xs={10}>
                <FileFolderContent />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </ContainerFenix>
    </FileManagerContextProvider>
  );
};

export default FileManagerPage;
