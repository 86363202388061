import React, { useState, useEffect } from "react";
import FenixTable from "@molecules/Tables/FenixTable";
import { MdPaid, MdAssignment } from "react-icons/md";
import CustomModal from "@molecules/Modal/CustomModal";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import RemittancesApi from "../api/RemittancesApi";
import useRemittances from "./useRemittances";
import RemittancePaymentModal from "./RemittancePaymentModal";

const RemittanceInProgress = () => {
  const [remittances, setRemittances] = useState([]);
  const [loading, setLoading] = useState(true);

  // Show invoices modal
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  // Confirm payment modal
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [remittanceSelected, setRemittanceSelected] = useState(null);

  const { tableHeadRemittances, parseRemittanceResponse } = useRemittances();

  const tableHeadPayments = [
    { code: "id", name: "ID", type: "string" },
    { code: "supplier", name: "Supplier", type: "string" },
    { code: "amount", name: "Importe (€)", type: "money" },
    { code: "ninvoices", name: "Nº facturas", type: "string" },
  ];

  useEffect(() => {
    async function getRemittances() {
      const remittancesResp = await RemittancesApi.getRemittancesInProgress();
      setRemittances(parseRemittanceResponse(remittancesResp));
      setLoading(false);
    }

    getRemittances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function seeInvoices(row) {
    setModalData(row);
    setOpenModal(true);
  }

  async function markAsPaid(row) {
    setRemittanceSelected(row);
    setOpenConfirmation(true);
  }

  return (
    <>
      <FenixTable
        loading={loading}
        tableTitle={"Remesas en progreso"}
        tableHead={tableHeadRemittances}
        tableContent={remittances}
        actions={[
          { name: "Ver pagos", icon: <MdAssignment size={30} />, func: seeInvoices },
          { name: "Marcar como pagada", icon: <MdPaid size={30} />, func: markAsPaid },
        ]}
      />
      <CustomModal open={openModal} bigModal customWidthModal={1000}>
        {modalData && (
          <>
            <FenixTable
              tableTitle={`Pagos de la remesa ${modalData.id}`}
              tableHead={tableHeadPayments}
              tableContent={modalData.payments}
              pagination
            />
            <ButtonCustom onClick={() => setOpenModal(false)}>Cerrar</ButtonCustom>
          </>
        )}
      </CustomModal>

      <RemittancePaymentModal
        open={openConfirmation}
        remittance={remittanceSelected}
        onClose={() => setOpenConfirmation(false)}
      />
    </>
  );
};

export default RemittanceInProgress;
