import React, { useState } from "react";
// import PropTypes from "prop-types";
import FxAlert from "@atoms/Alerts/FxAlert";

function UploadResultAlert({ uploadResult }) {
  return (
    <FxAlert variant={uploadResult.status}>
      <>
        <p>{`Resultado de subida de archivo csv de ${uploadResult.total} filas:`}</p>
        {uploadResult.saved && (
          <p>{`Se han guardado ${uploadResult.saved} item(s) donde se han encontrado cambios.`}</p>
        )}
        {uploadResult.errors.length > 0 && (
          <>
            <p>
              <b>Errores encontrados:</b>
            </p>
            <ul>
              {uploadResult.errors.map((error, index) => (
                <li key={`error${index}`}>{error}</li>
              ))}
            </ul>
          </>
        )}
      </>
    </FxAlert>
  );
}

UploadResultAlert.propTypes = {};

export default UploadResultAlert;
