import { useState, useEffect } from "react";
import axios from "axios";

import {
  useAffiliatesManagement,
  useSaveAffiliate,
  useDeleteAffiliate,
} from "../../shared/hooks/useAffiliatesFetch";
import { uuid_v4 } from "@lib/helpers/uuid_v4";
// import AffiliatesManagementActions from "./AffiliatesManagement.actions";

const AffiliatesManagementState = ({ creation, affiliateId }) => {
  const [affiliate, setAffiliate] = useState({
    loading: false,
    data: {
      id: affiliateId,
    },
  });

  const [modsToAdd, setModsToAdd] = useState([]);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };

  const updateAffiliate = async () => {
    setAffiliate({
      loading: true,
      data: {
        id: "",
      },
    });
    if (creation === true) {
      setAffiliate({
        loading: false,
        data: {
          id: "",
          enable: false,
          type: "referal",
          referralConf: {
            cookieDaysRetention: null,
          },
        },
      });
      return;
    }
    try {
      const { affiliateData } = await useAffiliatesManagement(affiliateId);
      setAffiliate({
        loading: affiliateData.loading,
        data: affiliateData.affiliate,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const saveAffiliate = async () => {
    const newAffiliate = { ...affiliate.data };
    const validations = [];
    if (!newAffiliate.id) {
      validations.push("El campo Id es obligatorio.");
    }
    if (newAffiliate.id.trim() === "") {
      validations.push("El campo Id no puede estar vacío.");
    }
    if (newAffiliate.type === "whitelabel") {
      if (!newAffiliate.whitelabelConf) {
        validations.push("El campo configuración de marca blanca es obligatorio.");
      }
      if (!newAffiliate.whitelabelConf.domain) {
        validations.push("El campo dominio es obligatorio.");
      }
      if (newAffiliate.whitelabelConf.domain.trim() === "") {
        validations.push("El campo dominio no puede estar vacío.");
      }
      if (!newAffiliate.whitelabelConf.defLang) {
        validations.push("El campo idioma por defecto es obligatorio.");
      }
    }
    if (newAffiliate.type === "referal") {
      if (!newAffiliate.referralConf) {
        validations.push("El campo configuración es obligatorio.");
      }
      if (!newAffiliate.referralConf.cookieDaysRetention) {
        validations.push("El campo días de retención es obligatorio.");
      }
      if (newAffiliate.referralConf.cookieDaysRetention < 0) {
        validations.push("El campo días de retención no puede ser negativo.");
      }
    }
    if (newAffiliate.cpa) {
      if (newAffiliate.cpa.defaultNumber === null || newAffiliate.cpa.defaultNumber === undefined) {
        validations.push("El campo número de CPA por defecto es obligatorio.");
      }
      if (newAffiliate.cpa.defaultNumber < 0) {
        validations.push("El campo número de CPA por defecto no puede ser negativo.");
      }
      if (newAffiliate.cpa.defaultNumber > 100) {
        validations.push("El campo número de CPA por defecto no puede ser mayor que 100.");
      }
      if (!newAffiliate.cpa.applyInPrice) {
        validations.push("Debes seleccionar un tipo de precio sobre el que aplicar el CPA por defecto.");
      }
      if (newAffiliate.cpa.modifications.length > 0 || modsToAdd.length > 0) {
        const modifications = [...newAffiliate.cpa.modifications, ...modsToAdd];
        for (const mod in modifications) {
          if (modifications[mod].cpa === null || modifications[mod].cpa === undefined) {
            validations.push(`El campo CPA de la modificación ${parseInt(mod) + 1} es obligatorio.`);
          }
          if (modifications[mod].cpa < 0) {
            validations.push(`El campo CPA de la modificación ${parseInt(mod) + 1} no puede ser negativo.`);
          }
          if (modifications[mod].cpa > 100) {
            validations.push(
              `El campo CPA de la modificación ${parseInt(mod) + 1} no puede ser mayor que 100.`
            );
          }
          if (!modifications[mod].code) {
            validations.push(`El campo código de la modificación ${parseInt(mod) + 1} es obligatorio.`);
          }
          if (modifications[mod].code.trim() === "") {
            validations.push(`El campo código de la modificación ${parseInt(mod) + 1} no puede estar vacío.`);
          }
          if (!modifications[mod].applyInPrice || modifications[mod].applyInPrice === "-") {
            validations.push(
              `Debes seleccionar un tipo de precio sobre el que aplicar el CPA de la modificación ${
                parseInt(mod) + 1
              }.`
            );
          }
        }
      }
    } else {
      validations.push("El campo CPA es obligatorio.");
    }

    if (validations.length > 0) {
      setValidationErrors(validations);
      return null;
    }

    try {
      const id = creation === true ? uuid_v4() : affiliateId;
      if (modsToAdd.length > 0) {
        newAffiliate.cpa.modifications.push(...modsToAdd);
      }
      newAffiliate.affiliateId = id;
      await useSaveAffiliate(id, newAffiliate);
      return id;
    } catch (error) {
      console.log(error);
      setAlertData("Ha ocurrido un error al guardar el afiliado.");
      setAlertOpen(true);
      return null;
    }
  };

  const deleteAffiliate = async () => {
    try {
      await useDeleteAffiliate(affiliateId);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    async function loadData() {
      await updateAffiliate();
    }
    loadData();
  }, []);

  return {
    affiliate,
    creation,
    setAffiliate,
    affiliateId,
    modsToAdd,
    setModsToAdd,
    saveAffiliate,
    deleteAffiliate,
    alertOpen,
    alertData,
    setAlertOpen,
    handleAlertSnackBar,
    validationErrors,
    setValidationErrors,
  };
};

export default AffiliatesManagementState;
