import styled from "styled-components";
import { Button } from "react-bootstrap";

export const ButtonInline = styled(Button)`
  margin-bottom: 10px;
  margin-left: 5px;
`;

export const ButtonAdd = styled(ButtonInline)`
  background-color: #006699;
  color: #fff;
  border-color: #006699;
  &:hover {
    background-color: #0a4c6d;
    border-color: #0a4c6d;
    color: #fff;
  }
`;

export const ButtonWarning = styled(ButtonInline)`
  background-color: #cc0909;
  color: #fff;
  border-color: #cc0909;
  &:hover {
    background-color: #960a0a;
    border-color: #960a0a;
    color: #fff;
  }
`;

export const ButtonConfirm = styled(ButtonInline)`
  background-color: #0eb256;
  color: #fff;
  border-color: #0fcf51;
  &:hover {
    background-color: #0b9b4a;
    border-color: #0fb548;
    color: #fff;
  }
`;
export const ButtonDefautl = styled(ButtonInline)`
  background-color: #f2f2f2;
  color: #000;
  border-color: #ddd;
  &:hover {
    background-color: #ddd;
    border-color: #ddd;
    color: #000;
  }
  &:active {
    color: #000 !important;
    background-color: #ddd !important;
    border-color: #ddd !important;
  }
  &:focus {
    box-shadow: none !important;
  }
`;

export const ButtonDownload = styled(ButtonAdd)`
  background-color: #17a2b8;
  border-color: #17a2b8;
  &:hover {
    background-color: #138496;
    border-color: #138496;
  }
`;

export const ButtonUpload = styled(ButtonAdd)`
  background-color: #ffffff;
  border-color: #000000;
  color: black;
  &:hover {
    background-color: lightgrey;
    border-color: darkgrey;
    color: black;
  }
`;

export const ButtonTotalWidht = styled(ButtonAdd)`
  width: 100%;
  margin: 0;
`;
