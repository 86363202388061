import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import Money from "@atoms/Money/Money";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import InputBookref from "@atoms/Inputs/InputBookref";
import CustomModal from "@molecules/Modal/CustomModal";
import { getBookWithBookref, assingBankTransferToBook } from "./useBankTransfers";

const BankTransfersModalAssign = ({ banktransfer, open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState();

  const selectBookref = async (newBook) => {
    const newBookref = newBook.bookref;
    const bookWithBr = await getBookWithBookref(newBookref);
    if (bookWithBr !== undefined) {
      setBook(bookWithBr);
    }
  };

  const deselectBook = () => {
    setBook();
  };

  const onCloseModal = () => {
    deselectBook();
    onClose({ reload: false });
  };

  const executeBookChange = async () => {
    setLoading(true);
    const { ok } = await assingBankTransferToBook(banktransfer.id, book.bookref);
    if (ok) {
      deselectBook();
      onClose({ reload: true });
    }
    setLoading(false);
  };

  return (
    <CustomModal
      loading={loading}
      title={"Asignar transferencia a reserva"}
      open={open}
      handleClose={onCloseModal}
      bigModal={true}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <BankTransferSummary banktransfer={banktransfer} />
        </Grid>
      </Grid>

      {book === undefined && (
        <AddBookrefInterface banktransfer={banktransfer} onSelectBookref={selectBookref} />
      )}

      {/* Book chosen */}
      {book !== undefined && <BookSelectedSummary book={book} banktransfer={banktransfer} />}

      {book !== undefined && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div style={{ float: "right" }}>
              <ButtonCustom onClick={deselectBook}>{"Asignarla a otra reserva"}</ButtonCustom>
              <ButtonCustom color="primary" onClick={executeBookChange}>
                {"Añadir transferencia"}
              </ButtonCustom>
            </div>
          </Grid>
        </Grid>
      )}
    </CustomModal>
  );
};

const BankTransferSummary = ({ banktransfer }) => {
  return (
    <>
      <h5>Transferencia seleccionada: </h5>
      <Table size="small" style={{ width: "100%" }}>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: "25%" }}>Concepto</TableCell>
            <TableCell style={{ width: "75%" }}>
              <b>{banktransfer.concept}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: "25%" }}>Pagador</TableCell>
            <TableCell style={{ width: "75%" }}>
              <b>{banktransfer.payer}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: "25%" }}>Fecha</TableCell>
            <TableCell style={{ width: "75%" }}>
              <b>{banktransfer.date}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: "25%" }}>Cantidad</TableCell>
            <TableCell style={{ width: "75%" }}>
              <b>
                <Money amount={banktransfer.amount} />
              </b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

const AddBookrefInterface = ({ banktransfer, onSelectBookref }) => {
  const possibles = banktransfer?.match?.possibles || [];
  return (
    <>
      {/* Suggestions */}
      {possibles.length > 0 && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <p>Para esta transferencia tenemos las siguientes sugerencias de reservas:</p>
            {possibles.map((br, index) => (
              <ButtonAdd key={`brToAdd${index}`} onClick={() => onSelectBookref({ bookref: br })}>
                {br}
              </ButtonAdd>
            ))}
          </Grid>
        </Grid>
      )}

      {/* Input */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <p>Puedes añadir manualmente un codigo de reserva buscandola aqui:</p>
          <InputBookref onSelect={onSelectBookref} />
        </Grid>
      </Grid>
    </>
  );
};

const BookSelectedSummary = ({ book, banktransfer }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <h5>Añadir esta transferencia a la siguiente reserva:</h5>
        <Table size="small" style={{ width: "100%" }}>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: "50%" }}>Reserva</TableCell>
              <TableCell style={{ width: "50%" }}>
                <b>{book.bookref}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "50%" }}>Cliente</TableCell>
              <TableCell style={{ width: "50%" }}>
                <b>
                  {book.contactPerson.name} {book.contactPerson.lname}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "50%" }}>P.V.P</TableCell>
              <TableCell style={{ width: "50%" }}>
                <b>
                  <Money amount={book.pvp} />
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "50%" }}>Ya pagado</TableCell>
              <TableCell style={{ width: "50%" }}>
                <b>
                  <Money amount={book.paid} />
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "50%" }}>Le queda por pagar</TableCell>
              <TableCell style={{ width: "50%" }}>
                <b>
                  <Money amount={book.pvp - book.paid} />
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "50%" }}>Tras aplicar esta transferencia</TableCell>
              <TableCell style={{ width: "50%" }}>
                <b>
                  <Money amount={book.pvp - book.paid - banktransfer.amount} />
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

BankTransfersModalAssign.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

BankTransfersModalAssign.defaultProps = {};

export default BankTransfersModalAssign;
