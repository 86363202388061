/* eslint-disable import/no-unresolved */
import React, { Fragment, useState, useEffect } from "react";
import FxSwitch from "@atoms/Switches/FxSwitch";
import InputNumber from "@atoms/Inputs/InputNumber";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import FxDatePicker from "@atoms/Pickers/FxDatePicker";
import { Row, Col, Card, Alert } from "react-bootstrap";
import { TextField, Fab } from "@material-ui/core";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import { MdWarning } from "react-icons/md";
import ErrorModal from "@atoms/Modals/ErrorModal";
import SectionTitle from "@atoms/Titles/SectionTitle";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles"; // DRM TODO Esto no se que es
import Paper from "@material-ui/core/Paper"; // DRM TODO Esto no se que es
import { ContainerFenix, UsCardTitle } from "@atoms/Card/UsCard.styles";
import CachedIcon from "@material-ui/icons/Cached";
import Grid from "@material-ui/core/Grid";
import { AlerTitle } from "@atoms/Titles/Titles.styles";
import {
  SelectMarketPosition,
  TextCounterSearches,
  ButtonSearches,
  InfoDescription,
  TextCounterSearchesTop,
} from "../../../contenido/json-pages/JsonPageEditor/JsonPageEditor.styles";
import OffersEditorImages from "../../components/OffersEditorComponents/OffersEditorImages/OffersEditorImages";
import OfferEditorInfo from "../../components/OffersEditorComponents/OffersEditorInfo/OfferEditorInfo";
import DropDownConfi from "../../components/OffersEditorComponents/DropDownConfi/DropDownConfiServicesIncludedInOffer";
import SearcherOfferTags from "../../components/OffersEditorComponents/SearcherOfferTags/SearcherOfferTags";
import OffersEditorConfiguration from "../../components/OffersEditorConfiguration";
import SocialNetwork from "../../components/OffersEditorComponents/SocialNetwork/SocialNetwork";
import useFeatureFlag from "@lib/hooks/useFeatureFlag";
import { serverSideTranslations } from "@lib/contexts/i18Traventia/serverSideTranslations";
import { I18TraventiaContextProvider } from "@lib/contexts/i18Traventia/i18TraventiaContext";
import HtmlTextEditor from "@atoms/Inputs/HtmlTextEditor/HtmlTextEditor";

const useStyles = makeStyles({ root: { flexGrow: 1 } });

function OfferListEditor({
  offerCode,
  counterSearches,
  form,
  setForm,
  loadingOffer,
  setErrors,
  errors,
  isDuplication,
  saveOfferForm,
  backToTable,
  onChangeForm,
  getColorTextCounterSearches,
  onChangeOccupancy,
  ticketsProducts,
  handleTicketsProducts,
  handleTicketProductsFetch,
  counterCharactersShortDescription,
  getColorTextCounterShortDescription,
  reloadCalendarOffert,
}) {
  const classes = useStyles();
  const [tabSelected, setTabSelected] = useState(0);
  const [i18Traventia, setI18Traventia] = useState({});
  const [errorInfo, setErrorInfo] = useState(false);
  const [errorTipInfo, setErrorTipInfo] = useState(null);

  useEffect(() => {
    const loadTranslations = async (market) => {
      const loaded = await serverSideTranslations(market, ["offers"]);
      setI18Traventia(loaded);
    };
    if (form.market) loadTranslations(form.market);
  }, [loadingOffer]);

  const checkHTML = function (html) {
    const htmlString = `<div>${html}</div>`;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "application/xml");
    return doc.querySelector("parsererror");
  };

  const handleChange = (event, newValue) => {
    setTabSelected(newValue);
  };

  const getDatePublication = () => {
    const date = new Date(form.datePublication);
    return date.toISOString();
  };

  if (loadingOffer) {
    return <div>Cargando....</div>;
  }
  return (
    <Fragment>
      <I18TraventiaContextProvider props={i18Traventia.i18Traventia}>
        {/* Errors modal */}
        <ErrorModal
          show={errors.show}
          listErrors={errors.list}
          onClose={() => setErrors({ show: false, list: [] })}
        />

        {/* Content */}
        <ContainerFenix>
          <Grid container>
            <Grid item xs={11}>
              <TextCounterSearchesTop colorText={getColorTextCounterSearches}>
                Esta oferta creara {counterSearches} búsquedas diarias.
              </TextCounterSearchesTop>
            </Grid>
            <Grid item xs={1}>
              <ButtonSearches colorText={getColorTextCounterSearches}>
                <Fab
                  key={"btG"}
                  variant="extended"
                  size="small"
                  color="secondary"
                  aria-label="recargar"
                  onClick={reloadCalendarOffert}
                >
                  <CachedIcon />
                </Fab>
              </ButtonSearches>
            </Grid>
          </Grid>

          <SectionTitle
            title={`Oferta ${form.idOffer} - ${form.title}`}
            backFunction={backToTable}
            buttons={[
              <ButtonSave key={"btG"} color="primary" onClick={saveOfferForm}>
                Guardar oferta
              </ButtonSave>,
            ]}
          />
          {isDuplication && (
            <Alert variant="warning">
              <MdWarning size={60} />
              ESTAS DUPLICANDO LA OFERTA <b>{offerCode}</b>, por favor, si cambias el mercado que le aplica,
              ten en cuenta que también deberían cambiar todos los campos que tengan contenido explícito del
              idioma (htmls, asuntos, etc...).
            </Alert>
          )}

          <Paper className={classes.root} style={{ marginBottom: "10px" }}>
            <Tabs
              value={tabSelected}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
            >
              <Tab label="Información" />
              <Tab label="Relevancia" />
              <Tab label="Etiquetas" />
              <Tab label="Configuracion" />
              <Tab label="Servicios" />
              <Tab label="Info y Contenido" />
              <Tab label="Imagenes" />
              <Tab label="Redes Sociales" />
            </Tabs>
          </Paper>
          {tabSelected === 0 && (
            <Card>
              <Card.Body>
                <UsCardTitle>
                  <span>Informacion de la oferta</span>
                </UsCardTitle>
                <Row>
                  <Col xs={4}>
                    <FxSwitch
                      value="end"
                      labels={["Oferta habilitada", "Oferta deshabilitada"]}
                      status={form.enable}
                      onChange={(nuValue) => {
                        const nuForm = { ...form };
                        nuForm.enable = nuValue;
                        setForm(nuForm);
                      }}
                    />
                  </Col>
                  <Col xs={4}>
                    <FxSwitch
                      labels={["Oferta Descatada", "Oferta Descatada"]}
                      status={form.highlighted}
                      onChange={(nuValue) => {
                        const nuForm = { ...form };
                        nuForm.highlighted = nuValue;
                        setForm(nuForm);
                      }}
                    />
                  </Col>
                  <Col xs={4}>
                    <SelectMarketPosition>
                      <SelectMarket
                        selection={form.market}
                        onChangeMarket={(nVal) => {
                          const nuForm = { ...form };
                          nuForm.market = nVal;
                          setForm(nuForm);
                        }}
                      />
                    </SelectMarketPosition>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <TextField disabled={true} label="Código" value={form.idOffer} />
                  </Col>
                  <Col>
                    <TextField
                      label="Titulo"
                      value={form.title}
                      onChange={(nVal) => {
                        const nuForm = { ...form };
                        nuForm.title = nVal.target.value;
                        setForm(nuForm);
                      }}
                    />
                  </Col>
                  <Col>
                    <FxDatePicker
                      label={"Fecha de publicación"}
                      dateInitial={getDatePublication()}
                      onChange={(nuDate) => {
                        const nuForm = { ...form };
                        nuForm.datePublication = nuDate;
                        setForm(nuForm);
                      }}
                    />
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <p style={{ color: "rgba(0, 0, 0, 0.38)", marginBottom: "-1px", fontSize: "0.9em" }}>
                          Descripción corta
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <HtmlTextEditor
                          id="shortDescription"
                          value={form.shortDescription}
                          onChange={(nVal) => {
                            const nuForm = { ...form };
                            nuForm.shortDescription = nVal;
                            setForm(nuForm);
                            const checkVal = checkHTML(nVal);
                            setErrorInfo(checkVal !== null);
                            if (checkVal !== null) {
                              setErrorTipInfo(checkVal.children);
                            } else {
                              setErrorTipInfo(null);
                            }
                          }}
                          placeholder={"Escribe aquí la descripcion."}
                          valid={!errorInfo}
                          width={"500px"}
                          height={200}
                        />
                      </Grid>
                    </Grid>
                    {/* <TextField
                      label="Descripción corta"
                      value={form.shortDescription}
                      onChange={(nVal) => {
                        const nuForm = { ...form };
                        nuForm.shortDescription = nVal.target.value;
                        setForm(nuForm);
                      }}
                    /> */}
                  </Col>
                  <Col>
                    <InfoDescription>La tarjeta mostrará un máximo de 155 caracteres.</InfoDescription>
                  </Col>
                  <Col>
                    <TextCounterSearches colorText={getColorTextCounterShortDescription()}>
                      {counterCharactersShortDescription(form.shortDescription, 155)} caracteres restantes.
                    </TextCounterSearches>
                  </Col>
                </Row>
                <br />
              </Card.Body>
            </Card>
          )}
          {tabSelected === 1 && (
            <Card>
              <Card.Body>
                <UsCardTitle>
                  <span>Peso de la oferta</span>
                </UsCardTitle>
                <Row className="justify-content-md-center">
                  <Col xs={2}>
                    <InputNumber
                      disabled={!form.enable}
                      size="small"
                      min={0}
                      max={100}
                      style={{ borderRadius: "4px 0 0 4px" }}
                      label={"Relevancia"}
                      value={parseInt(form.relevanceScoring) || 0}
                      onChange={(nuValue) => {
                        const nuForm = { ...form };
                        nuForm.relevanceScoring = nuValue;
                        setForm(nuForm);
                      }}
                    />
                  </Col>
                  <Col xs={9}>
                    <AlerTitle variant="info">
                      <b>Recuerda:</b> Las ofertas con más relevancia estarán mejor posicionas que las que
                      tengas menor relevancia.
                      <br />* No se puede asignar relevancia a ofertas deshabilitadas.
                      <br />* La relevancia se borrará si se deshabilia la oferta.
                    </AlerTitle>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          {tabSelected === 2 && (
            <SearcherOfferTags
              market={form.market}
              tags={form.tags}
              onChangeOffersTags={(tags) => {
                const nuForm = { ...form };
                nuForm.tags = tags;
                setForm(nuForm);
              }}
            />
          )}
          {tabSelected === 3 && (
            <OffersEditorConfiguration
              form={form}
              onChangeForm={onChangeForm}
              onChangeOccupancy={onChangeOccupancy}
            />
          )}
          {tabSelected === 4 && (
            <DropDownConfi
              handle={onChangeForm}
              form={form}
              ticketsProducts={ticketsProducts}
              deleteTicketProduct={(nuValue) => {
                handleTicketsProducts(nuValue);
              }}
              handleFetch={handleTicketProductsFetch}
            />
          )}
          {tabSelected === 5 && <OfferEditorInfo form={form} onChangeList={onChangeForm} />}
          {tabSelected === 6 && <OffersEditorImages form={form} onChange={onChangeForm} />}
          {tabSelected === 7 && (
            <SocialNetwork
              form={form}
              onChange={onChangeForm}
              offerCode={offerCode}
              // saveOfferForm={saveOfferForm}
            />
          )}
        </ContainerFenix>
      </I18TraventiaContextProvider>
    </Fragment>
  );
}

export default OfferListEditor;
