import React from "react";
import UploadXmlSii from "./UploadXmlSii";
import UploadCsvSii from "./UploadCsvSii";
import ProcessXmlSii from "./ProcessXmlSii";

const XmlSiiConvPage = () => {
  const [xmlsAreReady, setXmlsAreReady] = React.useState(false);
  const [accountInfoTaxIsReady, setAccountInfoTaxIsReady] = React.useState(false);
  const [xmls, setXmls] = React.useState([]);
  const [accountInfoTax, setAccountInfoTax] = React.useState({});
  const xmlReady = (xmls) => {
    setXmlsAreReady(true);
    setXmls(xmls);
  };
  const csvReady = (newAccountInfoTax) => {
    setAccountInfoTaxIsReady(true);
    setAccountInfoTax(newAccountInfoTax);
  };
  return (
    <>
      <h2>1. Sube todos los archvos xml a transformar</h2>
      <UploadXmlSii onReady={(xmls) => xmlReady(xmls)} />
      <br />
      <hr />
      <br />
      <h2>2. Sube el archivo csv</h2>
      <UploadCsvSii onReady={(newAccountInfoTax) => csvReady(newAccountInfoTax)} />
      {xmlsAreReady && accountInfoTaxIsReady && (
        <>
          <br />
          <hr />
          <br />
          <h2>3. Procesa los xml</h2>
          <ProcessXmlSii xmls={xmls} accountInfoTax={accountInfoTax} />
        </>
      )}
    </>
  );
};

export default XmlSiiConvPage;
