import React from "react";
import axios from "axios";
import ItemSelectorWithQuery from "@molecules/Forms/ItemSelectorWithQuery";
import { TravCard, TravHeaderCard, TravBodyCard } from "@atoms/Card/FxCard";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import Grid from "@material-ui/core/Grid";

function MealSelector({ mealPlans = [], onChange, withCard = true }) {
  const quickMealPlans = [
    { code: "SA", label: "Solo Alojamiento" },
    { code: "AD", label: "Alojamiento y desayuno" },
    { code: "MP", label: "Media pensión" },
    { code: "PC", label: "Pensión completa" },
  ];

  async function onSearch(query) {
    if (query.length < 2) {
      return [];
    }

    let mealPlanList = [];
    try {
      const url = "/api/v1/web-bff-bc/accommodation/mealplan";
      const promises = [
        axios.get(url, { params: { "code[regex]": query } }),
        axios.get(url, { params: { "desc[regex]": query } }),
      ];

      const response = await Promise.all(promises);

      response.forEach(({ status, data }) => {
        if (status === 200) {
          mealPlanList = [
            ...mealPlanList,
            ...data.data.map((product) => ({
              ...product,
              text: [product.code, product.description].join(" - "),
            })),
          ];
        }
      });
    } catch (e) {
      console.error(e);
    }

    return mealPlanList;
  }

  function onChangeMealPlans(newMealPlans) {
    onChange(newMealPlans);
  }

  return (
    <>
      {withCard ? (
        <TravCard>
          <TravHeaderCard>
            <h5>Regímenes</h5>
            <p style={{ fontSize: "12px" }}>
              Añade aqui los regímenes que usa el alojamiento <b>exclusivamente</b>. Si lo dejas vacio,
              incluira todos los posibles. Si añades alguno, la oferta solo incluira esos.
            </p>
          </TravHeaderCard>
          <TravBodyCard>
            <ItemSelectorWithQuery
              withCard={withCard}
              title={
                <>
                  {"Añade algunos rapidamente: "}
                  <br />
                  <>
                    {quickMealPlans.map((option) => (
                      <ButtonAdd
                        key={option.code}
                        size={"small"}
                        disabled={mealPlans.includes(option.code)}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onChangeMealPlans([...mealPlans, option.code]);
                        }}
                      >
                        {option.code}
                      </ButtonAdd>
                    ))}
                  </>
                </>
              }
              inputPlaceholder={"... o escribe aqui el código o nombre del régimen para buscarlo"}
              selected={mealPlans}
              onSearch={onSearch}
              onChange={onChangeMealPlans}
            />
          </TravBodyCard>
        </TravCard>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <h5>Regímenes</h5>
            <p style={{ fontSize: "12px" }}>
              Añade aqui los regímenes que usa el alojamiento <b>exclusivamente</b>. Si lo dejas vacio,
              incluira todos los posibles. Si añades alguno, la oferta solo incluira esos.
            </p>
          </Grid>
          <Grid item xs={12}>
            <ItemSelectorWithQuery
              title={
                <>
                  {"Añade algunos rapidamente: "}
                  <br />
                  <>
                    {quickMealPlans.map((option) => (
                      <ButtonAdd
                        key={option.code}
                        size={"small"}
                        disabled={mealPlans.includes(option.code)}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onChangeMealPlans([...mealPlans, option.code]);
                        }}
                      >
                        {option.code}
                      </ButtonAdd>
                    ))}
                  </>
                </>
              }
              inputPlaceholder={"... o escribe aqui el código o nombre del régimen para buscarlo"}
              selected={mealPlans}
              onSearch={onSearch}
              onChange={onChangeMealPlans}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default MealSelector;
