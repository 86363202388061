import { useState, useEffect, useCallback } from "react";
import { getCellContentWithFieldCode } from "./shared/utils";
import axios from "axios";

const useFenixTableWithCustomPagination = (
  tableHead,
  sortDefault,
  customUrl,
  withSearch,
  searchQueryParam,
  maxNumberPerPages,
  searchQueryAdditionalParams,
  forceReload,
  inputTextExternal,
  modifyContentTable,
  minCharsToSearch
) => {
  const [tableData, setTableData] = useState([]);

  // Order 'asc' / 'desc' && Field we are ordering by
  const [order, setOrder] = useState(sortDefault);

  // Search (filter) input
  const [inputText, setInputText] = useState(inputTextExternal);

  // Active filters
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState([]);
  const [timer, setTimer] = useState(null);
  const [limit, setLimit] = useState({
    pages: 0,
    pageActive: 0,
  });

  const updateTableContent = async (page, search) => {
    setLoading(true);
    let datas = { data: [], total: 0 };
    const url = `${customUrl}?_offset=${page * maxNumberPerPages}&_limit=${maxNumberPerPages}${
      searchQueryAdditionalParams ? "&" + searchQueryAdditionalParams : ""
    }`;

    if (inputText && search !== undefined) {
      if (typeof search === "string") {
        const { data } = await axios.get(url + "&" + search + "=" + inputText);
        datas.data.push(...data.data.map((cv) => JSON.stringify(cv)));
        datas.total += data.total;
      } else {
        for (const key of search) {
          const { data } = await axios.get(url + "&" + key + "=" + inputText);
          datas.data.push(...data.data.map((cv) => JSON.stringify(cv)));
          datas.total += data.total;
        }
      }
    } else {
      const { data } = await axios.get(url);
      datas.data.push(...data.data.map((cv) => JSON.stringify(cv)));
      datas.total += data.total;
    }
    const npc = [...new Set(datas.data)];
    setLimit({
      pages: Math.ceil(datas.total / maxNumberPerPages),
      pageActive: page + 1,
    });
    setLoading(false);
    let tableContentData = npc.map((cv) => JSON.parse(cv));
    if (modifyContentTable !== undefined) {
      const newData = modifyContentTable(tableContentData);
      tableContentData = newData;
    }
    setTableContent(tableContentData);
  };
  useEffect(() => {
    if (withSearch) {
      setLoading(true);
      if (timer !== null) {
        clearTimeout(timer);
      }
      if (inputText.length === 0) {
        updateTableContent(0);
      }
      if (inputText.length <= minCharsToSearch) {
        return;
      }
      let newTimer = setTimeout(() => {
        updateTableContent(0, searchQueryParam);
      }, 500);
      setTimer(newTimer);
    }
  }, [inputText]);

  useEffect(() => {
    if (forceReload > 0) {
      if (inputText.length > 0) {
        updateTableContent(0, searchQueryParam);
      } else {
        updateTableContent(limit.pageActive - 1);
      }
    }
  }, [forceReload]);

  const onPageChangeByOne = async (page) => {
    if (page === "more") await updateTableContent(limit.pageActive);
    else if (page === "less") await updateTableContent(limit.pageActive - 2);
  };

  const initializeFilters = useCallback(() => {
    // console.log("Inicializo los filtros");

    const nuFilterOptions = tableHead
      .filter((h) => h.filterable)
      .reduce((foptions, h) => {
        const field = h.code;
        const options = tableContent.reduce((opt, row) => {
          if (!opt.includes(row[field])) {
            opt.push(row[field]);
          }
          return opt;
        }, []);
        return { ...foptions, [field]: { field, options, values: [] } };
      }, {});

    return nuFilterOptions;
  }, [tableContent, tableHead]);

  const sortTable = (table, direction, field) => {
    // console.log(direction, field);

    return direction === "desc"
      ? table.sort((a, b) => (b[field] < a[field] ? -1 : 1))
      : table.sort((a, b) => (a[field] < b[field] ? -1 : 1));
  };

  const applyFilters = (tableWhere, filtersToApply) => {
    const ftable = tableWhere.filter((tableRow) => {
      let goThrough = true;
      Object.keys(filtersToApply).forEach((keyF) => {
        if (filtersToApply[keyF].values.length > 0) {
          goThrough = goThrough && filtersToApply[keyF].values.indexOf(tableRow[keyF]) !== -1;
        }
      });
      return goThrough;
    });

    return ftable;
  };

  const applyQuerySearch = useCallback(
    (tableWhere, querySearch) => {
      // console.log("Apply query search ", querySearch);
      let queryTable = [...tableWhere];

      if (querySearch.length >= 3) {
        queryTable = queryTable.filter((tableRow) => {
          let goThrough = false;
          tableHead.forEach((h) => {
            if (h.type === "string" || h.type === "numeric") {
              const cellContent = getCellContentWithFieldCode(tableRow, h);
              goThrough =
                goThrough || cellContent.toString().toLowerCase().indexOf(querySearch.toLowerCase()) !== -1;
            }
          });
          return goThrough;
        });
      }

      return queryTable;
    },
    [tableHead]
  );

  const initializeTableData = useCallback(() => {
    // console.log("Inicializo table data ", filters, inputText, order.by, order.direction, tableContent.length);

    const orderedContent = [...tableContent];
    sortTable(orderedContent, order.direction, order.by);
    let orderedIndexedContent = orderedContent.map((row, index) => ({
      ...row,
      key_id: `FxTableRow_${index}`,
    }));

    if (inputText !== "") {
      orderedIndexedContent = applyQuerySearch(orderedIndexedContent, inputText);
    }

    if (filters !== undefined) {
      orderedIndexedContent = applyFilters(orderedIndexedContent, filters);
    }

    setTableData(orderedIndexedContent);
  }, [applyQuerySearch, filters, inputText, order.by, order.direction, tableContent]);

  useEffect(() => {
    if (!loading) {
      initializeTableData();
    }
  }, [initializeTableData, loading, tableContent, tableHead]);

  useEffect(() => {
    setFilters(initializeFilters());
  }, [initializeFilters, tableHead, tableContent]);

  const onRequestSort = (property) => {
    let nuOrder = "desc";
    if (order.by === property && order.direction === "desc") {
      nuOrder = "asc";
    }

    const orderedContent = [...tableData];
    sortTable(orderedContent, nuOrder, property);

    const orderedIndexedContent = orderedContent.map((row, index) => ({
      ...row,
      key_id: `FxTableRow_${index}`,
    }));
    setTableData(orderedIndexedContent);
    setOrder({ direction: nuOrder, by: property });
  };

  const onChangeFilters = ({ column, value }) => {
    const newFilters = { ...filters };
    newFilters[column].values = value;

    const queryTable = applyQuerySearch(tableContent, inputText);
    const filteredTable = applyFilters(queryTable, newFilters);
    setTableData(filteredTable);
    setFilters(newFilters);
  };

  const onChangeInputSearch = (newInputText) => {
    // console.log("newInputText>>>>", newInputText);

    setInputText(newInputText);
    const filteredTable = applyFilters(tableContent, filters);
    const queryTable = applyQuerySearch(filteredTable, newInputText);
    setTableData(queryTable);
  };

  // Update the data of a row (for enable/disable action)
  const updateRowData = (index, nuRow) => {
    const nuTableData = [...tableData];
    nuTableData[index] = nuRow;
    setTableData(nuTableData);
  };

  return {
    onRequestLoading: loading,
    tableData,
    order,
    inputText,
    filters,
    onRequestSort,
    onChangeFilters,
    onChangeInputSearch,
    updateRowData,
    onPageChangeByOne,
    tableContent,
    limit,
  };
};

export default useFenixTableWithCustomPagination;
