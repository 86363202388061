import React, { Fragment, useState, useEffect } from "react";
import { DragAndDrop } from "@lib/layout/DnD";
import {
  DraggableListBox,
  RemoveOption,
  EditOption,
  EditCheckOption,
  EditDeleteOption,
  OptionBox,
} from "./DraggableList.styles";
import TextField from "@material-ui/core/TextField";
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
/**
 * ExpectedList = [[text(,object)],[text(,object)],[text(,object)]]
 */
const DraggableList = ({ title, list, onChangeList, editable = true }) => {
  const [listLocal, setListLocal] = useState(list);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [hideIndex, setHideIndex] = useState(-1);
  const [editObjectFromList, setEditObjectFromList] = useState({ index: -1, value: "" });
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });

  useEffect(() => {
    setListLocal(list);
  }, [list]);

  /**
   * Launch each time a user drag a block of list and drop in other position
   */
  const onDrop = (dragged, dropArea) => {
    const oldIndex = dragged.uid;
    const newIndex = dropArea.uid;
    if (newIndex !== oldIndex) {
      let pop = list.splice(oldIndex, 1);
      list.splice(newIndex, 0, pop[0]);
      onChangeList(list);
    }
    setHoverIndex(-1);
  };
  const onHover = (dragging, dropArea) => {
    setHoverIndex(dropArea.uid);
  };

  const startDrag = (dragging) => {
    //let pop = list.splice(dragging.uid, 1);
    //setListLocal([...list]);
  };
  const removeFromList = (index) => {
    const name = listLocal[index][0];

    setConfirmationDialog({
      open: true,
      text: `¿Estas seguro que desea eliminar?`,
      body: listLocal[index][0],
      onConfirm: () => {
        let pop = list.splice(index, 1);
        setConfirmationDialog({
          open: false,
          text: "",
          body: "",
          onConfirm: () => {},
        });
        onChangeList(list);
      },
    });
  };

  const EditFromList = (index) => {
    setEditObjectFromList({ index: index, value: listLocal[index][0] });
  };

  const ConfirmEditFromList = (index) => {
    const r = confirm(
      '¿Seguro que quieres modificar " ' +
        listLocal[index][0] +
        ' " por esto " ' +
        editObjectFromList.value +
        ' "?'
    );
    if (r) {
      let pop = list.splice(index, 1, [editObjectFromList.value]);
      onChangeList(list);
      resetEdit();
    }
  };
  const resetEdit = () => {
    setEditObjectFromList({ index: -1, value: "" });
  };

  return (
    <Fragment>
      {listLocal.map((objectFromList, index) => (
        <Fragment key={index}>
          <DragAndDrop group={title} uid={index} onDrop={onDrop} startDrag={startDrag} onHover={onHover}>
            <DraggableListBox active={hoverIndex === index} hide={hideIndex === index}>
              {editObjectFromList.index === index && (
                <Fragment>
                  {/* <TextField
                    id="standard-basic"
                    value={editObjectFromList.value}
                    onChange={(nuValue) => {
                      console.log(nuValue.target.value);
                      setEditObjectFromList({ index: index, value: nuValue.target.value });
                    }}
                  /> */}
                  <textarea
                    style={{ height: "120px", width: "100%" }}
                    name="Texto"
                    // rows="5"
                    // cols="10"
                    value={editObjectFromList.value}
                    disabled={false}
                    placeholder={"Texto de la publicación"}
                    onChange={(nuValue) => {
                      console.log(nuValue.target.value);
                      setEditObjectFromList({ index: index, value: nuValue.target.value });
                    }}
                  ></textarea>

                  <EditCheckOption
                    onClick={(evt) => {
                      evt.preventDefault();
                      // EditFromList(index);
                      ConfirmEditFromList(index);
                    }}
                  />
                  <EditDeleteOption
                    onClick={(evt) => {
                      evt.preventDefault();
                      resetEdit();
                    }}
                  />
                </Fragment>
              )}
              {editObjectFromList.index !== index && (
                <Fragment>
                  {objectFromList[0]}
                  <OptionBox>
                    {editable && (
                      <EditOption
                        onClick={(evt) => {
                          evt.preventDefault();
                          EditFromList(index);
                        }}
                      />
                    )}
                    <RemoveOption
                      onClick={(evt) => {
                        evt.preventDefault();
                        removeFromList(index);
                      }}
                    />
                  </OptionBox>
                </Fragment>
              )}
            </DraggableListBox>
          </DragAndDrop>
        </Fragment>
      ))}

      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={confirmationDialog.onConfirm}
        onDeny={() => {
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
    </Fragment>
  );
};

export default DraggableList;
