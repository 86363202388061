export const bookingModesEnabled = [
  "none",
  "nobook",
  "stemail",
  "europlayas",
  "cetursa",
  "ticketingprebuy",
  "experticket",
  "hotelbedsapitude",
  "zoomarine",
  "doblementev2",
  "aramon",
];

export const voucherModesEnabled = [
  "none",
  "stemail",
  "cetursa",
  "europlayas",
  "ticketingprebuy",
  "experticket",
  "hotelbedsapitude",
  "zoomarine",
  "frominboundemail",
  "qrcode",
  "fromsftp",
  "doblementev2",
  "doblementev2_esqui",
];

export const cancellationModesEnabled = [
  "none",
  "stemail",
  "europlayas",
  "cetursa",
  "ticketingprebuy",
  "experticket",
  "hotelbedsapitude",
  "zoomarine",
  "zendesk",
  "doblementev2",
  "aramon",
];
