import styled from "styled-components";

export const TextCounterVouchers = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
`;

export const TextCounterVouchersInfrigement = styled.div`
  color: red;
  margin-right: 20px;
`;

export const TextCounterVouchersFerrys = styled.div`
  color: #ffc107;
  margin-right: 20px;
`;
export const TextCounterVouchersManual = styled.div`
  color: #39d706;
  margin-right: 20px;
`;
export const TextCounterVouchersPending = styled.div`
  color: LightGrey;
  margin-right: 5px;
`;

export const TextCounterTotal = styled.div`
  color: grey;
  margin-right: 20px;
`;
export const BoxPaginationVouchers = styled.div`
  text-align: right;
`;
