import React, { Fragment } from "react";
import { Card } from "react-bootstrap";

import { Tabs, Tab } from "@material-ui/core";

//Components
import SliderConfiguration from "./components/SliderConfiguration";
import SpeedLinksConfiguration from "./components/SpeedLinksConfiguration";
import SectionTitle from "@atoms/Titles/SectionTitle";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import CenterLoader from "@atoms/Loaders/CenterLoader";

//Styles
import { UsCardTitle } from "@atoms/Card/UsCard.styles";
function HomeContentView({
  loading,
  homeContent,
  market,
  tabSelected,
  changeHomeContent,
  changeTab,
  changeMarket,
  saveHomeContent,
}) {
  return (
    <Fragment>
      <SectionTitle
        title={"Configuración de la Home"}
        info={
          "En esta sección se puede configurar diversos elementos que aparecen en la pagina principal de la web"
        }
        buttons={[
          <SelectMarket key={"marketsHC"} selection={market} onChangeMarket={(nVal) => changeMarket(nVal)} />,
          <ButtonSave key={"btGHC"} disabled={loading} onClick={() => saveHomeContent()}>
            Guardar contenido ({market})
          </ButtonSave>,
        ]}
      />

      {loading ? (
        <CenterLoader />
      ) : (
        <Fragment>
          {Object.keys(homeContent).length === 0 ? (
            <span>Error</span>
          ) : (
            <Fragment>
              <Card>
                <Card.Body>
                  <Tabs
                    value={tabSelected}
                    onChange={changeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                  >
                    <Tab label="Slider" />
                    <Tab label="Enlaces rápidos" />
                  </Tabs>
                </Card.Body>
              </Card>

              {tabSelected === 0 && (
                <Card>
                  <Card.Body>
                    <UsCardTitle>
                      <span>Slider de la pagina principal</span>

                      <ButtonAdd
                        onClick={() => {
                          const nu = { ...homeContent };
                          nu.slider.push({
                            image: { src: "" },
                            link: { enable: false, url: "" },
                            html: { enable: false, content: "" },
                          });
                          changeHomeContent(nu);
                        }}
                      >Añadir</ButtonAdd>
                    </UsCardTitle>

                    <SliderConfiguration
                      title={"Slider"}
                      list={homeContent.slider}
                      onChangeList={(nu) => {
                        changeHomeContent({ ...homeContent, slider: nu });
                      }}
                    />
                  </Card.Body>
                </Card>
              )}

              {tabSelected === 1 && (
                <Card>
                  <Card.Body>
                    <UsCardTitle>
                      <span>Enlaces rápidos</span>

                      <ButtonAdd
                        onClick={() => {
                          const nu = { ...homeContent };
                          nu.speedlinks.push({ title: "", url: "", links: [] });
                          changeHomeContent(nu);
                        }}
                      >Añadir bloque</ButtonAdd>
                    </UsCardTitle>

                    <SpeedLinksConfiguration
                      title={"Speedlinks"}
                      list={homeContent.speedlinks}
                      onChangeList={(nu) => {
                        changeHomeContent({ ...homeContent, speedlinks: nu });
                      }}
                    />
                  </Card.Body>
                </Card>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

export default HomeContentView;
