import React from "react";
import { BsArrowReturnRight, BsCreditCard } from "react-icons/bs";
import { AiFillBank } from "react-icons/ai";
import { RiCustomerService2Fill } from "react-icons/ri";

export const getPaymentTypeTranslation = () => {
  return {
    1: {
      name: (
        <>
          {/* <img
            style={{ height: "20px", marginRight: "5px" }}
            src="https://tr3.traventia.com/graphic-design/logos/logos_metodopago/credit_card.svg"
            alt="credit_card"
          /> */}
          <BsCreditCard style={{ fontSize: "20px", marginRight: "5px" }} />
          {"Tarjeta (Caixa Catalunya)"}
        </>
      ),
      colour: "no-background",
    },
    2: {
      name: (
        <>
          <img
            style={{ height: "20px", marginRight: "5px" }}
            src="https://tr3.traventia.com/graphic-design/logos/logos_metodopago/paypal_onlylogo.svg"
            alt="Paypal"
          />
          {"PayPal"}
        </>
      ),
      colour: "no-background",
    },
    3: {
      name: (
        <>
          {/* <img
            style={{ height: "20px", marginRight: "5px" }}
            src="https://tr2.traventia.com/graphic-design/logos/logos_metodopago/transferencia.svg"
            alt="transferencia"
          /> */}
          <AiFillBank style={{ fontSize: "20px", marginRight: "5px" }} />
          {"Transferencia"}
        </>
      ),
      colour: "no-background",
    },
    4: { name: "Devolución (antigua)", colour: "no-background" },
    5: {
      name: (
        <>
          {/* <img
            style={{ height: "20px", marginRight: "5px" }}
            src="https://tr3.traventia.com/graphic-design/logos/logos_metodopago/credit_card.svg"
            alt="credit_card"
          /> */}
          <BsCreditCard style={{ fontSize: "20px", marginRight: "5px" }} />
          {"Tarjeta (Sabadell)"}
        </>
      ),
      colour: "no-background",
    },
    6: {
      name: (
        <>
          {/* <img
            style={{ height: "20px", marginRight: "5px" }}
            src="https://tr3.traventia.com/graphic-design/logos/logos_metodopago/credit_card.svg"
            alt="credit_card"
          /> */}
          <BsCreditCard style={{ fontSize: "20px", marginRight: "5px" }} />
          {"Tarjeta (Santander)"}
        </>
      ),
      colour: "no-background",
    },
    7: {
      name: (
        <>
          {/* <img
            style={{ height: "20px", marginRight: "5px" }}
            src="https://tr3.traventia.com/graphic-design/logos/logos_metodopago/credit_card.svg"
            alt="credit_card"
          /> */}
          <BsCreditCard style={{ fontSize: "20px", marginRight: "5px" }} />
          {"Tarjeta (Santander)"}
        </>
      ),
      colour: "no-background",
    },
    8: {
      name: (
        <>
          <img
            style={{ height: "20px", marginRight: "5px" }}
            src="https://tr3.traventia.com/graphic-design/logos/logos_metodopago/bizum_icono.svg"
            alt="Bizum logo"
          />
          {"Bizum"}
        </>
      ),
      colour: "no-background",
    },
    9: {
      name: (
        <>
          <img
            style={{ height: "20px", marginRight: "5px" }}
            src="https://tr1.traventia.com/graphic-design/logos/logos_metodopago/logomultibanco.svg"
            alt="Multibanco logo"
          />
          {"Multibanco (Referencia-entidad)"}
        </>
      ),
      colour: "no-background",
    },
    10: {
      name: (
        <>
          <img
            style={{ height: "20px", marginRight: "5px" }}
            src="https://tr1.traventia.com/graphic-design/logos/logos_metodopago/logombway_black.svg"
            alt="MB Way logo"
          />
          {"MB Way"}
        </>
      ),
      colour: "no-background",
    },
    998: {
      name: (
        <>
          <RiCustomerService2Fill style={{ fontSize: "20px", marginRight: "5px" }} />
          {"Manual"}
        </>
      ),
      colour: "no-background",
    },
    999: {
      name: (
        <>
          <BsArrowReturnRight style={{ fontSize: "20px", marginLeft: "15px", marginRight: "5px" }} />
          {"Devolución"}
        </>
      ),
      colour: "no-background",
    },
  };
};

export const PaymentMethod = ({ paymentMethodCode }) => {
  const paymentMethodsList = getPaymentTypeTranslation();
  return paymentMethodsList[paymentMethodCode]?.name || `Método nº ${paymentMethodCode}`;
};

PaymentMethod.propTypes = {};
PaymentMethod.defaultProps = {};
