import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { useRemittance, useLockdown } from "../contexts/PaymentsToSuppliersContext";

const RemittanceButton = ({ onClick }) => {
  const { remittanceLoading, remittance } = useRemittance();
  const { lockdown } = useLockdown();

  const ninvoices = remittance?.invoices.length ?? 0;

  if (lockdown) {
    return <></>;
  }

  return (
    <ButtonCustom disabled={remittanceLoading} onClick={onClick} customcolor={"green"}>
      <>
        {remittanceLoading ? (
          <>{"Cargando..."}</>
        ) : (
          <>
            {`💰 Remesa con `}
            <span style={{ margin: "0 5px" }}>
              <b>{ninvoices}</b>
            </span>
            {` factura${ninvoices !== 1 ? "s" : ""}`}
          </>
        )}
      </>
    </ButtonCustom>
  );
};

export default RemittanceButton;
