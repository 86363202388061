import React from "react";
import InputUploadFile from "@atoms/Inputs/InputUploadFile/InputUploadFile";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import VoucherManageDetailVoucherTitle from "./VoucherManageDetailVoucherTitle.view";

const VoucherManageDetailVoucherList = ({
  bookref = "",
  voucherListElements = [],
  elementKey = "",
  typeKey = "",
  completeBook = {},
  voucherType = "",
  disabled = false,
  seeVoucherPDFAction = () => { },
  uploadVoucherAction = () => { },
}) => {
  const completeBookKey = typeKey === "acc" ? "accommodations" : typeKey;
  return (
    <Table aria-label="simple table">
      <TableBody>
        {voucherListElements.map((item, index) => {
          if (completeBook[completeBookKey][index].status !== 2)
            return (
              <>
                <TableRow key={`${elementKey}-${index}-title`}>
                  <TableCell
                    width="30%"
                    key={completeBook[completeBookKey][index].servref}
                    style={{ borderBottom: "none" }}
                  >
                    <VoucherManageDetailVoucherTitle
                      typeKey={completeBookKey}
                      completeBook={completeBook}
                      elementKey={elementKey}
                      index={index}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={`${elementKey}-${index}-content`}>
                  <TableCell width="30%">
                    <div className="namebono">
                      <span className="namebonotag">{voucherType}</span>
                      {item !== undefined ? (
                        <input value={item.path} disabled></input>
                      ) : (
                        <input placeholder={"Ruta del bono en pdf"} disabled></input>
                      )}
                    </div>
                  </TableCell>
                  <TableCell width="30%">
                    <InputUploadFile
                      id={`${elementKey}_${index}`}
                      disabled={disabled}
                      handleUpload={(file) => {
                        uploadVoucherAction({
                          bookref,
                          isComplete: false,
                          type: typeKey,
                          servref: completeBook[completeBookKey][index].servref,
                          file,
                          index
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell width="10%">
                    {item?.path !== "" && (
                      <ButtonCustom
                        variant="contained"
                        customcolor="blue"
                        disabled={disabled}
                        // eslint-disable-next-line no-undef
                        onClick={() =>
                          seeVoucherPDFAction({
                            typeKey: completeBookKey,
                            bookref,
                            voucherList: item,
                            index,
                          })
                        }
                      >
                        Ver bono
                      </ButtonCustom>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );
        })}
      </TableBody>
    </Table>
  );
};

export default VoucherManageDetailVoucherList;
