import React from "react";
import { FiPlusSquare } from "react-icons/fi";
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { Spinner } from "react-bootstrap";
import { Grid, MenuItem, Select, InputLabel, List, ListItem, ListItemText } from "@material-ui/core";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import InputText from "@atoms/Inputs/InputText";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import InputNumber from "@atoms/Inputs/InputNumber";
import FxSwitch from "@atoms/Switches/FxSwitch";
import ListEditable from "@atoms/Lists/ListEditable";
import InputQueryDropdownSearch from "@atoms/Inputs/InputQueryDropdownSearch";
import MarketChip from "@atoms/Content/MarketChip";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import Modal from "@atoms/Modals/Modal";

const AffiliateReportManagementButtons = ({
  affiliateReport,
  affiliateReportId,
  creation,
  setAffiliateReport,
  saveAffiliateReport,
  deleteAffiliateReport,
  handleAlertSnackBar,
}) => {
  const sectionTitleButtons = [];
  if (creation === false) {
    sectionTitleButtons.push(
      <FxSwitch
        labels={["Habilitado", "Deshabilitado"]}
        status={affiliateReport.data.enable}
        onChange={(val) => {
          const confirm = window.confirm(
            "¿Está seguro de cambiar el estado del reporte? Recuerda guardar después."
          );
          if (confirm === true) {
            const newAff = { ...affiliateReport };
            newAff.data.enable = val;
            setAffiliateReport(newAff);
          }
        }}
      />
    );
    sectionTitleButtons.push(
      <ButtonDelete
        key={"btD"}
        color="primary"
        onClick={async () => {
          const confirm = window.confirm("¿Está seguro de eliminar el reporte?");
          if (confirm === true) {
            await deleteAffiliateReport(affiliateReportId);
            window.location = "/app/marketing/affiliate/reports";
          }
        }}
      />
    );
  }
  sectionTitleButtons.push(
    <ButtonSave
      key={"btG"}
      color="primary"
      aria-label="add"
      onClick={async () => {
        const confirm = window.confirm("¿Está seguro de guardar los cambios?");
        if (confirm === true) {
          const id = await saveAffiliateReport();
          if (id !== null) {
            handleAlertSnackBar(true, "Reporte guardado correctamente.");
            window.location = "/app/marketing/affiliate/reports/" + id;
          } else {
            handleAlertSnackBar(true, "Error al guardar el reporte.");
          }
        }
      }}
    />
  );
  return sectionTitleButtons;
};

const AffiliatesReportsManagementView = ({
  affiliateReport,
  affiliateReportId,
  creation,
  setAffiliateReport,
  deleteAffiliateReport,
  saveAffiliateReport,
  affiliates,
  setAffiliates,
  alertOpen,
  alertData,
  setAlertOpen,
  handleAlertSnackBar,
  affiliatesReportsSent,
  affiliatesReportsSentHeadTable,
  affiliatesReportsSentActions,
  validationErrors,
  setValidationErrors,
}) => {
  return (
    <>
      {affiliateReport.loading === true && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status" size="xl" />
        </div>
      )}
      {affiliateReport.loading === false && affiliateReport.data.id && (
        <Grid container>
          <Grid item xs={12}>
            <SectionTitle
              title={"Reporte de afiliado" + (creation ? " nuevo" : "")}
              backFunction={() => {
                window.location = "/app/marketing/affiliate/reports";
              }}
              buttons={AffiliateReportManagementButtons({
                affiliateReport,
                affiliateReportId,
                creation,
                setAffiliateReport,
                deleteAffiliateReport,
                saveAffiliateReport,
                handleAlertSnackBar,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TravCard>
              <TravBodyCard>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <h4>Idioma del reporte</h4>
                    <SelectMarket
                      selection={affiliateReport.data.lang || null}
                      onChangeMarket={(nVal) => {
                        const newAff = { ...affiliateReport };
                        newAff.data.lang = nVal;
                        setAffiliateReport(newAff);
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h4>Obtener reporte por</h4>
                    <Select
                      fullWidth={true}
                      labelId="select-by"
                      title="Por"
                      label="Por"
                      value={affiliateReport.data.by || "-"}
                      onChange={(nVal) => {
                        const newAff = { ...affiliateReport };
                        newAff.data.by = nVal.target.value;
                        setAffiliateReport(newAff);
                      }}
                    >
                      <MenuItem value={"-"} key={`nonenone`} style={{ color: "grey" }}>
                        Selecciona un valor
                      </MenuItem>
                      <MenuItem key={`datecreation`} value={"datecreation"}>
                        Fecha de creación
                      </MenuItem>
                      <MenuItem key={`dateo`} value={"dateo"}>
                        Fecha de salida
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h4>Frecuencia del reporte</h4>
                    <Select
                      fullWidth={true}
                      labelId="select-aplicar-sobre"
                      title="Frecuencia del reporte"
                      label="Frecuencia del reporte"
                      value={affiliateReport.data.freq || "-"}
                      onChange={(nVal) => {
                        const newAff = { ...affiliateReport };
                        newAff.data.freq = nVal.target.value;
                        setAffiliateReport(newAff);
                      }}
                    >
                      <MenuItem value={"-"} key={`nonenone`} style={{ color: "grey" }}>
                        Selecciona un valor
                      </MenuItem>
                      <MenuItem key={`valuespvp`} value={"daily"}>
                        Diario
                      </MenuItem>
                      <MenuItem key={`valuesamount`} value={"weekly"}>
                        Semanal
                      </MenuItem>
                      <MenuItem key={`valuesamount`} value={"monthly"}>
                        Mensual
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h4>Afiliados</h4>
                    <InputQueryDropdownSearch
                      label={"Buscador de afiliados"}
                      getRequestPath={`/api/v1/marketing-bc/affiliates?id[regex]=%%QUERY%%`}
                      optionFilter={(option) => {
                        console.log(affiliates);
                        return !affiliates.find((aff) => aff.affiliateId === option.affiliateId);
                      }}
                      optionFormat={(option, index) => {
                        return (
                          <div key={`affiliateIdList${index}`}>
                            <b>
                              {option.id} |{" "}
                              {option.type === "whitelabel" ? (
                                <>
                                  <span>{`Marca blanca - ${option.whitelabelConf.domain} - `}</span>
                                  <MarketChip market={option.whitelabelConf.defLang} />
                                </>
                              ) : (
                                "Referido"
                              )}
                            </b>
                          </div>
                        );
                      }}
                      onSelectOption={(nuValue) => {
                        const newAffiliates = [...affiliates];
                        newAffiliates.push(nuValue);
                        setAffiliates(newAffiliates);
                      }}
                    />
                    <List className="rounded bg-light">
                      {affiliates.length > 0 &&
                        affiliates.map((affiliate, index) => (
                          <ListItem key={`affiliateIdListItem${index}`} className="py-1">
                            <div className="bg-white d-flex justify-content-between w-100 border rounded align-items-center pl-2">
                              <b>
                                {affiliate.id} |{" "}
                                {affiliate.type === "whitelabel" ? (
                                  <>
                                    <span>{`Marca blanca - ${affiliate.whitelabelConf.domain} - `}</span>
                                    <MarketChip market={affiliate.whitelabelConf.defLang} />
                                  </>
                                ) : (
                                  "Referido"
                                )}
                              </b>

                              <ButtonDelete
                                className="right h-100"
                                key={"btD"}
                                color="primary"
                                onClick={async () => {
                                  const confirm = window.confirm(
                                    `¿Está seguro de eliminar el afiliado ${affiliate.id}?`
                                  );
                                  if (confirm) {
                                    const newAffiliates = [...affiliates];
                                    newAffiliates.splice(index, 1);
                                    setAffiliates(newAffiliates);
                                  }
                                }}
                              />
                            </div>
                          </ListItem>
                        ))}
                    </List>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <ListEditable
                      title={"Emails"}
                      info={affiliateReport.data.emails}
                      form={affiliateReport.data}
                      validation={(email) => {
                        const re = /[^\s@]+@[^\s@]+\.[^\s@]+/;
                        return re.test(email);
                      }}
                      typeInfo={"tick"}
                      onChangeList={(nuEmails) => {
                        const newAff = { ...affiliateReport };
                        newAff.data.emails = nuEmails.map((email) => {
                          return email.trim().toLowerCase();
                        });
                        setAffiliateReport(newAff);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ListEditable
                      title={"Emails BCC"}
                      info={affiliateReport.data.emailsBcc}
                      form={affiliateReport.data}
                      typeInfo={"tick"}
                      onChangeList={(nuEmails) => {
                        const newAff = { ...affiliateReport };
                        newAff.data.emailsBcc = nuEmails;
                        setAffiliateReport(newAff);
                      }}
                    />
                  </Grid>
                </Grid>
              </TravBodyCard>
            </TravCard>
            <TravCard>
              <TravBodyCard>
                <FenixTable
                  tableTitle={"Histórico de envíos"}
                  loading={affiliatesReportsSent.loading}
                  tableHead={affiliatesReportsSentHeadTable}
                  tableContent={affiliatesReportsSent.data}
                  // actions={affiliatesReportsSentActions}
                  maxNumberPerPages={5}
                  pagination={true}
                  isSelectable={false}
                />
              </TravBodyCard>
            </TravCard>
          </Grid>
        </Grid>
      )}
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
      <Modal
        show={validationErrors.length > 0}
        fullWidth={false}
        onClose={() => {
          setValidationErrors([]);
        }}
      >
        <ul>
          {validationErrors.map((error, index) => (
            <li key={`error${index}`}>{error}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default AffiliatesReportsManagementView;
