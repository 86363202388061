import React from "react";
// import PropTypes from "prop-types";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";

const InputNumber = ({
  label,
  value,
  required,
  isCurrency,
  startAdornment,
  endAdornment,
  onChange,
  min,
  max,
  disabled,
  ...props
}) => {
  const labelValue = `${label} ${required ? "*" : ""}`;

  const inputValue = isCurrency ? value / 100 : value;

  function onChangeInput(event) {
    let newValue = event.target.value;

    if (min !== undefined) {
      if (newValue < min) {
        newValue = min;
      }
    }
    if (max !== undefined) {
      if (newValue > max) {
        newValue = max;
      }
    }

    if (isCurrency) {
      newValue *= 100;
    }
    onChange(newValue);
  }

  return (
    <FormControl {...props} fullWidth variant="outlined">
      <InputLabel htmlFor="component-outlined">{labelValue}</InputLabel>
      <OutlinedInput
        {...props}
        label={labelValue}
        id="component-outlined"
        type={"number"}
        defaultValue={inputValue}
        onChange={onChangeInput}
        disabled={disabled}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      />
    </FormControl>
  );
};

InputNumber.propTypes = {};

InputNumber.defaultProps = { required: false, isCurrency: false };

export default InputNumber;
