import React, { Fragment, useState, useEffect, useCallback } from "react";
import { BiImageAdd } from "react-icons/bi";
import { FaTrashAlt, FaBed, FaTags, FaDownload, FaCopy } from "react-icons/fa";
import { MdClear, MdFileDownload, MdLabel, MdHotel } from "react-icons/md";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import ErrorModal from "@atoms/Modals/ErrorModal";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import FileDownload from "js-file-download";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import ModalCrop from "./components/ModalCrop";
import TagAssignmentModal from "./components/TagAssignmentModal";
import AccommodationAssignmentModal from "./components/AccommodationAssignmentModal";
import { usePath } from "../../FileManagerContext";
import { ListFilesDirectory } from "../FileDirectory/FileDirectory.style";
import { getFilesInFolder, deleteFileInFolder, downloadFile } from "../../hooks/useFileFetch";

function parseFilesDataToView(files) {
  return files.map((file) => ({ ...file, thumb: `${file.url}?thumb=true` }));
}

const tableHead = [
  { code: "thumb", name: "", type: "image" },
  { code: "name", name: "Nombre", type: "string" },
  { code: "url", name: "Url", type: "url" },
  { code: "tags", name: "Tags", type: "tags" },
];

const FileFolderContent = () => {
  const [{ path, bucket }] = usePath();
  const [files, setFiles] = useState({ loading: true, data: [], error: null });
  const [errors, setErrors] = useState({ show: false, list: [] });

  const [alertText, setAlertText] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const [showAddImages, setShowAddImages] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [assignAccommodationModalOpen, setAssignAccommodationModalOpen] = useState(false);

  const [rowsChecked, setRowsChecked] = useState({});
  const [rowsCheckedValues, setRowsCheckedValues] = useState([]);
  const [selectedRowsForAction, setSelectedRowsForAction] = useState([]);

  const getFiles = useCallback(
    async function getFilesFn() {
      await getFilesInFolder(bucket, path).then((data) => {
        if (data.error !== null) {
          setErrors({
            show: true,
            list: [`Error al obtener archivos`, JSON.stringify(data.error)],
          });
        }
        const resp = parseFilesDataToView(data.data);
        data.data = resp;
        setFiles(data);
      });
    },
    [bucket, path]
  );

  function onChangeCropModal(showAddImagesBt) {
    if (!showAddImagesBt) {
      getFiles();
    }
    setShowAddImages(showAddImagesBt);
  }

  const deleteFile = (row) => {
    setSelectedRowsForAction([row]);
    setDeleteModalOpen(true);
  };

  const multipleDeleteFile = () => {
    setSelectedRowsForAction(rowsCheckedValues);
    setDeleteModalOpen(true);
  };

  const tagAssignation = (row) => {
    setSelectedRowsForAction([row]);
    setTagModalOpen(true);
  };

  const multipleTagAssignation = () => {
    setSelectedRowsForAction(rowsCheckedValues);
    setTagModalOpen(true);
  };

  const accommodationAssignation = (row) => {
    setSelectedRowsForAction([row]);
    setAssignAccommodationModalOpen(true);
  };

  const multipleAccommodationAssignation = () => {
    setSelectedRowsForAction(rowsCheckedValues);
    setAssignAccommodationModalOpen(true);
  };

  const executeDownloadFiles = async (filesToDownload) => {
    setFiles({ loading: true, data: [] });
    const errorList = [];
    for (let index = 0; index < filesToDownload.length; index += 1) {
      const file = filesToDownload[index];
      const response = await downloadFile(file.bucket, file.path);
      if (!response.ok) {
        errorList.push(`No se ha podido descargar el archivo ${file.path} - ${bucket}`);
      } else {
        FileDownload(Buffer.from(response.data.Body), file.name);
      }
    }
    if (errorList.length > 0) {
      setErrors({
        show: true,
        list: errorList,
      });
    } else {
      setAlertOpen(true);
      setAlertText("Archivos descargados con éxito");
    }
    getFiles();
  };

  const filesDownload = async (row) => {
    await executeDownloadFiles([row]);
  };

  const multipleFilesDownload = async () => {
    await executeDownloadFiles(rowsCheckedValues);
  };

  const multipleFilesCopyUrl = () => {
    const urls = rowsCheckedValues.map((row) => row.url).join(",");
    navigator.clipboard.writeText(urls);
    setAlertOpen(true);
    setAlertText("Urls copiadas en portapapeles");
  };

  const executeDeleteFiles = async () => {
    setDeleteModalOpen(false);
    setFiles({ loading: true, data: [] });
    const errorList = [];
    for (let index = 0; index < selectedRowsForAction.length; index += 1) {
      const file = selectedRowsForAction[index];
      const { ok } = await deleteFileInFolder(file.bucket, file.path);
      if (!ok) {
        errorList.push(`No se ha podido borrar el archivo ${file.path} - ${bucket}`);
      }
    }
    if (errorList.length > 0) {
      setErrors({
        show: true,
        list: errorList,
      });
    } else {
      setAlertOpen(true);
      setAlertText("Archivos borrados con éxito");
    }
    getFiles();
  };

  const singleFileActions = [
    { name: "Borrar Imágen", icon: <MdClear />, func: deleteFile },
    { name: "Añadir/editar Tags en Imágen", icon: <MdLabel />, func: tagAssignation },
    { name: "Asignar Imágen a Hotel", icon: <MdHotel />, func: accommodationAssignation },
    { name: "Descargar Imágen", icon: <MdFileDownload />, func: filesDownload },
  ];

  const multipleFileActionsButtons = [
    {
      show: true,
      title: `Borrar ${rowsCheckedValues.length} imágenes`,
      action: multipleDeleteFile,
      icon: <FaTrashAlt />,
    },
    {
      show: true,
      title: `Copiar url de ${rowsCheckedValues.length} imágenes`,
      action: multipleFilesCopyUrl,
      icon: <FaCopy />,
    },
    {
      show: true,
      title: `Descargar ${rowsCheckedValues.length} imágenes`,
      action: multipleFilesDownload,
      icon: <FaDownload />,
    },
    {
      show: true,
      title: `Añadir/editar tags a ${rowsCheckedValues.length} imágenes`,
      action: multipleTagAssignation,
      icon: <FaTags />,
    },
    {
      show: ["pruebas-1", "cdn-tr-hotels-v2"].includes(bucket),
      title: `Asignar ${rowsCheckedValues.length} imágenes a hotel`,
      action: multipleAccommodationAssignation,
      icon: <FaBed />,
    },
  ];

  useEffect(() => {
    getFiles();
  }, [getFiles, path]);

  useEffect(() => {
    const value = Object.keys(rowsChecked).reduce((acc, rowIndex) => {
      if (rowsChecked[rowIndex]) {
        acc.push(files.data[rowIndex]);
      }
      return acc;
    }, []);
    setRowsCheckedValues(value);
  }, [files.data, rowsChecked]);

  return (
    <Fragment>
      {/* Botones acción */}
      <Paper elevation={3} style={{ overflow: "hidden", marginBottom: 15 }}>
        <ListFilesDirectory>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              padding: "0 10px",
            }}
          >
            <ButtonCustom
              size="small"
              color="secondary"
              icon={<BiImageAdd />}
              onClick={() => setShowAddImages(true)}
            >
              Añadir Imágenes
            </ButtonCustom>
            <div>
              {multipleFileActionsButtons.map((button, index) => (
                <Fragment key={index}>
                  {button.show && (
                    <Fragment>
                      <Tooltip title={button.title}>
                        <span>
                          <IconButton
                            color="primary"
                            onClick={button.action}
                            disabled={rowsCheckedValues.length === 0}
                          >
                            {button.icon}
                          </IconButton>
                        </span>
                      </Tooltip>
                      {index < multipleFileActionsButtons.filter((act) => act.show).length - 1 && (
                        <span
                          style={{
                            height: "34px",
                            width: "1px",
                            borderRight: "1px solid #ddd",
                            padding: "0 3px",
                          }}
                        ></span>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </ListFilesDirectory>
      </Paper>

      {/* Tabla files */}
      <FenixTable
        loading={files.loading}
        tableHead={tableHead}
        tableContent={files.data}
        actions={singleFileActions}
        showInputFilter={false}
        isSelectable={true}
        allowSelectAll={true}
        selectedRows={rowsCheckedValues}
        onChangeSelections={setRowsCheckedValues}
      />
      {/* Modal subida y recorte */}
      <ModalCrop
        show={showAddImages}
        filesInFolder={files.data}
        onChangeCropModal={(value) => onChangeCropModal(value)}
      />

      {/* Modal borrado */}
      <ConfirmationModal
        show={deleteModalOpen}
        title={`Se va a proceder a borrar los siguientes archivos`}
        body={
          <Fragment>
            <ul>
              {selectedRowsForAction.map((row, index) => (
                <li key={index}>{row.name}</li>
              ))}
            </ul>
            <br />
            <p>
              {"Esta acción no puede revertirse. ¿Seguro que desea borrar permanentemente estos archivos?"}
            </p>
          </Fragment>
        }
        onConfirm={executeDeleteFiles}
        onDeny={() => {
          setDeleteModalOpen(false);
        }}
      />

      {/* Modal asignación tags */}
      <TagAssignmentModal
        open={tagModalOpen}
        bucket={bucket}
        selectedRows={selectedRowsForAction}
        handleCloseModal={(res) => {
          setTagModalOpen(false);
          if (res.execution) {
            if (!res.error) {
              setAlertOpen(true);
              setAlertText("Tags actualizados");
            }
            getFiles();
          }
        }}
      />

      {/* Modal asignación a hoteles */}
      <AccommodationAssignmentModal
        open={assignAccommodationModalOpen}
        bucket={bucket}
        selectedRows={selectedRowsForAction}
        handleCloseModal={(res) => {
          setAssignAccommodationModalOpen(false);
          if (res.execution) {
            if (!res.error) {
              setAlertOpen(true);
              setAlertText("Imágenes asignadas a hotel con éxito");
            }
            getFiles();
          }
        }}
      />

      {/* Modal error */}
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => {
          setErrors({ show: false, list: [] });
        }}
      />

      {/* Alerta */}
      <AlertSnackbar show={alertOpen} text={alertText} setOpen={setAlertOpen} />
    </Fragment>
  );
};

export default FileFolderContent;
