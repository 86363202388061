import React, { useState } from "react";
import { FaLock } from "react-icons/fa";
import { CircularProgress } from "@material-ui/core";
import CalendarDayEditingModal from "./CalendarDayEditingModal";
import { StyDivDay } from "./CalendarDay.styles";

function CalendarDay({
  loading,
  type,
  dataForDay,
  fieldToShow,
  calendarLength,
  highlighted,
  parentEnabled,
  fieldExclusions,
  onClickInDay,
}) {
  const openInClosedParent = dataForDay.enable && !parentEnabled;
  const [openModal, setOpenModal] = useState(false);

  function getCellValue() {
    if (["cost", "sellingPrice"].includes(fieldToShow)) {
      if (dataForDay.price !== undefined) {
        if (dataForDay.price[fieldToShow] !== undefined) {
          const num = dataForDay.price[fieldToShow] / 100;
          let fix = 2;
          if (num % 1 === 0) {
            fix = 0;
          }
          return num.toFixed(fix);
        }
      }
    }

    if (dataForDay[fieldToShow] === undefined) {
      if (dataForDay.parent !== undefined && dataForDay.parent[fieldToShow] !== undefined) {
        return <span style={{ color: "yellow" }}>{dataForDay.parent[fieldToShow]}</span>;
      }
      return "-";
    }

    return dataForDay[fieldToShow];
  }

  function onClickEditing(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  function closeModal() {
    setOpenModal(false);
  }

  function onClickThisDay() {
    if (!loading) {
      onClickInDay({ ...dataForDay, enable: !dataForDay.enable });
    }
  }

  return (
    <>
      <StyDivDay
        pointer={!loading}
        calendarLength={calendarLength}
        enable={dataForDay.enable}
        highlighted={highlighted}
        parentEnabled={parentEnabled}
        onClick={onClickThisDay}
        onContextMenu={onClickEditing}
      >
        <div style={{ textAlign: "center", color: "#fff" }}>
          {loading ? (
            <CircularProgress color="inherit" size={10} />
          ) : (
            <>
              {fieldToShow !== undefined ? (
                <span style={{ fontSize: "8px" }}>{getCellValue()}</span>
              ) : (
                <>{(!dataForDay.enable || openInClosedParent) && <FaLock />}</>
              )}
            </>
          )}
        </div>
      </StyDivDay>
      {openModal && (
        <CalendarDayEditingModal
          open={openModal}
          type={type}
          dataForDay={dataForDay}
          fieldExclusions={fieldExclusions}
          onClose={closeModal}
          onSend={(newDayData) => onClickInDay(newDayData)}
        />
      )}
    </>
  );
}

CalendarDay.defaultProps = {
  loading: false,
  highlighted: false,
  parentEnabled: true,
};

export default CalendarDay;
