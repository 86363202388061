import React, { useState, useEffect, useCallback } from "react";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import InputText from "@atoms/Inputs/InputText";
import SectionTitle from "@atoms/Titles/SectionTitle";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import CalendarUpdateRule from "./CalendarUpdateRule";
import {
  fetchCalendarUpdateRules,
  saveCalendarUpdateRule,
  deleteCalendarUpdateRule,
} from "./daoCalendarUpdateRules";

function CalendarUpdateRulesSection({ precinctCode }) {
  const history = useHistory();
  const [loadingRules, setLoadingRules] = useState(false);
  const [saved, setSaved] = useState(false);
  const [rules, setRules] = useState([]);
  const [errors, setErrors] = useState([]);

  const fetchProductsCalendarRules = useCallback(async () => {
    setLoadingRules(true);
    const { data: calendarUpdateProducts } = await fetchCalendarUpdateRules(precinctCode);
    setRules(calendarUpdateProducts);
    setLoadingRules(false);
  }, [precinctCode]);

  async function saveOneCalendarUpdateRule(ruleToSave) {
    try {
      await saveCalendarUpdateRule(ruleToSave);
      await fetchProductsCalendarRules();
      setSaved(true);
    } catch (e) {
      setErrors([e.message]);
      setLoadingRules(false);
    }
  }
  async function deleteOneCalendarUpdateRule(ruleToDelete) {
    if (confirm(`¿Seguro que quieres borrar <${ruleToDelete.name}>?`)) {
      try {
        await deleteCalendarUpdateRule(ruleToDelete);
        await fetchProductsCalendarRules();
      } catch (e) {
        setErrors([e.message]);
      }
    }
  }

  useEffect(() => {
    fetchProductsCalendarRules();
  }, [fetchProductsCalendarRules]);

  async function addRule() {
    const nuRule = {
      id: v4(),
      precinctCode,
      updateEnable: false,
      enable: false,
      isDefaultRule: false,
      name: `Nueva regla ${precinctCode} ${rules.length + 1}`,
    };

    await saveOneCalendarUpdateRule(nuRule);
  }

  function onChangeRule(indexRule, newRule) {
    const nuRules = [...rules];
    nuRules[indexRule] = newRule;
    setRules(nuRules);
  }

  function blockEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  function goToCalendar() {
    const ticketingSectionPathname = history.location.pathname.split("/").slice(0, 5);
    ticketingSectionPathname.push("calendar");
    ticketingSectionPathname.push("products");
    history.push(`${ticketingSectionPathname.join("/")}`);
  }

  return (
    <>
      <SectionTitle
        title={
          <>
            {"Reglas actualización de calendarios "}
            <b>{precinctCode}</b>
          </>
        }
        buttons={[<ButtonAdd key={0} onClick={addRule} >Añadir</ButtonAdd>]}
        backFunction={goToCalendar}
      />
      <TravCard>
        <TravBodyCard>
          {loadingRules ? (
            <CenterLoaderCircle />
          ) : (
            <>
              {rules.map((rule, index) => (
                <AccordionItem
                  open={true}
                  key={`rule${index}`}
                  title={
                    <>
                      <InputText
                        onClick={blockEvent}
                        title={"Nombre identificativo"}
                        value={rule.name}
                        onChange={(newName) => onChangeRule(index, { ...rule, name: newName.value })}
                      />

                      <ButtonDelete
                        style={{ borderRadius: 0 }}
                        onClick={(e) => {
                          blockEvent(e);
                          deleteOneCalendarUpdateRule(rule);
                        }}
                      />
                      <ButtonSave
                        style={{ borderRadius: 0 }}
                        onClick={(e) => {
                          blockEvent(e);
                          saveOneCalendarUpdateRule(rule);
                        }}
                      />
                    </>
                  }
                >
                  <CalendarUpdateRule rule={rule} onChange={(newRule) => onChangeRule(index, newRule)} />
                </AccordionItem>
              ))}
            </>
          )}
        </TravBodyCard>
      </TravCard>

      <AlertSnackbar
        show={errors.length > 0}
        text={errors.join("\n")}
        setOpen={() => setErrors([])}
        severity={"error"}
        duration={3000}
      />
      <AlertSnackbar show={saved} text={"¡Regla guardada!"} setOpen={() => setSaved(false)} duration={2000} />
    </>
  );
}

export default CalendarUpdateRulesSection;
