import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import ProvidersAccommodationsPage from "./providers/ProvidersAccommodations";

function RouteMoebius({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/moebius`;
  return (
    <Fragment>
      <Route exact path={`${mainUrl}/providers/accommodations`} component={ProvidersAccommodationsPage} />
    </Fragment>
  );
}

export default RouteMoebius;
