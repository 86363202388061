/* eslint-disable no-use-before-define */
import _ from "lodash";
import { fetchPrecinct, fetchPrecinctChannels, savePrecinctChannel } from "./usePrecincts";
import bookingModes from "../shared/ChannelConfigurationSelector/data/bookingModes.json";
import voucherModes from "../shared/ChannelConfigurationSelector/data/voucherModes.json";
// import { csvSpecChannels } from "./constants";
import { getChannelID } from "../shared/domainVariables";

/**
 *
 * @param {*} precinctChannelsList
 */
async function bulkUploadPrecinctsChannels(precinctChannelsCsv) {
  try {
    const precinctCodes = [...new Set(precinctChannelsCsv.map((t) => t.precinctCode))];

    const promisesPrecincts = precinctCodes.map((tk) => fetchPrecinct(tk));
    const promisesChannels = precinctCodes.map((tk) => fetchPrecinctChannels(tk));
    const responseChannels = await Promise.all(promisesChannels);
    const responsePrecincts = await Promise.all(promisesPrecincts);

    //* ** Get precincts saved
    const currentPrecinctsByCode = responsePrecincts.flat().reduce((a, p) => ({ ...a, [p.code]: p }), {});
    const currentChannelsByCode = responseChannels.flat().reduce((a, p) => {
      const nAcc = { ...a };
      if (nAcc[p.precinctCode] === undefined) {
        nAcc[p.precinctCode] = [];
      }
      nAcc[p.precinctCode].push(p);
      return nAcc;
    }, {});

    //* ** Validation
    const errorsValidation = precinctChannelsValidation(precinctChannelsCsv, currentPrecinctsByCode);
    if (errorsValidation.length > 0) {
      return { errors: errorsValidation };
    }

    //* ** Get the intersection between the two
    const toSave = extractPrecinctChannelsToSave(precinctChannelsCsv, currentChannelsByCode);

    //* ** Save the results
    const { saved, errors } = await requestsForChannels(toSave);

    return { saved, errors };
  } catch (e) {
    return { errors: [e.message] };
  }
}

function precinctChannelsValidation(precinctChannelsCsv, currentPrecinctsByCode) {
  const bookingModesKV = bookingModes.map((bm) => bm.code);
  const voucherModesKV = voucherModes.map((bm) => bm.code);

  const errors = [];
  precinctChannelsCsv.forEach((precinctChannel) => {
    try {
      if (currentPrecinctsByCode[precinctChannel.precinctCode] === undefined) {
        throw new Error(`Recinto <${precinctChannel.precinctCode}> no existe.`);
      }

      if (!bookingModesKV.includes(precinctChannel.booking.mode)) {
        throw new Error(`Modo de reserva <${precinctChannel.booking.mode}> no existe.`);
      }

      if (!precinctChannel.voucher.inherited && !voucherModesKV.includes(precinctChannel.voucher.mode)) {
        throw new Error(
          `Modo de bono no es el mismo que Modo de reserva y <${precinctChannel.booking.mode}> no existe.`
        );
      }

      if (
        !precinctChannel.cancellation.inherited &&
        !bookingModesKV.includes(precinctChannel.cancellation.mode)
      ) {
        throw new Error(
          `Modo de cancelación no es el mismo que Modo de reserva y <${precinctChannel.booking.mode}> no existe.`
        );
      }
    } catch (e) {
      errors.push(e.message);
    }
  });

  return errors;
}

function extractPrecinctChannelsToSave(precinctChannelsCsv, currentChannelsByCode) {
  const toSave = [];

  precinctChannelsCsv.forEach((precinctChannel) => {
    const { precinctCode } = precinctChannel;

    const precinctChannelToSave = { ...precinctChannel };

    if (precinctChannelToSave.channel.merchant === "") {
      delete precinctChannelToSave.channel.merchant;
    }

    if (precinctChannelToSave.booking.mode === "stemail") {
      if (Object.keys(precinctChannelToSave.booking.configuration).length === 0) {
        precinctChannelToSave.booking.configuration = {
          email: [],
          cco: [],
          include: { phone: false, complete: false, accommodation: false },
        };
      }
      if (precinctChannelToSave.booking.configuration.include.accommodation === "") {
        precinctChannelToSave.booking.configuration.include.accommodation = false;
      }
      if (precinctChannelToSave.booking.configuration.include.complete === "") {
        precinctChannelToSave.booking.configuration.include.complete = false;
      }
      if (precinctChannelToSave.booking.configuration.include.phone === "") {
        precinctChannelToSave.booking.configuration.include.phone = false;
      }
    }

    if (currentChannelsByCode[precinctCode] === undefined) {
      toSave.push(precinctChannelToSave);
    } else {
      const channelId = getChannelID(precinctChannelToSave.channel);
      const match = currentChannelsByCode[precinctCode].find(
        (current) => getChannelID(current.channel) === channelId
      );

      if (match === undefined) {
        toSave.push(precinctChannelToSave);
      } else {
        const isEqual = _.isEqual(match, precinctChannelToSave);
        if (!isEqual) {
          toSave.push(precinctChannelToSave);
        }
      }
    }
  });

  return toSave;
}

async function requestsForChannels(channelsToSave) {
  const errorsChannels = [];
  let saved = 0;
  for (let index = 0; index < channelsToSave.length; index += 1) {
    const chToAdd = channelsToSave[index];
    try {
      const { precinctCode, channel } = chToAdd;
      await savePrecinctChannel(precinctCode, channel, chToAdd);
      saved += 1;
    } catch (e) {
      errorsChannels.push(`Error en codigo ${chToAdd.precinctCode}: ${e.message}`);
    }
  }

  return { saved, errors: errorsChannels };
}

export default bulkUploadPrecinctsChannels;
