import styled from "styled-components";

const IconResultsLine = styled.span`
  font-size: 20px;
  margin-right: 10px;
`;

const SearchQueryResultsLineTitle = ({ element }) => {
  return (
    <div
      key={element.text}
      style={{
        padding: "10px 16px",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        fontWeight: "bold",
        cursor: "default",
      }}
    >
      {element.icon && <IconResultsLine className={element.icon}></IconResultsLine>}
      {element.text}
    </div>
  );
};
export default SearchQueryResultsLineTitle;
