/* eslint-disable no-use-before-define */
import React from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { RiImageEditFill } from "react-icons/ri";
import ButtonsGlobalVisibility from "@atoms/Buttons/ButtonsGlobalVisibility";
import MonthSelectorButtons from "@atoms/Selectors/MonthSelectorButtons";
import FxAlert from "@atoms/Alerts/FxAlert";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import YearSelector from "@atoms/Selectors/YearSelector";
import withSkeleton from "@lib/layout/withSkeleton";
import CalendarSkeleton from "./Calendar.skeleton";
import CalendarLegend from "./CalendarLegend";
import PriceModeSelector from "./PriceModeSelector";
import ButtonNavegation from "../../shared/ButtonNavegation";

function CalendarTemplate({
  calendarIsNotEmpty,
  month,
  year,
  field,
  errors,
  onChangeMonth,
  onChangeYear,
  onChangeErrorList,
  onChangePriceMode,
  changeAllProductVisibility,
  children,
}) {
  const history = useHistory();

  function goToMassUpdate() {
    const ticketingSectionPathname = history.location.pathname.split("/");
    const k = ticketingSectionPathname.pop();
    history.push([...ticketingSectionPathname, `${k}-update`].join("/"));
  }

  return (
    <Grid container spacing={2}>
      <AlertSnackbar
        show={errors.length > 0}
        text={errors.join("\n")}
        setOpen={() => onChangeErrorList([])}
        severity={"error"}
      />
      <Grid item xs={3} style={{ display: "inline-flex" }}>
        <CalendarLegend />
        <YearSelector year={year} onChangeYear={onChangeYear} limitPast={1} limitFuture={1} />
      </Grid>
      <Grid item xs={5}>
        <PriceModeSelector priceMode={field} onChangePriceMode={onChangePriceMode} />
      </Grid>
      <Grid item xs={4}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <ButtonsGlobalVisibility
            onClickShow={() => changeAllProductVisibility(true)}
            onClickHide={() => changeAllProductVisibility(false)}
          />

          <ButtonNavegation
            size={"small"}
            direction={"right"}
            label={"Modificación"}
            onClick={goToMassUpdate}
            icon={<RiImageEditFill size={20} color={"white"} />}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        {!calendarIsNotEmpty ? (
          <FxAlert variant={"info"}>Este recinto no tiene productos</FxAlert>
        ) : (
          <>
            <MonthSelectorButtons monthSelected={month} onChangeMonth={onChangeMonth} />
            {children}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default withSkeleton(CalendarTemplate, CalendarSkeleton);
