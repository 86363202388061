import React, { useState } from "react";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import FxAlert from "@atoms/Alerts/FxAlert";
import SupplierBlockListSkeleton from "./SupplierBlockList.skeleton";
import { usePendingPayments, useLockdown } from "../../contexts/PaymentsToSuppliersContext";
import SupplierBlock from "./SupplierBlock";
import SupplierBlockTitle from "./SupplierBlockTitle";
import LockdownMode from "../LockdownMode/LockdownMode";

const SupplierBlockList = () => {
  const { pendingLoading, pendingList } = usePendingPayments();
  const { lockdown } = useLockdown();
  const [openings, setOpenings] = useState([]);

  function changeOpening(index, newValue) {
    const newVlue = [...openings];
    newVlue[index] = newValue;
    setOpenings(newVlue);
  }

  if (pendingLoading) {
    return <SupplierBlockListSkeleton />;
  }

  if (lockdown) {
    return <LockdownMode />;
  }

  return (
    <>
      {pendingList.length === 0 ? (
        <FxAlert>No hay facturas pendientes de añadir a una remesa de ningun supplier</FxAlert>
      ) : (
        <>
          {pendingList
            .sort((a, b) => (a.supplier > b.supplier ? 1 : -1))
            .map((supplier, index) => (
              <AccordionItem
                key={`SupplierBlock_${supplier.supplier}`}
                title={<SupplierBlockTitle supplier={supplier} />}
                onChange={(newValue) => changeOpening(index, newValue)}
              >
                <SupplierBlock supplier={supplier.supplier} open={openings[index]} />
              </AccordionItem>
            ))}
        </>
      )}
    </>
  );
};

export default SupplierBlockList;
