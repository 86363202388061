import React, { Fragment, useState } from "react";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import SectionTitle from "@atoms/Titles/SectionTitle";
import InputText from "@atoms/Inputs/InputText";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import Grid from "@material-ui/core/Grid";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import InputQueryDropdownSearchTwoRequest from "@atoms/Inputs/InputQueryDropdownSearchTwoRequest";
import DraggableList from "@atoms/Lists/DraggableList";
import { AlerTitle } from "@atoms/Titles/Titles.styles";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import ButtonSend from "@atoms/Buttons/ButtonSend";
import { AiFillEye, AiOutlineReload } from "react-icons/ai";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import HtmlTextEditor from "@atoms/Inputs/HtmlTextEditor/HtmlTextEditor";
import FxChip from "@atoms/Content/FxChip";
import Tooltip from "@material-ui/core/Tooltip";
import { Alert } from "@material-ui/lab";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";

const getInfoStrOffers = (offers, idsOrdered) => {
  if (offers.loading) {
    return [];
  }
  const sortedOffers = idsOrdered
    .map((id) =>
      offers.offers.find((offer) => {
        return offer.idOffer === id;
      })
    )
    .filter((cv) => cv);

  return sortedOffers.map((cv) => [
    <div key={cv.idOffer} style={{ width: "100%", display: "flex" }}>
      <div>
        {!cv.enable && (
          <FxChip
            style={{ width: "100px" }}
            label={"Deshabilitada"}
            colour="#e0e0e0"
            colourtext={"rgba(0, 0, 0, 0.87)"}
            size="small"
          />
        )}
        {cv.enable && (
          <FxChip
            style={{ width: "100px" }}
            label={"Habilitada"}
            colour="#c9f7f5"
            colourtext={"#06a29b"}
            size="small"
          />
        )}
      </div>
      {cv.name}
    </div>,
  ]);
};

const NewsletterThirdPartyListView = ({
  isCreation,
  newsletter,
  setNewsletter,
  goToList,
  saveNewsletter,
  previewNewsletterAction,
  publishNewsletterAction,
  alertOpen,
  setAlertOpen,
  alertData,
  offers,
  setOffers,
  countingWords,
  score,
  loadScore,
}) => {
  const MAX_CHARACTERS_RECOMMENDED_SUBJECT = "35 y 50";
  const MAX_CHARACTERS_RECOMMENDED_PREVIEW = "35 y 90";
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorTop, setErrorTop] = useState(false);
  const [errorTipTitle, setErrorTipTitle] = useState(null);
  const [errorTipTop, setErrorTipTop] = useState(null);
  const checkHTML = function (html) {
    const htmlString = `<div>${html}</div>`;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "application/xml");
    return doc.querySelector("parsererror");
  };
  const sectionTitleButtons = [];
  if (isCreation === false) {
    sectionTitleButtons.push(
      <>
        {score.pass === null ? (
          <span>Cargando...</span>
        ) : (
          <>
            <Alert severity={"info"}>La mejor puntuación posible es 0</Alert>
            <AiOutlineReload onClick={loadScore} style={{ cursor: "pointer" }} />
            <Tooltip
              title={
                <>
                  {score.reasons.map((reason, index) => {
                    return <p key={`reason${index}`}>{reason}</p>;
                  })}
                </>
              }
            >
              <span className={score.pass === true ? "text-success" : "text-danger"}>
                <b>Puntuación spam: {score.score}</b>
              </span>
            </Tooltip>
          </>
        )}
      </>
    );
  }
  sectionTitleButtons.push(
    ...[
      <ButtonCustom
        key={"btV"}
        customcolor="lightblue"
        disabled={isCreation}
        icon={<AiFillEye />}
        onClick={() => previewNewsletterAction(newsletter.newsletter.id)}
      >
        Visualizar
      </ButtonCustom>,
      <ButtonSend disabled={isCreation} key={"btP"} color="primary" onClick={publishNewsletterAction}>
        Publicar
      </ButtonSend>,
      <ButtonSave
        key={"btG"}
        color="primary"
        aria-label="add"
        onClick={saveNewsletter}
        disabled={errorTitle || errorTop}
      >
        Guardar
      </ButtonSave>,
    ]
  );
  if (errorTop || errorTitle) {
    sectionTitleButtons.push(<span className="text-danger">Con errores</span>);
  }
  return (
    <>
      <SectionTitle
        title={(isCreation === true && "Crear newsletter") || newsletter.newsletter.name}
        backFunction={goToList}
        buttons={sectionTitleButtons}
      />
      <TravCard>
        <TravBodyCard>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <h5>
                <b>Configuracion para uso interno</b>
              </h5>
            </Grid>
            {/* <Grid item xs={4}>
              <h6 style={{ color: "silver" }}>Nombre de la campaña</h6>
            </Grid> */}
            <Grid item xs={12}>
              <InputText
                title="Nombre de la campaña"
                value={newsletter.newsletter.name}
                required={true}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  nuNewsletter.newsletter.name = nVal.value;
                  setNewsletter(nuNewsletter);
                }}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12}>
              <h5>
                <b>Configuracion para uso externo</b>
              </h5>
            </Grid>
            {/* <Grid item xs={12}>
              <h4 style={{ color: "silver" }}>
                <b>Configuración </b>
              </h4>
            </Grid> */}
            <Grid item xs={12}>
              <p style={{ color: "silver" }}>Mercado al que aplica *</p>
              <SelectMarket
                key={"bt-market"}
                withConfirm={true}
                confirmMessage={`Al cambiar de mercado se borrarán las ofertas seleccionadas
¿Desea continuar?`}
                selection={newsletter.newsletter.market}
                onChangeMarket={(newMarket) => {
                  const nuNewsletter = { ...newsletter };
                  nuNewsletter.newsletter.market = newMarket;
                  nuNewsletter.newsletter.template.config.offers = [];
                  setNewsletter(nuNewsletter);
                  setOffers({
                    loading: false,
                    offers: [],
                  });
                }}
              />
            </Grid>
            {!newsletter.newsletter.market && (
              <Grid item xs={12}>
                <AlerTitle variant="warning">
                  <b>Atención:</b> No podrás configurar la oferta hasta que no selecciones el mercado.
                </AlerTitle>
              </Grid>
            )}

            <Grid item xs={12}>
              <h6>
                <b>Asunto del email</b>
              </h6>
            </Grid>
            <Grid item xs={6}>
              <InputText
                title="Asunto"
                value={newsletter.newsletter.subject}
                required={true}
                disabled={!newsletter.newsletter.market}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  nuNewsletter.newsletter.subject = nVal.value;
                  setNewsletter(nuNewsletter);
                }}
              />
              {countingWords(newsletter.newsletter.subject) > 0
                ? `Caracteres: ${countingWords(
                    newsletter.newsletter.subject
                  )} - Recomendados: ${MAX_CHARACTERS_RECOMMENDED_SUBJECT}`
                : `Caracteres - Recomendados: ${MAX_CHARACTERS_RECOMMENDED_SUBJECT}`}
            </Grid>
            <Grid item xs={6}>
              <InputText
                title="Vista previa en el asunto"
                value={newsletter?.newsletter?.template?.config?.preview}
                required={true}
                disabled={!newsletter.newsletter.market}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  nuNewsletter.newsletter.template.config.preview = nVal.value;
                  setNewsletter(nuNewsletter);
                }}
              />
              {countingWords(newsletter?.newsletter?.template?.config?.preview) > 0
                ? `Caracteres: ${countingWords(
                    newsletter?.newsletter?.template?.config?.preview
                  )} - Recomendados: ${MAX_CHARACTERS_RECOMMENDED_PREVIEW}`
                : `Caracteres - Recomendados: ${MAX_CHARACTERS_RECOMMENDED_PREVIEW}`}
            </Grid>
            <Grid item xs={12}>
              <br />
              <h6>
                <b>Cuerpo del email</b>
              </h6>
            </Grid>
            <Grid item xs={12}>
              <p className={errorTitle ? "text-danger" : ""}>
                Título * <span style={{ color: "silver" }}>(admite código html)</span>
              </p>

              <HtmlTextEditor
                id="title"
                value={newsletter?.newsletter?.title ?? ""}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  nuNewsletter.newsletter.title = nVal;
                  setNewsletter(nuNewsletter);
                  const checkVal = checkHTML(nVal);
                  setErrorTitle(checkVal !== null);
                  if (checkVal !== null) {
                    setErrorTipTitle(checkVal.children);
                  } else {
                    setErrorTipTitle(null);
                  }
                }}
                placeholder={"Escribe aquí el título."}
                valid={!errorTitle}
              />
              {errorTitle === true && <span className="text-danger">Con errores</span>}
              {errorTitle === true && errorTipTitle !== null && (
                <p className="text-danger">{errorTipTitle.item(1).textContent}</p>
              )}
            </Grid>
            <Grid item xs={12}>
              <h6>
                <b>Imagen Superior</b>
              </h6>
            </Grid>
            <Grid item xs={4}>
              <InputText
                title="Imagen"
                value={newsletter?.newsletter?.template?.config?.topImage?.image ?? ""}
                required={true}
                disabled={!newsletter.newsletter.market}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  nuNewsletter.newsletter.template.config.topImage.image = nVal.value;
                  setNewsletter(nuNewsletter);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputText
                title="Url"
                value={newsletter?.newsletter?.template?.config?.topImage?.url ?? ""}
                required={true}
                disabled={!newsletter.newsletter.market}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  nuNewsletter.newsletter.template.config.topImage.url = nVal.value;
                  setNewsletter(nuNewsletter);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputText
                title="Alt"
                value={newsletter?.newsletter?.template?.config?.topImage?.alt ?? ""}
                required={true}
                disabled={!newsletter.newsletter.market}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  nuNewsletter.newsletter.template.config.topImage.alt = nVal.value;
                  setNewsletter(nuNewsletter);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <h6 className={errorTop ? "text-danger" : ""}>
                <b>Email Top * </b>
                <span style={{ color: "silver" }}>(admite código html)</span>
              </h6>
              {/* <h6 className={errorTop ? "text-danger" : ""}>
                <b>Email Top * (admite código html)</b>
              </h6> */}
              <HtmlTextEditor
                id="emailTop"
                value={newsletter?.newsletter?.template?.config?.emailTop ?? ""}
                onChange={(nVal) => {
                  const nuNewsletter = JSON.parse(JSON.stringify(newsletter));
                  nuNewsletter.newsletter.template.config.emailTop = nVal;
                  setNewsletter(nuNewsletter);
                  const checkVal = checkHTML(nVal);
                  setErrorTop(checkVal !== null);
                  if (checkVal !== null) {
                    setErrorTipTop(checkVal.children);
                  } else {
                    setErrorTipTop(null);
                  }
                }}
                placeholder={"Escribe aquí la parte superior del email."}
                valid={!errorTop}
              />
              {errorTop === true && <span className="text-danger">Con errores</span>}
              {errorTop === true && errorTipTop !== null && (
                <p className="text-danger">{errorTipTop.item(1).textContent}</p>
              )}
            </Grid>
            <Grid item xs={6}>
              <h6>
                <b>Faldón</b>
              </h6>
            </Grid>
            <Grid item xs={6}>
              <div style={{ textAlign: "right" }}>
                <ButtonDelete
                  key={"button_delete"}
                  size="small"
                  onClick={() => {
                    const nuNewsletter = { ...newsletter };
                    delete nuNewsletter.newsletter.template.config.bottomImage;
                    setNewsletter(nuNewsletter);
                  }}
                >
                  Eliminar faldón
                </ButtonDelete>
              </div>
            </Grid>
            <Grid item xs={4}>
              <InputText
                title="Imagen"
                value={newsletter?.newsletter?.template?.config?.bottomImage?.image}
                required={false}
                disabled={!newsletter.newsletter.market}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  if (!nuNewsletter.newsletter.template.config.bottomImage) {
                    nuNewsletter.newsletter.template.config.bottomImage = {};
                  }
                  nuNewsletter.newsletter.template.config.bottomImage.image = nVal.value;
                  setNewsletter(nuNewsletter);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputText
                title="Url"
                value={newsletter?.newsletter?.template?.config?.bottomImage?.url}
                required={false}
                disabled={!newsletter.newsletter.market}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  if (!nuNewsletter.newsletter.template.config.bottomImage) {
                    nuNewsletter.newsletter.template.config.bottomImage = {};
                  }
                  nuNewsletter.newsletter.template.config.bottomImage.url = nVal.value;
                  setNewsletter(nuNewsletter);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputText
                title="Alt"
                value={newsletter?.newsletter?.template?.config?.bottomImage?.alt}
                required={false}
                disabled={!newsletter.newsletter.market}
                onChange={(nVal) => {
                  const nuNewsletter = { ...newsletter };
                  if (!nuNewsletter.newsletter.template.config.bottomImage) {
                    nuNewsletter.newsletter.template.config.bottomImage = {};
                  }
                  nuNewsletter.newsletter.template.config.bottomImage.alt = nVal.value;
                  setNewsletter(nuNewsletter);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <br />
              <h6>
                <b>Ofertas</b>
              </h6>
            </Grid>
            <Grid item xs={12}>
              <AlerTitle variant="info">
                <b>Recuerda:</b> El orden de esta lista sera el mismo que en la newsletter.
              </AlerTitle>
            </Grid>
            <Grid item xs={12} disabled={!newsletter.newsletter.market}>
              <InputQueryDropdownSearchTwoRequest
                label={"Buscador de ofertas"}
                getRequestPath={[
                  `/api/v1/product-bc/offers-managment/offers?idOffer[regex]=%%QUERY%%&market[regex]=${newsletter?.newsletter?.market}`,
                  `/api/v1/product-bc/offers-managment/offers?title[regex]=%%QUERY%%&market[regex]=${newsletter?.newsletter?.market}`,
                ]}
                optionFormat={(option) => {
                  return (
                    <Fragment>
                      <div style={{ display: "flex", justifyContent: "space-between", spacing: 1 }}>
                        <div>
                          {!option.enable && (
                            <FxChip
                              style={{ width: "100px" }}
                              label={"Deshabilitada"}
                              colour="#e0e0e0"
                              colourtext={"rgba(0, 0, 0, 0.87)"}
                              size="small"
                            />
                          )}
                          {option.enable && (
                            <FxChip
                              style={{ width: "100px" }}
                              label={"Habilitada"}
                              colour="#c9f7f5"
                              colourtext={"#06a29b"}
                              size="small"
                            />
                          )}
                        </div>
                        {`${option.idOffer} | ${option.title}`}
                      </div>
                    </Fragment>
                  );
                }}
                onSelectOption={(nuValue) => {
                  const nuNewsletter = { ...newsletter };
                  nuNewsletter.newsletter.template.config.offers.push(nuValue.idOffer);
                  setNewsletter(nuNewsletter);
                  const nuOffers = { ...offers, loading: false };
                  nuOffers.offers.push({
                    name: `${nuValue.idOffer} | ${nuValue.title}`,
                    enable: nuValue.enable,
                    idOffer: nuValue.idOffer,
                  });
                  setOffers(nuOffers);
                }}
              />
            </Grid>
            <Grid item xs={10}>
              {newsletter?.newsletter?.template?.config?.offers?.length > 0 && offers.loading === false && (
                <TravCard>
                  <TravBodyCard>
                    <Fragment>
                      <DraggableList
                        editable={false}
                        title={"offer"}
                        list={getInfoStrOffers(offers, newsletter?.newsletter?.template?.config?.offers)}
                        onChangeList={(value) => {
                          const nuNewsletter = { ...newsletter };
                          const nuOffers = { ...offers };
                          nuNewsletter.newsletter.template.config.offers = value.flat().map((cv) => {
                            const [idOffer, title, enable] = cv.props.children[1].split(" | ");
                            return idOffer;
                          });
                          nuOffers.offers = value.flat().map((cv) => {
                            const offer = offers.offers.find((of) => of.name === cv.props.children[1]);
                            return offer;
                          });
                          setOffers(nuOffers);
                          setNewsletter(nuNewsletter);
                        }}
                      />
                    </Fragment>
                  </TravBodyCard>
                </TravCard>
              )}
            </Grid>
          </Grid>
        </TravBodyCard>
      </TravCard>
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
    </>
  );
};

export default NewsletterThirdPartyListView;
