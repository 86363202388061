import React, { useState, useEffect } from "react";
import {
  deleteRelevantInformation,
  fetchRelevantInformationWithCode,
  saveRelevantInformation,
} from "../useProducts";
import { TabStructure } from "@molecules/Tabs/TabStructure";
import { Grid } from "@material-ui/core";
import MarketChip from "@atoms/Content/MarketChip";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import RelevantInformationForm from "./RelevantInformationForm";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import { getChannelID } from "../../shared/domainVariables";

function RelevantInformationSection({ precinctCode, productCode, onChange }) {
  const LANGS = ["es-PT", "pt-PT", "fr-FR", "it-IT"];
  const [loadingTable, setLoadingTable] = useState(true);
  const [relevantInformation, setRelevantInformation] = useState([]);
  const [form, setForm] = useState(relevantInformation);
  const [innerIndex, setInnerIndex] = useState(0);
  const [tab, setTab] = useState(0);
  const [langsActive, setLangsActive] = useState(
    LANGS.reduce((acc, cv) => {
      acc[cv.split("-")[0]] = false;
      return acc;
    }, {})
  );

  //Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setInnerIndex(0);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function fetchRelevantInformation() {
    setLoadingTable(true);
    const relevantInformationResponse = await fetchRelevantInformationWithCode(precinctCode, productCode);
    let values = Object.values(relevantInformationResponse.data);
    values = values.sort((a, b) => {
      return LANGS.indexOf(a.lang) - LANGS.indexOf(b.lang);
    });
    values = values.map((value) => {
      value["edit"] = false;
      return value;
    });
    filterLang(values);
    values = groupByLangs(values);
    setRelevantInformation(values);
    setLoadingTable(false);
  }

  function groupByLangs(values) {
    const grouped = [];
    values.forEach((value) => {
      const index = grouped.findIndex((group) => group[0] !== undefined && group[0].lang === value.lang);
      if (index === -1) {
        grouped.push([value]);
      } else {
        grouped[index].push(value);
      }
    });
    return grouped;
  }

  function filterLang(values) {
    const langs = LANGS.reduce((acc, cv) => {
      acc[cv.split("-")[0]] = values.some((value) => value.lang.split("-")[0] === cv.split("-")[0]);
      return acc;
    }, {});
    setLangsActive(langs);
  }

  useEffect(() => {
    fetchRelevantInformation();
  }, []);

  useEffect(() => {
    setForm(relevantInformation);
  }, [relevantInformation]);

  function onChangeTab(newTab) {
    setInnerIndex(0);
    setTab(newTab);
  }

  function changeInRelevantInfoForm(info, index, innerIndex) {
    info.edit = true;
    form[index][innerIndex] = info;
    setForm(form);
  }

  function addRelevantInformation(lang) {
    const theNewLang = `${lang}-${lang.toUpperCase()}`;
    const newLang = {
      lang: theNewLang,
      content: "",
      title: "",
      enable: true,
      productCode: productCode,
      precinctCode: precinctCode,
      edit: true,
    };
    // order is es - pt - fr - it
    const newForm = [...form];
    newForm.push([newLang]);
    const sortedForm = newForm.sort((a, b) => {
      return LANGS.indexOf(a[0].lang) - LANGS.indexOf(b[0].lang);
    });
    setForm(sortedForm);
    // Desactivo el cambio de tab para evitar que se pierdan datos.
    // const index = sortedForm.findIndex((value) => value[0].lang === theNewLang);
    // handleLangActive(lang, true);
    // setTab(index);
    setAnchorEl(false);
  }

  function resetInforRelevantInformationForm() {
    setForm(relevantInformation);
  }

  async function deleteInforRelevantInformationFromForm(index, lang) {
    const newForm = [...form];
    const deleted = newForm[index][innerIndex];
    newForm[index].splice(innerIndex, 1);
    setInnerIndex(0);
    if (newForm[index].length === 0) {
      newForm.splice(index, 1);
      setTab(index === 0 ? 0 : index - 1);
    }
    if (innerIndex > 0) setInnerIndex(innerIndex - 1);
    setForm(newForm);
    await deleteRelevantInformation(deleted);
  }

  function handleLangActive(lang, value) {
    const newLangsActive = { ...langsActive };
    newLangsActive[lang.split("-")[0]] = value;
    setLangsActive(newLangsActive);
  }

  async function saveRelevantInformationForm() {
    const relevantInformationModified = form.flat().filter((value) => value.edit);
    if (confirm(`¿Seguro que quieres guardar los cambios realizados en la información relevante?`)) {
      for (let i = 0; i < relevantInformationModified.length; i++) {
        try {
          if (relevantInformationModified[i].channel) {
            await saveRelevantInformation(
              relevantInformationModified[i],
              getChannelID(relevantInformationModified[i].channel)
            );
          } else {
            await saveRelevantInformation(relevantInformationModified[i]);
          }
        } catch (error) {}
      }
      onChange();
    }
  }

  return (
    <>
      <Grid container spacing={2} alignItems={"center"} justify={"center"} direction="row">
        <Grid item xs={12}>
          <h4>Información Relevante</h4>
          <hr />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems={"center"} justify={"space-between"} direction="row">
        <Grid item xs={6}>
          <h4>
            <b>{productCode}</b>
          </h4>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2} justify={"flex-end"} direction="row">
            <Grid item>
              {Object.values(langsActive).filter((cv) => {
                if (cv) return cv;
              }).length < 4 && (
                <ButtonAdd
                  key={"btG-2.2"}
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  Añadir
                </ButtonAdd>
              )}

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  margin: "15px",
                }}
              >
                {Object.keys(langsActive).map((langKey) => {
                  if (!langsActive[langKey]) {
                    return (
                      <MenuItem
                        onClick={() => {
                          addRelevantInformation(langKey);
                        }}
                      >
                        <MarketChip market={langKey} />
                        <b>{` - Informacion Relevante`}</b>
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            </Grid>
            <Grid item>
              <ButtonSave key={"btG-2"} onClick={() => saveRelevantInformationForm()}>
                Guardar
              </ButtonSave>
            </Grid>
          </Grid>
        </Grid>

        <br />
        <Grid item xs={12}>
          {loadingTable ? (
            <CenterLoaderCircle role="status" />
          ) : (
            <TabStructure defaultTab={tab} variant={"standard"} onChange={onChangeTab}>
              {form.map((info, i) => {
                if (info.length > 0)
                  return (
                    <div
                      label={<TabLabel Icon={<MarketChip market={info[0].lang.split("-")[0]} />} />}
                      key={`div`}
                      style={{ marginTop: "10px" }}
                    >
                      <Grid
                        container
                        spacing={1}
                        alignItems={"flex-start"}
                        justify={"space-between"}
                        direction="row"
                      >
                        <Grid item xs={12} style={{ postion: "relative" }}>
                          <div
                            style={{
                              position: "absolute",
                              right: "20px",
                            }}
                          >
                            <ButtonDelete
                              size={"small"}
                              onClick={async () => {
                                await deleteInforRelevantInformationFromForm(i, info[0].lang);
                              }}
                            />
                          </div>
                          <RelevantInformationForm
                            onChange={(newInfo, selectedInnerIndex) => {
                              changeInRelevantInfoForm(newInfo, i, selectedInnerIndex);
                              setInnerIndex(selectedInnerIndex);
                            }}
                            precinctCode={precinctCode}
                            productCode={productCode}
                            selectedInnerIndex={innerIndex}
                            setSelectedInnerIndex={setInnerIndex}
                            relevantInformationGroup={info}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  );
              })}
            </TabStructure>
          )}
        </Grid>
      </Grid>
    </>
  );
}

function TabLabel({ text, Icon }) {
  return (
    <>
      {Icon}
      <span style={{ marginLeft: "10px" }}>{text}</span>
    </>
  );
}

RelevantInformationSection.propTypes = {};

export default RelevantInformationSection;
