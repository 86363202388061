import React from "react";
import PrecinctChannelsSection from "@organisms/Ticketing/ProductChannels/ProductChannelsSection";
import { useParams } from "react-router-dom";
import ChannelPageTemplate from "./ChannelPageTemplate";

const ProductChannelsPage = () => {
  const { precinctCode } = useParams();
  return (
    <ChannelPageTemplate>
      <PrecinctChannelsSection precinctCode={precinctCode} />
    </ChannelPageTemplate>
  );
};

export default ProductChannelsPage;
