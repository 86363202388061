import React from "react";
import { useParams } from "react-router-dom";
import CalendarUpdateRulesSection from "@organisms/Ticketing/CalendarUpdateRules/CalendarUpdateRulesSection";
import ChannelPageTemplate from "./ChannelPageTemplate";

const PrecinctCalendarUpdateRulesPage = () => {
  const { precinctCode } = useParams();
  return (
    <ChannelPageTemplate>
      <CalendarUpdateRulesSection precinctCode={precinctCode} />
    </ChannelPageTemplate>
  );
};

export default PrecinctCalendarUpdateRulesPage;
