import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import useInterval from "@lib/hooks/useInterval";
import { useThisBrick, saveBrick, postBrickHTML } from "../hooks/useBricks";

function useBrickEditor({ type }) {
  const { code } = useParams();
  const history = useHistory();
  const { search } = useLocation();

  const { loading, brick } = useThisBrick(code);

  const isEdition = !loading ? Object.keys(brick).length > 0 : false;
  const isDuplication = new URLSearchParams(search).get("duplicate") !== null;

  const [openNewModal, setOpenNewModal] = useState(false);
  const [form, setForm] = useState({
    code,
    name: "",
    type,
    mode: "",
    destination: "",
    content: { value: "" },
  });
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [bkey] = useState(new Date().getTime() + Math.trunc(365 * Math.random()));

  useEffect(() => {
    if (!loading) {
      if (Object.keys(brick).length > 0) {
        setForm(brick);
        setOpenNewModal(false);
      } else {
        setOpenNewModal(true);
      }
    }
  }, [brick, loading]);

  useInterval(async () => {
    await postBrickHTML(bkey, form.content.value);
  }, 1300);

  const executeSelection = (selectionsFromModal) => {
    setForm({
      ...form,
      mode: selectionsFromModal.mode,
      destination: selectionsFromModal.destination,
      content: { value: selectionsFromModal.template },
    });
    setOpenNewModal(false);
  };

  const backToTable = () => {
    history.push(`/app/content/bricks/${type}`);
  };

  const closeErrorModal = () => setErrors({ show: false, list: [] });

  const onChangeHTML = async (newValue) => {
    setForm({ ...form, content: { value: newValue } });
  };

  const checkFormErrors = () => {
    const nuErrors = [];

    if (!form.code) {
      nuErrors.push("Falta un código válido.");
    }
    if (form.name === "") {
      nuErrors.push(`Se necesita un nombre para identificar este ${type}.`);
    }
    if (form.content.value === "") {
      nuErrors.push(`Añadele contenido a este ${type}, ahora no tiene ninguno.`);
    }

    return nuErrors;
  };

  const saveBrickTemplate = async () => {
    const nuErrors = checkFormErrors();
    if (nuErrors.length > 0) {
      console.log(nuErrors);
      setErrors({ show: true, list: nuErrors });
    } else {
      const responseFromSaving = await saveBrick(form, isEdition, isDuplication);

      // const responseFromSaving = { ok: true };
      // Show if has error...
      if (!responseFromSaving.ok) {
        setErrors({
          show: true,
          list: [`No se ha podido guardar por un error al enviar: ${responseFromSaving.message}`],
        });
      } else {
        // Return to table
        backToTable();
      }
    }
  };

  return {
    idPreview: bkey,
    form,
    errors,
    openNewModal,
    isEdition,
    isDuplication,
    closeErrorModal,
    executeSelection,
    backToTable,
    onChangeHTML,
    setForm,
    saveBrickTemplate,
  };
}

export default useBrickEditor;
