const ONEDAYMS = 24 * 3600 * 1000;

/*
 * This function convert a date in standard string format of Y-m-d
 */
export const dateToStStr = (date) => {
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;
};

/*
 *
 */
export const spanishDateToDateString = (dateStringSpanish) => {
  const [day, month, year] = dateStringSpanish.split("/");
  return dateToStStr(new Date(`${year}-${month}-${day}`));
};

/*
 * This function convert a date in standard string format of Y-m-d
 */
export const dateToSpanishStr = (date) => {
  return `${`0${date.getDate()}`.slice(-2)}/${`0${date.getMonth() + 1}`.slice(-2)}/${date.getFullYear()}`;
};

/*
 * This function return a date sub X days to given date
 */
export const dateAddDays = (date, X) => {
  const d = new Date(date.getTime());
  d.setTime(d.getTime() + X * ONEDAYMS);
  return d;
};

export const getDatesBetweenTwo = (startDateString, stopDateString) => {
  const dateArray = [];
  let currentDate = startDateString;
  while (currentDate <= stopDateString) {
    dateArray.push(currentDate);
    currentDate = dateToStStr(dateAddDays(new Date(currentDate), 1));
  }
  return dateArray;
};
