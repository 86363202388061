/* eslint-disable no-use-before-define */
import axios from "axios";
// import { getChannelID } from "../shared/domainVariables";
// import { getChannelAsQueryParms } from "./utils";

const url = `/api/v1/ticketing-bc/calendar`;

export const fetchCalendarUpdateRules = async (precinctCode) => {
  return get(`${url}/update-rule`, { precinctCode });
};

export const saveCalendarUpdateRule = async (newRule) => {
  return put(`${url}/update-rule/${newRule.id}`, {}, newRule);
};

export const deleteCalendarUpdateRule = async (newRule) => {
  return deleteF(`${url}/update-rule/${newRule.id}`, {});
};

export const autoUpdateExtractorCalendar = async (newRule) => {
  return post(`${url}/auto-update-extractor/${newRule.id}`, {}, {});
};

async function get(path, params) {
  try {
    const { data } = await axios.get(path, { params });
    return data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    throw error;
  }
}

async function put(path, params, body) {
  try {
    const { data } = await axios.put(path, body, { params });
    return data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    throw error;
  }
}

async function post(path, params, body) {
  try {
    const { data } = await axios.post(path, body, { params });
    return data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    throw error;
  }
}

async function deleteF(path, params) {
  try {
    const { data } = await axios.delete(path, { params });
    return data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    throw error;
  }
}
