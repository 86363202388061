import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import FxAlert from "@atoms/Alerts/FxAlert";
import { TravCard, TravTitleCard, TravBodyCard } from "@atoms/Card/FxCard";
import { fetchConcilliationProviderStatus } from "./useConcilliation";

function ConcilliationsProviderStatus() {
  const [loading, setLoading] = useState(true);
  const [providerStatus, setProviderStatus] = useState([]);

  async function getProviderStatus() {
    setLoading(true);

    const response = await fetchConcilliationProviderStatus();

    setLoading(response.loading);
    setProviderStatus(parseConciliatedProviderStatus(response.list));
  }

  const parseConciliatedProviderStatus = (result) => {
    let nuList = {};
    result.forEach((resP) => {
      if (typeof nuList[resP.provider] == "undefined") {
        nuList[resP.provider] = { provider: resP.provider, types: {} };
      }

      if (typeof nuList[resP.provider]["types"][resP.type] == "undefined") {
        nuList[resP.provider]["types"][resP.type] = { n: 0, t: resP.type };
      }

      nuList[resP.provider]["types"][resP.type].n += resP.count;
    });

    let nnuList = Object.values(nuList);
    nnuList.map((lit) => (lit.types = Object.values(lit.types)));

    return nnuList;
  };

  useEffect(() => {
    getProviderStatus();
    return () => {};
  }, []);

  return (
    <>
      <FxAlert variant={"info"}>
        En esta sección se listan los proveedores que han tenido errores consecutivos en los últimos chequeos.
        Desaparecerán de esta sección cuando tengan el primer lanzamiento sin error.
      </FxAlert>

      <Grid container spacing={3}>
        {providerStatus.map((statusBlock, index) => (
          <Grid item xs={4} key={`bs-${index}`}>
            <TravCard>
              <TravBodyCard>
                <TravTitleCard>{statusBlock.provider}</TravTitleCard>
                {statusBlock.types.map((tp, index) => (
                  <div key={`brt${index}`}>
                    <b>Errores consecutivos {tp.t}</b>: {tp.n}
                  </div>
                ))}
              </TravBodyCard>
            </TravCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

ConcilliationsProviderStatus.propTypes = {};

export default ConcilliationsProviderStatus;
