import React, { useState, useEffect, useCallback } from "react";
import Badge from "@material-ui/core/Badge";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import ConcilliationTraventia from "@organisms/Operations/ConcilliationTraventia";
import ConcilliationProvider from "@organisms/Operations/ConcilliationProvider";
import ConcilliationsProviderStatus from "@organisms/Operations/ConcilliationsProviderStatus";
import { FaNotEqual } from "react-icons/fa";
import TabStructure from "@molecules/Tabs/TabStructure";
import {
  fetchConcilliationTraventia,
  fetchConcilliationProvider,
  fetchConcilliationV2Traventia,
  fetchConcilliationV2Provider,
} from "./useConcilliation";

function Concilliation({ target, titleName, useV2 }) {
  const [loadingTraventia, setLoadingTraventia] = useState(true);
  const [loadingProvider, setLoadingProvider] = useState(true);
  const [traventiaBooks, setTraventiaBooks] = useState([]);
  const [providerBooks, setProviderBooks] = useState([]);

  const [paramsTraventia, setParamsTraventia] = useState({
    ignore: false,
    checked: true,
    ok: false,
  });

  const [paramsProvider, setParamsProvider] = useState({
    ignore: false,
    checked: true,
    ok: false,
  });

  const getTraventiaBooks = useCallback(
    async (paramTraventia) => {
      setLoadingTraventia(true);
      const response = useV2
        ? await fetchConcilliationV2Traventia(target, paramTraventia)
        : await fetchConcilliationTraventia(target, paramTraventia);
      setTraventiaBooks(response.list);
      setLoadingTraventia(response.loading);
    },
    [target]
  );

  const getProviderBooks = useCallback(
    async (paramProvider) => {
      setLoadingProvider(true);
      const response = useV2
        ? await fetchConcilliationV2Provider(target, paramProvider)
        : await fetchConcilliationProvider(target, paramProvider);
      setProviderBooks(response.list);
      setLoadingProvider(response.loading);
    },
    [target]
  );

  const onChangeParamsTraventia = async (newParams) => {
    setParamsTraventia(newParams);
    await getTraventiaBooks(newParams);
  };

  const onChangeParamsProvider = async (newParams) => {
    setParamsProvider(newParams);
    await getProviderBooks(newParams);
  };

  useEffect(() => {
    getTraventiaBooks(paramsTraventia);
    getProviderBooks(paramsProvider);
    return () => {};
  }, [getProviderBooks, getTraventiaBooks, paramsProvider, paramsTraventia]);

  return (
    <ContainerFenix>
      <SectionTitle
        title={
          <>
            <FaNotEqual />
            {`Conciliación: ${titleName}`}
          </>
        }
      />

      <TabStructure>
        <div
          label={
            <span style={{ display: "inline-block" }}>
              Nuestras reservas
              <Badge
                style={{ marginLeft: "18px" }}
                color="secondary"
                badgeContent={traventiaBooks.length}
                showZero
                max={999}
              />
            </span>
          }
        >
          <ConcilliationTraventia
            loading={loadingTraventia}
            titleName={titleName}
            traventiaBooks={traventiaBooks}
            params={paramsTraventia}
            onChangeParams={onChangeParamsTraventia}
            useV2={useV2}
          />
        </div>
        <div
          label={
            <span style={{ display: "inline-block" }}>
              Reservas de proveedor
              <Badge
                style={{ marginLeft: "18px" }}
                color="secondary"
                badgeContent={providerBooks.length}
                showZero
                max={999}
              />
            </span>
          }
        >
          <ConcilliationProvider
            loading={loadingProvider}
            titleName={titleName}
            providerBooks={providerBooks}
            params={paramsProvider}
            onChangeParams={onChangeParamsProvider}
            useV2={useV2}
          />
        </div>
        <div label="Estado de proveedores">
          <ConcilliationsProviderStatus />
        </div>
      </TabStructure>
    </ContainerFenix>
  );
}

Concilliation.propTypes = {};

export default Concilliation;
