import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";

function ConfirmationModal({ title, body, show, onConfirm, onDeny }) {
  const [open, setOpen] = useState(show || false);
  const [dialogBody, setDialogBody] = useState(body || "");

  useEffect(() => {
    setOpen(show);
    setDialogBody(body);
  }, [show, body]);

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>{title}:</DialogContentText>
        {dialogBody}
      </DialogContent>
      <DialogActions>
        <ButtonCustom onClick={onDeny}>No</ButtonCustom>
        <ButtonCustom color="primary" onClick={onConfirm}>
          Si
        </ButtonCustom>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onDeny: PropTypes.func,
};

export default ConfirmationModal;
