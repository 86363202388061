import React, { useState, useEffect } from "react";
import { IoTicket, IoBoat, IoAirplane, IoTrain } from "react-icons/io5";
import { BiHotel } from "react-icons/bi";
import FxChip from "@atoms/Content/FxChip";

const VoucherManageDetailVoucherTitle = ({ elementKey = "", completeBook = {}, typeKey = "", index = 0 }) => {
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState(<></>);
  useEffect(() => {
    switch (typeKey) {
      case "accommodations":
        setTitle(completeBook[typeKey][index].hinfo?.name);
        setIcon(<BiHotel />);
        break;
      case "services":
        setTitle(completeBook[typeKey][index].str);
        setIcon(<IoTicket />);
        break;
      case "boats":
        setTitle(`Ferry ${index + 1}`);
        setIcon(<IoBoat />);
        break;
      case "flights":
        setTitle(`Vuelo ${index + 1}`);
        setIcon(<IoAirplane />);
        break;
      case "trains":
        setTitle(`Tren ${index + 1}`);
        setIcon(<IoTrain />);
        break;
      case "insurances":
        setTitle(completeBook[typeKey][index].str);
        setIcon(<IoTicket />);
        break;
      default:
        setTitle("");
        setIcon(<></>);
        break;
    }
  }, []);
  return (
    <>
      {
        <h4 className="titlesection" style={{ fontSize: "15px", fontWeight: "bold" }}>
          <span className="iconitems">{icon}</span>
          {title}
          {completeBook[typeKey][index].status === 3 && (
            <FxChip colour="LightGrey" key={`itemChip${index}`} label="Pendiente" />
          )}
          {completeBook[typeKey][index].status === 4 && (
            <FxChip colour="LightBlue" key={`itemChip${index}`} label="Retrasado" />
          )}
          {(completeBook[typeKey][index].status === 10 || completeBook[typeKey][index].status === 12) && (
            <FxChip colour="red" key={`itemChip${index}`} label="En error" />
          )}
        </h4>
      }
    </>
  );
};

export default VoucherManageDetailVoucherTitle;
