import styled from "styled-components";
import { Alert, Card } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { UsChipEmail, ChipDelete } from "@atoms/Content/Chips";

export const AlertNoAccommodation = styled(Alert)`
  margin-bottom: 0 !important;
  color: #0d3c61;
  background-color: #e8f4fd;
  border-color: #e8f4fd;
`;

export const TitleListService = styled.h6`
  position: relative;
  font-weight: bold;
  padding: 5px 10px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0.1rem;
    width: 4px;
    height: 100%;
    background-color: #1bc5bd;
  }
`;

export const AlertBrick = styled(Alert)`
  margin-bottom: 0 !important;
  color: #0d3c61;
  background-color: #aeffb8;
  border-color: #aeffb8;
`;

export const DwnCompnentConfi = styled.div`
  padding: 16px 0;
`;

export const HeaderTicket = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f2f2f2;
`;
export const ButtonDelete = styled(Button)`
  min-width: 26px !important;
  padding: 1px;
  height: 26px;
  border-radius: 50%;
  color: #666;
`;
export const BodyTicket = styled(Card.Body)`
  padding: 10px !important;
`;

export const BoxAutoContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CardTickets = styled(Card)`
  box-shadow: none !important;
`;

export const ChipService = styled(UsChipEmail)`
  background-color: #c9f7f5;
  color: #06a29b !important;
`;
export const ChipDeleteService = styled(ChipDelete)`
  color: #1bc5bd;

  &:hover {
    color: #06a29b;
  }
`;
