import React, { Fragment } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { MdSearch, MdSend } from "react-icons/md";

const SearchInput = ({
  label = "Busca",
  labelButton = "Buscar",
  searcherString,
  onChangeInput,
  searchFunction,
  buttonInInput = true,
}) => {
  const getInputProps = () => {
    return buttonInInput
      ? {
          startAdornment: (
            <InputAdornment position="start">
              <MdSearch />
            </InputAdornment>
          ),

          endAdornment: (
            <ButtonCustom 
              variant="contained"
              color="primary"
              icon={<MdSend />}
              onClick={() => searchFunction()}
            >
              {labelButton}
            </ButtonCustom>
          ),
        }
      : {
          startAdornment: (
            <InputAdornment position="start">
              <MdSearch />
            </InputAdornment>
          ),
        };
  };
  return (
    <Fragment>
      <TextField
        label={label}
        variant="outlined"
        value={searcherString}
        onChange={(evt) => {
          onChangeInput(evt.target.value);
        }}
        onKeyPress={(e) => {
          if (e.charCode === 13) {
            searchFunction();
          }
        }}
        InputProps={getInputProps()}
      />
    </Fragment>
  );
};

export default SearchInput;
