import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { Card } from "react-bootstrap";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import { SelectMarketPosition } from "../../JsonPageEditor/JsonPageEditor.styles";
import JsonPageEditorInfoTags from "./JsonPageEditorInfoTags";

function JsonPageEditorInfo({ onChange, form, setErrors }) {
  return (
    <Card>
      <Card.Body>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <TextField fullWidth disabled={true} label="Código" value={form.id} />
          </Grid>
          <Grid item xs={6} justify={"flex-end"}>
            <SelectMarketPosition>
              <SelectMarket
                selection={form.tld}
                onChangeMarket={(nVal) => {
                  const nuForm = { ...form };
                  nuForm.tld = nVal;
                  onChange(nuForm);
                }}
              />
            </SelectMarketPosition>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <TextField
              label="Nombre"
              value={form.name}
              onChange={(nVal) => {
                const nuForm = { ...form };
                nuForm.name = nVal.target.value;
                onChange(nuForm);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Url"
              value={form.path}
              onChange={(nVal) => {
                const nuForm = { ...form };
                const newPath = nVal.target.value.split("");
                if (newPath[0] === "/") {
                  newPath.shift();
                }
                nuForm.path = newPath.join("");
                onChange(nuForm);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <JsonPageEditorInfoTags
              tags={form.tags}
              label={"Tags"}
              onSelectOption={(nVal) => {
                const nuForm = { ...form };
                nuForm.tags.push(nVal);
                onChange(nuForm);
              }}
              onDeleteOption={(nVal) => {
                const nuForm = { ...form };
                nuForm.tags = nVal;
                onChange(nuForm);
              }}
              setErrors={setErrors}
            />
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
}

export default JsonPageEditorInfo;
