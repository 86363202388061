/* eslint-disable no-use-before-define */
import React, { Fragment } from "react";
import { v4 } from "uuid";
import { Grid } from "@material-ui/core";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import FxSwitch from "@atoms/Switches/FxSwitch";
import RuleOfProduct from "./RuleOfProduct";
import { saveCalendarUpdateRule } from "../../CalendarUpdateRules/daoCalendarUpdateRules";

function ProductRuleConfiguration({
  productsWithRules,
  rulesOfPrecinct,
  product,
  indexProduct,
  ticketsOfProduct,
  onChangeRules,
  onChangeRulesOfPrecinct,
}) {
  function addNewRule() {
    const nuRules = [...productsWithRules];
    nuRules[indexProduct].rules.push({ ruleId: "" });
    onChangeRules(nuRules);
  }
  async function onChangeEnable(newValue) {
    const nuRules = [...productsWithRules];
    nuRules[indexProduct].enable = newValue;

    if (newValue) {
      if (nuRules[indexProduct].rules === undefined) {
        nuRules[indexProduct].rules = [];
      }
      if (nuRules[indexProduct].rules.length === 0) {
        const defaultRule = rulesOfPrecinct.find((rule) => rule.isDefaultRule);

        if (defaultRule !== undefined) {
          nuRules[indexProduct].rules.push({ ruleId: defaultRule.id });
        } else {
          const newRule = {
            id: v4(),
            precinctCode: product.precinctCode,
            updateEnable: true,
            enable: true,
            isDefaultRule: true,
            name: `Regla por defecto ${product.precinctCode}`,
          };
          await saveCalendarUpdateRule(newRule);

          nuRules[indexProduct].rules.push(newRule);

          onChangeRulesOfPrecinct([...rulesOfPrecinct, newRule]);
        }
      }
    }

    onChangeRules(nuRules);
  }

  function onChangeRule(indexRule, newRule) {
    const nuRules = [...productsWithRules];
    nuRules[indexProduct].rules[indexRule] = newRule;
    onChangeRules(nuRules);
  }

  function deleteRule(indexRule) {
    const nuRules = [...productsWithRules];
    nuRules[indexProduct].rules.splice(indexRule, 1);
    onChangeRules(nuRules);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="space-between" alignItems="flex-end">
          <Grid item>
            <span style={{ width: "25%" }}>
              <b style={{ marginLeft: "5px" }}>{product.productCode}</b>
            </span>
          </Grid>
          <Grid item>
            <FxSwitch
              status={product.enable}
              onChange={() => onChangeEnable(!product.enable)}
              labelPlacement={"end"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item>
            <h5>Reglas</h5>
          </Grid>
          <Grid item>
            <ButtonAdd
              disabled={!product.enable || product.rules.length >= rulesOfPrecinct.length}
              text={"Añadir Regla"}
              onClick={addNewRule}
            >Añadir Regla</ButtonAdd>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {product.rules.map((rule, indexRule) => (
          <Fragment key={`rule${indexRule}`}>
            <Grid item xs={6}>
              <div
                style={{
                  padding: "20px",                 
                  borderRadius: "5px",
                  border:"1px solid #ddd",
                 backgroundColor:"#f8f8f8",
                  borderLeft: "4px solid #1bc5bd"
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <RuleOfProduct
                      {...{ productsWithRules, rule, indexProduct, indexRule, ticketsOfProduct }}
                      ruleList={rulesOfPrecinct.filter((ruleP) => {
                        if (ruleP.id !== rule.ruleId) {
                          return product.rules.findIndex((pR) => ruleP.id === pR.ruleId) === -1;
                        }
                        return true;
                      })}
                      rulesOfPrecinct={rulesOfPrecinct}
                      onChangeThisRule={(newRule) => onChangeRule(indexRule, newRule)}
                      deleteRule={() => deleteRule(indexRule)}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
}

export default ProductRuleConfiguration;
