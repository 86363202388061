const useAffiliateActions = ({ setAffiliateToManage, deleteAffiliate }) => {
  const manageAffiliateAction = async (event) => {
    await setAffiliateToManage(event.affiliateId);
    cb();
  };
  const deleteAffiliateAction = (cb) => async (event) => {
    const confirmation = confirm("¿Está seguro de eliminar este afiliado?");
    if (confirmation) {
      await deleteAffiliate(event.affiliateId);
    }
    cb();
  };

  return {
    manageAffiliateAction,
    deleteAffiliateAction,
  };
};

export default useAffiliateActions;
