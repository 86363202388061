import { withHook } from "@lib/helpers/withHook";
import AffiliatesReportsManagementState from "./AffiliatesReportsManagement.state";
import AffiliatesReportsManagementView from "./AffiliatesReportsManagement.view";

const AffiliatesReportsManagementPage = withHook(
  AffiliatesReportsManagementState,
  AffiliatesReportsManagementView
);

export default AffiliatesReportsManagementPage;
