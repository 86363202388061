import React, { useState } from "react";
// import PropTypes from "prop-types";
// import styled from "styled-components";
import { Menu, MenuItem } from "@material-ui/core";
import { MdMoreVert } from "react-icons/md";

export const ThreeDotMenu = ({ anchorEl, onOpenMenu, handleClose, children }) => {
  // If you add only one children it did not come as array
  let childrenAsArray = children;
  if (!Array.isArray(childrenAsArray)) {
    childrenAsArray = [children];
  }

  return (
    <>
      <MdMoreVert size={25} style={{ cursor: "pointer" }} onClick={onOpenMenu} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {childrenAsArray.map((child, index) => (
          <MenuItem key={index}>{child}</MenuItem>
        ))}
      </Menu>
    </>
  );
};

// We need this hook outside the component in order to control it from outside
export function useThreeDotMenu() {
  const [anchorEl, setAnchorEl] = useState();

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return { anchorEl, onOpenMenu, handleClose };
}
