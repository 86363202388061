import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import Payments from "@organisms/Summary/Payments";

import axios from "axios";

const PaymentsSectionPage = () => {
  const { bookref } = useParams();
  const [{ isLoading, pvp, email }, setBookData] = useState({ isLoading: true, pvp: 0, email: "noemail" });
  // const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const exec = async () => {
      const result = await axios.get(`/gestor/axbooks/getBookData/${bookref}/0`);
      const { book } = result.data;
      if (book !== undefined) {
        setBookData({ pvp: book.pvp, email: book.email, isLoading: false });
      }
    };
    if (isLoading) {
      exec();
    }
  }, [isLoading, bookref]);

  const reloadMe = () => {
    setBookData({ pvp, email, isLoading: true });
  };

  if (isLoading) {
    return <div>Espera..... </div>;
  }
  return (
    <ContainerFenix>
      <Grid container>
        <Grid item xs={12}>
          <Payments bookref={bookref} email={email} pvp={pvp} reloadMe={reloadMe} />
          <div style={{ marginBottom: "40px" }}></div>
        </Grid>
      </Grid>
    </ContainerFenix>
  );
};

export default PaymentsSectionPage;
