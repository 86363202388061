/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// Atoms
import SectionTitle from "@atoms/Titles/SectionTitle";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
// Feedback
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import ErrorModal from "@atoms/Modals/ErrorModal";
// Buttons
// import { ButtonConfirm, ButtonWarning, ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import ButtonReset from "@atoms/Buttons/ButtonReset";
// CustomHooks
import { fetchPrecinctChannels, savePrecinctChannel, deletePrecinctChannel } from "../Precincts/usePrecincts";
import { fetchBookingGroups, saveBookingGroup, deleteBookingGroup } from "../BookingGroups/useBookingGroups";
// Data
import PrecinctChannelsSectionForm from "./PrecinctChannelsSectionForm";
import { getChannelAsQueryParms } from "../Calendar/utils";

function PrecinctChannelsSection({ precinctCode }) {
  const history = useHistory();
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [alertText, setAlertText] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [loadingPrecinctChannels, setLoadingPrecinctChannels] = useState(true);
  const [precinctChannels, setPrecinctChannels] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });

  const searchPrecintChannels = useCallback(async () => {
    setLoadingPrecinctChannels(true);
    let precinctChannelsFound = await fetchPrecinctChannels(precinctCode);
    precinctChannelsFound = precinctChannelsFound.reduce(
      (acc, channel) => {
        acc.found.push(startPrecinctChannel(channel));
        acc.fielTextProvider.push(channel.channel.provider);
        return acc;
      },
      { found: [], fielTextProvider: [] }
    );

    setPrecinctChannels(precinctChannelsFound.found);
    setLoadingPrecinctChannels(false);
  }, [precinctCode]);

  useEffect(() => {
    searchPrecintChannels();
  }, [precinctCode, searchPrecintChannels]);

  const startPrecinctChannel = (newPrecinctChannel, type = "unchanged") => {
    const { booking, cancellation, voucher } = getStartChannelConfigurationSelector();
    const nuChannel = newPrecinctChannel;
    nuChannel.enableConfiguration = true;
    nuChannel.merchant = nuChannel.merchant === undefined ? "" : nuChannel.merchant;
    nuChannel.type = type;
    if (!nuChannel.booking) {
      nuChannel.booking = booking;
    } else {
      if (!nuChannel.booking.configuration) {
        nuChannel.booking.configuration = booking.configuration;
      } else {
        if (!nuChannel.booking.configuration.delayItToStartDate) {
          nuChannel.booking.configuration.delayItToStartDate = {
            enabled: false,
            daysBeforeStartDate: 1,
          };
        } else {
          if (!nuChannel.booking.configuration.delayItToStartDate.enabled) {
            nuChannel.booking.configuration.delayItToStartDate.enabled = false;
          }

          if (!nuChannel.booking.configuration.delayItToStartDate.daysBeforeStartDate) {
            nuChannel.booking.configuration.delayItToStartDate.daysBeforeStartDate = 1;
          }
        }

        if (!nuChannel.booking.configuration.multiBooking) {
          nuChannel.booking.configuration.multiBooking = {
            enabled: false,
            daysPerBooking: 1,
          };
        } else {
          if (!nuChannel.booking.configuration.multiBooking.enabled === undefined) {
            nuChannel.booking.configuration.multiBooking.enabled = false;
          }
          if (!nuChannel.booking.configuration.multiBooking.daysPerBooking) {
            nuChannel.booking.configuration.multiBooking.daysPerBooking = 1;
          }
        }
      }
      if (!nuChannel.booking.configuration.cco) {
        nuChannel.booking.configuration.cco = [];
      }
      if (!nuChannel.booking.configuration.include) {
        nuChannel.booking.configuration.include = {
          phone: false,
          accommodation: false,
        };
      }
      if (!nuChannel.booking.configuration.emails) {
        nuChannel.booking.configuration.emails = [];
      }
    }

    if (!nuChannel.cancellation) {
      nuChannel.cancellation = cancellation;
    }
    if (!nuChannel.voucher) {
      nuChannel.voucher = voucher;
    }
    return nuChannel;
  };

  const getStartChannelConfigurationSelector = () => {
    return {
      booking: {
        mode: "none",
        inherited: true,
        configuration: {
          emails: [],
          cco: [],
          include: {
            phone: false,
            accommodation: false,
          },
          multiBooking: {
            enabled: false,
            daysPerBooking: 1,
          },
          delayItToStartDate: {
            enabled: false,
            daysBeforeStartDate: 1,
          },
        },
      },
      cancellation: { mode: "none", inherited: true, configuration: { emails: [], cco: [] } },
      voucher: {
        mode: "none",
        inherited: true,
        configuration: { payable: "", logo: "", address: "", email: "", phone: "" },
      },
    };
  };

  const initializePrecinctChannel = () => {
    return startPrecinctChannel(
      {
        channel: { provider: "" },
        ...getStartChannelConfigurationSelector(),
      },
      "new"
    );
  };

  const changeType = (precinctChannel) => {
    return precinctChannel.type === "new" ? "new" : "edit";
  };

  const changePrecinctChannels = (newPrecinctChannels) => {
    setPrecinctChannels(newPrecinctChannels);
  };

  const addNewPrecinctChannels = () => {
    const newChannel = initializePrecinctChannel();
    changePrecinctChannels([...precinctChannels, newChannel]);
  };

  const savePrecinctChannels = async () => {
    const errorsChannels = [];
    const withBookingGroups = precinctChannels.filter((x) => x.bookingGroup !== undefined);
    if (withBookingGroups.length > 0) {
      for (const precinctChannel of withBookingGroups) {
        const theGroups = await fetchBookingGroups({
          params: {
            "group.precinctCode": precinctChannel.precinctCode,
            ...getChannelAsQueryParms(precinctChannel.channel, "channel"),
          },
        });
        if (theGroups.length && precinctChannel.bookingGroup.length === 0) {
          await deleteBookingGroup(theGroups[0]);
        } else {
          if (theGroups.length && theGroups[0].id !== precinctChannel.bookingGroup[0].id)
            await deleteBookingGroup(theGroups[0]);
          await saveBookingGroup(precinctChannel.bookingGroup[0]);
        }
      }
    }

    for (let index = 0; index < precinctChannels.length; index += 1) {
      const currentChannel = precinctChannels[index];
      if (currentChannel.type !== "unchanged") {
        try {
          const channel = currentChannel;
          delete channel.bookModeTxt;
          delete channel.enableConfiguration;
          delete channel.precinctCode;
          delete channel.type;
          // eslint-disable-next-line no-await-in-loop
          await savePrecinctChannel(precinctCode, currentChannel.channel, currentChannel);
        } catch (e) {
          errorsChannels.push(`Error en el precinct ${precinctCode}: ${e.message}`);
        }
      }
    }

    if (errorsChannels.length > 0) {
      setErrors({
        show: true,
        list: [`Error al guardar los canales`, JSON.stringify(errorsChannels)],
      });
    } else {
      setAlertOpen(true);
      setAlertText("Se han guardado los correctamente los canales");
    }
    searchPrecintChannels();
  };

  const deletePrecinctChannelHandle = async (index) => {
    const channel = precinctChannels[index];
    const errorsChannels = [];
    try {
      if (channel.type === "new") {
        const nuPrecincts = precinctChannels.splice(index, 1);
        setPrecinctChannels(nuPrecincts);
      } else {
        await deletePrecinctChannel(precinctCode, channel.channel);
        await searchPrecintChannels();
      }
    } catch (e) {
      errorsChannels.push(`Error en el precinct ${precinctCode}: ${e.message}`);
    }

    if (errorsChannels.length > 0) {
      setErrors({
        show: true,
        list: [`Error al guardar los canales`, JSON.stringify(errorsChannels)],
      });
    } else {
      setAlertOpen(true);
      setAlertText("Se ha borrado correctamente el canal");
    }
  };

  const checkPrecincts = () => {
    return precinctChannels.reduce((nerrors, precinctChannel) => {
      if (precinctChannel.channel.provider === "") {
        nerrors.push("El proveedor no puede estar vacio.");
      }
      return nerrors;
    }, []);
  };

  const handlerButtonSave = () => {
    const nerrors = checkPrecincts();
    if (nerrors.length > 0) {
      setErrors({
        show: true,
        list: nerrors,
      });
    } else {
      setConfirmationDialog({
        open: true,
        text: `¿Estas seguro que quieres guardar los cambios realizados?`,
        body: "Esta acción no puede revertirse. ¿Seguro que desea guardar los cambios?",
        onConfirm: () => {
          savePrecinctChannels();
        },
      });
    }
  };

  function goBackToPrecinct() {
    const ticketingSectionPathname = history.location.pathname.split("/");
    ticketingSectionPathname.pop();
    history.push(`${ticketingSectionPathname.join("/")}`);
  }

  return (
    <ContainerFenix>
      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={() => {
          confirmationDialog.onConfirm();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
        onDeny={() => {
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
      <SectionTitle
        title={`Canales ${precinctCode} (${precinctChannels.length} ${
          precinctChannels.length === 1 ? "canal" : "canales"
        })`}
        backFunction={goBackToPrecinct}
        buttons={[
          <ButtonAdd
            key={"buton_add"}
            size="small"
            onClick={() => {
              addNewPrecinctChannels();
            }}
          >
            Añadir
          </ButtonAdd>,
          <ButtonReset
            key={"buton_reset"}
            size="small"
            onClick={() => {
              searchPrecintChannels();
            }}
          >
            Restablecer
          </ButtonReset>,
          <ButtonSave
            key={"buton_confirm"}
            size="small"
            onClick={() => {
              handlerButtonSave();
            }}
          >
            Guardar
          </ButtonSave>,
        ]}
      />
      {loadingPrecinctChannels ? (
        <CenterLoaderCircle />
      ) : (
        <Grid
          container
          spacing={2}
          alignItems={"flex-start"}
          justify={precinctChannels.length === 1 ? "center" : "space-between"}
          direction="row"
        >
          {precinctChannels.map((channel, i) => {
            return (
              <PrecinctChannelsSectionForm
                key={`${i}-SectionForm`}
                deletePrecinct={() => {
                  setConfirmationDialog({
                    open: true,
                    text: `¿Estas seguro que quieres borrar este canal?`,
                    body: "Esta acción no puede revertirse.",
                    onConfirm: () => {
                      deletePrecinctChannelHandle(i);
                    },
                  });
                }}
                channel={channel}
                precinctLength={precinctChannels.length}
                onChange={(nuChannel) => {
                  const nchannel = { ...nuChannel };
                  const nuPrecinctChannels = [...precinctChannels];
                  nchannel.type = changeType(nchannel);
                  nuPrecinctChannels[i] = nchannel;
                  changePrecinctChannels(nuPrecinctChannels);
                }}
              />
            );
          })}
        </Grid>
      )}
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => {
          setErrors({ show: false, list: [] });
        }}
      />
      <AlertSnackbar show={alertOpen} text={alertText} setOpen={setAlertOpen} />
    </ContainerFenix>
  );
}

PrecinctChannelsSection.propTypes = {};

export default PrecinctChannelsSection;
