import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle, DialogActions, IconButton } from "@material-ui/core";
import { Alert } from "react-bootstrap";
import ButtonSend from "@atoms/Buttons/ButtonSend";
import { MdClose } from "react-icons/md";
import { executeCrossSellingCampaign } from "./hooks/useCrossSellingFetch";
import AddEmails from "@molecules/Forms/AddEmails";
import ErrorModal from "@atoms/Modals/ErrorModal";

function ModalExecuteNow({ campaign, onClose }) {
  // const campaignCode = campaign.code;
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState({ open: false, errors: [] });

  const executeCampaign = async () => {
    const res = await executeCrossSellingCampaign(campaign.code, emails);
    if (res.ok) {
      onClose();
    } else {
      setError({ open: true, errors: [res.message] });
    }
  };

  return (
    <Fragment>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <MdClose />
          </IconButton>
          Recibir simulacion de campaña <b>{campaign.name}</b>
        </DialogTitle>
        <DialogContent>
          <Alert variant="info">
            Se enviará ahora un modelo del estado actual de esta campaña a la lista de emails que añadas.
          </Alert>

          <AddEmails
            emails={emails}
            onChangeList={(e) => {
              setEmails([...e]);
            }}
          />
        </DialogContent>
        <DialogActions> 
          <ButtonSend
            disabled={emails.length === 0}
            variant="success"
            onClick={() => executeCampaign()}
          >
            Enviar
          </ButtonSend>
        </DialogActions>
      </Dialog>

      <ErrorModal
        show={error.open}
        listErrors={error.errors}
        onClose={() => {
          setError({ open: false, errors: [] });
        }}
      />
    </Fragment>
  );
}

ModalExecuteNow.propTypes = {
  campaign: PropTypes.object,
  onClose: PropTypes.func,
};

export default ModalExecuteNow;
