export const upDownItemInArray = (listToOrder, indexToMove, isUp) => {
  const nList = [...listToOrder];

  let ixInsert = indexToMove;
  let ixToMov = indexToMove + 1;
  if (isUp) {
    ixInsert = indexToMove - 1;
    ixToMov = indexToMove;
  }
  const moveRow = JSON.parse(JSON.stringify(listToOrder[ixToMov]));
  nList.splice(ixToMov, 1);
  nList.splice(ixInsert, 0, moveRow);

  return nList;
};

export const hasDuplicates = (array) => {
  return new Set(array).size !== array.length;
};
