import React from "react";
import FxAlert from "@atoms/Alerts/FxAlert";
import imageLock from "./quarantine-rick-and-morty.gif";

const LockdownMode = () => {
  return (
    <FxAlert variant={"error"}>
      <p>
        <b>{"Atención: "}</b>
        {
          "Hemos detectado algun problema de comunicación con nuestro sistema. Dada la delicadeza de este proceso, preferimos asegurar que no se produzcan interacciones que puedan quedar no registradas."
        }
      </p>
      <p>
        {
          "Por favor, vuelve a recargar la página y si el problema persiste, contacta con el departamento de Tecnologia."
        }
      </p>
      <img src={imageLock} alt="Lockdown Mode" style={{ width: "350px", alignItems: "center" }} />
    </FxAlert>
  );
};

export default LockdownMode;
