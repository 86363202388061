import React, { Fragment } from "react";
import { Route, Link } from "react-router-dom";

// Offers
import XmlSiiConv from "./XmlSiiConv/XmlSiiConv";
import AccountingSupplierPage from "../../5-pages/Accounting/AccountingSuppliers/AccountingSuppliersPage";

function RoutesAccounting({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/accounting`;
  return (
    <Fragment>
      {/* Offers */}
      {/* <Route exact path={`${mainUrl}/offers`} component={Offers} /> */}
      <Route exact path={`${mainUrl}/operations/xml-sii`} component={XmlSiiConv} />
      <Route
        exact
        path={[`${mainUrl}/suppliers`, `${mainUrl}/suppliers/:supplierCode`]}
        component={AccountingSupplierPage}
      />
    </Fragment>
  );
}

export default RoutesAccounting;
