import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  FormLabel,
  TextField,
} from "@material-ui/core";
import { ClickableTitleElements, ElementTagLeft } from "@atoms/Titles/Titles.styles";
import FxSwitch from "@atoms/Switches/FxSwitch";
import ListEditable from "@atoms/Lists/ListEditable";
import bookingModes from "./data/bookingModes.json";
import { bookingModesEnabled } from "./data/modesEnabled";

function BookingConfigurationSelector({ channel, onChange, isPrecinct }) {
  function hasBookingConfiguration() {
    return channel.booking !== undefined && !channel.booking?.inherited;
  }
  function onChangeBookingInherited(e) {
    onChange("booking", { ...channel.booking, inherited: e.target.checked });
  }

  return (
    <>
      {isPrecinct ? (
        <BookingConfiguration channel={channel} onChange={onChange} isPrecinct={isPrecinct} />
      ) : (
        <>
          <ClickableTitleElements className="check-box-title">
            <InputLabel>
              <FormControlLabel
                control={
                  <ElementTagLeft marginright="10px">
                    <Checkbox
                      color="primary"
                      checked={channel.booking.inherited}
                      onChange={onChangeBookingInherited}
                    />
                  </ElementTagLeft>
                }
                label={"¿Modo de reserva igual que el de recinto?"}
              />
            </InputLabel>
          </ClickableTitleElements>
          {hasBookingConfiguration() && (
            <BookingConfiguration channel={channel} onChange={onChange} isPrecinct={isPrecinct} />
          )}
        </>
      )}
    </>
  );
}

const BookingConfiguration = ({ channel, onChange, isPrecinct }) => {
  return (
    <>
      <FormControl variant="standard">
        <InputLabel id="demo-simple-select-standard-label">{"Modo de Reserva *"}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          label={"Modo de Reserva *"}
          value={channel.booking.mode ?? ""}
          onChange={(e) => {
            const newBookingConfiguration = { ...channel.booking };
            newBookingConfiguration.mode = e.target.value;
            onChange("booking", { ...newBookingConfiguration, ...channel.booking.configuration });
          }}
        >
          {bookingModes
            .filter((bookMode) => bookingModesEnabled.includes(bookMode.code))
            .map((bm, i) => (
              <MenuItem value={bm.code} key={`voucherMode${i}`}>
                {bm.txt}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {channel.booking.mode === "stemail" && (
        <BookModeEmailStandart
          configuration={channel.booking.configuration}
          onChange={(newConf) => onChange("booking", { ...channel.booking, configuration: newConf })}
        />
      )}
      <DelayItToStarDateConfiguration
        configuration={channel.booking.configuration}
        onChange={(newConf) => onChange("booking", { ...channel.booking, configuration: newConf })}
      />
      {!isPrecinct && (
        <MultiBookingConfiguration
          configuration={channel.booking.configuration}
          onChange={(newConf) => onChange("booking", { ...channel.booking, configuration: newConf })}
        />
      )}
    </>
  );
};
const MultiBookingConfiguration = ({ configuration, onChange }) => {
  return (
    <>
      <Grid container>
        <Grid item>
          <FormLabel style={{ marginTop: "10px" }} component="legend">
            <div className="titlesection">¿Modo de reserva multi reserva?</div>
          </FormLabel>
        </Grid>

        <Grid item style={{ marginTop: "5px" }}>
          <FxSwitch
            labels={["", ""]}
            status={configuration.multiBooking.enabled || false}
            onChange={(nVal) => {
              const newConf = { ...configuration };
              newConf.multiBooking.enabled = nVal;
              onChange(newConf);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {configuration.multiBooking.enabled && (
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="outlined-number"
                  label="Días por reserva"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  value={configuration.multiBooking.daysPerBooking}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(nVal) => {
                    const newConf = { ...configuration };
                    newConf.multiBooking.daysPerBooking = nVal.target.value;
                    onChange(newConf);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
    </>
  );
};

const DelayItToStarDateConfiguration = ({ configuration, onChange }) => {
  return (
    <Grid container style={{ margin: "20px 0" }}>
      <Grid item>
        <FormLabel style={{ margin: "10px 0px" }} component="legend">
          <div className="titlesection">Retrasar reserva hasta la fecha de inicio </div>
        </FormLabel>
      </Grid>
      <Grid item style={{ marginTop: "5px" }}>
        <FxSwitch
          labels={["", ""]}
          status={configuration.delayItToStartDate.enabled}
          onChange={(nVal) => {
            const newConf = { ...configuration };
            newConf.delayItToStartDate.enabled = nVal;
            onChange(newConf);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {configuration.delayItToStartDate.enabled && (
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                id="outlined-number"
                label="Días antes de fecha de inicio"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                value={configuration.delayItToStartDate.daysBeforeStartDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(nVal) => {
                  const newConf = { ...configuration };
                  newConf.delayItToStartDate.daysBeforeStartDate = nVal.target.value;
                  onChange(newConf);
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const BookModeEmailStandart = ({ configuration, onChange }) => {
  const msgError = "El email no es válido";
  function validateInputEmail(elementValue) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue);
  }
  return (
    <div style={{ padding: "10px", marginBottom: "10px", border: "1px solid #ddd" }}>
      <Grid
        container
        style={{ margin: "10px" }}
        alignItems={"center"}
        justify="space-between"
        direction="row"
      >
        <Grid item xs={12}>
          <div>En el email se incluye...</div>
          <div style={{ display: "flex", gap: "10px" }}>
            <FxSwitch
              labelPlacement="end"
              labels={["Teléfono"]}
              status={configuration.include?.phone ?? false}
              onChange={(nVal) =>
                onChange({ ...configuration, include: { ...configuration.include, phone: nVal } })
              }
            />
            <FxSwitch
              labelPlacement="end"
              labels={["Alojamiento"]}
              status={configuration.include?.accommodation ?? false}
              onChange={(nVal) =>
                onChange({ ...configuration, include: { ...configuration.include, accommodation: nVal } })
              }
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} justify="space-between" direction="row">
        <Grid item xs={6}>
          <ListEditable
            key={"BOOKING-EMAILS"}
            titleLabel={"Emails"}
            info={configuration.emails}
            onChangeList={(nuList) => onChange({ ...configuration, emails: nuList })}
            validation={validateInputEmail}
            msgError={msgError}
          />
        </Grid>
        <Grid item xs={6}>
          <ListEditable
            key={"BOOKING-CCP"}
            titleLabel={"Emails CCO"}
            info={configuration.cco}
            onChangeList={(nuList) => onChange({ ...configuration, cco: nuList })}
            validation={validateInputEmail}
            msgError={msgError}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BookingConfigurationSelector;
