import axios from "axios";
const url = "/api/v1/product-bc/offers-managment/send-calendar-summary-report";

export const sendEmail = async () => {
  let response = {};
  try {
    console.log(url);
    response = await axios.post(url, {});
  } catch (error) {
    console.error(error);
  }
  return response;
};
