import React from "react";
import FenixTable from "@molecules/Tables/FenixTable";
import { Grid, Button, ButtonGroup } from "@material-ui/core";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import ConcilliationErrorInterface from "./ConcilliationErrorInterface";

function ConcilliationTable({ loading, params, section, books, onChangeParams, onIgnore }) {
  const getBookStatus = () => {
    return {
      0: { name: "Pendiente", colour: "yellow" },
      3: { name: "Pendiente", colour: "yellow" },
      1: { name: "Confirmado", colour: "green" },
      2: { name: "Cancelado", colour: "grey" },
      4: { name: "Retrasado", colour: "blue" },
      12: { name: "No reservado", colour: "grey" },
      10: { name: "Con error", colour: "red" },
      11: { name: "Con error", colour: "red" },
      // <span *ngIf="ln_tr[hds.code] >= 10 && ln_tr[hds.code] != 12"  style="color:white;" class="badge badge-danger">Con Error</span>
    };
  };

  let tableHead = [{ code: "ignore", name: "Ign.", type: "boolean", coloured: { true: "darkgray" } }];
  if (section === "provider") {
    tableHead.push({ code: "locata", name: "Localizador", type: "string" });
  }
  tableHead = [
    ...tableHead,
    { code: "servref", name: "Servref", type: "string" },
    { code: "provider", name: "Proveedor", type: "string", filterable: true },
    { code: "checkin", name: "Checkin", type: "date" },
    { code: "checkout", name: "Checkout", type: "date" },
    { code: "status", name: "Estado", type: "label", translation: getBookStatus() },
    { code: "price.net", name: "Neto", type: "money" },
    { code: "datecreation", name: "Creacion", type: "date" },
    { code: "checked", name: "Check", type: "boolean" },
    { code: "ok", name: "Res.", type: "boolean" },
    { code: "nerrors", name: "Error", type: "numeric" },
    { code: "user", name: "Usuario", type: "string" },
  ];

  const ignoreBook = async (row) => {
    await onIgnore(true, row);
  };
  const stopIgnoringBook = async (row) => {
    await onIgnore(false, row);
  };

  const bookIsIgnored = (row) => {
    return row.ignore;
  };
  const bookIsNotIgnored = (row) => {
    return !row.ignore;
  };

  const actions = [
    { name: "Ignorar", icon: <AiFillEyeInvisible />, func: ignoreBook, showIf: bookIsNotIgnored },
    { name: "Dejar de ignorar", icon: <AiFillEye />, func: stopIgnoringBook, showIf: bookIsIgnored },
  ];

  const changeParams = (key) => {
    onChangeParams({ ...params, [key]: !params[key] });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ margin: "10px" }}>
            <span>
              <b>Mostar también</b>
            </span>
            <ButtonGroup size="small">
              <Button
                onClick={() => changeParams("ignore")}
                variant="contained"
                color={params.ignore && "primary"}
              >
                Ignoradas
              </Button>
              <Button
                onClick={() => changeParams("checked")}
                variant="contained"
                color={!params.checked && "primary"}
              >
                No chequeadas
              </Button>
              <Button onClick={() => changeParams("ok")} variant="contained" color={params.ok && "primary"}>
                Resultado OK
              </Button>
            </ButtonGroup>
          </div>
        </Grid>
      </Grid>
      <FenixTable
        loading={loading}
        tableHead={tableHead}
        tableContent={books.map((b) => ({
          ...b,
          nerrors: b.errors.length,
          children: <ConcilliationErrorInterface errorList={b.errors} />,
        }))}
        actions={actions}
        pagination={true}
      />
    </>
  );
}

ConcilliationTable.propTypes = {};

export default ConcilliationTable;
