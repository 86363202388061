// useFetch.js
import { useEffect, useState } from "react";
import axios from "axios";
import brickList from "./__test__/brickList.json";
import abrick from "./__test__/brick.json";

export const generateNewBrickCode = (type) => {
  return `${type.toUpperCase()}${new Date().getTime() + Math.trunc(365 * Math.random())}`;
};

const catchErrorMessage = (errorObject) => {
  let message = "Error no capturado";
  if (errorObject.message) {
    message = errorObject.message;
  }

  const { response } = errorObject;
  if (response !== undefined) {
    if (response.data !== undefined) {
      if (response.data.err !== undefined) {
        if (response.data.err.message !== undefined) {
          message = response.data.err.message;
        }
      }
    }
  }

  return message;
};

export const postBrickHTML = async (key, html) => {
  try {
    if (html !== "") {
      const response = await axios.put(`/api/v1/product-bc/content/brick-preview/${key}`, { value: html });
      //const response = { status: 200, data: { ok: true, data: { key } } };
      if (response.status === 201) {
        return { ok: true, key };
      }
    } else {
      return { ok: true, key };
    }
  } catch (error) {
    return { ok: false, key };
  }
};

/**
 *
 * @param {*} bcode
 */
export const deleteBrick = async (bcode) => {
  try {
    const response = await axios.delete(`/api/v1/product-bc/content/bricks/${bcode}`);
    //const response = { status: 200, data: { ok: true, data: { key } } };
    if (response.status === 204) {
      return { ok: true, bcode };
    } else {
      throw new Error("Not ok response");
    }
  } catch (error) {
    return { ok: false, bcode };
  }
};

/**
 *
 */
export const useBricks = (type) => {
  const [state, setState] = useState({ loading: true, list: [] });

  useEffect(() => {
    const fetchData = async (type) => {
      try {
        let thestate = { list: [], loading: false };
        const response = await axios.get(`/api/v1/product-bc/content/bricks?type=${type}`);
        //const response = brickList;
        if (response.status === 200 && response.data.data) {
          thestate = { list: response.data.data, loading: false };
        }
        setState(thestate);
      } catch (error) {
        setState({ list: [], loading: false });
        throw error;
      }
    };

    if (state.loading) {
      fetchData(type);
    }
  }, [state.loading]);

  return { loadingBricks: state.loading, bricks: state.list };
};

/**
 *
 * @param {*} code
 */
export const useThisBrick = (code) => {
  const [state, setState] = useState({ loading: true, brick: {} });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, brick: {} };

        const bcode = code || "NEW";
        const response = await axios.get(`/api/v1/product-bc/content/bricks/${bcode}`);
        //const response = abrick;
        if (response.status === 200 && response.data) {
          thestate = { loading: false, brick: response.data || {} };
        }

        setState(thestate);
      } catch (error) {
        setState({ loading: false, brick: {} });
        throw error;
      }
    };

    if (state.loading) {
      fetchData();
    }
  }, [state.loading, code]);

  return { ...state };
};

/**
 *
 * @param {*} campaign
 */
export const saveBrick = async (brick, isEdition, isDuplication) => {
  let responseRet = { ok: false };
  try {
    let newOne = !isEdition;

    const dBrick = { ...brick };
    if (isDuplication) {
      dBrick.code = generateNewBrickCode(brick.type);
      newOne = true;
    }

    const requestRes = await axios.put(`/api/v1/product-bc/content/bricks/${dBrick.code}`, dBrick);

    if (requestRes.status === 201 && requestRes) {
      responseRet = { ok: true };
    }
  } catch (error) {
    const message = catchErrorMessage(error);
    responseRet = { ok: false, message };
  }

  return responseRet;
};
