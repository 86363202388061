/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from "react";
// import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FcInfo } from "react-icons/fc";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";

const InputText = ({
  title,
  value,
  required,
  parentSended,
  helperText,
  helperTextIsError,
  errorText,
  onChange,
  validation,
  sanitization,
  mask,
  information,
  ...props
}) => {
  const [error, setError] = useState(false);
  const [infoText, setInfoText] = useState(helperTextIsError ? undefined : helperText);

  const type = props.type !== undefined ? props.type : "string";

  const validateInputText = useCallback(
    (inputValue) => {
      const isValid = validation(inputValue);
      setError(!isValid);
    },
    [validation]
  );

  useEffect(() => {
    if (error && errorText !== "") {
      setInfoText(errorText);
    } else {
      setInfoText(helperText);
    }
  }, [error, errorText, helperText]);

  useEffect(() => {
    if (parentSended) {
      validateInputText(value);
    }
  }, [parentSended, validateInputText, value]);

  const getCustomInputValues = () => {
    let customInput = {};
    const customInputProps = {};

    //* ** Mask format
    // const maskInput = mask !== undefined ? mask.split("").map(v => (v === "9" ? "#" : v)) : [];
    // if (maskInput.length > 0) {
    //   customInput = { inputComponent: TextMask };
    //   customInputProps = { mask: maskInput };
    // }

    //* ** Information icon
    if (information !== undefined) {
      customInput = {
        ...customInput,
        endAdornment: (
          <InputAdornment position="end" style={{ zIndex: 1, cursor: "help" }}>
            <Tooltip arrow title={<>{information}</>} placement="top">
              <span>
                <FcInfo style={{ fontSize: "2em" }} />
              </span>
            </Tooltip>
          </InputAdornment>
        ),
      };
    }

    return { customInput, customInputProps };
  };
  const { customInput, customInputProps } = getCustomInputValues();

  const onChangeInput = (e) => {
    const inputValue = sanitization(e.target.value);
    const valid = validation(inputValue);
    if (error) {
      setError(!valid);
    }

    onChange({ value: inputValue, valid });
  };

  const onClickOutsideOfInput = () => {
    if (value !== "") {
      validateInputText(value);
    }
  };

  return (
    <InputTextField
      {...props}
      type={type}
      variant="outlined"
      label={`${title} ${required ? "*" : ""}`}
      value={value}
      onChange={onChangeInput}
      error={error}
      helperText={infoText}
      onBlur={onClickOutsideOfInput}
      // Custom props
      InputProps={customInput}
      inputProps={customInputProps}
    />
  );
};

const InputTextField = styled(TextField)`
  width: 100% !important;
`;

// function TextMask(props) {
//   const { inputRef, mask, ...other } = props;

//   return <NumberFormat {...other} format={mask.join("")} />;
// }

InputText.propTypes = {
  /**
   * Title of the input block
   */
  title: PropTypes.string,
  /**
   *  Value of the input
   */
  value: PropTypes.string,
  /**
   *  Some optional text with information
   */
  helperText: PropTypes.string,
  /**
   *  Text that will be shown when a format error happened
   */
  errorText: PropTypes.string,
  /**
   *  Marks if the input is required
   */
  required: PropTypes.bool,
  /**
   *  Indicates if the parent (usually a form) has been sended
   */
  parentSended: PropTypes.bool,
  /**
   * Mask for the input (optional). It uses the number nine (9) as mask for numbers. Example: "(999) 999-9999"
   */
  mask: PropTypes.string,
  /**
   * React element with the content of an optional tooltip with information
   */
  information: PropTypes.element,
  /**
   *  Function that checks if the input is valid
   */
  validation: PropTypes.func,
  /**
   *  Function that changes the format of the input text
   */
  sanitization: PropTypes.func,
  /**
   *  Function applicable when the input changes. It will recived an object with the format: { value, valid }
   */
  onChange: PropTypes.func,
};

InputText.defaultProps = {
  title: "",
  value: "",
  helperText: "",
  helperTextIsError: false,
  errorText: "",
  required: false,
  parentSended: false,
  validation: () => {
    return true;
  },
  sanitization: (inputText) => {
    return inputText;
  },
  onChange: () => {},
};

export default InputText;
