/* eslint-disable no-use-before-define */
import { fetchTicket } from "./useTickets";
import { contentFields } from "./constants";
import difference from "../shared/differenceBetweenTwoLists";
import saveTicketList from "./bulkUpload";
import { getChannelID } from "../shared/domainVariables";
import { fetchPrecinctChannels } from "../Precincts/usePrecincts";

const bulkUploadTicketsChannels = async (ticketChannelCsv, precinctCode) => {
  try {
    const promises = [...new Set(ticketChannelCsv.map((t) => t.id))].map((tk) => fetchTicket(tk));
    const responseTickets = await Promise.all(promises);
    const precinctChannels = await fetchPrecinctChannels(precinctCode);

    //* ** Get tickets saved
    const currentByCode = responseTickets.reduce((a, p) => ({ ...a, [p.id]: p }), {});

    //* ** Validation
    const errorsValidation = ticketChannelsValidation(
      ticketChannelCsv,
      currentByCode,
      precinctChannels,
      precinctCode
    );
    if (errorsValidation.length > 0) {
      return { errors: errorsValidation };
    }

    const toUpload = createCompleteTicketListWithChannels(ticketChannelCsv, currentByCode);

    //* ** Get the intersection between the two
    const { toUpdate } = difference(Object.values(currentByCode), toUpload, true, contentFields, "id");

    //* ** Save the results
    const { saved, errors } = await saveTicketList(toUpdate);

    return { saved, errors };
  } catch (e) {
    return { errors: [e.message] };
  }
};

function ticketChannelsValidation(ticketChannelCsv, currentByCode, precinctChannels, precinctCode) {
  const errors = [];
  ticketChannelCsv.forEach((ticket) => {
    try {
      if (currentByCode[ticket.id] === undefined) {
        throw new Error(`Ticket <${ticket.id}> does not exists.`);
      }
      ticketChannelCsvRowValidation(ticket, precinctChannels, precinctCode);
    } catch (e) {
      errors.push(e.message);
    }
  });

  return errors;
}

function ticketChannelCsvRowValidation(ticketChannel, precinctChannels, precinctCode) {
  // Mandatory
  ["id", "channel", "enable", "mapcode", "hasToBeBook"].forEach((field) => {
    if (ticketChannel[field] === undefined) {
      throw new Error(`Campo <${field}> es obligatorio`);
    }
  });

  const ticketChannelId = getChannelID(ticketChannel.channel);
  const channelsId = precinctChannels.map((precinctChannel) => getChannelID(precinctChannel.channel));
  if (!channelsId.includes(ticketChannelId)) {
    throw new Error(`El canal <${ticketChannelId}> no existe en el recinto <${precinctCode}>`);
  }

  // Not Empty
  if (ticketChannel.channel.provider.trim() === "") {
    throw new Error(`Campo <provider> esta vacio`);
  }

  // priceCommissionable and Commission
  if (ticketChannel.priceCommissionable) {
    if (ticketChannel.commission < 0) {
      throw new Error(`Campo <comisión> tiene que ser mayor a 0`);
    }
    if (ticketChannel.commission === "") {
      throw new Error(`Campo <comisión> no puede estar vacio`);
    }
  }
}

const createCompleteTicketListWithChannels = (ticketChannelListToAdd, currentByCode) => {
  const currentCopy = JSON.parse(JSON.stringify(currentByCode));

  const toSave = ticketChannelListToAdd.reduce((toSaveList, ticketChannelToCheck) => {
    const { id } = ticketChannelToCheck;

    const newSaveList = { ...toSaveList };

    const current = currentCopy[id];
    if (toSaveList[current.id] === undefined) {
      newSaveList[current.id] = { ...currentCopy[id] };
      if (newSaveList[current.id].channels === undefined) {
        newSaveList[current.id].channels = [];
      }
    }

    const newChannel = { ...ticketChannelToCheck };
    delete newChannel.id;
    delete newChannel.name;
    delete newChannel.productCode;
    if (newChannel.channel.merchant.trim() === "") {
      delete newChannel.channel.merchant;
    }
    if (typeof newChannel.packageMarkup === "string" && newChannel.packageMarkup.trim() === "") {
      delete newChannel.packageMarkup;
    }
    const newChannelId = getChannelID(newChannel.channel);

    const idExists = newSaveList[id].channels.findIndex((c) => getChannelID(c.channel) === newChannelId);
    if (idExists === -1) {
      newSaveList[id].channels.push(newChannel);
    } else {
      newSaveList[id].channels[idExists] = newChannel;
    }

    return newSaveList;
  }, {});

  return Object.values(toSave);
};

export default bulkUploadTicketsChannels;
