import styled from "styled-components";

/**
 * Box Radius is just that, a box with border radius. By default is white but accept
 * several configurations regarding backgroundColor and other parámeters.
 */
const BoxRadius = styled.div`
  width: ${props => (props.width ? props.width : "100%")};
  padding: ${props => (props.padding ? props.padding : "0px")};
  position: ${props => (props.position ? props.position : "")};

  border-radius: ${props => (props.borderRadius ? props.borderRadius : "5px")};
  -webkit-box-shadow: 0px 9px 35px -6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 9px 35px -6px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 9px 35px -6px rgba(0, 0, 0, 0.4);
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : "#fff")};
`;
export default BoxRadius;
