import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

function ButtonNavegation({ size, variant, label, direction, onClick }) {
  return (
    <ButtonCustom size={size} variant={variant} aria-label={label} customcolor="blue" onClick={onClick}>
      {" "}
      {direction === "left" && <BsChevronLeft size={20} />}
      {label}
      {direction === "right" && <BsChevronRight size={20} />}
    </ButtonCustom>
  );
}

ButtonNavegation.deafultProps = { direction: "right" };

export default ButtonNavegation;
