import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import FileManager from "./fileManager/FileManager";

function RoutesCDN({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/cdn`;

  return (
    <Fragment>
      <Route exact path={`${mainUrl}/file-manager/hoteles`}>
        <FileManager bucket={"cdn-tr-hotels-v2"} />
      </Route>
      <Route exact path={`${mainUrl}/file-manager/images`}>
        <FileManager bucket={"cdn-tr-img"} />
      </Route>
      <Route exact path={`${mainUrl}/file-manager/estaticos`}>
        <FileManager bucket={"estaticos"} />
      </Route>
      <Route exact path={`${mainUrl}/file-manager/pruebas-1`}>
        <FileManager bucket={"pruebas-1"} />
      </Route>
      <Route exact path={`${mainUrl}/file-manager/sprites`}>
        <FileManager bucket={"sprites"} />
      </Route>
    </Fragment>
  );
}

export default RoutesCDN;
