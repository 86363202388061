import styled from "styled-components";

export const StyDivContainerLine = styled.div`
  width: 100%;
  float: left;
`;

export const StyDivContainerLeftSide = styled.div`
  width: 19.8%;
  height: 20px;
  float: left;
`;
export const StyDivContainerRightSide = styled.div`
  width: 100%;
`;

export const StyDivInteractionButton = styled.div`
  float: left;
  padding: 0 10px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.43;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "darkgray")};
  border: 1px solid transparent;
`;
export const StyDivInteractionIcon = styled.span`
  float: right;
  color: #fff;
  cursor: pointer;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "darkgray")};
  padding: 0 3px;
`;

export const StyDivProductText = styled.div`
  font-size: 10px;
  width: 17%;
  height: 20px;
  float: left;
  white-space: nowrap;
  padding-left: 5px;
  line-height: 20px;
`;
export const ConstantType = styled.span`
  cursor: pointer;
  &::before {
    content: "";
    margin: 2px 5px 0 0;
    display: ${(props) => (props.displayPriceModels === "PerOneDay" ? "inline-block" : "none")};
    width: 10px;
    height: 10px;
    color: #fff;
    border-radius: 50%;
    /* background-color: #1bc5bd; */
    border: 3px solid #1bc5bd;
  }
`;
