import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import ErrorModal from "@atoms/Modals/ErrorModal";
import FxSwitch from "./FxSwitch";

function FxSwitchAsync({ labels, status, onChange }) {
  const [checkedAsync, setCheckedAsync] = useState(status || false);
  const [errorModal, setErrorModal] = useState({ show: false, messages: [] });

  useEffect(() => {
    setCheckedAsync(status);
  }, [status]);

  /**
   * Async function because sometimes this could fire a request
   * @param {*} event
   */
  const handleChangeAsync = async (isChecked) => {
    const changeResponse = await onChange(isChecked);
    if (changeResponse === undefined || changeResponse.ok) {
      setCheckedAsync(isChecked);
    } else {
      let messages = ["Error al ejecutar la acción"];
      if (changeResponse.message !== undefined) {
        messages = [changeResponse.message];
      }
      setErrorModal({ show: true, messages });
    }
  };

  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        listErrors={errorModal.messages}
        onClose={() => setErrorModal({ show: false, messages: [] })}
      />
      <FxSwitch labels={labels} status={checkedAsync} onChange={(e) => handleChangeAsync(e)} />
    </Fragment>
  );
}

FxSwitchAsync.propTypes = {
  labels: PropTypes.array,
  status: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FxSwitchAsync;
