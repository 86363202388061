import React, { useState } from "react";
import moment from "moment";
import { Card, Row, Col } from "react-bootstrap";
import {
  TextField,
  Fab,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import { searchInB2B } from "./useCaldeaAPI";

const CaldeaApiBookSearch = () => {
  const tableHead = [
    { code: "BookingLocator", name: "Localizador", type: "string" },
    { code: "ServiceDescription", name: "Descripcion", type: "string" },
    { code: "ServiceStartDate", name: "Entrada", type: "date" },
    { code: "ServiceEndDate", name: "Salida", type: "date" },
    { code: "NetAmount", name: "NetAmount (€)", type: "numeric" },
    {
      code: "BookingStatus",
      name: "Estado",
      type: "label",
      translation: {
        Confirmado: { name: "Confirmado", colour: "rgb(46, 212, 162)" },
        Cancelado: { name: "Cancelado", colour: "red" },
      },
      filterable: true,
    },
  ];

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchResult, setSearchResult] = useState({ results: [] });
  const [searchParams, setSearchParams] = useState({
    selection: "locata",
    datei: moment().format("YYYY-MM-DD"),
    dateo: moment().format("YYYY-MM-DD"),
    locata: "",

    searchExecuted: false,
  });

  async function searchAPICaldea() {
    if (searchParams.selection === "locata" && searchParams.locata === "") {
      alert("Por favor, introduce un localizador");
      return;
    }

    setLoadingSearch(true);
    const sp = { ...searchParams };
    const res = await searchInB2B(sp);
    setSearchResult(res);
    setSearchParams({ ...searchParams, searchExecuted: true });
    setLoadingSearch(false);
  }

  return (
    <Card>
      <Card.Header>
        <h3>Buscador de reservas</h3>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={6} md={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Selecciona tipo de busqueda</FormLabel>
              <RadioGroup
                value={searchParams.selection}
                onChange={(e) => setSearchParams({ ...searchParams, selection: e.target.value })}
              >
                <FormControlLabel value="datesi" control={<Radio />} label="Por fecha de entrada" />
                <FormControlLabel value="dateso" control={<Radio />} label="Por fecha de salida" />
                <FormControlLabel value="locata" control={<Radio />} label="Por localizador de Caldea" />
              </RadioGroup>
            </FormControl>
          </Col>
          <Col xs={6} md={6}>
            {(searchParams.selection === "datesi" || searchParams.selection === "dateso") && (
              <FxDatePickersFromTo
                labelFrom={"Desde"}
                dateFromInitial={searchParams.datei}
                labelTo={"Hasta"}
                dateToInitial={searchParams.dateo}
                onChange={({ datei, dateo }) => {
                  setSearchParams({ ...searchParams, datei, dateo });
                }}
              />
            )}
            {searchParams.selection === "locata" && (
              <TextField
                label="Localizador de Caldea"
                value={searchParams.locata}
                onChange={(nVal) => {
                  setSearchParams({ ...searchParams, locata: nVal.target.value });
                }}
              />
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Fab
              style={{ width: "100%" }}
              variant="extended"
              size="large"
              color="secondary"
              aria-label="add"
              onClick={searchAPICaldea}
            >
              Buscar
            </Fab>
          </Col>
        </Row>

        {loadingSearch ? (
          <CenterLoaderCircle />
        ) : (
          searchParams.searchExecuted && (
            <FenixTable
              loading={loadingSearch}
              tableHead={tableHead}
              tableContent={searchResult.results}
              actions={[]}
            />
          )
        )}
      </Card.Body>
    </Card>
  );
};

export default CaldeaApiBookSearch;
