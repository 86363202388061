import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { Fab } from "@material-ui/core";
import { MdSave, MdWarning, MdAttachEmail } from "react-icons/md";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import { ContLoaderSpace } from "@atoms/Loaders/Loaders.styles";
import SectionTitle from "@atoms/Titles/SectionTitle";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { Modal } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import AddEmails from "@molecules/Forms/AddEmails";
import {
  ContainerFenix,
  UsCardCenterContent,
  SeparatorLine,
  TitleWithSeparator,
  LeftEnable,
} from "@atoms/Card/UsCard.styles";

import {
  useCrossSellingCampaign,
  saveCrossSellingCampaign,
  simulateCampaignCrossSelling,
} from "./hooks/useCrossSellingFetch";
import StandardTicketInterface from "./components/StandardTicketInterface";
import CrossSellingInfo from "./components/CrossSellingInfo";
import CrossSellingDateRangeValidation from "./components/CrossSellingDateRangeValidation";
import CrossSellingExecutingRules from "./components/CrossSellingExecutingRules";
import CrossSellingNotification from "./components/CrossSellingNotification";
import { getAllTicketProducts } from "./hooks/useCrossSellingFetch";
import { userAuthorized } from "@src/entries/fenix/auth/FenixAuthContext";

function CrossSellingCampaignsEditor() {
  const { profile } = userAuthorized();
  const { campaignCode } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const { loadingCampaign, campaign } = useCrossSellingCampaign(campaignCode);
  const isEdition = Object.keys(campaign).length > 0;
  const isDuplication = new URLSearchParams(search).get("duplicate") !== null;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [modal, setModal] = useState({ open: false });
  const [emailsSimulate, setEmailsSimulte] = useState([profile.email]);

  const [form, setForm] = useState({
    id: campaignCode,
    name: "",
    market: "es",
    enable: false,
    config: {
      maxInEmail: 0,
      tickets: { precincts: [] },
    },
    sql: "",
    notificateWhenSend: false,
    emailsManager: [],
    notificateWhenSend: true,
    emailConf: {
      subject: "",
      body: "",
    },
    notificationList: "",
    validityDatesEnable: false,
    validDatesRanges: [],
    type: "StandardTicket",
    executionRules: {
      frequency: "daily", // "daily" | "weekly" | "monthly" | "oneshoot"
      configuration: [],
    },
  });
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [ticketsProducts, setTicketsProducts] = useState({});

  useEffect(() => {
    const searchTicketsProduct = async () => {
      const promises = await Promise.all(
        campaign.config.tickets.precincts.map((t) => handleTicketProductsFetch(t, true))
      );
      const objFinal = promises.reduce((accu, tk) => ({ ...accu, ...tk }), {});
      setTicketsProducts(objFinal);
    };
    if (!loadingCampaign) {
      if (Object.keys(campaign).length > 0) {
        setForm(campaign);
        if (isEdition) {
          searchTicketsProduct();
        }
      }
    }
  }, [campaign, loadingCampaign]);

  const handleTicketProductsFetch = useCallback(
    async (nuValue) => {
      const nuValueEdit = { ...nuValue };

      if (!nuValueEdit.precinctId) {
        nuValueEdit.precinctId = nuValueEdit.code;
      }
      if (Object.keys(ticketsProducts).indexOf(nuValueEdit.precinctId) === -1) {
        const reponse = await getAllTicketProducts(nuValueEdit.precinctId);
        const obj = { [nuValueEdit.precinctId]: reponse.list };
        setTicketsProducts({ ...ticketsProducts, ...obj });
        return obj;
      }
      return null;
    },
    [ticketsProducts]
  );

  const updateContent = (nuContent) => {
    setForm(nuContent);
  };

  const backToTable = () => {
    let urlp = history.location.pathname.split("/");
    urlp.pop();
    urlp = urlp.join("/");
    history.push(urlp);
  };

  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };

  const checkFormErrors = () => {
    const nuErrors = [];

    if (!form.id) {
      nuErrors.push("Falta un código válido");
    }

    if (!form.name) {
      nuErrors.push("Falta un nombre válido");
    }

    if (
      form.type === "StandardTicket" &&
      (!form.config ||
        !form.config.tickets ||
        !form.config.tickets.precincts ||
        form.config.tickets.precincts.length === 0)
    ) {
      nuErrors.push("Necesitas añadir al menos un ticket a la configuración.");
    } else {
      form.config.tickets.precincts.forEach((cv) => {
        if (cv.description.length < 1) {
          nuErrors.push(`Al ticket con código ${cv.precinctId} le falta la descripción`);
        }
        // if (cv.image === "") {
        //   nuErrors.push(`Al ticket con código ${cv.precinctId} le falta la imagen`);
        // }
        if (cv.title === "") {
          nuErrors.push(`Al ticket con código ${cv.precinctId} le falta el titulo`);
        }
      });
    }
    if (form.executionRules.configuration.length === 0) {
      nuErrors.push("Necesitas añadir al menos una regla de ejecucion a la configuración.");
    }
    return nuErrors;
  };

  const saveCampaignForm = async () => {
    const nuErrors = checkFormErrors();
    if (nuErrors.length > 0) {
      setErrors({ show: true, list: nuErrors });
    } else {
      const responseFromSaving = await saveCrossSellingCampaign(form, isEdition, isDuplication);
      // Show if has error...
      if (!responseFromSaving.ok) {
        setErrors({
          show: true,
          list: [
            `No se ha podido guardar la campaña por un error al enviarla: ${responseFromSaving.message}`,
          ],
        });
      } else {
        handleAlertSnackBar(true, "Campaña guardada correctamente.");
      }
    }
  };

  const handleSimulateCampaignCrossSelling = async () => {
    const nuErrors = checkFormErrors();
    if (nuErrors.length > 0) {
      setErrors({ show: true, list: nuErrors });
    } else {
      const responseFromSimulateCrossSellingCampaign = await simulateCampaignCrossSelling(
        form.id,
        emailsSimulate
      );
      // Show if has error...
      if (!responseFromSimulateCrossSellingCampaign.ok) {
        setErrors({
          show: true,
          list: [
            `No se ha podido simular la campaña por un error al enviarla: ${responseFromSimulateCrossSellingCampaign.message}`,
          ],
        });
      } else {
        handleAlertSnackBar(true, "Campaña simulada correctamente. Revise los correos correspondientes.");
      }
    }
  };

  const openSimultaModal = async () => {
    setModal({ open: true });
  };

  const changeEmailList = (newEmailList) => {
    setEmailsSimulte([...newEmailList]);
  };

  return (
    <Fragment>
      {/* Errors modal */}
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => setErrors({ show: false, list: [] })}
      />

      <Modal
        show={modal.open}
        onHide={() => {
          setModal({ open: false });
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>simulación de campaña</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <AddEmails emails={emailsSimulate} onChangeList={changeEmailList} />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
            <Grid item>
              <Fab
                key={"btG"}
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add"
                onClick={async () => {
                  setModal({ open: false });
                  await handleSimulateCampaignCrossSelling();
                }}
              >
                <MdAttachEmail size={20} /> &nbsp; Enviar simulación
              </Fab>
            </Grid>
          </Grid>
        </Modal.Footer>
      </Modal>

      {/* Content */}
      <ContainerFenix>
        {loadingCampaign ? (
          <Fragment>
            <ContLoaderSpace>
              <CenterLoaderCircle />
            </ContLoaderSpace>
          </Fragment>
        ) : (
          <Fragment>
            <SectionTitle
              title={`Campaña ${campaignCode}`}
              backFunction={backToTable}
              info={[`${isEdition ? "Edición" : "Creación"} de campaña ${campaignCode}`]}
              buttons={[
                <Fab
                  key={"btG"}
                  variant="extended"
                  size="medium"
                  color="secondary"
                  aria-label="add"
                  onClick={openSimultaModal}
                >
                  <MdAttachEmail size={20} /> &nbsp; Simular Campaña
                </Fab>,
                <Fab
                  key={"btG"}
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="add"
                  onClick={saveCampaignForm}
                >
                  <MdSave size={20} /> &nbsp; Guardar
                </Fab>,
              ]}
            />

            {!isEdition && (
              <Alert variant="info">
                La campaña <b>{form.id} es NUEVA</b> y será creada al guardar.
              </Alert>
            )}

            {isDuplication && (
              <Alert variant="warning">
                <MdWarning size={60} />
                ESTAS DUPLICANDO LA CAMPAÑA <b>{campaignCode}</b>, por favor, si cambias el mercado que le
                aplica, ten en cuenta que también deberían cambiar todos los campos que tengan contenido
                explícito del idioma (htmls, asuntos, etc...).
              </Alert>
            )}

            <CrossSellingInfo
              form={form}
              onChange={(nuForm) => {
                setForm(nuForm);
              }}
            />

            <CrossSellingNotification
              form={form}
              onChange={(nuForm) => {
                setForm(nuForm);
              }}
            />

            <CrossSellingDateRangeValidation
              form={form}
              onChange={(nuForm) => {
                setForm(nuForm);
              }}
            />

            <Row className="justify-content-md-center">
              <Col xs={12} md={6}>
                <UsCardCenterContent>
                  <SeparatorLine />
                  <TitleWithSeparator>Tipo de campaña</TitleWithSeparator>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Control as="select">
                      <option>Ticket Estandar</option>
                    </Form.Control>
                  </Form.Group>
                </UsCardCenterContent>
              </Col>
            </Row>

            {/* Content: StandardTicket */}
            {form.type === "StandardTicket" && (
              <StandardTicketInterface
                form={form}
                onChangeContent={updateContent}
                ticketsProducts={ticketsProducts}
                handleTicketProductsFetch={handleTicketProductsFetch}
              />
            )}
            <CrossSellingExecutingRules
              form={form}
              onChange={(nuForm) => {
                setForm(nuForm);
              }}
            />
            <LeftEnable>
              <Fab
                variant="extended"
                size="large"
                color="primary"
                aria-label="add"
                onClick={saveCampaignForm}
              >
                <MdSave size={20} />
                Guardar
              </Fab>
            </LeftEnable>
          </Fragment>
        )}
        <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
      </ContainerFenix>
    </Fragment>
  );
}

// CrossSellingCampaignsEditor.propTypes = {};

export default CrossSellingCampaignsEditor;
