import React, { Fragment } from "react";
import { MdEdit, MdControlPointDuplicate, MdDelete } from "react-icons/md";
import { FiPlusSquare } from "react-icons/fi";
import SectionTitle from "@atoms/Titles/SectionTitle";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";

//Styles
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";

function BrickListView({
  type,
  loadingBricks,
  bricks,
  goBack,
  addNewBrick,
  editBrick,
  duplicateBrick,
  deleteThisBrick,
}) {
  const actions = [
    { name: "Editar", icon: <MdEdit />, func: editBrick },
    { name: "Duplicar", icon: <MdControlPointDuplicate />, func: duplicateBrick },
    { name: "Borrar", icon: <MdDelete />, func: deleteThisBrick },
  ];
  const tableHead = [
    { code: "code", name: "Código", type: "string" },
    { code: "name", name: "Nombre", type: "string" },
    { code: "mode", name: "Modo", type: "string" },
    {
      code: "destination",
      name: "Web",
      type: "label",
      translation: {
        "web-react": { name: "Web nueva", colour: "rgb(46, 212, 162)" },
        "web-legacy": { name: "Web antigua", colour: "#ffae17" },
      },
      filterable: true,
    },
  ];

  return (
    <Fragment>
      <SectionTitle
        title={`Listado de ${type.charAt(0).toUpperCase() + type.slice(1)}s`}
        buttons={[
          <ButtonAdd key={"bt2"} variant="primary" size="md" onClick={addNewBrick}>
            <FiPlusSquare /> Añade nuevo {type}
          </ButtonAdd>,
        ]}
      />

      <FenixTable loading={loadingBricks} tableHead={tableHead} tableContent={bricks} actions={actions} />
    </Fragment>
  );
}

export default BrickListView;
