import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Switch, withStyles } from "@material-ui/core";
import amber from "@material-ui/core/colors/amber";
import grey from "@material-ui/core/colors/grey";

function FxSwitch({ labels, status, onChange, disabled, labelPlacement }) {
  let dLabels = ["Habilitado", "Deshabilitado"];
  if (labels !== undefined) {
    dLabels = labels;
    if (labels.length === 1) {
      dLabels.push(labels[0]);
    }
  }

  const [checked, setChecked] = useState(status || false);

  useEffect(() => {
    if (status !== checked) setChecked(status);
  }, [status]);

  const handleChange = (event) => {
    const newState = !checked;
    setChecked(newState);
    onChange(newState);
  };

  return (
    <FormControlLabel
      disabled={disabled || false}
      style={{ marginBottom: "0" }}
      value="top"
      control={<PurpleSwitch size="medium" checked={checked} onClick={handleChange} value={checked} />}
      label={checked ? dLabels[0] : dLabels[1]}
      labelPlacement={labelPlacement}
    />
  );
}

const PurpleSwitch = withStyles({
  switchBase: {
    color2: grey[50],
    "&$checked": { color: amber[700] },
    "&$checked + $track": { backgroundColor: amber[700] },
  },
  checked: {},
  track: {},
})(Switch);

FxSwitch.propTypes = {
  labels: PropTypes.array,
  status: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  labelPlacement: PropTypes.string,
};
FxSwitch.defaultProps = { disabled: false, labelPlacement: "start" };

export default FxSwitch;
