import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  useAffiliatesReportsList,
  useDeleteAffiliateReport,
} from "../../shared/hooks/useAffiliatesReportsFetch";
import AffiliatesReportsListActions from "./AffiliatesReportsList.actions";
import { useAffiliatesList } from "@organisms/Marketing/Affiliates/shared/hooks/useAffiliatesFetch";
import { Tooltip } from "@material-ui/core";
import MarketChip from "@atoms/Content/MarketChip";

const AffiliatesReportsListState = () => {
  const MAX_AffiliatesReports_VIEW = 25;
  const [affiliatesReportsList, setAffiliatesReportsList] = useState({
    loadingList: true,
    list: [],
    infoList: { total: 0 },
    pages: 1,
  });
  const [affiliates, setAffiliates] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };
  const setAffiliatesReportsToManage = (id) => {
    window.location = `/app/marketing/affiliate/reports/${id}`;
  };

  const getAllAffiliates = async () => {
    const { affiliatesData } = await useAffiliatesList();
    setAffiliates(affiliatesData.list);
  };

  const updateAffiliatesReportsList = async () => {
    setAffiliatesReportsList({
      loadingList: true,
      list: [],
      infoList: { total: 0 },
      pages: 1,
    });
    const { affiliatesReportsData } = await useAffiliatesReportsList();
    setAffiliatesReportsList({
      loadingList: affiliatesReportsData.loadingList,
      list: affiliatesReportsData.list,
      infoList: {
        total: affiliatesReportsData.list.length ?? 0,
      },
      pages: affiliatesReportsData.list.length / MAX_AffiliatesReports_VIEW,
    });
  };

  const deleteAffiliatesReports = async (id) => {
    try {
      const deleted = await useDeleteAffiliateReport(id);
      // const response = await axios.delete(`/api/v1/marketing-bc/third-party-Affiliates/${id}`);
      // if (response.status === 200) {
      if (deleted) {
        handleAlertSnackBar(true, "Reporte eliminado");
        await updateAffiliatesReportsList();
      } else throw new Error("No se pudo eliminar el reporte");
      // await updateAffiliatesList();
      // }
    } catch (error) {
      console.log(error);
      handleAlertSnackBar(true, "No se pudo eliminar el reporte.");
    }
  };

  const { affiliatesReportsActions } = AffiliatesReportsListActions({
    setAffiliatesReportsToManage,
    updateAffiliatesReportsList,
    deleteAffiliatesReports,
  });

  useEffect(() => {
    async function loadData() {
      await getAllAffiliates();
    }
    loadData();
  }, []);
  useEffect(() => {
    async function loadData() {
      await updateAffiliatesReportsList();
    }
    loadData();
  }, [affiliates]);

  const tableHead = [
    { code: "id", name: "Id", type: "string" },
    {
      code: "freq",
      name: "Frecuencia",
      type: "translation",
      translation: { daily: "Diario", weekly: "Semanal", monthly: "Mensual" },
    },
    {
      code: "affiliatesId",
      name: "Afiliados",
      type: "customFromRow",
      searchableText: (rowData) => {
        const affiliate = rowData;
        const affs = affiliate.affiliatesId.map((affId) => {
          const affiliate = affiliates.find((aff) => aff.affiliateId === affId);
          if (!affiliate) return { id: "No encontrado", type: "" };
          return affiliate;
        });
        return affs.map((x) => x.id).join(" ");
      },
      customFromRow: (rowData) => {
        const affiliate = rowData.rowData;
        const affs = affiliate.affiliatesId.map((affId) => {
          const affiliate = affiliates.find((aff) => aff.affiliateId === affId);
          if (!affiliate) return { id: "No encontrado", type: "" };
          return affiliate;
        });
        return (
          <Tooltip
            title={affs.map((x) => {
              return (
                <div key={Math.random() + "-" + x}>
                  <>
                    {x.id}
                    {x.type === "whitelabel" && (
                      <>
                        <span>{` | Marca blanca - ${x.whitelabelConf.domain}`}</span>
                        <MarketChip market={x.whitelabelConf.defLang} />
                      </>
                    )}
                    {x.type === "referal" && " | Referido"}
                  </>
                </div>
              );
            })}
          >
            <span style={{ cursor: "pointer" }}>
              {affs[0].id}
              {affs.length > 1 ? ", ..." : ""}
            </span>
          </Tooltip>
        );
      },
    },
    { code: "enable", name: "Activo", type: "boolean" },
    { code: "lang", name: "Idioma", type: "market" },
    {
      code: "by",
      name: "Por",
      type: "translation",
      translation: { datecreation: "Fecha creación", dateo: "Fecha de salida" },
    },
    { code: "emails", name: "Emails", type: "tooltiplist" },
    { code: "emailsBcc", name: "Emails BCC", type: "tooltiplist" },
  ];

  return {
    affiliatesReportsList,
    affiliates,
    tableHead,
    affiliatesReportsActions,
    MAX_AffiliatesReports_VIEW,
    alertOpen,
    alertData,
    handleAlertSnackBar,
    setAlertOpen,
  };
};

export default AffiliatesReportsListState;
