import React from "react";
import AccountingSuppliersPage from "@organisms/Administration/AccountingSuppliers/components";

import { useParams } from "react-router-dom";
const AccountingSupplierPage = () => {
  const { supplierCode } = useParams();
  return <AccountingSuppliersPage supplierCode={supplierCode || ""} />;
};

export default AccountingSupplierPage;
