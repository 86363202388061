import React, { useState } from "react";
import { Grid, Checkbox, Select, MenuItem, FormControl } from "@material-ui/core";
import FxAlert from "@atoms/Alerts/FxAlert";
import InputNumber from "@atoms/Inputs/InputNumber";
import FxSwitch from "@atoms/Switches/FxSwitch";
import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import DayOfMonthSelector from "@atoms/Selectors/DayOfMonthSelector";
import { BsFillInfoCircleFill } from "react-icons/bs";
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import ButtonUpdate from "@atoms/Buttons/ButtonUpdate";
import { autoUpdateExtractorCalendar } from "./daoCalendarUpdateRules";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";

function CalendarUpdateRule({ rule, onChange }) {
  const [daysActive, setDaysActive] = useState(rule.from !== undefined || rule.to !== undefined);
  const [frequencyActive, setFrequencyActive] = useState(rule.frequency !== undefined);

  function onChangeValue(field, newValue) {
    onChange({ ...rule, [field]: newValue });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutoUpdateExtractorCalendar rule={rule} onChange={onChange} />
      </Grid>
      <Grid item xs={3}>
        <FxSwitch
          status={rule.enable}
          onChange={() => onChangeValue("enable", !rule.enable)}
          labelPlacement={"end"}
        />
        <br />
        <FxSwitch
          status={rule.updateEnable}
          onChange={() => onChangeValue("updateEnable", !rule.updateEnable)}
          labelPlacement={"end"}
          labels={["Actualización"]}
        />
        <br />
        <FxSwitch
          labels={["¿Por defecto?"]}
          status={rule.isDefaultRule}
          onChange={() => onChangeValue("isDefaultRule", !rule.isDefaultRule)}
          labelPlacement={"end"}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        {rule.isDefaultRule ? (
          <FxAlert variant={"info"}>Por defecto, de 0 a 240 días.</FxAlert>
        ) : (
          <FromToDays
            active={daysActive}
            rule={rule}
            onChangeActivation={() => setDaysActive(!daysActive)}
            onChangeValue={onChangeValue}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        {rule.isDefaultRule ? (
          <FxAlert variant={"info"}>Por defecto, cada día.</FxAlert>
        ) : (
          <FrequencyBlock
            active={frequencyActive}
            rule={rule}
            onChangeActivation={() => setFrequencyActive(!frequencyActive)}
            onChangeValue={onChangeValue}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <BoundariesBlock rule={rule} onChangeValue={onChangeValue} />
      </Grid>
    </Grid>
  );
}

function AutoUpdateExtractorCalendar({ rule, onChange }) {
  const [alertData, setAlertData] = useState({ text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
  });

  const getDateString = () => {
    const lastExecutionDatetime = new Date(rule.lastExecutionDatetime);
    return `${lastExecutionDatetime.getDate().toString().padStart(2, 0)}/${(
      lastExecutionDatetime.getMonth() + 1
    )
      .toString()
      .padStart(2, 0)}/${lastExecutionDatetime.getFullYear()} a las ${lastExecutionDatetime
      .getHours()
      .toString()
      .padStart(2, 0)}:${lastExecutionDatetime.getMinutes().toString().padStart(2, 0)}`;
  };
  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item>
        {rule.lastExecutionDatetime === null ? (
          <div style={{ color: "red" }}>
            <b>{`Esta regla todavía no se ha ejecutado`}</b>
          </div>
        ) : (
          ` Última vez actualizada ${getDateString()}`
        )}
      </Grid>
      <Grid item spacing={2}>
        <ButtonUpdate
          onClick={(e) => {
            setConfirmationDialog({
              open: true,
              text: `Regla ${rule.name}`,
              body: "¿Estás seguro que quieres actualizar la regla?",
              onConfirm: () => {
                autoUpdateExtractorCalendar(rule);
                setConfirmationDialog({
                  open: false,
                  text: "",
                  body: "",
                  onConfirm: () => {},
                });
                setAlertData({ text: `La regla se está actualizando...` });
                setAlertOpen(true);
              },
            });
          }}
        >
          Actualizar Manualmente
        </ButtonUpdate>
      </Grid>
      <Grid item xs={12}>
        <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
        <ConfirmationModal
          show={confirmationDialog.open}
          title={confirmationDialog.text}
          body={confirmationDialog.body}
          onConfirm={confirmationDialog.onConfirm}
          onDeny={() => {
            setConfirmationDialog({ ...confirmationDialog, open: false });
          }}
        />
      </Grid>
    </Grid>
  );
}

function BlockWithInfoAlert({ label, alertInfoText, children }) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <span>
        <b>{label}</b>
        <BsFillInfoCircleFill
          style={{ cursor: "pointer", marginLeft: "10px", color: "dodgerblue" }}
          onClick={() => setShowInfo(!showInfo)}
        />
      </span>
      <div style={{ display: "flex", padding: "10px" }}>{children}</div>
      {showInfo && <FxAlert variant={"info"}>{alertInfoText}</FxAlert>}
    </>
  );
}
function FromToDays({ active, rule, onChangeActivation, onChangeValue }) {
  return (
    <BlockWithInfoAlert
      label={"Restricción por días"}
      alertInfoText={
        <>
          {
            "Las restricciones marcarán qué dias desde el momento de ejecucion de la regla serán cubiertos por la misma."
          }
          <br />
          {"Si no marcas nada, por defecto una regla cubre desde 0 a 240 días."}
        </>
      }
    >
      <ActiveCheckbox checked={active} onChange={onChangeActivation} />

      <InputNumber
        size="small"
        style={{ borderRadius: "0" }}
        disabled={!active}
        label={"Desde (nº días)"}
        value={rule.from || 0}
        min={0}
        onChange={(newValue) => onChangeValue("from", parseInt(newValue, 10))}
      />
      <InputNumber
        size="small"
        style={{ borderRadius: "0 4px 4px 0" }}
        disabled={!active}
        label={"Hasta (nº días)"}
        value={rule.to || 0}
        min={0}
        onChange={(newValue) => onChangeValue("to", parseInt(newValue, 10))}
      />
    </BlockWithInfoAlert>
  );
}
function FrequencyBlock({ active, rule, onChangeActivation, onChangeValue }) {
  const { frequency } = rule;
  const fieldSelected = frequency !== undefined && frequency.hours !== undefined ? "hours" : "days";
  const [inputValue, setInputValue] = useState(rule.frequency[fieldSelected] || 0);
  const [selected, setSelected] = useState(fieldSelected); // days / hours

  function onChangeInput(newValue) {
    const theValue = parseInt(newValue, 10);
    setInputValue(theValue);
    onChangeValue("frequency", { [selected]: theValue });
  }

  function handleChange(e) {
    const nuSelection = e.target.value;
    setSelected(nuSelection);
    onChangeValue("frequency", { [nuSelection]: inputValue });
  }

  return (
    <BlockWithInfoAlert
      label={"Frecuencia"}
      alertInfoText={
        <>
          {"Frecuencia a la que deseamos que se ejecute esta regla."} <br />
          {"Si no marcas nada, por defecto una regla se ejecuta diariamente (cada 1 día)."}
        </>
      }
    >
      <ActiveCheckbox checked={active} onChange={onChangeActivation} />
      <div style={{ display: "flex" }}>
        <InputNumber
          min={0}
          size="small"
          style={{ borderRadius: "0" }}
          disabled={!active}
          label={"Cada"}
          value={inputValue}
          onChange={onChangeInput}
        />
        <FormControl variant="outlined">
          <Select
            style={{ height: "37px", borderRadius: "0 4px 4px 0" }}
            disabled={!active}
            value={selected}
            label={" "}
            onChange={handleChange}
          >
            <MenuItem value={"days"}>Días</MenuItem>
            <MenuItem value={"hours"}>Horas</MenuItem>
          </Select>
        </FormControl>
      </div>
    </BlockWithInfoAlert>
  );
}
function BoundariesBlock({ rule, onChangeValue }) {
  const { boundaries } = rule;

  const fieldSelected =
    boundaries !== undefined && boundaries.fromDayOfYear !== undefined ? "dayOfMonth" : "full";
  const [selected, setSelected] = useState(fieldSelected); // full / dayOfMonth

  function onChangeEnable() {
    const nuRuleBoundaries = boundaries !== undefined ? { ...rule.boundaries } : {};
    nuRuleBoundaries.enable = boundaries !== undefined ? !boundaries.enable : true;
    onChangeValue("boundaries", nuRuleBoundaries);
  }

  function handleChangeSelection(e) {
    const nuSelection = e.target.value;
    setSelected(nuSelection);

    const nuRuleBoundaries = { enable: rule.boundaries.enable };
    if (nuSelection === "dayOfMonth") {
      nuRuleBoundaries.fromDayOfYear = { month: 1, day: 1 };
      nuRuleBoundaries.toDayOfYear = { month: 1, day: 1 };
    } else {
      nuRuleBoundaries.fromDate = "2022-01-01";
      nuRuleBoundaries.toDate = "2022-02-01";
    }

    onChangeValue("boundaries", nuRuleBoundaries);
  }

  function onChangeFromToDates({ datei, dateo }) {
    const nuRuleBoundaries = { ...rule.boundaries };
    nuRuleBoundaries.fromDate = datei;
    nuRuleBoundaries.toDate = dateo;
    onChangeValue("boundaries", nuRuleBoundaries);
  }

  function onChangeBoundariesDayOfYear(line, data) {
    const nuRuleBoundaries = { ...rule.boundaries };
    nuRuleBoundaries[line] = data;
    onChangeValue("boundaries", nuRuleBoundaries);
  }

  return (
    <>
      <BlockWithInfoAlert
        label={"Límites"}
        alertInfoText={
          "Además de las configuraciones anteriores, aqui puedes marcar explicitamente qué dias del año quieres que cubra esta regla. Puedes hacerlo con fechas exactas o con días del mes, que sirven para cualquier año."
        }
      >
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={4} >
            <div style={{display:"flex"}}>
            <ActiveCheckbox checked={boundaries !== undefined && boundaries.enable} onChange={onChangeEnable} />
            <FormControl variant="outlined">
              <Select
                style={{ height: "37px", borderRadius: "0 4px 4px 0" }}
                disabled={boundaries === undefined || !boundaries.enable}
                value={selected}
                label={"Modo"}
                onChange={handleChangeSelection}
              >
                <MenuItem value={"full"}>Fecha exacta</MenuItem>
                <MenuItem value={"dayOfMonth"}>Día del mes</MenuItem>
              </Select>
            </FormControl>
            </div>
          </Grid>
          <Grid item xs={8}>
            {boundaries && selected === "full" && (
              <FxDatePickersFromTo
                disabled={boundaries === undefined || !boundaries.enable}
                labelFrom={"Desde"}
                dateFromInitial={boundaries.fromDate}
                labelTo={"Hasta"}
                dateToInitial={boundaries.toDate}
                onChange={onChangeFromToDates}
              />
            )}
            {boundaries && selected === "dayOfMonth" && (
              <Grid container spacing={0} style={{ padding: "10px" }}>
                <Grid item xs={6} style={{ display: "flex" }}>
                  <span style={{ lineHeight: "35px", margin: "0 10px" }}>Desde</span>
                  <DayOfMonthSelector
                    dayOfMonthSelected={boundaries.fromDayOfYear}
                    disabled={boundaries === undefined || !boundaries.enable}
                    onChange={(newData) => onChangeBoundariesDayOfYear("fromDayOfYear", newData)}
                  />
                </Grid>
                <Grid item xs={6} style={{ display: "flex" }}>
                  <span style={{ lineHeight: "35px", margin: "0 10px" }}>Hasta</span>
                  <DayOfMonthSelector
                    dayOfMonthSelected={boundaries.toDayOfYear}
                    disabled={boundaries === undefined || !boundaries.enable}
                    onChange={(newData) => onChangeBoundariesDayOfYear("toDayOfYear", newData)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </BlockWithInfoAlert>
    </>
  );
}

function ActiveCheckbox({ checked, onChange, disabled }) {
  return (
    <Checkbox
      style={{ backgroundColor: "#c9f7f5", borderRadius: "5px 0 0 5px" }}
      size="small"
      {...{ disabled, checked, onChange }}
    />
  );
}
 
export default CalendarUpdateRule;
