import React, { useEffect, useState } from "react";
import {
  useAccountingSuppliersManagement,
  useAccountingSupplierSave,
  useAccountingSuppliersBankAccountsManagement,
} from "../../hooks";
import {
  Grid,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Icon,
} from "@material-ui/core";

import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import InputText from "@atoms/Inputs/InputText";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { Check } from "@material-ui/icons";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { FaUndo } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import InputNumber from "@atoms/Inputs/InputNumber";
import MutedText from "@atoms/Content/MutedText";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import Modal from "@atoms/Modals/Modal";
import { BsCurrencyDollar } from "react-icons/bs";
import ConfirmationModal from "../../../../../1-atoms/Modals/ConfirmationModal";
import CenterLoaderCircle from "../../../../../1-atoms/Loaders/CenterLoader";
import ListEditable from "../../../../../1-atoms/Lists/ListEditable";
import { UsChipEmail } from "../../../../../1-atoms/Content/Chips";
import ListEditableChip from "../../../../../1-atoms/Lists/ListEditableChip";
export function AccountingSupplierManagement({
  supplier,
  backFunction,
  isNew = false,
  setManagementMode,
  setSupplierToManageId,
}) {
  const [supplierData, setSupplierData] = useState({});
  const [supplierForm, setSupplierForm] = useState({});
  const [supplierBankAccounts, setSupplierBankAccounts] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [enableForm, setEnableForm] = useState(false);
  const [confirmSave, setConfirmSave] = useState({ open: false });
  const [loading, setLoading] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const handleAlertSnackBar = (open, text) => {
    setAlertOpen(open);
    setAlertData(text);
  };

  const changeView = (nextFunction, isSave) => () => {
    if (hasChanges)
      setConfirmSave({
        open: true,
        text: isSave ? "¿Deseas guardar el supplier?" : "¿Tienes cambios sin guardar, deseas salir?",
        onConfirm: () => {
          setConfirmSave({ open: false });
          nextFunction();
        },
        onDeny: () => setConfirmSave({ open: false }),
      });
    else {
      setConfirmSave({ open: false });
      nextFunction();
    }
  };
  const updateSupplier = async () => {
    if (!isNew) {
      const { supplierBankAccountsData } = await useAccountingSuppliersBankAccountsManagement(supplier);
      const { accountingSupplierData } = await useAccountingSuppliersManagement(supplier);
      setSupplierData(accountingSupplierData.accountingSupplier);
      setSupplierForm(accountingSupplierData.accountingSupplier);
      setSupplierBankAccounts(supplierBankAccountsData.accountingSupplierBankAccounts.data);
    } else {
      const newSupp = {
        code: "",
        name: "",
        identificationDocumentNumber: "",
        country: "",
        town: "",
        city: "",
        address: "",
        postalCode: "",
        contactName: "",
        email: [""],
        phone: "",
        subCount: "",
        regime: "",
        tofTax: "",
        deviationEnable: false,
        deviationPercentage: null,
        isValid: false,
      };
      setSupplierData(newSupp);
      setSupplierForm(newSupp);
    }
    setEnableForm(true);
  };

  useEffect(() => {
    setLoading(true);
    updateSupplier();
    setLoading(false);
  }, [supplier]);
  const taxZones = [
    {
      code: "TAI",
      name: "TAI",
    },
    {
      code: "EU",
      name: "EU",
    },
    {
      code: "NOEU",
      name: "Otro",
    },
  ];

  const paymentMethods = [
    {
      code: "CREDIT",
      name: "Credito",
    },
    {
      code: "MANDATORY_CREDIT",
      name: "Credito obligatorio",
    },
    {
      code: "CREDIT_ANDORRA",
      name: "Credito Andorra",
    },
    {
      code: "PREPAID",
      name: "Prepago",
    },
    {
      code: "CARD",
      name: "Tarjeta",
    },
    {
      code: "DOMICILIATION",
      name: "Domiciliación",
    },
  ];

  const updateForm = (newForm) => {
    setSupplierForm({ ...newForm });
  };

  useEffect(() => {
    setHasChanges(JSON.stringify(supplierForm) !== JSON.stringify(supplierData));
    if (supplierForm.code) {
      if (!supplierForm.name) {
        setHasErrors(true);
        return;
      }
      if (!supplierForm.identificationDocumentNumber) {
        setHasErrors(true);
        return;
      }
      if (!supplierForm.country) {
        setHasErrors(true);
        return;
      }

      if (!supplierForm.regimen || supplierForm.regimen === "-") {
        setHasErrors(true);
        return;
      }

      if (supplierForm.regimen === "COM" && !supplierForm.tofTax) {
        setHasErrors(true);
        return;
      }

      if (!supplierForm.subCount) {
        setHasErrors(true);
        return;
      }

      if (
        supplierForm.paymentConfiguration?.type === "CREDIT" &&
        (!supplierForm.paymentConfiguration?.configuration.supplierPaymentBankAccountId ||
          supplierForm.paymentConfiguration?.configuration.notificationEmail?.length === 0)
      ) {
        setHasErrors(true);
        return;
      }
      if (
        supplierForm.paymentConfiguration?.type === "MANDATORY_CREDIT" &&
        (!supplierForm.paymentConfiguration?.configuration.supplierPaymentBankAccountId ||
          supplierForm.paymentConfiguration?.configuration.notificationEmail?.length === 0)
      ) {
        setHasErrors(true);
        return;
      }
      setHasErrors(false);
    }
  }, [supplierForm]);

  const goToBankAccounts = () => {
    setManagementMode("bankAccounts");
  };

  const topButtons = (supplierForm) => {
    const btns = [];
    if (hasChanges) {
      btns.push(
        <ButtonCustom
          customcolor="blue"
          label="Deshacer"
          aria-label="Deshacer"
          icon={<FaUndo />}
          onClick={() => {
            updateForm(supplierData);
          }}
        ></ButtonCustom>
      );
    }
    btns.push(
      ...[
        <ButtonSave disabled={!hasChanges || hasErrors} onClick={changeView(saveForm, true)}>
          Guardar
        </ButtonSave>,
        <ButtonCustom
          customcolor={supplierForm.isValid ? "green" : "red"}
          label={supplierForm.isValid ? "Válido" : "No válido"}
          aria-label={supplierForm.isValid ? "Válido" : "No válido"}
          icon={supplierForm.isValid ? <Check /> : <MdClose />}
          onClick={() => {
            updateForm({ ...supplierForm, isValid: !supplierForm.isValid });
          }}
        >
          {supplierForm.isValid ? "Válido" : "No válido"}
        </ButtonCustom>,
        <ButtonCustom
          label={"Cuentas bancarias"}
          aria-label={"Cuentas bancarias"}
          icon={<Icon>💳</Icon>}
          onClick={changeView(goToBankAccounts, false)}
        >
          Cuentas bancarias
        </ButtonCustom>,
      ]
    );

    return btns;
  };

  const validateForm = () => {
    // validate fields like in use effect, but returning an array of errors en español
    const errors = [];
    if (!supplierForm.code || supplierForm.code.trim().length === 0) {
      errors.push("El código es obligatorio");
    }
    if (!supplierForm.name || supplierForm.name.trim().length === 0) {
      errors.push("El nombre es obligatorio");
    }
    if (
      !supplierForm.identificationDocumentNumber ||
      supplierForm.identificationDocumentNumber.trim().length === 0
    ) {
      errors.push("El NIF/CIF es obligatorio");
    }
    if (!supplierForm.country || supplierForm.country.trim().length === 0) {
      errors.push("El país de facturación es obligatorio");
    } else {
      if (!supplierForm.country.match(/^[A-Z]{2}$/)) {
        errors.push("El país de facturación debe ser un código ISO-3166-1 de 2 letras");
      }
    }
    if (!supplierForm.regimen || supplierForm.regimen === "-") {
      errors.push("El tipo de regimen es obligatorio");
    }
    if (supplierForm.regimen === "COM" && !supplierForm.tofTax) {
      errors.push("La zona de impuestos es obligatoria");
    }
    if (!supplierForm.subCount || supplierForm.subCount.trim().length === 0) {
      errors.push("La subcuenta contable es obligatoria");
    }
    if (
      supplierForm.paymentConfiguration &&
      ["CREDIT", "MANDATORY_CREDIT"].includes(supplierForm.paymentConfiguration?.type) &&
      !supplierForm.paymentConfiguration?.configuration.supplierPaymentBankAccountId
    ) {
      errors.push("La cuenta bancaria es obligatoria");
    }
    if (
      supplierForm.paymentConfiguration &&
      ["CREDIT", "MANDATORY_CREDIT"].includes(supplierForm.paymentConfiguration?.type) &&
      !supplierForm.paymentConfiguration?.configuration.notificationEmail
    ) {
      errors.push("El email de notificación es obligatorio");
    }
    if (errors.length > 0) {
      setValidationErrors(errors);
      return null;
    }
    return true;
  };

  const saveForm = async () => {
    const validated = validateForm();
    if (!validated) return;
    setLoading(true);
    const res = await useAccountingSupplierSave(supplierForm.code, supplierForm);
    if (res.error) {
      setLoading(false);
      handleAlertSnackBar(true, res.errorText);
      return;
    } else {
      handleAlertSnackBar(true, "Supplier guardado correctamente...");
      await updateSupplier();
      setLoading(false);
      setSupplierToManageId(supplierForm.code);
    }
  };

  return (
    <>
      {loading && <CenterLoaderCircle />}
      {!loading && enableForm && (
        <>
          <Grid item xs={12}>
            <TravCard>
              <SectionTitle
                hx="h5"
                title={`Supplier ${supplierForm.code}`}
                buttons={topButtons(supplierForm)}
                backFunction={changeView(backFunction)}
              />
              <TravBodyCard>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Código*"
                      value={supplierForm.code}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, code: e.value });
                      }}
                      disabled={!isNew}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Nombre*"
                      value={supplierForm.name}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, name: e.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="NIF/CIF*"
                      value={supplierForm.identificationDocumentNumber}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, identificationDocumentNumber: e.value });
                      }}
                    />
                  </Grid>
                </Grid>
                <hr></hr>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="País facturación*"
                      value={supplierForm.country}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, country: e.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Provincia"
                      value={supplierForm.town}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, town: e.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Población"
                      value={supplierForm.city}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, city: e.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Domicilio"
                      value={supplierForm.address}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, address: e.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Código postal"
                      value={supplierForm.postalCode}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, postalCode: e.value });
                      }}
                    />
                  </Grid>
                </Grid>
                <hr></hr>
                <h5>Contacto:</h5>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Nombre contacto"
                      value={supplierForm.contactName}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, contactName: e.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Email contacto"
                      value={supplierForm.email[0]}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, email: [e.value] });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Teléfono contacto"
                      value={supplierForm.phone}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, phone: e.value });
                      }}
                    />
                  </Grid>
                </Grid>
                <hr></hr>
                <h5>Datos contables:</h5>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={2}>
                    <InputText
                      title="Subcuenta contable*"
                      value={supplierForm.subCount}
                      onChange={(e) => {
                        updateForm({ ...supplierForm, subCount: e.value });
                      }}
                    />
                  </Grid>
                </Grid>
                <hr></hr>
                <h5>Método de pago</h5>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={2}>
                    <FormControl variant="outlined">
                      <InputLabel id="payment-method">Método de pago</InputLabel>
                      <Select
                        fullWidth={true}
                        labelId="payment-method"
                        title="Método de pago"
                        label="Método de pago"
                        value={supplierForm.paymentConfiguration?.type || "-"}
                        onChange={(nVal) => {
                          const paymentConfiguration = { type: nVal.target.value };
                          if (nVal.target.value === "CREDIT" || nVal.target.value === "MANDATORY_CREDIT") {
                            paymentConfiguration.configuration = {
                              supplierPaymentBankAccountId: undefined,
                              notificationEmail: [],
                            };
                          }
                          updateForm({ ...supplierForm, paymentConfiguration });
                        }}
                      >
                        <MenuItem value={"-"} key={`nonenone`} style={{ color: "grey" }}>
                          Selecciona un valor
                        </MenuItem>
                        {paymentMethods.map((paymentMethod) => {
                          return (
                            <MenuItem key={`paymentMethod_${paymentMethod.code}`} value={paymentMethod.code}>
                              {paymentMethod.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {["CREDIT", "MANDATORY_CREDIT"].includes(supplierForm.paymentConfiguration?.type) && (
                    <>
                      <Grid item xs={12} md={6} lg={2}>
                        <FormControl variant="outlined">
                          <InputLabel id="payment-method-bank-account">Cuenta bancaria*</InputLabel>
                          <Select
                            fullWidth={true}
                            labelId="payment-method-bank-account"
                            title="Cuenta bancaria"
                            label="Cuenta bancaria"
                            value={
                              supplierForm.paymentConfiguration?.configuration.supplierPaymentBankAccountId ||
                              "-"
                            }
                            onChange={(nVal) => {
                              const paymentConfiguration = { ...supplierForm.paymentConfiguration };
                              const configuration = { ...paymentConfiguration.configuration };
                              configuration.supplierPaymentBankAccountId = nVal.target.value;
                              paymentConfiguration.configuration = configuration;
                              updateForm({ ...supplierForm, paymentConfiguration });
                            }}
                          >
                            <MenuItem value={"-"} key={`nonenone`} style={{ color: "grey" }}>
                              Selecciona un valor
                            </MenuItem>
                            {supplierBankAccounts.map((bankAccount) => {
                              return (
                                <MenuItem key={`bankAccount_${bankAccount.id}`} value={bankAccount.id}>
                                  {bankAccount.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12} lg={8}>
                        <ListEditableChip
                          title={"Emails de notificacion*"}
                          info={supplierForm.paymentConfiguration?.configuration.notificationEmail}
                          form={supplierForm.paymentConfiguration}
                          typeInfo={"tick"}
                          titleSmall
                          onChangeList={(nuEmails) => {
                            const paymentConfiguration = { ...supplierForm.paymentConfiguration };
                            const configuration = { ...paymentConfiguration.configuration };
                            configuration.notificationEmail = nuEmails;
                            paymentConfiguration.configuration = configuration;
                            updateForm({ ...supplierForm, paymentConfiguration });
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <hr />
                <h5>Tipo de regimen:</h5>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={2}>
                    <FormControl variant="outlined">
                      <InputLabel id="regimen-type">Tipo de regimen*</InputLabel>
                      <Select
                        fullWidth={true}
                        labelId="regimen-type"
                        title="Tipo de regimen"
                        label="Tipo de regimen"
                        value={supplierForm.regimen || "-"}
                        onChange={(nVal) => {
                          const newSupplierForm = { ...supplierForm };
                          delete newSupplierForm.tofTax;
                          updateForm({ ...newSupplierForm, regimen: nVal.target.value });
                        }}
                      >
                        <MenuItem value={"-"} key={`nonenone`} style={{ color: "grey" }}>
                          Selecciona un valor
                        </MenuItem>
                        <MenuItem key={`reav`} value={"REAV"}>
                          Nombre propio (REAV)
                        </MenuItem>
                        <MenuItem key={`com`} value={"COM"}>
                          Comisionista
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {supplierForm.regimen === "COM" && (
                    <Grid item xs={12} md={6} lg={4}>
                      <ButtonGroup fullWidth>
                        {taxZones.map((taxZone) => {
                          return (
                            <Button
                              key={`taxZone_${taxZone.code}`}
                              color={
                                supplierForm.tofTax && `ES_COM_${taxZone.code}` === supplierForm.tofTax
                                  ? "secondary"
                                  : "primary"
                              }
                              onClick={() => {
                                updateForm({ ...supplierForm, tofTax: `ES_COM_${taxZone.code}` });
                              }}
                            >
                              {taxZone.name}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </Grid>
                  )}
                </Grid>
                <hr />
                {/* <h5>Porcentaje de desviación</h5>
                <Grid container spacing={3} alignContent="center">
                  <Grid item xs={12} md={6} lg={2}>
                    <ButtonCustom
                      fullWidth
                      size="large"
                      customcolor={supplierForm.deviationEnable ? "green" : "red"}
                      label={supplierForm.deviationEnable ? "Activo" : "Inactivo"}
                      aria-label={supplierForm.deviationEnable ? "Activo" : "Inactivo"}
                      icon={supplierForm.deviationEnable ? <Check /> : <MdClose />}
                      onClick={() => {
                        const newSupplierForm = { ...supplierForm };
                        if (newSupplierForm.deviationEnable) {
                          newSupplierForm.deviationEnable = false;
                          delete newSupplierForm.deviationPercentage;
                        } else {
                          newSupplierForm.deviationEnable = true;
                        }
                        updateForm(newSupplierForm);
                      }}
                    >
                      {supplierForm.deviationEnable ? "Activo" : "Inactivo"}
                    </ButtonCustom>
                  </Grid>

                  {supplierForm.deviationEnable && (
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        <InputNumber
                          label="Porcentaje de desviación"
                          value={supplierForm.deviationPercentage ?? null}
                          onChange={(e) => {
                            updateForm({ ...supplierForm, deviationPercentage: parseFloat(e) });
                          }}
                          min={0}
                          endAdornment={<>%</>}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <MutedText>
                          Al subir una factura de este supplier, si hay una desviación de precio entre lo que
                          marca la factura y el precio equivalente en la reserva, se utilizara este porcentaje
                          como rango (% superior e inferior) para saber si ha de actualizar el precio en
                          reserva (esta dentro del rango) o dar error (esta fuera del rango, se listaran en la
                          sección 'Acumulación de descuadres por suppliers'). Si no activas este campo, se
                          utilizara como % de rango el definido en la sección de "Configuraciones contables".
                        </MutedText>
                      </Grid>
                    </>
                  )}
                </Grid> */}
              </TravBodyCard>
            </TravCard>
          </Grid>
          <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
          <ConfirmationModal
            show={confirmSave.open}
            title={confirmSave.text}
            body={confirmSave.body}
            onConfirm={confirmSave.onConfirm}
            onDeny={confirmSave.onDeny}
          />
          <Modal
            show={validationErrors.length > 0}
            fullWidth={false}
            onClose={() => {
              setValidationErrors([]);
            }}
          >
            <ul>
              {validationErrors.map((error, index) => (
                <li key={`error${index}`}>{error}</li>
              ))}
            </ul>
          </Modal>
        </>
      )}
    </>
  );
}

// Falta: tipo de configuración, seleccionar la cuenta bancaria a usar y el email de notificación
