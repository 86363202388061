/* eslint-disable no-use-before-define */
import { fetchProducts, fetchProductChannels } from "../Products/useProducts";
import { fetchPrecinct } from "../Precincts/usePrecincts";
import difference from "../shared/differenceBetweenTwoLists";
// import { contentFields } from "./constants";
import { tlds } from "../shared/domainVariables";
import saveProductChannelList from "./bulkUpload";

/**
 *
 * @param {*} productList
 * @returns
 * saved: number, number of element send in the request after filtered
 * errors: string[], errors received during the execution
 */
const bulkUploadProductChannels = async (productChannelsList) => {
  try {
    //* ** Validation
    const newProductList = parseProductChannels(productChannelsList);
    console.log("SALIDA PARSE", newProductList);

    const precinctCodes = newProductList.reduce((allP, product) => {
      if (!allP.includes(product.precinctCode)) {
        allP.push(product.precinctCode);
      }
      return allP;
    }, []);
    if (precinctCodes.length > 1) {
      throw new Error(`No puede enviar una lista con distintos precinctCodes`);
    }

    const errorsValidation = await productChannelsValidation(newProductList);
    if (errorsValidation.length > 0) {
      return { errors: errorsValidation };
    }

    const params = { precinctCode: precinctCodes[0] };
    const currentProductChannels = await fetchProductChannels({ params });

    //* ** Get the intersection between the two
    const { toInsert, toUpdate } = difference(currentProductChannels, newProductList, false, {});

    //* ** Save the results
    const { saved, errors } = await saveProductChannelList([...toInsert, ...toUpdate]);

    return { saved, errors };
  } catch (e) {
    return { errors: [e.message] };
  }
};

const parseProductChannels = (productChannelList) => {
  return productChannelList.map((productChannel) => {
    const newProductChannel = { ...productChannel };
    if (newProductChannel.configurationInheritedPrecinct) {
      delete newProductChannel.voucher;
      delete newProductChannel.booking;
      delete newProductChannel.cancellation;
    }
    if (newProductChannel.channel.merchant === "") {
      delete newProductChannel.channel.merchant;
    }

    return newProductChannel;
  });
};

const productChannelsValidation = async (productList) => {
  const precinctCodesChecked = {};
  const ncodes = [];
  let errors = [];

  for (let index = 0; index < productList.length; index += 1) {
    const rowId = index + 2;

    const product = productList[index];

    errors = [...errors, ...productChannelValidation(product).map((ev) => `Fila ${rowId}: ${ev}`)];

    if (precinctCodesChecked[product.precinctCode] === undefined) {
      try {
        await fetchPrecinct(product.precinctCode);
        precinctCodesChecked[product.precinctCode] = true;
      } catch (e) {
        errors.push(`Fila ${rowId}: Codigo de recinto <${product.precinctCode}> inexistente`);
        precinctCodesChecked[product.precinctCode] = false;
      }
    } else if (!precinctCodesChecked[product.precinctCode]) {
      errors.push(`Fila ${rowId}: Codigo de recinto <${product.precinctCode}> inexistente`);
    }

    if (ncodes.includes(product.code)) {
      errors.push(`Fila ${rowId}: Codigo <${product.code}> duplicado`);
    } else {
      ncodes.push(product.code);
    }
  }

  return errors;
};

const productChannelValidation = (product) => {
  const errors = [];
  // Mandatory
  ["productCode", "precinctCode", "configurationInheritedPrecinct"].forEach((field) => {
    if (product[field] === undefined) {
      errors.push(`El campo <${field}> es requerido`);
    }
  });

  return errors;
};

export default bulkUploadProductChannels;
