import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import SectionTitle from "@atoms/Titles/SectionTitle";
import FenixTable from "@molecules/Tables/FenixTable";
import Money from "@atoms/Money/Money";
import ButtonSend from "@atoms/Buttons/ButtonSend";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import { useRemittance } from "../../contexts/PaymentsToSuppliersContext";
import useRemittanceView from "./useRemittanceView";
import RemittanceConfirmationModal from "./RemittanceConfirmationModal";

const RemittanceView = ({ open, onClose }) => {
  const { remittance, remittanceLoading, getRemittanceTotalAmount } = useRemittance();
  const { fetchRemittance, removeInvoicesFromRemittance } = useRemittanceView();
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (open) {
      fetchRemittance().catch((error) => {
        console.error(error);
        // setLockdown(true);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const tableHead = [
    { code: "supplier", name: "Supplier", type: "string" },
    { code: "invoice", name: "Nº Factura", type: "string" },
    { code: "date", name: "Fecha", type: "date" },
    { code: "amount", name: "Importe (€)", type: "money" },
  ];

  function onSelectRows(rowsSelected) {
    setSelectedRows(rowsSelected);
  }

  async function removeInvoices() {
    await removeInvoicesFromRemittance(selectedRows);

    setSelectedRows([]);
  }

  async function removeAllInvoices() {
    await removeInvoicesFromRemittance(remittance.invoices);

    setSelectedRows([]);
  }

  function onContinueConfirmation() {
    setOpenModal(true);
  }

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose} disableBackdropClick={remittanceLoading}>
      <div style={{ minWidth: "800px", padding: "15px" }}>
        <SectionTitle
          title={"💰 Remesa a generar"}
          hx={"h3"}
          buttons={[
            selectedRows.length > 0 && (
              <ButtonDelete
                key={"bt1"}
                size={"large"}
                disabled={selectedRows.length === 0 || remittanceLoading}
                onClick={removeInvoices}
              >
                {`Quitar ${selectedRows.length} factura${selectedRows.length !== 1 ? "s" : ""}`}
              </ButtonDelete>
            ),
          ]}
        />

        <FenixTable
          loading={remittanceLoading}
          tableHead={tableHead}
          tableContent={remittance?.invoices ?? []}
          isSelectable
          allowSelectAll
          selectedRows={selectedRows}
          onChangeSelections={onSelectRows}
          pagination
        />

        <div>
          <div style={{ display: "inline-grid" }}>
            <span>
              <b>{remittance?.invoices.length ?? 0}</b> facturas añadidas
            </span>
            <ButtonDelete size={"small"} disabled={remittanceLoading} onClick={removeAllInvoices}>
              {`Quitar todas`}
            </ButtonDelete>
          </div>
          <span style={{ float: "right" }}>
            {"Total añadido: "}
            <b>
              <Money amount={getRemittanceTotalAmount()} />
            </b>
          </span>
        </div>

        <div style={{ textAlign: "right" }}>
          <ButtonSend onClick={onContinueConfirmation} disabled={remittanceLoading} customcolor={"green"}>
            {"Generar remesa"}
          </ButtonSend>
        </div>

        <RemittanceConfirmationModal open={openModal} onClose={() => setOpenModal(false)} />
      </div>
    </Drawer>
  );
};

export default RemittanceView;
