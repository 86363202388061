import React, { Fragment, useState } from "react";
import TextField from "@material-ui/core/TextField";
import DraggableList from "@atoms/Lists/DraggableList";
import { UsCardTitle } from "@atoms/Card/UsCard.styles";
import { Check, Cross } from "./ListEditableChip.styles";
import FxAlert from "@atoms/Alerts/FxAlert";
import ChipList from "./ChipList";
import InputText from "../Inputs/InputText";
import ButtonCustom from "../Buttons/ButtonCustom";
import { FormControl, Grid } from "@material-ui/core";

// typeInfo = tick || delete
function ListEditableChip({
  title = "",
  titleLabel,
  info,
  onChangeList,
  validation = null,
  msgError = "Contiene errores",
}) {
  const infoList = info;
  const [showPlus, setShowPlus] = useState(false);
  const [error, setError] = useState(false);
  const [valueInput, setValueInput] = useState("");
  const addValueToList = () => {
    if (valueInput !== "") {
      if (validation !== null && !validation(valueInput)) {
        setError(true);
        setValueInput(`${valueInput} - ${msgError}`);
        return null;
      }
      const infoListAux = [...infoList];
      infoListAux.push(valueInput);
      onChangeList(infoListAux);
      setValueInput("");
      setShowPlus(false);
    }
  };

  const onChangeListLocal = (newList) => {
    onChangeList(newList.map((el) => el[0]));
  };
  const list = infoList.map((d) => {
    return [d];
  });

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <div style={{ display: "inline-flex" }}>
              <InputText
                title={title}
                value={valueInput}
                onChange={(e) => {
                  setValueInput(e.value);
                }}
              />
              <ButtonCustom onClick={addValueToList} customcolor={valueInput !== "" && "lightblue"}>
                <Check />
              </ButtonCustom>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <ChipList
            editable
            list={list}
            onDeleteElement={(index) => {
              let pop = list.splice(index, 1);
              onChangeListLocal(list);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ListEditableChip;
