import styled from "styled-components";
import Fab from "@material-ui/core/Fab";

export const ButtonsPosition = styled.div`
  display: flex;
  justify-content: ${(props) => (props.justify !== undefined ? props.justify : "center")};
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 50px;
`;

export const ButtonDomain = styled(Fab)`
  box-shadow: none !important;
  margin: 5px 20px 5px 0;
  ${(props) =>
    props.isDanger && {
      "background-color": "#fff!important",
      border: "1px solid #f44336",
      color: "#f44336",
      "margin-top": "100px",
    }}
`;
