/* eslint-disable no-use-before-define */
import { fetchPrecincts } from "./usePrecincts";
import { contentFields, csvSpecContent } from "./constants";
import { tldXLangMatch } from "../shared/domainVariables";
import contentCsvRowValidation from "../shared/contentCsvRowValidation";
import savePrecinctList from "./bulkUpload";
import difference from "../shared/differenceBetweenTwoLists";

const bulkUploadPrecinctsContent = async (precinctContentList) => {
  //* ** Get precincts saved
  let currentPrecincts = [];
  try {
    currentPrecincts = await fetchPrecincts({});
  } catch (e) {
    return { errors: [e.message] };
  }

  const currentByCode = currentPrecincts.reduce((a, p) => ({ ...a, [p.code]: p }), {});

  //* ** Validation
  const errorsValidation = precinctContentListValidation(precinctContentList, currentByCode);
  if (errorsValidation.length > 0) {
    return { errors: errorsValidation };
  }

  const toUpload = createCompletePrecinctListFromContent(precinctContentList, currentByCode);

  //* ** Get the intersection between the two
  const { toUpdate } = difference(currentPrecincts, toUpload, true, contentFields);

  //* ** Save the results
  const { saved, errors } = await savePrecinctList(toUpdate);

  return { saved, errors };
};

const precinctContentListValidation = (precinctList, currentByCode) => {
  const errors = [];
  precinctList.forEach((precinct) => {
    try {
      if (currentByCode[precinct.code] === undefined) {
        throw new Error(`Precinct <${precinct.code}> does not exists.`);
      }

      contentCsvRowValidation(precinct, csvSpecContent, Object.keys(contentFields));
    } catch (e) {
      errors.push(e.message);
    }
  });

  return errors;
};

const createCompletePrecinctListFromContent = (precinctContentCsv, currentByCode) => {
  const currentCopy = JSON.parse(JSON.stringify(currentByCode));

  const toSave = precinctContentCsv.reduce((toSaveAccu, precinctContentToCheck) => {
    const { code, field, tld, value } = precinctContentToCheck;

    const newSaveAccu = { ...toSaveAccu };

    const current = currentCopy[code];
    if (toSaveAccu[current.code] === undefined) {
      newSaveAccu[current.code] = { ...currentCopy[code] };
      if (newSaveAccu[current.code].channels !== undefined) {
        delete newSaveAccu[current.code].channels;
      }
    }

    const lang = tldXLangMatch[tld];
    newSaveAccu[code][field][lang] = value;

    return newSaveAccu;
  }, {});

  return Object.values(toSave);
};

export default bulkUploadPrecinctsContent;
