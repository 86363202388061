import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import SectionTitle from "@atoms/Titles/SectionTitle";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import ErrorModal from "@atoms/Modals/ErrorModal";
import Modal from "@atoms/Modals/Modal";
import { MdWarning } from "react-icons/md";
import { Alert } from "react-bootstrap";
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import InputQueryDropdownSearchPost from "@atoms/Inputs/InputQueryDropdownSearchPost";
import { AlertProvider } from "./RappelEditor.styles";
import { UsChipEmail, ChipLabel, ChipDelete } from "@atoms/Content/Chips";
import InputNumber from "@atoms/Inputs/InputNumber";
import { Grid, TextField, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

function JsonPageEditorView({
  backToTable,
  handleSaveRapple,
  onChangeForm,
  alertOpen,
  setAlertOpen,
  alertData,
  closeErrorModal,
  form,
  errors,
  loading,
  confirmationDialog,
  setConfirmationDialog,
  infoModal,
  closeInfoModal,
  isDuplication,
}) {
  return (
    <Fragment>
      <ErrorModal show={errors.show} listErrors={errors.list} onClose={closeErrorModal} />
      <Modal show={infoModal.show} onClose={closeInfoModal}>
        <h3>Recuerda</h3>
        <p>{infoModal.text}</p>
      </Modal>
      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={() => {
          confirmationDialog.onConfirm();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
        onDeny={() => {
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
      <SectionTitle
        title={`Rappel ${form.id}`}
        backFunction={backToTable}
        buttons={[
          <ButtonSave key={"btG"} color="primary" onClick={handleSaveRapple} disabled={loading}>
            Guardar
          </ButtonSave>,
        ]}
      />
      {isDuplication && (
        <Alert variant="warning">
          <MdWarning size={60} />
          ESTE RAPPLE ES DUPLICADO <b>{form.id}</b>
        </Alert>
      )}

      <Card>
        <Card.Body>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={3}>
              <TextField disabled={true} label="Código" value={form.id} onChange={() => {}} />
            </Grid>
            <Grid item xs={2}>
              {form.rappelPercentage !== null && (
                <InputNumber
                  label="Porcentaje"
                  value={form.rappelPercentage}
                  onChange={(e) => {
                    const nuForm = { ...form };
                    nuForm.rappelPercentage = parseFloat(e);
                    onChangeForm(nuForm);
                  }}
                  min={0}
                />
              )}
            </Grid>

            <Grid item xs={5}>
              <FormControl variant="outlined" style={{ width: "50%!important" }}>
                <InputLabel id="demo-simple-select-label">Regla</InputLabel>
                <Select
                  style={{ backgroundColor: "white" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form.rules}
                  label={"Regla"}
                  onChange={(nVal) => {
                    const nuForm = { ...form };
                    nuForm.rules = nVal.target.value;
                    onChangeForm(nuForm);
                  }}
                >
                  <MenuItem disabled value={""}>
                    Selecciona una regla
                  </MenuItem>
                  {["ALL", "TICKETING", "ACCOMMODATION"].map((rule, id) => (
                    <MenuItem key={id} value={rule}>
                      <span>{rule}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <br />

          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12}>
                  <span style={{ color: "Silver" }}>Fecha de finalización de reserva</span>
                </Grid>
                <br />
                <br />
                <Grid item xs={10}>
                  <FxDatePickersFromTo
                    labelFrom={"Desde"}
                    dateFromInitial={form.dateoFrom}
                    labelTo={"Hasta"}
                    dateToInitial={form.dateoTo}
                    onChange={({ datei, dateo }) => {
                      const nuForm = { ...form };
                      console.log(datei, dateo);
                      nuForm.dateoFrom = datei;
                      nuForm.dateoTo = dateo;
                      onChangeForm(nuForm);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={12}>
                  <InputQueryDropdownSearchPost
                    label={"Buscador de Proveedores"}
                    // postRequestPath={`/it/axapimanager/queryprovider/`}
                    postRequestPath={`https://fenix.traventia.com/it/axapimanager/queryprovider/`}
                    optionFormat={(option) => {
                      return (
                        <div>
                          <b>{option.shortP}</b>
                        </div>
                      );
                    }}
                    onSelectOption={(nuValue) => {
                      const nuForm = { ...form };
                      nuForm.provider = nuValue.shortP;
                      onChangeForm(nuForm);
                    }}
                  />
                </Grid>
                <br />
                <Grid item xs={12}>
                  {/* <h6>Proveedor</h6> */}
                  <AlertProvider variant="info">
                    <TextField disabled={true} label="Proveedor" value={form.provider} onChange={() => {}} />
                    {/* {form.provider === "" ? (
                      "Ningun Proveedor añadido"
                    ) : (
                      <UsChipEmail key={"chip-provider"}>
                        <ChipLabel>{form.provider}</ChipLabel>
                        <ChipDelete
                          onClick={() => {
                            removeValueTagList("topic", index);
                          }}
                        />
                      </UsChipEmail>
                    )} */}
                  </AlertProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card.Body>
      </Card>

      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
    </Fragment>
  );
}

export default JsonPageEditorView;
