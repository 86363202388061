import React from "react";
import Grid from "@material-ui/core/Grid";
import { GoKebabHorizontal } from "react-icons/go";
import { TiMediaRecord } from "react-icons/ti";
import { AiOutlineHeart } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { IoChatbubbleOutline } from "react-icons/io5";
import { FaRegBookmark } from "react-icons/fa";

function PreviewInstagram({ form, cardImg, formaterText }) {
  const getLikes = () => {
    return Math.floor(Math.random() * (100000 - 100 + 1) + 100);
  };

  const getHour = () => {
    return Math.floor(Math.random() * (10 - 1 + 1) + 1);
  };
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={2}
      style={{ border: "solid 1px #D3D3D3" }}
    >
      <Grid item xs={8}>
        <img src={cardImg} width={"100%"} style={{ marginRight: "16px" }} />
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          direction="column"
          justify="space-between"
          spacing={2}
          style={{ borderLeft: "solid 1px #D3D3D3" }}
        >
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between" alignItems="baseline" spacing={2}>
              <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="baseline" spacing={1}>
                  <Grid item>
                    <img
                      src={
                        "https://tr3.traventia.com/graphic-design/logos/logos_traventia/travis_logo_telegram.jpg"
                      }
                      width={"40px"}
                      style={{ border: "solid 1px #FF8F18", borderRadius: "100px" }}
                    />
                  </Grid>
                  <Grid item>Traventia.es</Grid>
                  <Grid item>
                    <TiMediaRecord style={{ fontSize: "10px" }} />
                  </Grid>
                  <Grid item style={{ color: "#0095F6" }}>
                    <b>Seguir</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <GoKebabHorizontal style={{ fontSize: "20px" }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-between"
              spacing={2}
              style={{ borderTop: "solid 1px #D3D3D3", maxHeight: "380px", overflowY: "auto" }}
            >
              <Grid item xs={2}>
                <img
                  src={
                    "https://tr3.traventia.com/graphic-design/logos/logos_traventia/travis_logo_telegram.jpg"
                  }
                  width={"30px"}
                  style={{ border: "solid 1px #FF8F18", borderRadius: "100px" }}
                />
              </Grid>
              <Grid item xs={10}>
                {formaterText(form.socialNetwork.text)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              spacing={2}
              style={{ borderTop: "solid 1px #D3D3D3" }}
            >
              <Grid item xs={12}>
                <Grid container direction="row" justify="space-between" spacing={3}>
                  <Grid item>
                    <AiOutlineHeart style={{ fontSize: "26px", fontWeight: "600" }} />
                    <IoChatbubbleOutline
                      style={{
                        transform: "scaleX(-1)",
                        fontSize: "24px",
                        fontWeight: "bolder",
                        marginLeft: "15px",
                      }}
                    />
                    <FiSend style={{ fontSize: "24px", fontWeight: "600", marginLeft: "15px" }} />
                  </Grid>
                  <Grid item>
                    <FaRegBookmark style={{ fontSize: "22px", fontWeight: "600" }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "-20px" }}>
                <p style={{ fontSize: "0.9em" }}>
                  <b> {getLikes()} Me gustas</b>
                </p>
                <p style={{ fontSize: "0.7em", color: "#D3D3D3" }}>HACE {getHour()} HORAS</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PreviewInstagram;
