import axios from "axios";
// eslint-disable-next-line camelcase
const KEY_PICK_UP_URL = "/api/v1/accommodation-bc/key-pickup/information";

export const useKeyPickupList = async (page, search = "", limit = 10) => {
  let keyPickup = {
    loadingList: true,
    list: [],
    total: 0,
    status: 0,
  };
  const generateQuery = (page, search) => {
    const query = {
      _offset: page,
      _limit: limit,
      // enable: false,
    };
    if (search) {
      query["hcode[regex]"] = search;
    }
    return (
      KEY_PICK_UP_URL +
      "?" +
      new URLSearchParams(query).toString() +
      "&_fields=id,info,pickingType,hcode,updateBy,@updated_on"
    );
  };
  const fetchData = async (page, search) => {
    try {
      const response = await axios.get(generateQuery(page, search));
      if (response.status === 200) {
        return {
          loadingList: false,
          list: response.data.data,
          status: response.status,
          total: response.data.total,
        };
      }
    } catch (error) {
      return { loadingList: false, list: [], total: 0 };
    }
    return { loadingList: true, list: [], total: 0 };
  };
  keyPickup = await fetchData(page, search);
  return keyPickup;
};

export const useKeyPickupFetch = async (id) => {
  let keyPickup = {
    loading: true,
    data: {},
  };
  const fetchData = async (id) => {
    try {
      const response = await axios.get(USERS_URL + "/" + id);
      if (response.status === 200) {
        return {
          loading: false,
          data: response.data,
        };
      }
    } catch (error) {
      console.log(error);
      return { loading: false, data: {} };
    }
    return { loading: true, data: {} };
  };
  keyPickup = await fetchData(id);
  return { keyPickup };
};

export const putKeyPickups = async (keyPickup) => {
  let response = {};
  if (keyPickup.pickingType === "24H") {
    keyPickup.info = {
      "es-ES": "",
      "pt-PT": "",
      "fr-FR": "",
      "it-IT": "",
    };
  }
  try {
    response = await axios.put(`${KEY_PICK_UP_URL}`, keyPickup);
    response.ok = true;
  } catch (error) {
    response.ok = false;
    response.error = error.message;
  }
  return response;
};

export const deleteKeyPickups = async (keyPickup) => {
  let response = {};
  try {
    response = await axios.delete(`${KEY_PICK_UP_URL}/${keyPickup.hcode}`);
    response.ok = true;
  } catch (error) {
    response.ok = false;
    respoonse.error = error.message;
  }
  return response;
};
