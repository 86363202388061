/* eslint-disable no-use-before-define */
import React from "react";
import FxAlert from "@atoms/Alerts/FxAlert";
import withSkeleton from "@lib/layout/withSkeleton";
import CalendarSkeleton from "../shared/Calendar.skeleton";
import { StyDivContainerLine } from "../shared/CalendarRow.styles";
import CalendarTemplate from "../shared/CalendarTemplate";
import CalendarRowNumberedDays from "../shared/CalendarRowNumberedDays";
import CalendarRow from "../shared/CalendarRow";
import useCalendarFilters from "../shared/useCalendarFilters";
import useCalendarVisibility from "../shared/useCalendarVisibility";
import { useCalendar } from "./useCalendar";
import useChangeDay from "../shared/useChangeDay";

function Calendar({ precinctCode, channel }) {
  const {
    year,
    month,
    errors,
    priceMode,
    onChangeMonth,
    onChangeYear,
    onChangeErrors,
    onChangePriceMode,
    changeRowHighlighting,
    isHightlighted,
  } = useCalendarFilters(precinctCode);
  const { loadingCalendar, calendar, fetchCalendar, products, tickets } = useCalendar({
    precinctCode,
    channel,
    year,
  });
  const productQuotaEnable = products.reduce((ac, p) => ({ ...ac, [p.productCode]: p.quotaEnable }), {});
  const { isVisible, changeProductVisibility, changeAllProductVisibility } = useCalendarVisibility({
    precinctCode,
    loading: loadingCalendar,
    productList: products,
    key: "calendar",
  });
  const { daysLoading, onChangeInDayData } = useChangeDay({ channel, year, onChangeErrors, fetchCalendar });

  return loadingCalendar ? (
    <CalendarSkeleton />
  ) : (
    <CalendarTemplate
      propsLoading={loadingCalendar}
      calendarIsNotEmpty={calendar !== undefined && Object.keys(calendar).length > 0}
      onChangeMonth={onChangeMonth}
      onChangeYear={onChangeYear}
      onChangeErrorList={onChangeErrors}
      field={priceMode}
      {...{
        errors,
        month,
        year,
        onChangePriceMode,
        changeAllProductVisibility,
      }}
    >
      {calendar[month].products.length === 0 && (
        <FxAlert variant={"info"}>No hay productos asignados a este recinto</FxAlert>
      )}

      {calendar[month].products.map((product, index) => (
        <div key={`product${index}`}>
          {/* Linea de dias */}
          <CalendarRowNumberedDays daysOfMonthArray={Object.values(product.calendar)} />
          {/*  Linea de recinto */}
          {isVisible(product.productCode) && (
            <CalendarRow
              type={"precinct"}
              item={{ precinctCode, calendar: calendar[month].precinctCalendar }}
              onClickInDay={onChangeInDayData}
              {...{ daysLoading, changeProductVisibility, precinctCode }}
            />
          )}
          {/*  Linea de producto */}
          <CalendarRow
            item={product}
            type={"product"}
            parentCalendars={[calendar[month].precinctCalendar]}
            productCode={product.productCode}
            product={products.find((cvProduct) => cvProduct.productCode === product.productCode)}
            onClickInDay={onChangeInDayData}
            {...{ daysLoading, isVisible, changeProductVisibility, precinctCode }}
            fieldToShow={
              priceMode === "packageMarkup"
                ? priceMode
                : productQuotaEnable[product.productCode] && priceMode === "quota"
                ? priceMode
                : undefined
            }
          />

          {/* Linea de tarifa  */}
          {isVisible(product.productCode) && (
            <>
              {product.tickets.length > 0 ? (
                product.tickets.map((ticket, indexTicket) => {
                  const ticketData = tickets[product.productCode].find((t) => t.id === ticket.ticketId);
                  return (
                    ticketData &&
                    !ticketData.archived && (
                      <CalendarRow
                        key={`ticket${index}${indexTicket}`}
                        item={ticket}
                        precinctCode={precinctCode}
                        productCode={product.productCode}
                        type={"ticket"}
                        fieldToShow={priceMode}
                        daysLoading={daysLoading}
                        onClickInDay={onChangeInDayData}
                        parentCalendars={[calendar[month].precinctCalendar, product.calendar]}
                        highlighted={isHightlighted(product.productCode, ticket.ticketId)}
                        changeHighligthing={() => changeRowHighlighting(product.productCode, ticket.ticketId)}
                      />
                    )
                  );
                })
              ) : (
                <StyDivContainerLine>
                  <FxAlert variant={"info"}>No hay entradas asignadas a este producto</FxAlert>
                </StyDivContainerLine>
              )}
            </>
          )}
        </div>
      ))}
    </CalendarTemplate>
  );
}

export default withSkeleton(Calendar, CalendarSkeleton);
