/* eslint-disable no-use-before-define */
import { useState } from "react";
import useFilterInStorage from "./useFilterInStorage";

function useCalendarFilters(precinctCode) {
  const today = new Date();

  const [month, onChangeMonth] = useFilterInStorage(precinctCode, "month", today.getMonth());
  const [year, onChangeYear] = useFilterInStorage(precinctCode, "year", today.getFullYear());
  const [priceMode, onChangePriceMode] = useFilterInStorage(precinctCode, "priceMode", "cost");

  const [rowHighlight, onChangeHighlighting] = useFilterInStorage(precinctCode, "highlight", {});
  const [displayedChildren, onChangeChildren] = useFilterInStorage(precinctCode, "children", {});
  // const [displayedChildren, setDisplayedChildren] = useState({});

  const [errors, setErrors] = useState([]);

  function onChangeErrors(newErrors) {
    setErrors(newErrors);
  }

  function changeRowHighlighting(productCode, rowId) {
    const current = { ...rowHighlight };

    if (current[productCode] === undefined) {
      current[productCode] = {};
    }

    current[productCode][rowId] =
      current[productCode][rowId] === undefined ? true : !current[productCode][rowId];
    onChangeHighlighting(current);
  }

  function changeDisplayedChildren(productCode, rowId) {
    const current = { ...displayedChildren };

    if (current[productCode] === undefined) {
      current[productCode] = {};
    }

    current[productCode][rowId] =
      current[productCode][rowId] === undefined ? true : !current[productCode][rowId];
    onChangeChildren(current);
  }

  function areChildrenDisplayed(productCode, rowId) {
    if (Object.keys(displayedChildren).length === 0) {
      return false;
    }
    if (displayedChildren[productCode] === undefined) {
      return false;
    }
    return displayedChildren[productCode][rowId] !== undefined && displayedChildren[productCode][rowId];
  }

  function isHightlighted(productCode, rowId) {
    if (Object.keys(rowHighlight).length === 0) {
      return false;
    }
    if (rowHighlight[productCode] === undefined) {
      return false;
    }
    return rowHighlight[productCode][rowId] !== undefined && rowHighlight[productCode][rowId];
  }

  return {
    year,
    month,
    errors,
    priceMode,
    rowHighlight,
    displayedChildren,
    areChildrenDisplayed,
    isHightlighted,
    onChangeMonth,
    onChangeYear,
    onChangeErrors,
    onChangePriceMode,
    changeRowHighlighting,
    changeDisplayedChildren,
  };
}

export default useCalendarFilters;
