import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";

export const DraggableListBox = styled.div`
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  opacity: 1;
  /* &:hover {
    opacity: 0.6;
  } */
  ${({ active }) =>
    active &&
    `
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0.6;
  `}
  ${({ hide }) =>
    hide &&
    `
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0;
  `}
`;

export const RemoveOption = styled(AiOutlineClose)`
  position: relative;
  top: 0px;
  right: 0px;
  z-index: 1000;
  font-size: 20px;
  color: #666 !important;
  cursor: pointer;
`;
