import { useEffect, useState } from "react";
import axios from "axios";

const catchErrorMessage = (errorObject) => {
  let message = "Error no capturado";
  if (errorObject.message) {
    message = errorObject.message;
  }

  const { response } = errorObject;
  if (response !== undefined) {
    if (response.data !== undefined) {
      if (response.data.err !== undefined) {
        if (response.data.err.message !== undefined) {
          message = response.data.err.message;
        }
      }
    }
  }

  return message;
};
/**
 *
 * @param {*} tld
 * @param {*} path
 */
// eslint-disable-next-line import/prefer-default-export
export const useRappelEditor = (id) => {
  const [state, setState] = useState({ loading: true, list: [], error: "", ok: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { list: {}, loading: false };
        const response = await axios.get(`/api/v1/product-bc/rappel/${id}`);
        if (response.status === 200 && response.data) {
          thestate = { list: response.data, loading: false, error: "", ok: true };
        }
        setState(thestate);
      } catch (error) {
        setState({ list: {}, loading: false, error: catchErrorMessage(error), ok: false });
        throw error;
      }
    };
    if (state.loading) {
      fetchData();
    }
  }, [state.loading, state, id]);

  return { loading: state.loading, rappel: state.list, error: state.error, ok: state.ok };
};

export const useProviders = (input) => {
  const [state, setState] = useState({ loading: true, list: [], error: "", ok: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { list: {}, loading: false };
        // https://fenix.traventia.com/it/axapimanager/queryprovider/
        const response = await axios.post(`/it/axapimanager/queryprovider/`, { query: input });
        if (response.status === 200 && response.data) {
          thestate = { list: response.data, loading: false, error: "", ok: true };
        }
        setState(thestate);
      } catch (error) {
        setState({ list: {}, loading: false, error: catchErrorMessage(error), ok: false });
        throw error;
      }
    };
    if (state.loading) {
      fetchData();
    }
  }, [state.loading, state, input]);

  return { loadingP: state.loading, providers: state.list, errorP: state.error, okP: state.ok };
};

export const useSuppliers = (id) => {
  const [state, setState] = useState({ loading: true, list: [], error: "", ok: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { list: {}, loading: false };
        const response = await axios.get(`/proxy/us_accounting/api/suppliers`);
        if (response.status === 200 && response.data) {
          thestate = { list: response.data, loading: false, error: "", ok: true };
        }
        setState(thestate);
      } catch (error) {
        setState({ list: {}, loading: false, error: catchErrorMessage(error), ok: false });
        throw error;
      }
    };
    if (state.loading) {
      fetchData();
    }
  }, [state.loading, state, id]);

  return {
    loadingSuppliers: state.loading,
    suppliers: state.list,
    error: state.error,
    okSuppliers: state.ok,
  };
};

export const upsertRappel = async (rappel) => {
  let responseRet = { ok: false };
  try {
    const requestRes = await axios.put(`/api/v1/product-bc/rappel/${rappel.id}`, rappel);
    if (requestRes.status === 201 && requestRes) {
      responseRet = { ok: true };
    }
  } catch (error) {
    const message = catchErrorMessage(error);
    responseRet = { ok: false, message };
  }
  return responseRet;
};
