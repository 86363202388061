import axios from "axios";

// import * as vouchersJSON from "../components/vouchersMock.json";
// import * as voucherDetailJSON from "../components/voucherMockDetail_XDF0685322RB.json";
// import * as voucherDetailJSONFerry from "../components/voucherMockDetail_BOG4312422QB.json";
import useVoucherUtils from "./useVoucherUtils";

const urlGetAllVouchers = "/vouchers/axvouchers/getBooksByVoucherState/";
const urlGetVoucherById = "/vouchers/axvouchers/getVoucherData/";
const urlBlockAndUnBlockVoucher = "/vouchers/axvouchers/blockVoucher/";
const urlSendMarkVoucher = "/vouchers/axvouchers/changeVoucherFlag/";
const urlSendEmailVoucher = "/vouchers/axvouchers/sendVoucherByMail/";
const urlMutexBlockBook = "/gestor/axbooks/mutexbook/";
const urlCombineVoucherPdfs = "/vouchers/axvouchers/mergeVouchers/";
const urlUploadVoucherPdfs = "/vouchers/axvouchers/uploadVoucher/";
const urlRegenerateVouchersPdf = "/vouchers/axvouchers/regenvoucher/";
const urlSeePDF = "/";

export const useVoucherList = async () => {
  const { voucherClassification, cleanVoucherList } = useVoucherUtils();

  let vouchersData = {
    loadingList: true,
    list: {
      pendings: [],
      infringements: [],
      ferrys: [],
      travisWorking: [],
    },
    total: 0,
  };
  // TODO: Cuando se tenga el endopoint, eliminar vouchersJSON
  const fetchData = async () => {
    // TODO:Eliminar estas dos lineas y descomentar el resto cuando se tenga el EP
    // const { infringements, ferrys, travisWorking } = await voucherClassification(vouchersJSON.default.data.data);
    // return { loadingList: false, list: { infringements, ferrys, travisWorking } };
    try {
      const response = await axios.get(urlGetAllVouchers);
      if (response.status === 200) {
        const cleanVouchers = await cleanVoucherList(response.data.pending);
        const { pendings, infringements, ferrys, travisWorking } = await voucherClassification(cleanVouchers);
        return {
          loadingList: false,
          list: { pendings, infringements, ferrys, travisWorking },
          total: cleanVouchers.length,
        };
      }
    } catch (error) {
      vouchersData = { loadingList: false, list: [], total: 0 };
      throw error;
    }
    return { loadingList: true, list: [], total: 0 };
  };
  vouchersData = { ...(await fetchData()) };
  return { vouchersData };
};

export const manageVoucher = async (bookref, checkMutex = true) => {
  /* TODO:La acción ha sido mockeada, cuando se añada el EP, habría que realizar una 
  petición get/:id, donde el id es el bookref del bono, así es como funciona actuamente */
  // if (bookref === "BOG4312422QB") {
  //   return { loadingVoucher: false, bookref, detail: voucherDetailJSONFerry.default };
  // }
  // return { loadingVoucher: false, bookref, detail: voucherDetailJSON.default };
  try {
    const response = await axios.get(`${urlGetVoucherById}${bookref}/${checkMutex ? "1" : "0"}`);
    if (response.status === 200) {
      return { loadingVoucher: false, bookref, detail: response.data };
    }
  } catch (error) {
    // console.log(error);
    return error.message;
  }
  return { loadingVoucher: true, bookref, detail: {} };
};

/* TODO:La acción ha sido mockeada, cuando se añada el EP, habría que revisar si
al recargar la página se mantiene bloqueada y tiene la propiedad voucherBlock
*/

export const blockVoucher = async (voucher, user) => {
  // TODO:Eliminar estas tres lineas y descomentar el resto cuando se tenga el EP
  // eslint-disable-next-line no-param-reassign
  voucher.voucherBlock = user.profile.email;
  // console.log("Evento Bloquear", voucher);
  const body = { bookref: voucher.bookref, usuario: user.profile.email };
  try {
    const response = await axios.post(urlBlockAndUnBlockVoucher, body);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // console.log(error);
    return error.message;
  }
  return null;
};

/* TODO:La acción ha sido mockeada, cuando se añada el EP, habría que revisar si
al recargar la página se desbloquea y ya no tiene la propiedad voucherBlock
*/

export const unBlockVoucher = async (voucher) => {
  // TODO:Eliminar estas tres lineas y descomentar el resto cuando se tenga el EP
  // eslint-disable-next-line no-param-reassign
  // const { voucherBlock, ...voucherUnBlocked } = voucher;
  // console.log("Evento Desbloquear", voucherUnBlock);
  // console.log("Evento Desbloquear", voucher);
  const body = { bookref: voucher.bookref, usuario: "" };
  try {
    const response = await axios.post(urlBlockAndUnBlockVoucher, body);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // console.log(error);
    return error.message;
  }
  return null;
};

export const seeVoucherPDF = async (voucher) => {
  // console.log("Evento ver PDF", voucher);
  const { typeKey, bookref, voucherList, index } = voucher;

  if (
    (typeKey === "main" || !typeKey) &&
    voucherList?.main?.path !== undefined &&
    voucherList?.main?.path !== ""
  ) {
    // eslint-disable-next-line no-restricted-globals, no-undef
    window.open(
      `${urlSeePDF}vouchers/axvouchers/seeVoucher/${bookref}/main/0`,
      "_blank",
      "noopener,noreferrer"
    );
  }
  if (voucherList?.path !== undefined && voucherList?.path !== "") {
    // eslint-disable-next-line no-restricted-globals, no-undef
    window.open(
      `${urlSeePDF}vouchers/axvouchers/seeVoucher/${bookref}/${typeKey}/${index}`,
      "_blank",
      "noopener,noreferrer"
    );
  }
};

export const sendEmailVoucher = async (voucher, fromTable) => {
  // TODO:Eliminar estas tres lineas y descomentar el resto cuando se tenga el EP
  // const voucherHasMarkedAsSend = 1;
  // // eslint-disable-next-line no-param-reassign
  // voucher.voucher = voucherHasMarkedAsSend;
  // console.log("Evento enviar (MOCKEADO REVISAR)", voucher);
  try {
    const response = await axios.get(`${urlSendEmailVoucher}${voucher.bookref}/${fromTable}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // console.log(error);
    return error.message;
  }
  return null;
};

export const sendMarkVoucher = async (voucher) => {
  // TODO:Eliminar estas tres lineas y descomentar el resto cuando se tenga el EP

  // const voucherHasMarkedAsSend = 1;
  // // eslint-disable-next-line no-param-reassign
  // voucher.voucher = voucherHasMarkedAsSend;
  // console.log("Evento marcar enviar", voucher);
  const body = { bookCode: voucher.bookref, status: 1 };
  try {
    const response = await axios.post(urlSendMarkVoucher, body);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return error.message;
  }
  return null;
};

export const combineVoucherPDFs = async (voucher) => {
  // console.log("Evento combinar PDFs enviar(MOCKEADO)", voucher);

  try {
    const response = await axios.post(urlCombineVoucherPdfs, voucher);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // console.log(error);
    return error.message;
  }
  // return null;
};

export const uploadVoucher = async (voucher) => {
  try {
    const formData = new FormData();
    formData.append("uploadFile", voucher.file, voucher.file.name);
    formData.append("uploadPath", `vouchers/${voucher.bookref}/`);
    formData.append("bucket", "tr-vouchers");

    const response = await axios.post(
      `${urlUploadVoucherPdfs}${
        voucher.isComplete
          ? `${voucher.bookref}/main/0/VACIO`
          : `${voucher.bookref}/${voucher.type}/${voucher.index}/${voucher.servref}`
      }`,
      formData
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // console.log(error);
    return error.message;
  }
  // return null;
};

export const mutexVoucher = async (voucher, user) => {
  const path = `${voucher.bookref}/${randomCode()}${user.profile.email}${randomCode()}`;
  try {
    const response = await axios.get(`${urlMutexBlockBook}${path}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // console.log(error);
    return error.message;
  }
  return null;
};

export const regenerateVoucher = async (voucher) => {
  const path = `${urlRegenerateVouchersPdf}${voucher.bookref}`;
  try {
    const response = await axios.get(path);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // console.log(error);
    return error.message;
  }
};

const randomCode = () => {
  return Math.round(Math.random() * 100000);
};
