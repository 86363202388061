import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { RiCharacterRecognitionLine } from "react-icons/ri";
import { MdOutlineNotificationImportant } from "react-icons/md";
import SectionTitle from "@atoms/Titles/SectionTitle";
import { ThreeDotMenu, useThreeDotMenu } from "@molecules/Menus/ThreeDotMenu";
import {
  contentFields,
  csvHead,
  csvSpecs,
  wordcontrol,
  filename,
  wordcontrolRelevantInformation,
  filenameRelevantInformation,
  csvHeadRelevantInformation,
  csvSpecRelevantInformation,
  wordcontrolContent,
  filenameContent,
  csvHeadContent,
  csvSpecContent,
} from "./constants";
import bulkUploadProducts from "./bulkUploadProducts";
import bulkUploadRelevantInformation from "./bulkUploadRelevantInformation";
import bulkUploadProductsContent from "./bulkUploadProductsContent";
import { fetchRelevantInformationListOfCodes } from "./useProducts";
import MenuItemUploadCSV from "../shared/MenuItemUploadCSV";
import MenuItemDownloadCSV from "../shared/MenuItemDownloadCSV";
import { langs, langXTldMatch } from "../shared/domainVariables";
import reorganizeListIntoCsvContent from "../shared/reorganizeListIntoCsvContent";
// Icons
import EditIcon from "@material-ui/icons/Edit";

function ProductsSectionMenu({ precinctCode, productList, onUploadEnded }) {
  const history = useHistory();
  const itemsName = "productos";

  const [loading, setLoading] = useState(false);

  const { anchorEl, onOpenMenu, handleClose } = useThreeDotMenu();

  const getRelevantInformation = async () => {
    setLoading(true);
    //* Get relevant Information stored
    const productsCodes = [...new Set(productList.map((t) => t.code))];
    const responsePromises = await fetchRelevantInformationListOfCodes(productsCodes);
    const currentInformation = responsePromises
      .flat()
      .map((ri) => ({ ...ri, lang: langXTldMatch[ri.lang], content: ri.content.replace(/\n/g, "") }));
    setLoading(false);
    return currentInformation;
  };

  function onUploadCSVEnded(result) {
    handleClose();
    onUploadEnded(result);
  }

  function generateProductListCSV() {
    return productList.map((product) => {
      const nuP = { ...product };
      if (nuP.peopleRequirements !== undefined) {
        if (nuP.peopleRequirements.ages !== undefined && nuP.peopleRequirements.ages.length > 0) {
          nuP.peopleRequirements.ages = nuP.peopleRequirements.ages.map((age) =>
            [age.min, age.max].join("-")
          );
        }
      }
      return nuP;
    });
  }

  const editorProductChannels = () => {
    history.push(`${history.location.pathname}/products/channels`);
  };

  const editorProductConfigurations = () => {
    history.push(`${history.location.pathname}/products/configurations`);
  };

  return (
    <SectionTitle
      style={{ width: "100%", marginTop: "0" }}
      title={"Productos"}
      buttons={[
        <ThreeDotMenu key={0} style={{ minWidth: "0" }} {...{ anchorEl, onOpenMenu, handleClose }}>
          <span style={{ width: "100%" }} onClick={editorProductChannels}>
            <EditIcon size={25} /> Editar Canales
          </span>
          <span style={{ width: "100%" }} onClick={editorProductConfigurations}>
            <EditIcon size={25} /> Editar Configuraciones
          </span>
          <MenuItemUploadCSV
            itemsName={itemsName}
            wordcontrol={wordcontrol}
            filename={`${precinctCode}_${filename}`}
            buttonLabel={"Subir productos"}
            specs={csvSpecs}
            uploadFunction={bulkUploadProducts}
            onUploadEnded={onUploadCSVEnded}
          />
          <MenuItemDownloadCSV
            wordcontrol={wordcontrol}
            filename={`${precinctCode}_${filename}`}
            head={csvHead}
            specs={csvSpecs}
            // table={productList}
            generateTable={generateProductListCSV}
            buttonLabel={"Descargar productos"}
            onDownloadEnded={handleClose}
          />

          {/* Contenido */}
          <MenuItemUploadCSV
            icon={<RiCharacterRecognitionLine size={20} />}
            itemsName={"contenidos"}
            wordcontrol={wordcontrolContent}
            filename={filenameContent}
            buttonLabel={"Subir contenido"}
            specs={csvSpecContent}
            uploadFunction={bulkUploadProductsContent}
            onUploadEnded={onUploadCSVEnded}
          />
          <MenuItemDownloadCSV
            icon={<RiCharacterRecognitionLine size={20} />}
            wordcontrol={wordcontrolContent}
            filename={filenameContent}
            head={csvHeadContent}
            specs={csvSpecContent}
            generateTable={() => reorganizeListIntoCsvContent(productList, contentFields)}
            buttonLabel={"Descargar contenido"}
            onDownloadEnded={handleClose}
          />

          {/* Info relevante */}
          <MenuItemUploadCSV
            icon={<MdOutlineNotificationImportant size={20} />}
            itemsName={"Info relevante"}
            wordcontrol={wordcontrolRelevantInformation}
            filename={`${precinctCode}_${filenameRelevantInformation}`}
            buttonLabel={"Subir informacion relevante"}
            specs={csvSpecRelevantInformation}
            uploadFunction={bulkUploadRelevantInformation}
            onUploadEnded={onUploadCSVEnded}
          />
          <MenuItemDownloadCSV
            icon={<MdOutlineNotificationImportant size={20} />}
            wordcontrol={wordcontrolRelevantInformation}
            head={csvHeadRelevantInformation}
            specs={csvSpecRelevantInformation}
            filename={`${precinctCode}_${filenameRelevantInformation}`}
            generateTable={getRelevantInformation}
            buttonLabel={"Descargar informacion relevante"}
            onDownloadEnded={handleClose}
          />
        </ThreeDotMenu>,
      ]}
    />
  );
}

ProductsSectionMenu.propTypes = {};

export default ProductsSectionMenu;
