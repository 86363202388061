import React from "react";
import { Grid } from "@material-ui/core";
import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import FxAlert from "@atoms/Alerts/FxAlert";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import SelectorHzones from "@molecules/Forms/SelectorHzones";
import SelectorAccommodations from "@molecules/Forms/SelectorAccommodations";

function CityTaxEditorRequirements({ ctTemplate, onChange }) {
  const includeHzones = (newHzones) => {
    const n = { ...ctTemplate };
    n.selector.hzones.inclusives = newHzones;
    onChange(n);
  };
  const excludeHzones = (newHzones) => {
    const n = { ...ctTemplate };
    n.selector.hzones.exclusives = newHzones;
    onChange(n);
  };

  const includeHcodes = (newHzones) => {
    const n = { ...ctTemplate };
    n.selector.hcodes.inclusives = newHzones;
    onChange(n);
  };
  const excludeHcodes = (newHzones) => {
    const n = { ...ctTemplate };
    n.selector.hcodes.exclusives = newHzones;
    onChange(n);
  };

  return (
    <TravCard>
      <TravBodyCard>
        <TravTitleCard>Requisitos</TravTitleCard>
        <FxAlert variant={"info"}>
          {`Aqui has de definir los requisitos que aplicaran a esta plantilla. 
                  Puedes añadir zonas de alojamiento y alojamientos (éstos tendran prioridad sobre las zonas).
                  Tambien puedes excluirlos, por lo que no serán explicitamente incluidos para esta plantilla.`}
        </FxAlert>
        <AccordionItem title={"Zonas de alojamiento"} open={false}>
          <Grid container>
            <Grid item xs={6}>
              <SelectorHzones
                title={
                  <>
                    {"Zonas de alojamiento a "}
                    <span style={{ color: "green" }}>
                      <b>{"INCLUIR"}</b>
                    </span>
                  </>
                }
                selected={ctTemplate.selector?.hzones?.inclusives}
                onChange={includeHzones}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectorHzones
                title={
                  <>
                    {"Zonas de alojamiento a "}
                    <span style={{ color: "red" }}>
                      <b>{"EXCLUIR"}</b>
                    </span>
                  </>
                }
                selected={ctTemplate.selector?.hzones?.exclusives}
                onChange={excludeHzones}
              />
            </Grid>
          </Grid>
        </AccordionItem>
        <AccordionItem title={"Alojamientos"} open={false}>
          <Grid container>
            <Grid item xs={6}>
              <SelectorAccommodations
                title={
                  <>
                    {"Alojamientos a "}
                    <span style={{ color: "green" }}>
                      <b>{"INCLUIR"}</b>
                    </span>
                  </>
                }
                selected={ctTemplate.selector?.hcodes?.inclusives}
                onChange={includeHcodes}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectorAccommodations
                title={
                  <>
                    {"Alojamientos a "}
                    <span style={{ color: "red" }}>
                      <b>{"EXCLUIR"}</b>
                    </span>
                  </>
                }
                selected={ctTemplate.selector?.hcodes?.exclusives}
                onChange={excludeHcodes}
              />
            </Grid>
          </Grid>
        </AccordionItem>
      </TravBodyCard>
    </TravCard>
  );
}

CityTaxEditorRequirements.propTypes = {};

export default CityTaxEditorRequirements;
