import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { MdClose } from "react-icons/md";
import moment from "moment";
import { useNextExecutions } from "./hooks/useCrossSellingFetch";

function ModalNextExecutions({ campaign, onClose }) {
  const MAX_SHOW = 10;
  const campaignCode = campaign.code;

  const { loadingNext, list } = useNextExecutions(campaignCode, MAX_SHOW);

  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <DialogTitle>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <MdClose />
        </IconButton>
        Próximas {MAX_SHOW} ejecuciones de la campaña <b>{campaign.name}</b>:
      </DialogTitle>
      <DialogContent>
        {loadingNext ? (
          <span>Loading...</span>
        ) : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nº</TableCell>
                <TableCell>Campaña</TableCell>
                <TableCell>Fecha/Hora</TableCell>
                <TableCell>Tipo</TableCell>
              </TableRow>
            </TableHead>
            {list.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4}>No se encuentra ninguna ejecución.</TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {list.map((execution, index) => {
                  return (
                    <TableRow key={`execution_${index}`}>
                      <TableCell component="th" scope="row">
                        #{index + 1}
                      </TableCell>
                      <TableCell>{execution.code}</TableCell>
                      <TableCell>
                        {moment(execution.date).format("L")} {execution.hour}
                      </TableCell>
                      <TableCell>{execution.type}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        )}
      </DialogContent>
    </Dialog>
  );
}

ModalNextExecutions.propTypes = {
  campaign: PropTypes.object,
  onClose: PropTypes.func,
};

export default ModalNextExecutions;
