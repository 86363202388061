import thisNamespaces from "./namespaces";

const cache = {};
const TTL = 1000 * 60 * 2;

export async function serverSideTranslations(locale, namespaces) {
  console.log(locale);
  if (!isInCache(locale)) {
    await loadTranslationsFromBackend(locale);
  }
  return {
    i18Traventia: {
      translations: filterTranslationsWithNamespaces(cache[locale]?.translations ?? {}, namespaces),
    },
  };
}

function filterTranslationsWithNamespaces(translations, namespaces) {
  return namespaces.reduce((accu, namespace) => {
    if (translations[namespace]) {
      return Object.assign(accu, { [namespace]: translations[namespace] });
    }
    return accu;
  }, {});
}

async function loadTranslationsFromBackend(locale) {
  //console.log("Reloading translation cache for locale:", locale);
  const langs = await getLangs(locale);

  const namespaces = getNamespaces(langs);
  const translations = { langs, ...namespaces };
  cache[locale] = { ts: new Date().getTime(), translations };
}

const isInCache = (locale) => {
  return cache[locale] && cache[locale].ts > new Date().getTime() - TTL;
};

async function getLangs(locale) {
  const response = await fetch(`/api/v1/web-bff-bc/langs/${locale}`);
  return await response.json();
}

function getNamespaces(langs) {
  //const nsFeed: { [key: string]: string[] } = { ...namespaceContent };
  const nsFeed = { ...thisNamespaces() };
  const initial = {};

  return Object.keys(nsFeed).reduce((accu, namespaceKey) => {
    let nuAccu = { ...accu };
    let source = nsFeed[namespaceKey];
    if (namespaceKey !== "common") source = [...nsFeed[namespaceKey], ...nsFeed["common"]];
    nuAccu[namespaceKey] = source.reduce((kvacc, langKey) => {
      if (langs[langKey] !== undefined) {
        return { ...kvacc, [langKey]: langs[langKey] };
      }
      return kvacc;
    }, {});
    return nuAccu;
  }, initial);
}
