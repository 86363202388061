import { useEffect, useState } from "react";
import axios from "axios";

/**
 *
 */
export const getHomeContent = () => {
  const emptyHomeContent = { slider: [], speedlinks: [] };

  const [market, setMarket] = useState("es");
  const [homeContent, setHomeContent] = useState(emptyHomeContent);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading]);

  useEffect(() => {
    fetchData();
  }, [market]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/v1/product-bc/content/home-conf/${market}`);
      if (response.status === 200) {
        delete response.data["@created_at"];
        delete response.data["@updated_at"];
        setHomeContent(response.data);
      }
      setLoading(false);
    } catch (error) {
      setHomeContent(emptyHomeContent);
      setLoading(false);
    }
  };

  const saveHomeContent = async () => {
    try {
      await axios.put(`/api/v1/product-bc/content/home-conf/${market}`, homeContent);
      alert("Guardado!");
    } catch (error) {
      alert("Ha ocurrido un error!");
    }
  };

  return { loading, homeContent, setHomeContent, market, setMarket, saveHomeContent };
};
