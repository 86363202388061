import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CustomModal from "@molecules/Modal/CustomModal";
import { Fab, Grid, FormControl, Input, Select, Divider, InputLabel, MenuItem } from "@material-ui/core";
import { postNewManualPayment } from "./usePayments";

const PaymentsModalAdd = ({ bookData, open, onClose }) => {
  const paymentmethods = [{ type: 998, label: "Manual" }];
  const reasonsForPayment = [
    { label: "Reserva de familiar o conocido" },
    { label: "Incidencia con los pagos" },
    { label: "Reserva de test" },
    { label: "Otros (Indicar razón)" },
  ];

  const [loading, setLoading] = useState(false);
  const [paymenthod, setPaymethod] = useState(0);
  const [amount, setAmout] = useState();
  const [reason, setReason] = useState(-1);
  const [reasonText, setReasonText] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const changePaymethod = (event) => {
    setPaymethod(event.target.value);
  };
  const changeReasonForPayment = (event) => {
    setReason(event.target.value);
  };

  useEffect(() => {
    let ready = paymenthod !== undefined && amount > 0 && reason !== undefined && reason !== -1;
    if (reason === 3) {
      ready = ready && reasonText !== undefined && reasonText.length > 0;
    }
    setButtonDisabled(!ready);
  }, [paymenthod, amount, reason, reasonText]);

  async function sendNewManualPayment() {
    setLoading(true);
    let comment = reasonsForPayment[reason].label;
    if (reason === 3) {
      comment = [comment, reasonText].join(": ");
    }

    const payment = {
      bookref: bookData.bookref,
      email: bookData.email,
      method: paymentmethods[paymenthod].type,
      amount: amount * 100,
      comment,
    };

    const response = await postNewManualPayment(payment);
    if (response.status === 201) {
      onClose(true);
    } else {
      alert("Ocurrio un error al crear este pago.");
      setLoading(false);
    }
  }

  return (
    <CustomModal
      loading={loading}
      title={"Añadir un pago a esta reserva"}
      open={open}
      handleClose={() => onClose(false)}
      bigModal={true}
    >
      {/* ---Formulario de pago--- */}
      <Grid container spacing={4}>
        <Grid container item xs={4}>
          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>Método de pago:</div>

          <FormControl fullWidth>
            <InputLabel>Selecciona un método</InputLabel>
            <Select value={paymenthod} onChange={changePaymethod} label={paymentmethods[paymenthod].label}>
              {paymentmethods.map((paym, index) => (
                <MenuItem key={`paymethod${index}`} value={index}>
                  {paym.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={8}>
          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>Selecciona cantidad:</div>
          <FormControl fullWidth>
            <InputLabel>Cantidad</InputLabel>
            <Input
              type="number"
              value={amount || ""}
              onChange={(e) => setAmout(e.target.value)}
              endAdornment={"€"}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid container item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      {/* ---Justificación motivo pago--- */}
      <Grid container spacing={4}>
        <Grid container item xs={12} justify="space-between">
          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>¿Por qué añades este pago?*:</div>
          <FormControl fullWidth>
            <InputLabel>Motivo del pago</InputLabel>
            <Select
              value={reason}
              onChange={changeReasonForPayment}
              label={reasonsForPayment[reason]?.label || ""}
            >
              {reasonsForPayment.map((tReason, index) => (
                <MenuItem key={`payemntReason${index}`} value={index}>
                  {tReason.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* ---Textarea for write down reason--- */}
          {reason === 3 && (
            <textarea
              style={{ width: "100%", marginTop: "10px" }}
              name="textarea"
              rows="10"
              cols="50"
              value={reasonText}
              onChange={(e) => setReasonText(e.target.value)}
              placeholder={"Escribe aquí el motivo del pago."}
            ></textarea>
          )}
        </Grid>
      </Grid>
      {/* ---Botón añadir--- */}
      <Grid container spacing={4}>
        <Grid container item xs={12} justify="flex-end">
          <Fab variant="extended" color="primary" disabled={buttonDisabled} onClick={sendNewManualPayment}>
            {"Añadir pago"}
          </Fab>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

PaymentsModalAdd.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PaymentsModalAdd.defaultProps = {};

export default PaymentsModalAdd;
