/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Box, Tabs, Tab } from "@material-ui/core";
import { FiRefreshCcw } from "react-icons/fi";
import { BsFillCalendarDateFill, BsFillClockFill } from "react-icons/bs";
import { IoTicketSharp } from "react-icons/io5";
import ButtonEdit from "@atoms/Buttons/ButtonEdit";
import FxAlert from "@atoms/Alerts/FxAlert";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import SectionTitle from "@atoms/Titles/SectionTitle";
import ChannelSelector from "./ChannelSelector";
import Calendar from "./CalendarPrecinct/Calendar";
import CalendarCSV from "./CalendarMassUpdate/CalendarCSV";
import CalendarSessionCSV from "./CalendarSession&TicketSessionUpdated/CalendarSessionUpdate/CalendarSessionCSV";
import CalendarTicketSessionCSV from "./CalendarSession&TicketSessionUpdated/CalendarTicketSessionUpdate/CalendarTicketSessionCSV";
import CalendarMassUpdate from "./CalendarMassUpdate/CalendarMassUpdate";
import CalendarSessions from "./CalendarSessions/CalendarSessions";
import CalendarSessionsMassUpdate from "./CalendarSessionsMassUpdate/CalendarSessionsMassUpdate";
import CalendarUpdateConfiguration from "./CalendarUpdateConfiguration/CalendarUpdateConfiguration";
import useCalendarChannels from "./useCalendarChannels";
import ButtonNavegation from "../shared/ButtonNavegation";

function CalendarSection({ precinctCode, url }) {
  const tabs = [
    {
      label: "Calendario productos",
      value: 0,
      icon: (
        <div style={{ display: "flex", gap: "5px" }}>
          <IoTicketSharp size={20} />
          <BsFillCalendarDateFill size={20} />
        </div>
      ),
      url: ["products", "products-update"],
    },
    // {
    //   label: "Calendario sesiones",
    //   value: 1,
    //   icon: (
    //     <div style={{ display: "flex", gap:"5px" }}>
    //       <BsFillClockFill size={20} />
    //       <BsFillCalendarDateFill size={20} />
    //     </div>
    //   ),
    //   url: ["sessions", "sessions-update"],
    // },
    {
      label: "Actualización automática",
      value: 1,
      icon: <FiRefreshCcw size={20} />,
      url: ["auto-update"],
    },
  ];

  const tab = tabs.findIndex((t) => t.url.includes(url));

  const history = useHistory();

  const { loadingChannels, channels, channel, onChangeChannel } = useCalendarChannels(precinctCode);

  const [resultOpen, setResultOpen] = useState(false);

  function onChangeTab(_, newTab) {
    const ticketingSectionPathname = history.location.pathname.split("/");
    ticketingSectionPathname.pop();
    ticketingSectionPathname.push(tabs[newTab].url[0]);
    history.push(`${ticketingSectionPathname.join("/")}`);
  }

  async function onMassUpdate() {
    setResultOpen(true);

    const ticketingSectionPathname = history.location.pathname.split("/");
    const [nuUrl] = ticketingSectionPathname.pop().split("-");
    history.push(`${[...ticketingSectionPathname, nuUrl].join("/")}`);
  }

  function goBackToPrecinct() {
    const ticketingSectionPathname = history.location.pathname.split("/").slice(0, 5);
    history.push(`${ticketingSectionPathname.join("/")}`);
  }

  function goToRulesSection() {
    const ticketingSectionPathname = history.location.pathname.split("/").slice(0, 5);
    history.push([...ticketingSectionPathname, "calendar-update-rules"].join("/"));
  }

  return (
    <>
      <SectionTitle
        title={`Calendario de ${precinctCode}`}
        buttons={[
          <ChannelSelector
            key={1}
            channelSelected={channel}
            onChange={onChangeChannel}
            {...{ loadingChannels, channels }}
          />,
          <ButtonEdit key={0} size="medium" onClick={goToRulesSection}>
            Editar reglas
          </ButtonEdit>,
        ]}
        backFunction={goBackToPrecinct}
      />
      <TravCard>
        <TravBodyCard>
          {!loadingChannels && channels.length === 0 ? (
            <FxAlert variant={"info"}>Selecciona un canal.</FxAlert>
          ) : (
            <>
              <Box sx={{ borderBottom: 1 }} style={{ marginBottom: "30px" }}>
                <Tabs
                  value={tab}
                  onChange={onChangeTab}
                  aria-label="tabsCalendarSection"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {tabs.map((t, index) => (
                    <Tab key={`tab${t.value}`} label={t.label} value={index} icon={t.icon} />
                  ))}
                </Tabs>
              </Box>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {tab === 0 && (
                    <>
                      {url === "products" && (
                        <Calendar propsLoading={loadingChannels} {...{ precinctCode, channel }} />
                      )}

                      {url === "products-update" && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <ButtonNavegation
                              size={"small"}
                              direction={"left"}
                              label={"Volver al calendario"}
                              onClick={() => onChangeTab("", 0)}
                            />
                          </div>
                          <CalendarCSV {...{ precinctCode, channel }} onUpdate={onMassUpdate} />
                          <CalendarMassUpdate {...{ channel, precinctCode }} onUpdate={onMassUpdate} />
                        </>
                      )}
                    </>
                  )}

                  {/* Tab Calendar Sessions */}
                  {/* {tab === 1 && (
                    <>
                      {url === "sessions" && (
                        <CalendarSessions propsLoading={loadingChannels} {...{ precinctCode, channel }} />
                      )}

                      {url === "sessions-update" && (
                        <>
                          <ButtonNavegation
                            size="small"
                            direction={"left"}
                            label={"Volver al calendario"}
                            onClick={() => onChangeTab("", 0)}
                          />
                          <CalendarSessionCSV {...{ precinctCode, channel }} onUpdate={onMassUpdate} />
                          <CalendarTicketSessionCSV {...{ precinctCode, channel }} onUpdate={onMassUpdate} />
                          <CalendarSessionsMassUpdate
                            {...{ channel, precinctCode }}
                            onUpdate={onMassUpdate}
                          />
                        </>
                      )}
                    </>
                  )} */}

                  {/* Tab Configurations */}
                  {tab === 1 && !loadingChannels && (
                    <TravCard>
                      <TravBodyCard>
                        <CalendarUpdateConfiguration
                          channel={channel}
                          precinctCode={precinctCode}
                          onUpdate={onMassUpdate}
                        />
                      </TravBodyCard>
                    </TravCard>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </TravBodyCard>
      </TravCard>

      <AlertSnackbar
        show={resultOpen}
        text={`Actualizados calendarios`}
        setOpen={setResultOpen}
        severity={"success"}
      />
    </>
  );
}

export default CalendarSection;
