import axios from "axios";

const EP_TRAVEL_LOCATIONS = `/api/v1/product-bc/vacation/travel-location`;
const EP_TRAVEL_NODES = `/api/v1/product-bc/vacation/travel-zone`;
const EP_NODES = `/api/v1/product-bc/vacation/node`;

export const getAllVacationalPackageDestination = async () => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${EP_TRAVEL_LOCATIONS}`);
      if (response.status === 200) {
        return { loadingDestinations: false, destinations: response.data.data };
      }
      return { loadingDestinations: false, destinations: [] };
    } catch (error) {
      console.error(error);
      return { loadingDestinations: false, destinations: [] };
    }
  };
  const theStateFinal = await fetchData({ loadingDestinations: false, destinations: [] });
  return theStateFinal;
};

export const getAllVacationalPackageDNodes = async (tld) => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${EP_NODES}?enable=true&type=packagevp&tld=${tld}`);
      if (response.status === 200) {
        return { loadingNodes: false, nodes: response.data.data };
      }
      return { loadingNodes: false, nodes: [] };
    } catch (error) {
      console.error(error);
      return { loadingNodes: false, nodes: [] };
    }
  };
  const theStateFinal = await fetchData({ loadingNodes: false, nodes: [] });
  return theStateFinal;
};
