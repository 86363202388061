import React from "react";
import PropTypes from "prop-types";
import ButtonDownload from "@atoms/Buttons/ButtonDownload";
import useCSVManagement from "@lib/hooks/useCSVManagement";

function ButtonDownloadCSV({
  wordcontrol,
  head,
  specs,
  table,
  filename,
  buttonLabel,
  info,
  generateTable,
  onDownloadEnded,
  ...props
}) {
  const { loading, onClickDownloadButton } = useCSVManagement({
    wordcontrol,
    head,
    specs,
    table,
    filename,
    info,
    generateTable,
    onDownloadEnded,
  });

  return (
    <ButtonDownload {...props} size="medium" onClick={onClickDownloadButton} disabled={loading}>
      {buttonLabel && buttonLabel}
    </ButtonDownload>
  );
}

ButtonDownloadCSV.propTypes = {
  wordcontrol: PropTypes.string,
  head: PropTypes.array.isRequired,
  specs: PropTypes.object.isRequired,
  table: PropTypes.array,
  filename: PropTypes.string,
  buttonLabel: PropTypes.string,
  info: PropTypes.array,
  generateTable: PropTypes.func,
};

ButtonDownloadCSV.defaultProps = {
  table: [],
  info: [],
  onDownloadEnded: () => {},
};

export default ButtonDownloadCSV;
