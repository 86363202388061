/* eslint-disable no-use-before-define */
import { spanishDateToDateString } from "@lib/helpers/dates";
import { fetchCalendarByType } from "../shared/daoCalendars";
import checkExistence from "./checkExistence";
import saveCalendars from "../shared/saveCalendars";
import { priceInputData, emptyDatabaseCalendar, getTypeOfCsvRow } from "../utils";
// import * as csvd from "../__test__/calendarCSV_NEW_unafila.json";
// import * as csvd from "../__test__/calendarCSV_NEW.json";

async function csvCalendarUploader(csvToUpload, wordcontrol) {
  const [[, wc], head, ...csv] = csvToUpload;

  if (wc !== wordcontrol) {
    throw new Error(`Palabra de control "${wc}" incorrecta. Valida: ${wordcontrol}`);
  }

  // Check if all the required items to upload exist in DB
  const csvWithID = csv.map((row) => initilizeCalendarObjectWithCSVRow(row).identificationData);
  const nonexistent = await checkExistence(csvWithID);
  if (nonexistent.length > 0) {
    throw new Error(["Los siguientes codigos no han sido encontrados:", ...nonexistent].join("\n"));
  }

  const calendarRaw = getCalendarFromCsv(head, csv);
  const listToSave = await createListToSave(calendarRaw);

  const resultSaving = await saveCalendars(listToSave);
  return resultSaving;
}

function getCalendarFromCsv(head, csv) {
  const kvFieldName = priceInputData.reduce((a, p) => ({ ...a, [p.label]: p }), {});
  const dateList = head.slice(7).map((d) => spanishDateToDateString(d));

  const calendarRaw = {};
  csv.forEach((csvRow) => {
    const id = csvRow.slice(0, 5).join("#");
    const nameField = csvRow[6].trim();
    // console.log(id, field);

    if (!kvFieldName[nameField]) {
      throw new Error(`Campo "${nameField}" no permitido`);
    }
    const { code: field, parent } = kvFieldName[nameField];

    if (calendarRaw[id] === undefined) {
      calendarRaw[id] = initilizeCalendarObjectWithCSVRow(csvRow);
    }

    const dataForDays = csvRow.slice(7);
    const dataByDays = Object.fromEntries(dateList.map((_, i) => [dateList[i], dataForDays[i]]));
    calendarRaw[id].calendarByYear = Object.keys(dataByDays).reduce((byYear, date) => {
      const ndate = new Date(date);
      const year = ndate.getFullYear();

      const nuByYear = { ...byYear };

      if (nuByYear[year] === undefined) {
        nuByYear[year] = {};
      }
      if (nuByYear[year][date] === undefined) {
        nuByYear[year][date] = { date };
      }

      if (dataByDays[date] !== "") {
        const valueCSVFormatted = formatCSVCellToCalendar(dataByDays[date], field);

        if (parent) {
          if (nuByYear[year][date][parent] === undefined) {
            nuByYear[year][date][parent] = {};
          }
          nuByYear[year][date][parent][field] = valueCSVFormatted;
        } else {
          nuByYear[year][date][field] = valueCSVFormatted;
        }
      }

      return nuByYear;
    }, calendarRaw[id].calendarByYear);
  });

  const calendarByType = {};
  Object.keys(calendarRaw).forEach((id) => {
    const { type, code } = getTypeOfCsvRow(calendarRaw[id].identificationData);
    const thisCode = calendarRaw[id].identificationData[code];

    Object.keys(calendarRaw[id].calendarByYear).forEach((year) => {
      const k = [type, year, thisCode].join("_");
      if (calendarByType[k] === undefined) {
        calendarByType[k] = emptyDatabaseCalendar({
          type,
          ...calendarRaw[id].identificationData,
          year,
        });
      }

      calendarByType[k].calendar = Object.values(calendarRaw[id].calendarByYear[year]);
    });
  });

  return calendarByType;
}

function initilizeCalendarObjectWithCSVRow(csvRow) {
  const [provider, merchant, precinctCode, productCode, ticketId] = csvRow;
  const identificationData = { precinctCode };

  if (provider !== "") {
    identificationData.channel = { provider };
    if (merchant !== "") {
      identificationData.channel.merchant = merchant;
    }
  }

  if (productCode !== "") {
    identificationData.productCode = productCode;
  }
  if (ticketId !== "") {
    identificationData.ticketId = ticketId;
  }

  return { identificationData, calendarByYear: {} };
}

function formatCSVCellToCalendar(valueFromCSV, field) {
  const valueNoComma = valueFromCSV.replace(",", ".");

  if (field === "enable") {
    return valueNoComma === "1";
  }

  if (["cost", "sellingPrice"].includes(field)) {
    return Math.round(valueNoComma * 100);
  }
  return Math.round(valueNoComma * 100) / 100;
}

async function createListToSave(calendarRaw) {
  const keys = Object.keys(calendarRaw);
  const listToSave = [];
  for (let indexT = 0; indexT < keys.length; indexT += 1) {
    const key = keys[indexT];
    const [type, stringYear, itemId] = keys[indexT].split("_");
    const { channel, calendar } = calendarRaw[key];
    const year = parseInt(stringYear, 10);

    // Get the current calendar...
    let currentThisYearCalendar = {};
    const databaseYearCalendar = await fetchCalendarByType(type, itemId, channel || {}, year);
    if (Object.keys(databaseYearCalendar).length > 0) {
      currentThisYearCalendar = { ...databaseYearCalendar };
    } else {
      // If does not exists, create an empty one
      const { precinctCode, productCode, ticketId } = calendarRaw[key];
      const dataForEmpty = { type, precinctCode, productCode, ticketId, year, channel };
      currentThisYearCalendar = emptyDatabaseCalendar(dataForEmpty);
    }

    const fusedCalendarEntry = {
      ...currentThisYearCalendar,
      calendar: Object.values(
        mergeCurrentCalendarWithCsvCalendar(currentThisYearCalendar.calendar, calendar)
      ),
    };
    listToSave.push({ type, calendarEntry: fusedCalendarEntry });
  }

  return listToSave;
}

function mergeCurrentCalendarWithCsvCalendar(currentCalendar, csvCalendar) {
  const kvCurrentCalendar = currentCalendar.reduce((a, dt) => ({ ...a, [dt.date]: dt }), {});
  csvCalendar.forEach((nuDayFromCSV) => {
    const nuDateFromCSV = nuDayFromCSV.date;

    // Date from csv does not exist in current calendar?
    if (kvCurrentCalendar[nuDateFromCSV] === undefined) {
      kvCurrentCalendar[nuDateFromCSV] = { date: nuDateFromCSV, enable: false };
    }

    // Substitution of the fields, one by one
    kvCurrentCalendar[nuDateFromCSV] = substitutionOfDayData(kvCurrentCalendar[nuDateFromCSV], nuDayFromCSV);
  });

  return kvCurrentCalendar;
}

// Iterate the new fields from csv in order to add to current
function substitutionOfDayData(currentCalendarDay, nuDay) {
  const fusedCalendarDay = { ...currentCalendarDay };
  Object.keys(nuDay).forEach((fieldInCsv) => {
    const newFieldValue = nuDay[fieldInCsv];

    if (typeof nuDay[fieldInCsv] === "object") {
      fusedCalendarDay[fieldInCsv] = substitutionOfDayData(fusedCalendarDay[fieldInCsv], nuDay[fieldInCsv]);
    } else if (newFieldValue !== "") {
      fusedCalendarDay[fieldInCsv] = newFieldValue;
    }
  });

  if (fusedCalendarDay.price && Object.keys(fusedCalendarDay.price).length === 0) {
    delete fusedCalendarDay.price;
  }

  return fusedCalendarDay;
}

export default csvCalendarUploader;
