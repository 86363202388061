class XmlProcessorStatsAndErrors {
  stats = {};
  errors = [];
  constructor() {
    this.stats = {
      ignoradas: {
        A: 0, //"caso tipoDesglose.DesgloseFactura.NoSujeta.ImportePorArticulos7_14_Otros"
        B: 0, //"caso PrestacionServicios.NoSujeta.ImportePorArticulos7_14_Otros"
        C: 0,
      },
      modifiedWithoutTaxex: {
        A: 0, //"caso tipoDesglose.DesgloseFactura.Sujeta.Exenta.DetalleExenta.BaseImponible"
        B: 0, //"caso PrestacionServicios.Sujeta.Exenta.DetalleExenta"
      },
      modified: {
        A: 0,
        B: 0,
      },
    };
  }

  getSummaryMessages() {
    return [
      `Xml procesado correctamente`,
      `Facturas modificadas con impuestos: ${this.stats.modified.A + this.stats.modified.B}`,
      `Facturas modificadas sin impuestos: ${
        this.stats.modifiedWithoutTaxex.A + this.stats.modifiedWithoutTaxex.B
      }`,
      `Facturas ignoradas: ${this.stats.ignoradas.A + this.stats.ignoradas.B}`,
    ];
  }
  ignored(type) {
    this.stats.ignoradas[type]++;
  }
  modifiedWithoutTaxex(type) {
    this.stats.modifiedWithoutTaxex[type]++;
  }
  modified(type) {
    this.stats.modified[type]++;
  }
  addError(error) {
    this.errors.push(error);
  }
  hasErrors() {
    return this.errors.length > 0;
  }
  getErrors() {
    return this.errors;
  }
}

export default XmlProcessorStatsAndErrors;
