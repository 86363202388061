import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

export const ButtonTP = styled(Button)`
  min-width: 36px !important;
  min-height: 60px !important;
  margin-bottom: 5px;
`;

export const AlertNoAccommodation = styled(Alert)`
  margin-bottom: 0 !important;
  color: #0d3c61;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
`;
