import React from "react";
import Concilliation from "@organisms/Operations/Concilliation";

export const ConcilliationAccommodationsPage = () => {
  return <Concilliation target={"accommodations"} titleName={"Alojamientos"} />;
};

export const ConcilliationTicketsPage = () => {
  return <Concilliation target={"services"} titleName={"Tickets"} />;
};

export const ConcilliationAccommodationsPageV2 = () => {
  return <Concilliation target={"accommodations"} titleName={"Alojamientos"} useV2={true} />;
};

export const ConcilliationTicketsPageV2 = () => {
  return <Concilliation target={"services"} titleName={"Tickets"} useV2={true} />;
};
