import axios from "axios";
import { useState, useEffect } from "react";
const urlCookiesGroup = "/api/v1/product-bc/content/cookies-group";
const urlCookies = "/api/v1/product-bc/content/cookies";

export const useCookiesGroup = async () => {
  let cookiesGroupState = { loadingList: true, list: [] };
  const fetchData = async (cookiesGroupState) => {
    try {
      let thestate = { loadingList: true, list: [] };
      const response = await axios.get(`${urlCookiesGroup}`);
      if (response.status === 200) {
        thestate = { loadingList: false, list: response.data.data };
      }
      cookiesGroupState = { ...thestate };
    } catch (error) {
      cookiesGroupState = { loadingList: false, list: [] };
      throw error;
    }
    return cookiesGroupState;
  };
  if (cookiesGroupState.loadingList) {
    return (cookiesGroupState = fetchData(cookiesGroupState));
  }
  return cookiesGroupState;
};

export const putCookieGroup = async (cookieGroup) => {
  let response = {};
  try {
    response = await axios.put(`${urlCookiesGroup}/${cookieGroup.code}`, cookieGroup);
    response.ok = true;
  } catch (error) {
    response.ok = false;
    response.error = error.message;
  }
  return response;
};

export const deleteCookieGroup = async (cookieGroup) => {
  let response = {};
  try {
    response = await axios.delete(`${urlCookiesGroup}/${cookieGroup.code}`);
    response.ok = true;
  } catch (error) {
    response.ok = false;
    response.error = error.message;
  }

  return response;
};

export const putCookie = async (cookie) => {
  let response = {};
  try {
    response = await axios.put(`${urlCookies}/${cookie.id}`, cookie);
    response.ok = true;
  } catch (error) {
    response.ok = false;
    response.error = error.message;
  }
  return response;
};

export const deleteCookie = async (cookie) => {
  let response = {};
  try {
    response = await axios.delete(`${urlCookies}/${cookie.id}`);
    response.ok = true;
  } catch (error) {
    response.ok = false;
    respoonse.error = error.message;
  }
  return response;
};
