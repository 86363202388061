import React, { Fragment } from "react";
import { Tabs, Tab } from "react-bootstrap";

//Components & hooks
import CardTitle from "@atoms/Titles/CardTitle";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import DoblementeApiSearchInterface from "@organisms/Product/Ticketing/DoblementeApi/DoblementeApiSearchInterface";
import DoblementeApiTicketList from "@organisms/Product/Ticketing/DoblementeApi/DoblementeApiTicketList";
import { useTicketsInfo } from "@organisms/Product/Ticketing/DoblementeApi/useDoblementeAPI";

//Styles
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

const NaturlandiaApiPage = () => {
  const { loading, tickets } = useTicketsInfo("NATURLANDIA");

  return (
    <ContainerFenix>
      {loading ? (
        <CenterLoaderCircle />
      ) : (
        <Fragment>
          <CardTitle title={"Buscador API Naturlandia"} buttons={[]} />

          <Tabs defaultActiveKey="search">
            <Tab eventKey="search" title="Buscador de entradas">
              <DoblementeApiSearchInterface provider={"NATURLANDIA"} />
            </Tab>
            <Tab eventKey="tickets" title="Todas las entradas">
              <DoblementeApiTicketList tickets={tickets} />
            </Tab>
          </Tabs>
        </Fragment>
      )}
    </ContainerFenix>
  );
};

export default NaturlandiaApiPage;
