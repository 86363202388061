import React, { useEffect, useState } from "react";
//Material
import Grid from "@material-ui/core/Grid";
//Boostrap
import Modal from "react-bootstrap/Modal";
//Lib
import { useTranslation } from "@lib/contexts/i18Traventia/i18TraventiaContext";
import { userAuthorized } from "../../../../../entries/fenix/auth/FenixAuthContext";
//Icons
import { AiOutlineInstagram, AiFillFacebook, AiFillPicture } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
//Hooks
import createShortUrl from "../../../hooks/useShortUrlFetch";
import getBasePrice from "../../../hooks/useBasePriceOfferFetch";
import { getCardImg, publishPost } from "../../../hooks/useSocialNetworkFetch";
import createStoryImage from "../../../hooks/useCreateStoryImage";

//Previews
import PreviewDefault from "./Previews/PreviewDefault";
import PreviewFacebook from "./Previews/PreviewFacebook";
import PreviewTelegram from "./Previews/PreviewTelegram";
import PreviewInstagram from "./Previews/PreviewInstagram";
//Traventia Componentes
import FxChip from "@atoms/Content/FxChip";
import FxSwitch from "@atoms/Switches/FxSwitch";
import ErrorModal from "@atoms/Modals/ErrorModal";
import AcceptModal from "@atoms/Modals/AcceptModal";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import FxDateTimePicker from "@atoms/Pickers/FxDateTimePicker";
import ConfirmationModal from "@atoms/Modals/ConfirmationModal";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import HtmlTextEditor from "@atoms/Inputs/HtmlTextEditor/HtmlTextEditor";
import CustomModal from "@molecules/Modal/CustomModal";
import { TravCard, TravHeaderCard, TravBodyCard } from "@atoms/Card/FxCard";
import { getOfferAsync } from "../../../hooks/useOfferFetch";
import { useSocialNetworkPost } from "../../../hooks/useSocialNetworkFetch";

function SocialNetwork({ form, onChange, offerCode }) {
  // Las traducciones van en base al idioma de la oferta
  const { t } = useTranslation("offers");
  const user = userAuthorized();
  const [modal, setModal] = useState({ open: false });
  const [socialNetwork, setSocialNetwork] = useState("");
  const [cardImg, setCardImg] = useState("");
  const [loadingCardImg, setLoadingCardImg] = useState(false);
  const [loadingStoryImg, setLoadingStoryImg] = useState(false);
  const [disableButtonPublish, setDisableButtonPublish] = useState(false);
  const { loadingSocialNetworkPost: loadingPosts, socialNetworkPost: posts } =
    useSocialNetworkPost(offerCode);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [errorModal, setErrorModal] = useState({ show: false, messages: [] });
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    text: "",
    body: "",
    onConfirm: () => {},
    onDenied: () => {},
  });
  const [acceptDialog, setAcceptDialog] = useState({
    open: false,
    text: "",
    body: "",
    onAccept: () => {},
  });
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorSubTitle, setErrorSubTitle] = useState(false);
  const [errorTipTitle, setErrorTipTitle] = useState(null);
  const [errorTipSubTitle, setErrorTipSubTitle] = useState(null);
  const [scheduled, setScheduled] = useState(false);
  const [scheduledPublishDate, setScheduledPublishDate] = useState(null);
  const [lastShortUrl, setLastShortUrl] = useState("%%URL_CORTA%%");
  const [openStoryModal, setOpenStoryModal] = useState(false);

  const checkHTML = (html) => {
    let htmlString = `<div>${html}</div>`;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "application/xml");
    return doc.querySelector("parsererror");
  };

  const icons = {
    facebook: <AiFillFacebook />,
    instagram: <AiOutlineInstagram />,
    telegram: <FaTelegram />,
  };

  useEffect(() => {
    if (alertOpen) {
      setAlertOpen(false);
      setAlertData("");
    }
  }, [disableButtonPublish]);

  useEffect(() => {
    if (loadingCardImg) {
      setDisableButtonPublish(true);
    }
  }, [loadingCardImg]);

  const getSocialnetworkName = (sc) => {
    let name = sc.toLowerCase();
    name = name.split("");
    name[0] = name[0].toUpperCase();
    name = name.join("");
    return name;
  };

  const getSocialnetworkColor = (sc) => {
    switch (sc) {
      case "FACEBOOK":
        return { color: "#3b5998", colorText: "#fff" };
      case "INSTAGRAM":
        return { color: "#E1306C", colorText: "#fff" };
      case "TELEGRAM":
        return { color: "#1D98DC", colorText: "#fff" };
      default:
        return { color: "#c9f7f5", colorText: "#06a29b" };
    }
  };

  const tableHeadSocialnetworks = [
    {
      code: "socialNetwork",
      name: "Red Social",
      type: "customFromRow",
      customFromRow: (rowData) => {
        const type = rowData.rowData.socialNetwork;
        const colorSC = getSocialnetworkColor(type);
        const name = getSocialnetworkName(type);
        return (
          <FxChip
            icon={icons[rowData.rowData.socialNetwork.toLowerCase()]}
            style={{ maxWidth: "130px" }}
            label={name}
            colour={colorSC.color}
            colourtext={colorSC.colorText}
            size="small"
          />
        );
      },
    },
    { code: "date", name: "Fecha", type: "string" },
    { code: "publishedBy", name: "Publicado por", type: "string" },
    {
      code: "published",
      name: "Publicado",
      type: "boolean",
      text: { y: "Si", n: "No" },
      filterable: false,
    },
  ];

  const constCleanHtml = (cadena) => {
    return cadena.replace(/<[^>]*>?/gm, "");
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const createAutomaticHeaderText = (price) => {
    const priceText = `${t("offers_socialnetwork_header_price_text").replace("%%PRICE%%", price)}`;
    const startDate = new Date(form.offerCriteria.dates[0].startDate);
    const startDateText =
      startDate >= new Date() ? startDate.toLocaleDateString() : new Date().toLocaleDateString();
    const endDate = new Date(
      form.offerCriteria.dates[form.offerCriteria.dates.length - 1].endDate
    ).toLocaleDateString();
    const travelCalendarText = `${t("offers_socialnetwork_header_travel_date_text")
      .replace("%%FROM%%", startDateText)
      .replace("%%TO%%", endDate)}`;
    const urlText = `${t("offers_socialnetwork_url_text")}`;
    if (form.flash.enable) {
      const flashDate = new Date(form.flash.datetime).toLocaleDateString();
      const flashOfferText = `${t("offers_socialnetwork_header_flash_date_text").replace(
        "%%FLASHDATE%%",
        flashDate
      )}`;
      return `${priceText}\n${urlText}\n${flashOfferText}\n${travelCalendarText}`;
    }
    return `${priceText}\n${urlText}\n${travelCalendarText}`;
  };

  const automaticText = (basePrice) => {
    return `${constCleanHtml(form?.socialNetwork?.title)}\n${constCleanHtml(form?.socialNetwork?.subtitle)}\n
${createAutomaticHeaderText(basePrice)}\n
${constCleanHtml(form?.content?.includes?.map((item) => item).join("\n"))}
\n%%FOOTER_RED_SOCIAL%%\n`;
  };

  const returnUrlCorta = async () => {
    const nuForm = { ...form };
    nuForm.socialNetwork.text = nuForm.socialNetwork.text.replaceAll(lastShortUrl, "%%URL_CORTA%%");
    onChange(nuForm);
  };

  const generateAutomaticText = async () => {
    const nuForm = { ...form };
    const basePrice = await getBasePrice(nuForm.idOffer);
    nuForm.socialNetwork.title = `<h2>${form.title}</h2>`;
    nuForm.socialNetwork.text = automaticText(basePrice, socialNetwork);
    onChange(nuForm);
  };

  const executePublish = async () => {
    setDisableButtonPublish(true);
    setLoadingPublish(true);
    const data = {
      idOffer: form.idOffer,
      socialnetwork: socialNetwork.toUpperCase(),
      publishedBy: user.profile.email,
      url: cardImg,
      market: form.market,
      text: form.socialNetwork.text,
      schedulePublishDate: scheduledPublishDate === null ? null : scheduledPublishDate,
      schedulePublishTime: scheduledPublishDate === null ? null : new Date(scheduledPublishDate).getTime(),
    };
    if (!scheduled) {
      data.schedulePublishDate = null;
    }
    if (!scheduled) {
      data.schedulePublishTime = null;
    }
    try {
      const response = await publishPost(data);
    } catch (error) {
      let { message } = error;
      if (error.response && error.response.data && error.response.data.error) {
        message = error.response.data.error.replace(/\\/g, "");
      }
      setDisableButtonPublish(false);
      setModal({ open: false });
      setErrorModal({
        show: true,
        messages: [`Error al publicar en la red social. Error: ${message}`],
      });
    }
    setDisableButtonPublish(false);
    setLoadingPublish(false);
    setModal({ open: false });
    setAlertData(`Se ha realizado la publicación en ${socialNetwork}`);
    setAlertOpen(true);
    setScheduled(false);
    setScheduledPublishDate(null);
  };

  const isPublishedInSocialNetwork = (socialNetwork) => {
    return posts.some((item) => item.socialNetwork.toUpperCase() === socialNetwork.toUpperCase());
  };

  const publish = async () => {
    setConfirmationDialog({
      open: true,
      text: `Advertencia`,
      body: isPublishedInSocialNetwork(socialNetwork)
        ? `Esta oferta ya ha sido publicada en ${socialNetwork}. \n ¿Estás seguro de querer publicarla de nuevo?`
        : "¿Estás seguro de querer publicar esta oferta?",
      onConfirm: () => {
        executePublish();
      },
      onDenied: () => {
        setModal({ open: false });
      },
    });
  };

  const isSaved = async () => {
    const isOfferSaved = async () => {
      const offer_1 = await getOfferAsync(offerCode);
      if (JSON.stringify(form) === JSON.stringify(offer_1)) {
        return true;
      }
      return false;
    };

    return await isOfferSaved(offerCode);
  };

  const handleModalOfferSaved = async (type) => {
    const saved = await isSaved();
    setAcceptDialog({
      open: !saved,
      text: `Advertencia`,
      body: `Es necesario guardar para poder previsualizar los últimos cambios.`,
      onAccept: () => {
        setModal({ open: false });
      },
    });
  };

  const handleOpenModal = async (type) => {
    const saved = await isSaved();
    if (saved) {
      handleOpenModalExecute(type);
    }
    handleModalOfferSaved(type);
  };

  const handleOpenModalExecute = async (type) => {
    setSocialNetwork(type);
    setLoadingCardImg(true);
    setModal({ open: true });
    let url = "";
    try {
      url = await getCardImg(form.idOffer);
      const optionsUtm = {
        utmMedium: "social",
        utmlSource: type,
        utmCampaign: form.idOffer,
        utmContent: form.url,
      };
      const shortUrl = await createShortUrl(form.url, form.market, optionsUtm);
      const socialNetworkOptionsfooterText = {
        facebook: t("offers_socialnetwork_facebook_footer_text")
          .replace("%%SHORT_URL%%", "%%URL_CORTA%%")
          .replaceAll("\\n", "\n"),
        instagram: t("offers_socialnetwork_instagram_footer_text")
          .replace("%%SHORT_URL%%", "%%URL_CORTA%%")
          .replaceAll("\\n", "\n"),
        telegram: t("offers_socialnetwork_telegram_footer_text")
          .replace("%%SHORT_URL%%", "%%URL_CORTA%%")
          .replaceAll("\\n", "\n"),
      };
      form.socialNetwork.text = form.socialNetwork.text.replaceAll(
        "%%FOOTER_RED_SOCIAL%%",
        socialNetworkOptionsfooterText[type]
      );
      form.socialNetwork.text = form.socialNetwork.text.replaceAll("%%URL_CORTA%%", shortUrl.data.short);
      form.socialNetwork.text = form.socialNetwork.text.replaceAll(lastShortUrl, shortUrl.data.short);
      setLastShortUrl(shortUrl.data.short);
    } catch (error) {
      setModal({ open: false });
      setErrorModal({
        show: true,
        messages: ["Error al generar la imagen, esta oferta no tiene calendario"],
      });
    }
    setCardImg(url);
    setLoadingCardImg(false);
    setDisableButtonPublish(false);
  };

  const textFormatedForPreview = (text) => {
    return text.split("\n").map((cv) => <p>{cv}</p>);
  };

  const downloadImage = async () => {
    setOpenStoryModal(true);
    await createStoryImage(form.idOffer, setLoadingStoryImg);
    setLoadingStoryImg(false);
    setOpenStoryModal(false);
  };

  const onCloseModal = () => {
    setOpenStoryModal(false);
  };

  return (
    <>
      <TravCard>
        <TravHeaderCard>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <h5>Redes Sociales</h5>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                  <ButtonCustom
                    key={"btFacebook"}
                    customcolor="facebook"
                    icon={<AiFillFacebook />}
                    variant={"outlined"}
                    onClick={() => {
                      handleOpenModal("facebook");
                    }}
                  >
                    Facebook
                  </ButtonCustom>
                </Grid>
                <Grid item>
                  <ButtonCustom
                    key={"btInstagram"}
                    customcolor="instagram"
                    variant={"outlined"}
                    icon={<AiOutlineInstagram />}
                    onClick={() => {
                      handleOpenModal("instagram");
                    }}
                  >
                    Instagram
                  </ButtonCustom>
                </Grid>
                <Grid item>
                  {loadingStoryImg ? (
                    <>
                      <CustomModal
                        loading={loadingStoryImg}
                        title={"Obteniendo Story Instagram"}
                        open={openStoryModal}
                        handleClose={onCloseModal}
                        bigModal={true}
                      ></CustomModal>
                    </>
                  ) : (
                    <>
                      <ButtonCustom
                        key={"btInstagram"}
                        customcolor="instagram"
                        icon={<AiOutlineInstagram />}
                        variant={"outlined"}
                        onClick={() => {
                          downloadImage();
                        }}
                      >
                        Descargar story Instagram
                      </ButtonCustom>
                    </>
                  )}
                </Grid>
                <Grid item>
                  <ButtonCustom
                    key={"btInstagram"}
                    customcolor="telegram"
                    variant={"outlined"}
                    icon={<FaTelegram />}
                    onClick={() => {
                      if (form.socialNetwork.text.length > 1024) {
                        setAcceptDialog({
                          open: true,
                          text: (
                            <>
                              <FaTelegram style={{ fontSize: "25px", color: "#1D98DC" }} />{" "}
                              {"Máximo de carecteres superado"}
                            </>
                          ),
                          body: (
                            <html>
                              <body>
                                <p>
                                  Hemos detectado que el texto ha superado el máximo de caracteres establecido
                                  por <i>Telegram</i>, que es <b>1024 caracteres</b>, se procederá a acortar
                                  el texto en la publicación.
                                </p>
                                <p>
                                  Actualmente el texto de la publicación contiene un total de&nbsp;
                                  <b>{form.socialNetwork.text.length} caracteres</b>.`
                                </p>
                              </body>
                            </html>
                          ),
                          onAccept: () => {
                            handleOpenModal("telegram");
                          },
                        });
                      } else {
                        handleOpenModal("telegram");
                      }
                    }}
                  >
                    Telegram
                  </ButtonCustom>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TravHeaderCard>
        <TravBodyCard>
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={4}>
            <Grid item xs={6}>
              <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                <Grid item xs={12}>
                  <h6>
                    <p className={errorTitle ? "text-danger" : ""}>
                      Título * <span style={{ color: "silver" }}>(admite código html)</span>
                    </p>
                  </h6>
                  <HtmlTextEditor
                    id="title"
                    value={form?.socialNetwork?.title ?? ""}
                    onChange={(nVal) => {
                      const nuForm = { ...form };
                      nuForm.socialNetwork.title = nVal;
                      onChange(nuForm);
                      const checkVal = checkHTML(nVal);
                      setErrorTitle(checkVal !== null);
                      if (checkVal !== null) {
                        setErrorTipTitle(checkVal.children);
                      } else {
                        setErrorTipTitle(null);
                      }
                    }}
                    placeholder={"Escribe aquí el título."}
                    valid={!errorTitle}
                    width={"500px"}
                    height={200}
                  />
                  {errorTitle === true && <span className="text-danger">Con errores</span>}
                  {errorTitle === true && errorTipTitle !== null && (
                    <p className="text-danger">{errorTipTitle.item(1).textContent}</p>
                  )}
                  <br />
                  <h6>
                    <p className={errorTitle ? "text-danger" : ""}>
                      Subtítulo * <span style={{ color: "silver" }}>(admite código html)</span>
                    </p>
                  </h6>
                  <HtmlTextEditor
                    id="subtitle"
                    value={form?.socialNetwork?.subtitle ?? ""}
                    onChange={(nVal) => {
                      const nuForm = { ...form };
                      nuForm.socialNetwork.subtitle = nVal;
                      onChange(nuForm);
                      const checkVal = checkHTML(nVal);
                      setErrorSubTitle(checkVal !== null);
                      if (checkVal !== null) {
                        setErrorTipSubTitle(checkVal.children);
                      } else {
                        setErrorTipSubTitle(null);
                      }
                    }}
                    placeholder={"Escribe aquí el Subtítulo."}
                    valid={!errorSubTitle}
                    width={"500px"}
                    height={200}
                  />
                  {errorSubTitle === true && <span className="text-danger">Con errores</span>}
                  {errorSubTitle === true && errorTipSubTitle !== null && (
                    <p className="text-danger">{errorTipSubTitle.item(1).textContent}</p>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid item xs={6}>
              <Grid container direction="row" justify="space-between" alignItems="baseline" spacing={2}>
                <Grid item>
                  <h6>
                    <p className={errorTitle ? "text-danger" : ""}>Texto de la publicación *</p>
                  </h6>
                </Grid>
                <Grid item>
                  <ButtonCustom
                    key={"btGenerateText"}
                    color={"secondary"}
                    // variant={"outlined"}
                    onClick={generateAutomaticText}
                  >
                    Generar texto automáticamente
                  </ButtonCustom>
                </Grid>
              </Grid>
              <textarea
                style={{ height: "426px", width: "100%" }}
                name="Texto"
                rows="10"
                cols="50"
                value={form.socialNetwork.text}
                onChange={(nVal) => {
                  const nuForm = { ...form };
                  nuForm.socialNetwork.text = nVal.target.value;
                  onChange(nuForm);
                }}
                placeholder={"Texto de la publicación"}
              ></textarea>
            </Grid>
          </Grid>
        </TravBodyCard>
      </TravCard>
      <AccordionItem title={<div className="card-title h5">{`Ultimas publicaciones`}</div>}>
        <FenixTable
          loading={loadingPosts}
          tableHead={tableHeadSocialnetworks}
          tableContent={posts}
          showInputFilter={true}
          maxNumberPerPages={10}
          pagination={true}
        />
      </AccordionItem>
      <Modal
        show={modal.open}
        onHide={() => {
          returnUrlCorta();
          setModal({ open: false });
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Previsualización {capitalizeFirstLetter(socialNetwork)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              {loadingCardImg ? (
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <CenterLoaderCircle role="status" />
                  </Grid>
                  <Grid item>Generando imagen...</Grid>
                </Grid>
              ) : loadingPublish ? (
                <Grid item xs={12}>
                  <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      <CenterLoaderCircle role="status" />
                    </Grid>
                    <Grid item>Publicando...</Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {socialNetwork === "instagram" && (
                    <PreviewInstagram form={form} cardImg={cardImg} formaterText={textFormatedForPreview} />
                  )}
                  {socialNetwork === "facebook" && (
                    <PreviewFacebook form={form} cardImg={cardImg} formaterText={textFormatedForPreview} />
                  )}
                  {socialNetwork === "telegram" && (
                    <PreviewTelegram form={form} cardImg={cardImg} formaterText={textFormatedForPreview} />
                  )}
                  {socialNetwork !== "instagram" &&
                    socialNetwork !== "facebook" &&
                    socialNetwork !== "telegram" && (
                      <PreviewDefault form={form} cardImg={cardImg} formaterText={textFormatedForPreview} />
                    )}
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <FxSwitch
                value="scheduled"
                labels={["Programar publicación", "Programar publicación"]}
                status={scheduled}
                disabled={disableButtonPublish}
                onChange={() => {
                  setScheduled(!scheduled);
                  setScheduledPublishDate(new Date().toISOString());
                }}
              />
              {scheduled && (
                <FxDateTimePicker
                  disabled={true}
                  label={"Hora de la publicación"}
                  dateInitial={scheduledPublishDate || new Date().toISOString()}
                  onChange={(nuDateTime) => {
                    setScheduledPublishDate(nuDateTime);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <ButtonCustom
                key={"btGreen"}
                customcolor={socialNetwork}
                icon={icons[socialNetwork]}
                variant={"contained"}
                onClick={publish}
                fullWidth={true}
                disabled={disableButtonPublish}
              >
                Publicar en {capitalizeFirstLetter(socialNetwork)}
              </ButtonCustom>
            </Grid>

            <Grid item xs={4}></Grid>
          </Grid>
        </Modal.Body>
      </Modal>
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
      <ErrorModal
        show={errorModal.show}
        listErrors={errorModal.messages}
        onClose={() => setErrorModal({ show: false, messages: [] })}
      />
      <ConfirmationModal
        show={confirmationDialog.open}
        title={confirmationDialog.text}
        body={confirmationDialog.body}
        onConfirm={() => {
          confirmationDialog.onConfirm();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
        onDeny={() => {
          confirmationDialog.onDenied();
          setConfirmationDialog({ ...confirmationDialog, open: false });
        }}
      />
      <AcceptModal
        show={acceptDialog.open}
        title={acceptDialog.text}
        body={acceptDialog.body}
        onAccept={() => {
          acceptDialog.onAccept();
          setAcceptDialog({ ...acceptDialog, open: false });
        }}
      />
    </>
  );
}

export default SocialNetwork;
