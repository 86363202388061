export const wordcontrol = "product_channels_v1";
export const filename = "product_channels";

export const csvHead = [
  "Recinto",
  "Tarifa",
  "Proveedor",
  "Merchant",
  "Heredar configuración del recinto",
  "Heredar reserva",
  "Modo de reserva",
  "Emails reserva",
  "CCO reserva",
  "Añadir teléfono al email",
  "Añadir alojamiento al email",
  "Bono - Heredar configuracion de la reserva",
  "Modo de Bono",
  "Pagadero del Bono",
  "Logo del Bono",
  "Dirección del Bono",
  "Email del Bono",
  "Teléfono del Bono",
  "Cancelacion - Heredar configuracion de la reserva",
  "Modo de Cancelacion",
  "Emails Cancelacion",
  "CCO Cancelacion",
];

export const csvSpecs = {
  precinctCode: "",
  productCode: "",
  channel: { provider: "", merchant: "" },
  configurationInheritedPrecinct: "b",
  booking: {
    inherited: "b",
    mode: "",
    configuration: {
      emails: "sc",
      cco: "sc",
      include: {
        phone: "b",
        accommodation: "b",
      },
    },
  },
  voucher: {
    inherited: "b",
    mode: "",
    configuration: {
      payable: "",
      logo: "",
      address: "",
      email: "",
      phone: "",
    },
  },
  cancellation: {
    inherited: "b",
    mode: "",
    configuration: {
      emails: "sc",
      cco: "sc",
    },
  },
  // rooming:{
  //   enable: "b",
  //   daysToDate: "d",
  //   emails: {
  //     to: "sc",
  //     bcc: "sc",
  //   },
  //   agesGroup: [], // {ageMin: number,ageMax: number,name: string,}
  // }
};
