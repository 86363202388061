import React from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { FiDownloadCloud } from "react-icons/fi";

function ButtonDownload({ size, variant, disabled, onClick, label, children }) {
  return (
    <ButtonCustom
      size={size}
      disabled={disabled}
      variant={variant}
      aria-label="Upload"
      customcolor="lightblue"
      label={label}
      icon={<FiDownloadCloud />}
      onClick={onClick}
    >
      {children}
    </ButtonCustom>
  );
}

export default ButtonDownload;
