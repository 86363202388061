import React, { useState } from "react";
import { Menu, List, ListItem } from "@material-ui/core";
import { FaInfoCircle } from "react-icons/fa";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import CalendarDay from "./CalendarDay";
import { ConstantType } from "./CalendarRow.styles";

function CalendarLegend() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonCustom
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        icon={<FaInfoCircle style={{ color: "#1e92f4" }} />}
        onClick={handleClick}
      />
      <Menu
        // style={{ width: "350px" }}
        {...{ anchorEl, open }}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <span style={{ padding: "10px" }}>
          <b>Leyenda:</b>
        </span>
        <List>
          <ListItem>
            <div style={{ width: "30px" }}>
              <CalendarDay dataForDay={{ enable: true }} calendarLength={1} />
            </div>
            <span>Abierto</span>
          </ListItem>
          <ListItem>
            <div style={{ width: "30px" }}>
              <CalendarDay dataForDay={{ enable: false }} calendarLength={1} />
            </div>
            <span>Cerrado (Recintos/productos)</span>
          </ListItem>
          <ListItem>
            <div style={{ width: "30px" }}>
              <CalendarDay
                dataForDay={{ enable: false, price: { cost: 1400 } }}
                calendarLength={1}
                fieldToShow={"cost"}
                parentEnabled={true}
              />
            </div>
            <span>Cerrado (Entradas)</span>
          </ListItem>
          <ListItem>
            <div style={{ width: "30px" }}>
              <CalendarDay
                dataForDay={{ enable: true, price: { cost: 1400 } }}
                calendarLength={1}
                fieldToShow={"cost"}
                parentEnabled={false}
              />
            </div>
            <span>Abierto pero padre cerrado</span>
          </ListItem>
          <ListItem>
            <div style={{ width: "30px" }}>
              <ConstantType displayPriceModels={"PerOneDay"}></ConstantType>
            </div>
            <span>
              Tarifa <i>PerOneDay</i>: Precio por día aplica unicamente a ese día.
            </span>
          </ListItem>
        </List>
      </Menu>
    </>
  );
}

export default CalendarLegend;
