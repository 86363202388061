import React from "react";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";

const AccommodationsProviderModalInfo = ({ content, tableHeadModal, loading, getDataBySubprovider }) => {

  const getRows = () => {
    const row = { provider: content.row.provider, ...getDataBySubprovider(content.row) };
    delete row.provider_copy;
    if(content.subrows === undefined){
      return [row];
    }
    return [row, ...content.subrows.map((cv) => getDataBySubprovider(cv))];
  };
  return (
    <>
      <Grid container direction="column" spacing={5}>
        <Grid item xs>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={4}>
            <Grid item>
              <TextField label="Proveedor" disabled={true} value={content.row.provider} onChange={() => {}} />
            </Grid>
            <Grid item>
              <TextField
                label="Numero de proveedor"
                disabled={true}
                value={content.row.provNum}
                onChange={() => {}}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FenixTable
              key={"second"}
              tableHead={tableHeadModal}
              tableContent={getRows()}
              loading={loading}
              showInputFilter={false}
            />
        </Grid>
      </Grid>
    </>
  );
};

export default AccommodationsProviderModalInfo;
