import generateSessionId from "../shared/sessionId";
import { saveSession } from "./useSessions";

const saveSessionList = async (fullList) => {
  let saved = 0;
  const errors = [];
  for (let index = 0; index < fullList.length; index += 1) {
    const session = fullList[index];
    try {
      // Generate id if necessary
      if (session.id.trim() === "") {
        session.id = generateSessionId(session.productCode, session.segments);
      }
      await saveSession(session);
      saved += 1;
    } catch (e) {
      errors.push(
        `Error en id ${session.id}: ${e.response.data.errors
          .reduce((acc, cv) => {
            acc.push(cv.error);
            return acc;
          }, [])
          .join(",")}`
      );
    }
  }

  return { saved, errors };
};

export default saveSessionList;
