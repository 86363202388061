import React from "react";
import { UsChipEmail } from "../Content/Chips";
import { FaCross } from "react-icons/fa";
import { Cross } from "./ListEditable.styles";
const ChipList = ({ list, editable, onDeleteElement }) => {
  return (
    <div>
      {list.map((item, index) => (
        <UsChipEmail key={item}>
          <div style={{ padding: "10px" }}>
            {item}
            {editable && (
              <Cross
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => onDeleteElement(index)}
              />
            )}
          </div>
        </UsChipEmail>
      ))}
    </div>
  );
};
export default ChipList;
