/**
 *
 * @param {*} rowData
 * @param {*} interfaceForThisCell
 * @returns
 */
export const getCellContentWithFieldCode = (rowData, interfaceForThisCell, cellContentDefault = "-") => {
  let cellContent = cellContentDefault;
  if (interfaceForThisCell.code.includes(".")) {
    const codes = interfaceForThisCell.code.split(".");
    let dataChain = { ...rowData };
    let complete = true;
    codes.forEach((c) => {
      if (dataChain[c] !== undefined && dataChain[c] !== null) {
        dataChain = dataChain[c];
      } else {
        complete = false;
      }
    });
    if (complete) {
      cellContent = dataChain;
    }
    // } else if (rowData[interfaceForThisCell.code] === null) {
    // if (rowData[interfaceForThisCell.code] !== undefined) {
    //   cellContent = rowData[interfaceForThisCell.code];
    // }
  } else {
    if (rowData[interfaceForThisCell.code] !== undefined) {
      cellContent = rowData[interfaceForThisCell.code];
    }
  }

  if (cellContent === null) {
    cellContent = "";
  }
  return cellContent;
};
