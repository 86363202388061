import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

import BrickListPage from "@organisms/contenido/bricks/BrickList";

function BrickList({ type }) {
  return (
    <ContainerFenix>
      <BrickListPage type={type} />
    </ContainerFenix>
  );
}

export default BrickList;
