import axios from "axios";
export const ACCOUNTING_SUPPLIER_URL = "/api/v1/booking-bc/accounting/suppliers";
export const ACCOUNTING_SUPPLIER_BANK_ACCOUNTS_URL =
  "/api/v1/booking-bc/accounting/supplier-payment-bank-account";

export const useAccountingSuppliersManagement = async (id) => {
  let accountingSupplierData = {
    loading: true,
    accountingSupplier: {},
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${ACCOUNTING_SUPPLIER_URL}/${id}`);
      if (response.status === 200) {
        const accountingSupplier = response.data;
        if (!accountingSupplier.email) accountingSupplier.email = [];
        return { loading: false, accountingSupplier, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return { loading: false, accountingSupplier: {} };
      // throw error;
    }
  };

  accountingSupplierData = await fetchData();
  return { accountingSupplierData };
};

export const useAccountingSupplierSave = async (supplierId, supplierData) => {
  try {
    const response = await axios.put(`${ACCOUNTING_SUPPLIER_URL}/${supplierId}`, supplierData);
    if (response.status === 201) {
      return {
        loading: false,
        accountingSupplier: response.data,
        status: response.status,
        error: false,
        errorText: "",
      };
    }
  } catch (error) {
    return { loading: false, accountingSupplier: {}, error: true, errorText: error.message };
    // throw error;
  }
};

export const useAccountingSuppliersList = async () => {
  let accountingSuppliersData = {
    loadingList: true,
    list: [],
    status: 0,
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(ACCOUNTING_SUPPLIER_URL);
      if (response.status === 200) {
        return { loadingList: false, list: response.data.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return { loadingList: false, list: [] };
      // throw error;
    }
  };
  accountingSuppliersData = await fetchData();
  return { accountingSuppliersData };
};

export const useAccountingSuppliersBankAccountsList = async () => {
  let suppliersBankAccountsListData = {
    loadingList: true,
    list: [],
    status: 0,
  };
  const fetchData = async (supplier) => {
    try {
      const response = await axios.get(ACCOUNTING_SUPPLIER_BANK_ACCOUNTS_URL);
      if (response.status === 200) {
        return { loadingList: false, list: response.data.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return { loadingList: false, list: [] };
      // throw error;
    }
  };
  suppliersBankAccountsListData = await fetchData();
  return { suppliersBankAccountsListData };
};

export const useAccountingSuppliersBankAccountsManagement = async (id) => {
  let supplierBankAccountsData = {
    loading: true,
    accountingSupplier: {},
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${ACCOUNTING_SUPPLIER_BANK_ACCOUNTS_URL}?supplier=${id}`);
      if (response.status === 200) {
        return { loading: false, accountingSupplierBankAccounts: response.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return { loading: false, accountingSupplierBankAccounts: {} };
      // throw error;
    }
  };

  supplierBankAccountsData = await fetchData();
  return { supplierBankAccountsData };
};

export const useAccountingSupplierBankAccountSave = async (id, bankAccountData) => {
  try {
    const response = await axios.post(`${ACCOUNTING_SUPPLIER_BANK_ACCOUNTS_URL}/${id}`, bankAccountData);
    if (response.status === 200) {
      return {
        loading: false,
        accountingSupplierBankAccount: response.data,
        status: response.status,
        error: false,
        errorText: "",
      };
    }
  } catch (error) {
    console.log(error);
    return { loading: false, accountingSupplierBankAccount: {}, error: true, errorText: error.message };
    // throw error;
  }
};
