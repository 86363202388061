import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function AlertSnackbar({ text, show, vertical, horizontal, severity, autoClose, setOpen, duration }) {
  useEffect(() => {
    if (autoClose && show) {
      setTimeout(() => {
        setOpen(false);
      }, duration);
    }
  }, [autoClose, duration, setOpen, show]);

  return (
    <Snackbar open={show} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
      <MuiAlert variant="filled" severity={severity}>
        {text}
      </MuiAlert>
    </Snackbar>
  );
}

AlertSnackbar.propTypes = {
  text: PropTypes.string,
  open: PropTypes.bool,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  severity: PropTypes.string,
  autoClose: PropTypes.bool,
  setOpen: PropTypes.func,
};

AlertSnackbar.defaultProps = {
  vertical: "bottom",
  horizontal: "right",
  severity: "success",
  autoClose: true,
  duration: 2000,
};

export default AlertSnackbar;
