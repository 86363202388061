import React, { useState, useEffect } from "react";
import useVacationalPackageManagement from "../../../hooks/useVacationalPackageManagement";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { AiOutlineSearch } from "react-icons/ai";
import Grid from "@material-ui/core/Grid";
import SectionTitle from "@atoms/Titles/SectionTitle";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FxSwitch from "@atoms/Switches/FxSwitch";
import {
  TitleListServiceFerry,
  ChipNameRoute,
  HeaderScheduleStyle,
  IconExcludeStyle,
  IconExcludeBarStyle,
} from "../OffersBoatComponents/OfferBoat.styles";
import {
  AlertNoAccommodation,
  TitleListService,
  ChipDeleteService,
} from "../DropDownConfi/DropDownConfiServicesIncludedInOffer.styles";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import InputQueryDropdownSearch from "@atoms/Inputs/InputQueryDropdownSearch";
import { Row, Col } from "react-bootstrap";
import MealSelector from "../MealSelector/MealSelector";
import RoomOptionSelector from "../RoomOptionSelector/RoomOptionSelector";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";

const OfferVacationalPackageComponent = ({ form, handle }) => {
  const { destinations, setDestinations, nodes, setNodes } = useVacationalPackageManagement(form.market);
  const [loadingConfiguration, setLoadingConfiguration] = useState(true);
  const [openSearchRoutes, setOpenSearchRoutes] = useState(false);
  const [routesByDestination, setRoutesByDestination] = useState({});
  const [vacationalPackageNewConfigResult, setVacationalPackageNewConfigResult] = useState({
    serviceAccommodation: [],
    destination: "",
    routes: [],
    excludeProviders: [],
    withInsuranceCheck: false,
    withTransferCheck: false,
  });

  useEffect(() => {
    if (form.services.vacationalPackage.length > 0) {
      setVacationalPackageNewConfigResult(form.services.vacationalPackage[0]);
      setOpenSearchRoutes(true);
    }
  }, []);

  useEffect(() => {
    handle([vacationalPackageNewConfigResult]);
  }, [vacationalPackageNewConfigResult]);

  useEffect(() => {
    if (!destinations.loadingDestinations && !nodes.loadingNodes) {
      setLoadingConfiguration(false);
      const routesByDestination = getRoutesByDestination();
      setRoutesByDestination(routesByDestination);
    }
  }, [destinations.loadingDestinations, nodes.loadingNodes]);

  const resetVacationalPackage = () => {
    setVacationalPackageNewConfigResult({
      serviceAccommodation: [],
      destination: "",
      routes: [],
      excludeProviders: [],
      withInsuranceCheck: false,
      withTransferCheck: false,
    });
  };

  const getRoutesByDestination = () => {
    const routesByDestination = nodes.nodes.reduce((acc, node) => {
      if (acc[node.travelZone.destination] === undefined) {
        acc[node.travelZone.destination] = [];
      }
      node.info = destinations.destinations.find((cv) => cv.code === node.travelZone.destination);
      acc[node.travelZone.destination].push(node);
      return acc;
    }, {});
    return routesByDestination;
  };

  const isDestinationSelected = (destinationCode) => {
    return vacationalPackageNewConfigResult.destination === destinationCode;
  };

  const isRoutedSelected = (route) => {
    return vacationalPackageNewConfigResult.routes.includes(route.code);
  };

  const isProviderSelected = (providerCode) => {
    return vacationalPackageNewConfigResult.excludeProviders.includes(providerCode);
  };

  const getAllRouteProviders = () => {
    return routesByDestination[vacationalPackageNewConfigResult.destination].reduce((acc, route) => {
      Object.keys(route.travelZone.providersVP).forEach((keyProvider) => {
        if (!acc.includes(keyProvider)) acc.push(keyProvider);
      });
      return acc;
    }, []);
  };

  return (
    <>
      {loadingConfiguration && (
        <>
          <CenterLoaderCircle role="status" />
        </>
      )}
      {!loadingConfiguration && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputQueryDropdownSearch
              label={"Buscador de alojamientos"}
              getRequestPath={`/api/private/accommodations?query=%%QUERY%%`}
              optionFormat={(option) => {
                return (
                  <div>
                    <b>
                      {option.code} | {option.name}
                    </b>
                  </div>
                );
              }}
              onSelectOption={(nuValue) => {
                const newVP = { ...vacationalPackageNewConfigResult };
                newVP.serviceAccommodation.push({
                  hcode: nuValue.code,
                  name: nuValue.name,
                });
                setVacationalPackageNewConfigResult(newVP);
              }}
            />
            <div>
              <br />
              <TitleListService>Alojamiento seleccionados</TitleListService>
            </div>

            {vacationalPackageNewConfigResult.serviceAccommodation.length === 0 && (
              <AlertNoAccommodation variant="info">Ningun alojamiento añadido</AlertNoAccommodation>
            )}
            {vacationalPackageNewConfigResult.serviceAccommodation.length > 0 && (
              <>
                {vacationalPackageNewConfigResult.serviceAccommodation.map((item, index) => (
                  <div style={{ border: "1px solid #ddd", borderRadius: "15px" }} key={index}>
                    <SectionTitle
                      hx={"h4"}
                      title={`${item.hcode} | ${item.name}`}
                      buttons={[
                        <ChipDeleteService
                          key={"bt1"}
                          onClick={() => {
                            const newVP = { ...vacationalPackageNewConfigResult };
                            newVP.serviceAccommodation.splice(index, 1);
                            setVacationalPackageNewConfigResult(newVP);
                          }}
                        />,
                      ]}
                    />
                    <Row>
                      <Col xs={6}>
                        <MealSelector
                          withCard={false}
                          mealPlans={vacationalPackageNewConfigResult.serviceAccommodation[index].mealPlans}
                          onChange={(mealPlans) => {
                            const newVP = { ...vacationalPackageNewConfigResult };
                            newVP.serviceAccommodation[index].mealPlans = mealPlans;
                            setVacationalPackageNewConfigResult(newVP);
                          }}
                        />
                      </Col>
                      <Col xs={6}>
                        <RoomOptionSelector
                          withCard={false}
                          roomOptions={
                            vacationalPackageNewConfigResult.serviceAccommodation[index].roomOptions
                          }
                          onChange={(roomOptions) => {
                            const newVP = { ...vacationalPackageNewConfigResult };
                            newVP.serviceAccommodation[index].roomOptions = roomOptions;
                            setVacationalPackageNewConfigResult(newVP);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Grid>
          {!openSearchRoutes ? (
            <>
              <Grid item xs={12}>
                <HeaderScheduleStyle
                  style={{
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <h6 style={{ margin: "0", fontWeight: "bold" }}>Seleccionar destino</h6>
                </HeaderScheduleStyle>
              </Grid>

              {Object.keys(routesByDestination).map((destination, index) => {
                const infoDestination = destinations.destinations.find((cv) => cv.code === destination);
                return (
                  <Grid item key={`${destination}-${index}`}>
                    <ButtonCustom
                      variant={isDestinationSelected(destination) ? "contained" : "outlined"}
                      color={isDestinationSelected(destination) ? "primary" : "default"}
                      onClick={() => {
                        const newVP = { ...vacationalPackageNewConfigResult };
                        newVP.destination = destination;
                        newVP.routes =
                          routesByDestination[destination] === undefined
                            ? []
                            : routesByDestination[destination].map((cv) => cv.code);
                        setVacationalPackageNewConfigResult(newVP);
                      }}
                    >
                      {infoDestination?.name?.es ||
                        infoDestination?.name?.pt ||
                        infoDestination?.name?.it ||
                        infoDestination?.name?.fr ||
                        infoDestination?.name}
                    </ButtonCustom>
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                {vacationalPackageNewConfigResult.destination !== "" && (
                  <ButtonCustom
                    icon={<AiOutlineSearch />}
                    customcolor={"lightblue"}
                    onClick={() => {
                      setOpenSearchRoutes(true);
                    }}
                  >
                    {"Buscar rutas "}
                    {
                      destinations.destinations.find(
                        (cv) => cv.code === vacationalPackageNewConfigResult.destination
                      ).name.es
                    }
                  </ButtonCustom>
                )}
              </Grid>
            </>
          ) : (
            <>
              {routesByDestination[vacationalPackageNewConfigResult.destination] !== undefined &&
              routesByDestination[vacationalPackageNewConfigResult.destination].length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <TitleListServiceFerry>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                          Destino seleccionado &nbsp;
                          <ChipNameRoute>
                            {
                              destinations.destinations.find(
                                (cv) => cv.code === vacationalPackageNewConfigResult.destination
                              ).name.es
                            }
                          </ChipNameRoute>
                        </div>
                        <ButtonDelete
                          size={"small"}
                          onClick={() => {
                            resetVacationalPackage();
                            setOpenSearchRoutes(false);
                          }}
                        />
                      </div>
                    </TitleListServiceFerry>
                  </Grid>
                  {routesByDestination[vacationalPackageNewConfigResult.destination].map((route, index) => {
                    return (
                      <Grid item key={`${route.code}-${index}`}>
                        <ButtonCustom
                          variant={isRoutedSelected(route) ? "contained" : "outlined"}
                          color={isRoutedSelected(route) ? "primary" : "default"}
                          onClick={() => {
                            const newVP = { ...vacationalPackageNewConfigResult };
                            const routedSelected = isRoutedSelected(route);
                            if (routedSelected) {
                              newVP.routes = newVP.routes.filter((cv) => cv !== route.code);
                            } else {
                              newVP.routes.push(route.code);
                            }
                            setVacationalPackageNewConfigResult(newVP);
                          }}
                        >
                          {route.code}
                        </ButtonCustom>
                      </Grid>
                    );
                    // }
                  })}
                  {/* Excluir - Limitar proveedores */}
                  <Grid item xs={12}>
                    <Grid container style={{ border: "1px solid #ddd", paddingBottom: "20px" }}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <HeaderScheduleStyle>
                              <h6 style={{ margin: "0", fontWeight: "bold" }}>
                                <IconExcludeStyle>
                                  <IconExcludeBarStyle />
                                </IconExcludeStyle>
                                Excluir proveedores
                              </h6>
                            </HeaderScheduleStyle>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                              {getAllRouteProviders().map((provider, index) => {
                                return (
                                  <Grid item key={`${provider}-${index}`}>
                                    <ButtonCustom
                                      variant={isProviderSelected(provider) ? "contained" : "outlined"}
                                      color={isProviderSelected(provider) ? "primary" : "default"}
                                      onClick={() => {
                                        const newVP = { ...vacationalPackageNewConfigResult };
                                        const isSelected = isProviderSelected(provider);
                                        if (isSelected) {
                                          newVP.excludeProviders = newVP.excludeProviders.filter(
                                            (cv) => cv !== provider
                                          );
                                        } else {
                                          newVP.excludeProviders.push(provider);
                                        }
                                        setVacationalPackageNewConfigResult(newVP);
                                      }}
                                    >
                                      {provider}
                                    </ButtonCustom>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <h6 style={{ fontWeight: "bold", margin: "0" }}>Mostrar información del seguro</h6>
                      <ButtonGroup aria-label="outlined  primary button group">
                        <FxSwitch
                          labels={["Desactivar", "Activar"]}
                          status={vacationalPackageNewConfigResult.withInsuranceCheck}
                          onChange={() => {
                            const newVP = { ...vacationalPackageNewConfigResult };
                            newVP.withInsuranceCheck = !newVP.withInsuranceCheck;
                            setVacationalPackageNewConfigResult(newVP);
                          }}
                        />
                      </ButtonGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <h6 style={{ fontWeight: "bold", margin: "0" }}>Mostrar información de transfer</h6>
                      <ButtonGroup aria-label="outlined  primary button group">
                        <FxSwitch
                          labels={["Desactivar", "Activar"]}
                          status={vacationalPackageNewConfigResult.withTransferCheck}
                          onChange={() => {
                            const newVP = { ...vacationalPackageNewConfigResult };
                            newVP.withTransferCheck = !newVP.withTransferCheck;
                            setVacationalPackageNewConfigResult(newVP);
                          }}
                        />
                      </ButtonGroup>
                    </div>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <AlertNoAccommodation variant="info">No hay rutas disponibles</AlertNoAccommodation>
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default OfferVacationalPackageComponent;
