/* eslint-disable no-undef */
/* eslint-disable no-alert */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MdClear } from "react-icons/md";
// eslint-disable-next-line camelcase
import { LS_getItem, LS_setItem } from "@lib/storage/localStorage";
import { usePagesAliasesList } from "@organisms/contenido/pages-aliases/hooks/usePagesAliasesList";
import difference from "../../../Ticketing/shared/differenceBetweenTwoLists";
import { contentFields } from "./constants";

const usePagesAliasesListState = ({ type }) => {
  const MAX_PAGES_VIEW = 25;
  const [errorState, setErrors] = useState({ show: false, list: [] });
  const [market, setMarket] = useState(LS_getItem("market") === null ? "es" : LS_getItem("market"));
  const { fetchData, loading, list, deletePageAlias, putPageAlias } = usePagesAliasesList({ market });
  const history = useHistory();
  const [uploadResult, setUploadResult] = useState();

  const [rowsSelected, setRowSelected] = useState({ es: [], fr: [], pt: [], it: [] });

  const onChangeMarketSelections = (newList) => {
    setRowSelected({ ...rowsSelected, [market]: newList });
  };
  const handleMarket = async (nuMarket) => {
    setMarket(nuMarket);
    setUploadResult();
    LS_setItem("market", nuMarket);
  };

  const tableHead = [
    { code: "tld", name: "Mercado", type: "market" },
    { code: "oldPath", name: "URL antigua", type: "string" },
    { code: "newPath", name: "URL nueva", type: "string" },
    { code: "type", name: "Tipo", type: "string" },
  ];

  const actionDeletePageAlias = async (rowData) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "ATENCIÓN: Ha elegido eliminar el alias guardado\nEsta acción  eliminará la redirección a la nueva URL.\n¿Está Seguro?"
      )
    ) {
      await deletePageAlias(rowData);
    }
  };

  const actions = [{ name: "Eliminar", icon: <MdClear />, func: actionDeletePageAlias }];

  const parseType = (type) => {
    switch (type) {
      case "page":
        return "Página";
      case "accommodation":
        return "Alojamiento";
      default:
        return type;
    }
  };

  const addNewPageAlias = () => {
    throw "Not implemented yet";
  };
  const newList = list.map((item) => {
    return { ...item, type: parseType(item.type) };
  });
  const generateCsvFromList = () => {
    return list.map((item) => ({
      ...item,
    }));
  };
  /* eslint-disable no-use-before-define */
  /**
   *
   * @param {*} suppliersList
   * @returns
   * saved: number, number of element send in the request after filtered
   * errors: string[], errors received during the execution
   */
  const bulkUploadPagesAliases = async (pagesAliasesList) => {
    try {
      //* ** Validation

      const errorsValidation = await pagesAliasesValidation(pagesAliasesList);

      if (errorsValidation.length > 0) {
        return { errors: errorsValidation };
      }

      const { toInsert, toUpdate } = difference(list, pagesAliasesList, false, {});
      for (const item of [...toInsert, ...toUpdate]) {
        await putPageAlias(item);
      }
      return { saved: [...toInsert, ...toUpdate].length, errors: [] };
    } catch (e) {
      return { errors: [e.message] };
    }
  };

  const pagesAliasesValidation = async (pagesAliasesList) => {
    let errors = [];
    for (let index = 0; index < pagesAliasesList.length; index += 1) {
      const rowId = index + 2;
      const pageAlias = pagesAliasesList[index];

      errors = [...errors, ...pageAliasValidation(pageAlias).map((ev) => `Fila ${rowId}: ${ev}`)];
    }
    return errors;
  };

  const pageAliasValidation = (pageAlias) => {
    const errors = [];
    // Mandatory
    ["tld", "oldPath", "newPath", "type"].forEach((field) => {
      if (pageAlias[field] === undefined || pageAlias[field] === "") {
        errors.push(`El campo <${field}> es requerido`);
      }
      if (field === "type" && !["page", "accommodation"].includes(pageAlias[field])) {
        errors.push(`El campo <${field}> debe ser un valor de [page, accommodation]`);
      }
      if (field === "tld" && !["es", "fr", "pt", "it"].includes(pageAlias[field])) {
        errors.push(`El campo <${field}> debe ser un valor de [es, fr, pt, it]`);
      }
      if (field === "tld" && pageAlias[field] !== market) {
        errors.push(`El campo <${field}> debe ser igual al mercado seleccionado`);
      }
    });
    return errors;
  };

  const reloadAliases = async () => {
    await fetchData();
  };

  const onUploadEnded = async (resultUpload) => {
    let status = "success";
    if (resultUpload.errors.length > 0) {
      status = resultUpload.saved && resultUpload.saved > 0 ? "warning" : "error";
    } else {
      await reloadAliases();
    }
    setUploadResult({ status, ...resultUpload });
  };

  return {
    MAX_PAGES_VIEW,
    type,
    errorState,
    setErrors,
    market,
    setMarket,
    loading,
    newList,
    history,
    rowsSelected,
    setRowSelected,
    onChangeMarketSelections,
    handleMarket,
    addNewPageAlias,
    tableHead,
    actions,
    generateCsvFromList,
    bulkUploadPagesAliases,
    reloadAliases,
    uploadResult,
    onUploadEnded,
  };
};

export default usePagesAliasesListState;
