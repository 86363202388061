import React, { useState } from "react";
// import PropTypes from "prop-types";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import CircularProgress from "@material-ui/core/CircularProgress";

function ButtonsCsvContent({
  itemsName,
  wordcontrol,
  filename,
  csvFunction,
  head,
  specs,
  uploadFunction,
  onUploadEnded,
}) {
  const [loading, setLoading] = useState(false);

  async function onResultReady(resultList) {
    if (confirm(`Se van a subir contenido de ${itemsName}. ¿Continuar?`)) {
      setLoading(true);
      const uploadResult = await uploadFunction(resultList);
      setLoading(false);

      onUploadEnded({ total: resultList.length, ...uploadResult });
    }
  }

  return (
    <>
      {loading ? (
        <>
          <CircularProgress color="primary" /> Cargando CSV...
        </>
      ) : (
        <>
          <div style={{position: "relative",display:"flex", gap:"10px"}}>
            <div style={{position: "absolute", top:"-20px"}}>
              <b>Contenido:</b>
            </div>
            <ButtonUploadCSV
              wordcontrol={wordcontrol}
              specs={specs}
              ignoreWordcontrol={false}
              onResultReady={onResultReady}
            />
            <ButtonDownloadCSV
              wordcontrol={wordcontrol}
              head={head}
              specs={specs}
              filename={filename}
              generateTable={csvFunction}
            />
          </div>
        </>
      )}
    </>
  );
}

ButtonsCsvContent.propTypes = {};

export default ButtonsCsvContent;
