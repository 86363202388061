import axios from "axios";
import React, { useState, useEffect } from "react";
import { sortActionsByParent, addNewField } from "../utils/utilsActions";

const url = "/api/v1/booking-bc/contactcenter";

export const useActionsList = async (tld, lang) => {
  let thestateFinal = { loadingList: true, list: [], marketApplied: null };
  const fetchData = async (thestateFinal) => {
    try {
      let thestate = { loadingList: true, list: [], marketApplied: null };
      const response = await axios.get(`${url}/actions?tld=${tld}`);
      if (response.status === 200) {
        thestate = {
          loadingList: false,
          list: addNewField(sortActionsByParent(response.data.data)),
          marketApplied: tld,
        };
      }
      thestateFinal = { ...thestate };
    } catch (error) {
      thestateFinal = { loadingList: false, list: [], marketApplied: null };
      throw error;
    }
    return thestateFinal;
  };
  if ((thestateFinal.loadingList && tld !== null) || tld !== thestateFinal.marketApplied) {
    return (thestateFinal = fetchData(thestateFinal));
  }
  return thestateFinal;
};

export const getAction = (idAction) => {
  const [actionData, setActionData] = useState({ loading: true, action: {} });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, action: {} };
        const response = await axios.get(`${url}/actions/${idAction}`);

        if (response.status === 200) {
          thestate = { loading: false, action: response.data };
        }
        setActionData(thestate);
      } catch (error) {
        setActionData({ loading: false, action: {} });
        console.error(error);
      }
    };

    if (actionData.loading) {
      fetchData();
    }
  }, [actionData.loading]);
  return { loadingAction: actionData.loading, action: actionData.action };
};

export const putAction = async (action) => {
  let response = { ok: false };
  try {
    response = await axios.put(`${url}/actions/${action.id}`, action);
    response.ok = true;
  } catch (error) {
    response.errorMessage = `Error al insertar la acción ${action.id}`;
    response.error = error;
  }
  return response;
};

export const deleteAction = async (action) => {
  try {
    await axios.delete(`${url}/actions/${action.id}`);
  } catch (error) {
    console.error(error);
  }
};
