import { uuid_v4 } from "@lib/helpers/uuid_v4";

const useNewsletterActions = ({
  user,
  setNewsletterToManage,
  createNewsletter,
  deleteNewsletter,
  getNewsletterPreview,
}) => {
  const manageNewsletterAction = async (event) => {
    await setNewsletterToManage(event.id);
    cb();
  };
  const duplicateNewsletterAction = (cb) => async (event) => {
    const duplicated = event;
    duplicated.id = uuid_v4();
    delete duplicated.key_id;
    await createNewsletter(duplicated);
    cb();
  };
  const deleteNewsletterAction = (cb) => async (event) => {
    const confirmation = confirm("¿Está seguro de eliminar esta newsletter?");
    if (confirmation) {
      await deleteNewsletter(event.id);
    }
    cb();
  };

  const previewNewsletterAction = async (event) => {
    const content = await getNewsletterPreview(event.id);
    var win = window.open(
      "",
      content.name,
      "redirection=yes,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=" +
        (screen.height - 400) +
        ",left=" +
        (screen.width - 840)
    );
    win.document.body.innerHTML = content;
  };

  return {
    manageNewsletterAction,
    duplicateNewsletterAction,
    deleteNewsletterAction,
    previewNewsletterAction,
  };
};

export default useNewsletterActions;
