import React, { useEffect, Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { LS_getItem, LS_setItem } from "@lib/storage/localStorage";
import { uuid_v4 } from "@lib/helpers/uuid_v4";
import { useActionsList, deleteAction, putAction } from "../../hooks/useActionsFetch";
import CardTitle from "@atoms/Titles/CardTitle";
import ErrorModal from "@atoms/Modals/ErrorModal";
import SelectMarket from "@atoms/Selectors/SelectMarket";
import { ButtonAdd } from "@atoms/Buttons/Buttons.styles";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { MdEdit, MdClear } from "react-icons/md";
import { FiPlusSquare } from "react-icons/fi";
import { TYPES_ACTIONS, TYPES_ALERT } from "../../utils/types";

function ActionsList() {
  const history = useHistory();
  const VERSION = 1.0;
  const MAX_OFFERS_VIEW = 25;
  const [market, setMarket] = useState(LS_getItem("market") === null ? "es" : LS_getItem("market"));
  const [errorState, setErrors] = useState({ show: false, list: [] });
  const [alertData, setAlertData] = useState({ text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [actionsList, setActionsList] = useState({
    loadingList: true,
    list: [],
    marketApplied: null,
    pages: 1,
    idList: [],
  });
  const wordcontrol = `CONTACTCENTER_ACTIONS_${market.toUpperCase()}_V${VERSION}`;
  useEffect(() => {
    handleUseActionsList();
  }, [market]);

  const handleUseActionsList = async () => {
    let actions = await useActionsList(market);
    actions.pages = actions.list.length > 0 ? Math.ceil(actions.list.length / MAX_OFFERS_VIEW) : 1;
    actions.idList = actions.list.reduce((acc, cv) => {
      acc.push(cv.id);
      return acc;
    }, []);
    setActionsList(actions);
  };

  const handleMarket = async (nuMarket) => {
    setMarket(nuMarket);
    LS_setItem("market", nuMarket);
  };

  const addNewAction = () => {
    const uniqueValue = uuid_v4();
    history.push(`${history.location.pathname}/${uniqueValue}`);
  };

  const actionEditAction = (rowData) => {
    history.push(`${history.location.pathname}/${rowData.id}`);
  };

  const actionCopyId = async (rowData) => {
    navigator.clipboard.writeText(rowData.id);
    setAlertOpen(true);
    setAlertData({ text: `Id copiado!` });
  };

  const hasAnyChildren = (idSearched) => {
    return actionsList.list.reduce((acc, cv) => {
      if (cv.parentId === idSearched) {
        acc.push(cv.idShort);
      }
      return acc;
    }, []);
  };

  const actionDeleteAction = async (action) => {
    try {
      const childrens = hasAnyChildren(action.id);
      if (childrens.length > 0) {
        setErrors({
          show: true,
          list: [
            `Esta acción no puede borrarse porque las acciones ${childrens.join(" , ")} dependen de ella.`,
          ],
        });
      } else {
        await deleteAction(action);
        setAlertOpen(true);
        setAlertData({ text: `Acción borrada con éxito` });
        handleUseActionsList();
      }
    } catch (error) {
      setErrors({
        show: true,
        list: ["Error al borrar la acción"],
      });
    }
  };

  const handleAvailability = async (action) => {
    const nuAction = { ...action };
    nuAction.enable = !nuAction.enable;
    const response = await putAction(nuAction);
    if (response.ok) {
      setAlertOpen(true);
      setAlertData({ text: `Acción ${nuAction.enable ? "habilitada" : "desabilitada"} con éxito` });
    } else {
      setErrors({
        show: true,
        list: [`Error al ${nuAction.enable ? "habilitada" : "desabilitada"} la acción ${action.id}`],
      });
    }
    handleUseActionsList();
    return response;
  };

  /* CONFIGURACION TABLE FENIX*/
  const tableHead = [
    { code: "idShort", name: "Id", type: "string" },
    { code: "parentIdShort", name: "Depende de", type: "string" },
    { code: "typeTranslated", name: "Tipo", type: "string" },
    { code: "title", name: "Titulo", type: "string" },
    {
      code: "forCommentWithBook",
      name: "Comentario",
      type: "boolean",
      text: { y: "Con Reserva", n: "Sin Reserva" },
      color: { y: "#1bc5bd", n: "silver" },
      filterable: false,
    },
    {
      code: "enable",
      name: "Stado",
      type: "boolean",
      func: handleAvailability,
    },
  ];

  const actions = [
    { name: "Copiar Id", icon: <FileCopyIcon />, func: actionCopyId },
    { name: "Editar", icon: <MdEdit />, func: actionEditAction },
    { name: "Borrar", icon: <MdClear />, func: actionDeleteAction },
  ];

  /* CONFIGURACION CSV */
  const headActions = [
    "Id",
    "Depende de (id de acción padre)",
    "Titulo",
    "Mercado",
    "Tipo (Opciones: Cancelación, Recibo de la transferencia, Bono, Mensaje, Grupo, Pago, Coste de cancelacion)",
    "Consulta con reserva",
    "Etiquetas para FAQ (separadas por ,)",
    "Alerta Habilitada",
    "Alerta Color (Opciones : Amarillo, Azul, Rojo)",
    "Alerta Mensaje",
  ];
  const specsActions = {
    id: "",
    parentId: "",
    title: "",
    tld: "",
    type: "",
    forCommentWithBookCSV: "b",
    faqIds: "sc",
    alertEnable: "b",
    alertLevel: "",
    alertMessage: "",
  };

  const onCSVUpload = async (csvList) => {
    let mapsList = [];
    let alertMsg = [];
    try {
      csvList.map((point, i) => {
        const nuAction = {
          id: "",
          parentId: "",
          title: "",
          tld: market,
          alert: null,
          faqIds: [],
          type: "",
          forCommentWithBook: false,
        };
        const ln = `Linea ${i + 3}`;

        //COMPROBACIONES
        if (point.id === "") point.id = "0";
        if (point.parentId === "") point.parentId = "0";

        if (point.title === "") {
          alertMsg.push(`${ln}: El titulo está vacío.`);
        }

        if (typeof point.forCommentWithBookCSV !== "boolean") {
          alertMsg.push(`${ln}: Consulta con reserva tiene que contener un valor 0 o 1.`);
        }

        if (point.parentId !== "0" && !actionsList.idList.includes(point.parentId)) {
          alertMsg.push(`${ln}: El id de referencia no existe`);
        }
        if (
          point.parentId !== "0" &&
          actionsList.idList.indexOf(point.parentId).forCommentWithBook !== point.forCommentWithBookCSV
        ) {
          alertMsg.push(
            `${ln}: El valor de la consulta con reserva tiene que ser igual que a la accion que se hace referencia.`
          );
        }

        const vTLD = ["es", "pt", "it", "fr"];
        if (!vTLD.includes(point.tld)) {
          alertMsg.push(`${ln}: El mercado "${tld.toLowerCase()}" no es valido. ${this._validTLD.join(",")}`);
        }

        const types = Object.keys(TYPES_ACTIONS).reduce((acc, cv, i) => {
          acc.push(TYPES_ACTIONS[cv].toLowerCase());
          return acc;
        }, []);
        const indexType = types.indexOf(point.type.toLowerCase());
        const keyType = Object.keys(TYPES_ACTIONS)[indexType];
        if (!types.includes(point.type.toLowerCase())) {
          alertMsg.push(`${ln}: La tipo "${point.type}" no es valido.`);
        }

        if (point.alertEnable && point.alertMessage === "") {
          alertMsg.push(`${ln}: El mensaje de la alerta está vacío.`);
        }
        if (typeof point.alertEnable !== "boolean") {
          alertMsg.push(`${ln}: La alerta tiene que contener un valor 0 o 1.`);
        }
        const typesAlert = Object.keys(TYPES_ALERT).reduce((acc, cv, i) => {
          acc.push(TYPES_ALERT[cv].toLowerCase());
          return acc;
        }, []);
        const indexAlertLevel = typesAlert.indexOf(point.alertLevel.toLowerCase());
        const keyAlertLevel = Object.keys(TYPES_ALERT)[indexAlertLevel];
        if (point.alertEnable && indexAlertLevel < 0) {
          alertMsg.push(`${ln}: La color "${point.alertLevel}" no es valido.`);
        }
        //INICIALIZACIONES
        nuAction.id = point.id === "0" ? uuid_v4() : point.id;
        nuAction.title = point.title;
        nuAction.tld = point.tld.toLowerCase();
        nuAction.alert = point.alertEnable ? { level: keyAlertLevel, message: point.alertMessage } : null;
        nuAction.faqIds = point.faqIds;
        nuAction.type = keyType;
        nuAction.forCommentWithBook = point.forCommentWithBookCSV;
        mapsList.push(nuAction);
      });
    } catch (eX) {
      console.error(eX);
      alert(eX);
      return;
    }

    if (alertMsg.length > 0) {
      alert(alertMsg.join("\n"));
      return;
    }

    const r = confirm(`Se va a proceder a subir ${mapsList.length} nueva(s) entradas. ¿Está de acuerdo?`);
    if (r) {
      try {
        await handlePutActions(mapsList);
        alert("Se ha lanzado la tarea de subida. Tardara unos minutos.");
      } catch (err1) {
        console.error(err1);
        alert(err1);
        return;
      }
    }
  };

  const handlePutActions = async (list) => {
    let listResults = [];
    let listErrors = [];
    try {
      const allPromises = Promise.all(
        list.map((item) => {
          return putAction(item);
        })
      );
      const responses = await allPromises;
      responses.forEach((res) => {
        if (!res.ok) {
          listErrors.push(res);
        } else {
          listResults.push(res);
        }
      });

      if (listErrors.length > 0) {
        setErrors({
          show: true,
          list: listErrors.reduce((acc, cv) => {
            acc.push(`${cv.errorMessage}`);
            return acc;
          }, []),
        });
      } else {
        setAlertOpen(true);
        setAlertData({ text: `CSV subido con éxito` });
      }
      handleUseActionsList();
    } catch (error) {
      setErrors({ show: true, list: [error.message] });
    }
  };

  return (
    <Fragment>
      <ContainerFenix>
        <AlertSnackbar show={alertOpen} text={alertData.text} setOpen={setAlertOpen} />
        <CardTitle
          title={"Listado de las acciones"}
          buttons={[
            <SelectMarket key={"bt1"} selection={market} onChangeMarket={handleMarket} />,
            <ButtonAdd key={"bt5"} variant="primary" size="md" onClick={addNewAction}>
              <FiPlusSquare /> Añade nueva acción {market.toUpperCase()}
            </ButtonAdd>,
            <ButtonUploadCSV
              key={"nv-bt1"}
              wordcontrol={wordcontrol}
              specs={specsActions}
              ignoreWordcontrol={false}
              buttonLabel={`Subir Acciones`}
              onResultReady={onCSVUpload}
            />,
            <ButtonDownloadCSV
              key={"nv-bt2"}
              wordcontrol={wordcontrol}
              head={headActions}
              specs={specsActions}
              table={actionsList.list}
              filename={`contac_center_actions_${market}`}
              buttonLabel={`Descargar Acciones ${market.toUpperCase()}`}
              info={["NO es necesario subir el excel entero"]}
            />,
          ]}
        />
        <ErrorModal
          show={errorState.show}
          listErrors={errorState.list}
          onClose={() => setErrors({ show: false, list: [] })}
        />
        {market === null ? (
          <Alert variant={"info"}>
            <span>Selecciona un mercado</span>
          </Alert>
        ) : (
          <Fragment>
            <FenixTable
              loading={actionsList.loadingList}
              tableHead={tableHead}
              tableContent={actionsList.list}
              actions={actions}
              maxNumberPerPages={MAX_OFFERS_VIEW}
              pagination={true}
              isSelectable={false}
            />
          </Fragment>
        )}
      </ContainerFenix>
    </Fragment>
  );
}

export default ActionsList;
