import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import HomeContent from "@organisms/contenido/home-content/HomeContent";

function HomeContentV({ type }) {
  return (
    <ContainerFenix>
      <HomeContent type={type} />
    </ContainerFenix>
  );
}

export default HomeContentV;
