import { useState, useCallback } from "react";
import { getChannelID } from "../../shared/domainVariables";
import { fetchPrecinctChannels } from "../../Precincts/usePrecincts";
import axios from "axios";

export const fetchRelevantInformationWithLangWithChannel = async (productCode, lang, channel) => {
  const url = `/api/v1/ticketing-bc/relevant-information/${productCode}/${lang}/${getChannelID(channel)}`;
  try {
    const { data } = await axios.get(url, {});
    return data.data;
  } catch (e) {
    return null;
  }
};

export const useRelevantInfoChannels = (precinctCode, productCode, channelSelected, setChannelSelected) => {
  const keyLS = `chanelFor${precinctCode}${productCode}`;
  const storageChannel = sessionStorage.getItem(keyLS);
  const [channels, setChannels] = useState([channelSelected]);
  const [loadingChannels, setLoadingChannels] = useState(true);

  const getRelevantInfoChannels = useCallback(async () => {
    setLoadingChannels(true);
    const precinctChannelsFound = await fetchPrecinctChannels(precinctCode);
    const newChannelList = precinctChannelsFound.map((ch) => ({ ...ch.channel }));

    setChannels([channelSelected].concat(newChannelList));
    setLoadingChannels(false);
  }, [precinctCode, storageChannel]);

  useState(() => {
    getRelevantInfoChannels();
  }, [precinctCode, getRelevantInfoChannels]);

  function onChangeChannel(event) {
    setChannelSelected(event.target.value);
    sessionStorage.setItem(keyLS, getChannelID(event.target.value));
  }

  return { loadingChannels, channels, onChangeChannel };
};
