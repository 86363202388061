import axios from "axios";

class BankAccountApi {
  static async getBankAccountPending() {
    const url = "/api/v1/sps/supplier-bank-accounts";
    const { data, status } = await axios.get(url, { params: { verified: false } });
    if (status !== 200 || data === null) {
      throw new Error("getBankAccountPending: Not valid response");
    }
    return data;
  }

  static async startValidationSupplierBankAccount(bankAccountID, userValidator) {
    // (`📩 Enviando email de validación a ${bankAccountID}`);

    const url = `/api/v1/sps/supplier-bank-accounts/${bankAccountID}/start-validation`;
    const { data, status } = await axios.post(url, { userValidator });
    if (status !== 200 || data === null) {
      throw new Error("createRemittance: Not valid response");
    }
  }

  static async continueValidationSupplerBankAccount(bankAccountID, userValidator) {
    // (`📲 Enviando SMS de validación a ${bankAccountID}`);

    const url = `/api/v1/sps/supplier-bank-accounts/${bankAccountID}/continue-validation`;
    const { data, status } = await axios.post(url, { userValidator });
    if (status !== 200 || data === null) {
      throw new Error("createRemittance: Not valid response");
    }
  }

  static async signValidationSupplierBankAccount(bankAccountID, userValidator, verificationCodes) {
    // (`📝 Firmar validación de ${bankAccountID}`);

    const url = `/api/v1/sps/supplier-bank-accounts/${bankAccountID}/sign`;
    const { data, status } = await axios.post(url, { userValidator, verificationCodes });
    if (status !== 200 || data === null) {
      throw new Error("createRemittance: Not valid response");
    }
  }
}

export default BankAccountApi;
