import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Grid, TextField, FormLabel } from "@material-ui/core";
import { Skeleton, Alert } from "@material-ui/lab";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import FxSwitch from "@atoms/Switches/FxSwitch";
import SelectMarketMulti from "@atoms/Selectors/SelectMarketMulti";
import SectionTitle from "@atoms/Titles/SectionTitle";
import MultiItemSelector from "@molecules/Forms/MultiItemSelector";
import SelectorTicketProduct from "@molecules/Forms/SelectorTicketProduct";
import { fetchSingleCheckoutRequirement, saveCheckoutRequirement } from "./useCheckoutRequirements";

const CheckoutRequirementsEditor = ({}) => {
  const history = useHistory();
  const { reqCode } = useParams();
  const isNew = reqCode === "NEW";

  const formFields = [
    { code: "gender", name: "Género" },
    { code: "birthday", name: "Fecha de nacimiento" },
    { code: "city", name: "Ciudad" },
    { code: "country", name: "Nacionalidad" },
    {
      code: "document",
      name: "Tipos de documento",
      options: [
        { code: "%ID", name: "ID nacional" },
        { code: "PAS", name: "Pasaporte" },
      ],
    },
    { code: "passport_number", name: "Número de documento" },
    { code: "passport_date", name: "Caducidad de documento" },
    { code: "issuing_country", name: "País de documento" },
    { code: "borncity", name: "Ciudad de nacimiento" },
    { code: "flightNumber", name: "Número de vuelo" },
  ];

  const [loading, setLoading] = useState(true);
  const [checkoutRequirement, setCheckoutRequirement] = useState();

  async function getSingleCheckoutRequirement() {
    setLoading(true);

    const response = await fetchSingleCheckoutRequirement(reqCode);

    setLoading(response.loading);

    setCheckoutRequirement(response.requirement);
  }

  useEffect(() => {
    if (isNew) {
      setCheckoutRequirement({
        enable: false,
        name: "",
        pcodes: [],
        requirements: [],
        tld: [],
        needpassage: false,
      });
      setLoading(false);
    } else {
      getSingleCheckoutRequirement();
    }
  }, []);

  const changeOnName = (newName) => {
    setCheckoutRequirement({ ...checkoutRequirement, name: newName.target.value });
  };

  const changeOnEnable = (newEnable) => {
    setCheckoutRequirement({ ...checkoutRequirement, enable: newEnable });
  };

  const changeOnMarket = (newMarket) => {
    setCheckoutRequirement({ ...checkoutRequirement, tld: newMarket });
  };

  const changeOnProductCodes = (newValue) => {
    setCheckoutRequirement({ ...checkoutRequirement, pcodes: newValue });
  };

  const changeOnNeedPassage = (newValue) => {
    setCheckoutRequirement({ ...checkoutRequirement, needpassage: newValue });
  };

  const changeOnFieldRequirements = (newFieldRequirements) => {
    setCheckoutRequirement({ ...checkoutRequirement, requirements: newFieldRequirements });
  };

  const backToTable = () => {
    history.push(`/app/product/checkout-requirements-services-table`);
  };

  const saveRequirements = async () => {
    setLoading(true);
    try {
      await saveCheckoutRequirement(checkoutRequirement);
      backToTable();
    } catch (e) {
      alert(e);
      return;
    }
  };

  return loading || checkoutRequirement === undefined ? (
    <Skeleton variant="rect" height={300} animation="wave" />
  ) : (
    <>
      <SectionTitle
        title={isNew ? "Creando un NUEVO requisito de formulario" : `Editor de formulario ${reqCode}`}
        backFunction={backToTable}
        buttons={[
          <ButtonSave key={"B1"} onClick={saveRequirements}>
            Guardar requisitos
          </ButtonSave>,
        ]}
      />
      <Grid container style={{ marginBottom: "15px" }}>
        <Grid item xs={4}>
          <TextField label="Nombre identificativo" value={checkoutRequirement.name} onChange={changeOnName} />
        </Grid>

        <Grid item xs={4}>
          <SelectMarketMulti selection={checkoutRequirement.tld} onChangeMarket={changeOnMarket} />
        </Grid>

        <Grid item xs={4}>
          <FxSwitch
            value="end"
            labels={["Habilitado", "Deshabilitado"]}
            status={checkoutRequirement.enable}
            onChange={changeOnEnable}
          />
        </Grid>
      </Grid>

      <SelectorTicketProduct selected={checkoutRequirement.pcodes} onChange={changeOnProductCodes} />
      <TravCard>
        <TravBodyCard>
          <TravTitleCard>Selecciona los campos del formulario</TravTitleCard>

          <Grid container style={{ marginBottom: "15px" }}>
            <Grid item xs={8}>
              <Alert severity="info">
                Los campos que selecciones se le mostraran al cliente en el formulario de confirmación
                <br />
                (No se incluyen nombre y apellidos, pues van siempre).
              </Alert>
            </Grid>
            <Grid item xs={4}>
              {/* RMR: Nota-> Material no funciona con RadioButtons y booleanos :( */}
              <div style={{ display: "inline-grid", margin: "10px 0", float: "right" }}>
                <FormLabel component="legend">¿A quien solicitar estos campos?</FormLabel>
                <FxSwitch
                  labels={["A todos los pasajeros"]}
                  status={checkoutRequirement.needpassage}
                  onChange={changeOnNeedPassage}
                />

                <FxSwitch
                  labels={["Solo al titular"]}
                  status={!checkoutRequirement.needpassage}
                  onChange={(n) => changeOnNeedPassage(!n)}
                />
              </div>
            </Grid>
          </Grid>

          <MultiItemSelector
            title={"Campos del formulario"}
            selected={checkoutRequirement.requirements}
            optionsTemplate={formFields}
            onChange={changeOnFieldRequirements}
          />
        </TravBodyCard>
      </TravCard>
    </>
  );
};

export default CheckoutRequirementsEditor;
