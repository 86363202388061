import { useState, useEffect } from "react";
import {
  getAllBoatsDestination,
  getAllBoatsRoutes,
  getAllBoatsPortZones,
  getAllBoatsAccommodations,
} from "./useBoatsFetch";

export default function useBoatsManagement() {
  const [destinations, setDestinations] = useState({
    loadingList: true,
    list: [],
  });

  const [routePorts, setRoutePorts] = useState({
    loadingList: true,
    list: [],
  });

  const [portZones, setPortZones] = useState({
    loadingList: true,
    list: [],
  });

  const [accommodationFares, setAccommodationsFares] = useState({
    loadingList: true,
    list: [],
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUseAllBoatDestination = async () => {
    const responsePortsDestination = await getAllBoatsDestination();
    setDestinations({
      loadingList: responsePortsDestination.loadingList,
      list: responsePortsDestination.list,
    });
  };

  useEffect(() => {
    if (destinations.loadingList) {
      handleUseAllBoatDestination();
    }
  }, [destinations.loadingList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAllBoatsRoutes = async () => {
    const responseRoutePortsDestination = await getAllBoatsRoutes();
    setRoutePorts({
      loadingList: responseRoutePortsDestination.loadingList,
      list: responseRoutePortsDestination.list,
    });
  };

  useEffect(() => {
    if (routePorts.loadingList) {
      handleAllBoatsRoutes();
    }
  }, [routePorts.loadingList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAllBoatsPortZones = async () => {
    const responsePortZones = await getAllBoatsPortZones();
    setPortZones({
      loadingList: responsePortZones.loadingList,
      list: responsePortZones.list,
    });
  };

  useEffect(() => {
    if (portZones.loadingList) {
      handleAllBoatsPortZones();
    }
  }, [portZones.loadingList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUseAllBoatAccomodations = async () => {
    const responseAccommodationFares = await getAllBoatsAccommodations();
    setAccommodationsFares({
      loadingList: responseAccommodationFares.loadingList,
      list: responseAccommodationFares.list,
    });
  };

  useEffect(() => {
    if (accommodationFares.loadingList) {
      handleUseAllBoatAccomodations();
    }
  }, [accommodationFares.loadingList]);

  return {
    destinations,
    setDestinations,
    routePorts,
    setRoutePorts,
    portZones,
    setPortZones,
    accommodationFares,
    setAccommodationsFares,
  };
}
