class TaskQueue {
  constructor() {
    this.queue = [];
    this.running = false;
    this.onCompleteCallback = () => {};
    this.onCompleteTasksCallback = () => {};
  }

  add(task) {
    this.queue.push(task);
    if (!this.running) {
      this.running = true;
      this.run();
    }
  }

  async run() {
    if (this.queue.length > 0) {
      const task = this.queue.shift();
      try {
        await task();
      } catch (error) {
        console.error(error);
      }
      this.onCompleteTasksCallback(this.queue.length);
      this.run();
    } else {
      this.running = false;
      this.onCompleteCallback();
    }
  }

  flush() {
    if (this.queue.length > 0) {
      this.onCompleteCallback();
    }
    this.queue = [];
  }

  onComplete(callback) {
    this.onCompleteCallback = callback;
  }

  onCompleteTasks(callback) {
    this.onCompleteTasksCallback = callback;
  }
}

export default TaskQueue;
