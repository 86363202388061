import React from "react";
import styled from "styled-components";
import { Row, Button, Alert } from "react-bootstrap";
import { MdCancel } from "react-icons/md";

export const ChipEmail = styled(Alert)`
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  border: none;
  display: inline-flex;
  outline: none;
  align-items: center;
  white-space: nowrap;
  border-radius: 24px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  margin: 0 5px 10px 0;
`;
export const UsChipEmail = styled.div`
  color: rgba(0, 0, 0, 0.87);
  height: 32px;
  cursor: default;
  border: none;
  display: inline-flex;
  outline: none;
  padding: 0;
  font-size: 0.8125rem;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-sizing: border-box;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  margin: 0 10px 10px 0;
`;
export const ChipLabel = styled.span`
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
`;
export const ChipDelete = styled(MdCancel)`
  color: #bdbdbd;
  font-size: 24px;
  cursor: pointer;
  height: auto;
  margin: 0 5px 0 -8px;
  &:hover {
    color: #666666;
  }
`;
