/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";

const FxAlert = ({
  variant,
  textcolor,
  bordercolor,
  borderleft,
  backgroundcolor,
  iconcolor,
  icon,
  dismissable,
  ...props
}) => {
  const [open, setOpen] = useState(true);

  function closeAlert() {
    if (dismissable) {
      setOpen(false);
    }
  }

  /**
   * Default variants
   */
  const styleProps = getFullStyleProps();

  function getFullStyleProps() {
    let styleP = {};
    switch (variant) {
      default:
      case "info":
        styleP = {
          severity: "info",
          // color: "#0d3c61!important",
          bordercolor: "#39a1f4!important",
          backgroundcolor: "#e8f4fd!important",
          iconcolor: "#2196f3!important",
        };
        break;
      case "error":
        styleP = {
          severity: "error",
          // color: "#611a15!important",
          bordercolor: "#f44336!important",
          backgroundcolor: "#fdecea!important",
          iconcolor: "#f44336!important",
        };
        break;
      case "warning":
        styleP = {
          severity: "warning",
          // color: "#663c00!important",
          bordercolor: "#ff9800!important",
          backgroundcolor: "#fff4e5!important",
          iconcolor: "#ff9800!important",
        };
        break;
      case "success":
        styleP = {
          severity: "success",
          // color: "#1e4620!important",
          bordercolor: "#8aca8c!important",
          backgroundcolor: "#edf7ed!important",
          iconcolor: "#1e4620!important",
        };
        break;
      case "disabled":
        styleP = {
          severity: "info",
          bordercolor: "#8a8a8a !important",
          backgroundcolor: "#c9c9c9 !important",
          iconcolor: "#8a8a8a !important",
          opacity: "0.7",
        };
        break;
    }

    /**
     * Custom prop values
     */
    if (textcolor !== undefined) styleP.color = textcolor;
    if (iconcolor !== undefined) styleP.iconcolor = iconcolor;
    if (bordercolor !== undefined) styleP.bordercolor = bordercolor;
    if (backgroundcolor !== undefined) styleP.backgroundcolor = backgroundcolor;
    if (icon !== undefined) styleP.icon = icon;

    if (dismissable) {
      styleP.onClose = closeAlert;
    }

    return styleP;
  }

  return (
    open && (
      <StyleCustomAlert {...styleProps} borderleft={borderleft ? "1" : "0"}>
        {props.children}
      </StyleCustomAlert>
    )
  );
};

const StyleCustomAlert = styled(Alert)`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-left-width: ${(props) => (props.borderleft === "1" ? "7px" : "1px")};
  border-color: ${(props) => props.bordercolor};
  background-color: ${(props) => props.backgroundcolor};
  opacity: ${(props) => props.opacity || 1};
  & .MuiSvgIcon-root {
    color: ${(props) => props.iconcolor};
  }
`;

FxAlert.propTypes = {
  /**
   * Default variants
   */
  variant: PropTypes.oneOf(["info", "error", "warning", "success", "disabled"]),

  /**
   * Text color.  Usar el !important en el prop
   */
  textcolor: PropTypes.string,

  /**
   * Border color.  Usar el !important en el prop
   */
  bordercolor: PropTypes.string,

  /**
   * Border Left. left border width.
   */
  borderleft: PropTypes.bool,

  /**
   * background color. Usar el !important en el prop
   */
  backgroundcolor: PropTypes.string,
  /**
   * Left icon
   */
  icon: PropTypes.element,
  /**
   * If the alert could be closed with an "X"
   */
  dismissable: PropTypes.bool,
};

FxAlert.defaultProps = { borderleft: true, dismissable: false };

export default FxAlert;
