import styled from "styled-components";

export const OfferIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -10px;
  top: -5px;
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 6px;
`;
export const OfferIconInclude = styled(OfferIcon)`
  background-color: #2ed4a2;
`;

export const OfferIconNoInclude = styled(OfferIcon)`
  background-color: #ef4a68;
`;
