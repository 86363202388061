import styled from "styled-components";
import { Col } from "react-bootstrap";
import { ImPlus, ImMinus, ImCross } from "react-icons/im";
import FormControl from "@material-ui/core/FormControl";

export const FormControOccuppancy = styled(FormControl)`
  margin-bottom: 10px !important;
`;

export const ContOccupancy = styled.div`
  border: 1px solid #c6c8ca;
  padding: 10px;
`;

export const DistributionDefault = styled.div`
  margin-bottom: 5px;
`;

export const TextCenter = styled(Col)`
  text-align: center;
`;
export const TextLeft = styled(Col)`
  text-align: left;
`;
export const TextRight = styled(Col)`
  text-align: right;
`;

export const Minus = styled(ImMinus)`
  color: FIREBRICK;
  &:hover {
    color: red;
  }
`;

export const Cross = styled(ImCross)`
  color: FIREBRICK;
  &:hover {
    color: red;
  }
`;

export const Plus = styled(ImPlus)`
  color: #006699;
  &:hover {
    color: #17a2b8;
  }
`;
