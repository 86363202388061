import React from "react";
const Money = ({ amount, currency = "EUR", decimals = 2 }) => {
  const amountView = (amount / 100).toFixed(decimals);
  const currencySimbol = "€";
  return (
    <>
      {amountView}
      {currencySimbol}
    </>
  );
};
export default Money;
