import React from "react";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import JsonPagesList from "@organisms/contenido/json-pages/JsonPagesList/index";

function JSONPagesList({ type }) {
  return (
    <ContainerFenix>
      <JsonPagesList type={type}></JsonPagesList>
    </ContainerFenix>
  );
}

export default JSONPagesList;
