import axios from "axios";
const url = "/api/v1/product-bc/offers/create-calendars";

export const regenerateOffers = async (offers) => {
  let response = {};
  const objGenManager = {
    idOffers: offers.map((off) => off.idOffer),
  };
  try {
    response = await axios.post(url, objGenManager);
  } catch (error) {
    console.error(error);
  }
  return response;
};
