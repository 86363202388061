/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import moment from "moment";
import { Grid, Fab } from "@material-ui/core";
import FxAlert from "@atoms/Alerts/FxAlert";
import ButtonsGlobalVisibility from "@atoms/Buttons/ButtonsGlobalVisibility";
import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import ButtonSave from "@atoms/Buttons/ButtonSave";
import saveCalendarMassUpdate from "../shared/saveCalendarMassUpdate";
import { useCalendarPrecinctMassUpdate } from "../shared/useCalendarMassUpdate";
import MassUpdatePrecinctList from "../shared/MassUpdatePrecinctList";
import useCalendarVisibility from "../shared/useCalendarVisibility";

function CalendarMassUpdate({ precinctCode, channel, onUpdate }) {
  const { loadingProducts, selections, setLoadingProducts, setSelections } = useCalendarPrecinctMassUpdate({
    precinctCode,
    channel,
  });

  const productList = getProductList();
  const { isVisible, changeProductVisibility, changeAllProductVisibility } = useCalendarVisibility({
    precinctCode,
    loading: loadingProducts,
    productList,
    key: "massupdate",
  });

  const [errorList, setErrorLists] = useState([]);
  const [dates, setDates] = useState({
    datei: moment().format("YYYY-MM-DD"),
    dateo: moment().format("YYYY-MM-DD"),
  });

  function getProductList() {
    if (loadingProducts || selections === undefined || Object.keys(selections).length === 0) {
      return [];
    }
    return Object.values(selections[precinctCode].products);
  }

  function onChangeDates(newDates) {
    setDates(newDates);
  }

  function onChangeSelectionPrecinct(newSelection, thisPrecinctCode) {
    const nuSelection = { ...selections };
    nuSelection[thisPrecinctCode] = { ...newSelection[thisPrecinctCode], ...newSelection };
    setSelections(nuSelection);
  }
  function onChangeSelectionProduct(newSelection) {
    const { productCode } = newSelection;
    const nuSelection = { ...selections };
    nuSelection[precinctCode].products[productCode] = { ...newSelection };
    setSelections(nuSelection);
  }
  function onChangeSelectionTicket(newSelection, thisPrecinctCode, productCode) {
    const { ticketId } = newSelection;
    const nuSelection = { ...selections };
    nuSelection[thisPrecinctCode].products[productCode].tickets[ticketId] = { ...newSelection };
    setSelections(nuSelection);
  }

  async function saveSelections() {
    setLoadingProducts(true);
    setErrorLists([]);

    const { num, errors } = await saveCalendarMassUpdate(dates, selections, channel);
    if (errors.length === 0) {
      setLoadingProducts(false);
      onUpdate({ dates, num });
    } else {
      setErrorLists(errors);
      setLoadingProducts(false);
    }
  }

  const showAll = () => changeAllProductVisibility(true);
  const hideAll = () => changeAllProductVisibility(false);

  return (
    <TravCard>
      <TravBodyCard>
        <TravTitleCard>Modificación directa</TravTitleCard>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <FxDatePickersFromTo
              labelFrom={"Desde"}
              dateFromInitial={dates.datei}
              labelTo={"Hasta"}
              dateToInitial={dates.dateo}
              onChange={onChangeDates}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ButtonsGlobalVisibility onClickShow={showAll} onClickHide={hideAll} />
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {loadingProducts ? (
                <Fab style={{ float: "right" }} size="large" color="default">
                  <CenterLoaderCircle />
                </Fab>
              ) : (
                <ButtonSave disabled={false} size="small" onClick={saveSelections}>
                  Aplicar modificación
                </ButtonSave>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <MassUpdatePrecinctList
              loading={loadingProducts}
              {...{
                selections,
                onChangeSelectionPrecinct,
                onChangeSelectionProduct,
                onChangeSelectionTicket,
                isVisible,
                changeProductVisibility,
              }}
            />

            {errorList.length > 0 && (
              <FxAlert variant="error" dismissable={true}>
                <>
                  Se han detectado los siguientes errores:
                  <ul>
                    {errorList.map((e, i) => (
                      <li key={`error${i}`}>{e}</li>
                    ))}
                  </ul>
                </>
              </FxAlert>
            )}
          </Grid>
        </Grid>
      </TravBodyCard>
    </TravCard>
  );
}

export default CalendarMassUpdate;
