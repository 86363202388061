import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import InputText from "@atoms/Inputs/InputText";

const InputCode = ({ title, value, onChange, ...props }) => {
  const sanitizeCode = (inputValue) => {
    let betweenString = inputValue.replace(/\s/gi, "");
    betweenString = betweenString.replace(/\W/gi, "");
    betweenString = betweenString.replace(/[.*+?^${}()|[\]\\]/gi, "");

    betweenString = betweenString.toUpperCase();

    return betweenString;
  };

  return <InputText {...props} title={title} value={value} sanitization={sanitizeCode} onChange={onChange} />;
};

InputCode.propTypes = {};

InputCode.defaultProps = {
  title: "",
  value: "",
  onChange: () => {},
};

export default InputCode;
