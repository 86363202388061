import React, { Fragment } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { TYPES_ALERT } from "../utils/types";
import { AlertIcon } from "../pages/ActionEditor/ActionsListEditor.styles";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { UsCardTitle } from "@atoms/Card/UsCard.styles";
import FxSwitch from "@atoms/Switches/FxSwitch";
import { TextField, Fab } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

function ActionAlert(actionAlert, onChangeActionType, onChangeActionAlertMessage, onChangeLevelActionAlert) {
  return (
    <Card>
      <AlertIcon>
        <FeedbackIcon />
      </AlertIcon>
      <Card.Body>
        <UsCardTitle>
          <span>Activar Alerta</span>{" "}
          <FxSwitch
            value="end"
            labels={["ON", "OFF"]}
            status={actionAlert.state}
            onChange={onChangeActionType}
          />
        </UsCardTitle>
        <Row className="justify-content-md-center">
          {actionAlert.state && (
            <Fragment>
              <Col xs={12}>
                <div shrink id="demo-simple-select-placeholder-label-label">
                  Mensaje *
                </div>
                <TextField label="" value={actionAlert.message} onChange={onChangeActionAlertMessage} />
              </Col>
              <br />
              <br />
              <br />
              <br />
              <Col xs={12}>
                <div shrink id="demo-simple-select-placeholder-label-label">
                  Color
                </div>
                <FormControl variant="outlined">
                  <Select
                    native
                    value={actionAlert.level}
                    onChange={onChangeLevelActionAlert}
                    label="alertType"
                    inputProps={{
                      name: "Type",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {Object.keys(TYPES_ALERT).map((cv, i) => {
                      return <option value={cv}>{`${TYPES_ALERT[cv]}`}</option>;
                    })}
                  </Select>
                </FormControl>
              </Col>
            </Fragment>
          )}
        </Row>
        <br />
      </Card.Body>
    </Card>
  );
}

export default ActionAlert;
