import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MdEdit, MdControlPointDuplicate, MdClear } from "react-icons/md";
import { useOfferList, deleteOffer, putOffer, generateNewOfferCode } from "../../hooks/useOfferFetch";
import { LS_getItem, LS_setItem } from "@lib/storage/localStorage";
import { regenerateOffers } from "../../hooks/useGenManager";
import { sendEmail } from "../../hooks/useSummaryReport";
import { userAuthorized } from "../../../../entries/fenix/auth/FenixAuthContext";

const OfferListState = () => {
  const user = userAuthorized();
  const MAX_OFFERS_VIEW = 25;
  const history = useHistory();
  const [market, setMarket] = useState(LS_getItem("market") === null ? "es" : LS_getItem("market"));
  const [errorState, setErrors] = useState({ show: false, list: [] });
  const [offerList, setOfferList] = useState({
    loadingList: true,
    list: [],
    infoList: { enabled: 0, disabled: 0, total: 0 },
    marketApplied: null,
    pages: 1,
  });

  const [reload, setReload] = useState({ listReload: "", valuesReload: {} });

  const [rowsSelected, setRowSelected] = useState({ es: [], fr: [], pt: [], it: [] });

  const onChangeMarketSelections = (newList) => {
    setRowSelected({ ...rowsSelected, [market]: newList });
  };

  useEffect(() => {
    handleUseOfferList();
  }, [market]);

  const sendSummaryReport = async () => {
    await sendEmail();
    alert(`En unos instantes recibirá un email con el informe solicitado ✉ `);
  };

  const handleUseOfferList = async () => {
    let offers = await useOfferList(market);
    offers.list = offers.list.map((offer) => {
      if (offer.services.tickets.length > 0) {
        offer._nameTicket = offer.services.tickets[0].name;
      } else {
        offer._nameTicket = "-";
      }
      if (offer.services.accommodations.length > 0) {
        offer._nameAccommodation = offer.services.accommodations[0].name;
      } else {
        offer._nameAccommodation = "-";
      }
      if (offer.relevanceScoring === undefined) {
        offer.relevanceScoring = 0;
      }
      return offer;
    });
    offers.infoList = counterEnabledStatusList(offers);
    offers.pages = offers.list.length > 0 ? Math.ceil(offers.list.length / MAX_OFFERS_VIEW) : 1;
    setOfferList(offers);
  };

  const counterEnabledStatusList = (offers) => {
    return offers.list.reduce(
      (acc, offer) => {
        if (offer.enable) {
          acc.enabled += 1;
        } else {
          acc.disabled += 1;
        }
        acc.total += 1;
        return acc;
      },
      { enabled: 0, disabled: 0, total: 0 }
    );
  };

  const handleMarket = async (nuMarket) => {
    setMarket(nuMarket);
    LS_setItem("market", nuMarket);
  };

  const nodeTypes = ["packageht", "packagefht", "acczone"];

  const head = [
    "Codigo (Para nueva dejar vacio)",
    // "Mercado",
    "Activado (0|1)",
    "Nodo",
    `Tipo de nodo (${nodeTypes.join(", ")})`,
    "HCode (opcional)",
    "Order por defecto",
    "Imagen",
    "Titulo",
    "Descripcion corta",
    "Precio público",
    "Precio privado",
    "Descuento",
    "Mínimo de noches",
    "¿Descatado? (0|1)",
    "¿Flash? (0|1)",
    'Etiquetas Temas (separado por ",")',
    'Etiquetas Fechas (separado por ",")',
    'Etiquetas Destinos (separado por ",")',
    // "URL de la página",
    "Fecha de publicación (DD/MM/YYYY)",
    "Fecha de expiracion (DD/MM/YYYY HH:mm:ss)",
    "Borrado (escribir la palabra ELIMINAR)",
  ];

  const actionEditTemplate = (rowData) => {
    history.push(`${history.location.pathname}/${rowData.idOffer}`);
  };

  const actionDuplicate = async (rowData, duplicateMarket) => {
    const nuOffer = { ...rowData };
    let textMarket = market;
    nuOffer.idOffer = generateNewOfferCode();
    nuOffer.enable = false;
    if (duplicateMarket !== market) {
      textMarket = duplicateMarket;
      nuOffer.market = duplicateMarket;
      nuOffer.tags = {
        topic: [],
        dates: [],
        location: [],
      };
      nuOffer.title = `${nuOffer.title} - DUPLICADO DESDE ${textMarket}`;
    }
    // nuOffer.market = market;
    await putOffer(nuOffer);
    handleUseOfferList(offerList.marketApplied);

    // history.push(`${history.location.pathname}/${rowData.code}?duplicate=true`);
  };

  const actionDeleteOffer = async (rowData) => {
    const deleteConfirm = confirm(`¿Está seguro de eliminar la oferta ${rowData.idOffer}?`);
    if (deleteConfirm) {
      await deleteOffer(rowData);
      handleUseOfferList(offerList.marketApplied);
    }
  };

  const handleAvailability = async (offer) => {
    const nuOffer = { ...offer };
    nuOffer.enable = !nuOffer.enable;
    const response = await putOffer(nuOffer);
    handleUseOfferList(offerList.marketApplied);
    return response;
  };

  const handleHighlighted = async (offer) => {
    const nuOffer = { ...offer };
    nuOffer.highlighted = !nuOffer.highlighted;
    const response = await putOffer(nuOffer);
    handleUseOfferList(offerList.marketApplied);
    return response;
  };

  const getIdOffersValues = (values) => {
    return Object.keys(values).reduce((acc, cv, i) => {
      if (values[cv]) {
        acc.push(offerList.list[i].idOffer);
      }
      return acc;
    }, []);
  };

  const addNewOffer = () => {
    const uniqueValue = generateNewOfferCode();
    history.push(`${history.location.pathname}/${uniqueValue}`);
  };

  const reloadOffers = async () => {
    const resultConfirm = confirm(
      `❗ Este proceso va a lanzar búsquedas reales ❗\nSi puedes evitar hacerlo mejor. Ten en cuenta que una vez que lo lances puede tardar hasta 30 minutos.\n\nSe van a lanzar un total de ${
        rowsSelected[market].length
      } ofertas:\n\n${rowsSelected[market].map((o) => o.idOffer).join("\n")}`
    );
    if (resultConfirm) {
      await regenerateOffers(rowsSelected[market]);
      handleUseOfferList();
      alert(`Las ofertas han sido lanzadas 🚀`);
      setRowSelected({ es: [], fr: [], pt: [], it: [] });
    }
  };

  const specs = {
    code: "",
    // tld: "",
    enable: "b",
    node: "",
    nodetype: "",
    hcode: "",
    order: "d",
    image: "",
    title: "",
    shortdescription: "",
    price: {
      publicPrice: "d",
      privatePrice: "d",
      discount: "d",
    },
    minNights: "d",
    highlighted: "b",
    flashOffer: "b",
    tags: { topic: "sc", dates: "sc", location: "sc" },
    // urlDestination: "",
    publishDate: "",
    expirationDate: "",
    toDelete: "",
  };

  const actions = [
    { name: "Editar", icon: <MdEdit />, func: actionEditTemplate },
    {
      name: "Duplicar en ES",
      icon: <MdControlPointDuplicate />,
      func: (rowData) => actionDuplicate(rowData, "es"),
    },
    {
      name: "Duplicar en PT",
      icon: <MdControlPointDuplicate />,
      func: (rowData) => actionDuplicate(rowData, "pt"),
    },
    {
      name: "Duplicar en FR",
      icon: <MdControlPointDuplicate />,
      func: (rowData) => actionDuplicate(rowData, "fr"),
    },
    {
      name: "Duplicar en IT",
      icon: <MdControlPointDuplicate />,
      func: (rowData) => actionDuplicate(rowData, "it"),
    },
    { name: "Borrar", icon: <MdClear />, func: actionDeleteOffer },
  ];

  const tableHead = [
    //{ code: "checkboxWithoutHeader", name: "", type: "checkbox" },
    { code: "idOffer", name: "Código", type: "string" },
    { code: "title", name: "Titulo", type: "string" },
    { code: "market", name: "Mercado", type: "market" },
    { code: "relevanceScoring", name: "Relevancia", type: "string" },
    {
      code: "_nameAccommodation",
      name: "Alojamiento",
      type: "string",
    },
    {
      code: "_nameTicket",
      name: "Ticket",
      type: "string",
    },
    {
      code: "enable",
      name: "Disponibilidad",
      type: "boolean",
      func: handleAvailability,
    },
    // {
    //   code: "highlighted",
    //   name: "Destacada",
    //   type: "boolean-alternative",
    //   text: { y: "Si", n: "No" },
    //   func: handleHighlighted,
    // },
    {
      code: "highlighted",
      name: "Destacada",
      type: "boolean",
      labels: ["Destacada", "No destacada"],
      func: handleHighlighted,
    },
    // { code: "node", name: "Nodo", type: "string" },
    // { code: "nodetype", name: "Tipo de nodo", type: "string" },
    // { code: "publishDate", name: "Publicada", type: "date" },
    // { code: "expirationDate", name: "Caducidad", type: "date", format: "DD/MM/YYYY HH:mm:ss" },
  ];

  return {
    offerList,
    market,
    setMarket,
    setErrors,
    errorState,
    head,
    nodeTypes,
    tableHead,
    actions,
    specs,
    handleUseOfferList,
    addNewOffer,
    handleAvailability,
    actionDeleteOffer,
    actionDuplicate,
    actionEditTemplate,
    handleMarket,

    MAX_OFFERS_VIEW,
    //handleCheckboxReload,
    //reload,
    reloadOffers,

    rowsSelected,
    onChangeMarketSelections,
    sendSummaryReport,
  };
};

export default OfferListState;
