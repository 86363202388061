import { useState, useEffect } from "react";

import { useVoucherList, manageVoucher, unBlockVoucher } from "../hooks/useVoucherFetch";
import voucherListActions from "./VoucherListActions";

const VoucherListState = () => {
  const MAX_VOUCHERS_VIEW = 10;
  const [isManageVoucherActive, setIsManageVoucherActive] = useState(false);
  const [voucherToManage, setVoucherToManage] = useState({});
  const [isLoading, setLoading] = useState(false); // eslint-disable-line no-unused-vars
  const [voucherList, setVoucherList] = useState({
    loadingList: true,
    list: {
      pendings: [],
      infringements: [],
      ferrys: [],
      travisWorking: [],
    },
    infoList: {
      pendings: 0,
      infringements: 0,
      ferrys: 0,
      travisWorking: 0,
      total: 0,
    },
    pages: 1,
  });

  const updateVoucherList = async () => {
    const { vouchersData } = await useVoucherList();
    setVoucherList({
      loadingList: vouchersData.loadingList,
      list: {
        pendings: vouchersData.list.pendings,
        infringements: vouchersData.list.infringements,
        ferrys: vouchersData.list.ferrys,
        travisWorking: vouchersData.list.travisWorking,
      },
      infoList: {
        pendings: vouchersData.list.pendings.length ?? 0,
        infringements: vouchersData.list.infringements.length ?? 0,
        ferrys: vouchersData.list.ferrys.length ?? 0,
        travisWorking: vouchersData.list.travisWorking.length ?? 0,
        total: vouchersData.total ?? 0,
      },
      pages: 1,
    });
    setLoading(false);
  };

  const { actionsVouchersPending, actionsVouchersInfrigement, actionsVouchersTravis, actionsVouchersFerrys } =
    voucherListActions({
      setIsManageVoucherActive,
      setVoucherToManage,
      updateVoucherList,
    });

  useEffect(() => {
    setLoading(true);
    async function loadData() {
      await updateVoucherList();
    }
    loadData();
  }, []);

  const updateVoucherData = async (checkMutex = true) => {
    setLoading(true);
    await unBlockVoucher(voucherToManage);
    await setTimeout(async () => {
      const voucherDetail = await manageVoucher(voucherToManage.bookref, checkMutex);
      setVoucherToManage(voucherDetail);
      setLoading(false);
      setIsManageVoucherActive(true);
    }, 2000);
  };

  const returnToList = () => {
    setIsManageVoucherActive(false);
    setLoading(true);
    updateVoucherList();
  };

  const tableHead = [
    { code: "bookref", name: "CodRef", type: "string" },
    { code: "contactPerson.name", name: "Nombre", type: "string" },
    { code: "email", name: "Email", type: "string" },
    { code: "datecreation", name: "F.Compra", type: "date", format: "DD/MM/YYYY" },
    { code: "datei", name: "CheckIn", type: "date", format: "DD/MM/YYYY" },
    { code: "dateo", name: "CheckOut", type: "date", format: "DD/MM/YYYY" },
    { code: "market", name: "Mercado", type: "market" },
    { code: "phone", name: "Teléfono", type: "string" },
    { code: "services", name: "Servicios", type: "string" },
    { code: "voucherBlock", name: "Bloqueado", type: "string" },
  ];

  return {
    voucherList,
    setVoucherList,
    tableHead,
    MAX_VOUCHERS_VIEW,
    actionsVouchersPending,
    actionsVouchersInfrigement,
    actionsVouchersTravis,
    actionsVouchersFerrys,
    isManageVoucherActive,
    setIsManageVoucherActive,
    voucherToManage,
    setVoucherToManage,
    updateVoucherList,
    isLoading,
    updateVoucherData,
    returnToList,
  };
};

export default VoucherListState;
