import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Paper, MenuList, MenuItem } from "@material-ui/core";

const FileSuggestions = ({ searcherString, bucket, maxsuggestions = 50, onSelectOption }) => {
  const minchar = 3;
  const [suggestions, setSuggestions] = useState([]);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (timer !== null) {
      clearTimeout(timer);
      setTimer(null);
    }
    if (searcherString.length >= minchar) {
      let newTimer = setTimeout(() => {
        getSuggestions(bucket, searcherString)
          .then((r) => {
            setSuggestions(r.slice(0, maxsuggestions));
          })
          .catch((e) => {
            console.error(e);
          });
      }, 500);
      setTimer(newTimer);
    } else {
      setSuggestions([]);
    }
  }, [searcherString]);

  const getSuggestions = async (bucket, stringToSearch) => {
    let listSuggestions = [];
    try {
      const tags = await axios.get(
        `/api/v1/product-bc/files-manager/${bucket}/tags?idTag[regex]=${stringToSearch}`
      );
      if (tags.status === 200) {
        listSuggestions = tags.data.data.reduce((acc, tag) => {
          acc.push({ name: tag.idTag, type: "tag" });
          return acc;
        }, []);
      }

      const filesN = await axios.get(
        `/api/v1/product-bc/files-manager/${bucket}/files?name[regex]=${stringToSearch}`
      );
      if (filesN.status === 200) {
        listSuggestions = [...new Set([...listSuggestions, ...filesN.data.data])];
      }
    } catch (error) {
      console.error(error);
    }

    return listSuggestions;
  };

  const selectOption = (newOption) => {
    setSuggestions([]);
    onSelectOption(newOption);
  };

  return (
    <Fragment>
      {suggestions.length > 0 && (
        <Paper style={{ maxHeight: "230px", overflowY: "auto" }}>
          <MenuList>
            {suggestions.length === 0 && <MenuItem>No hay sugerencias</MenuItem>}
            {/* Menu List */}
            {suggestions.length > 0 &&
              suggestions.map((option, index) => {
                return (
                  <MenuItem key={`searchoption_${index}`} onClick={() => selectOption(option.name)}>
                    {option.name}
                  </MenuItem>
                );
              })}
          </MenuList>
        </Paper>
      )}
    </Fragment>
  );
};

export default FileSuggestions;
