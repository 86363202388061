import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

function YearSelectorMultiple({
  label,
  disabled,
  limitPast,
  limitFuture,
  yearsSelected,
  onChangeSelectedYears,
}) {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - limitPast;
  const endYear = currentYear + limitFuture;

  const yearList = Array(endYear - startYear + 1)
    .fill()
    .map((_, idx) => startYear + idx);

  function handleChange(year) {
    const newYearsSelected = [...yearsSelected];
    const ix = newYearsSelected.findIndex((y) => y === year);
    if (ix !== -1) {
      newYearsSelected.splice(ix, 1);
    } else {
      newYearsSelected.push(year);
    }
    onChangeSelectedYears(newYearsSelected.sort());
  }

  return (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row={true}>
        {yearList.map((thisYear) => (
          <FormControlLabel
            key={`year${thisYear}`}
            control={
              <Checkbox
                disabled={disabled}
                size="small"
                checked={yearsSelected.includes(thisYear)}
                onChange={() => handleChange(thisYear)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={thisYear}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

YearSelectorMultiple.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  limitPast: PropTypes.number,
  limitFuture: PropTypes.number,
  yearsSelected: PropTypes.arrayOf(PropTypes.number),
  onChangeSelectedYears: PropTypes.func,
};

YearSelectorMultiple.defaultProps = { limitPast: 1, limitFuture: 1 };

export default YearSelectorMultiple;
