import React, { Fragment } from "react";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import CardTitle from "@atoms/Titles/CardTitle";
import ErrorModal from "@atoms/Modals/ErrorModal";
import { FiPlusSquare } from "react-icons/fi";
import AlertSnackbar from "@atoms/Alerts/AlertSnackbar";

const RappelsListView = ({
  list,
  loading,
  setErrors,
  errorState,
  tableHead,
  actions,
  addNewRappel,
  MAX_ITENS_FOR_VIEW,
  alertData,
  alertOpen,
  setAlertOpen,
}) => {
  return (
    <Fragment>
      <ContainerFenix>
        <CardTitle
          title={"Listado de Rappels"}
          buttons={[
            <ButtonAdd key={"bt1"} icon={<FiPlusSquare />} onClick={addNewRappel}>
              Nuevo Rappel
            </ButtonAdd>,
          ]}
        />
        <ErrorModal
          show={errorState.show}
          listErrors={errorState.list}
          onClose={() => setErrors({ show: false, list: [] })}
        />

        <FenixTable
          loading={loading}
          tableHead={tableHead}
          tableContent={list}
          actions={actions}
          maxNumberPerPages={MAX_ITENS_FOR_VIEW}
          pagination={true}
          isSelectable={false}
          selectedRows={list}
          onChangeSelections={() => {}}
        />
      </ContainerFenix>
      <AlertSnackbar show={alertOpen} text={alertData} setOpen={setAlertOpen} severity={"info"} />
    </Fragment>
  );
};

export default RappelsListView;
