import React, { Fragment, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { FiCheckCircle, FiEye } from "react-icons/fi";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import { MdDeleteForever } from "react-icons/md";
import { UsCardTitle, LeftEnable } from "@atoms/Card/UsCard.styles";
import { AlerTitle } from "@atoms/Titles/Titles.styles";

const initialDates = () => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return {
    startDate: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
    endDate: `${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()}`,
  };
};

function FXComponent({ handle, form }) {
  const [showDates, setShowDate] = useState(false);
  const [ShowNewDate, setShowNewDate] = useState(false);
  const [newDate, setNewDate] = useState(initialDates());

  const infoConverter = () => {
    let info = form.offerCriteria.dates.length === 0 ? "No hay periodos seleccionados." : "Resumen: ";
    form.offerCriteria.dates.map((item, index) => {
      info += `${item.startDate.split("-").reverse().join("-")} a ${item.endDate
        .split("-")
        .reverse()
        .join("-")}`;
      if (index < form.offerCriteria.dates.length - 1) {
        info += ` , `;
      }
      return info;
    });

    return info;
  };

  const getMaxDateTo = () => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 375);
    return maxDate;
  };

  return (
    <Card>
      <Card.Body>
        <UsCardTitle>
          <span>Periodo de validez de la oferta</span>
          <LeftEnable>
            {form.offerCriteria.dates.length > 0 && (
              <div style={{ marginRight: "3px" }}>
                <ButtonCustom
                  icon={<FiEye />}
                  key={"bt1"}
                  size="medium"
                  onClick={() => {
                    setShowNewDate(false);
                    setShowDate(!showDates);
                  }}
                >
                  {!showDates && <Fragment>Ver</Fragment>}
                  {showDates && <Fragment>Ocultar</Fragment>}
                </ButtonCustom>
              </div>
            )}

            {!ShowNewDate ? (
              <ButtonAdd
                key={"bt2"}
                size="medium"
                onClick={() => {
                  setShowDate(false);
                  if (ShowNewDate) {
                    const nuForm = { ...form };
                    nuForm.offerCriteria.dates.push(newDate);
                    handle(nuForm);
                    setShowNewDate(false);
                  } else {
                    setShowNewDate(true);
                  }
                }}
              >
                Añadir fechas
              </ButtonAdd>
            ) : (
              <ButtonCustom
                key={"bt2"}
                color="secondary"
                size="medium"
                icon={<FiCheckCircle />}
                onClick={() => {
                  setShowDate(false);
                  if (ShowNewDate) {
                    const nuForm = { ...form };
                    nuForm.offerCriteria.dates.push(newDate);
                    handle(nuForm);
                    setShowNewDate(false);
                  } else {
                    setShowNewDate(true);
                  }
                }}
              >
                Confirmar fechas
              </ButtonCustom>
            )}
          </LeftEnable>
        </UsCardTitle>
        {ShowNewDate && (
          <Fragment>
            <Row>
              <FxDatePickersFromTo
                maxDate={getMaxDateTo()}
                labelFrom={"Desde"}
                dateFromInitial={newDate.startDate}
                labelTo={"Hasta"}
                dateToInitial={newDate.endDate}
                onChange={({ datei, dateo }) => {
                  setNewDate({ startDate: datei, endDate: dateo });
                }}
              />
            </Row>
            <br />
          </Fragment>
        )}

        <Row>
          {showDates &&
            form.offerCriteria.dates
              .sort((firstEl, secondEl) => {
                return new Date(firstEl.startDate).getTime() - new Date(secondEl.startDate).getTime();
              })
              .map((item, index) => {
                return (
                  <Col xs={12} key={index}>
                    <div
                      style={{
                        position: "relative",
                        border: "1px solid #c6c8ca",
                        padding: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <FxDatePickersFromTo
                        labelFrom={"Desde"}
                        dateFromInitial={item.startDate}
                        labelTo={"Hasta"}
                        dateToInitial={item.endDate}
                        onChange={({ datei, dateo }) => {
                          const nuForm = { ...form };
                          nuForm.offerCriteria.dates[index].startDate = datei;
                          nuForm.offerCriteria.dates[index].endDate = dateo;
                          handle(nuForm);
                        }}
                      />
                      <MdDeleteForever
                        style={{ position: "absolute", cursor: "pointer", top: "10px", right: "10px" }}
                        onClick={() => {
                          const nuForm = { ...form };
                          nuForm.offerCriteria.dates.splice(index, 1);
                          handle(nuForm);
                        }}
                      />
                      <br />
                    </div>
                  </Col>
                );
              })}
          <Col xs={12}>{!showDates && <AlerTitle variant="info">{infoConverter()}</AlerTitle>}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default FXComponent;
