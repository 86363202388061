import axios from "axios";

const urlVoucherInfoRules = "/api/v1/booking-bc/vouchers/info";

const useVoucherUtils = () => {
  function isInTravisHandsVoucher(voucher) {
    return voucher.voucherBlock !== undefined && voucher?.voucherBlock === "travis_trabajando";
  }

  function isFerryVoucher(voucher) {
    return voucher?.boats?.length > 0;
  }

  const checkIfExistInfrigementsVouchers = async (bookrefs) => {
    const INFRINGEMENT = "INFRINGEMENT";
    if (bookrefs === undefined || bookrefs.length === 0) {
      return [];
    }
    const request = { bookrefs };
    try {
      const response = await axios.post(urlVoucherInfoRules, request);

      if (response.status === 200) {
        const infringementsVouchersBookRef = Object.entries(response.data);

        const bookrefsInfringements = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of infringementsVouchersBookRef) {
          if (value) {
            if (value.ruleStatus === INFRINGEMENT) {
              bookrefsInfringements.push(key);
            }
          }
        }

        return bookrefsInfringements;
      }
    } catch (error) {
      return error.message;
    }
    return null;
  };

  const cleanVoucherList = (vouchers) => {
    return vouchers.map((voucher) => {
      voucher.contactPerson.name = `${voucher.contactPerson.name} ${voucher.contactPerson.lname}`;
      return voucher;
    });
  };

  const voucherClassification = async (allVouchers) => {
    const travisWorking = allVouchers.filter((voucher) => isInTravisHandsVoucher(voucher));
    const ferrys = allVouchers.filter((voucher) => isFerryVoucher(voucher));

    const bookrefs = allVouchers.map((voucher) => voucher.bookref);
    const statusVouchers = await checkIfExistInfrigementsVouchers(bookrefs);
    const infringements = allVouchers.filter(
      (voucher) =>
        statusVouchers.includes(voucher.bookref) &&
        !isInTravisHandsVoucher(voucher) &&
        !isFerryVoucher(voucher)
    );
    const pendings = allVouchers.filter(
      (voucher) =>
        !statusVouchers.includes(voucher.bookref) &&
        !isInTravisHandsVoucher(voucher) &&
        !isFerryVoucher(voucher)
    );
    return { pendings, infringements, ferrys, travisWorking };
  };

  return {
    isInTravisHandsVoucher,
    isFerryVoucher,
    checkIfExistInfrigementsVouchers,
    voucherClassification,
    cleanVoucherList,
  };
};

export default useVoucherUtils;
