export const langs = ["es-ES", "fr-FR", "pt-PT", "it-IT"];
export const tlds = ["es", "pt", "fr", "it"];
export const tldXLangMatch = {
  es: "es-ES",
  fr: "fr-FR",
  pt: "pt-PT",
  it: "it-IT",
};
export const langXTldMatch = {
  "es-ES": "es",
  "fr-FR": "fr",
  "pt-PT": "pt",
  "it-IT": "it",
};

/**
 * Return the iteration of langs or tlds depending of the value of the contentFields key
 * @param {*} model
 * @returns
 */
export function returnModelArrayFromContentIdentificator(model) {
  let modelArray = [];
  if (model === "lang") {
    modelArray = [...langs];
  } else if (model === "tld") {
    modelArray = [...tlds];
  } else if (model === "original") {
    modelArray = [];
  } else {
    throw new Error('Neither "tld", "lang" or "original" definition in contentFields object');
  }

  return modelArray;
}

export function getChannelID(channel) {
  const id = [channel.provider];
  if (channel.merchant !== undefined && channel.merchant !== "") {
    id.push(channel.merchant);
  }
  return id.join("-");
}
