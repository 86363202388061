import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Grid, TextField, Fab } from "@material-ui/core";
import FxChip from "@atoms/Content/FxChip";
import SelectorDefault from "@molecules/Forms/SelectorDefault";
import { precinctTypes, precinctTypesTranslation } from "./constants";

function PrecinctSectionForm({ onChange, formPrecinct, precinct }) {
  return (
    <Fragment>
      {!formPrecinct.enable ? (
        <Fragment>
          <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
            <Grid item xs={6}>
              <img
                src={precinct.logo}
                alt="Fenix"
                style={{ width: "100%", height: "150px", objectFit: "contain" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2} alignItems={"stretch"} justify={"space-between"} direction="column">
                <Grid item xs={12}>
                  <FxChip
                    style={{ width: "80%", textAlign: "center" }}
                    label={precinctTypesTranslation[precinct.type].name}
                    colour={precinctTypesTranslation[precinct.type].colour}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={!formPrecinct.enable}
                    label="Nombre ES"
                    value={precinct.name["es-ES"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={!formPrecinct.enable}
                    label="CountryCode"
                    value={precinct.countryCode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
            <Grid item xs={6}>
              <TextField
                disabled={!formPrecinct.enable}
                label="CountryCode"
                value={formPrecinct.form.countryCode}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.countryCode = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectorDefault
                disabled={!formPrecinct.enable}
                title={"Tipo"}
                selected={formPrecinct.form.type}
                list={precinctTypes}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.type = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
            <Grid item xs={6}>
              <TextField
                disabled={!formPrecinct.enable}
                label={"Imagen principal"}
                value={formPrecinct.form.headImage}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.headImage = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={!formPrecinct.enable}
                label={"Logo"}
                value={formPrecinct.form.logo}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.logo = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
          </Grid>
          <br />
          <hr />
          <h6>Nombre</h6>
          <br />
          <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
            {Object.keys(precinct.name).map((key) => {
              return (
                <Fragment key={key}>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!formPrecinct.enable}
                      label={key}
                      value={formPrecinct.form.name[key]}
                      onChange={(nVal) => {
                        const nuForm = { ...formPrecinct };
                        nuForm.form.name[key] = nVal.target.value;
                        onChange(nuForm);
                      }}
                    />
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
          <br />
          <hr />
          <h6>Localizacion</h6>
          <br />
          <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
            <Grid item xs={6}>
              <TextField
                disabled={!formPrecinct.enable}
                label="Dirección"
                value={formPrecinct.form.location.address}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.location.address = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled={!formPrecinct.enable}
                label="Latitud"
                value={formPrecinct.form.location.coordinates.latitude}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.location.coordinates.latitude = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                disabled={!formPrecinct.enable}
                label="Longitud"
                value={formPrecinct.form.location.coordinates.longitude}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.location.coordinates.longitude = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
            <Grid item xs={6}>
              <TextField
                disabled={!formPrecinct.enable}
                label="Telefono"
                value={formPrecinct.form.location.phone}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.location.phone = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={!formPrecinct.enable}
                label="Email"
                value={formPrecinct.form.location.email}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.location.email = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2} alignItems={"flex-start"} justify={"space-between"} direction="row">
            <Grid item xs={3}>
              <TextField
                disabled={!formPrecinct.enable}
                label="Código Ciudad"
                value={formPrecinct.form.location.hcity}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.location.hcity = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled={!formPrecinct.enable}
                label="Código de País"
                value={formPrecinct.form.location.hcountry}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.location.hcountry = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled={!formPrecinct.enable}
                label="Código Region"
                value={formPrecinct.form.location.hregion}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.location.hregion = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled={!formPrecinct.enable}
                label="Código Estado"
                value={formPrecinct.form.location.hstate}
                onChange={(nVal) => {
                  const nuForm = { ...formPrecinct };
                  nuForm.form.location.hstate = nVal.target.value;
                  onChange(nuForm);
                }}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
      <br />
    </Fragment>
  );
}

PrecinctSectionForm.propTypes = {};

export default PrecinctSectionForm;
