import React, { Fragment, useState, useEffect } from "react";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import ButtonDownloadCSV from "@molecules/Buttons/ButtonDownloadCSV";
import ButtonUploadCSV from "@molecules/Buttons/ButtonUploadCSV";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import CardTitle from "@atoms/Titles/CardTitle";
import ErrorModal from "@atoms/Modals/ErrorModal";
import axios from "axios";
import { MdControlPointDuplicate, MdDeleteForever } from "react-icons/md";

import {
  saveNevasportOffers,
  deleteNevasportOffers,
  useNevasportSeasons,
  useNevasportSkiResort,
} from "./hook/useNevasportFetch";
import { treatCSV, customSlice, searchRepeatedTitles } from "./utils/utilsNevasport";

const url = "/api/v1/marketing-bc/nevasport";
const NevasportOffers = () => {
  // Market, type & wordcontrol
  const VERSION = 1.0;
  const wordcontrol = `NEVASPORT_V${VERSION}`;

  // Errors in CSV
  const [errorState, setErrors] = useState({ show: false, list: [] });

  // Offers
  const { loadingSeasons, seasonsNevasport } = useNevasportSeasons({});
  const { loadingSkiResort, skiResortsNevasport } = useNevasportSkiResort({});
  const [offersData, setOffersData] = useState({ loading: true, offers: [] });
  useEffect(() => {
    fetchOffersData();
  }, []);

  const fetchOffersData = async () => {
    try {
      const response = await axios.get(`${url}/offers`);
      if (response.status === 200 && response.data.ok) {
        setOffersData({ loading: false, offers: response.data.data });
      }
    } catch (error) {
      setOffersData({ loading: false, offers: [] });
      throw error;
    }
  };

  const headOffers = [
    "Id",
    "Titulo",
    "Url",
    "Precio",
    "Precio Día",
    "Precio Antes",
    "Estacion",
    "Temporada",
    "Visible(0|1)",
    "Fecha Inico",
    "Fecha Fin",
    "Borrar(0|1)",
  ];
  const specsOffers = {
    idOferta: "dz",
    title: "",
    url: "",
    price: "d",
    dayPrice: "dz",
    previousPrice: "dz",
    idSkiResort: "d",
    idSeason: "",
    visible: "b",
    startDate: "",
    endDate: "",
    delete: "dz",
  };

  const headSeasons = ["Codigo", "Nombre"];
  const specsSeasons = {
    code: "",
    name: "",
  };

  const headSkiResorts = ["Codigo Pais", "Codigo Estacion", "Nombre"];
  const specsSkiResorts = {
    id_pais: "d",
    id: "d",
    nombre: "",
  };

  const duplicateOffer = async (data) => {
    const nuData = { ...data };
    nuData.idOferta = "0";
    await saveNevasportOffers([nuData]);
    window.location = "";
    setLoadingSection(true);
  };

  const deleteOffer = async (data) => {
    await deleteNevasportOffers([data]);
    fetchOffersData();
  };

  const actions = [
    // { name: "Duplicar", icon: <MdControlPointDuplicate />, func: duplicateOffer },
    { name: "Borrar", icon: <MdDeleteForever />, func: deleteOffer },
  ];

  const tableHead = [
    { code: "idOferta", name: "Codigo", type: "string" },
    { code: "title", name: "Titulo", type: "string" },
    { code: "idSeason", name: "Temporada", type: "string" },
    { code: "idSkiResort", name: "Estacion", type: "string" },
    { code: "startDate", name: "Fecha Inicio", type: "string" },
    { code: "endDate", name: "Fecha Fin", type: "string" },
    {
      code: "visible",
      name: "Visible",
      type: "boolean",
      text: { y: "Visible", n: "Oculto" },
      filterable: false,
    },
  ];

  const handleCSVData = async (data) => {
    const repeatedTitles = searchRepeatedTitles(data, offersData.offers);
    if (repeatedTitles.length > 0) {
      setErrors({ show: true, list: repeatedTitles });
    } else {
      const csvTrated = treatCSV(errorState.list, seasonsNevasport, skiResortsNevasport, data);
      if (csvTrated.listErrors.length > 0) {
        const nuErrorState = { ...errorState };
        nuErrorState.show = true;
        nuErrorState.list = csvTrated.listErrors;
        setErrors(nuErrorState);
      } else {
        const itemsSlice = customSlice(csvTrated.offers);
        setOffersData({ loading: true, offers: [] });
        await deleteNevasportOffers(itemsSlice.deleted);
        await saveNevasportOffers(itemsSlice.upupdate);
        fetchOffersData();
      }
    }
  };

  return (
    <Fragment>
      <ContainerFenix>
        <CardTitle
          title={"Ofertas Nevasport"}
          buttons={
            !false
              ? [
                  // <SelectMarket key={"bt1"} selection={market} onChangeMarket={tempFunc} />,

                  <ButtonUploadCSV
                    key={"nv-bt1"}
                    wordcontrol={wordcontrol}
                    specs={specsOffers}
                    ignoreWordcontrol={false}
                    buttonLabel={`Subir Ofertas`}
                    onResultReady={handleCSVData}
                  />,
                  <ButtonDownloadCSV
                    key={"nv-bt2"}
                    wordcontrol={wordcontrol}
                    head={headOffers}
                    specs={specsOffers}
                    table={offersData.offers}
                    filename={`NevasportOffer`}
                    buttonLabel={`Descargar Ofertas`}
                    info={["NO es necesario subir el excel entero"]}
                  />,
                  <ButtonDownloadCSV
                    key={"nv-bt3"}
                    wordcontrol={wordcontrol}
                    head={headSeasons}
                    specs={specsSeasons}
                    table={seasonsNevasport}
                    filename={`NevasportOffer-Seasons`}
                    buttonLabel={`Descargar Temporadas`}
                    info={[]}
                  />,
                  <ButtonDownloadCSV
                    key={"nv-bt4"}
                    wordcontrol={wordcontrol}
                    head={headSkiResorts}
                    specs={specsSkiResorts}
                    table={skiResortsNevasport}
                    filename={`NevasportOffer-SkiResorts`}
                    buttonLabel={`Descargar Estaciones`}
                    info={[]}
                  />,
                ]
              : []
          }
        />
        <ErrorModal
          show={errorState.show}
          listErrors={errorState.list}
          onClose={() => setErrors({ show: false, list: [] })}
        />
        <FenixTable
          loading={offersData.loading}
          tableHead={tableHead}
          tableContent={offersData.offers}
          actions={actions}
          pagination={true}
          maxNumberPerPages={20}
        />
      </ContainerFenix>
    </Fragment>
  );
};

export default NevasportOffers;
