import React from "react";
import { Grid } from "@material-ui/core";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import CheckoutRequirementsTable from "@organisms/producto/CheckoutRequirementsTable";

const CheckoutRequirementsPage = ({}) => {
  return (
    <ContainerFenix>
      <Grid container>
        <Grid item xs={12}>
          <CheckoutRequirementsTable />
        </Grid>
      </Grid>
    </ContainerFenix>
  );
};

export default CheckoutRequirementsPage;
