import styled from "styled-components";
import { Button } from "@material-ui/core";

export const BoxSectionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
`;
export const ButtonBack = styled(Button)`
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const PrincipalTitleElements = styled.div`
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 576px) {
    display: block;
  }
`;
export const PrincipalTitleElement = styled.div`
display:flex;
flex-grow:1;
  @media (max-width: 768px) {
    min-width: 404px;
  }
  @media (max-width: 576px) {
    text-align: center;
    margin-bottom: 20px;
    min-width: 100%;
  }
`;
export const ElementsTitleRight = styled.div`
position:relative;
  display: flex;
  gap:10px;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  //min-width: 200px;
  flex-grow:1;
  @media (max-width: 576px) {
    text-align: center;
  }
`;
