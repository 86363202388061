/* eslint-disable no-use-before-define */
import { fetchTicket } from "./useTickets";
import { contentFields, csvSpecContent } from "./constants";
import { tldXLangMatch } from "../shared/domainVariables";
import contentCsvRowValidation from "../shared/contentCsvRowValidation";
import saveTicketList from "./bulkUpload";
import difference from "../shared/differenceBetweenTwoLists";

const bulkUploadTicketsContent = async (ticketContentList) => {
  try {
    const promises = [...new Set(ticketContentList.map((t) => t.id))].map((tk) => fetchTicket(tk));
    const responseTickets = await Promise.all(promises);

    //* ** Get tickets saved
    const currentByCode = responseTickets.reduce((a, p) => ({ ...a, [p.id]: p }), {});

    //* ** Validation
    const errorsValidation = ticketContentListValidation(ticketContentList, currentByCode);
    if (errorsValidation.length > 0) {
      return { errors: errorsValidation };
    }

    const toUpload = createCompleteTicketListFromContent(ticketContentList, currentByCode);

    //* ** Get the intersection between the two
    const { toUpdate } = difference(Object.values(currentByCode), toUpload, true, contentFields, "id");

    //* ** Save the results
    const { saved, errors } = await saveTicketList(toUpdate);

    return { saved, errors };
  } catch (e) {
    return { errors: [e.message] };
  }
};

const ticketContentListValidation = (ticketList, currentByCode) => {
  const errors = [];
  ticketList.forEach((ticket) => {
    try {
      if (currentByCode[ticket.id] === undefined) {
        throw new Error(`Ticket <${ticket.id}> does not exists.`);
      }

      contentCsvRowValidation(ticket, csvSpecContent, Object.keys(contentFields));
    } catch (e) {
      errors.push(e.message);
    }
  });

  return errors;
};

const createCompleteTicketListFromContent = (ticketContentCsv, currentByCode) => {
  const currentCopy = JSON.parse(JSON.stringify(currentByCode));

  const toSave = ticketContentCsv.reduce((toSaveList, ticketContentToCheck) => {
    const { id, field, tld, value } = ticketContentToCheck;

    const newSaveList = { ...toSaveList };

    const current = currentCopy[id];
    if (toSaveList[current.id] === undefined) {
      newSaveList[current.id] = { ...currentCopy[id] };
    }

    const lang = tldXLangMatch[tld];
    newSaveList[id][field][lang] = value;

    return newSaveList;
  }, {});

  return Object.values(toSave);
};

export default bulkUploadTicketsContent;
