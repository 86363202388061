import React, { Fragment } from "react";
import { Tabs, Tab } from "react-bootstrap";

// Components & hooks
import CardTitle from "@atoms/Titles/CardTitle";
import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import DoblementeApiSearchInterface from "@organisms/Product/Ticketing/DoblementeApi/DoblementeApiSearchInterface";
import DoblementeApiTicketList from "@organisms/Product/Ticketing/DoblementeApi/DoblementeApiTicketList";
import CaldeaApiBookSearch from "@organisms/Product/Ticketing/Caldea/CaldeaApiBookSearch";
import { useTicketsInfo } from "@organisms/Product/Ticketing/DoblementeApi/useDoblementeAPI";

// Styles
import { ContainerFenix } from "@atoms/Card/UsCard.styles";

const CaldeaApiPage = () => {
  const provider = "CALDEA";

  const { loading, tickets } = useTicketsInfo(provider);

  return (
    <ContainerFenix>
      {loading ? (
        <CenterLoaderCircle />
      ) : (
        <Fragment>
          <CardTitle title={"Buscador API Caldea"} />

          <Tabs defaultActiveKey="book">
            <Tab eventKey="search" title="Buscador de entradas">
              <DoblementeApiSearchInterface provider={provider} />
            </Tab>
            <Tab eventKey="book" title="Buscador de reservas">
              <CaldeaApiBookSearch />
            </Tab>
            <Tab eventKey="tickets" title="Todas las entradas">
              <DoblementeApiTicketList tickets={tickets} />
            </Tab>
          </Tabs>
        </Fragment>
      )}
    </ContainerFenix>
  );
};

export default CaldeaApiPage;
