import React from "react";
import PrecinctChannelsSection from "@organisms/Ticketing/PrecinctChannels/PrecinctChannelsSection";
import { useParams } from "react-router-dom";
import ChannelPageTemplate from "./ChannelPageTemplate";

const PrecinctChannelsPage = () => {
  const { precinctCode } = useParams();
  return (
    <ChannelPageTemplate>
      <PrecinctChannelsSection precinctCode={precinctCode} />
    </ChannelPageTemplate>
  );
};

export default PrecinctChannelsPage;
