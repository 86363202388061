import React from "react";
import { Route } from "react-router-dom";

import PaymentsSectionPage from "../../5-pages/Summary/PaymentsTablePage";

function RoutesSummary({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/summary`;

  return (
    <>
      <Route path={`${mainUrl}/payments/:bookref`} component={PaymentsSectionPage} />
    </>
  );
}

export default RoutesSummary;
