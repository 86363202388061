import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, TextField, InputAdornment, MenuList, MenuItem, ClickAwayListener } from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import axios from "axios";
import { ContInputQuery } from "./InputQueryDropdown.styles";

/*
 * QUERY INPUT
 * Usage:
 * *  label: String, text for the input
 * *  postRequestPath: String, URL for the post request
 * *  optionFormat: Function that returns an HTML-formated option of the result
 * *  onSelectOption: Function triggered when an option is selected
 * *  fieldInResults: String, the field in the body of data of the result containg the list (optional)
 */
const InputQueryDropdownSearch = ({
  label,
  postRequestPath,
  optionFormat,
  onSelectOption,
  fieldInResults = "",
}) => {
  const MINCHAR = 2;
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const [options, setOptions] = useState([]);
  const [lastResult, setLastResult] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (inputText === "") {
      setIsOpen(false);
    }
  }, [inputText]);

  useEffect(() => {
    if (lastResult !== null && lastResult.query === inputText) {
      setIsLoading(false);
      setOptions(lastResult.resultList);
      setIsOpen(true);
    }
  }, [lastResult]);

  useEffect(() => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    if (inputText.length <= MINCHAR) {
      return;
    }
    setIsLoading(true);
    let newTimer = setTimeout(() => {
      executeSearch(inputText)
        .then(() => {})
        .catch((e) => {});
    }, 500);
    setTimer(newTimer);
  }, [inputText]);

  const onError = () => {
    setIsLoading(false);
    setIsOpen(true);
    setOptions([]);
  };

  /**
   *
   * @param {*} option
   */
  const selectOption = (option) => {
    setIsLoading(false);
    setIsOpen(false);
    setOptions([]);
    setInputText("");
    onSelectOption(option);
  };

  /**
   *
   */
  const onChangeQuery = async (event) => {
    setInputText(event.target.value);
  };

  /**
   *
   * @param {*} query
   */
  const executeSearch = async (query) => {
    try {
      const response = await axios.post(postRequestPath, { query: query });
      console.log("response", response);
      if (response.status === 200 && response.data !== undefined) {
        let resultList = [];
        if (fieldInResults !== "") {
          console.log("fieldInResults", fieldInResults);
          resultList = response.data[fieldInResults];
        } else {
          console.log("response.data", response.data);
          resultList = response.data;
        }
        setLastResult({ query, resultList });
      }
    } catch (error) {
      onError();
    }
  };

  return (
    <ContInputQuery>
      <TextField
        label={label}
        // helperText={`La búsqueda comenzara a partir de los ${MINCHAR} caracteres.`}
        // type="search"
        variant="outlined"
        value={inputText}
        size="small"
        onChange={onChangeQuery}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdSearch />
            </InputAdornment>
          ),
          endAdornment: isLoading && <Spinner animation="border" role="status" size="sm" />,
        }}
      />

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Paper style={{ maxHeight: "230px", overflowY: "auto" }}>
            <MenuList>
              {options.length === 0 && <MenuItem>No hay resultados</MenuItem>}
              {/* Menu List */}
              {options.length > 0 &&
                options.map((option, index) => {
                  const ixKey = `searchoption_${index}`;
                  return (
                    <MenuItem key={ixKey} onClick={() => selectOption(option)}>
                      {optionFormat(option)}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      )}
    </ContInputQuery>
  );
};

InputQueryDropdownSearch.propTypes = {
  label: PropTypes.string,
  postRequestPath: PropTypes.string,
  optionFormat: PropTypes.func,
  onSelectOption: PropTypes.func,
};

export default InputQueryDropdownSearch;
