/* eslint-disable no-use-before-define */
import { getSuppliersHyperguest } from "./useSuppliersHyperguest";
import difference from "../../../Ticketing/shared/differenceBetweenTwoLists";
import saveSuppliersList from "./bulkUpload";
import { contentFields } from "./constants";
/**
 *
 * @param {*} suppliersList
 * @returns
 * saved: number, number of element send in the request after filtered
 * errors: string[], errors received during the execution
 */
const bulkUploadSuppliersHyperguest = async (suppliersList) => {
  try {
    //* ** Validation
    const newSuppliersList = parseProducts(suppliersList);

    const errorsValidation = await suppliersListValidation(newSuppliersList);

    if (errorsValidation.length > 0) {
      return { errors: errorsValidation };
    }

    const currentProducts = await getSuppliersHyperguest();
    const { toInsert, toUpdate } = difference(currentProducts, newSuppliersList, false, {});
    const { saved, errors } = await saveSuppliersList([...toInsert, ...toUpdate]);
    return { saved, errors };
  } catch (e) {
    return { errors: [e.message] };
  }
};

const suppliersListValidation = async (suppliersList) => {
  let errors = [];
  for (let index = 0; index < suppliersList.length; index += 1) {
    const rowId = index + 2;
    const supplier = suppliersList[index];

    errors = [...errors, ...supplierValidation(supplier).map((ev) => `Fila ${rowId}: ${ev}`)];
  }
  return errors;
};

const parseProducts = (suppliersList) => {
  return suppliersList.map((supplier) => {
    const newSupplier = { ...supplier };
    return newSupplier;
  });
};

const supplierValidation = (newSupplier) => {
  const errors = [];
  // Mandatory
  ["code", "api", "supplier"].forEach((field) => {
    if (newSupplier[field] === undefined || newSupplier[field] === "") {
      errors.push(`El campo <${field}> es requerido`);
    }
  });
  return errors;
};

export default bulkUploadSuppliersHyperguest;
