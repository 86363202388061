import React, { Fragment } from "react";
import { Route, Link } from "react-router-dom";

// Accommodations
import RoutesAccommodations from "./accommodations/RoutesAccommodations";

// Offers
import OffersList from "./offers/OffersList";
import OffersEditor from "./offers/OffersEditor";
import RappelListPage from "./rappel/RappelList";
import RappelEditor from "./rappel/RappelEditor";
import OffersTags from "./offers/OffersTags";
import CleanCache from "./cleanCache/CleanCache";
import CheckoutRequirementsPage from "../../5-pages/Product/CheckoutRequirementsPage";
import CheckoutRequirementsPageEditor from "../../5-pages/Product/CheckoutRequirementsPageEditor";
import KeyPickupPage from "../../5-pages/Accommodations/KeyPickupPage";
import JackSparrowRulesList from "./jackSparrowRules/JackSparrowRulesList";
import JackSparrowRulesEditor from "./jackSparrowRules/JackSparrowRulesEditor";
// Ticketing
import NaturlandiaApiSearch from "./ticketing/naturlandia-api-search/NaturlandiaApiSearch";

function RoutesProduct({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/product`;
  return (
    <Fragment>
      <RoutesAccommodations parentURL={mainUrl} />
      {/* Offers */}
      {/* <Route exact path={`${mainUrl}/offers`} component={Offers} /> */}
      <Route exact path={`${mainUrl}/offers/list`} component={OffersList} />
      <Route exact path={`${mainUrl}/offers/tags`} component={OffersTags} />
      <Route path={`${mainUrl}/offers/list/:offerCode`} component={OffersEditor} />
      {/* CleanCache */}
      <Route path={`${mainUrl}/clean-cache`} component={CleanCache} />
      {/* Checkout Requirements */}
      <Route path={`${mainUrl}/checkout-requirements-services-table`} component={CheckoutRequirementsPage} />
      <Route
        path={`${mainUrl}/checkout-requirements-services-editor/:reqCode`}
        component={CheckoutRequirementsPageEditor}
      />
      {/* Api searchers */}
      <Route path={`${mainUrl}/ticketing/naturlandia-api-search`} component={NaturlandiaApiSearch} />
      {/* rappel */}
      <Route exact path={`${mainUrl}/rappel/list`} component={RappelListPage} />
      <Route path={`${mainUrl}/rappel/list/:rappelCode`} component={RappelEditor} />
      {/* key-pickup */}
      <Route exac path={`${mainUrl}/alojamientos/key-pickup`} component={KeyPickupPage} />
      {/* Jack Sparrow Rules */}
      <Route exact path={`${mainUrl}/jack-sparrow-rules/list`} component={JackSparrowRulesList} />
      <Route path={`${mainUrl}/jack-sparrow-rules/list/:idRule`} component={JackSparrowRulesEditor} />
    </Fragment>
  );
}

export default RoutesProduct;
