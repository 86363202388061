import React, { Fragment, useState, useEffect } from "react";
import { DragAndDrop } from "@lib/layout/DnD";
import { DraggableListBox, RemoveOption } from "./withDraggableList.styles";

const withDraggableList = (WrappedComponent) => (props) => {
  const { title, list, onChangeList } = props;

  const [listLocal, setListLocal] = useState(list);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [hideIndex, setHideIndex] = useState(-1);

  useEffect(() => {
    setListLocal(list);
  }, [list]);

  /**
   * Launch each time a user drag a block of list and drop in other position
   */
  const onDrop = (dragged, dropArea) => {
    const oldIndex = dragged.uid;
    const newIndex = dropArea.uid;
    if (newIndex !== oldIndex) {
      let pop = list.splice(oldIndex, 1);
      list.splice(newIndex, 0, pop[0]);
      onChangeList(list);
    }
    setHoverIndex(-1);
  };
  const onHover = (dragging, dropArea) => {
    setHoverIndex(dropArea.uid);
  };

  const startDrag = (dragging) => {
    //let pop = list.splice(dragging.uid, 1);
    //setListLocal([...list]);
  };
  const removeFromList = (index) => {
    const r = confirm("¿Seguro que quieres eliminar " + listLocal[index][0] + "?");
    if (r) {
      let pop = list.splice(index, 1);
      onChangeList(list);
    }
  };
  return (
    <Fragment>
      {listLocal.map((objectFromList, index) => (
        <Fragment key={index}>
          <DragAndDrop group={title} uid={index} onDrop={onDrop} startDrag={startDrag} onHover={onHover}>
            <DraggableListBox active={hoverIndex === index} hide={hideIndex === index}>
              <WrappedComponent
                index={index}
                item={objectFromList}
                onChangeItem={(nuItem) => {
                  const nu = [...listLocal];
                  nu[index] = nuItem;
                  setListLocal(nu);
                  onChangeList(nu);
                }}
              />
              <RemoveOption
                onClick={(evt) => {
                  evt.preventDefault();
                  removeFromList(index);
                }}
              />
            </DraggableListBox>
          </DragAndDrop>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default withDraggableList;
