import styled from "styled-components";

/**
 * Allow scrolling in drawer mobile content
 */
const DrawerMobileScrollableContent = styled.div`
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`;

export default DrawerMobileScrollableContent;
