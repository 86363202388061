import React from "react";
import { useParams } from "react-router-dom";
import NewsletterThirdPartyManagementPage from "@organisms/Marketing/NewsletterThirdParty/management/components";

const NewsletterManagementPage = () => {
  const { newsletterId } = useParams(); 
  return <NewsletterThirdPartyManagementPage creation={newsletterId === "new"} newsletterId={newsletterId} />;
};

export default NewsletterManagementPage;
