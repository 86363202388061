import axios from "axios";

export const useAffiliatesList = async () => {
  let affiliatesData = {
    loadingList: true,
    list: [],
    status: 0,
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(AFFILIATE_URL);
      if (response.status === 200) {
        return { loadingList: false, list: response.data.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return { loadingList: false, list: [] };
      // throw error;
    }
  };
  affiliatesData = await fetchData();
  return { affiliatesData };
};

const AFFILIATE_URL = "/api/v1/marketing-bc/affiliates";

export const useAffiliatesManagement = async (affiliateId) => {
  let affiliateData = {
    loading: true,
    affiliate: {},
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${AFFILIATE_URL}/${affiliateId}`);
      if (response.status === 200) {
        return { loading: false, affiliate: response.data, status: response.status };
      }
    } catch (error) {
      console.log(error);
      return { loading: false, affiliate: {} };
      // throw error;
    }
  };
  affiliateData = await fetchData();
  return { affiliateData };
};

export const useSaveAffiliate = async (id, data) => {
  try {
    const response = await axios.put(`${AFFILIATE_URL}/${id}`, data);
    if (response.status === 201) {
      return { id: response.data.affiliateId };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const useDeleteAffiliate = async (id) => {
  try {
    const response = await axios.delete(`${AFFILIATE_URL}/${id}`);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
