import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { MdSearch } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import { ContInputQuery } from "@atoms/Inputs/InputQueryDropdownSearch/InputQueryDropdown.styles";
import { UsChipEmail, ChipLabel, ChipDelete } from "@atoms/Content/Chips";
import { Grid, TextField, InputAdornment, MenuList, MenuItem, ClickAwayListener } from "@material-ui/core";
import { AlertTagsBorder } from "./JsonPageEditorJsonEditor.styles";

const JsonPageEditorInfoTags = ({ label, onSelectOption, onDeleteOption, tags, setErrors }) => {
  const MINCHAR = 2;
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const [options, setOptions] = useState([]);

  const onChangeQuery = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/v1/product-bc/content/pages-tags?tag[regex]=${inputText}`);
      setOptions(response.data.data);
    } catch (e) {
      setErrors({ show: false, list: ["Error al cargas las etiquetas."] });
      setIsOpen(false);
      setOptions([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (inputText.length > MINCHAR) {
      onChangeQuery();
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputText]);

  const onChangeTextField = async (event) => {
    setInputText(event.target.value);
  };

  const selectOption = (option) => {
    setIsLoading(false);
    setIsOpen(false);
    setOptions([]);
    setInputText("");
    onSelectOption(option);
  };

  const removeValueTagList = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    onDeleteOption(newTags);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div style={{ marginBottom: "20px" }} className="titlesection">
          Buscar y crear etiquetas
        </div>
        <ContInputQuery>
          <TextField
            label={label}
            variant="outlined"
            value={inputText}
            size="small"
            onChange={onChangeTextField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdSearch />
                </InputAdornment>
              ),
              endAdornment: isLoading && <Spinner animation="border" role="status" size="sm" />,
            }}
          />

          {isOpen && (
            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
              <Paper style={{ maxHeight: "230px", overflowY: "auto" }}>
                <MenuList>
                  {options.length === 0 && (
                    <MenuItem
                      key={`searchoption_new`}
                      onClick={() => selectOption(inputText)}
                    >{`Haz click para crear una nueva etiqueta con el texto" ${inputText} ".`}</MenuItem>
                  )}
                  {options.length > 0 &&
                    options.map((option, index) => {
                      const ixKey = `searchoption_${index}`;
                      return (
                        <MenuItem key={ixKey} onClick={() => selectOption(option)}>
                          {option}
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </Paper>
            </ClickAwayListener>
          )}
        </ContInputQuery>
      </Grid>
      <Grid item xs={12}>
        <h6>Etiquetas seleccionadas</h6>
        <AlertTagsBorder variant="info">
          {tags.length === 0 && "Ninguna etiqueta añadida"}
          <Grid container>
            <Grid item>
              {tags.map((item, index) => {
                return (
                  <UsChipEmail key={index}>
                    <ChipLabel>{item}</ChipLabel>
                    <ChipDelete onClick={() => removeValueTagList(index)} />
                  </UsChipEmail>
                );
              })}
            </Grid>
          </Grid>
        </AlertTagsBorder>
      </Grid>
    </Grid>
  );
};

JsonPageEditorInfoTags.propTypes = {
  label: PropTypes.string,
  tags: PropTypes.array,
  onSelectOption: PropTypes.func,
  setErrors: PropTypes.func,
};

export default JsonPageEditorInfoTags;
