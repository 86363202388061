import { useEffect, useState } from "react";
import axios from "axios";

const urlContentJSONPageProductBc = "/api/v1/product-bc/content/pages";

// eslint-disable-next-line import/prefer-default-export
export const useJsonPagesList = ({ market }) => {
  const [state, setState] = useState({ loading: true, list: [] });

  const fetchData = async () => {
    try {
      let thestate = { list: [], loading: false };
      const response = await axios.get(`${urlContentJSONPageProductBc}`);
      if (response.status === 200 && response.data) {
        thestate = { list: response.data.data, loading: false };
      }

      setState(thestate);
    } catch (error) {
      setState({ list: [], loading: false });
      throw error;
    }
  };

  const deleteJsonPage = async (idJsonPage) => {
    try {
      await axios.delete(`${urlContentJSONPageProductBc}/${idJsonPage}`);
      await fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const putJsonPage = async (jsonPage) => {
    let response = {};
    try {
      const newJsonPage = { ...jsonPage };
      response = await axios.put(`${urlContentJSONPageProductBc}/${newJsonPage.id}`, newJsonPage);
      response.ok = true;
      await fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (state.loading) {
      fetchData();
    }
  }, [state.loading, state.list, market]);

  return {
    fetchData,
    deleteJsonPage,
    putJsonPage,
    loading: state.loading,
    list: state.list.filter((item) => item.tld === market),
  };
};
