// useFetch.js
import { useEffect, useState } from "react";
import axios from "axios";

// const url = "http://hefesto.default.eks-i2.com";
const url = "/api/hefesto";

/**
 *
 */
export const useQueryConfigurationsData = () => {
  const [state, setState] = useState({ loading: true, concepts: {}, queries: {} });

  useEffect(() => {
    const parseConceptsAndQueries = rawFromDB => {
      const concepts = {};
      const queries = {};

      rawFromDB.forEach(line => {
        if (line.type === "concepts") {
          concepts[line.tld] = line.data.map(con => {
            return { c: con };
          });
        } else if (line.type === "queries") {
          queries[line.tld] = line.data;
        }
      });

      return { concepts, queries };
    };

    const fetchData = async () => {
      try {
        let thestate = { loading: true, concepts: {}, queries: {} };

        // const response = {
        //   status: 200,
        //   data: {
        //     ok: true,
        //     data: [
        //       { type: "concepts", tld: "es", data: ["TRAVENTIA", "PORTAVENTURA", "WARNER", "CABARCENO"] },
        //       { type: "concepts", tld: "pt", data: ["TRAVENTIA", "PORTAVENTURA", "ZOOMARINE"] },
        //       {
        //         type: "queries",
        //         tld: "es",
        //         data: [
        //           { q: "traventia", c: "TRAVENTIA" },
        //           { q: "trabentia", c: "TRAVENTIA" },
        //           { q: "travertia", c: "TRAVENTIA" },
        //           { q: "portaventura", c: "PORTAVENTURA" },
        //           { q: "port aventura", c: "PORTAVENTURA" },
        //           { q: "fort aventura", c: "PORTAVENTURA" },
        //           { q: "warner", c: "WARNER" },
        //           { q: "güarner", c: "WARNER" },
        //         ],
        //       },
        //     ],
        //   },
        // };

        const response = await axios.get(`${url}/seo/configurations`);
        if (response.status === 200 && response.data.ok) {
          const { concepts, queries } = parseConceptsAndQueries(response.data.data);
          thestate = { concepts, queries, loading: false };
        }
        setState(thestate);
      } catch (error) {
        setState({ loading: false, concepts: {}, queries: {} });
        throw error;
      }
    };

    if (state.loading) {
      fetchData();
    }
  }, [state.loading]);

  return { ...state };
};

/**
 *
 * @param {*} listConcepts
 * @param {*} market
 */
export const saveConcepts = async (listConcepts, market) => {
  let responseRet = { ok: false };
  try {
    const data = listConcepts.map(lc => lc.c);
    const toSave = { type: "concepts", tld: market, data };

    const requestRes = await axios.put(`${url}/seo/configurations`, toSave);
    // const requestRes = { status: 200, data: { ok: true } };
    if (requestRes.status === 200 && requestRes.data.ok) {
      responseRet = requestRes.data;
    }
  } catch (error) {
    responseRet = { ok: false, error };
  }

  return responseRet;
};

export const saveQueries = async toSave => {
  let responseRet = { ok: false };
  try {
    const requestRes = await axios.put(`${url}/seo/configurations`, toSave);
    // const requestRes = { status: 200, data: { ok: true } };
    if (requestRes.status === 200 && requestRes.data.ok) {
      responseRet = requestRes.data;
    }
  } catch (error) {
    responseRet = { ok: false, error };
  }

  return responseRet;
};
