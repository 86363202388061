import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card, Alert } from "react-bootstrap";
import { TextField, TextareaAutosize } from "@material-ui/core";
import { MdReorder } from "react-icons/md";
import TicketList from "./TicketList";
import { ContForSapace } from "./StandardTicketInterface.styles";
import InputQueryDropdownSearch from "@atoms/Inputs/InputQueryDropdownSearch";
import { UsCardTitleEnd } from "@atoms/Card/UsCard.styles";
import HtmlTextEditor from "@atoms/Inputs/HtmlTextEditor/HtmlTextEditor";
import Grid from "@material-ui/core/Grid";

function StandardTicketInterface({ ticketsProducts, handleTicketProductsFetch, form, onChangeContent }) {
  const addTicketToList = async (option) => {
    const productsToUse = await handleTicketProductsFetch(option, true);
    const nuForm = { ...form };
    nuForm.config.tickets.precincts.push({
      precinctId: option.code,
      name: option.name[`${form.market}-${form.market.toUpperCase()}`],
      title: option.name[`${form.market}-${form.market.toUpperCase()}`],
      // description: option.description[getLangKey(form.market)],
      description: "",
      useAllProducts: true,
      productsToUse: productsToUse[option.code],
      image: "",
      productForPriceRef: "",
    });
    onChangeContent(nuForm);
  };
  return (
    <Card>
      <Card.Header>
        <span style={{ fontSize: "1.2em" }}>Configuracion para Ticket Estandar</span>
      </Card.Header>
      <Card.Body>
        <Grid container>
          <Grid item xs={12}>
            <Alert variant="info">
              <Grid container spacing={2} alignItems="baseline" justify="space-between">
                <Grid item xs={9}>
                  <Alert.Heading>Tickets involucrados</Alert.Heading>A continuación se seleccionaran los
                  tickets que van a ser incluidos en el email de cross-selling.
                  <ul>
                    <li>
                      La casilla <b>Nº máximo de tickets</b> sirve para indicar el numero de tickets máximo
                      que se mostrará en el email después de que el sistema le aplique una serie de filtros
                      comparativos con la reserva:
                      <ul>
                        <li>
                          Coincidencia de ese ticket en la reserva: Eliminara los tickets que ya esten
                          incluidos en la reserva.
                        </li>
                        <li>Disponibilidad del ticket: Mostrara los tickets que tengan disponibilidad.</li>
                      </ul>
                    </li>
                    <li>
                      Se respetara el orden que se establezca aquí. Puedes utilizar el icono{" "}
                      <MdReorder size={20} /> de la derecha de cada bloque.
                    </li>
                    <li>
                      Se mostraran dentro del bloque marcado con la meta-etiqueta <b>%%STANDARDTICKETS%%</b>{" "}
                      en el HTML (ver abajo).
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={3}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Ejemplo de ticket en el email</Card.Title>
                    </Card.Body>
                    <Card.Img
                      variant="top"
                      src="https://tr1.traventia.com/graphic-design/fenix/ejemplocs.jpg"
                      alt="Ejemplo"
                    />
                  </Card>
                </Grid>
              </Grid>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-between" alignItems="stretch" spacing={2}>
              <Grid item xs={6}>
                <UsCardTitleEnd>
                  <InputQueryDropdownSearch
                    label={"Buscador de tickets"}
                    getRequestPath={`/api/v1/ticketing-bc/precincts?code[regex]=%%QUERY%%`}
                    optionFormat={(option) => {
                      return (
                        <Fragment>
                          <b>
                            {option.code} &nbsp;{"|"}&nbsp;
                            {option.name[`${form.market}-${form.market.toUpperCase()}`]}
                          </b>
                          {/* {option.code} &nbsp;{"|"}&nbsp; <b>{option.name["es-ES"]}</b> */}
                        </Fragment>
                      );
                    }}
                    onSelectOption={addTicketToList}
                  />
                </UsCardTitleEnd>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  type="number"
                  size="small"
                  value={form.config.maxInEmail}
                  label="Maximo de tickets por email"
                  onChange={(nVal) => {
                    const nuForm = { ...form };
                    nuForm.config.maxInEmail = parseInt(nVal.target.value);
                    onChangeContent(nuForm);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TicketList
          title={"Tickets seleccionados"}
          ticketArray={form.config.tickets.precincts}
          ticketsProducts={ticketsProducts}
          onChangeList={(nuT) => {
            const nuForm = { ...form };
            nuForm.config.tickets.precincts = nuT;
            onChangeContent(nuForm);
          }}
        />

        <Alert variant="info">
          <Alert.Heading>Consulta SQL</Alert.Heading>
          <ul>
            <li>
              La query ha de tener restricciones de status y mercado, aunque el sistema aplica también esta
              restricción al mandarlo.
            </li>
            <li>Se recomienda encarecidamente que previamente se ejecute esta query en MySQLWorkbench.</li>
            <li>
              Algunos consejos para trabajar con fechas:
              <ul>
                <li>
                  <b>NOW()</b> devuelve la fecha y hora actual (formato YYYY-mm-dd HH:MM:SS)
                </li>
                <li>
                  <b>CURDATE()</b> devuelve la fecha actual (formato YYYY-mm-dd)
                </li>

                <li>
                  <b>DATE_ADD()</b> Añade un intervalo a una fecha
                  <br /> Sintaxis: DATE_ADD(date, INTERVAL expr type)
                  <br />
                  Donde "date" es una fecha valida, "expr" es un numero a añadir y "type" es una de las
                  siguientes: MICROSECOND, SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, QUARTER, YEAR
                  <br /> Ejemplo 1: DATE_ADD(NOW(), INTERVAL 1 MONTH) -&gt; Corresponderia a la fecha de
                  dentro de un mes, desde hoy.
                  <br /> Ejemplo 2: DATE_ADD('2020-01-15', INTERVAL 2 DAY) -&gt; Devolveria '2020-01-17', dos
                  dias despues del 15.
                </li>
                <li>
                  <b>DATE_SUB()</b> Misma funcionalidad que DATE_ADD, pero restando en vez de sumar.
                </li>
                <li>
                  Más informacion
                  <a
                    href={"https://www.geeksforgeeks.org/sql-date-functions/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" aquí"}
                  </a>
                </li>
              </ul>
            </li>

            <li>
              Un ejemplo de consulta SQL:
              <br />
              {`SELECT bookref 
              FROM busman.booksAccommodations, busman.books 
              WHERE (books.bookref = booksAccommodations.bookref) 
              AND books.datei >= DATE_FORMAT(NOW(),"%Y-%m-%d") 
              AND books.datei <= DATE_ADD(DATE_FORMAT(NOW(),"%Y-%m-%d"), INTERVAL 100 DAY) 
              AND books.status = 1 AND booksAccommodations.hzonelvl3 in ('HCANDORRAC') 
              AND books.market='ES'`}
            </li>
          </ul>
        </Alert>
        <Grid container>
          <Grid item xs={12}>
            {/* Text area */}
            <ContForSapace>
              <TextareaAutosize
                variant="outlined"
                placeholder="Consula SQL"
                // multiline={true}
                value={form.sql}
                onChange={(e) => {
                  const nuForm = { ...form };
                  nuForm.sql = e.target.value;
                  onChangeContent(nuForm);
                }}
                aria-label="maximum weight"
                rowsMin={8}
                style={{ width: "100%" }}
              />
            </ContForSapace>
          </Grid>
        </Grid>

        <Alert variant="info">
          <Alert.Heading>Contenido del email</Alert.Heading>
          <ul>
            <li>
              Las <b>meta-etiquetas</b> que se permiten, tanto en asunto como en el contenido HTML, son:
            </li>
            <ul>
              <li>
                <b>%%USERNAME%%</b>: Nombre del cliente.
              </li>
              <li>
                <b>%%BOOKREF%%</b>: Codigo de reserva.
              </li>
              <li>
                <b>%%STANDARDTICKETS%%</b>: Bloque HTML de tickets, generado automáticamente. Se añadirá en
                cada ticket el bloque de descripcion HTML del ticket configurado anteriormente.
              </li>
            </ul>
          </ul>
        </Alert>

        <Grid container>
          <Grid item xs={12}>
            {/* Email Asunto */}
            <ContForSapace>
              <TextField
                variant="outlined"
                label="Asunto del email"
                helperText="Permirte uso de las metaetiquetas"
                value={form.emailConf.subject}
                onChange={(e) => {
                  const nuForm = { ...form };
                  nuForm.emailConf.subject = e.target.value;
                  onChangeContent(nuForm);
                }}
              />
            </ContForSapace>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {/* Email Contenido */}
            <ContForSapace>
              <HtmlTextEditor
                id="shortDescription"
                value={form.emailConf.body}
                onChange={(nVal) => {
                  const nuForm = { ...form };
                  nuForm.emailConf.body = nVal;
                  onChangeContent(nuForm);
                }}
                placeholder={"Contenido HTML del email"}
                width={"500px"}
                height={200}
              />
            </ContForSapace>
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
}

StandardTicketInterface.propTypes = {
  content: PropTypes.object,
  onChangeContent: PropTypes.func,
};

export default StandardTicketInterface;
