import React from "react";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import {
  contentFields,
  csvHead,
  csvSpecs,
  csvHeadContent,
  csvSpecContent,
  wordcontrol,
  wordcontrolContent,
  filename,
  filenameContent,
} from "./constants";
import ButtonsCsvChannel from "./ButtonsCsvChannel";
import bulkUploadPrecincts from "./bulkUploadPrecincts";
import bulkUploadPrecinctsContent from "./bulkUploadPrecinctsContent";
import reorganizeListIntoCsvContent from "../shared/reorganizeListIntoCsvContent";
import ButtonsCsv from "../shared/ButtonsCsv";
import ButtonsCsvContent from "../shared/ButtonsCsvContent";

function PrecinctsSectionMenu({ precinctsList, onUploadEnded }) {
  const itemsName = "recintos";

  function generateCsvFromList() {
    return precinctsList;
  }

  return (
    <TravCard>
      <TravBodyCard>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", paddingTop:"10px"}}>
          <div>
            <ButtonsCsv
              itemsName={itemsName}
              wordcontrol={wordcontrol}
              filename={filename}
              csvFunction={generateCsvFromList}
              head={csvHead}
              specs={csvSpecs}
              uploadFunction={bulkUploadPrecincts}
              onUploadEnded={onUploadEnded}
            />
          </div>
          <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center", gap:"10px"}}>
            <ButtonsCsvContent
              itemsName={itemsName}
              wordcontrol={wordcontrolContent}
              filename={filenameContent}
              csvFunction={() => reorganizeListIntoCsvContent(precinctsList, contentFields)}
              head={csvHeadContent}
              specs={csvSpecContent}
              uploadFunction={bulkUploadPrecinctsContent}
              onUploadEnded={onUploadEnded}
            />
            <ButtonsCsvChannel onUploadEnded={onUploadEnded} />
          </div>
        </div>
      </TravBodyCard>
    </TravCard>
  );
}

PrecinctsSectionMenu.propTypes = {};

export default PrecinctsSectionMenu;
