import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CircularProgress, Modal, Backdrop } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";

const CustomModal = ({
  loading,
  title,
  open,
  timeout,
  bigModal,
  handleClose,
  children,
  customWidthModal = 800,
  closeOnClickOutside = false,
}) => {
  useEffect(() => {
    if (timeout !== undefined) {
      const timer = setTimeout(() => handleClose(), timeout);
      return () => clearTimeout(timer);
    }
  }, [handleClose, timeout]);

  const body = (
    <ModalSimple bigModal={bigModal} customWidthModal={customWidthModal}>
      <CloseModal onClick={handleClose} />
      {title && <h4 style={{ padding: "20px" }}>{title}</h4>}
      <div style={{ padding: "20px" }}>{children}</div>
    </ModalSimple>
  );

  return (
    <div onClick={(e) => e.preventDefault()}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 2000 }}
          disableBackdropClick={!closeOnClickOutside}
        >
          {body}
        </Modal>
      )}
    </div>
  );
};

const ModalSimple = styled.div`
  position: relative;
  max-width: 550px;
  max-width: ${(props) => (props.bigModal ? `${props.customWidthModal}px` : "500px")};
  //min-height: 400px;
  max-height: 700px;
  overflow-x: auto;
  background-color: #fff;
  border: none !important;
  margin: 60px auto;
  border-radius: 10px;
  &:focus {
    outline: none !important;
  }
`;
const CloseModal = styled(AiOutlineClose)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  font-size: 30px;
  color: black !important;
  cursor: pointer;
`;

CustomModal.propTypes = {
  /**
   *
   */
  open: PropTypes.bool,
  /**
   *
   */
  bigModal: PropTypes.bool,
  /**
   *
   */
  handleClose: PropTypes.func,
};
CustomModal.defaultProps = { title: "", bigModal: false, customWidthModal: false, loading: false };

export default CustomModal;
