/* eslint-disable no-use-before-define */
import React, { Fragment } from "react";
import FxAlert from "@atoms/Alerts/FxAlert";
import withSkeleton from "@lib/layout/withSkeleton";
import CalendarSkeleton from "../shared/Calendar.skeleton";
import { StyDivContainerLine } from "../shared/CalendarRow.styles";
import CalendarTemplate from "../shared/CalendarTemplate";
import CalendarRowNumberedDays from "../shared/CalendarRowNumberedDays";
import CalendarRow from "../shared/CalendarRow";
import useCalendarFilters from "../shared/useCalendarFilters";
import useCalendarVisibility from "../shared/useCalendarVisibility";
import { useCalendarSessions } from "./useCalendarSessions";
import useChangeDay from "../shared/useChangeDay";

function CalendarSessions({ precinctCode, channel }) {
  const {
    year,
    month,
    errors,
    priceMode,
    displayedChildren,
    isHightlighted,
    areChildrenDisplayed,
    onChangeMonth,
    onChangeYear,
    onChangeErrors,
    onChangePriceMode,
    changeRowHighlighting,
    changeDisplayedChildren,
  } = useCalendarFilters(precinctCode);
  const { loadingCalendar, calendar, fetchCalendar, products } = useCalendarSessions({
    precinctCode,
    channel,
    year,
  });
  const { isVisible, changeProductVisibility, changeAllProductVisibility } = useCalendarVisibility({
    precinctCode,
    loading: loadingCalendar,
    productList: products,
    key: "calendar_sessions",
  });
  const { daysLoading, onChangeInDayData } = useChangeDay({ channel, year, onChangeErrors, fetchCalendar });

  function sessionHasTicketSessions(session) {
    return session.ticketSessions.length > 0;
  }

  return loadingCalendar ? (
    <CalendarSkeleton />
  ) : (
    <CalendarTemplate
      propsLoading={loadingCalendar}
      calendarIsNotEmpty={calendar !== undefined && Object.keys(calendar).length > 0}
      onChangeMonth={onChangeMonth}
      onChangeYear={onChangeYear}
      onChangeErrorList={onChangeErrors}
      field={priceMode}
      {...{
        errors,
        month,
        year,
        onChangePriceMode,
        changeAllProductVisibility,
      }}
    >
      {calendar[month] && calendar[month].products.length === 0 && (
        <FxAlert variant={"info"}>No hay productos asignados a este recinto</FxAlert>
      )}

      {/* Linea de dias */}
      {calendar[month].products.map((product, index) => (
        <div key={`product${index}`}>
          {/* Linea de dias */}
          <CalendarRowNumberedDays daysOfMonthArray={Object.values(product.calendar)} />
          {/*  Linea de recinto */}
          {isVisible(product.productCode) && (
            <CalendarRow
              type={"precinct"}
              item={{ precinctCode, calendar: calendar[month].precinctCalendar }}
              onClickInDay={onChangeInDayData}
              {...{ daysLoading, changeProductVisibility, precinctCode }}
            />
          )}
          {/*  Linea de producto */}
          <CalendarRow
            type={"product"}
            item={product}
            parentCalendars={[calendar[month].precinctCalendar]}
            productCode={product.productCode}
            onClickInDay={onChangeInDayData}
            {...{ daysLoading, isVisible, changeProductVisibility, precinctCode }}
          />

          {/* Linea de sesion  */}
          {isVisible(product.productCode) && (
            <>
              {product.sessions.length > 0 ? (
                product.sessions.map((session, indexSession) => (
                  <Fragment key={`sessions${index}${indexSession}`}>
                    <CalendarRow
                      type={"session"}
                      item={session}
                      fieldToShow={priceMode}
                      highlighted={isHightlighted(product.productCode, session.sessionId)}
                      changeHighligthing={() => changeRowHighlighting(product.productCode, session.sessionId)}
                      hasChildren={sessionHasTicketSessions(session)}
                      childrenDisplayed={
                        Object.keys(displayedChildren).length > 0 &&
                        displayedChildren[product.productCode] !== undefined &&
                        displayedChildren[product.productCode][session.sessionId]
                      }
                      changeChildrenVisibility={() =>
                        changeDisplayedChildren(product.productCode, session.sessionId)
                      }
                      parentCalendars={[calendar[month].precinctCalendar, product.calendar]}
                      precinctCode={precinctCode}
                      productCode={product.productCode}
                      productName={product.name}
                      daysLoading={daysLoading}
                      onClickInDay={onChangeInDayData}
                    />
                    {areChildrenDisplayed(product.productCode, session.sessionId) &&
                      sessionHasTicketSessions(session) &&
                      session.ticketSessions.map((ticketSession, indexTS) => (
                        <CalendarRow
                          key={["ts", index, indexTS].join("|")}
                          type={"ticketsession"}
                          item={ticketSession}
                          fieldToShow={priceMode}
                          highlighted={isHightlighted(product.productCode, ticketSession.ticketSessionId)}
                          changeHighligthing={() =>
                            changeRowHighlighting(product.productCode, ticketSession.ticketSessionId)
                          }
                          parentCalendars={[
                            calendar[month].precinctCalendar,
                            product.calendar,
                            session.calendar,
                          ]}
                          productCode={product.productCode}
                          productName={product.name}
                          daysLoading={daysLoading}
                          onClickInDay={onChangeInDayData}
                        />
                      ))}
                  </Fragment>
                ))
              ) : (
                <StyDivContainerLine>
                  <FxAlert variant={"info"}>
                    No hay sesiones asignadas a producto {product.productCode}
                  </FxAlert>
                </StyDivContainerLine>
              )}
            </>
          )}
        </div>
      ))}
    </CalendarTemplate>
  );
}

export default withSkeleton(CalendarSessions, CalendarSkeleton);
