export class CookieItem {
  constructor(key) {
    this.key = key;
  }

  read() {
    var nameEQ = this.key + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) {
        if (c.substring(nameEQ.length, c.length) === "") return true;
        return JSON.parse(c.substring(nameEQ.length, c.length));
      }
    }
    return null;
  }

  save(data, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = this.key + "=" + (JSON.stringify(data) || "") + expires + "; path=/";
  }

  delete() {
    document.cookie = this.key + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
}
