import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";

import { cleanUrl, cleanMarket } from "../../hooks/useCleanCacheFetch";
import { ButtonsPosition, ButtonDomain, ButtonAll } from "./CleanCachePage.styles";

import MarketChip from "@atoms/Content/MarketChip";
import ErrorModal from "@atoms/Modals/ErrorModal";
import CenterLoader from "@atoms/Loaders/CenterLoader";
import SuccessAnimation from "@atoms/Content/SuccessAnimation";
import { LoadingCenter } from "@atoms/Loaders/Loaders.styles";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import { TravCardIcon, TravBodyCard } from "@atoms/Card/FxCard";

import TextField from "@material-ui/core/TextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const MARKETS = [
  { code: "es", name: "España" },
  { code: "pt", name: "Portugal" },
  { code: "fr", name: "Francia" },
  { code: "it", name: "Italia" },
  { code: "com", name: ".com" },
];
const ALL_MARKETS = { code: "all", name: "todos los mercados" };

function CleanCachePage({}) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [valueInput, setValueInput] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    }
  }, [showSuccess]);

  const cleanUrlCache = async () => {
    if (valueInput !== "") {
      setLoading(true);
      const response = await cleanUrl(valueInput);
      if (!response.ok && response.err !== undefined) {
        setErrors({ show: true, list: response.err });
      } else {
        setShowSuccess(true);
      }
      setValueInput("");
      setLoading(false);
    }
  };

  const cleanMarketCache = async (market) => {
    const r = confirm(`Se va a proceder a borrar la caché de ${market.name}. ¿Está de acuerdo?`);
    if (r) {
      setLoading(true);
      const response = await cleanMarket(market.code);
      if (!response.ok && response.err !== undefined) {
        setErrors({ show: true, list: response.err });
      } else {
        setShowSuccess(true);
      }
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {/* Errors modal */}
      <ErrorModal
        show={errors.show}
        listErrors={errors.list}
        onClose={() => setErrors({ show: false, list: [] })}
      />

      {/* Content */}
      <ContainerFenix>
        <TravCardIcon>
          <TravBodyCard>
            {loading ? (
              <Fragment>
                <LoadingCenter>
                  Limpiando...
                  <br />
                  <CenterLoader />
                </LoadingCenter>
              </Fragment>
            ) : showSuccess ? (
              /* Mensaje Caché borrada con exito */
              <SuccessAnimation text={"Caché limpiada con éxito"} />
            ) : (
              <Fragment>
                <Row>
                  <Col xs={12} style={{ display: "flex" }}>
                    <ButtonGroup fullWidth>
                      <TextField
                        value={valueInput}
                        id="outlined-basic"
                        label="URL a limpiar"
                        placeholder="https://..."
                        variant="outlined"
                        onChange={(evt) => {
                          setValueInput(evt.target.value);
                        }}
                      />
                      <Button variant="secondary" onClick={cleanUrlCache}>
                        Limpiar
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                <ButtonsPosition>
                  {MARKETS.map((imarket) => {
                    return (
                      <div key={`market_${imarket.code}`}>
                        <ButtonDomain
                          variant="extended"
                          disableElevation
                          onClick={() => cleanMarketCache(imarket)}
                        >
                          <MarketChip market={imarket.code} showLabel={false} /> Limpiar {imarket.name}
                        </ButtonDomain>
                      </div>
                    );
                  })}
                </ButtonsPosition>
                <ButtonsPosition justify="flex-end">
                  <ButtonDomain
                    isDanger="true"
                    variant="extended"
                    disableElevation
                    onClick={() => cleanMarketCache(ALL_MARKETS)}
                  >
                    <MarketChip market={ALL_MARKETS.code} showLabel={false} />
                    Limpiar {ALL_MARKETS.name}
                  </ButtonDomain>
                </ButtonsPosition>
              </Fragment>
            )}
          </TravBodyCard>
        </TravCardIcon>
      </ContainerFenix>
    </Fragment>
  );
}

export default CleanCachePage;
