/* eslint-disable import/no-unresolved */
import React, { Fragment, useState, useEffect } from "react";
import ListEditable from "@atoms/Lists/ListEditable";
import { Row, Col, Card } from "react-bootstrap";
import { UsCardTitle, LeftEnable } from "@atoms/Card/UsCard.styles";

function OffersEditorImages({ form, onChange }) {
  return (
    <Fragment>
      <Card>
        <Card.Body>
          <UsCardTitle>
            <span>Imagenes de la oferta</span>
          </UsCardTitle>

          <Row className="justify-content-sm-center">
            <Col xs={12}>
              <ListEditable
                title={"Portada"}
                info={form.image.src === "" ? [] : [form.image.src]}
                form={form}
                typeInfo={"tick"}
                onChangeList={(img) => {
                  const nuForm = { ...form };
                  if (img.length > 1) img.shift();
                  nuForm.image.src = img.length === 0 ? "" : img[0];
                  onChange(nuForm);
                }}
              />
              <br />
            </Col>

            <Col xs={12}>
              <ListEditable
                title={"Destino Final"}
                info={form.destination.images.reduce((acc, cv) => {
                  acc.push(cv.src);
                  return acc;
                }, [])}
                form={form}
                typeInfo={"tick"}
                onChangeList={(img) => {
                  const nuForm = { ...form };
                  nuForm.destination.images = img.reduce((acc, cv) => {
                    acc.push({
                      ["src"]: cv,
                    });
                    return acc;
                  }, []);
                  onChange(nuForm);
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default OffersEditorImages;
