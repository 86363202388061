import React from "react";
import { Row, Col } from "react-bootstrap";
import { ContainerFenix } from "@atoms/Card/UsCard.styles";
import WidgetCardPhoto from "@atoms/Content/PhotoCard";
import CardTitle from "@atoms/Titles/CardTitle";

function Nevasport() {
  return (
    <ContainerFenix>
      <CardTitle title={"Nevasport"} buttons={[]} />
      <Row>
        <Col xs={12} sm={6} md={3}>
          <WidgetCardPhoto
            title={"Nevasport"}
            image={"https://tr3.traventia.com/graphic-design/sprites/temporary/cross_sellin_icon.jpg"}
            linkText={"Acceder"}
            link={"/app/marketing/nevasport/offers"}
          />
        </Col>
      </Row>
    </ContainerFenix>
  );
}

export default Nevasport;
