import React, { useEffect, useState, useCallback } from "react";
import {
  accommodationProviderStatus,
  fetchAccommodationsProviders,
  fetchAccommodationProviderByProviderAndSubprovider,
  fetchAccommodationProviderByProvider,
} from "../../hooks/useAccommodationProviders";
import FxChip from "@atoms/Content/FxChip";
import { userAuthorized } from "../../../../../entries/fenix/auth/FenixAuthContext";
import { AiOutlineEye } from "react-icons/ai";

const AccommodationsProvidersListState = () => {
  const user = userAuthorized();
  const [searchQuery, setSearchQuery] = useState("");
  const [forceReloadTable, setForceReloadTable] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [tabSelected, setTabSelected] = useState(0);
  const [modal, setModal] = useState({
    open: false,
    data: {
      subrows: [],
      row: {},
    },
  });
  const [obj, setObj] = useState({});
  const [loading, setLoading] = useState(true);

  const resetModal = () => {
    setModal({
      open: false,
      data: {
        row: {},
        subrows: [],
        // provider: "",
        // provNum: "",
        // maxCodesInASearch: "",
        // changedBy: {
        //   lm_ts: "",
        //   lm: "",
        // },
      },
    });
  };

  const getDataByProvider = (accommodationProvider) => {
    const newAcc = { ...accommodationProvider };
    // newAcc.isOnlyForPackage = "EMPTY";
    // newAcc.isOnlyForTransportPackage = "EMPTY";
    newAcc.provider = newAcc.provider;
    return newAcc;
  };

  const getDataBySubprovider = (accommodationProvider) => {
    const newAcc = { ...accommodationProvider };
    if (!newAcc.provider_copy) {
      newAcc.provider_copy = accommodationProvider.provider;
    }
    delete newAcc.provider;
    delete newAcc.provNum;
    return newAcc;
  };

  const getDataByProviderWithoutSubprovider = (accommodationProvider) => {
    const newAcc = { ...accommodationProvider };
    // newAcc.isOnlyForPackage = "EMPTY";
    // newAcc.isOnlyForTransportPackage = "EMPTY";
    return newAcc;
  };

  const getAccommodationsProviders = useCallback(async () => {
    setLoading(true);

    const response = await fetchAccommodationsProviders();

    const newList = response.accommodationsProviders
      .sort((a, b) => {
        if (a.provider.toLowerCase() < b.provider.toLowerCase()) {
          return -1;
        }
        if (a.provider.toLowerCase() > b.provider.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      .reduce(
        (accumulative, accommodationProvider) => {
          const accu = { ...accumulative };
          const confi = [];
          if (accommodationProvider.changedBy === undefined) {
            accommodationProvider.changed = "";
          } else {
            accommodationProvider.changed = getLastModified(accommodationProvider.changedBy);
          }
          // if (accommodationProvider.isOnlyForPackage) confi.push("Empaquetar");
          // if (accommodationProvider.isOnlyForTransportPackage) confi.push("Empaquetar Transporte");
          // if (accommodationProvider.isOnlyForLoggedUser) confi.push("Logueado");
          // if (accommodationProvider.isOnlyForRepeatClient) confi.push("Recurrente");

          // accommodationProvider.configuration = confi;

          if (accu.obj[accommodationProvider.provider] === undefined) {
            accu.obj[accommodationProvider.provider] = {
              row: accommodationProvider,
              subrows: [],
            };
          }
          if (accommodationProvider.subprovider !== undefined) {
            accu.obj[accommodationProvider.provider].subrows.push(accommodationProvider);
          }
          return accu;
        },
        {
          obj: {},
        }
      );

    setObj(newList.obj);
    setLoading(response.loading);
  }, []);

  const getLastModified = (changedBy) => {
    if (changedBy.disabled_by && changedBy.enabled_ts) {
      if (new Date(changedBy.enabled_ts).getTime() < new Date(changedBy.disabled_ts).getTime()) {
        return (
          <>
            <div>
              <b>Deshabilitado</b>: <br />
              {changedBy.disabled_by}
              <br />
              {new Date(changedBy.disabled_ts).toLocaleDateString()}
            </div>
            <br />
            <div>
              <b>Habilitado</b>: <br />
              {changedBy.enabled_by}
              <br />
              {new Date(changedBy.enabled_ts).toLocaleDateString()}
            </div>
          </>
        );
      } else {
        return (
          <>
            <div>
              <b>Habilitado</b>: <br />
              {changedBy.enabled_by}
              <br />
              {new Date(changedBy.enabled_ts).toLocaleDateString()}
            </div>
            <br />
            <div>
              <b>Deshabilitado</b>: <br />
              {changedBy.disabled_by}
              <br />
              {new Date(changedBy.disabled_ts).toLocaleDateString()}
            </div>
          </>
        );
      }
    } else if (changedBy.disabled_by === undefined && changedBy.enabled_by) {
      return (
        <div>
          <b>Habilitado</b>: <br />
          {changedBy.enabled_by}
          <br />
          {new Date(changedBy.enabled_ts).toLocaleDateString()}
        </div>
      );
    } else if (changedBy.enabled_by === undefined && changedBy.disabled_by) {
      return (
        <div>
          <b>Deshabilitado</b>: <br />
          {changedBy.disabled_by}
          <br />
          {new Date(changedBy.disabled_ts).toLocaleDateString()}
        </div>
      );
    }
  };

  useEffect(() => {
    getAccommodationsProviders();
  }, [getAccommodationsProviders, forceReloadTable]);

  const INFO_DATA = {
    0: {
      title: "alojamientos",
      query: {
        path: "/api/v1/moebius-bc/providers/accommodations",
        params: "_sort=provider&_sort=subprovider",
      },
    },
  };

  const actionView = async (rowData) => {
    const newRow = { ...rowData };
    delete newRow.subrows;
    setModal({
      open: true,
      data: { subrows: rowData.subrows, row: newRow },
    });
  };

  const handleEnable = async (accommodationProvider, isModal) => {
    const newAccommodationProvider = { ...accommodationProvider };
    if (newAccommodationProvider.provider_copy) {
      newAccommodationProvider.provider = newAccommodationProvider.provider_copy;
      // delete newAccommodationProvider.provider_copy;
    }
    const response = await accommodationProviderStatus(
      newAccommodationProvider,
      newAccommodationProvider.enabled ? "disable" : "enable",
      user.profile.email
    );
    if (response.ok) {
      setAlertData(`Proveedor ${!accommodationProvider.enabled ? "habilitado" : "deshabilitado"} con exito.`);
      setAlertOpen(true);

      const newObj = { ...obj };
      // Si tiene copy es por que viene de un subproveedor
      if (newAccommodationProvider.provider_copy) {
        if (newObj[newAccommodationProvider.provider].subrows.length > 0) {
          const subrows = [];

          for (const subrow_1 of newObj[newAccommodationProvider.provider].subrows) {
            if (accommodationProvider.subprovider === subrow_1.subprovider) {
              let response = await fetchAccommodationProviderByProviderAndSubprovider(
                newAccommodationProvider.provider,
                subrow_1.subprovider
              );
              subrow_1.enabled = !newAccommodationProvider.enabled;
              subrow_1.changed = getLastModified(response.accommodationsProviders[0].changedBy);
            }
            subrows.push(subrow_1);
          }
          newObj[newAccommodationProvider.provider].subrows = subrows;
          if (isModal) {
            setModal({
              open: true,
              data: newObj[newAccommodationProvider.provider],
            });
          }
        }
      } else {
        newObj[newAccommodationProvider.provider].row.enabled = !newAccommodationProvider.enabled;
        const respo = await fetchAccommodationProviderByProvider(newAccommodationProvider.provider);
        newObj[newAccommodationProvider.provider].row.changed = getLastModified(
          respo.accommodationsProviders[0].changedBy
        );
        if (newObj[newAccommodationProvider.provider].subrows.length > 0) {
          const subrows = [];
          for (const subrow of newObj[newAccommodationProvider.provider].subrows) {
            let response = await fetchAccommodationProviderByProviderAndSubprovider(
              newAccommodationProvider.provider,
              subrow.subprovider
            );
            subrow.enabled = !newAccommodationProvider.enabled;
            subrow.changed = getLastModified(response.accommodationsProviders[0].changedBy);
            subrows.push(subrow);
          }
          newObj[newAccommodationProvider.provider].subrows = subrows;
          if (isModal) {
            setModal({
              open: true,
              data: newObj[newAccommodationProvider.provider],
            });
          }
        }

        // if (newObj[newAccommodationProvider.provider].subrows.length > 0) {
        //   newObj[newAccommodationProvider.provider].subrows.forEach((subrows) => {
        //     subrows.enabled = !newAccommodationProvider.enabled;
        //   });
        // }
      }
      setObj(newObj);
    }
    return response;
  };

  const handleTabChange = (event, newValue) => {
    setTabSelected(newValue);
    setForceReloadTable(tabSelected + 1);
  };

  const translateConf = (conf) => {
    switch (conf) {
      case "package":
        return "Empaquetar";
      case "transportPackage":
        return "Empaquetar Transporte";
      case "loggedUser":
        return "Logueado";
      case "repeatClient":
        return "Recurrente";
      case "metas":
        return "Metas";
      default:
        return "";
    }
  };

  const tableHead = [
    { code: "provNum", name: "Numero de proveedor", type: "string" },
    { code: "provider", name: "Proveedor", type: "string" },
    {
      code: "configuration",
      name: "Configuracion",
      type: "customFromRow",
      customFromRow: (rowData) => {
        const row = rowData.rowData;
        const configs = Object.keys(row.configuration);
        return (
          <>
            {configs.map((conf) => {
              if (row.configuration[conf] === "ONLY")
                return (
                  <FxChip
                    key={Math.random()}
                    label={translateConf(conf)}
                    colour={"#c9f7f5"}
                    colourtext={"#06a29b"}
                    size="small"
                  />
                );
              else if (row.configuration[conf] === "EXCLUDE")
                return (
                  <FxChip
                    key={Math.random()}
                    label={`No ${translateConf(conf)}`}
                    colour={"#ff000091"}
                    colourtext={"#a20606"}
                    size="small"
                  />
                );
            })}
          </>
        );
      },
    },
    { code: "subprovider", name: "Subproveedor", type: "string", filterable: false },
    { code: "maxCodesInASearch", name: "MaxCodes", type: "string" },
    { code: "changed", name: "Habilitado/Deshabilitado", type: "string", filterable: false },
    {
      code: "enabled",
      name: "Habilitado",
      type: "boolean",
      acceptsEmpty: true,
      func: async (row) => {
        return await handleEnable(row, false);
      },
    },
  ];
  const tableHeadModal = [
    { code: "subprovider", name: "Subproveedor", type: "string" },
    {
      code: "configuration",
      name: "Configuracion",
      type: "customFromRow",
      customFromRow: (rowData) => {
        const row = rowData.rowData;
        if (row.configuration === undefined) return <></>;
        const configs = Object.keys(row.configuration);
        return (
          <>
            {configs.map((conf) => {
              if (row.configuration[conf] === "ONLY")
                return (
                  <FxChip
                    key={Math.random()}
                    label={translateConf(conf)}
                    colour={"#c9f7f5"}
                    colourtext={"#06a29b"}
                    size="small"
                  />
                );
              else if (row.configuration[conf] === "EXCLUDE")
                return (
                  <FxChip
                    key={Math.random()}
                    label={`No ${translateConf(conf)}`}
                    colour={"#ff000091"}
                    colourtext={"#a20606"}
                    size="small"
                  />
                );
            })}
          </>
        );
      },
    },
    { code: "maxCodesInASearch", name: "MaxCodes", type: "string" },
    { code: "changed", name: "Habilitado/Deshabilitado", type: "string" },
    {
      code: "enabled",
      name: "Habilitado",
      type: "boolean",
      acceptsEmpty: true,
      func: async (row) => {
        return await handleEnable(row, true);
      },
    },
  ];

  const actions = [{ name: "Visualizar", icon: <AiOutlineEye />, func: actionView }];

  return {
    alertData,
    alertOpen,
    setAlertOpen,
    tableHead,
    tableHeadModal,
    actions,
    searchQuery,
    setSearchQuery,
    forceReloadTable,
    tabSelected,
    handleTabChange,
    INFO_DATA,
    loading,
    obj,
    getDataByProvider,
    getDataBySubprovider,
    getDataByProviderWithoutSubprovider,
    modal,
    actionView,
    resetModal,
  };
};

export default AccommodationsProvidersListState;
