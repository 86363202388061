import React, { useState } from "react";
import ButtonCustom from "@atoms/Buttons/ButtonCustom";
import { FiCheck, FiMinusSquare, FiPlusSquare } from "react-icons/fi";
import FxAlert from "@atoms/Alerts/FxAlert";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MdFilterList } from "react-icons/md";
import { HeaderScheduleStyle } from "./OfferBoat.styles";

function OfferBoatGridOptionsConfiguration({
  title,
  alert,
  withButton,
  titleWithoutBg,
  propertyKey,
  listOptions = [],
  allowMultipleChoice = false,
  indexBoatInfo,
  boatsInfo,
  setBoatsInfo,
  defaultSelectAll = false,
  filterActive = false,
}) {
  const [checkSelectAll, setCheckSelectAll] = useState(defaultSelectAll);
  const [filter, setFilter] = useState("");

  const filterList = (e) => {
    let value = "";
    if (e.target.value.length >= 3) {
      value = e.target.value;
    }
    setFilter(value.toLowerCase());
  };

  const applyFilter = (model) => {
    return Object.values(model).toString().toLowerCase().includes(filter.toLowerCase());
  };

  const addOptions = (option) => {
    if (Array.isArray(boatsInfo)) {
      if (allowMultipleChoice) {
        if (propertyKey.indexOf(".") > -1) {
          const keys = propertyKey.split(".");
          boatsInfo[indexBoatInfo][keys[0]][keys[1]].push(option);
          setBoatsInfo([...boatsInfo]);
        } else {
          boatsInfo[indexBoatInfo][propertyKey].push(option);
          setBoatsInfo([...boatsInfo]);
        }
      } else {
        boatsInfo[indexBoatInfo][propertyKey] = option;
        setBoatsInfo([...boatsInfo]);
      }
    } else if (allowMultipleChoice) {
      if (propertyKey.indexOf(".") > -1) {
        const keys = propertyKey.split(".");
        boatsInfo[keys[0]][keys[1]].push(option);
        setBoatsInfo({ ...boatsInfo });
      } else {
        boatsInfo[propertyKey].push(option);
        setBoatsInfo({ ...boatsInfo });
      }
    } else {
      setBoatsInfo({ ...boatsInfo, [propertyKey]: option });
    }
  };

  const deleteOptionInMultipleChoice = (option) => {
    if (Array.isArray(boatsInfo)) {
      if (allowMultipleChoice) {
        if (propertyKey.indexOf(".") > -1) {
          const keys = propertyKey.split(".");
          const exist = boatsInfo[indexBoatInfo][keys[0]][keys[1]].filter((item) => item !== option);
          boatsInfo[indexBoatInfo][keys[0]][keys[1]] = exist;
          setBoatsInfo([...boatsInfo]);
        }
        if (propertyKey.indexOf(".") === -1) {
          const exist = boatsInfo[indexBoatInfo][propertyKey].filter((item) => item !== option);
          boatsInfo[indexBoatInfo][propertyKey] = exist;
          setBoatsInfo([...boatsInfo]);
        }
      }
    }
  };

  const checkOptionIfAlreadyExistInMultipleChoice = (option) => {
    if (Array.isArray(boatsInfo)) {
      if (allowMultipleChoice) {
        if (propertyKey.indexOf(".") > -1) {
          const keys = propertyKey.split(".");
          const exist = boatsInfo[indexBoatInfo][keys[0]][keys[1]].filter((item) => item === option);
          return exist.length > 0;
        }
        if (propertyKey.indexOf(".") === -1) {
          const exist = boatsInfo[indexBoatInfo][propertyKey].filter((item) => item === option);
          return exist.length > 0;
        }
      }
    }
    return false;
  };

  const addOrDelete = (option) => {
    const check = checkOptionIfAlreadyExistInMultipleChoice(option);
    if (check) {
      deleteOptionInMultipleChoice(option);
    } else {
      addOptions(option);
    }
  };

  return (
    <div>
      {title !== undefined && (
        <HeaderScheduleStyle
          style={{
            justifyContent: "space-between",
            marginBottom: "10px",
            backgroundColor: `${titleWithoutBg !== undefined && "transparent"}`,
          }}
        >
          <h6 style={{ margin: "0", fontWeight: "bold" }}>{title}</h6>

          {withButton ? (
            <ButtonCustom
              variant={"outlined"}
              size="small"
              icon={checkSelectAll ? <FiMinusSquare /> : <FiPlusSquare />}
              onClick={() => {
                listOptions.forEach((option) => {
                  addOrDelete(option.code);
                  setCheckSelectAll(!checkSelectAll);
                });
              }}
            >
              {checkSelectAll ? "Deseleccionar todo" : "Seleccionar todo"}
            </ButtonCustom>
          ) : (
            ""
          )}
        </HeaderScheduleStyle>
      )}

      {alert !== undefined && (
        <div style={{ marginBottom: "10px" }}>
          <FxAlert variant="info">{alert}</FxAlert>
        </div>
      )}

      {filterActive && (
        <div style={{ maxWidth: "300px" }}>
          <TextField
            size="small"
            label="Filtrar"
            onChange={filterList}
            margin="normal"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdFilterList />
                </InputAdornment>
              ),
            }}
          />
          {/* 
            <input type="text" onChange={filterList}></input> */}
        </div>
      )}
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        {/* TODO: AMPARO PONER INPUT BONITO */}

        {listOptions.map((option, index) => {
          if (applyFilter(option.name)) {
            return (
              <div key={`${option.code}-${index}`}>
                <ButtonCustom
                  variant={checkOptionIfAlreadyExistInMultipleChoice(option.code) ? "contained" : "outlined"}
                  color={checkOptionIfAlreadyExistInMultipleChoice(option.code) ? "primary" : ""}
                  onClick={() => {
                    addOrDelete(option.code);
                  }}
                >
                  {option?.name?.es ||
                    option?.name?.pt ||
                    option?.name?.it ||
                    option?.name?.fr ||
                    option?.name}
                  {checkOptionIfAlreadyExistInMultipleChoice(option.code) ? <FiCheck /> : ""}
                </ButtonCustom>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default OfferBoatGridOptionsConfiguration;
