import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ButtonGroup, Button } from "@material-ui/core";
import MarketChip from "@atoms/Content/MarketChip";

function SelectMarket({ selection, onChangeMarket, withConfirm, confirmMessage, fullWidth = false }) {
  const markets = ["es", "pt", "fr", "it"];

  const [market, setMarket] = useState(selection || "es");

  useEffect(() => {
    if (selection !== undefined) {
      setMarket(selection);
    }
  }, [selection]);

  return (
    <Fragment>
      <ButtonGroup
        className="market-no-select-class"
        size="large"
        color="primary"
        aria-label="marketSelector"
        fullWidth={fullWidth}
      >
        {markets.map((imarket) => {
          return (
            <Button
              key={`market_${imarket}`}
              color={imarket === market ? "secondary" : "primary"}
              className={imarket === market ? "market-select-class" : "market-no-select-class"}
              onClick={() => {
                const confirm = !withConfirm || window.confirm(confirmMessage);
                if (imarket !== undefined && confirm) {
                  setMarket(imarket);
                  onChangeMarket(imarket);
                }
              }}
            >
              <MarketChip market={imarket} />
            </Button>
          );
        })}
      </ButtonGroup>
    </Fragment>
  );
}

SelectMarket.propTypes = {
  selection: PropTypes.string,
  onChangeMarket: PropTypes.func,
};

export default SelectMarket;
