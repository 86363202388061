const useAffiliatesReportsActions = ({ setAffiliatesReportsToManage, deleteAffiliatesReports }) => {
  const manageAffiliatesReportsAction = async (event) => {
    await setAffiliatesReportsToManage(event.id);
    cb();
  };
  const deleteAffiliatesReportsAction = (cb) => async (event) => {
    const confirmation = confirm("¿Está seguro de eliminar esta affiliatesReports?");
    if (confirmation) {
      await deleteAffiliatesReports(event.id);
    }
    cb();
  };

  return {
    manageAffiliatesReportsAction,
    deleteAffiliatesReportsAction,
  };
};

export default useAffiliatesReportsActions;
