import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import BricksHub from "./bricks/BricksHub";
import BrickEditor from "./bricks/BrickEditor";
import BrickList from "./bricks/BrickList";
import HomeContent from "./home-content/HomeContent";
import ActionsPage from "./contact-center/Actions/index";
import ActionEditorPage from "./contact-center/ActionEditor/index";
import JSONPagesList from "./json-pages/json-pagesList/index";
import JSONPagesEditorPage from "./json-pages/json-pagesEditor/index";
import { PagesAliasesFormPage, PagesAliasesListPage } from "./pages-aliases/index";
import CookiesPageList from "./cookies/cookiesList/index";
import CookiesGroupPageList from "./cookies/cookiesGroupList/index";

function RoutesContenido({ parentURL = "/app" }) {
  const mainUrl = `${parentURL}/content`;
  return (
    <Fragment>
      <Route exact path={`${mainUrl}/bricks`}>
        <BricksHub />
      </Route>
      {/* Templates */}
      <Route exact path={`${mainUrl}/bricks/template`}>
        <BrickList type={"template"} />
      </Route>
      <Route path={`${mainUrl}/bricks/template/:code`}>
        <BrickEditor type={"template"} />
      </Route>
      {/* Bricks */}
      <Route exact path={`${mainUrl}/bricks/brick`}>
        <BrickList type={"brick"} />
      </Route>
      <Route path={`${mainUrl}/bricks/brick/:code`}>
        <BrickEditor type={"brick"} />
      </Route>
      {/* JSON Pages */}
      <Route exact path={`${mainUrl}/json-pages/`}>
        <JSONPagesList type={"json-pages"} />
      </Route>
      <Route exact path={`${mainUrl}/json-pages/:tld/:id`}>
        <JSONPagesEditorPage type={"json-page"} />
      </Route>
      {/* Pages Aliases */}
      <Route exact path={`${mainUrl}/pages-aliases/`}>
        <PagesAliasesListPage />
      </Route>
      {/* Cookies */}
      <Route exact path={`${mainUrl}/cookies-group/list`}>
        <CookiesGroupPageList />
      </Route>
      <Route exact path={`${mainUrl}/cookies/list`}>
        <CookiesPageList />
      </Route>
      {/* Home */}
      <Route path={`${mainUrl}/home`}>
        <HomeContent />
      </Route>

      {/* contac-center */}
      <Route exact path={`${mainUrl}/contact-center/actions`} component={ActionsPage} />
      <Route exact path={`${mainUrl}/contact-center/actions/:ActionCode`} component={ActionEditorPage} />
    </Fragment>
  );
}

export default RoutesContenido;
