import React from "react";
import { Grid, TextField } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// Atoms
import { TravCard, TravBodyCard, TravTitleCard } from "@atoms/Card/FxCard";
// Buttons
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import ChannelConfigurationSelector from "../shared/ChannelConfigurationSelector/ChannelConfigurationSelector";
// Icons
import { IconEdit, IconNew } from "./PrecinctChannelsSection.styles";

function PrecinctChannelsSectionForm({ channel, onChange, precinctLength, deletePrecinct }) {
  return (
    <Grid item xs={12}>
      <TravCard>
        <TravTitleCard>
          <Grid container direction="row" justify={"space-between"} spacing={2}>
            <Grid item>
              {channel.type === "new" && <IconNew>Nuevo</IconNew>}
              {channel.type === "edit" && <IconEdit>Editado</IconEdit>}
            </Grid>
            <Grid style={{marginTop:"10px"}} item>
              <ButtonDelete                
                size="small"
                key={"btW"}
                onClick={() => {
                  deletePrecinct();
                }}
              />
            </Grid>
          </Grid>
        </TravTitleCard>
        <TravBodyCard>
          <Grid container justify="space-between" direction="row" spacing={3}>
            <Grid item xs={6}>
              <TextField
                label="Proveedor *"
                value={channel.channel.provider}
                onChange={(nVal) => {
                  const nuChannel = { ...channel };
                  nuChannel.channel.provider = nVal.target.value;
                  onChange(nuChannel);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Merchant"
                value={channel.channel.merchant}
                onChange={(nVal) => {
                  const nuChannel = { ...channel };
                  nuChannel.channel.merchant = nVal.target.value;
                  onChange(nuChannel);
                }}
              />
            </Grid>
            <ChannelConfigurationSelector channel={channel} onChange={onChange} isPrecinct={true} />
          </Grid>
        </TravBodyCard>
      </TravCard>
    </Grid>
  );
}

PrecinctChannelsSectionForm.propTypes = {};

export default PrecinctChannelsSectionForm;
