import axios from "axios";
import { useState, useEffect } from "react";

const url = "/api/v1/product-bc/jack-sparrow-rules";

export const deleteRule = async (rule) => {
  let response = {};
  try {
    response = await axios.delete(`${url}/${rule.id}`);
    response.ok = true;
  } catch (error) {
    response.ok = false;
    response.error = error.message;
  }

  return response;
};

export const putRule = async (rule) => {
  let response = {};
  try {
    response = await axios.put(`${url}/${rule.id}`, rule);
    response.ok = true;
  } catch (error) {
    response.ok = false;
    response.error = error.message;
  }
  return response;
};

export const getRule = (id) => {
  const [data, setData] = useState({ loading: true, rule: {} });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, rule: {} };
        const idRule = id || "NEW";
        const response = await axios.get(`${url}/${idRule}`);

        if (response.status === 200) {
          thestate = { loading: false, rule: response.data };
        }
        setData(thestate);
      } catch (error) {
        setData({ loading: false, rule: {} });
        console.error(error);
      }
    };

    if (data.loading) {
      fetchData();
    }
  }, [data.loading, id]);
  return { loadingRule: data.loading, rule: data.rule };
};

export const useJackSparrowRuleList = async (page, search = "", limit = 10) => {
  let rules = {
    loadingList: true,
    list: [],
    total: 0,
    status: 0,
  };
  const generateQuery = (page, search) => {
    const query = {
      _offset: page,
      _limit: limit,
    };
    if (search) {
      query["id[regex]"] = search;
    }
    return url + "?" + new URLSearchParams(query).toString();
  };
  const fetchData = async (page, search) => {
    try {
      const response = await axios.get(generateQuery(page, search));
      if (response.status === 200) {
        return {
          loadingList: false,
          list: response.data.data,
          status: response.status,
          total: response.data.total,
        };
      }
    } catch (error) {
      return { loadingList: false, list: [], total: 0 };
    }
    return { loadingList: true, list: [], total: 0 };
  };
  rules = await fetchData(page, search);
  return rules;
};
