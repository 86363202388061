/* eslint-disable no-use-before-define */

export default function generatePendingXML(dataAccounts) {
  const doc = document.implementation.createDocument("", "", null);

  const Document = doc.createElement("Document");
  Document.setAttribute("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance");
  Document.setAttribute("xmlns:xsd", "http://www.w3.org/2001/XMLSchema");
  Document.setAttribute("xmlns", "urn:iso:std:iso:20022:tech:xsd:pain.001.001.03");

  const CstmrCdtTrfInitn = doc.createElement("CstmrCdtTrfInitn");

  // Header
  CstmrCdtTrfInitn.appendChild(getXMLHeader(doc, dataAccounts));

  // Accounts
  dataAccounts.forEach((account) => CstmrCdtTrfInitn.appendChild(getXMLAccount(doc, account)));

  Document.appendChild(CstmrCdtTrfInitn);

  doc.appendChild(Document);

  // console.log(doc);
  download(doc);
}

function elementTagWithInner(doc, tag, inner) {
  const docTag = doc.createElement(tag);
  docTag.innerHTML = parseInvalidChars(inner);
  return docTag;
}

function getXMLHeader(doc, dataAccounts) {
  const GrpHdr = doc.createElement("GrpHdr");

  const date = new Date();
  const dt = [date.getFullYear(), `0${date.getMonth() + 1}`.slice(-2), `0${date.getDate()}`.slice(-2)];
  const hr = [
    `0${date.getHours()}`.slice(-2),
    `0${date.getMinutes()}`.slice(-2),
    `0${date.getSeconds()}`.slice(-2),
  ];
  const todayT = [dt.join("-"), hr.join(":")].join("T");

  const t1 = {
    MsgId: [dt.join(""), hr.join("")].join(""),
    CreDtTm: todayT,
    NbOfTxs: dataAccounts.length,
    CtrlSum: (dataAccounts.reduce((sum, d) => sum + Math.abs(d.amount.amount), 0) / 100).toFixed(2),
  };
  Object.keys(t1).forEach((t) => GrpHdr.appendChild(elementTagWithInner(doc, t, t1[t])));
  const InitgPty = doc.createElement("InitgPty");
  InitgPty.appendChild(elementTagWithInner(doc, "Nm", "YOUBID, S.L."));
  const Id = doc.createElement("Id");
  const OrgId = doc.createElement("OrgId");
  const Othr = doc.createElement("Othr");
  Othr.appendChild(elementTagWithInner(doc, "Id", "B86700424000"));
  OrgId.appendChild(Othr);
  Id.appendChild(OrgId);
  InitgPty.appendChild(Id);

  GrpHdr.appendChild(InitgPty);

  return GrpHdr;
}

function getXMLAccount(doc, account) {
  // Información del pago
  const PmtInf = doc.createElement("PmtInf");
  PmtInf.appendChild(elementTagWithInner(doc, "PmtInfId", account.refundedFromPaymentId.replaceAll("-", "")));
  PmtInf.appendChild(elementTagWithInner(doc, "PmtMtd", "TRF"));
  PmtInf.appendChild(elementTagWithInner(doc, "BtchBookg", "false"));
  PmtInf.appendChild(elementTagWithInner(doc, "NbOfTxs", "1"));
  PmtInf.appendChild(elementTagWithInner(doc, "CtrlSum", parseAmount(account.amount.amount)));

  // Información del tipo de pago
  const PmtTpInf = doc.createElement("PmtTpInf");
  PmtTpInf.appendChild(elementTagWithInner(doc, "InstrPrty", "NORM"));
  const SvcLvl = doc.createElement("SvcLvl");
  SvcLvl.appendChild(elementTagWithInner(doc, "Cd", "SEPA"));
  PmtTpInf.appendChild(SvcLvl);

  PmtInf.appendChild(PmtTpInf);
  PmtInf.appendChild(elementTagWithInner(doc, "ReqdExctnDt", account.datecreation.split("T")[0]));

  // Ordenante
  const Dbtr = doc.createElement("Dbtr");
  Dbtr.appendChild(elementTagWithInner(doc, "Nm", "YOUBID, S.L."));
  PmtInf.appendChild(Dbtr);

  // Cuenta del ordenante
  const DbtrAcct = doc.createElement("DbtrAcct");
  const Id = doc.createElement("Id");
  Id.appendChild(elementTagWithInner(doc, "IBAN", "ES8601822329950204391942"));
  DbtrAcct.appendChild(Id);
  PmtInf.appendChild(DbtrAcct);

  // Entidad del ordenante
  const DbtrAgt = doc.createElement("DbtrAgt");
  const FinInstnId = doc.createElement("FinInstnId");
  FinInstnId.appendChild(elementTagWithInner(doc, "BIC", "BBVAESMMXXX"));
  DbtrAgt.appendChild(FinInstnId);
  PmtInf.appendChild(DbtrAgt);

  PmtInf.appendChild(elementTagWithInner(doc, "ChrgBr", "SLEV"));

  const CdtTrfTxInf = doc.createElement("CdtTrfTxInf");

  // Identificación del pago
  const idPayment = account.paymentId.replaceAll("-", "");
  const PmtId = doc.createElement("PmtId");
  PmtId.appendChild(elementTagWithInner(doc, "InstrId", idPayment));
  PmtId.appendChild(elementTagWithInner(doc, "EndToEndId", idPayment));
  CdtTrfTxInf.appendChild(PmtId);

  // Amount
  const Amt = doc.createElement("Amt");
  const InstdAmt = elementTagWithInner(doc, "InstdAmt", parseAmount(account.amount.amount));
  InstdAmt.setAttribute("Ccy", "EUR");
  Amt.appendChild(InstdAmt);
  CdtTrfTxInf.appendChild(Amt);

  // Entidad del beneficiario
  //   const CdtrAgt = doc.createElement("CdtrAgt");
  //   const FinInstnId2 = doc.createElement("FinInstnId");
  //   FinInstnId2.appendChild(elementTagWithInner(doc, "BIC", "???????"));
  //   CdtrAgt.appendChild(FinInstnId2);
  //   CdtTrfTxInf.appendChild(CdtrAgt);

  // Beneficiario
  const Cdtr = doc.createElement("Cdtr");
  Cdtr.appendChild(elementTagWithInner(doc, "Nm", account.dataAccount.holder));
  CdtTrfTxInf.appendChild(Cdtr);

  // Cuenta del ordenante
  const CdtrAcct = doc.createElement("CdtrAcct");
  const Id1 = doc.createElement("Id");
  Id1.appendChild(elementTagWithInner(doc, "IBAN", account.dataAccount.bankAccount.toUpperCase()));
  CdtrAcct.appendChild(Id1);
  CdtTrfTxInf.appendChild(CdtrAcct);

  // Concepto
  const RmtInf = doc.createElement("RmtInf");
  RmtInf.appendChild(elementTagWithInner(doc, "Ustrd", `${account.bookref} Traventia`));
  CdtTrfTxInf.appendChild(RmtInf);

  PmtInf.appendChild(CdtTrfTxInf);
  return PmtInf;
}

function parseAmount(amountInCurrency) {
  const nuAmount = (Math.abs(amountInCurrency) / 100).toFixed(2);
  return nuAmount.toString();
}

function download(xmlDoc) {
  const date = new Date();
  const filename = `TransferenciasPendientes${[
    [date.getFullYear(), `0${date.getMonth() + 1}`.slice(-2), `0${date.getDate()}`.slice(-2)].join("_"),
    [
      `0${date.getHours()}`.slice(-2),
      `0${date.getMinutes()}`.slice(-2),
      `0${date.getSeconds()}`.slice(-2),
    ].join(""),
  ].join("__")}`;

  const xmlString = [
    "\uFEFF",
    '<?xml version="1.0" encoding="utf-8" ?>',
    new XMLSerializer().serializeToString(xmlDoc),
  ].join("");

  const blob = new Blob([xmlString], { type: "data:text/plain" });
  const objectURL = window.URL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
  const element = document.createElement("a");
  element.setAttribute("href", objectURL);
  element.setAttribute("download", `${filename}.XML`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
}

function parseInvalidChars(stringToParse) {
  let nuString = stringToParse.toString().replaceAll("Ñ", "N");
  nuString = nuString.replaceAll("ñ", "n");
  nuString = nuString.replaceAll("ç", "c");
  nuString = nuString.replaceAll("Ç", "C");
  nuString = nuString.replace(/[^0-9A-Za-z/?:() -.,+]/g, "");

  return nuString;
}
