import React, { Fragment, useState } from "react";
// import PropTypes from "prop-types";
import moment from "moment";
import { TableCell, Tooltip } from "@material-ui/core";
import FxSwitch from "@atoms/Switches/FxSwitch";
import FxChip from "@atoms/Content/FxChip";
import MarketChip from "@atoms/Content/MarketChip";
import ButtonCopyToClipboard from "@atoms/Buttons/ButtonCopyToClipboard";
import Money from "@atoms/Money/Money";
import { getCellContentWithFieldCode } from "./shared/utils";

function FenixTableCell({ rowData, interfaceForThisCell, cellContentDefault }) {
  const cellContent = getCellContentWithFieldCode(rowData, interfaceForThisCell, cellContentDefault);
  const [loadingCell, setLoadingCell] = useState(false);

  const onChangeBooleanAsync = async () => {
    setLoadingCell(true);
    const { ok } = await interfaceForThisCell.func(rowData);
    if (ok) {
      setLoadingCell(false);
    } else {
      alert("Error al ejecutar esta accion");
    }
  };

  switch (interfaceForThisCell.type) {
    case "string":
      return (
        <TableCell
          align="left"
          // style={{maxWidth: "200px",overflow: "hidden"}}
        >
          {cellContent}
        </TableCell>
      );

    case "numeric":
      return <TableCell align="right">{cellContent}</TableCell>;

    case "money":
      return (
        <TableCell align="right">
          <Money amount={cellContent} />
        </TableCell>
      );

    case "moneyamount":
      return (
        <TableCell align="right">
          <Money amount={cellContent.amount} />
        </TableCell>
      );

    case "date":
      let date = moment(cellContent).format(interfaceForThisCell.format || "DD/MM/YYYY");
      if (date === "Fecha inválida") {
        date = interfaceForThisCell.invalidText;
      }
      return <TableCell align="right">{date}</TableCell>;

    case "timestamp":
      return (
        <TableCell align="right">
          {moment(cellContent).format(interfaceForThisCell.format || "hh:mm:ss DD/MM/YYYY")}
        </TableCell>
      );

    case "boolean":
      if (cellContent === "EMPTY") {
        return <TableCell align="center" className="status-cell"></TableCell>;
      }
      const ncellContent = cellContent === "-" ? false : cellContent;
      let textToShow = ncellContent ? "✔" : "✖";
      if (interfaceForThisCell.text) {
        textToShow = ncellContent ? interfaceForThisCell.text.y : interfaceForThisCell.text.n;
      }

      let color = ncellContent ? "green" : "red";
      if (interfaceForThisCell.color) {
        color = ncellContent ? interfaceForThisCell.color.y : interfaceForThisCell.color.n;
      }

      return (
        <TableCell align="center" className="status-cell">
          {interfaceForThisCell.func ? (
            <>
              {loadingCell ? (
                <>Cargando...</>
              ) : (
                <FxSwitch
                  labels={interfaceForThisCell.labels}
                  status={rowData[interfaceForThisCell.code]}
                  onChange={onChangeBooleanAsync}
                />
              )}
            </>
          ) : (
            <FxChip label={textToShow} colour={color} />
          )}
        </TableCell>
      );

    case "market":
      return (
        <TableCell align="center">
          <MarketChip market={cellContent} />
        </TableCell>
      );

    case "label":
      let labelText = cellContent;
      let labelColour = "grey";
      if (interfaceForThisCell.translation[cellContent] !== undefined) {
        labelText = interfaceForThisCell.translation[cellContent].name;
        labelColour = interfaceForThisCell.translation[cellContent].colour;
      }

      // RMR: Trampeja para no tener chip
      if (labelColour === "no-background") {
        return (
          <TableCell align="center" className="status-cell">
            {labelText}
          </TableCell>
        );
      }

      return (
        <TableCell align="center" className="status-cell">
          <FxChip label={labelText} colour={labelColour} />
        </TableCell>
      );

    case "translation":
      return (
        <TableCell align="left" className="status-cell">
          {interfaceForThisCell.translation[cellContent]}
        </TableCell>
      );

    case "tooltiplist":
      return (
        <TableCell align="left" className="status-cell">
          <Tooltip
            title={cellContent.map((x) => (
              <div key={Math.random() + "-" + x}>{x}</div>
            ))}
          >
            <span style={{ cursor: "pointer" }}>
              {cellContent[0]}
              {cellContent.length > 1 ? ", ..." : ""}
            </span>
          </Tooltip>
        </TableCell>
      );

    case "customFromRow":
      const CustomFromRow = interfaceForThisCell.customFromRow;
      return (
        <TableCell align="left" className="status-cell">
          <CustomFromRow rowData={rowData} />
        </TableCell>
      );

    case "tags":
      const tags = !Array.isArray(cellContent) ? [cellContent] : cellContent;
      return (
        <TableCell align="left">
          {tags.map((tag, i) => (
            <Fragment key={`Tag${i}`}>
              <FxChip
                style={{ maxWidth: "130px" }}
                label={tag}
                colour="#c9f7f5"
                colourtext={"#06a29b"}
                size="small"
              />
            </Fragment>
          ))}
        </TableCell>
      );

    case "image":
      const [url] = cellContent.split("?");
      return (
        <TableCell align="center" style={{ maxWidth: "100px", overflow: "hidden" }}>
          <img
            style={{ width: "75px", height: "50px", objectFit: "contain" }}
            src={`${url}?thumb=true`}
            alt={cellContent}
          />
        </TableCell>
      );

    case "url":
      return (
        <TableCell
        // style={{ maxWidth: "300px", overflow: "hidden" }}
        >
          <div style={{ marginBottom: "5px" }}>
            <a href={cellContent} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          </div>

          <ButtonCopyToClipboard size="small" textToCopy={cellContent} />
        </TableCell>
      );

    case "html":
      return (
        <TableCell
        // style={{ maxWidth: "300px", overflow: "hidden" }}
        >
          <div dangerouslySetInnerHTML={{ __html: cellContent }} />
        </TableCell>
      );

    default:
      return (
        <TableCell align="center">
          {"⛔"}
          <span style={{ fontSize: "10px" }}>
            <br />
            {interfaceForThisCell.type}
          </span>
        </TableCell>
      );
  }
}

FenixTableCell.propTypes = {};
FenixTableCell.defaultProps = {};

export default FenixTableCell;
