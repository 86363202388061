/* eslint-disable import/no-unresolved */
import { useState, useEffect, useCallback } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { LS_getItem } from "@lib/storage/localStorage";
import { getOffer, getOfferAsync, putOffer } from "../../hooks/useOfferFetch";
import { useOfferSearchs, getOfferSearchs } from "../../hooks/useOfferSearchs";
import { getAllTicketProducts } from "../../hooks/useTicketProductsFetch";
import { regenerateOffers } from "../../hooks/useGenManager";
import { useSocialNetworkPost } from "../../hooks/useSocialNetworkFetch";

function OfferListEditor() {
  const { offerCode } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const { loadingOffer, offer } = getOffer(offerCode);
  const { loadingSearchs, searchs } = useOfferSearchs(offerCode);
  const [errors, setErrors] = useState({ show: false, list: [] });
  const [timer, setTimer] = useState(null);
  const [form, setForm] = useState(offer);
  const isEdition = Object.keys(offer).length > 0;
  const [counterSearches, setCounterSearches] = useState(0);
  const isDuplication = new URLSearchParams(search).get("duplicate") !== null;
  const [ticketsProducts, setTicketsProducts] = useState({});

  const handleTicketProductsFetch = useCallback(
    async (nuValue, returnOK = false) => {
      const nuValueEdit = { ...nuValue };

      if (!nuValueEdit.precinctId) {
        nuValueEdit.precinctId = nuValueEdit.code;
      }
      if (Object.keys(ticketsProducts).indexOf(nuValueEdit.precinctId) === -1) {
        const reponse = await getAllTicketProducts(nuValueEdit.precinctId);
        const obj = { [nuValueEdit.precinctId]: reponse.list };
        setTicketsProducts({ ...ticketsProducts, ...obj });
        if (returnOK) {
          return obj;
        }
      }

      return null;
    },
    [ticketsProducts]
  );

  useEffect(() => {
    const counter = searchSum(searchs);
    setCounterSearches(counter);
  }, [loadingSearchs]);

  // useEffect(() => {
  //   if(loadingSocialNetworkPost ){
  //     setSocialNetworks(socialNetworkPost);
  //   }
  //   }, [loadingSocialNetworkPost]);

  useEffect(() => {
    const searchTicketsProduct = async () => {
      const promises = await Promise.all(
        offer.services.tickets.map((t) => handleTicketProductsFetch(t, true))
      );
      const objFinal = promises.reduce((accu, tk) => ({ ...accu, ...tk }), {});

      setTicketsProducts(objFinal);
    };

    if (isEdition) {
      setForm(offer);
      searchTicketsProduct();
    } else {
      setForm({
        idOffer: offerCode,
        title: "",
        market: LS_getItem("market") === null ? "es" : LS_getItem("market"),
        enable: false,
        highlighted: false,
        relevanceScoring: 0,
        socialNetwork: {
          title: "",
          subtitle: "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOffer]);

  const handleTicketsProducts = (nuValue) => {
    setTicketsProducts(nuValue);
  };

  const searchSum = (s) => {
    const counter = Object.keys(s).reduce((acc, cv) => {
      if (cv !== "offersCalendar") {
        return acc + s[cv].length;
      }
      return acc;
    }, 0);

    return counter;
  };

  const saveOfferForm = async () => {
    if (form.flash.enable) {
      if (form.flash.date) {
        delete form.flash.date;
      }
      if (form.flash.time) {
        delete form.flash.time;
      }
    }
    if (typeof form.relevanceScoring === "string" && form.enable) {
      form.relevanceScoring = parseInt(form.relevanceScoring, 10);
    }
    const reponse = await putOffer(form);
    const offerSearchs = await getOfferSearchs(offerCode);
    if (Object.keys(reponse).length === 0) {
      alert(`Error al guardar.`);
    } else {
      const txt = ["Oferta guardada.", `Recalculando busquedas.`];
      alert(txt.join("\n"));
    }
    setCounterSearches(searchSum(offerSearchs.searchs));
  };

  const onChangeForm = (nuForm) => {
    setForm(nuForm);
  };

  const getColorTextCounterSearches = () => {
    return "red";
    // if (counterSearches < valueMax) {
    //   return "#25db6d";
    // } else if (counterSearches < valueMax) {
    //   return "#ffae17";
    // } else {
    //   return "red";
    // }
  };

  const getColorTextCounterShortDescription = (valueMax = 155) => {
    if (form.shortDescription.length < valueMax * 0.75) {
      return "#25db6d";
    }
    if (form.shortDescription.length < valueMax * 0.95) {
      return "#ffae17";
    }
    return "red";
  };

  const onChangeOccupancy = (nuForm) => {
    setForm(nuForm);
  };

  const backToTable = () => {
    history.push(`/app/product/offers/list`);
  };

  const counterCharactersShortDescription = (shortDescription, valueMax) => {
    const regex = /<[^>]*>[\s\S]*?|<\/?[^>]+>/g;
    let result = 0;
    let match;

    if (shortDescription.length <= valueMax) {
      result = valueMax - shortDescription.length;
    }

    while ((match = regex.exec(shortDescription)) !== null) {
      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      result += match[0].length;
    }

    return result;
  };

  const reloadCalendarOffert = async () => {
    const resultConfirm = confirm(
      "❗ Este proceso va a lanzar búsquedas reales ❗\nSi puedes evitar hacerlo mejor. Ten en cuenta que una vez que lo lances puede tardar hasta 30 minutos."
    );
    if (resultConfirm) {
      await regenerateOffers([{ idOffer: offerCode }]);
      alert(`La oferta ${offerCode} ha sido lanzada 🚀`);
    }
  };

  /**
   * DRM. Añado esta sección de código para inicializar campos nuevos que vamos metiendo
   * ahroa mientras desarrollamos
   */

  if (form.relevanceScoring === undefined) {
    form.relevanceScoring = 0;
  }
  if (form.content === undefined) {
    form.content = { includes: [], excludes: [], info_bricks: [] };
  }
  if (form.tags === undefined) {
    form.tags = { topic: [], dates: [], location: [] };
  }

  if (form.services === undefined) {
    form.services = { accommodations: [], tickets: [], boats: [], vacationalPackage: [] };
  }

  if (form.highlighted === undefined) {
    form.highlighted = false;
  }

  if (form.datePublication === undefined) {
    form.datePublication = new Date();
  }

  if (form.destination === undefined) {
    form.destination = { images: [] };
  }

  if (form.image === undefined) {
    form.image = { src: "" };
  }

  if (form.shortDescription === undefined) {
    form.shortDescription = "";
  }

  if (form.socialNetwork === undefined) {
    form.socialNetwork = {
      title: "",
      subtitle: "",
    };
  }

  if (form.flash === undefined) {
    form.flash = {
      enable: false,
      datetime: "",
    };
  } else if (form.flash.datetime === undefined) {
    let datetime = new Date();
    if (form.flash.date && form.flash.date !== "" && form.flash.time && form.flash.time !== "") {
      const date = form.flash.date.split("-");
      const time = form.flash.time.split(":");
      datetime = new Date(date[0], date[1], date[2], time[0], time[1]);
    }
    form.flash.datetime = datetime.toString();
  }
  if (form.offerCriteria === undefined) {
    form.offerCriteria = {
      nights: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      defaultOccupancy: {
        adults: 2,
        kids: [],
      },
      occupancy: {
        types: {
          kids: {
            accept: true,
            minAge: 2,
            maxAge: 12,
            refAge: 6,
          },
          babies: {
            accept: true,
            minAge: 0,
            maxAge: 1,
            refAge: 1,
          },
        },
        occupants: {
          accept: [2], // De 1 a 8,
          custom: [],
        },
      },
      dates: [],
    };
  }
  return {
    offerCode,
    counterSearches,
    form,
    setForm,
    loadingOffer,
    offer,
    setErrors,
    errors,
    isEdition,
    isDuplication,
    saveOfferForm,
    backToTable,
    onChangeForm,
    getColorTextCounterSearches,
    onChangeOccupancy,
    ticketsProducts,
    handleTicketsProducts,
    handleTicketProductsFetch,
    counterCharactersShortDescription,
    getColorTextCounterShortDescription,
    reloadCalendarOffert,
  };
}

export default OfferListEditor;
