import axios from "axios";
import React, { useState, useEffect } from "react";

const URL_CALLS = "/api/v1/booking-bc/call-center-services/calls";
const URL_EMERGENCYCALLS = "/api/v1/booking-bc/call-center-services/emergencyCalls";
const URL_QUEUES = "/api/v1/booking-bc/call-center-services/queues";
const URL_AGENTS = "/api/v1/booking-bc/call-center-services/agents";

export const getCalls = () => {
  const [data, setData] = useState({ loading: true, dataCalls: [] });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, dataCalls: [] };
        const response = await axios.get(`${URL_CALLS}`);
        const responseEmergency = await axios.get(`${URL_EMERGENCYCALLS}`);
        if (response.status === 200 && responseEmergency.status === 200) {
          const idEcalls = responseEmergency.data.data.reduce((acc, cv) => {
            acc.push(cv.idCall);
            return acc;
          }, []);
          thestate = {
            loading: false,
            dataCalls: response.data.data
              .map((cv) => {
                cv.isEmergencyCall = idEcalls.includes(cv.idCall);
                cv.type = cv.isEmergencyCall ? "A" : "B";
                cv.idCall = cv.idCall.slice(-10);
                cv.dateTime = `${new Date(cv.created_on).toLocaleDateString()} ${new Date(
                  cv.created_on
                ).toLocaleTimeString()}`;
                let nuPhone = cv.phone.split("");
                nuPhone.splice(3, 0, " ");
                cv.phone = nuPhone.join("");
                return cv;
              }, [])
              .reverse(),
          };
        }
        setData(thestate);
      } catch (error) {
        setData({ loading: false, dataCalls: [] });
        console.error(error);
      }
    };

    if (data.loading) {
      fetchData();
    }
  }, [data.loading]);
  return { loadingCalls: data.loading, dataCalls: data.dataCalls };
};

export const getEmergencyCalls = () => {
  const [data, setData] = useState({ loading: true, dataCalls: [] });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, dataCalls: [] };
        const response = await axios.get(`${URL_EMERGENCYCALLS}`);
        if (response.status === 200) {
          thestate = {
            loading: false,
            dataCalls: response.data.data,
          };
        }
        setData(thestate);
      } catch (error) {
        setData({ loading: false, dataCalls: [] });
        console.error(error);
      }
    };

    if (data.loading) {
      fetchData();
    }
  }, [data.loading]);
  return { loadingCalls: data.loading, dataCalls: data.dataCalls };
};

export const getAgent = (idAgent) => {
  const [data, setData] = useState({ loading: true, agent: {} });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, agent: {} };
        const response = await axios.get(`${URL_AGENTS}/${idAgent}`);
        if (response.status === 200) {
          thestate = { loading: false, agent: response.data };
        }
        setData(thestate);
      } catch (error) {
        setData({ loading: false, agent: {} });
        console.error(error);
      }
    };

    if (data.loading) {
      fetchData();
    }
  }, [data.loading]);
  return { loadingAgent: data.loading, dataAgent: data.agent };
};

export const useAgents = async () => {
  try {
    let thestate = { loadingAgents: true, listAgents: [], ok: false };
    const response = await axios.get(`${URL_AGENTS}`);
    if (response.status === 200) {
      thestate = {
        loading: false,
        listAgents: response.data.data.map((agent) => {
          const nuAgent = agent;
          nuAgent.rolAgentName = agent.rolAgent === "super" ? "Supervisor" : "Agente";
          nuAgent.name = agent.idAgent
            .split("@")[0]
            .split(".")
            .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
            .join(" ");
          return nuAgent;
        }),
      };
    }
    return thestate;
  } catch (error) {
    throw error;
  }
};

export const getAgents = () => {
  const [data, setData] = useState({ loading: true, agents: [] });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, agents: [] };
        const response = await axios.get(`${URL_AGENTS}`);
        if (response.status === 200) {
          thestate = {
            loading: false,
            agents: response.data.data.map((agent) => {
              const nuAgent = agent;
              nuAgent.rolAgentName = agent.rolAgent === "super" ? "Supervisor" : "Agente";
              nuAgent.name = agent.idAgent
                .split("@")[0]
                .split(".")
                .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                .join(" ");
              return nuAgent;
            }),
          };
        }
        setData(thestate);
      } catch (error) {
        setData({ loading: false, agents: [] });
        console.error(error);
      }
    };

    if (data.loading) {
      fetchData();
    }
  }, [data.loading]);
  return { loadingAgents: data.loading, dataAgents: data.agents };
};

export const getAgentsInQueue = () => {
  const [queueData, setQueueData] = useState({ loading: true, queue: [] });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let thestate = { loading: true, queue: [] };
        const response = await axios.get(`${URL_QUEUES}/emergencies/es`);
        if (response.status === 200) {
          thestate = { loading: false, queue: response.data.agentsAvailable };
        }
        setQueueData(thestate);
      } catch (error) {
        setQueueData({ loading: false, queue: [] });
        console.error(error);
      }
    };

    if (queueData.loading) {
      fetchData();
    }
  }, [queueData.loading]);
  return { loadingQueue: queueData.loading, dataQueue: queueData.queue };
};

export const upsertAgent = async (agent) => {
  let response = {};
  try {
    response = await axios.put(`${URL_AGENTS}/${agent.idAgent}`, agent);
  } catch (error) {
    response = { status: 400 };
    console.error(error);
  }
  return response;
};
