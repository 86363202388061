import React, { useState, useEffect } from "react";
import { TravCard, TravBodyCard } from "@atoms/Card/FxCard";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import { FiClock, FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { IoBoatOutline } from "react-icons/io5";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import OfferBoatCheckElementConfiguration from "./OfferBoatCheckElementConfiguration";
import OfferBoatGridOptionsConfiguration from "./OfferBoatGridOptionsConfiguration";
import OfferBoatIntervalShedule from "./OfferBoatIntervalShedule";
import {
  TitleListServiceFerry,
  AlertNoFerry,
  ChipNameRoute,
  ScheduleContainerStyle,
  HeaderScheduleStyle,
  HeaderSecundaryScheduleStyle,
  CardScheduleStyle,
  IconExcludeStyle,
  IconExcludeBarStyle,
} from "./OfferBoat.styles";

const OffersBoatList = ({
  form,
  handle,
  setLoadingConfiguration,
  routePorts,
  accommodationFares,
  destinations,
}) => {
  const [boatsInfo, setBoatsInfo] = useState(form?.services?.boats);
  const [providerInfoRadioButton, setProviderInfoRadioButton] = useState("excludeProviders");

  const [defaultRoutes, setDefaultRoutes] = useState([]);
  const [providerByRoutes, setProvidersByRoutes] = useState([]);
  const [destinationName, setDestinationName] = useState("");

  useEffect(() => {
    if (routePorts.list.length > 0) {
      const destinationCodes = routePorts.list
        .filter((route) => route.destination_code === boatsInfo[0].destination)
        .map((item) => item.code);
      setDefaultRoutes(routePorts.list.filter((r) => destinationCodes.includes(r.code)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routePorts.list]);

  useEffect(() => {
    if (defaultRoutes.length > 0) {
      setProvidersByRoutes(
        ...defaultRoutes.map((route) =>
          Object.entries(route.providers)
            .map((item) => {
              if (item[1].length > 0) {
                return {
                  name: { es: item[0] },
                  code: item[0],
                };
              }
            })
            .filter((filtered) => filtered !== undefined)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRoutes.length]);

  useEffect(() => {
    if (destinations.length > 0) {
      setDestinationName(destinations.filter((des) => des.code === boatsInfo[0].destination)[0]?.name?.es);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinations]);

  const handleChangeProviders = (e) => {
    setProviderInfoRadioButton(e.target.value);
  };

  return (
    <>
      {form.services.boats.length === 0 && <AlertNoFerry variant="info">Ningún Ferry añadido</AlertNoFerry>}

      {boatsInfo.length > 0 && (
        <>
          {boatsInfo.map((item, index) => {
            return (
              <TravCard key={`boat-${index}`}>
                <TravBodyCard>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: "20px" }}>
                    <TitleListServiceFerry>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                          Destino seleccionado &nbsp;
                          <ChipNameRoute> {destinationName}</ChipNameRoute>
                        </div>
                        <ButtonDelete
                          size={"small"}
                          onClick={() => {
                            boatsInfo.splice(index, 1);
                            setBoatsInfo([]);
                            form.services.boats = [];
                            handle({ ...form });
                            setLoadingConfiguration(true);
                          }}
                        />
                      </div>
                    </TitleListServiceFerry>

                    <OfferBoatGridOptionsConfiguration
                      title="Seleccionar rutas"
                      propertyKey="routes"
                      listOptions={defaultRoutes}
                      allowMultipleChoice={true}
                      indexBoatInfo={index}
                      boatsInfo={boatsInfo}
                      setBoatsInfo={setBoatsInfo}
                      defaultSelectAll={true}
                      filterActive={true}
                    />
                    {/* Excluir - Limitar proveedores */}
                    <ScheduleContainerStyle>
                      <HeaderScheduleStyle>
                        <IoBoatOutline />
                        <h6 style={{ margin: "0", fontWeight: "bold" }}>Excluir o limitar proveedores</h6>
                      </HeaderScheduleStyle>
                      <div style={{ padding: "10px" }}>
                        <RadioGroup
                          row
                          name="executionRules"
                          value={providerInfoRadioButton}
                          onChange={handleChangeProviders}
                        >
                          <span className="providersclass">
                            <FormControlLabel
                              value="excludeProviders"
                              control={<Radio color="primary" />}
                              label={
                                <div>
                                  <IconExcludeStyle>
                                    <IconExcludeBarStyle />
                                  </IconExcludeStyle>
                                  Excluir proveedores
                                </div>
                              }
                            />
                          </span>
                          <FormControlLabel
                            value="forceProviders"
                            control={<Radio color="primary" />}
                            label="Limitar proveedores"
                          />
                        </RadioGroup>
                        {providerInfoRadioButton === "excludeProviders" ? (
                          <>
                            <OfferBoatGridOptionsConfiguration
                              titleWithoutBg
                              title="Excluir proveedores"
                              alert="Esta acción excluye los proveedores seleccionados para que no salgan en la oferta. Si no seleccionas ninguno por defecto saldán todos."
                              propertyKey="excludeProviders"
                              listOptions={providerByRoutes}
                              allowMultipleChoice={true}
                              indexBoatInfo={index}
                              boatsInfo={boatsInfo}
                              setBoatsInfo={setBoatsInfo}
                              filterActive={true}
                            />
                          </>
                        ) : (
                          <>
                            <OfferBoatGridOptionsConfiguration
                              titleWithoutBg
                              title="Limitar proveedores"
                              alert="Esta acción fuerza a que salgan los proveedores seleccionados. Si no seleccionas ninguno por defecto saldrán todos."
                              propertyKey="forceProviders"
                              listOptions={providerByRoutes}
                              allowMultipleChoice={true}
                              indexBoatInfo={index}
                              boatsInfo={boatsInfo}
                              setBoatsInfo={setBoatsInfo}
                              filterActive={true}
                            />
                          </>
                        )}
                      </div>
                    </ScheduleContainerStyle>
                    {/* Limitar configurar intervalos de horarios */}
                    <ScheduleContainerStyle>
                      <HeaderScheduleStyle>
                        <FiClock />
                        <h6 style={{ margin: "0", fontWeight: "bold" }}>Limitar Horarios</h6>{" "}
                      </HeaderScheduleStyle>
                      <CardScheduleStyle>
                        <div>
                          <HeaderSecundaryScheduleStyle>
                            <FiArrowRight /> Limitar Horarios ida
                          </HeaderSecundaryScheduleStyle>
                          <div
                            style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}
                          >
                            <OfferBoatIntervalShedule
                              title="Horario previsto de salida Ida"
                              propertyKey="goDepartureHourInterval"
                              indexBoatInfo={index}
                              boatsInfo={boatsInfo}
                              setBoatsInfo={setBoatsInfo}
                            />
                            <OfferBoatIntervalShedule
                              title="Horario previsto de llegada Ida"
                              propertyKey="goArrivalHourInterval"
                              indexBoatInfo={index}
                              boatsInfo={boatsInfo}
                              setBoatsInfo={setBoatsInfo}
                            />
                          </div>
                        </div>
                        <div>
                          <HeaderSecundaryScheduleStyle>
                            <FiArrowLeft />
                            Limitar Horarios Vuelta
                          </HeaderSecundaryScheduleStyle>
                          <div
                            style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}
                          >
                            <OfferBoatIntervalShedule
                              title="Horario previsto de salida Vuelta"
                              propertyKey="backDepartureHourInterval"
                              indexBoatInfo={index}
                              boatsInfo={boatsInfo}
                              setBoatsInfo={setBoatsInfo}
                            />
                            <OfferBoatIntervalShedule
                              title="Horario previsto de llegada Vuelta"
                              propertyKey="backArrivalHourInterval"
                              indexBoatInfo={index}
                              boatsInfo={boatsInfo}
                              setBoatsInfo={setBoatsInfo}
                            />
                          </div>
                        </div>
                      </CardScheduleStyle>
                    </ScheduleContainerStyle>
                    {/* Limitar acomodaciones ferries */}
                    <ScheduleContainerStyle>
                      <HeaderScheduleStyle>
                        <MdAirlineSeatReclineNormal />
                        <h6 style={{ margin: "0", fontWeight: "bold" }}>Limitar Acomodaciones ferry</h6>{" "}
                      </HeaderScheduleStyle>
                      <CardScheduleStyle>
                        <div>
                          <HeaderSecundaryScheduleStyle>
                            <FiArrowRight /> Acomodación ida
                          </HeaderSecundaryScheduleStyle>
                          <div style={{ padding: "10px" }}>
                            <OfferBoatGridOptionsConfiguration
                              propertyKey="accommodationFilter.go"
                              listOptions={accommodationFares.list}
                              allowMultipleChoice={true}
                              indexBoatInfo={index}
                              boatsInfo={boatsInfo}
                              setBoatsInfo={setBoatsInfo}
                              filterActive={true}
                            />
                          </div>
                        </div>
                        <div>
                          <HeaderSecundaryScheduleStyle>
                            <FiArrowLeft />
                            Acomodación Vuelta
                          </HeaderSecundaryScheduleStyle>
                          <div style={{ padding: "10px" }}>
                            <OfferBoatGridOptionsConfiguration
                              propertyKey="accommodationFilter.back"
                              listOptions={accommodationFares.list}
                              allowMultipleChoice={true}
                              indexBoatInfo={index}
                              boatsInfo={boatsInfo}
                              setBoatsInfo={setBoatsInfo}
                              filterActive={true}
                            />
                          </div>
                        </div>
                      </CardScheduleStyle>
                    </ScheduleContainerStyle>
                    {/* Vehículo por defecto */}
                    <OfferBoatCheckElementConfiguration
                      title="Oferta solo con vehículo:"
                      propertyKey="offerOnlyWithVehicle"
                      indexBoatInfo={index}
                      boatsInfo={boatsInfo}
                      setBoatsInfo={setBoatsInfo}
                    />
                    {/* Precio con Vehículo por defecto */}
                    {/* TODO:AMA: si esta seleccionado oferta solo con vehiculo automáticamente el precio por defecto por vehículo deberia o desaparacer o estar en disable. Me decanto más por que aparezca en disable */}
                    <OfferBoatCheckElementConfiguration
                      title="Activar Precio por defecto con Vehiculo:"
                      propertyKey="defaultPriceWithVehicle"
                      indexBoatInfo={index}
                      boatsInfo={boatsInfo}
                      setBoatsInfo={setBoatsInfo}
                    />
                  </div>
                </TravBodyCard>
              </TravCard>
            );
          })}
        </>
      )}
    </>
  );
};

export default OffersBoatList;
