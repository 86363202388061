import React from "react";
import ProductConfigurationsSection from "@organisms/Ticketing/ProductConfigurations/ProductConfigurationsSection";
import { useParams } from "react-router-dom";
import ChannelPageTemplate from "./ChannelPageTemplate";

const ProductConfigurationsPage = () => {
  const { precinctCode } = useParams();
  return (
    <ChannelPageTemplate>
      <ProductConfigurationsSection precinctCode={precinctCode} />
    </ChannelPageTemplate>
  );
};

export default ProductConfigurationsPage;
