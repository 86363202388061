import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { TextField, Fab, FormControl, InputLabel, Select } from "@material-ui/core";
import moment from "moment";
import { SeparatorLine, TitleWithSeparator } from "@atoms/Card/UsCard.styles";
import FxDatePickersFromTo from "@atoms/Pickers/FxDatePickersFromTo";
import InputNumber from "@atoms/Inputs/InputNumber";

import CenterLoaderCircle from "@atoms/Loaders/CenterLoader";
import DoblementeApiSearchTableResults from "./DoblementeApiSearchTableResults";
import { serviceTypes, searchProductsAPI } from "./useDoblementeAPI";

const DoblementeApiSearchInterface = ({ provider }) => {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchResult, setSearchResult] = useState({ results: [] });
  const [searchParams, setSearchParams] = useState({
    datei: moment().format("YYYY-MM-DD"),
    dateo: moment().format("YYYY-MM-DD"),
    serviceType: "A",
    product: "",
    sector: "",
    occupancy: "",
    adl: 1,
    chl: 0,
    chla: [],
  });

  async function searchAPIDoblemente() {
    setLoadingSearch(true);
    const sp = { ...searchParams, provider };
    const as = [...Array(parseInt(sp.adl, 10))].map(() => "ADL-30");
    const cs = sp.chla.map((c) => `CHL-${c}`);
    sp.occupancy = [...as, ...cs].join("|");

    const res = await searchProductsAPI(sp);
    setSearchResult(res);
    setLoadingSearch(false);
  }

  return (
    <Row className="justify-content-md-center">
      <Col xs={12}>
        <Card>
          <Card.Header>
            <h3>Buscador de entradas</h3>
          </Card.Header>
          <Card.Body>
            <TitleWithSeparator>Tipo de servicio</TitleWithSeparator>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Tipo de servicio</InputLabel>
              <Select
                native
                value={searchParams.serviceType}
                onChange={(nVal) => {
                  setSearchParams({ ...searchParams, serviceType: nVal.target.value });
                }}
                label="Tipo de servicio"
              >
                {serviceTypes.map((st, index) => (
                  <option key={`st${index}`} value={st.code}>{`${st.code} - ${st.name}`}</option>
                ))}
              </Select>
            </FormControl>

            <SeparatorLine />
            <Row>
              <FxDatePickersFromTo
                labelFrom={"Desde"}
                dateFromInitial={searchParams.datei}
                labelTo={"Hasta"}
                dateToInitial={searchParams.dateo}
                onChange={({ datei, dateo }) => {
                  setSearchParams({ ...searchParams, datei, dateo });
                }}
              />
            </Row>
            <hr />
            <Row>
              <Col>
                <TextField
                  label="Número de sector"
                  value={searchParams.sector}
                  onChange={(nVal) => {
                    setSearchParams({ ...searchParams, sector: nVal.target.value });
                  }}
                  disabled={provider !== "CALDEA"}
                />
              </Col>
              <Col>
                <TextField
                  label="Código de producto"
                  value={searchParams.product}
                  onChange={(nVal) => {
                    setSearchParams({ ...searchParams, product: nVal.target.value });
                  }}
                  helperText="Puedes poner varios separados por * pero han de ser del mismo sector y tipo de servicio"
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <InputNumber
                  label="Adultos"
                  value={searchParams.adl}
                  onChange={(nVal) => {
                    setSearchParams({ ...searchParams, adl: nVal });
                  }}
                />
              </Col>
              <Col>
                <InputNumber
                  label="Niños"
                  value={searchParams.chl}
                  min={0}
                  onChange={(nVal) => {
                    if (nVal) {
                      const nChl = parseInt(nVal);
                      if (nChl <= 0) {
                        setSearchParams({ ...searchParams, chl: nChl, chla: [] });
                      } else {
                        let nChla = new Array(nChl).fill(8);
                        nChla = nChla.map((e, ia) => {
                          if (searchParams.chla[ia] === undefined) {
                            return 8;
                          }
                          return searchParams.chla[ia];
                        });
                        setSearchParams({ ...searchParams, chl: nChl, chla: nChla });
                      }
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              {Array.from(Array(parseInt(searchParams.chl, 10))).map((e, ia) => {
                return (
                  <Col xs={3} key={`edad${ia}`} style={{ paddingTop: "10px" }}>
                    <InputNumber
                      label={`Edad ${ia + 1}`}
                      value={searchParams.chla[ia]}
                      onChange={(nVal) => {
                        const sp = { ...searchParams };
                        sp.chla[ia] = nVal;
                        setSearchParams(sp);
                      }}
                      min={1}
                      max={17}
                      error={searchParams.chla[ia] > 17}
                    />
                  </Col>
                );
              })}
            </Row>
            <hr />
            <Row>
              <Col>
                <Fab
                  style={{ width: "100%" }}
                  variant="extended"
                  size="large"
                  color="secondary"
                  aria-label="add"
                  onClick={searchAPIDoblemente}
                >
                  Buscar
                </Fab>
              </Col>
            </Row>

            {loadingSearch ? (
              <CenterLoaderCircle />
            ) : (
              <DoblementeApiSearchTableResults searchResult={searchResult} />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default DoblementeApiSearchInterface;
