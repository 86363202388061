import React, { Fragment, useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AiOutlineClose } from "react-icons/ai";

import ComboTitleAndURLInput from "./ComboTitleAndURLInput";
import withDraggableList from "@atoms/Lists/withDraggableList";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
/* Styles */
import {
  BoxContentConfiguration,
  BoxContentElements,
  NumberSlide,
  ElementsConfiguration,
} from "./SpeedLinksConfiguration.styles";

const SpeedLinksConfiguration = ({ index, item, onChangeItem }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChangeAccordion = (nu) => {
    setExpanded(nu);
  };

  return (
    <BoxContentConfiguration>
      <Fragment>
        <BoxContentElements>
          <ElementsConfiguration>
            <ComboTitleAndURLInput
              blockId={`block-${index}`}
              labels={["Título del bloque", "Enlace del titulo"]}
              item={item}
              onChangeItem={(e) => onChangeItem(e)}
            />
          </ElementsConfiguration>
        </BoxContentElements>
        <ElementsConfiguration>
          <Accordion expanded={expanded} onChange={() => handleChangeAccordion(!expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <span>Enlaces</span>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "inline-block", width: "100%" }}>
                <ButtonAdd
                  onClick={() => {
                    item.links.push({ name: "", url: "" });
                    onChangeItem(item);
                  }}
                >Añadir enlace</ButtonAdd>

                <List style={{ width: "100%" }}>
                  {item.links.map((link, i) => {
                    const k = `block${index}-link-${i}`;
                    return (
                      <ListItem key={k} style={{ width: "100%" }}>
                        <ComboTitleAndURLInput
                          blockId={k}
                          labels={[`Texto enlace ${i + 1}`, `URL enlace ${i + 1}`]}
                          item={link}
                          onChangeItem={(e) => {
                            item.links[i] = e;
                            onChangeItem(item);
                          }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              item.links.splice(i, 1);
                              onChangeItem(item);
                            }}
                          >
                            <AiOutlineClose />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </AccordionDetails>
          </Accordion>
        </ElementsConfiguration>
      </Fragment>
    </BoxContentConfiguration>
  );
};

export default withDraggableList(SpeedLinksConfiguration);
