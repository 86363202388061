import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import SelectorWithInputQuery from "@molecules/Selectors/SelectorWithInputQuery";

const InputBookref = ({ fields, onSelect }) => {
  const [elements, setElements] = useState([]);
  const [bookrefSelected, setBookrefSelected] = useState(null);

  const onChangeQuery = async (query) => {
    if (query.length < 3) {
      return;
    }

    let books = [];
    try {
      const qFields = [...new Set([...fields, "bookref", "email"])];

      const { status, data } = await axios.get("/api/v1/booking-bc/projections/books", {
        params: {
          "bookref[regex]": query.toUpperCase(),
          _fields: qFields.join(","),
          _limit: 10,
          _offset: 0,
        },
      });

      if (status === 200) {
        const { data: bdata } = data;
        books = bdata.map((book) => {
          return {
            ...book,
            text: [book.bookref, book.email].join(" - "),
          };
        });
      }
    } catch (e) {
      console.error(e);
    }

    setElements(books);
  };

  const selectBookref = (newBook) => {
    if (bookrefSelected !== newBook.bookref) {
      setBookrefSelected(newBook.bookref);
      onSelect(newBook);
    }
  };

  return (
    <SelectorWithInputQuery
      additionalFormBorderProps={{ width: "100%" }}
      elements={elements}
      textOfAoutside={bookrefSelected}
      onSelect={selectBookref}
      onChangeQuery={onChangeQuery}
      placeholder={"Busca un codigo de reserva"}
      icon={"search"}
    />
  );
};

InputBookref.propTypes = {};
InputBookref.defaultProps = { fields: ["bookref", "email"] };

export default InputBookref;
