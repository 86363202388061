import React, { useState, useEffect, useCallback } from "react";
import { fetchProducts } from "../../Products/useProducts";
import ButtonSelected from "@atoms/Buttons/ButtonSelected";
import ButtonAdd from "@atoms/Buttons/ButtonAdd";
import { fetchBookingGroups } from "../../BookingGroups/useBookingGroups";
import { uuid_v4 } from "@lib/helpers/uuid_v4";
import ButtonDelete from "@atoms/Buttons/ButtonDelete";
import { getChannelAsQueryParms } from "../../Calendar/utils";

function BookingGroupConfigurationSelector({ channel, onChange }) {
  const [precinctCode, setPrecinctCode] = useState(channel.precinctCode);
  const [bookingGroups, setBookingGroups] = useState([]);
  const [bookingGroupsChanged, setBookingGroupsChanged] = useState();
  const [productList, setProductList] = useState([]);
  const fetchProductList = useCallback(async () => {
    const products = await fetchProducts({ params: { precinctCode } });
    setProductList(products);
  }, [precinctCode]);
  const fetchBookingGroup = useCallback(async () => {
    const bookingGr = await fetchBookingGroups({
      params: {
        "group.precinctCode": channel.precinctCode,
        ...getChannelAsQueryParms(channel.channel, "channel"),
      },
    });
    setBookingGroups(bookingGr);
  }, [precinctCode]);
  useEffect(() => {
    fetchProductList();
    fetchBookingGroup();
  }, [precinctCode]);

  useEffect(() => {
    if (bookingGroupsChanged > 0) onChange("bookingGroup", bookingGroups);
  }, [bookingGroupsChanged]);
  const changeBookingGroups = (bookingGroup, index, product) => {
    const selected = bookingGroup.group.some((pro) => {
      return pro.productCode === product.code;
    });
    if (!selected) {
      const bG = bookingGroups.slice();
      bG[index].group.push({ precinctCode, productCode: product.code });
      setBookingGroups(bG);
      if (bG[index].group.length > 0) setBookingGroupsChanged(Math.random());
    } else {
      const bG = bookingGroups.slice();
      bG[index].group = bG[index].group.filter((pro) => {
        return pro.productCode !== product.code;
      });
      setBookingGroups(bG);
      if (bG[index].group.length > 0) setBookingGroupsChanged(Math.random());
    }
  };
  const addBookingGroup = () => {
    if (bookingGroups.length === 0) {
      const bG = bookingGroups.slice();
      bG.push({ id: uuid_v4(), group: [], channel: channel.channel });
      setBookingGroups(bG);
    } else {
      setBookingGroups([]);
      setBookingGroupsChanged(Math.random());
    }
  };
  return (
    <>
      {bookingGroups.length === 0 ? (
        <ButtonAdd
          key={"buton_add"}
          size="small"
          onClick={() => {
            addBookingGroup();
          }}
        >
          Añadir grupo de reserva
        </ButtonAdd>
      ) : (
        <ButtonDelete
          key={"button_delete"}
          size="small"
          onClick={() => {
            addBookingGroup();
          }}
        >
          Eliminar grupo de reserva
        </ButtonDelete>
      )}
      {bookingGroups.length > 0 &&
        bookingGroups.map((bookingGroup, index) => {
          return (
            <div key={bookingGroup.id}>
              {productList.length &&
                productList.map((product) => (
                  <ButtonSelected
                    selected={bookingGroup.group.some((pro) => {
                      return pro.productCode === product.code;
                    })}
                    onClick={() => {
                      changeBookingGroups(bookingGroup, index, product);
                    }}
                    key={product.code}
                  >
                    {product.code}
                  </ButtonSelected>
                ))}
            </div>
          );
        })}
    </>
  );
}

export default BookingGroupConfigurationSelector;
