import axios from "axios";
import { v4 } from "uuid";

export const fetchCheckoutRequirements = async () => {
  try {
    const response = await axios.get(`/product/axtools/getCheckoutRequirementsServices`);
    let list = [];
    if (response.status === 200 && response.data.length > 0) {
      list = response.data;
    }

    // const list = [
    //   {
    //     code: "ChRS1002",
    //     enable: false,
    //     name: "TEST",
    //     pcodes: ["PARQUEWARNER-1", "PARQUEWARNER-X"],
    //     requirements: [{ code: "birthday" }],
    //     tld: ["es", "pt", "fr", "it"],
    //     needpassage: false,
    //   },
    //   {
    //     code: "ChRS1004",
    //     enable: true,
    //     name: "alhambra",
    //     pcodes: ["VISITAALHAMBRA2-GUIADA", "VISITAALHAMBRA2-TARDE"],
    //     requirements: [
    //       { code: "birthday" },
    //       { code: "country" },
    //       { code: "document", options: ["%ID", "PAS"] },
    //       { code: "passport_number" },
    //     ],
    //     tld: ["es", "pt"],
    //     needpassage: true,
    //   },
    //   {
    //     code: "ChRS1005",
    //     enable: true,
    //     name: "Islas Cies",
    //     pcodes: ["ISLASCIES-CIES", "ISLASCIES-CIES2"],
    //     requirements: [
    //       { code: "birthday" },
    //       { code: "country" },
    //       { code: "document", options: ["%ID", "PAS"] },
    //       { code: "passport_number" },
    //     ],
    //     tld: ["es", "pt"],
    //     needpassage: true,
    //   },
    // ];

    return { loading: false, list };
  } catch (error) {
    console.error(error);
    return { loading: false, list: [] };
  }
};

export const fetchSingleCheckoutRequirement = async (code) => {
  try {
    const response = await axios.get(`/product/axtools/getSingleCheckoutRequirementsServices/${code}`);

    let requirement = {};
    if (response.status === 200) {
      requirement = response.data;
    }

    // const requirement = {
    //   code: "ChRS1005",
    //   enable: true,
    //   name: "Islas Cies",
    //   pcodes: ["ISLASCIES-CIES", "ISLASCIES-CIES2"],
    //   requirements: [
    //     { code: "birthday" },
    //     { code: "country" },
    //     { code: "document", options: ["%ID", "PAS"] },
    //     { code: "passport_number" },
    //   ],
    //   tld: ["es", "pt"],
    //   needpassage: true,
    // };

    return { loading: false, requirement };
  } catch (error) {
    console.error(error);
    return { loading: false, requirement: {} };
  }
};

export const saveCheckoutRequirement = async (checkoutRequirement) => {
  return await axios.post(`/product/axtools/postCheckoutRequirementsServices`, checkoutRequirement);
};

export const deleteCheckoutRequirement = async (code) => {
  try {
    await axios.delete(`/product/axtools/removeCheckoutRequirementsServices/${code}`);
    return;
  } catch (e) {
    alert(e);
  }
};
