// useFetch.js
import { useEffect, useState } from "react";
import axios from "axios";

const url = "/api/v1/ticketing-bc/providers";

export const serviceTypes = [
  { code: "A", name: "Actividades" },
  { code: "ABN", name: "Abonos" },
  { code: "C", name: "Clases" },
  { code: "EV2", name: "Tickets (v2)" },
  { code: "F", name: "Forfait" },
  { code: "OS", name: "Otros servicios" },
  { code: "R", name: "Restaurantes" },
  { code: "S", name: "Seguros" },
  { code: "T", name: "Transfers" },
  { code: "TTO", name: "Tratamientos" },
  { code: "X", name: "Tours" },
];

export const useTicketsInfo = (provider) => {
  const [state, setState] = useState({ loading: true, tickets: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = serviceTypes.map(async (st) => {
          return await axios.get(`${url}/doblementev2/tickets`, { params: { provider, service: st.code } });
        });
        const response = await Promise.all(promises);

        let tickets = [];
        response.forEach((res) => {
          if (res.status === 200 && res.data) {
            tickets = [...res.data, ...tickets];
          }
        });

        setState({ tickets, loading: false });
      } catch (error) {
        setState({ loading: false, tickets: [] });
        throw error;
      }
    };

    if (state.loading) {
      fetchData();
    }
  }, [provider, state.loading]);

  return { ...state };
};

/**
 *
 * @param {*} searchParams
 */
export const searchProductsAPI = async (params) => {
  let responseRet = { ok: false, results: [] };
  try {
    const { status, data } = await axios.get(`${url}/doblementev2/availability/product`, { params });

    if (status === 200 && data) {
      responseRet = { ok: true, results: data };
    }
  } catch (error) {
    responseRet = { ok: false, results: [] };
  }

  return responseRet;
};
